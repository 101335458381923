import React, { useEffect } from 'react'
import Button from '../../../components/button';
import { useNavigate } from 'react-router-dom';
import Footer from '../../client/appointment/footer';

export default function RegistrationCompleted() {
    const navigate = useNavigate();
    useEffect(() => {
        // window.gtag('config', 'AW-11191395185/5KRNCILZj7AZEPHGvNgp');
        console.log(process.env.REACT_APP_LIVE_SCRIPT,"process.env.REACT_APP_BASE_URL")
        if (process.env.REACT_APP_LIVE_SCRIPT === 'TRUE') {
            window.gtag('event', 'conversion', { 'send_to': 'AW-11191395185/5KRNCILZj7AZEPHGvNgp' });
            window.fbq('track', 'COMPLETE_REGISTRATION');

        }

        setTimeout(() => {
            localStorage.removeItem('registration-from')
        }, 3000);
    }, [])
    return (
        <div className='min-height-80vh'>

            <div>
                <div className="coming-soon">
                    <img className="mt-2 mr-2" src="/images/tick-marks-removebg.png" />
                    <h3 className="font-size-28 text-center max-width-900">
                        You Have Successfully Completed The Registration,<br />Click the button below to Book an Appointment.
                    </h3>
                    <br />
                    <div>
                        <Button
                            status={0}
                            type="button"
                            title={'Book Appointment'}
                            onClick={() => navigate('/appointment')}
                            className={`resend-btn height-45 width-190px  mt-2 mb-2`}
                        />
                    </div>
                    <br />
                    <b><p class='font-size-19 text-center fw-bolder'>
                    <u>Note:</u>  Prior to your session, test the Zoom audio and video connections on your device using the link:
                    </p></b>
                    <p class='text-center'>
                        <a href='https://zoom.us/test'>https://zoom.us/test</a>
                    </p>
                </div>
            </div>

            <div className='text-center'>
                <Footer />
            </div>
        </div>
    )
}
