import React from 'react'
import { items } from '../calendar/data'

export default function ScheduledetailsModal({ info, setloaderType, Index ,checkMediumInOnDemand ,cacheCheck}) {
    return (
        <div>
            <div
                className="modal fade right_modal "
                id="ScheduledetailsModal"
                data-backdrop="static"
                role="dialog"
            >
                <div className="modal-dialog advanceConfirmbooking  modal-dialog-preview height-unset modal-30w">
                    <div className="modal-content">
                        {/* <button
              type="button"
              class="close about-us-review-close  calendar-view-creoss-icin"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span className="cross-preview-box" aria-hidden="true">&times;</span>
            </button> */}
                        <div className="modal-body">
                            <div class="sc-1vtwom0-0 fybOzd"><button onClick={() => window.loadModal2('#ScheduledetailsModal', 'hide')} data-testid="panelBack" class="SquareButton__StyledSquareButton-sc-1njhw9f-0 cwNJQZ sc-1vtwom0-2 eZmLtC" type="button"><div class="SquareButton__IconWrapper-sc-1njhw9f-1 iXcSWy"><svg viewBox="0 0 24 24" width="1.5em" height="1.5em" rotate="90" aria-hidden="true" focusable="false" class="BaseSvg-sc-yh8lnd-0 ChevronIcon___StyledBaseSvg-sc-1y4em6t-0 ggZsDW"><path d="M3.47 8.26 4.53 7.2 12 14.67l7.47-7.47 1.06 1.06L12 16.8z"></path></svg></div><span class="VisuallyHidden-sc-8buqks-0 lmhoCy"></span></button><h2 class="sc-1vtwom0-1 iFZzof">Event information</h2></div>
                            {info !== undefined &&
                                <div class="sc-ukqhnl-1 gRbkB">
                                    <div class="sc-we5la6-1 lfhkUh">
                                        <div class="Flex-sc-mx5re3-0 sc-we5la6-2 hksPTU QondX">
                                            <div class="sc-we5la6-4 KhUBn">
                                                <h3 class="sc-we5la6-5 dUiSGl"><span aria-hidden="true"><span>{info.day}, {info.monthname} {info.date}</span></span><span class="VisuallyHidden-sc-8buqks-0 lmhoCy">
                                                    {/* <span>Wednesday, November 13</span> */}
                                                </span>
                                                </h3>

                                            </div>

                                        </div>

                                        <div>
                                            <div class="sc-1lh1r6l-2 hkTojk">
                                                <span class="sc-1lh1r6l-4 fTjKCR">{info.time}</span>
                                                <span className='d-block sc-fyofxi-6 ivIeWB mt-2'>{info.name}</span>
                                                <span class="d-block sc-fyofxi-5 gJmuwa mt-2">{info.subtext}</span>

                                                <div className="godemantevent-date-box mt-3">
                                                    <a onClick={info.showReminder ? () => checkMediumInOnDemand(info) : () => { }} href="#" className={`w-100 kCQEIv indexstyles__StyledButton-sc-83qv1q-0 jdbotF sc-1qeub3n-4 lmaHQg  border-radius-40 ${info.showReminder ? 'Green-bg-all' : ''}`}>
                                                    <span class="indexstyles__FlexWrapper-sc-83qv1q-1 kCQEIv"><span class="indexstyles__Text-sc-83qv1q-2 jHTUWf"><span class="sc-1qeub3n-6 iwMkwK"><span class="sc-1qeub3n-5 eTlzst">{info.showReminder ? "Find a Partner" : "Remind Me"}</span></span>

                                                        <svg class="BaseSvg-sc-yh8lnd-0 ChevronIcon___StyledBaseSvg-sc-1y4em6t-0 ckLyyv sc-1qeub3n-2 bcqnRP" viewBox="0 0 24 24" width="1.5em" height="1.5em" aria-hidden="true" focusable="false"><path d="M3.47 8.26 4.53 7.2 12 14.67l7.47-7.47 1.06 1.06L12 16.8z"></path></svg>
                                                    </span></span></a>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className='bottom-line mt-3'>
                                    </div>
                                    <br />
                                    <br />
                                    <p class="sc-cqhbd7-2 hZKQqU">{info.description}</p>

                                </div>}
                        </div>
                    </div>

                </div>
            </div >
        </div>
    )
}
