import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import TermsAndConditions from "../pages/termsandcondition";
import Privacy from "../pages/privacy";

const CommonRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/terms-conditions" />} />
      <Route path="/terms-conditions" element={<TermsAndConditions />} />
      <Route path="/privacy-policy" element={<Privacy />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
export default CommonRoute;
