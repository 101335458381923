import Vimeo from '@u-wave/react-vimeo'
import React, { useEffect, useState } from 'react'
import { FadeLoader } from 'react-spinners'
import Button from '../../../../components/button'
import { Link, useNavigate } from 'react-router-dom'
import TickMark from '../../../../components/tickmark'
import { saveStepsProgress } from '../../../../services/medium'

export default function StepFive({ NextStep ,innerWidthHandler }) {

    const navigate = useNavigate()

    const [showLoader, setShowLoader] = useState(true);
    const [verificationStatus, setVerificationStatus] = useState(0);
    const [showNext, setShowNext] = useState(false);

    const openAppointMent = () => {
        window.open("/integration", "_blank", "noreferrer")
    }
    useEffect(() => {
        setTimeout(() => {
            setVerificationStatus(1);
            setShowLoader(false);
        }, 7000)
    })

    const savestep = () => {
        let body = {
            step_number: 5
        }
        saveStepsProgress(body).then((data) => {
            if (data.data.status) {
                localStorage.setItem('step', 5)
                NextStep()
            }
        }).catch(() => {

        })
    }
    const createSchedule = () => {
        setShowNext(true);
        window.open("/availability?from=onboarding&time=1", "_blank");
        // navigate('/availability?from="onboarding',"")
    }
    return (
        <div>
            <div className='text-left  pl-5 pr-5 mt-5 mb-5'>

                <h3 className='font-size-40'> Make a Schedule</h3>
                <ol className='font-size-18 line-height-24px'>
                    <li>Watch the video below.</li>
                    <li>Create a Schedule. (optional)
                        <br />
                        
                        (Will open in a new tab. Come back here once complete.)
                    </li>

                    <li>Check Preview of Schedule.</li>
                </ol>
                {/* <p className='font-size-18 line-height-24px'>The Create a Schedule screen will open in a new tab. To continue the Onboarding process come back to this tab.</p> */}


            </div>
            <div>
                <Vimeo
                    muted={true}
                    autoplay={false}
              
                    // className="popover-video"
                    width={innerWidthHandler()}
                    height={330}
                    video={"https://vimeo.com/922236305/45a8a7bc1e"}
                />
            </div>
            <div className='mt-5'>

                {!showNext ? <Button
                    className={"action-button margin-unset"}
                    status={0}
                    onClick={() => createSchedule()}
                    title="Create a Schedule" />
                    :
                    <Button
                        className={"action-button margin-unset"}
                        status={0}
                        onClick={() => savestep()}
                        title="Next" />}
                        

                <div className='ml-5 d-inline-block'>
                    <Button
                        className={"action-button margin-unset skip-pbtn "}
                        status={0}
                        onClick={() => savestep()}
                        title="Skip for Now" />
                </div>


            </div>
          <p className='mt-3'>Note: Create a schedule and then come back to this tab.</p> 

        </div>
    )
}
