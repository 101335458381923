import React from "react";


export default function TabItem({ tab, index, activeTabHandler }) {
 

  return (
    <li
      onClick={() => activeTabHandler(index)}
      className={`${tab.active ? "active-tab" : ""}`}
    >
      {tab.name}&nbsp;{tab.count !== undefined ? `(${tab.count})` : ""}
   
    </li>
  );
}
