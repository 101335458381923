import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pusher from "pusher-js";
import PayOptionsPopUp from "../../../../components/payoptionspopup";
import PayItForwardPopUp from "../../appointments/components/payitforward";
import WaitingTimerPopup from "../subcomponents/waitingTimer";
import { setbookedAppointmentData } from "../../../../reducer/clientsSlice";
import { toast } from "react-toastify";
import { getTimeImage } from "../../../../utils/utils";
import ConfirmDeleteModal from "../../../../components/deleteConfirmModal";
import { doAPayItForwardPayment } from "../../../../services/client";
import CheckNightAppointment from "../subcomponents/checkNightAppointment";
import "../dialog.css"
//components
// import ConfirAdvanceModal from "../advanceappointment/confirmadvancemodal";
export default function Progress({
  bookingType,
  // setAppointments,
  practitionerInfo,
  sessionType,
  changeRender,
  // getAvailabilites,
  // selectType,
  bookAppointment,
  activeSubSessionId,
  activeDurationId,
  activeAmount
  // loaderLine
}) {
  //
  const dispatch = useDispatch()
  const [activePay, setActivePay] = useState("");
  const [paymentCurrency, setPaymentCurrency] = useState('USD');
  const [paymentstatus, setPaymentstatus] = useState(false);
  const [payItforwardValue, setpayItforwardValue] = useState('');
  const [payItforwardFixPrice ,setpayItforwardFixPrice] = useState("")
  const [counterStatus, setCounterStatus] = useState(false);
  const {
    bookingdate,
    bookingtime,
  } = useSelector((state) => state.client);
  const { id } = useSelector((state) => state.user.user);

  useEffect(() => {
    Pusher.logToConsole = false;

    var pusher = new Pusher("823ab72ed6ecd563b8c8", {
      cluster: "ap2",
    });

    var channel = pusher.subscribe("notify-client-payment");
    channel.bind("App\\Events\\NotifyClientAboutPayment", function (data) {
      console.log(data, "data.appointment)");

      if (parseInt(data.appointment.client_id) === parseInt(id)) {
        dispatch(setbookedAppointmentData(data.appointment));
        window.loadModal2('#WaitingTimerPopup', 'hide');
        window.loadModal("#advanceConfirmbooking", "open");
      }

      // appointment
      // : 
      // appointment_id
      // : 
      // 92
      // date
      // : 
      // "2023-06-24"
      // medium_name
      // : 
      // "Sumit C"
      // meeting_link
      // : 
      // "https://us05web.zoom.us/j/81170517832?pwd=VGdIWStZcmpkeGVCOXRUV1VaYjRjQT09"
      // time
      // : 
      // "06:00 AM"
    });

  }, [counterStatus])
  const openMoreInfo = (info) => {
    window.loadModal2("#payoptionspopup", "show");
  };
  const { bookingBtnStatus } = useSelector((state) => state.client);

  const checkBookingType = () => {
    localStorage.setItem('bookingType', bookingType);
    console.log("bookingType", bookingType)
    console.log("practitionerInfo", practitionerInfo)
    // return;
    if (bookingType === "professional") {
      if (practitionerInfo.general.payStatus !== undefined) {
        if (practitionerInfo.general.payStatus === "before") {
          if (bookingdate === "" || bookingtime === "") {
            toast.error("Please select both date and time slot", {
              position: "top-center",
            });
            return;
          }
          else {
            if(practitionerInfo.general.payType.includes(1)){
              payToBookInfo(practitionerInfo.general);
              return;
            }else{
              openMoreInfo();
            return;
            }
            
          }
        }
        else {
          localStorage.setItem('profType', practitionerInfo.general.payStatus);
          bookAppointment();
          return;
        }
      }
      else {
        if (
          practitionerInfo.general.pre_pay_session_type === undefined &&
          practitionerInfo.general.post_pay_session_type === undefined
        ) {
          if (bookingdate === "" || bookingtime === "") {
            toast.error("Please select both date and time slot", {
              position: "top-center",
            });
            return;
          }
          else {

            openMoreInfo();
            return;
          }
        } else if (
          practitionerInfo.general.pre_pay_session_type === "8" &&
          practitionerInfo.general.post_pay_session_type === "7"
        ) {
          if (bookingdate === "" || bookingtime === "") {
            toast.error("Please select both date and time slot", {
              position: "top-center",
            });
            return;
          }
          else {

            openMoreInfo();
            return;
          }
        }
        else if (
          practitionerInfo.general.pre_pay_session_type === "8" &&
          practitionerInfo.general.post_pay_session_type === undefined
        ) {
          if (bookingdate === "" || bookingtime === "") {
            toast.error("Please select both date and time slot", {
              position: "top-center",
            });
            return;
          }
          else {

            openMoreInfo();
            return;
          }
        }
        else {
          localStorage.setItem('profType', practitionerInfo.general.payStatus);

          bookAppointment();
          return;
        }
      }
    }
    else if (bookingType === "outreach") {
      if (bookingdate === "" || bookingtime === "") {
        toast.error("Please select both date and time slot", {
          position: "top-center",
        });
        return;
      }
      let check = getTimeImage(bookingtime)
      if (check) {
        window.loadModal2('#checkForNightTime', 'show')
      }
      else {

        console.log('this outreach case working');
        bookAppointment();
        return;
      }

      // bookAppointment();
    }
    else {
      console.log('this case working');
      bookAppointment();
      return;
    }
  };
  const payToBookInfo = (item) => {
    setActivePay(item);
   
    if (item.is_pay_forward) {
      if(item.payitforward_pricing !== null){
        const cleanString = item.payitforward_pricing.slice(1, -1); // remove the outer quotes
      const parsedString = cleanString.replace(/\\/g, ''); // remove backslashes
      // Step 2: Parse the cleaned string into an object
      let parsedPricing;
      try {
        parsedPricing = JSON.parse(parsedString);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
      let duration = localStorage.getItem("duration");
      let priceValue = parsedPricing[duration];
      setpayItforwardValue(item.payitforward_pricing !== null ? priceValue : '');
      setpayItforwardFixPrice(item.payitforward_pricing !== null ? priceValue : '');
      window.loadModal2("#PayItForwardPopUp", "show");
      }else{
        setpayItforwardFixPrice(item.payitforward_pricing !== null ? '' : '');
        setpayItforwardValue(item.payitforward_pricing !== null ? '' : '');
        window.loadModal2("#PayItForwardPopUp", "show");
      }
      return;
    }
    openMoreInfo();
  };
  const payItForwardHandler = () => {
    let currencyVal = activePay !== null ? activePay.session_currency !== undefined && activePay.session_currency !== "" && activePay.session_currency !== null ? Object.values(
      JSON.parse(activePay.session_currency)
    )[0] : 'CAD' : '';
    let duration = localStorage.getItem("duration");
    if (duration && duration.endsWith("2")) {
        duration = duration.slice(0, -1);
    }
    let data;
    if (activePay !== null && activePay !== undefined) {
      if (activePay.payStatus !== undefined) {
        if (activePay.payStatus === "before") {
          data = 8;
        }
        else {
          data = 7;
        }
      }
    }
    let body = {
      medium_id: activePay.medium_id,
      amount: payItforwardValue,
      appointment_type: 'paybefore',
      currency: currencyVal,
      session_type: data,
      date: bookingdate,
      time: bookingtime,
      method : "paypal",
      duration: duration,
      subsession_id: activeSubSessionId
    }
    
    doAPayItForwardPayment(body).then((data) => {
      if (data.data.status) {
        window.open(data.data.data.approvedUrl, "_self");
        // openWaitingContainer();
      }
    }).catch(() => {

    })
  }
  return (
    <>
      <div className="progress-saction">
        <div className="container">
          <ul>
            <li onClick={changeRender} className="back">
              <a href="#">
                <img src="images/black-erow.png" alt="back" />
                Back
              </a>
            </li>
            {!bookingBtnStatus ? (
              <li
                // onClick={
                //   selectType == "newappointment"
                //     ? getAvailabilites
                //     : () => setAppointments(selectType)
                // }
                className="continue"
              >
                {/* <a href="#">
                Continue
                <img src="images/wight-erow.png" />
              </a> */}
              </li>
            ) : (
              <li className="continue">
                <div className="dont-have-acc">
                  {/* sessionType==="prof"?  () =>"": */}
                  <button
                    onClick={checkBookingType}
                    type="button"
                    className="btn btn-info btn-lg width-unset-img "
                  // data-toggle="modal"
                  // data-target="#myModal"
                  >
                    Book Appointment
                    <img
                      className="width-unset-img book-app-btn-client "
                      src="../images/wight-erow.png"
                      alt="book appointment"
                    />
                  </button>
                  {/* <ConfirAdvanceModal /> */}
                </div>
              </li>
            )}
          </ul>
        </div>

        {/* <div style={{ width: `${loaderLine}%` }} className="prgress-baar-new">
      {loaderLine !== 0 && (
            <span className="loaderline"> {loaderLine}%</span>
          )}
      </div> */}
      </div>
      <PayOptionsPopUp
        practitionerInfo={practitionerInfo}
        bookAppointment={bookAppointment}
        setCounterStatus={setCounterStatus}
        activeSubSessionId={activeSubSessionId}
        activeDurationId={activeDurationId}
        activeAmount={activeAmount}
      />
       <PayItForwardPopUp
        activePay={activePay}
        paymentMethodHandler={payItForwardHandler}
        setpayItforwardValue={setpayItforwardValue}
        setPaymentCurrency={setPaymentCurrency}
        payItforwardFixPrice={payItforwardFixPrice}
        paymentstatus={paymentstatus}
        payItforwardValue={payItforwardValue}
      />
      {counterStatus && (<WaitingTimerPopup />)}
      <CheckNightAppointment
        bookingtime={bookingtime}
        deleteUser={bookAppointment}
        cancelRequest={() => window.loadModal2('#checkForNightTime', 'hide')}
        id={'checkForNightTime'}


      />
    </>
  );
}
