import React, { useState } from "react";
import "./payment.css";
import Button from "../../../../../../components/button";
export default function PaymentPrices({ paymentMethodHandler ,setPaymentAmount }) {
  const [payment, setPayment] = useState([
    { id: 1, value: "20", status: true },
    { id: 2, value: "30", status: false },
    { id: 3, value: "40", status: false },
    { id: 4, value: "50", status: false },
    { id: 5, value: "70", status: false },
    { id: 6, value: "100", status: false },
  ]);
  const [inputValue, setInputValue] = useState("20");

  const setPaymentHandler = (item) => {
    let info = payment.map((data) => {
      if (data.id === item.id) {
        return { ...data, ...(data.status = true) };
      } else {
        return { ...data, ...(data.status = false) };
      }
    });
    let input = info.filter((data) => data.status === true);
    setPaymentAmount(input[0].value)
    setInputValue(input[0].value);
    setPayment(info);
  };

  const paymentChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setInputValue(value);
    setPaymentAmount(value)
  };
  return (
    <div
      class="modal fade payment-price-popup mt-5"
      tabindex="-1"
      role="dialog"
      id="payment-price-popup"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-preview" >
        <div class="modal-content">
          <div class="modal-body payment-wrapper">
            <h4 className="payment-heading">Make a Payment</h4>
            <div className="payment-price-tags pointer">
              {payment.map((item, index) => (
                <>
                  <div
                    onClick={() => setPaymentHandler(item)}
                    className={`tag ${item.status ? "" : "tag-white"}`}
                  >
                    ${item.value}
                  </div>
                </>
              ))}
            </div>
            <div className="payment-methods-container">
              <div>
                <label>Enter Amount</label>
                <input
                  value={inputValue}
                  name="inputvalue"
                  type="text"
                  className="mt-3"
                  onChange={paymentChangeHandler}
                />
              </div>
            </div>
            <div className="payment-save-btn">
              <Button
                className={"resend-btn w-unset height-45 min-width-130px"}
                status={0}
                onClick={paymentMethodHandler}
                title="Pay"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
