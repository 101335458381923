import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Integration from "../pages/medium/integration";
import VerifyEmail from "../pages/admin/calendar/verifyemail";
import Onboarding from "../pages/medium/onboarding";

const MediumOnBoardingRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/onboarding-process" />} />
      <Route path="/onboarding-process" element={<Onboarding />} />
      <Route path="/verifyemail" element={<VerifyEmail />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
export default MediumOnBoardingRoute;
