import React, { useEffect, useState } from "react";
import { updateFeedbackByAdmin } from "../../../../../services/admin";
import { toast } from "react-toastify";

export default function EditFeedbackPractice({ editFeedbackDetail, callBack }) {
    const [aboutVerysoul, setaboutVerysoul] = useState("");
    const [anythingElse, setanythingElse] = useState("");
    const [constructiveThought, setconstructiveThought] = useState("");
    const [
        introduction,
        setIntroduction,
    ] = useState("");
    const [compliment, setCompliment] = useState("");

    ///
    const [aboutVerysoulPublish, setaboutVerysoulPublish] = useState("");
    const [meaningfulExperiencePublish, setanythingElsePublish] = useState("");
    const [constructiveThoughtPublish, setconstructiveThoughtPublish] = useState("");
    const [
        introductionPublish,
        setIntroductionPublish,
    ] = useState("");
    const [complimentPublish, setcomplimentPublish] = useState("");

    const [requestValidationtPublish, setrequestValidationtPublish] = useState("");
    const [requestValidation, setrequestValidation] = useState("");

    const [SufficientEvidence, setSufficientEvidence] = useState("");
    const [SufficientEvidencePublish, setSufficientEvidencePublish] = useState("");
    const [tookturn, settookturn] = useState("");
    const [tookturnPublish, settookturnPublish] = useState("");

    const [feedbackId, setFeedbackId] = useState('')

    //effects
    const clearStates = () => {
        setaboutVerysoul("");
        setanythingElse("");
        setconstructiveThought("");
        setIntroduction("");
        setCompliment("");
        setrequestValidation("");
        setSufficientEvidence("");
        settookturn("");

        /// publisheabele
        setaboutVerysoulPublish("");
        setanythingElsePublish("");
        setconstructiveThoughtPublish("");
        setIntroductionPublish("");
        setcomplimentPublish("");
        setrequestValidationtPublish("")
        setSufficientEvidencePublish("");
        settookturnPublish("");

        setFeedbackId("")

    }
    useEffect(() => {
        if (editFeedbackDetail !== undefined) {
            console.log(Object.values(editFeedbackDetail)[0],"editFeedbackDetail");
            let feedback;
            let id;
            let publish;
            
            if ((Object.keys(editFeedbackDetail).length) === 1) {
                feedback = Object.values(editFeedbackDetail)[0].feedback;
                id = Object.values(editFeedbackDetail)[0].feedback_id
            }
            else {
                feedback = Object.values(editFeedbackDetail)[1].feedback;
                id = Object.values(editFeedbackDetail)[1].feedback_id
            }
            // setaboutVerysoul(feedback.about_verysoul);
            // setanythingElse(feedback.anything_else);
            // setconstructiveThought(feedback.constructive_thought);
            // setIntroduction(
            //     feedback.Introduction
            // );
            // setCompliment(feedback.compliment);
            // setrequestValidation(feedback.request_validation);

            // settookturn(feedback.took_turn);
            // setSufficientEvidence(feedback.sufficient_evidence);

            /// publisheabele
            setaboutVerysoulPublish(feedback.about_verysoul);
            setanythingElsePublish(feedback.anything_else);
            setconstructiveThoughtPublish(feedback.constructive_thought);
            setIntroductionPublish(
                feedback.Introduction
            );
            setrequestValidationtPublish(feedback.request_validation);
            setcomplimentPublish(feedback.compliment);
            settookturnPublish(feedback.took_turn);
            setSufficientEvidencePublish(feedback.sufficient_evidence);

            setFeedbackId(id)
        }
    }, [editFeedbackDetail]);

    const onChangeHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        // let check = (value > 0 && value < 6 || value === "")
        let check = true
        if (name === "aboutVerysoulPublish") {

            setaboutVerysoulPublish(value);

        }
        if (name === "meaningfulExperiencePublish" && check) {
            setanythingElsePublish(value);
        }
        if (name === "constructiveThoughtPublish") {
            setconstructiveThoughtPublish(value);
        }
        if (name === "introductionPublish" && check) {
            setIntroductionPublish(value);
        }
        if (name === "complimentPublish" && check) {
            setcomplimentPublish(value);
        }
        if (name === "requestValidationtPublish" && check) {
            setrequestValidationtPublish(value);
        }
        if (name === "SufficientEvidencePublish" && check) {
            setSufficientEvidencePublish(value);
        }
        if (name === "tookturnPublish" && check) {
            settookturnPublish(value);
        }
    };

    const onCancelHandler = () => {
        // window.loadModal2("#editFeedback", "hide");
        clearStates()
        window.loadModal2("#editpracticeFeedbacks", "hide");
    };

    const updateHandler = () => {
        let body = {
            feedback_id: feedbackId,
            Introduction: introductionPublish,
            anything_else: meaningfulExperiencePublish,
            compliment: complimentPublish,
            constructive_thought: constructiveThoughtPublish,
            request_validation: requestValidationtPublish,
            sufficient_evidence: SufficientEvidencePublish,
            took_turn: tookturnPublish,
            about_verysoul: aboutVerysoulPublish,
        }
        updateFeedbackByAdmin(body)
            .then((data) => {
                if (data.data.status) {
                    toast.success('Feedback updated succesfully', { position: 'top-center' })
                    callBack()
                    // window.loadModal2('#editFeedback', 'hide');
                    clearStates()
                    window.loadModal2("#editpracticeFeedbacks", "hide");
                }
            })
            .catch(() => {
                toast.error('Error', { position: 'top-center' })
            });
    };
    return (
        <div
            className="modal fade "
            id="editpracticeFeedbacks"
            // data-backdrop="static"
            role="dialog"
        >
            <div className="modal-dialog  modal-dialog-preview">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="schedule-popup">
                            {/* <img src="../images/new-cll.png" alt="user" /> */}

                            <h3 className="mb-4 text-center ">Feedback</h3>
                                <div>
                                    <div className="from-fild mt-2 mb-4">
                                        <label htmlFor="firstName">
                                            Introduction
                                        </label>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <input
                                                onChange={onChangeHandler}
                                                value={introductionPublish}
                                                type="text"
                                                id="introductionPublish"
                                                name="introductionPublish"
                                                placeholder="Recognized My Loved One From Evidence"

                                            />
                                        </div>
                                    </div>
                                    <div className="from-fild mt-2 mb-4">
                                        <label htmlFor="firstName">Anything Else</label>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <input
                                                onChange={onChangeHandler}
                                                value={meaningfulExperiencePublish}

                                                type="text"
                                                id="meaningfulExperiencePublish"
                                                name="meaningfulExperiencePublish"
                                                placeholder="Anything Else"
                                            />
                                        </div>
                                    </div>


                                    <div className="from-fild mt-2 mb-4">
                                        <label htmlFor="firstName">Compliment</label>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <input
                                                onChange={onChangeHandler}
                                                value={complimentPublish}

                                                type="text"
                                                id="complimentPublish"
                                                name="complimentPublish"
                                                placeholder="Compliment"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="from-fild mt-2 mb-4">
                                    <label htmlFor="firstName">Constructive Thought</label>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <input
                                            onChange={onChangeHandler}
                                            value={constructiveThoughtPublish}
                                            type="text"
                                            id="constructiveThoughtPublish"
                                            name="constructiveThoughtPublish"
                                            placeholder="Constructive Thought"
                                        />
                                    </div>
                                </div>
                                <div className="from-fild mt-2 mb-4">
                                    <label htmlFor="firstName">Request Validation</label>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <input
                                            onChange={onChangeHandler}
                                            value={requestValidationtPublish}
                                            type="text"
                                            id="requestValidationtPublish"
                                            name="requestValidationtPublish"
                                            placeholder="Request Validation"
                                        />
                                    </div>
                                </div>
                                <div className="from-fild mt-2 mb-4">
                                    <label htmlFor="firstName">Sufficient Evidence</label>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <input
                                            onChange={onChangeHandler}
                                            value={SufficientEvidencePublish}
                                            type="text"
                                            id="SufficientEvidencePublish"
                                            name="SufficientEvidencePublish"
                                            placeholder="Sufficient Evidence"
                                        />
                                    </div>
                                </div>
                                <div className="from-fild mt-2 mb-4">
                                    <label htmlFor="firstName">Took Turn</label>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <input
                                            onChange={onChangeHandler}
                                            value={tookturnPublish}
                                            type="text"
                                            id="tookturnPublish"
                                            name="tookturnPublish"
                                            placeholder="Took Turn"
                                        />
                                    </div>
                                </div>
                                <div className="from-fild mt-2 mb-4">
                                    <label htmlFor="firstName">About Verysoul</label>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <textarea
                                            rows={6}
                                            className="feedback-text-area"
                                            onChange={onChangeHandler}
                                            value={aboutVerysoulPublish}
                                            type="text"
                                            id="aboutVerysoulPublish"
                                            name="aboutVerysoulPublish"
                                            placeholder="About Verysoul"
                                        />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div
                                        className="advance-tag pointer text-center width-150px"
                                        onClick={onCancelHandler}
                                    >
                                        Cancel
                                    </div>
                                    <div
                                        className="advance-tag pointer text-center width-150px ml-2"
                                        onClick={updateHandler}
                                    >
                                        Update
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
    );
}
