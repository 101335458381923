import React, { useEffect, useState } from "react";
// import {
//   doAProfessionalPayment,
//   payWithPaypal,
//   payWithStripe,
// } from "../../../services/client";
// import PaymentPrices from "../payment/payment";
import { useSelector } from "react-redux";

export default function CheckPayOptionsPopUp({ activePay, setPaymentType }) {


  const paymentMethodHandler = (type) => {
    setPaymentType(type)
    window.loadModal2('#CheckPayOptionsPopUp', "hide");
    setTimeout(() => {
      
      window.loadModal2('#payment-price-popup', "show");
    }, 500)
  };
  return (
    <div
      className="modal fade"
      id="CheckPayOptionsPopUp"
      role="dialog"
      data-backdrop="true"
    >
      <div className="modal-dialog modal-dialog-preview w-100">
        <div className="modal-content margin-top-300 border-waiting-time">
          <div className="modal-body modal-body-waiting-time">
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
            <div className="profile-video"></div>
            <h3 className="text-center mt-2">Pay With</h3>
            <div>
              <div className="payment-new-wrapper mt-5">
                <div className="payment-new-container mt-5">
                  {activePay.payment_gateway === "both" && <> <button
                    onClick={() => paymentMethodHandler("paypal")}
                    data-toggle="modal"
                    className={`main-btn`}
                  >
                    {" "}
                    <img src={"/integrationimages/paypal.png"} />
                  </button>
                    <button
                      onClick={() => paymentMethodHandler("stripe")}
                      data-toggle="modal"

                      className={`main-btn`}
                    >
                      {" "}
                      <img
                        className="stripe-img"
                        src={"/integrationimages/stripe.png"}
                      />
                    </button></>}
                  {activePay.payment_gateway === "paypal" && <button
                    onClick={() => paymentMethodHandler("paypal")}
                    data-toggle="modal"
                    className={`main-btn`}
                  >
                    {" "}
                    <img src={"/integrationimages/paypal.png"} />
                  </button>
                  }
                  {activePay.payment_gateway === "stripe" && <button
                    onClick={() => paymentMethodHandler("stripe")}
                    data-toggle="modal"
                    className={`main-btn`}
                  >
                    {" "}
                    <img
                      className="stripe-img"
                      src={"/integrationimages/stripe.png"}
                    />
                  </button>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
