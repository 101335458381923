import React from "react";
import moment from "moment";

export default function Weekdays() {
  const weekdayshort = moment.weekdaysShort();

  return (
    <tr>
      {weekdayshort.map((day) => (
        <th key={day} className="week-day">
          {day}
        </th>
      ))}
    </tr>
  );
}
