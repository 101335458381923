import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function GoToAvailability() {
    const navigate = useNavigate();
    const GoToAvailabilityHandler = () => {
        navigate('/availability')
    }
    return (
        <div className="confirm-delete-wrapper register-medium">
            <div data-backdrop="static" className="modal fade" id="gotoavailability" role="dialog">
                <div className="modal-dialog modal-dialog-preview">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="icon-pop">
                                <img src="images/Union-icon.png" alt="Union-icon" />
                            </div>
                            <h3>{"Click here to set your availability"}</h3>
                            <div className="confirm-btn-box">
                            <div
                                    className="register-medium-confirnation"
                                    data-dismiss="modal"
                                    
                                >
                                    {'Cancel'}
                                </div>
                                <div
                                    className="register-medium-confirnation"
                                    data-dismiss="modal"
                                    onClick={GoToAvailabilityHandler}
                                >
                                    {'Go To Availability'}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
