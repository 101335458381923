import React from "react";
import Footer from "../../components/footer/footer";
import Topbar from "../../components/topbar/topbar";
import "../termsandcondition/terms.css";

export default function Privacy() {
  return (
    <>
      <Topbar />
      <div>
        <h1 className="single-title page-title entry-title">Privacy Policy</h1>{" "}
        <div className="terms-container">
          <section
            className="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-54ebfd94 trms elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="54ebfd94"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div
                className="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1e99a27"
                data-id="1e99a27"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-42142a3b elementor-widget elementor-widget-text-editor"
                    data-id="42142a3b"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p className="terms-date">
                        Last Modified: June 26, 2023
                      </p>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-2a6cbd37 elementor-widget elementor-widget-heading"
                    data-id="2a6cbd37"
                    data-element_type="widget"
                    data-settings='{"_animation":"none"}'
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Introduction{" "}
                      </h2>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-229921ef elementor-widget elementor-widget-text-editor"
                    data-id="229921ef"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        Very Soul LLC (“<strong>VerySoul</strong>,” “
                        <strong>Company</strong>,”&nbsp;or&nbsp;“
                        <strong>we</strong>,” or “<strong>us</strong>”) respects
                        your&nbsp;privacy&nbsp;and is committed to protecting it
                        through our compliance with this&nbsp;policy (the “
                        <strong>Privacy Policy</strong>
                        ”).
                      </p>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-13573d68 elementor-widget elementor-widget-text-editor"
                    data-id="13573d68"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        This Privacy Policy describes the types of information
                        we may collect from you or that you may provide when you
                        visit the website{" "}
                        <a href="https://verysoul.com">VerySoul.com</a>,
                        including any content, functionality, and services or
                        programmed offered on, through, or coordinated with
                        VerySoul (our “Website”) and our practices for
                        collecting, using, maintaining, protecting, and
                        disclosing that information.
                      </p>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-3353ed23 elementor-widget elementor-widget-text-editor"
                    data-id="3353ed23"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        This&nbsp;Privacy Policy&nbsp;applies to information we
                        collect:
                      </p>
                      <ul>
                        <li>On this Website.</li>
                        <li>
                          In email, text, and other electronic messages between
                          you and this Website.
                        </li>
                        <li>
                          Through mobile and desktop applications you download
                          from this Website, which provide dedicated
                          non-browser-based interaction between you and this
                          Website.
                        </li>
                        <li>
                          When you interact with our advertising and
                          applications on third-party websites and services, if
                          those applications or advertising include links to
                          this&nbsp;Privacy Policy.
                        </li>
                        <li>
                          Any other information you may choose to share with
                          this Website.
                        </li>
                      </ul>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-196b98f5 elementor-widget elementor-widget-text-editor"
                    data-id="196b98f5"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>It does not apply to information collected by:</p>
                      <ul>
                        <li>
                          Us offline or through any other means, including on
                          any other website operated by Company or any third
                          party (including our affiliates and subsidiaries); or
                        </li>
                        <li>
                          Any third party (including our affiliates and
                          subsidiaries), including through any application or
                          content (including advertising) that may link to or be
                          accessible from or on the Website.
                        </li>
                      </ul>
                      <p>
                        Please read this&nbsp;Privacy Policy&nbsp;carefully to
                        understand our&nbsp;policies&nbsp;and practices
                        regarding your information and how we will treat it. If
                        you do not agree with our&nbsp;policies&nbsp;and
                        practices, your choice is not to use our Website. By
                        accessing or using this Website, you agree to
                        this&nbsp;Privacy&nbsp;Policy. This&nbsp;Privacy
                        Policy&nbsp;may change from time to time. Your continued
                        use of this Website after we make changes is deemed to
                        be acceptance of those changes, so please check this
                        Privacy Policy&nbsp;periodically for updates.
                      </p>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-262794ec elementor-widget elementor-widget-heading"
                    data-id="262794ec"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Children Under the Age of 18
                      </h2>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-6946271 elementor-widget elementor-widget-text-editor"
                    data-id="6946271"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        Our Website is not intended for children under 18 years
                        of age. No one under the age of 18 may provide any
                        information to or on the Website. We do not knowingly
                        collect Personal Information from children under 18. If
                        you are under 18, do not use this Website or provide any
                        information on or to this Website. If we learn we have
                        collected or received Personal Information from a child
                        under 18 without verification of parental consent, we
                        will delete that information. If you believe we might
                        have any information from or about a child under 18,
                        please contact us at{" "}
                        <a href="mailto:privacy@verysoul.com">
                          privacy@verysoul.com.
                        </a>
                      </p>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-7b251e1d elementor-widget elementor-widget-heading"
                    data-id="7b251e1d"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Information We Collect About You and How We Collect It
                      </h2>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-95c76d3 elementor-widget elementor-widget-text-editor"
                    data-id="95c76d3"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        We collect several types of information from and about
                        users of our Website, including information:
                      </p>
                      <ul>
                        <li>
                          By which you may be personally identified, such as
                          name, e-mail address, and any other identifier by
                          which you may be contacted online or offline
                          (“Personal Information”);
                        </li>
                        <li>
                          That is about you but individually does not identify
                          you; and/or
                        </li>
                        <li>
                          About your internet connection, the equipment you use
                          to access our Website, and usage details.
                        </li>{" "}
                      </ul>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-192166c3 elementor-widget elementor-widget-text-editor"
                    data-id="192166c3"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>We collect this information:</p>
                      <ul>
                        <li>Directly from you when you provide it to us.</li>
                        <li>
                          Automatically as you navigate through the site.
                          Information collected automatically may include usage
                          details, IP addresses, and information collected
                          through cookies, web beacons, and other tracking
                          technologies.
                        </li>
                        <li>
                          From third parties, for example, our business
                          partners.
                        </li>
                      </ul>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-4f336467 elementor-widget elementor-widget-heading"
                    data-id="4f336467"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Information You Provide to Us
                      </h2>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-49e1d7c0 elementor-widget elementor-widget-text-editor"
                    data-id="49e1d7c0"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        The information we collect on or through our Website may
                        include:
                      </p>
                      <ul>
                        <li>
                          Information that you provide by filling in forms on
                          our Website. This includes information provided at the
                          time of registering to use our Website, posting
                          material, or requesting further services. We may also
                          ask you for information when you enter a contest or
                          promotion sponsored by us, and when you report a
                          problem with our Website.
                        </li>
                        <li>
                          Records and copies of your correspondence (including
                          email addresses), if you contact us.
                        </li>
                        <li>
                          Your responses to surveys that we might ask you to
                          complete for research purposes.
                        </li>
                        <li>Your search queries on the Website.</li>
                        <li>
                          Any other information you may choose to share with
                          this Website.
                        </li>
                      </ul>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-32bd242 elementor-widget elementor-widget-text-editor"
                    data-id="32bd242"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        You also may provide information to be published or
                        displayed (hereinafter, “<strong>posted</strong>“) on
                        public areas of the Website, or transmitted to other
                        users of the Website or third parties (collectively, “
                        <strong>User Contributions</strong>“). Your User
                        Contributions are posted on and transmitted to others at
                        your own risk. Although you may set
                        certain&nbsp;privacy&nbsp;settings for your account
                        profile, please be aware that no security measures are
                        perfect or impenetrable. Additionally, we cannot control
                        the actions of other users of the Website with whom you
                        may choose to share your User Contributions. Therefore,
                        we cannot and do not guarantee that your User
                        Contributions will not be viewed by unauthorized
                        persons.
                      </p>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-1eca30f9 elementor-widget elementor-widget-heading"
                    data-id="1eca30f9"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Information We Collect Through Automatic Data Collection
                        Technologies
                      </h2>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-38ec0b8 elementor-widget elementor-widget-text-editor"
                    data-id="38ec0b8"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        As you navigate through and interact with our Website,
                        we may use automatic data collection technologies to
                        collect certain information about your equipment,
                        browsing actions, and patterns, including:
                      </p>
                      <ul>
                        <li>
                          Details of your visits to our Website, including
                          traffic data, location data, logs, and other
                          communication data and the resources that you access
                          and use on the Website.
                        </li>
                        <li>
                          Information about your computer and internet
                          connection, including your IP address, operating
                          system, and browser type.
                        </li>
                      </ul>
                      <p>
                        We also may use these technologies to collect
                        information about your online activities over time and
                        across third-party websites or other online services
                        (behavioral tracking).&nbsp;
                      </p>
                      <p>
                        The information we collect automatically is only
                        statistical data and does not include Personal
                        Information, but we may maintain it or associate it with
                        Personal Information we collect in other ways or receive
                        from third parties. It helps us to improve our Website
                        and to deliver a better and more personalized service,
                        including by enabling us to:
                      </p>
                      <ul>
                        <li>Estimate our audience size and usage patterns.</li>
                        <li>
                          Store information about your preferences, allowing us
                          to customize our Website according to your individual
                          interests.
                        </li>
                        <li>Speed up your searches.</li>
                        <li>Recognize you when you return to our Website.</li>
                      </ul>
                      <p>
                        The technologies we use for this automatic data
                        collection may include:
                      </p>
                      <ul>
                        <li>
                          <strong>Cookies (or browser cookies).</strong>
                          &nbsp;A cookie is a small file placed on the hard
                          drive of your computer. You may refuse to accept
                          browser cookies by activating the appropriate setting
                          on your browser. However, if you select this setting
                          you may be unable to access certain parts of our
                          Website. Unless you have adjusted your browser setting
                          so that it will refuse cookies, our system will issue
                          cookies when you direct your browser to our Website.
                        </li>
                      </ul>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-7c8b65a3 elementor-widget elementor-widget-text-editor"
                    data-id="7c8b65a3"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <ul>
                        <li>
                          <strong>Flash Cookies.</strong>&nbsp;Certain features
                          of our Website may use local stored objects (or Flash
                          cookies) to collect and store information about your
                          preferences and navigation to, from, and on our
                          Website. Flash cookies are not managed by the same
                          browser settings as are used for browser cookies.
                        </li>
                      </ul>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-61058b96 elementor-widget elementor-widget-text-editor"
                    data-id="61058b96"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <ul>
                        <li>
                          <strong>Web Beacons.</strong>&nbsp;Pages of our
                          Website
                          <a href="#">and our emails</a> may contain small
                          electronic files known as web beacons (also referred
                          to as clear gifs, pixel tags, and single-pixel gifs)
                          that permit the Company, for example, to count users
                          who have visited those pages or opened an email and
                          for other related website statistics (for example,
                          recording the popularity of certain website content
                          and verifying system and server integrity).
                        </li>
                      </ul>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-4af87f6e elementor-widget elementor-widget-text-editor"
                    data-id="4af87f6e"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        We do not collect Personal Information automatically,
                        but we may tie this information to Personal Information
                        about you that we collect from other sources or you
                        provide to us.
                      </p>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-3d06ab1d elementor-widget elementor-widget-heading"
                    data-id="3d06ab1d"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Third-Party Use of Cookies and Other Tracking
                        Technologies{" "}
                      </h2>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-3548853e elementor-widget elementor-widget-text-editor"
                    data-id="3548853e"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        Some content or applications, including advertisements,
                        on the Website are served by third parties, including
                        advertisers, ad networks and servers, content providers,
                        and application providers. These third parties may use
                        cookies alone or in conjunction with web beacons or
                        other tracking technologies to collect information about
                        you when you use our website. The information they
                        collect may be associated with your Personal Information
                        or they may collect information, including Personal
                        Information, about your online activities over time and
                        across different websites and other online services.
                        They may use this information to provide you with
                        interest-based (behavioral) advertising or other
                        targeted content.
                      </p>
                      <p>
                        We do not control these third parties’ tracking
                        technologies or how they may be used. If you have any
                        questions about an advertisement or other targeted
                        content, you should contact the responsible provider
                        directly.{" "}
                      </p>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-177cd868 elementor-widget elementor-widget-heading"
                    data-id="177cd868"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        How We Use Your Information
                      </h2>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-1aa623ae elementor-widget elementor-widget-text-editor"
                    data-id="1aa623ae"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        We use information that we collect about you or that you
                        provide to us, including any Personal Information:
                      </p>
                      <ul>
                        <li>To present our Website and its contents to you.</li>
                        <li>
                          To provide you with information, products, or services
                          that you request from us.
                        </li>
                        <li>
                          To fulfill any other purpose for which you provide it.
                        </li>
                        <li>To provide you with notices about your account.</li>
                        <li>
                          To carry out our obligations and enforce our rights
                          arising from any contracts entered into between you
                          and us.
                        </li>
                        <li>
                          To notify you about changes to our Website or any
                          products or services we offer or provide though it.
                        </li>
                        <li>
                          To allow you to participate in interactive features on
                          our Website.
                        </li>
                        <li>
                          To help maintain the safety, security, and integrity
                          of our Website, products and services, databases and
                          other technology assets, and business.
                        </li>
                        <li>
                          In any other way we may describe when you provide the
                          information.
                        </li>
                        <li>For any other purpose with your consent.</li>
                      </ul>
                      <p>
                        We may also use your information to contact you about
                        our own and third-parties’ goods and services that may
                        be of interest to you. If you do not want us to use your
                        information in this way, please make sure that
                        preference is selected during the account registration
                        process.
                      </p>
                      <p>
                        We may use the information we have collected from you to
                        enable us to display advertisements to our advertisers’
                        target audiences. Even though we do not disclose your
                        Personal Information for these purposes without your
                        consent, if you click on or otherwise interact with an
                        advertisement, the advertiser may assume that you meet
                        its target criteria.
                      </p>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-34849b2b elementor-widget elementor-widget-heading"
                    data-id="34849b2b"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Disclosure of Your Information
                      </h2>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-456a6991 elementor-widget elementor-widget-text-editor"
                    data-id="456a6991"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        <span className="font-400">
                          We may disclose aggregated information about our
                          users, and information that does not identify any
                          individual, without restriction.&nbsp;
                        </span>
                      </p>
                      <p>
                        <span className="font-400">
                          We may disclose Personal Information that we collect
                          or you provide as described in this Privacy
                          Policy:&nbsp;
                        </span>
                      </p>
                      <ul>
                        <li className="font-400" aria-level="1">
                          <span className="font-400">
                            To our subsidiaries and affiliates.&nbsp;
                          </span>
                        </li>
                        <li className="font-400" aria-level="1">
                          <span className="font-400">
                            To contractors, service providers, and other third
                            parties we use to support our business.&nbsp;
                          </span>
                        </li>
                        <li className="font-400" aria-level="1">
                          <span className="font-400">
                            To a buyer or other successor in the event of a
                            merger, divestiture, restructuring, reorganization,
                            dissolution, or other sale or transfer of some or
                            all of Company’s assets, whether as a going concern
                            or as part of bankruptcy, liquidation, or similar
                            proceeding, in which Personal Information held by
                            Company about our Website users is among the assets
                            transferred.&nbsp;
                          </span>
                        </li>
                        <li className="font-400" aria-level="1">
                          <span className="font-400">
                            To third parties to market their products or
                            services to you if you have consented to these
                            disclosures.&nbsp;
                          </span>
                        </li>
                        <li className="font-400" aria-level="1">
                          <span className="font-400">
                            To fulfill the purpose for which you provide it. For
                            example, if you give us an email address to use for
                            booking a session, we will transmit your email
                            address to the person leading that session.&nbsp;
                          </span>
                        </li>
                        <li className="font-400" aria-level="1">
                          <span className="font-400">
                            For any other purpose disclosed by us when you
                            provide the information.&nbsp;
                          </span>
                        </li>
                        <li className="font-400" aria-level="1">
                          <span className="font-400">
                            With your consent.&nbsp;
                          </span>
                        </li>
                      </ul>
                      <p>
                        <span className="font-400">
                          We may also disclose your Personal Information:&nbsp;
                        </span>
                      </p>
                      <ul>
                        <li className="font-400" aria-level="1">
                          <span className="font-400">
                            To comply with any court order, law, or legal
                            process, including to respond to any government or
                            regulatory request.&nbsp;
                          </span>
                        </li>
                        <li className="font-400" aria-level="1">
                          <span className="font-400">
                            To enforce or apply our terms of use
                          </span>
                          <a href="https://VerySoul.com/terms-conditions">
                            <span className="font-400">
                              {" "}
                              VerySoul.com/terms-conditions
                            </span>
                          </a>
                          <span className="font-400">
                            {" "}
                            and other agreements, including for billing and
                            collection purposes.&nbsp;
                          </span>
                        </li>
                        <li className="font-400" aria-level="1">
                          <span className="font-400">
                            If we believe disclosure is necessary or appropriate
                            to protect the rights, property, or safety of
                            VerySoul, our customers, or others. This includes
                            exchanging information with other companies and
                            organizations for the purposes of fraud protection
                            and credit risk reduction.
                          </span>
                        </li>
                      </ul>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-17156e2b elementor-widget elementor-widget-heading"
                    data-id="17156e2b"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Choices About How We Use and Disclose Your Information
                      </h2>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-5292f28b elementor-widget elementor-widget-text-editor"
                    data-id="5292f28b"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        We strive to provide you with choices regarding the
                        Personal Information you provide to us. We have created
                        mechanisms to provide you with the following control
                        over your information:
                      </p>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-791af5b7 elementor-widget elementor-widget-text-editor"
                    data-id="791af5b7"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <ul>
                        <li>
                          <strong>
                            Tracking Technologies and Advertising.
                          </strong>
                          &nbsp;You can set your browser to refuse all or some
                          browser cookies, or to alert you when cookies are
                          being sent. If you disable or refuse cookies, please
                          note that some parts of this site may then be
                          inaccessible or not function properly.
                        </li>
                      </ul>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-21dcb14d elementor-widget elementor-widget-text-editor"
                    data-id="21dcb14d"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <ul>
                        <li>
                          <strong>
                            Disclosure of Your Information for Third-Party
                            Advertising.&nbsp;
                          </strong>
                          We will not share your Personal Information with
                          unaffiliated or non-agent third parties for
                          promotional purposes unless you opt in to sharing that
                          information during the account registration process.
                          You can withdraw this permission by logging into the
                          Website and adjusting your user preferences in your
                          account profile or by sending us an email with your
                          request to{" "}
                          <a href="mailto:privacy@verysoul.com">
                            privacy@verysoul.com.
                          </a>
                        </li>
                      </ul>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-51b77938 elementor-widget elementor-widget-text-editor"
                    data-id="51b77938"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <ul>
                        <li>
                          <strong>
                            Promotional Offers from the Company.&nbsp;
                          </strong>
                          If you wish to allow the Company to use your contact
                          information to promote our own or third parties’
                          products or services, you can opt-in during the
                          account registration process. You can withdraw this
                          permission by logging into the Website and adjusting
                          your user preferences in your account profile or by
                          sending us an email stating your request to{" "}
                          <a href="mailto:privacy@verysoul.com">
                            privacy@verysoul.com.
                          </a>{" "}
                          If we have sent you a promotional email, you may send
                          us a return email asking to be omitted from future
                          email distributions.
                        </li>
                      </ul>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-62e4896e elementor-widget elementor-widget-text-editor"
                    data-id="62e4896e"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        {" "}
                        We do not control third parties’ collection or use of
                        your information to serve interest-based advertising.
                        However, these third parties may provide you with ways
                        to choose not to have your information collected or used
                        in this way. You can opt out of receiving targeted ads
                        from members of the Network Advertising Initiative (“
                        <strong>NAI</strong>”) on the NAI’s&nbsp;
                        <a href="https://optout.networkadvertising.org/?c=1">
                          https://optout.networkadvertising.org/?c=1.
                        </a>{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-347e0090 elementor-widget elementor-widget-text-editor"
                    data-id="347e0090"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        California residents may have additional Personal
                        Information rights and choices. Please see Your
                        California Privacy Rights for more information.
                      </p>
                      <p>
                        Nevada residents who wish to exercise their sale opt-out
                        rights under Nevada Revised Statutes Chapter 603A may
                        submit a request to
                        <strong>
                          <span className="text-underline">
                            {" "}
                            <a href="mailto:privacy@verysoul.com">
                              privacy@verysoul.com.
                            </a>
                          </span>
                        </strong>{" "}
                        However, please know we do not currently sell data
                        triggering that statute’s opt-out requirements.
                      </p>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-61b5b480 elementor-widget elementor-widget-heading"
                    data-id="61b5b480"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Accessing and Correcting Your Information
                      </h2>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-1448a805 elementor-widget elementor-widget-text-editor"
                    data-id="1448a805"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        You can review and change your Personal Information by
                        logging into the Website and visiting your account
                        profile page.
                      </p>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-52d3091e elementor-widget elementor-widget-text-editor"
                    data-id="52d3091e"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        You may also send us an email at{" "}
                        <strong>
                          <span className="text-underline">
                            <a href="mailto:privacy@verysoul.com">
                              privacy@verysoul.com{" "}
                            </a>
                          </span>
                        </strong>
                        to request access to, correct or delete any Personal
                        Information that you have provided to us. We cannot
                        delete your Personal Information except by also deleting
                        your user account. We may not accommodate a request to
                        change information if we believe the change would
                        violate any law or legal requirement or cause the
                        information to be incorrect.
                      </p>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-6eed90ac elementor-widget elementor-widget-text-editor"
                    data-id="6eed90ac"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        If you delete your User Contributions from the Website,
                        copies of your User Contributions may remain viewable in
                        cached and archived pages, or might have been copied or
                        stored by other Website users. Proper access and use of
                        information provided on the Website, including User
                        Contributions, is governed by our terms of use{" "}
                        <a href="https://verysoul.com/terms-conditions/">
                          VerySoul.com/terms-conditions
                        </a>
                        .
                      </p>
                      <p>
                        California residents may have additional Personal
                        Information rights and choices. Please see&nbsp;Your
                        California Privacy Rights for more information.
                      </p>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-1f570944 elementor-widget elementor-widget-heading"
                    data-id="1f570944"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Your California Privacy Rights
                      </h2>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-624ad1e6 elementor-widget elementor-widget-text-editor"
                    data-id="624ad1e6"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        We comply with all applicable privacy laws, including
                        the California Consumer Privacy Act.
                        <br />
                        <br />
                        If you are a California resident, California law may
                        provide you with additional rights regarding our use of
                        your Personal Information. If you have any questions,
                        please reach out to privacy@verysoul.com. We do not sell
                        your Personal Information.{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-2a9f0219 elementor-widget elementor-widget-spacer"
                    data-id="2a9f0219"
                    data-element_type="widget"
                    data-widget_type="spacer.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-spacer">
                        <div className="elementor-spacer-inner"></div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-7e11bcc5 elementor-widget elementor-widget-heading"
                    data-id="7e11bcc5"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Your Privacy Rights Outside of the United States
                      </h2>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-7e339cb3 elementor-widget elementor-widget-text-editor"
                    data-id="7e339cb3"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        If you are in a jurisdiction outside of the United
                        States with laws and regulations governing the
                        collection, use, and disclosure of personal data that
                        differ from the laws and regulations in the United
                        States, please be aware that the Website and servers are
                        located in the United States.
                      </p>
                      <p>
                        Information we collect in connection with the Website
                        will be processed and stored in the United States, or in
                        other countries where we, our service providers,
                        affiliates, or third-party partners have operations. If
                        you submit your personal data to the Website, you
                        expressly consent to having your personal data
                        transferred to, processed in, and stored in the United
                        States or another jurisdiction which offer a lesser
                        level of privacy protection as than that offered in the
                        country where you reside or are a citizen. If personal
                        data is transferred to a country that has not been
                        recognized as having an adequate level of data
                        protection, that transfer is governed by applicable law
                        or regulation, such as the European Commission’s
                        standard contractual clauses.
                      </p>
                      <p>
                        We will only use your information and process your
                        personal data in a lawful manner for a purpose described
                        in this Privacy Policy. Specifically, we will process
                        your personal data:
                      </p>
                      <ul>
                        <li>
                          As necessary to provide the services through the
                          Website;
                        </li>
                        <li>
                          Based on any consents you have provided to us, which
                          may be withdrawn by you at any time;{" "}
                        </li>
                        <li>
                          As necessary to comply with our obligations under the
                          law;
                        </li>
                        <li>
                          To protect your vitals interests or the vital
                          interests of another; and
                        </li>
                        <li>
                          As necessary for legitimate interests, unless those
                          interests are superseded by your individual interests,
                          fundamental rights, or freedoms.
                        </li>
                      </ul>
                      <p>
                        If you have any question about your Personal
                        Information, or if you which to exercise any of your
                        rights to your Personal Information, please reach out to
                        privacy@verysoul.com.
                      </p>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-69971f3b elementor-widget elementor-widget-heading"
                    data-id="69971f3b"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Data Security
                      </h2>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-5c05b6e8 elementor-widget elementor-widget-text-editor"
                    data-id="5c05b6e8"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        We have implemented measures designed to secure your
                        Personal Information from accidental loss and from
                        unauthorized access, use, alteration, and disclosure.
                        All information you provide to us is stored on our
                        secure servers behind firewalls. Any payment
                        transactions will occur through payment processors using
                        encrypted technology.
                      </p>
                      <p>
                        The safety and security of your information also depends
                        on you. Where we have given you (or where you have
                        chosen) a password for access to certain parts of our
                        Website, you are responsible for keeping this password
                        confidential. We ask you not to share your password with
                        anyone. We urge you to be careful about giving out
                        information in public areas of the Website like message
                        boards. The information you share in public areas may be
                        viewed by any user of the Website.
                      </p>
                      <p>
                        Unfortunately, the transmission of information via the
                        internet is not completely secure. Although we do our
                        best to protect your Personal Information, we cannot
                        guarantee the security of your Personal Information
                        transmitted to our Website. Any transmission of Personal
                        Information is at your own risk. We are not responsible
                        for circumvention of any&nbsp;privacy&nbsp;settings or
                        security measures contained on the Website.
                      </p>{" "}
                      <p>
                        (VerySoul’s) use and transfer of information received
                        from Google APIs to any other app will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy" target="_blank" >Google
                        API Services User Data Policy </a>, including the Limited Use
                        requirements.
                      </p>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-68f6ffda elementor-widget elementor-widget-heading"
                    data-id="68f6ffda"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Changes to Our&nbsp;Privacy&nbsp;Policy
                      </h2>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-101f64a9 elementor-widget elementor-widget-text-editor"
                    data-id="101f64a9"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        It is our&nbsp;policy&nbsp;to post any changes we make
                        to our&nbsp;Privacy&nbsp;Policy&nbsp;on this page with a
                        notice that the&nbsp;Privacy&nbsp;Policy&nbsp;has been
                        updated on the Website home page . If we make material
                        changes to how we treat our users’ Personal Information,
                        we will notify you by email to the primary email address
                        specified in your account and/or through a notice on the
                        Website home page. The date the Privacy Policy was last
                        revised is identified at the top of the page. You are
                        responsible for ensuring we have an up-to-date active
                        and deliverable email address for you, and for
                        periodically visiting our Website and this Privacy
                        Policy to check for any changes.
                      </p>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-6b59ff72 elementor-widget elementor-widget-heading"
                    data-id="6b59ff72"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Contact Information
                      </h2>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-6b6ffb41 elementor-widget elementor-widget-text-editor"
                    data-id="6b6ffb41"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        To ask questions or comment about
                        this&nbsp;Privacy&nbsp;Policy&nbsp;and
                        our&nbsp;privacy&nbsp;practices, contact us at{" "}
                        <strong>
                          <span className="text-underline">
                            <a href="mailto:privacy@verysoul.com">
                              privacy@verysoul.com.
                            </a>
                          </span>
                        </strong>
                      </p>
                      <p>
                        To register a complaint or concern, please reach out to:
                        <span style={{ textDecoration: "underline" }}>
                          <a href="mailto:concerns@verysoul.com">
                            concerns@verysoul.com
                          </a>
                        </span>
                        .
                      </p>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-34fa84e4 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="34fa84e4"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div
                className="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-67098d1c"
                data-id="67098d1c"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-49d07b97 elementor-widget elementor-widget-text-editor"
                    data-id="49d07b97"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p className="text-center">
                        <span className="font-400">
                          © 2024 Very Soul LLC. All rights reserved.
                        </span>
                      </p>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
}
