const shortcuts = [
  {
    id: 1,
    title: "Find Meeting Link",
    src: "./clientdashboardimages/1.png",
    alt: "card",
    url:'/appointments',
    type:'tab',
  },
  {
    id: 2,
    title: "Cancel/Change Booking",
    src: "./clientdashboardimages/2.png",
    alt: "card",
    url:'/appointments',
    type:'tab',
  },
  {
    id: 3,
    title: "Give Medium Feedback",
    src: "./clientdashboardimages/3.png",
    alt: "card",
    url:'/appointments',
    type:'tab',
  },
  {
    id: 4,
    title: "Pay My Medium",
    src: "./clientdashboardimages/4.png",
    alt: "card",
    url:'/appointments',
    type:'tab',
  },
  {
    id: 5,
    title: "Book New Session",
    src: "./clientdashboardimages/2.png",
    alt: "card",
    url:'/appointment',
    type:'tab',
  },
  {
    id: 6,
    title: "Get Help",
    src: "./clientdashboardimages/5.png",
    alt: "card",
    url:'https://support.verysoul.com/',
    type:'mail',
  },
  {
    id: 7,
    title: "Report Issue",
    src: "./clientdashboardimages/6.png",
    alt: "card",
    url:'mailto:concerns@verysoul.com',
    type:'mail',
  },
];

export { shortcuts };
