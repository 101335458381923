import React, { useEffect, useState } from "react";
// import "./toogle.css";
// import { changeProfessionalStatus } from "../../services/admin";

export default function Toggle({ openConfirmAppointment, item, setActiveUser }) {
    const [checked, setChecked] = useState(false);
    useEffect(() => {
        setChecked(item.confirm_appointment === 1 ? true : false)
    }, ["", item])
    const onChange = (e) => {
        e.stopPropagation();
        if (!checked) {
            console.log(checked);
            setActiveUser(item)
            openConfirmAppointment()
            //   setChecked(!checked);
        }
        else {

        }

    };
    return (
        <div class="toggle-button-cover">
            <div class={`button-cover ${item.color !== " " ? item.color : 'grey'}`}>
                <div class="button r" id="button-1" onClick={onChange}>
                    <input
                        type="checkbox"
                        checked={checked}
                        name="toggle"
                        class="checkbox"
                    // onChange={onChange}
                    />
                    <div class="knobs"></div>
                    <div class="layer"></div>
                </div>
            </div>
        </div>
    );
}
