import { setLoading, setUpAuthTrue } from "../reducer/auth/auth";
import { setGoOndemandStatus } from "../reducer/mediumSlice";
import { changeOnlineStatus } from "../reducer/userSlice";
import { logout } from "../services/auth";
import { setMediumOnlineStatus } from "../services/medium";

//change online status for mediums
const changeOnlineStatusFunc = (status, dispatch) => {
  let body = {
    status: status,
  };
  setMediumOnlineStatus(body)
    .then((data) => {
      if (data.data.status) {
        dispatch(changeOnlineStatus(status));
        dispatch(setGoOndemandStatus(false));
      }
    })
    .catch(() => {});
};

//logout user
const moveRoute = (dispatch, navigate, url = "/login") => {
  dispatch(setUpAuthTrue());
  localStorage.clear();
  navigate(url);
  window.location.reload();
};
const logoutUser = (dispatch, navigate, url) => {
  //   let url = "/thanks";
  changeOnlineStatusFunc(0, dispatch);
  dispatch(setLoading(true));
  logout()
    .then((data) => {
      dispatch(setLoading(false));
      if (data.data.status) {
        moveRoute(dispatch, navigate, url);
      }
    })
    .catch((err) => {
      dispatch(setLoading(false));
      console.log(err);
    });
};

export { changeOnlineStatusFunc, logoutUser };
