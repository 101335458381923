import React, { useEffect, useState } from "react";
import { initialValues } from "./data";
import "./calendar.css";
import Schedules from "./schedules";
import NewSessionPopup from "./newsessionpopup";
import AddNewSessions from "./addnewsessions";
import AssignSessionsByLevel from "./assignsessionsbylevel";
import { useSearchParams } from "react-router-dom";
import BackToSchedules from "./backtoschedules";
import {
  clearInitialValues,
  deletesubsessions,
  getSessions,
  getSessionTypes,
  updateLanguage,
  updateAssignSessionArray,
  updateCountries,
} from "./functions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setSessionFormInfo } from "../../../reducer/adminSlice";

export default function Calendar() {
  const dispatch = useDispatch();
  const { calendarSessions, sessionTypes, assignSessionArray } = useSelector(
    (state) => state.users
  );
  const { languagesArray, countryArray } = useSelector((state) => state.uidata);
  const [searchParams] = useSearchParams();
  const status_schedule = searchParams.get("schedule");
  const medium_id = searchParams.get("id");
  const [sessionList, setSessionList] = useState([]);
  const [sessionTypesList, setSessionTypesList] = useState([]);
  //const [sessionTypeModal, setSessionTypeModal] = useState(false);
  const [redirectType, setRedirectType] = useState("Sessions-list");
  const [formType, setFormType] = useState("");
  const [sessionId, setsessionId] = useState("");
  const [sessionName, setSessionName] = useState("");
  const [sessionRadio, setsessionRadio] = useState("");
  const [languages, setLanguages] = useState([]);
  const [countries, setCountries] = useState([]);
  const [scheduleId, setScheduleId] = useState("");
  const [assignSessionList, setassignSessionList] = useState({});

  //effects

  useEffect(() => {
    if (!calendarSessions.length) getSessions(dispatch);
    if (!sessionTypes.length) getSessionTypes(dispatch);
    if (!languagesArray.length) updateLanguage(dispatch);
    if (!assignSessionArray.length) updateAssignSessionArray(dispatch);
    if (!countryArray.length) updateCountries(dispatch);
  }, []);

  useEffect(() => {
    setSessionList(calendarSessions);
  }, [calendarSessions]);

  useEffect(() => {
    setassignSessionList(assignSessionArray);
  }, [assignSessionArray]);

  useEffect(() => {
    setLanguages(
      languagesArray.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      })
    );
  }, [languagesArray]);
  useEffect(() => {
    setCountries(
      countryArray.map((item) => {
        return {
          value: item.id,
          label: item.country_name,
        };
      })
    );
  }, [countryArray]);

  useEffect(() => {
    setSessionTypesList(calendarSessions);
  }, [calendarSessions]);

  const addSessionType = (event, name, ID, val) => {
    setsessionId(ID);
    setSessionName(name);
    setsessionRadio(String(val));
  };
 // const openSessionTypeModal = () => setSessionTypeModal(true);
  //const closeSessionTypeModal = () => setSessionTypeModal(false);
  const openSessionList = () => {
    dispatch(setSessionFormInfo({}));
    clearInitialValues(initialValues);
    setRedirectType("Sessions-list");
  };
  const openAssignSessionToMedium = () =>
    setRedirectType("Assign-session-by-Level");
  const openSchedules = () => {
    setRedirectType("schedules");
  };
  const openNewSessionForm = () => {
    setsessionId("");
    setFormType("Add new");
    setRedirectType("Add-new-session");
  };
  const openEditSessionForm = (ID) => {
    setsessionId(ID);
    setFormType("Edit");
    setRedirectType("Add-new-session");
  };
  const openScheduleForm = (id) => {
    console.log(id);
    if (id === "") {
      setScheduleId("");
    } else {
      setScheduleId(id);
    }
    setRedirectType("add-edit-schedule");
  };

 // const addnewsession = () => {};

  useEffect(() => {
    if (status_schedule === "schedule") openSchedules();
  }, []);
  const changeHandler = (e) => {
    console.log(e.target.value);
    console.log(e.target.name);
    if (e.target.name === "sessionRadio") {
      setsessionRadio(e.target.value);
    } else {
      setSessionName(e.target.value);
    }
  };
  return (
    <div>
      {redirectType === "Sessions-list" && (
        <div className="calendar-box-new">
          <div className="top-bar-clander-box">
            <div className="left-cont-clandr">
              <span>Choose Sessions / Groups</span>
            </div>
            <div className="right-cont-clandr">
              <button
                type="button"
                data-toggle="modal"
                data-target="#practiceModal"
                onClick={(e) => addSessionType(e)}
              >
                Add New
              </button>
              <button type="button" onClick={openNewSessionForm}>
                Add New Session
              </button>
              <button type="button" onClick={openAssignSessionToMedium}>
                Assign Session By Level
              </button>
            </div>
          </div>

          <div id="accordion">
            {sessionList.length > 0 &&
              sessionList.map((item) => (
                <div class="card">
                  <div class="card-header" id={`heading-${item.session_id}`}>
                    <h5 class="mb-0">
                      <a
                        class="collapsed"
                        role="button"
                        data-toggle="collapse"
                        href={`#collapse-${item.session_id}`}
                        aria-expanded="true"
                        aria-controls={`collapse-${item.session_id}`}
                      >
                        {""}
                        {item.session_name}{" "}
                      </a>
                    </h5>

                    <div className="popup-acordion">
                      <button
                        type="button"
                        class="btn btn-info btn-lg"
                        data-toggle="modal"
                        data-target="#practiceModal"
                        onClick={(e) =>
                          addSessionType(
                            e,
                            item.session_name,
                            item.session_id,
                            item.session_type
                          )
                        }
                      >
                        <img src="images/icon-feather-edit.png"></img>
                      </button>
                    </div>
                  </div>
                  <div
                    id={`collapse-${item.session_id}`}
                    class="collapse"
                    data-parent="#accordion"
                    aria-labelledby={`heading-${item.session_id}`}
                  >
                    <div class="card-body">
                      <div className="table-box-clander">
                        <table>
                          <tbody>
                            {item.sub_sessions !== undefined &&
                              item.sub_sessions.map((session) => (
                                <tr>
                                  <td>{session.sub_session_name}</td>
                                  <td>{session.duration} Minutes</td>
                                  <td>${session.pricing}.00</td>
                                  <td>
                                    <button
                                      onClick={() =>
                                        openEditSessionForm(
                                          session.sub_session_id
                                        )
                                      }
                                      className="tbl-edete"
                                    >
                                      <img src="images/icon-feather-edit.png"></img>
                                    </button>
                                    <button
                                      onClick={() =>
                                        deletesubsessions(
                                          session.sub_session_id,
                                          dispatch
                                        )
                                      }
                                      className="tbl-close"
                                    >
                                      <img src="images/close.png"></img>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <NewSessionPopup
            sessionRadio={sessionRadio}
            sessionName={sessionName}
            changeHandler={changeHandler}
            sessionId={sessionId}
            sessionList={sessionList}
          />
        </div>
      )}
      {redirectType === "Add-new-session" && (
        <AddNewSessions
          session_id={sessionId}
          formType={formType}
          sessionTypesList={sessionTypesList}
          openSessionList={openSessionList}
          setRedirectType={setRedirectType}
        />
      )}
      {redirectType === "Assign-session-by-Level" && (
        <AssignSessionsByLevel
          openSessionList={openSessionList}
          assignSessionList={assignSessionList}
        />
      )}
      {redirectType === "schedules" && (
        <Schedules
          type="admin"
          setRedirectType={setRedirectType}
          openScheduleForm={openScheduleForm}
          medium_id={medium_id}
          setScheduleId={setScheduleId}
        />
      )}
      {redirectType === "add-edit-schedule" && (
        <BackToSchedules
          openSchedules={openSchedules}
          medium_id={medium_id}
          schedule_id={scheduleId}
          languages={languages}
          countries={countries}
          type="admin"
        />
      )}
    </div>
  );
}
