import React from "react";

export default function AlredyJoined({ checkONdemandMessage}) {
    // const { id  } = useSelector((state) => state.user.user);

    return (
        <div>
            <div
                id="AlredyJoined"
                class="modal fade bd-example-modal-lg mt-5 preview-modal-availability"
                tabindex="-1"
                role="dialog"
                aria-labelledby="myLargeModalLabel"
                aria-hidden="true"
                data-backdrop="static"
            >
                <div class="modal-dialog modal-lg modal-dialog-preview text-center">
                    <div class="modal-content p-5">
                        <h3>
                            {/* You are already in another session<br /> and cannot join a new one until the current session concludes. */}

                        {checkONdemandMessage}
                        </h3>
                        <div id="msform">

                            <input
                                type="button"
                                name="Close"
                                class="next action-button m-3"
                                value="Close"
                                onClick={() => window.loadModal2('#AlredyJoined', "hide")}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
