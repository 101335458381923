import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { sendSurveyEmail, getSurveyLinks } from "../../../services/admin";
import { toast } from "react-toastify";

//component
import SurveyTable from "./table";
import { setAdmsurveyList } from "../../../reducer/adminSlice";

const initialValues = {
  mediumName: "",
  mediumEmail: "",
  clientName: "",
  clientEmail: "",
};

const Survey = (props) => {
  const { admsurveyList } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const [surveyLinks, setSurveyLinks] = useState([]);
  useEffect(() => {
    if (!admsurveyList.length > 0) getLink();
  }, []);

  const getLink = () => {
    getSurveyLinks()
      .then((data) => {
        if (data.data.status) {
          dispatch(setAdmsurveyList(data.data.data));
        }
      })
      .catch(() => {});
  };
  useEffect(() => {
    setSurveyLinks(admsurveyList);
  }, [admsurveyList]);

  const RunFunction = (formData) => {
    let body = {
      medium_name: formData.mediumName,
      medium_email: formData.mediumEmail,
      client_name: formData.clientName,
      client_email: formData.clientEmail,
      // survey_link
    };
    sendSurveyEmail(body)
      .then((data) => {
        if (data.data.status) {
          toast.success("Survey generated Succesfully", {
            position: "top-center",
          });
          getLink();
        }
      })
      .catch(() => {});
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          let errors = {};
          if (!values.mediumName) errors.mediumName = "Required";
          if (!values.clientName) errors.clientName = "Required";
          if (!values.mediumEmail) {
            errors.mediumEmail = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.mediumEmail)
          ) {
            errors.mediumEmail = "Invalid email address";
          }
          if (!values.clientEmail) {
            errors.clientEmail = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.clientEmail)
          ) {
            errors.clientEmail = "Invalid email address";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          RunFunction(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
        }) => (
          <form id="msform" onSubmit={handleSubmit}>
            <div className="from-inner-box">
              <div className="left-side">
                <label>Medium Name</label>
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.mediumName}
                  type="text"
                  id="mediumName"
                  name="mediumName"
                  placeholder="Medium Name"
                />
                <span className="error negative-margin-20-top">
                  {errors.mediumName && touched.mediumName && errors.mediumName}
                </span>
              </div>
              <div className="right-side">
                <label>Medium Email</label>
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.mediumEmail}
                  type="text"
                  id="mediumEmail"
                  name="mediumEmail"
                  placeholder="Medium Email"
                />
                <span className="error negative-margin-20-top">
                  {errors.mediumEmail &&
                    touched.mediumEmail &&
                    errors.mediumEmail}
                </span>
              </div>
              <div className="left-side">
                <label>Client Name</label>
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.clientName}
                  type="text"
                  id="clientName"
                  name="clientName"
                  placeholder="Client Name"
                />
                <span className="error negative-margin-20-top">
                  {errors.clientName && touched.clientName && errors.clientName}
                </span>
              </div>
              <div className="right-side">
                <label>Client Email</label>
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.clientEmail}
                  type="text"
                  id="clientEmail"
                  name="clientEmail"
                  placeholder="Client Email"
                />
                <span className="error negative-margin-20-top">
                  {errors.clientEmail &&
                    touched.clientEmail &&
                    errors.clientEmail}
                </span>
              </div>
            </div>
            <button type="submit" className="next action-button">
              Submit
            </button>
          </form>
        )}
      </Formik>
      <SurveyTable surveyLinks={surveyLinks} />
    </>
  );
};
export default Survey;
