import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

//reducer

import Weekdays from "../client/appointment/advanceappointment/calendar/weekdays";
import { getDateCount } from "../../services/client";
// import Slots from "../slots";
// import { getSlotsCountByDate } from "../../../../../services/client";
// import { setLoadin, setLoadingContent, setLoadingText } from "../../../../../reducer/auth/auth";
import { removFirstDash } from "../../utils/helpers";
import Slots from "./slots";
// import { useSearchParams } from "react-router-dom";

export default function BookingCalendar({
  getDatesCount,
  timeZoneValue,
  dateCount,
  activeSubSessionId,
  getTimeSlots,
  slots,
  setSlots,
  setBookingDate,
  setBookingTime,
  slotsupdated,
  setSlotsUpdated,
  active,
  noSlotsFound,
  setnoSlotsFound,
  month_empty
}) {
  //hooks
  const dispatch = useDispatch();
  //   const { id, timezone } = useSelector((state) => state.user.user);
  //   const auth = useSelector((state) => state.auth);

  //   const [params] = useSearchParams();
  const [monthYear, setMonthYear] = useState([]);
  const [dateObject, setdateObject] = useState(moment());

  const [selectedDay, setselectedDay] = useState(null);
  const [clickedData, setclickedData] = useState();
  //   const [slots, setSlots] = useState({});
  const [clickedDate, setclickedDate] = useState();

  //   ///
  const [oldStartDate, setOldStartDate] = useState('');
  const [oldEndDate, setOldEndDate] = useState('');
  const [backupDates, setBackpUdates] = useState();
  //   const [activeMonth, setActiveMonth] = useState(moment().month());
  //   //
  const firstDayOfMonth = () => {
    let firstDay = moment(dateObject)
      .startOf("month")
      .format("d");
    return firstDay;
  };
  const lastDayOfMonth = () => {
    let lastDay = moment(dateObject)
      .endOf("month")
      .format("d");
    return lastDay;
  };
  const getTodaysDate = (day) => {
    var mydate = `${day}-${month()}-${year()}`;
    return removFirstDash(moment(mydate).format("YYYY-MM-DD"));
  };
  const daysInMonth = () => {
    return dateObject.daysInMonth();
  };
  const year = () => {
    return dateObject.format("Y");
  };
  const month = () => {
    return dateObject.format("MMMM");
  };
  const shortmonth = () => {
    return dateObject.format("MMM");
  };
  const currentDay = () => {
    return dateObject.format("D");
  };
  const currentMonth = () => {
    return moment().format("MMM");
  };
  const getWeekDayName = (d, monthName, yearName) => {
    var mydate = `${d}-${monthName}-${yearName}`;
    var weekDayName = moment(mydate).format("dddd");
    return weekDayName;
  };
  const changeDateFormat = (d) => {
    let monthName = month();
    let yearName = year();
    var mydate = `${d}-${monthName}-${yearName}`;
    var date = moment(mydate).format("YYYY-MM-DD");
    return date;
  };

  const onPrev = () => {
    setnoSlotsFound(false);
    let name = dateObject.month();
    let currentMonth = moment().month();
    let yearname = moment().year();
    let yearN = dateObject.year();
    console.log(yearname, yearN)
    console.log(name, "name")
    console.log(currentMonth, "currentMonth")
    if (name <= currentMonth && yearname === yearN) {
      return;
    }
    setSlotsUpdated(false);
    setclickedData();
    let dataes;

    let curr = "month";
    setdateObject(dateObject.subtract(1, curr));

    // console.log(name - 1, "name")
    // console.log(currentMonth, "currentMonth"  )
    if (name - 1 === currentMonth) {

      const endOfMonth = moment().endOf('month').format('DD');
      const startOfMonth = moment().startOf('month').format('DD');
      const presentOfMonth = moment().format('DD');
      let endDate;
      // if (presentOfMonth <= 15) {
      //   endDate = moment().add('days', 15).format("YYYY-MM-DD");
      //   setOldEndDate(moment().add('days', 15).format("DD"));
      //   window.oldEndDate = moment().add('days', 15).format("DD")
      // }
      // else {
      endDate = moment().add('days', parseInt(endOfMonth - presentOfMonth)).format("YYYY-MM-DD");
      setOldEndDate(moment().add('days', parseInt(endOfMonth - presentOfMonth)).format("DD"))
      window.oldEndDate = moment().add('days', parseInt(endOfMonth - presentOfMonth)).format("DD")
      // }
      dataes = {
        start: moment().format("YYYY-MM-DD"),
        end: endDate
      }

      setOldStartDate(moment().format("DD"))
      window.oldStartDate = moment().format("DD")
    }
    else {
      dataes = {
        start: moment(`${1}-${month()}-${year()}`).format("YYYY-MM-DD"),
        // end: moment(`${15}-${month()}-${year()}`).format("YYYY-MM-DD"),
        end: moment(dateObject)
          .endOf("month")
          .format("YYYY-MM-DD")
      };
      setOldStartDate(1)
      window.oldStartDate = 1
      setOldEndDate(moment(dateObject)
        .endOf("month")
        .format("YYYY-MM-DD"))
      window.oldEndDate = moment(dateObject)
        .endOf("month").format("DD")


    }
    setBackpUdates(dataes)
    getDatesCount(dataes);
  };
  const onNext = () => {
    setnoSlotsFound(false);
    setSlotsUpdated(false);

    setclickedData();
    let curr = "month";
    setdateObject(dateObject.add(1, curr));
    let dataes = {
      start: moment(dateObject)
        .startOf("month")
        .format("YYYY-MM-DD"),
      end: moment(dateObject)
        .endOf("month")
        .format("YYYY-MM-DD")
      // end: moment(`${15}-${month()}-${year()}`).format("YYYY-MM-DD"),
    };

    setOldStartDate(moment(dateObject).startOf("month").format("DD"))
    window.oldStartDate = moment(dateObject).startOf("month").format("DD")

    setOldEndDate(moment(dateObject)
      .endOf("month")
      .format("YYYY-MM-DD"));
    window.oldEndDate = moment(dateObject)
      .endOf("month")
      .format("YYYY-MM-DD")
    // setOldEndDate(moment(`${15}-${month()}-${year()}`).format("DD"));
    // window.oldEndDate = moment(`${15}-${month()}-${year()}`).format("DD")
    setBackpUdates(dataes)
    getDatesCount(dataes);
  };

  const getClickedDate = (d) => {
    let monthName = shortmonth();
    let yearName = year();
    let weekN = getWeekDayName(d, monthName, yearName);
    return `${weekN}, ${monthName} ${d}`;
  };

  const onDayClick = (e, d, todayDate, clicked) => {
    let date = moment(`${d}-${month()}-${year()}`).format("YYYY-MM-DD");
    getTimeSlots(date, d);
    // console.log(e, d, todayDate, clicked, 'clicked', month(), year())
    // if (d !== window.clickedDates) {
    //   setSlotsUpdated(false);
    // }
    window.clickedDates = d
    // setclickedDate(d)
    Array.from(document.querySelectorAll(".td-clicked")).forEach(function (el) {
      el.classList.remove("td-clicked");
    });
    let clickedDate = window.clickedDate;
    const endOfMonth = moment(dateObject).endOf('month').format('DD');

    document.getElementById(d).className = "td-clicked";
    setclickedData(d);
    // dispatch(setBookingDate(changeDateFormat(d)));
    setselectedDay(getClickedDate(d));
    console.log(d, parseInt(window.oldEndDate), "end", endOfMonth, ",lastDayOfMonth()")
    // if (d > parseInt(window.oldEndDate)) {
    //   let start = parseInt(window.oldStartDate);
    //   let dataes = {
    //     start: moment(`${16}-${month()}-${year()}`).format("YYYY-MM-DD"),
    //     end: moment(`${parseInt(endOfMonth)}-${month()}-${year()}`).format("YYYY-MM-DD")
    //   };

    //   setOldStartDate(moment(`${16}-${month()}-${year()}`).format("DD"))
    //   window.oldStartDate = moment(`${16}-${month()}-${year()}`).format("DD")

    //   setOldEndDate(moment(`${parseInt(endOfMonth)}-${month()}-${year()}`).format("DD"));
    //   window.oldEndDate = moment(`${parseInt(endOfMonth)}-${month()}-${year()}`).format("DD")
    //   getDatesCount(dataes);
    // }
    // if (d < 16 && parseInt(window.oldEndDate) > 15) {
    //   let dataes = {
    //     start: moment(`${1}-${month()}-${year()}`).format("YYYY-MM-DD"),
    //     end: moment(`${15}-${month()}-${year()}`).format("YYYY-MM-DD")
    //   };

    //   setOldStartDate(moment(`${1}-${month()}-${year()}`).format("DD"))
    //   window.oldStartDate = moment(`${1}-${month()}-${year()}`).format("DD")

    //   setOldEndDate(moment(`${15}-${month()}-${year()}`).format("DD"));
    //   window.oldEndDate = moment(`${15}-${month()}-${year()}`).format("DD")
    //   getDatesCount(dataes);
    // }
    getTimeSlots(date, d);
  };

  const showCount = (slot, date) => {
    return slot[date];
  };

  const checkCalendarType = (d, currentDays, todayDate, otherDate, clicked) => {
    let status = false;

    if (d >= parseInt(window.oldStartDate) && d <= parseInt(window.oldEndDate)) {
      status = true
    }
    else {
      status = false
    }

    return status;
  };
  const fillTbale = (slot, clicked) => {
    let blanks = [];
    let rows = [];
    let cells = [];

    for (let i = 0; i < firstDayOfMonth(); i++) {
      blanks.push(<td className="calendar-day  empty">{""}</td>);
    }
    let daysInMonthList = [];
    for (let d = 1; d <= daysInMonth(); d++) {
      let date = getTodaysDate(d);
      let currentDays = d === currentDay() ? "today" : "";
      let todayDate = moment().format("YYYY-MM-DD");
      let otherDate = moment(`${d}-${month()}-${year()}`).format("YYYY-MM-DD");
      let circle = clicked === d ? "td-clicked" : "";
      if (d === currentDay()) {
        setselectedDay(getClickedDate(d));
      }
      daysInMonthList.push(
        <td
          onClick={
            !moment(otherDate).isBefore(todayDate)
              ? (e) => onDayClick(e, d, todayDate, clicked)
              : () => {
                console.log("disabled");
              }
          }
          key={d}
          className={ `${shortmonth() === currentMonth() ? '' : 'bg-pointer-selected'} date-picker calendar-td pointer ${checkCalendarType(d, currentDays, todayDate, otherDate, clicked)
            ? "bg-pointer-selected"
            : ""
            } ${!moment(otherDate).isBefore(todayDate) ? "" : "disabled-date"}`}
        >
          <div className={`pointer  ${circle}`}>
            {slot !== undefined &&
              showCount(slot, date) !== 0 &&
              showCount(slot, date) !== undefined ? (
              <p className="orange-bulb">{showCount(slot, date)} </p>
            ) : (
              <></>
            )}
            <span
              id={d}
              className={`pointer ${!moment(otherDate).isBefore(todayDate) ? "" : "disabled-date"
                } ${currentDays ? "selected" : ""}
             ${parseInt(currentDay()) === parseInt(d) &&
                  shortmonth() === currentMonth()
                  ? "td-clicked-active-date"
                  : ""
                }`}
            >
              {d}
            </span>
          </div>
        </td>
      );
    }

    var totalSlots = [...blanks, ...daysInMonthList];
    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
      if (i === totalSlots.length - 1) {
        rows.push(cells);
      }
    });

    let daysinmonth = rows.map((d, i) => {
      return <tr>{d}</tr>;
    });
    setMonthYear(daysinmonth);
    const div = document.getElementsByClassName("td-clicked");
    if (div[0] !== undefined) {
      div[0].className = "";
    }
  };
  useEffect(() => {
    if (timeZoneValue !== "") {
      let presentDay = moment().date();
      let dataes;
      const endOfMonth = moment().endOf('month').format('DD');
      const startOfMonth = moment().startOf('month').format('DD');
      const presentOfMonth = moment().format('DD');
      let endDate;
      // if (presentOfMonth <= 15) {
      //   endDate = moment().add('days', 15).format("YYYY-MM-DD");
      //   setOldEndDate(moment().add('days', 15).format("DD"));
      //   window.oldEndDate = moment().add('days', 15).format("DD")
      // }
      // else {
      endDate = moment().add('days', parseInt(endOfMonth - presentOfMonth)).format("YYYY-MM-DD");
      setOldEndDate(moment().add('days', parseInt(endOfMonth - presentOfMonth)).format("DD"))
      window.oldEndDate = moment().add('days', parseInt(endOfMonth - presentOfMonth)).format("DD")
      // }
      if (backupDates !== undefined) {
        dataes = backupDates
      }
      else {

        dataes = {
          start: moment().format("YYYY-MM-DD"),
          end: endDate
        }
        setBackpUdates(dataes);
      }
      setOldStartDate(moment().format("DD"))
      window.oldStartDate = moment().format("DD")
      setBackpUdates(dataes);
      getDatesCount(dataes);
    }
  }, [timeZoneValue, activeSubSessionId]);
  //   }, [timezone, activeSubSessionId]);
  useEffect(() => {
    fillTbale(dateCount, '')
  }, [dateCount])


  return (
    <>
      <div className="col-md-12">
        <div className="">
          <p>Step 1: Select Service</p>
          <p>Step 2: Find and select a date with availability. (Availability will be indicated with a number inside an orange dot.)</p>
          <p>Step 3: Choose one of the start times listed below.</p>
          <p>Step 4: Select “Book Appointment” (at the bottom) to move on to complete booking your session .</p>
        </div>
        <h6>Note: You can view up to 30 days at a time. Click on dates into the future for more dates and times.</h6>
        <div className="page-heading">
        </div>
        <div className="calenl-scn">
          <div className="wrapper">
            <div className="container-calendar padding-unset">
              <h3 id="monthAndYear">
                {month()}&nbsp;
                {year()}
              </h3>
              <div className="button-container-calendar">
                <button id="previous" onClick={onPrev}>
                  <img src="/images/black-erow.png" alt="previous" />
                </button>
                <button id="next" onClick={onNext}>
                  <img src="/images/right-ereow.png" alt="next" />
                </button>
              </div>

              <table className="table-calendar" id="calendar" data-lang="en">
                <thead id="thead-month">
                  <Weekdays />
                </thead>
                <tbody id="calendar-body">{monthYear}</tbody>
              </table>
            </div>
          </div>

          <div className="tim-clander-shedul padding-unset">
            {slotsupdated && (
              <>
                <h4 className="mt-5 font-size-24"><b>{selectedDay}</b></h4>
              </>
            )}
            {noSlotsFound &&
              <>
                <h4 className="mt-5 font-size-24"><b>{selectedDay}</b></h4>
                <br />
                <h4 className="mt-2 mb-5 font-size-18 line-height-24"> No appointments available for this date.<br />Check other dates or months.

                </h4>
              </>
            }

            {month_empty &&
              <h4 className="mt-5 mb-5 font-size-18 line-height-24"> Sorry no appointments available for this month.
                <br />Please check future months.

              </h4>

            }
            <div className="d-flex align-items-center flex-wrap mt-4 ml-2">
              <Slots
                slots={slots}
                setSlots={setSlots}
                setBookingTime={setBookingTime}
                sloteupdate={slotsupdated}
                active={active}
              // sessionType={sessionType}
              // practitionerInfo={practitionerInfo}
              // activesession_id={activesession_id}
              // mediumItems={mediumItems}
              // user_type={user_type}
              // setSlotsUpdated={setSlotsUpdated}
              // slotsupdated={slotsupdated}
              // activeSubSessionId={activeSubSessionId}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
