import React, { useEffect, useState } from 'react'
import { onDemandHistory, deleteonDemandHistory, deleteAllOnDemandHistory } from '../../../services/admin';
import { toast } from "react-toastify";
import Button from "../../../components/button";
import Pusher from 'pusher-js';
import Pagination from '../../../components/tablePagination';
import { TablePagination } from '@mui/material';

export default function OnDemandHistory() {
    const listHeading = ["User ID", 'Screen Name', 'Email', ' Event', 'Date Time Added', 'Date Out', 'Selected Preference', 'Paired With', "Appointment ID", 'Action'];
    const [onDemandHistoryList, setOnDemandHistoryList] = useState([])

    useEffect(() => {
        onDemandHistory().then((data) => {
            setOnDemandHistoryList(data.data.data)
            const start = (page - 1) * rowsPerPage;
            const end = page * rowsPerPage;
            setDataToDisplay(data.data.data.slice(start, end));
        }).catch(() => { })
    }, [])
    const handleDelete = (id) => {
        deleteonDemandHistory(id)
            .then((data) => {
                // Remove the deleted item from the state
                console.log(data.data)
                if (data.data.data == true) {
                    toast.success(data.data.success, {
                        position: "top-center",
                    });
                    setOnDemandHistoryList(prevList =>
                        prevList.filter(item => item.id !== id)
                    );
                } else {
                    toast.error("Something went wrong", {
                        position: "top-center",
                    });
                }
            })
            .catch((error) => {
                console.error('Error deleting record:', error);
                toast.error("Something went wrong", {
                    position: "top-center",
                });
            });
    };
    useEffect(() => {

        Pusher.logToConsole = true;
        var pusher = new Pusher("823ab72ed6ecd563b8c8", {
            cluster: "ap2",
        });

        var channel = pusher.subscribe("on-demand-queue");
        channel.bind("App\\Events\\OnDemandQueue", function (data) {
            // if (data.users.length) {
            onDemandHistory().then((data) => {
                setOnDemandHistoryList(data.data.data)
                const start = (page - 1) * rowsPerPage;
                const end = page * rowsPerPage;
                setDataToDisplay(data.data.data.slice(start, end));
            }).catch(() => { })
            // }


        });

    }, [])

    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [dataToDisplay, setDataToDisplay] = useState([]);

    const handleChangePage = (
        event,
        newPage
    ) => {
        console.log(newPage, "event")
        setPage(newPage + 1);
    };

    const handleChangeRowsPerPage = (
        event
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };
    useEffect(() => {
        const start = (page - 1) * rowsPerPage;
        const end = page * rowsPerPage;
        setDataToDisplay(onDemandHistoryList.slice(start, end));
    }, [page, rowsPerPage]);

    const deleteAll = () => {
        deleteAllOnDemandHistory().then((data) => {
            if(data.data.data){
                onDemandHistory().then((data) => {
                    setOnDemandHistoryList(data.data.data)
                    const start = (page - 1) * rowsPerPage;
                    const end = page * rowsPerPage;
                    setDataToDisplay(data.data.data.slice(start, end));
                }).catch(() => { })
                toast.success('Deleted successfully', { position: 'top-center' });
            }
            else{
                toast.success('Problem while deleting history', { position: 'top-center' });
            }

        }).catch(() => { })
    }
    return (
        <div className="table-mideum-table tableclander">
            <div className='text-right mb-4'>
                <Button
                    status={0}
                    type="button"
                    onClick={deleteAll}
                    className={`resend-btn  min-width-210 create-app-sm-screen`}
                    title="Delete All"
                />
            </div>
            <table>
                <tbody>
                    <tr>
                        {listHeading.map((item) => (
                            <th>{item}</th>
                        ))}
                    </tr>


                    {dataToDisplay.length > 0 && dataToDisplay.map((item) => <tr>
                        <td className="padd-15">{item.user_id}</td>

                        <td className="padd-15">{item.user_name}</td>
                        <td className="padd-15">{item.email}</td>
                        <td className="padd-15">{item.event_name}</td>
                        <td className="padd-15">{item.date_added}</td>
                        <td className="padd-15">{item.date_out}</td>
                        <td className="padd-15">{item.selected_preference}</td>
                        <td className="padd-15">{item.paired_with ? item.paired_with : "-"}</td>
                        <td className="padd-15">{item.appointment_id ? item.appointment_id : "-"}</td>
                        <td className="padd-15">
                            <Button
                                status={0}
                                onClick={(e) => handleDelete(item.id)}
                                type="button"
                                className={`resend-btn w-130`}
                                title="Delete"
                            />

                        </td>
                    </tr>)}
                </tbody>
            </table>
            <div className="">
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    count={onDemandHistoryList.length}
                    page={page - 1}
                    //  labelDisplayedRows={labelDisplayedRows}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    )
}


