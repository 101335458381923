import React from 'react'
import VerificationSteps from '../booking/verificationsteps';
import Topbar from '../../../components/topbar/topbar';
import Footer from '../../client/appointment/footer';

export default function Onboarding() {
    return (
        <>
            <Topbar />


            <div className="practice-booking-step-container">

                <VerificationSteps /></div>

            <div className="text-center pb-5 mt-5 pt-5 padding-botton-120px">
                <Footer />
            </div>
        </>
    );
}
