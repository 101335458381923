export const slotsArray = [
  { mtime: "12:00", time: "12:00 AM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "12:15", time: "12:15 AM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "12:30", time: "12:30 AM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "12:45", time: "12:45 AM", status: false, selectedStatus: false ,timeStatus:false },


  { mtime: "01:00", time: "01:00 AM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "01:15", time: "01:15 AM", status: false, selectedStatus: false ,timeStatus:false },

  { mtime: "01:30", time: "01:30 AM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "01:45", time: "01:45 AM", status: false, selectedStatus: false ,timeStatus:false },

  { mtime: "02:00", time: "02:00 AM", status: false, selectedStatus: false ,timeStatus:false },

  { mtime: "02:15", time: "02:15 AM", status: false, selectedStatus: false ,timeStatus:false },

  { mtime: "02:30", time: "02:30 AM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "02:45", time: "02:45 AM", status: false, selectedStatus: false ,timeStatus:false },

  { mtime: "03:00", time: "03:00 AM", status: false, selectedStatus: false ,timeStatus:false },

  { mtime: "03:15", time: "03:15 AM", status: false, selectedStatus: false ,timeStatus:false },

  { mtime: "03:30", time: "03:30 AM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "03:45", time: "03:45 AM", status: false, selectedStatus: false ,timeStatus:false },

  { mtime: "04:00", time: "04:00 AM", status: false, selectedStatus: false ,timeStatus:false },

  { mtime: "04:15", time: "04:15 AM", status: false, selectedStatus: false ,timeStatus:false },

  { mtime: "04:30", time: "04:30 AM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "04:45", time: "04:45 AM", status: false, selectedStatus: false ,timeStatus:false },

  { mtime: "05:00", time: "05:00 AM", status: false, selectedStatus: false ,timeStatus:false },

  { mtime: "05:15", time: "05:15 AM", status: false, selectedStatus: false ,timeStatus:false },

  { mtime: "05:30", time: "05:30 AM", status: false, selectedStatus: false ,timeStatus:false },

  { mtime: "06:00", time: "06:00 AM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "06:15", time: "06:15 AM", status: false, selectedStatus: false ,timeStatus:false },

  { mtime: "06:30", time: "06:30 AM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "07:00", time: "07:00 AM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "07:15", time: "07:15 AM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "07:30", time: "07:30 AM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "08:00", time: "08:00 AM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "08:15", time: "08:15 AM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "08:30", time: "08:30 AM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "08:45", time: "08:45 AM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "09:00", time: "09:00 AM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "09:15", time: "09:15 AM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "09:30", time: "09:30 AM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "09:45", time: "09:45 AM", status: false, selectedStatus: false ,timeStatus:false },


  { mtime: "10:00", time: "10:00 AM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "10:15", time: "10:15 AM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "10:30", time: "10:30 AM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "10:45", time: "10:45 AM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "11:00", time: "11:00 AM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "11:15", time: "11:15 AM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "11:30", time: "11:30 AM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "11:45", time: "11:45 AM", status: false, selectedStatus: false ,timeStatus:false },

  { mtime: "12:00", time: "12:00 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "12:15", time: "12:15 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "12:30", time: "12:30 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "12:45", time: "12:45 PM", status: false, selectedStatus: false ,timeStatus:false },

  { mtime: "01:00", time: "01:00 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "01:15", time: "01:15 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "01:30", time: "01:30 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "01:45", time: "01:45 PM", status: false, selectedStatus: false ,timeStatus:false },

  { mtime: "02:00", time: "02:00 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "02:15", time: "02:15 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "02:30", time: "02:30 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "02:45", time: "02:45 PM", status: false, selectedStatus: false ,timeStatus:false },

  { mtime: "03:00", time: "03:00 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "03:15", time: "03:15 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "03:30", time: "03:30 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "03:45", time: "03:45 PM", status: false, selectedStatus: false ,timeStatus:false },

  { mtime: "04:00", time: "04:00 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "04:15", time: "04:15 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "04:30", time: "04:30 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "04:45", time: "04:45 PM", status: false, selectedStatus: false ,timeStatus:false },

  { mtime: "05:00", time: "05:00 PM", status: false, selectedStatus: false ,timeStatus:false },

  { mtime: "05:15", time: "05:15 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "05:30", time: "05:30 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "05:45", time: "05:45 PM", status: false, selectedStatus: false ,timeStatus:false },

  { mtime: "06:00", time: "06:00 PM", status: false, selectedStatus: false ,timeStatus:false },

  { mtime: "06:15", time: "06:15 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "06:30", time: "06:30 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "06:45", time: "06:45 PM", status: false, selectedStatus: false ,timeStatus:false },

  { mtime: "07:00", time: "07:00 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "07:15", time: "07:15 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "07:30", time: "07:30 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "07:45", time: "07:45 PM", status: false, selectedStatus: false ,timeStatus:false },

  { mtime: "08:00", time: "08:00 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "08:15", time: "08:15 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "08:30", time: "08:30 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "08:45", time: "08:45 PM", status: false, selectedStatus: false ,timeStatus:false },

  { mtime: "09:00", time: "09:00 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "09:15", time: "09:15 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "09:30", time: "09:30 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "09:45", time: "09:45 PM", status: false, selectedStatus: false ,timeStatus:false },


  { mtime: "10:00", time: "10:00 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "10:15", time: "10:15 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "10:30", time: "10:30 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "10:45", time: "10:45 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "11:00", time: "11:00 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "11:15", time: "11:15 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "11:30", time: "11:30 PM", status: false, selectedStatus: false ,timeStatus:false },
  { mtime: "11:45", time: "11:45 PM", status: false, selectedStatus: false ,timeStatus:false },
  // { time: "08:00 PM", status: false },
  // { time: "09:00 PM", status: false },
  // { time: "10:00 PM", status: false },
  // { time: "11:00 PM", status: false },
  // { time: "12:00 AM", status: false },
  // { time: "01:00 AM", status: false },
  // { time: "02:00 AM", status: false },
  // { time: "03:00 AM", status: false },
  // { time: "04:00 AM", status: false },
  // { time: "05:00 AM", status: false },
  // { time: "06:00 AM", status: false },
  // { time: "07:00 AM", status: false },
  // { time: "08:00 AM", status: false },
  // { time: "09:00 AM", status: false },
];
