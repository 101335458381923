import Vimeo from '@u-wave/react-vimeo'
import React, { useEffect, useState } from 'react'
import { FadeLoader } from 'react-spinners'
import Button from '../../../../components/button'
import { Link, useNavigate } from 'react-router-dom'
import TickMark from '../../../../components/tickmark'
import { saveStepsProgress, verifyZoom } from '../../../../services/medium'
import { useDispatch, useSelector } from 'react-redux'
import { getZoomAuthUrl } from '../../../../services/uidata'
import { setLoading } from '../../../../reducer/auth/auth'

export default function StepOne({ NextStep,innerWidthHandler }) {
    const { id } = useSelector((state) => state.user.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [showLoader, setShowLoader] = useState(true);
    const [verificationStatus, setVerificationStatus] = useState(0);
    const [verifyText, setVerifyText] = useState('');

    const openAppointMent = () => {
        window.open("/integration", "_blank", "noreferrer")
    }
    function getPathFromUrl(url) {
        const newUrl = new URL(window.location.href);
        newUrl.searchParams.delete("status");
        newUrl.searchParams.delete("message");
        window.history.replaceState({}, document.title, newUrl.href);
    }
    useEffect(() => {
        getPathFromUrl();
        setTimeout(() => {
            verifyZoomHandler()

        }, 5000)
    }, [])

    const verifyZoomHandler = () => {
        let body = {
            medium_id: id
        }
        verifyZoom(body).then((data) => {
            console.log(data)
            if (data.data.status) {
                setVerifyText(data.data.message)
                setVerificationStatus(1);
                setShowLoader(false);
            }
            else {
                setVerifyText(data.data.message)
                setVerificationStatus(2);
                setShowLoader(false);
            }


        })
    }
    const getAuthUrl = () => {
        dispatch(setLoading(true));
        getZoomAuthUrl("onboarding")
            .then((data) => {
                dispatch(setLoading(false));
                if (data.data.status) {
                    window.open(data.data.data.redirect_url, "_self");
                }
            })
            .catch((err) => {
                dispatch(setLoading(false));
                console.log(err);
            });
    };

    const savestep = () => {
        let body = {
            step_number: 1
        }
        saveStepsProgress(body).then((data) => {
            if (data.data.status) {
                NextStep()
                localStorage.setItem('step',1)
            }
        }).catch(() => {

        })
    }
    return (
        <div>

            <div className='text-left  pl-5 pr-5'>

                <h3 className='font-size-40'>Connect Your Zoom</h3>
                <ol className='font-size-18 line-height-24px'>
                    <li>Watch the video below.</li>
                    <li>Connect your Zoom account.</li>
                </ol>
                {/* <p className='font-size-18 line-height-24px'>When your Zoom account is successfully connected with our system,
                    it then allows us to create Zoom links and send them for you to your session Partners or Clients.</p> */}


            </div>
            <div>
                
                <Vimeo
                    muted={true}
                    autoplay={false}
                    // className="popover-video"
                    width={innerWidthHandler()}
                    height={330}
                    video={"https://vimeo.com/922691222/31abba4ecc"}
                />
            </div>
            <br />
            {showLoader && <div className='justify-content-center d-flex align-items-center'>
                <span className='mr-5'> Please wait we are verifying your zoom...</span>
                <FadeLoader size={50} color='#d5a67b' />


            </div>}
            {verificationStatus === 1 &&
                <div className='text-center'>
                    <div className='d-inline-flex align-items-center'>
                        <img className='w-20-px mr-2' src="./images/tick-marks.png" />
                        <p className='margin-unset'>{verifyText}</p>
                    </div>
                </div>
            }
            {verificationStatus === 2 &&
                <div>
                    <div className='d-inline-flex align-items-center ml-4'>

                        {/* <img className='w-20-px mr-2' src="./images/error-mark.png" /> */}
                        <p className='margin-unset'>{'Please connect your Zoom below'}</p>
                    </div>
                </div>
            }
            {!showLoader && <div className='mt-4'>

                {verificationStatus === 1 && <Button
                    className={"  margin-unset action-button"}
                    status={0}
                    onClick={() => savestep()}
                    title="Next" />}

                {verificationStatus === 2 && <Button
                    className={"action-button margin-unset ml-5"}
                    status={0}
                    onClick={getAuthUrl}
                    title="Link your Zoom" />}

                {/* <Link to={'/'} > Lear more</Link> */}
            </div>}


        </div>
    )
}
