import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { store } from "./store/store";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import 'react-phone-input-2/lib/style.css';
import { render } from "react-dom";

const history= createBrowserHistory();
// const root = ReactDOM.createRoot(document.getElementById("root"));
render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter history={history}>
        <App   history={history}/>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
