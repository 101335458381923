import React, { useEffect, useState } from "react";
import Button from "../../../components/button";
import { addReferralCode, getReferralCodes } from "../../../services/admin";
import { toast } from "react-toastify";

export default function ReferralCodes() {
    const [value, setValue] = useState("");
    const [referralCodes, setReferralCodes] = useState([]);

    useEffect(() => {
        getReferralCodes()
            .then((data) => {
                setReferralCodes(data.data.data.data);
            })
            .catch(() => { });
    }, []);

    const onChangeHandler = (e) => {
        setValue(e.target.value);
    };
    const onClickHandler = () => {
        if (value === "") {
            toast.error("Input value is empty", { position: "top-center" });
            return;
        }
        let body = {
            referal_code: value,
        };
        addReferralCode(body)
            .then((data) => {
                if (data.data.data) {
                    toast.success("Code Updated", { position: "top-center" });
                    setReferralCodes(data.data.data.data);
                } else {
                }
            })
            .catch(() => { });
    };
    return (
        <div>
            <div className="mt-5">
                {/* Previous Limit : {limit} */}
                <div className="mt-2">
                    <input onChange={onChangeHandler} value={value} placeholder="Code" />
                    <Button
                        status={0}
                        onClick={onClickHandler}
                        className={"proceed-btn ml-2"}
                        title={"Enter"}
                        type={"button"}
                    />
                </div>
                {/* <span className="mt-2 d-block">{message}</span> */}
            </div>

            <div className="table-box-medium admin-appoi admin-upcoming-app mt-5">
        <table className="caseTable">
          <tbody>
            <tr className="dark">
              <th className="min-width-100">S.NO</th>
              <th className="text-left">

                Referral Code
              </th>
            </tr>
            {referralCodes.length > 0 ? (
              referralCodes.map((record,index) => (
                <tr className="innr">
                     <td>{index + 1}</td>
                  <td>
                    <span className="capitalize">{record.referal_code}</span>
                  </td>

                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No Record Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* <div className="pagination-box">
          <div className="left-pagi">
            <span>Showing 1 to 10 of 18 entries</span>
          </div>
          <div className="right-pagi">
            <ul>
              <li>Previous</li>
              <li className="active">
                <a href="#">1</a>
              </li>
              <li>
                <a href="#">2</a>
              </li>
              <li>
                <a href="#">3</a>
              </li>
              <li>Next</li>
            </ul>
          </div>
        </div> */}
        {/* <Pagination
          rowsPerPageOptions={[5, 10, 25]}
          count={allUpcomingCount}
          activeTab={"upcomingAdmin"}
          getPaginationData={getPaginationData}
          upcomingAppointmentsPage={upcomingAppointmentsPage}
          upcomingAppointmentsrowsPerPage={upcomingAppointmentsrowsPerPage}
        /> */}


      </div>
        </div>
    );
}
