const initialValues = {
    medium_availability_id: "",
    medium_id: "",
    valid_from: "",
    valid_to: "",
    session_type: "",
    buffer_time: "15",
    number_of_session_per_day: 1,
    min_booking_hour: "",
    min_booking_time: "",
    max_booking_hour: "",
    max_booking_time: "",
    min_booking_days: "01",
    max_booking_days: "07",
    meeting_preferences: "",
    participant_preferences: "",
    meeting_preferences_meeting: [],
    meeting_preferences_participants: [],
    recording_meeting: "",
    recording_participants: "",
    group_limit: "",
    partner_level: [],
    student_level: [],
    payment_type: 0,
    payment_id: "",
    start_date: "",
    end_date: "",
    scheduleSunday: [{ from: "", to: "" }],
    scheduleMonday: [{ from: "", to: "" }],
    scheduleTuesday: [{ from: "", to: "" }],
    scheduleWednesday: [{ from: "", to: "" }],
    scheduleThursday: [{ from: "", to: "" }],
    scheduleFriday: [{ from: "", to: "" }],
    scheduleSaturday: [{ from: "", to: "" }],
    specificDateSlots: [],
    blockedDateSlots: [],
    daysunday: false,
    daymonday: false,
    daytuesday: false,
    daywednesday: false,
    daythursday: false,
    dayfriday: false,
    daysaturday: false,
    country_type: "",
    exclusions: [],
    inclusions: [],
    countries: [],
    max_attendees: "",
    preferred_medium_level: ["1", "2", "3", "4", "5", "6", "7"],
    receive_email: false,
    cancellation_email: false,
    is_group_session:0,





    // preferred_medium_level: ["1", "2", "3","4","5","6","7"],

}
export { initialValues }