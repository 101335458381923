import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSessionName } from "../functions";

export default function NewSessionPopup({
  sessionName,
  sessionRadio,
  changeHandler,
  sessionId,
  sessionList,
}) {
  const dispatch = useDispatch();
  // const { calendarSessions } = useSelector(
  //   (state) => state.users
  // );
  const submitHandler = () => {
    let body = {
      session_name: sessionName,
      sessionRadio:sessionRadio,
      session_id: sessionId,

    };
    updateSessionName(body, sessionList, dispatch);
  };
  return (
    <div className="popup-accordion">
      <div class="modal fade" id="practiceModal" role="dialog">
        <div class="modal-dialog h-unset modal-dialog-preview">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div class="modal-body">
              <h4> {sessionId !== undefined ? "Edit" : "Add"} Session Type</h4>
              <form action="get">
                <div className="fild-popup-row">
                  <label>Title</label>
                  <input
                    onChange={changeHandler}
                    value={sessionName}
                    type="text"
                    id="sessionName"
                    name="sessionName"
                    placeholder=""
                  ></input>
                </div>
                <div className="price-box-all sessionRadio-box  mt-3">
                  <div className="filde-box-price">
                    <label className="mb-2">Session Type</label>
                    {/* <form action="#"> */}
                    <p>
                      <input
                        onChange={changeHandler}
                        value="1"
                        type="radio"
                        id="OneOnOne"
                        name="sessionRadio"
                        checked={sessionRadio === "1" ? true : false}
                      ></input>
                      <label for="OneOnOne">1-on-1</label>
                    </p>
                    <p>
                      <input
                        onChange={changeHandler}
                        value="2"
                        type="radio"
                        id="GroupSession"
                        name="sessionRadio"
                        checked={sessionRadio === "2" ? true : false}
                      ></input>
                      <label for="GroupSession">Group Session</label>
                    </p>
                  </div>
                </div>
                <div className="cancel-btn-sumbit">
                  <button
                    type="button"
                    data-dismiss="modal"
                    className="cancel-tn"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={submitHandler}
                    type="button"
                    // data-dismiss="modal"
                    className="sumbit-tn"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
