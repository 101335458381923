import React, { useState } from "react";
import { components } from "react-select";
import Button from "../../../../../components/button";

export default function FeedBackTimeBox({info, publishMediumFeedback }) {
  const [feedbackstatus, setFeedBackstatus] = useState(30);

  const changeHandler = (e) => {
    setFeedBackstatus(e.target.value);
  };
  return (
    <div
      className="modal fade"
      id="feedbacktimebox"
      role="dialog"
      data-backdrop="true"
    >
      <div className="modal-dialog modal-dialog-preview">
        {/* <button type="button" className="close" data-dismiss="modal">
        &times;
      </button> */}
        <div className="modal-content margin-top-300">
          <div className="modal-body">
            <div className="profile-video"></div>
            <h3 className="text-center mt-2 mb-2">Approve Feedback</h3>
            <div>
              <div className="prvacy-box eth-pri">
                <input
                  onChange={changeHandler}
                  type="radio"
                  id="30_days"
                  value={30}
                  name="publishdays"
                  // checked={bannerLimit ? true : false}
                />
                <label htmlFor="30_days">publish for 30 days</label>
              </div>
              <div className="prvacy-box eth-pri">
                <input
                  onChange={changeHandler}
                  type="radio"
                  id="60_days"
                  value={60}
                  name="publishdays"
                  // checked={bannerLimit ? true : false}
                />
                <label htmlFor="60_days">publish for 60 days</label>
              </div>
              <div className="prvacy-box eth-pri">
                <input
                  onChange={changeHandler}
                  type="radio"
                  id="90_days"
                  value={90}
                  name="publishdays"
                  // checked={bannerLimit ? true : false}
                />
                <label htmlFor="90_days">publish for 90 days</label>
              </div>
              <div className="text-center">
                <Button
                  className={`resend-btn w-unset height-35 min-width-130px mt-2 mb-2`}
                  title="Publish"
                  status={0}
                  onClick={()=>publishMediumFeedback(info.feedback_id,1,feedbackstatus)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
