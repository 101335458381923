import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getMediumPublicProfileDetails } from "../../services/uidata";
import "./embeded.css";

export default function Embeded() {
    const params = useParams();
    const [personal_booking_links, setpersonal_booking_links] = useState('');
    const [medium_id, setMediumId] = useState('');

    useEffect(() => {

        getMediumPublicProfileDetails(params.id).then((data) => {
            console.log(data.data.data)
            if (data.data.status) {
                setpersonal_booking_links(data.data.data.general.personal_booking_link)
                setMediumId(data.data.data.general.medium_id);
            }
        }).catch(() => { })
    }, []);
    return (
        <div className="embeded-wrapper">
            <div className="embeded-container">
                <div className="embeded-btn-container">
                    <a
                        target="_blank"
                        className="btn1"
                        href={`${process.env.REACT_APP_BASE_URL}profile-view/${params.id}`}
                    >
                        Recent Reviews
                    </a>

                    <a
                        target={"_blank"}
                        // href={`/register-client?guest=true&id=${medium_id}`}
                        href={`${process.env.REACT_APP_BASE_URL}guest-booking/${medium_id}`}
                        className="btn2"
                    >
                        Book Now
                    </a>
                    {/* <a
                        target={
                           personal_booking_links !== "" && personal_booking_links !== "null" ? "_blank" : "_blank"
                        }
                        href={
                            personal_booking_links !== "" && personal_booking_links !== "null"
                                ?personal_booking_links
                                : "https://app.verysoul.com/register-client"
                        }
                        className="btn2"
                    >
                        Book Now
                    </a> */}
                </div>
                <div className="embeded-powered-container">
                    <span>powered by</span>
                    <img src="/localImages/powered.png" alt="" /> <span>VerySoul</span>
                </div>
            </div>
        </div>
    );
}
