import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setUser } from "../../reducer/userSlice";
import { setActiveRouteName } from "../../reducer/auth/auth";
import { fetchUser } from "../../services/auth";
import { ADMIN, MEDIUM, CLIENT } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../commonfunctions";
import { items } from "../../pages/admin/calendar/data";

export default function Sidebar({ toogle, ToggleSideBar, setToogle }) {
  const { role } = useSelector((state) => state.auth);
  const {
    current_mediumship_level
  } = useSelector((state) => state.user.user);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [userdata, setUserData] = useState("");
  const [toogleSideBar, setToogleSideBar] = useState(false);
  const [userListType, setUserListType] = useState([]);
  const user = role;
  const patname = window.location.pathname;
  useEffect(() => {

    let token = localStorage.getItem("access_token");
    callProfile(token);
    let info = getUserType().map((data) => {

      return data.status;
    });
    setUserListType(info);
  }, []);

  useEffect(() => {

    let path = "";

    getUserType().map((user) => {
      if (user.innerChild === undefined) {

        if (patname === `/${user.route}`) {
          path = user;
        }
      }
      else {
        user.innerChild.map((item) => {
          if (patname === `/${item.route}`) {
            path = item;
          }
        })

      }
    });
    activateRouteName(path);
  }, [patname])

  const callProfile = async (token) => {
    const response = await fetchUser(token)
      .then((data) => {
        dispatch(setUser(data.data.data));
        setUserData(data.data.data);
        // localStorage.setItem("user", JSON.stringify(data.data.data));
        // localStorage.setItem("medium_status", data.data.data.medium_status);
        // localStorage.setItem("status", data.data.data.status);
      })
      .catch((err) => console.log(err));

    return response;
  };
  const getUserType = () => {

    let updatedSidebarData;
    if (userdata.current_mediumship_level == 3 || userdata.current_mediumship_level == 6 || userdata.current_mediumship_level == 7) {
      updatedSidebarData = MEDIUM;
    } else {
      updatedSidebarData = MEDIUM.map(item => ({
        ...item,
        innerChild: item.innerChild.filter(child => child.name !== "Pricing")
      }));
    }
    if (current_mediumship_level == 1 || current_mediumship_level == 2 || current_mediumship_level == 4) {
      let array = updatedSidebarData[1].innerChild
      array.pop()
    }
    if (user === "admin") return ADMIN;
    if (user === "medium") return updatedSidebarData;
    if (user === "client") return CLIENT;
  };

  const checkPathName = (path) =>
    location.pathname === `/${path}` ? "active" : "";

  const activateRouteName = (name) => {
    setToogle(true);
    let newName = "";
    if (name == 'Dashboard') {
      newName = name
    }
    else if (name.headerName !== undefined) {
      newName = name.headerName
    }
    else {
      newName = name.name
    }
    dispatch(setActiveRouteName(newName));
  }
  const openMiddlePopup = (item, index) => {
    let info = userListType.map((data, i) => {
      if (i === index) {
        return !data;
      } else {
        return data;
      }
    });

    setUserListType(info);
    // item.status = !item.status
    // console.log(item, "item");
    // setToogleSideBar(!toogleSideBar)
  };
  function myfunction() {
    //do something
    return false;
  }

  const checkActiveRouteName = (user, innerItem) => {
    if (user.route !== "/") {


      activateRouteName(
        innerItem.route !== "/" ? innerItem : "Dashboard"
      )

    }
    else {

    }
  }
  return (
    <ul
      className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${toogle ? "toogle-nav" : ""
        }`}
      id="accordionSidebar"
    >

      <div onClick={ToggleSideBar} className="close-btn">
        <img src="images/remove.png" alt="remove btn" />
      </div>
      <Link
        className="sidebar-brand d-flex align-items-center justify-content-center"
        to=""
      >
        <div className="sidebar-brand-icon rotate-n-15">
          <img src="/images/logo-wight.png" alt="logo" />
        </div>
      </Link>
      <div className="profile-side">
        {/* <div className="profile-image">
          <img src="/images/profile-image.png" alt="profile image" />
        </div> */}
        <div className="profile-name">
          {userdata.screen_name
            ? userdata.screen_name
            : `${userdata && userdata.first_name} ${userdata &&
            userdata.last_name}`}
        </div>
        {user !== "admin" && (
          <>
            <div className="profile-email">
              <Link to="/">
                {!userdata.username ? userdata.email : userdata.username}
              </Link>
            </div>
            {user !== "client" && (
              <>
                {/* <div className="progress-bar">
                  <div className="bar"></div>
                </div> */}
                {/* <div className="progress-text"></div> */}
                {/* <div className="profile-complet">
                  <span>Complete Profile</span>
                </div> */}
              </>
            )}
          </>
        )}
      </div>
      {/* <li className="nav-item active">
        <Link className="nav-link" to="/dashboard">
          <img src="images/dashboard1.png" className="browan" />
          <img src="images/dashboard2.png" className="wight" />
          Dashboard
        </Link>
      </li> */}
      {React.Children.toArray(
        getUserType().map((user, index) =>
          user.innerChild !== undefined ? (
            <>
              <li className={`nav-item`}>
                <div
                  className="d-flex align-items-center justify-content-between pointer border-bottom-sidebar"
                  onClick={() => openMiddlePopup(user, index)}
                >
                  <div>
                    <a href="javascript:void();" onclick={myfunction} className={`nav-link hover-stop-navlink`}>
                      {" "}
                      <img src={user.imgDark} className="browan top-5 new-sidebar-img" alt="dark" />
                      <img src={user.imgLight} className="wight top-5  new-sidebar-img" alt="light" />
                      {user.name}
                    </a>
                  </div>
                  {!userListType[index] ? (
                    <img
                      src="/images/dropdown.png"
                      className="mr-2"
                      alt="dropdown.png"
                    ></img>
                  ) : (
                    <img
                      src="/images/dropdown.png"
                      className="mr-2 downward-arrow"
                      alt="dropdown.png"
                    ></img>
                  )}
                </div>

                {userListType[index] &&
                  user.innerChild.map((innerItem) =>
                    <div onClick={() => {
                      ToggleSideBar(); checkActiveRouteName(user, innerItem)
                    }
                    }



                      className="">
                      {!innerItem.url ? (
                        <Link className={`nav-link d-flex align-items-center
                         ${innerItem.route == "go-on-demand-practice" && location.pathname === `/${innerItem.route}`
                            ? 'nva-link-button padding-10' :
                            innerItem.route == "go-on-demand-practice" && location.pathname !== `/${'go-on-demand-practice'}`
                              ? 'nva-link-button nva-link-button-inactive padding-10' : ''} ${innerItem.route !== "go-on-demand-practice" && location.pathname === `/${innerItem.route}`
                                ? "active-inner-route" : ""}`} to={`/${innerItem.route}`}>
                          {innerItem.route == "go-on-demand-practice"
                            ?
                            <>
                              <div className="special-event-buttons">
                                <img src={innerItem.imgLight} className="pl-4  new-sidebar-img" alt="light" />
                                <div className="mt-2">  {innerItem.name}</div>

                              </div>
                              <>
                                {/* <img src={innerItem.imgLight} className="pl-5  new-sidebar-img" alt="light" />
                                <div className="mt-2">  {innerItem.name}</div> */}
                              </>
                            </>
                            :
                            <>
                              <img src={innerItem.imgDark} className="browan pl-5  new-sidebar-img" alt="dark" />
                              <img src={innerItem.imgLight} className="wight pl-5  new-sidebar-img" alt="light" />
                              <div className="mt-2">  {innerItem.name}</div>
                              {innerItem.count !== "" && (
                                <span className="alert-number">{innerItem.count}</span>
                              )}
                            </>
                          }
                        </Link>
                      ) : (
                        <a href={innerItem.url} className="nav-link  align-items-center  d-flex" target="_blank">
                          <img src={innerItem.imgDark} className="browan pl-5   new-sidebar-img" alt="dark" />
                          <img src={innerItem.imgLight} className="wight pl-5  new-sidebar-img" alt="light" />
                          <div className="mt-2"> {innerItem.name}</div>
                        </a>
                      )}
                      {/* <a className="nav-link pointer">
                    {" "}
                    <img src={user.imgDark} className="browan pl-5" alt="dark" />
                    <img src={user.imgLight} className="wight pl-5" alt="light" />
                    {user.name}
                  </a>
                  <a className="nav-link pointer">
                    {" "}
                    <img src={user.imgDark} className="browan pl-5" alt="dark" />
                    <img src={user.imgLight} className="wight pl-5" alt="light" />
                    {user.name}
                  </a> */}
                    </div>
                  )
                }
                {/* {user.innerChild.map((innerItem) =>
                !innerItem.url ? (
                  <Link className="nav-link" to={`/${innerItem.route}`}>
                    <img src={innerItem.imgDark} className="browan" alt="dark" />
                    <img src={innerItem.imgLight} className="wight" alt="light" />
                    {innerItem.name}
                    {innerItem.count !== "" && (
                      <span className="alert-number">{innerItem.count}</span>
                    )}
                  </Link>
                ) : (
                  <a href={innerItem.url} className="nav-link" target="_blank">
                    <img src={innerItem.imgDark} className="browan" alt="dark" />
                    <img src={innerItem.imgLight} className="wight" alt="light" />
                    {innerItem.name}
                  </a>
                )
              )

              } */}
              </li>
            </>
          ) : (
            <li
              onClick={
                user.route !== "/"
                  ? () =>
                    activateRouteName(
                      user.route !== "/" ? user : "Dashboard"
                    )
                  : () => { }
              }
              className={`nav-item ${checkPathName(user.route)}`}
            >
              {/* {user.innerChild &&  */}

              {/*  */}
              {!user.url ? (
                <Link className="nav-link" to={`/${user.route}`}>
                  <img src={user.imgDark} className="browan" alt="dark" />
                  <img src={user.imgLight} className="wight" alt="light" />
                  {user.name}
                  {user.count !== "" && (
                    <span className="alert-number">{user.count}</span>
                  )}
                </Link>
              ) : (
                <a href={user.url} className="nav-link" target="_blank">
                  <img src={user.imgDark} className="browan" alt="dark" />
                  <img src={user.imgLight} className="wight" alt="light" />
                  {user.name}
                </a>
              )}
            </li>
          )
        )
      )}
      {/* <div
        onClick={() => logoutUser(dispatch, navigate)}
        className="logout-box"
      >
        <Link to="#">
          <img src="/images/logout.png" alt="logout.png" />
          Logout
        </Link>
      </div> */}
    </ul>
  );
}