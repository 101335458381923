import { setLoading } from "../../../reducer/auth/auth";
import { referOthers, checkreferCode } from "../../../services/profile";
import { useSelector, useDispatch } from 'react-redux';
import { fetchUser } from "../../../services/auth";
import React, { useEffect, useState } from 'react';
import Button from '../../../components/button';
import { toast } from "react-toastify";
import "./index.css"


export default function ReferOthers() {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.user.user);
  const [userdata, setUserData] = useState("");
  const [referStarter, setreferStarter] = useState("");
  useEffect(() => {
    callProfile();
  }, []);
  const callProfile = async () => {
    dispatch(setLoading(true));
    let userId = `Starter ${id}`;
    let referNumber = userId
      .replace(/\s+/g, '') // Remove whitespace
      .replace(/\D/g, ''); // Remove non-digits
    if (referNumber.length > 4) {
      referNumber = referNumber.slice(-4); // Keep only the last four digits
    } else {
      referNumber = referNumber.padStart(4, '0'); // Pad with leading zeros if less than four digits
    }
    let referName = `Starter ${referNumber}`;
    if (referName) {
      let formattedValue = referName
        .replace(/\s+/g, '')
        .toLowerCase();

      checkUserReferCode(formattedValue);
    }
  };
  const checkUserReferCode = async (referCode) => {

    let item = {
      userId: id,
      referral_code: referCode
    };
    checkreferCode(item)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          let codeReferValue = data.data.data.refer_code;
          setreferStarter(codeReferValue)
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  }
  const handleClick = () => {
    dispatch(setLoading(true));
    let item = {
      userId: id,
      referral_code: referStarter
    };
    referOthers(item)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          toast.success("Email sent successfully", { position: "top-center" });
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  }

  return (
    <>
      <div className="mt-4">
        <div className="row">
          {/* Left Side Content */}
          <div className="col-md-8">
            <div className='mb-4'>
              <div className="row">
                <div className="col-md-12">
                  <h3 className="font-size-26 font-weight-bolder">Starter Client Volunteers</h3>
                  <h5 className="mainHeading">(Practice Sitters)</h5>
                </div>
              </div>
              <div className='d-flex mt-2 align-items-center position-relative d-flex'>
                <p className='mt-2 font-weight-normal main-top-pre'>Your Referral Code :</p>&nbsp;&nbsp;
                <p className='mt-2 font-weight-bold main-sub-heading'>{referStarter && referStarter}&nbsp;</p>
              </div>
              <div>
              </div>
            </div>
            <div className="d-flex">
              <Button

                status={0}
                type="button"
                className={`resend-btn w-unset height-45 min-width-130px mt-2 me-3`}
                title={"Referral Email"}
                onClick={handleClick}

              />
              <div className="col-md-7 hoverContent">
                <div className="hover-container">
                  <p className="hover-target" tabindex="0"> <Button
                    status={0}
                    type="button"
                    className={`resend-btn w-unset height-45 min-width-130px mt-2`}
                    title={"Learn More"}
                  /></p>
                  <aside className="hover-popup-r">
                    <h2 className="mainHeading">Referring Friends and Family to the Starter Client Volunteer Program (Non-Mediums)</h2>
                    <p className="mainpre">1. Any VerySoul Medium of any ability level can make referrals of friends and family to our Starter Client Volunteer Program. This program is designed to help intermediate level mediums get early client experiences in an environment conducive to their development. This experience is completely free to you and to your referrals</p>
                    <p className="mainpre">2. The opportunity for the volunteers is that they can receive many free mediumship sessions with mediums who clearly have the ability to connect with spirit.  The volunteers get to experience many different styles which often helps them understand both mediumship and their communicators even better.</p>
                    <p className="mainpre">3. Please choose your referrals thoughtfully. Specifically make sure that your selectees:<br />&nbsp;&nbsp;a. Are not mediums or psychics<br />&nbsp;&nbsp;b. Are supportive of Mediumship development in general,<br />&nbsp;&nbsp;c. Are not deeply grieving a loss right now, and <br />&nbsp;&nbsp;d. Are good with appointments.</p>

                    <p className="mainpre">4. Each Medium has been given a unique referral code and there is no limit to the number of referrals you can make into the Program.</p>
                    <p className="mainpre">5. We have drafted an email that you can use to send this information to your referrals. Simply select the  “Referral Email” button below your referral code. This button will automatically send the email draft to you with your unique referral code embedded. You can personalize it however you’d like and then forward it directly to your nominees.</p>
                    <p className="mainpre">6. Starter Client Volunteers will be asked to watch a brief video about the program and to fill out a brief questionnaire during registration. They will also receive brief instructions about what to expect before their first session to help them feel prepared.</p>
                  </aside>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <br />
      <div className="mt-4">
        <div className="row">
          {/* Left Side Content */}
          <div className="col-md-6">
            <div className='mb-4'>
              <h3 className="font-size-26 font-weight-bolder">Other Clients</h3>
              <div className='d-flex mt-2'>
                <h4 className="mainHeading">TBD</h4>
              </div>
            </div>

            <div className='mb-4'>
              <h3 className="font-size-26 font-weight-bolder">Other Mediums</h3>
              <div className='d-flex mt-2'>
                <h4 className="mainHeading">TBD</h4>
              </div>
            </div>

            <div className='mb-4'>
              <h3 className="font-size-26 font-weight-bolder">Potential Mentors and Coaches</h3>
              <div className='d-flex mt-2'>
                <h4 className="mainHeading">TBD</h4>
              </div>
            </div>
          </div>

          {/* Right Side Content */}
          <div className="col-md-6">

          </div>
        </div>
      </div>
    </>
  )
}
