import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  preferences: {},
  bookingtime: "",
  bookingdate: "",
  allUpcomingCount: 0,
  allCompletedCount: 0,
  allCancelledCount: 0,
  allNoShowCount: 0,
  bookingIds: [],
  mediumListItems: [],
  bookingBtnStatus: true,
  selectedMedium: {},
  appointmentsList: [],
  allUpcomingAppointments: [],
  allCompletedAppointments: [],
  allCancelledAppointments: [],
  allNoShowsAppointments: [],
  bookedAppointmentData: null,
  counts: "",
  pathClientArray: null,
  windowObject: true,
  mediumsToBeMatch: [],
  blinkRequest: '',
  paymentTypeAvailbale: '',
  paymentMethodAvailbale: 'both',
  renderTypeInfo: '',
  bookingTypeInfo: '',
};
export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    setPreferences: (state, action) => {
      state.preferences = action.payload;
    },
    setBookingTime: (state, action) => {
      state.bookingtime = action.payload;
    },
    setBookingDate: (state, action) => {
      state.bookingdate = action.payload;
    },
    setBookingIds: (state, action) => {
      state.bookingIds = action.payload;
    },
    setmediumListItems: (state, action) => {
      state.mediumListItems = action.payload;
    },
    setMediumsToBeMatch: (state, action) => {
      state.mediumsToBeMatch = action.payload;
    },
    setbookingBtnStatus: (state, action) => {
      state.bookingBtnStatus = action.payload;
    },
    setselectedMedium: (state, action) => {
      state.selectedMedium = action.payload;
    },
    setaAppointmentsList: (state, action) => {
      state.appointmentsList = action.payload;
    },
    setAllAppointments: (state, action) => {
      state.allUpcomingAppointments = action.payload.upcoming;
      state.allCompletedAppointments = action.payload.completed;
      state.allCancelledAppointments = action.payload.cancelled;
      state.allNoShowsAppointments = action.payload.show;
    },
    setbookedAppointmentData: (state, action) => {
      state.bookedAppointmentData = action.payload;
    },
    setClientCount: (state, action) => {
      state.counts = action.payload;
    },
    setAllUpcomingCount: (state, action) => {
      state.allUpcomingCount = action.payload;
    },
    setAllCompletedCount: (state, action) => {
      state.allCompletedCount = action.payload;
    },
    setAllCancelledCount: (state, action) => {
      state.allCancelledCount = action.payload;
    },
    setAllNoShowCount: (state, action) => {
      state.allNoShowCount = action.payload;
    },
    setClientPathArray: (state, action) => {
      state.pathClientArray = action.payload;
    },
    setWindowObject: (state, action) => {
      state.windowObject = action.payload;
    },
    setBlinkRequest: (state, action) => {
      state.blinkRequest = action.payload;
    },
    setPaymentTypeAvailbale: (state, action) => {
      state.paymentTypeAvailbale = action.payload
    },
    setPaymentMethodAvailbale: (state, action) => {
      state.paymentMethodAvailbale = action.payload
    },
    setRenderTypeInfo: (state, action) => {
      state.renderTypeInfo = action.payload
    },
    setBookingTypeInfo: (state, action) => {
      state.bookingTypeInfo = action.payload
    }
  },
});

export const {
  setPreferences,
  setBookingTime,
  setBookingDate,
  setmediumListItems,
  setbookingBtnStatus,
  setselectedMedium,
  setaAppointmentsList,
  setAllUpcomingCount,
  setAllCompletedCount,
  setAllCancelledCount,
  setAllNoShowCount,
  setbookedAppointmentData,
  setClientCount,
  setClientPathArray,
  setAllAppointments,
  setBookingIds,
  setWindowObject,
  setMediumsToBeMatch,
  setBlinkRequest,
  setPaymentTypeAvailbale,
  setPaymentMethodAvailbale,
  setRenderTypeInfo,
  setBookingTypeInfo
} = clientSlice.actions;

export default clientSlice.reducer;

export const CurrentUser = (state) => state.users;
