import React from "react";
import './userstatus.css';

export default function UserStatus({ status }) {
  return (
    <div className="userstatus">
      <div className={`${status===1 ? "userstatus-green" : "userstatus-red"}`}></div>
    </div>
  );
}
