import Vimeo from '@u-wave/react-vimeo'
import React, { useEffect, useState } from 'react'
import { FadeLoader } from 'react-spinners'
import Button from '../../../../components/button'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import TickMark from '../../../../components/tickmark'
import { fetchLinkedCalendar, saveStepsProgress, updateGoogleCalendar, verifyCalendar } from '../../../../services/medium'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../../../reducer/auth/auth'
import { LinkPersonalCalendar } from '../../../../services/uidata'
import { toast } from 'react-toastify'

export default function StepTwo({ NextStep,innerWidthHandler }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const { id } = useSelector((state) => state.user.user);
    const [showLoader, setShowLoader] = useState(true);
    const [verificationStatus, setVerificationStatus] = useState(0);
    const [verifyText, setVerifyText] = useState('');
    const [calendarList, setCalendarList] = useState([]);

    const openAppointMent = () => {
        window.open("/integration", "_blank", "noreferrer")
    }
    function getPathFromUrl(url) {
        const newUrl = new URL(window.location.href);
        newUrl.searchParams.delete("status");
        newUrl.searchParams.delete("message");
        window.history.replaceState({}, document.title, newUrl.href);
    }
    useEffect(() => {
        setTimeout(() => {
            verifyCalendarHandler()

        }, 5000)
    }, [])
    useEffect(() => {
        let calendar_status = searchParams.get("status");
        let linked_status = searchParams.get("message");
        if (calendar_status && linked_status === "GoogleAccountLinked") {
            fetchLinkedCalendar()
                .then((data) => {
                    console.log(data);
                    if (data.data.status) {
                        let info = data.data.data.calendar_list.map((item) => {
                            if (item.id === "primary") {
                                return {
                                    ...item,
                                    status: true,
                                };
                            } else {
                                return {
                                    ...item,
                                    status: false,
                                };
                            }
                        });
                        setCalendarList(info);
                        window.loadModal2("#integration-modal", "show");


                    }
                })
                .catch(() => { });
        }

    }, []);
    const selectCalendarHandler = (item) => {
        let info = calendarList.map((data) => {
            if (data.id == item.id) {
                return {
                    ...data,
                    status: true,
                };
            } else {
                return {
                    ...data,
                    status: false,
                };
            }
        });
        setCalendarList(info);
    };
    const verifyCalendarHandler = () => {
        let body = {
            medium_id: id
        }
        verifyCalendar(body).then((data) => {
            console.log(data)
            if (data.data.status) {
                setVerifyText(data.data.message)
                setVerificationStatus(1);
                setShowLoader(false);
            }
            else {
                setVerifyText(data.data.message)
                setVerificationStatus(2);
                setShowLoader(false);
            }


        })
    }
    const linkcalendar = () => {
        dispatch(setLoading(true));
        LinkPersonalCalendar(id,"onboarding")
            .then((data) => {
                dispatch(setLoading(false));
                if (data.data.status) {
                    window.open(data.data.data.url, "_self");
                }
            })
            .catch((err) => {
                dispatch(setLoading(false));
                console.log(err);
            });
    };
    const savestep = () => {
        let body = {
            step_number: 2
        }
        saveStepsProgress(body).then((data) => {
            if (data.data.status) {
                localStorage.setItem('step',2)
                NextStep()
            }
        }).catch(() => {

        })
    }
    const saveSelectedCalendar = () => {
        let id = calendarList.filter((data) => data.status);
        let body = {
            google_calendar_id: id[0].id,
            google_calendar_name: id[0].summary,
        };
        console.log(id);
        updateGoogleCalendar(body)
            .then((data) => {
                if (data.data.status) {
                    toast.success("Calendar Updated Succesfully", {
                        position: "top-center",
                    });
                    getPathFromUrl();
                    window.loadModal2("#integration-modal", "hide");

                }
            })
            .catch(() => { });
    };
    return (
        <div>
            <div className='text-left  pl-5 pr-5'>

                <h3 className='font-size-40'>Connect Google Calendar</h3>
                <ol className='font-size-18 line-height-24px'>
                    <li>Watch the video below.</li>
                    <li>Connect your Google Calendar. (optional)</li>
                </ol>
                {/* <p className='font-size-18 line-height-24px'>
                    This step is optional. When connecting an electronic calendar to our system,
                    it must be a Google Calendar account. This is a helpful tool that will both
                    add appointments into your Google Calendar and check that calendar for blocks
                    to help make sure that you do not get double booked.

                </p> */}


            </div>
            <div className='mt-2 mb-2'>
                <Vimeo
                    muted={true}
                    autoplay={false}
                    // className="popover-video"
                    width={innerWidthHandler()}
                    height={330}
                    video={"https://vimeo.com/922699013/34a0892fe4"}
                />
            </div>
            <br />
            {showLoader && <div className='justify-content-center d-flex align-items-center'>
                <span className='mr-5'> Please wait we are verifying your calendar...</span>
                <FadeLoader size={50} color='#d5a67b' />


            </div>}
            {verificationStatus === 1 &&
                <div className='text-center'>
                    <div className='d-inline-flex align-items-center'>
                        <img className='w-20-px mr-2' src="./images/tick-marks.png" />
                        <p className='margin-unset mt-1'>{verifyText}</p>
                    </div>
                </div>
            }
            {verificationStatus === 2 &&
                <div className='text-center'>
                    <div className='d-inline-flex align-items-center ml-4'>

                        {/* <img className='w-20-px mr-2 ' src="./images/error-mark.png" /> */}
                        <p className='margin-unset '>{'Please connect your Calendar below'}</p>
                    </div>
                </div>
            }
            {!showLoader && <div className='mt-4'>

                {verificationStatus === 1 && <Button
                    className={"action-button margin-unset"}
                    status={0}
                    onClick={() => savestep()}
                    title="Next" />
                }

                {verificationStatus === 2 && <Button
                    className={"action-button margin-unset ml-5"}
                    status={0}
                    onClick={linkcalendar}
                    title="Link your calendar" />
                }
                {verificationStatus === 2 && <div className='ml-5 d-inline-block'><Button
                    className={"action-button margin-unset skip-pbtn "}
                    status={0}
                    onClick={() => savestep()}
                    title="Skip for now" />
                    </div>
                }


            </div>}
            <div className="modal fade" id="integration-modal" role="dialog">
                <div className="modal-dialog modal-dialog-preview">
                    <div className="modal-content">
                        <div className="modal-body text-align-unset">
                            <h4 className="text-center mt-2">Choose your calendar</h4>
                            {calendarList.map((item) => (
                                <div
                                    onClick={() => selectCalendarHandler(item)}
                                    className={`calendar-card pointer ${item.status ? "calendar-card-norder" : ""
                                        }`}
                                >
                                    {/* <p>{item.etag}</p>
                    <p>{item.id}</p> */}
                                    <p className="letter-spacing-1px">
                                        <b>Calendar Name </b>: {item.summary}
                                    </p>
                                    <p className="letter-spacing-1px">
                                        <b>Time Zone</b> : {item.timeZone}
                                    </p>
                                </div>
                            ))}
                            <div className="text-center">
                                <Button
                                    className={"resend-btn w-unset height-45 min-width-130px"}
                                    status={0}
                                    onClick={saveSelectedCalendar}
                                    title="Save Calendar"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
