import React from "react";
import { useNavigate } from "react-router-dom";

export default function TimeEndedPopup({ setloaderType, addMediumQueue }) {
    const navigate = useNavigate();
    // const { id  } = useSelector((state) => state.user.user);



    const navigatePage = () => {
        window.loadModal2("#TimeEndedPopup", "hide")
        setTimeout(() => {
            navigate('/appointments')
        }, 100)

    }
    const exitFromHere = () => {
        window.loadModal2("#TimeEndedPopup", "hide")
        addMediumQueue()
        // setTimeout(() => {
        //     navigate('/appointments')
        // }, 100)
    }
    return (
        <div>
            <div
                id="TimeEndedPopup"
                class="modal fade bd-example-modal-lg mt-5 preview-modal-availability"
                tabindex="-1"
                role="dialog"
                aria-labelledby="myLargeModalLabel"
                aria-hidden="true"
                data-backdrop="static"
            >
                <div class="modal-dialog modal-lg modal-dialog-preview text-center">
                    <div class="modal-content ">
                        <div className="no-app-fount-box">

                            <p className="font-size-18">We are sorry, we could not match you for a backfilled Donated Session at this time.


                            </p>


                            <br />
                            <br />
                            <br />
                            {/* <p className="font-size-18">Check your appointments for other upcoming sessions, or try booking a Practice session in advance.
                            </p> */}
                            <div className="d-flex align-items-center justify-content-around">

                                <div
                                    onClick={exitFromHere}

                                    className="width-215-px white-border pointer indexstyles__StyledButton-sc-83qv1q-0 jdbotF sc-1qeub3n-4  d-flex align-items-center justify-content-center"
                                >
                                    {/* Upcoming Appointments */}
                                    Continue Waiting
                                </div>
                                <div
                                    onClick={navigatePage}
                                    className="width-215-px white-bg-btn pointer indexstyles__StyledButton-sc-83qv1q-0 jdbotF sc-1qeub3n-4  d-flex align-items-center justify-content-center"
                                >
                                    Exit Queue
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
