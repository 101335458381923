import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getAppointmentInfo } from '../../../services/client'
import { useDispatch } from 'react-redux'
import { setbookedAppointmentData } from '../../../reducer/clientsSlice'
import { setLoading } from '../../../reducer/auth/auth'

export default function AppointmentConfirm() {
    const dispatch = useDispatch()
    const param = useParams()


    useEffect(() => {
        // localStorage.removeItem('bookingType');
        // localStorage.removeItem('profType')
        dispatch(setLoading(true))
        getAppointmentInfo(param.id).then((data) => {
            if (data.data.status) {

                dispatch(setLoading(false))
                dispatch(setbookedAppointmentData(data.data.data));
                setTimeout(() => {

                    window.loadModal("#advanceConfirmbooking", "open");
                }, 100)
            }
        }).catch(() => { dispatch(setLoading(false)) })

    }, [])
    return (
        <div className='appointmentconfirmbox'></div>
    )
}
