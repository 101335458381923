import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik,useFormik } from "formik";
import { useDispatch } from "react-redux";
//services
import { Link } from "react-router-dom";
import { updateUserTechnologiesMeta } from "../../../services/profile";
import {
  logout,
  sendVerificationCode,
  updateUserEmail,
} from "../../../services/auth";
import { setLoading } from "../../../reducer/auth/auth";
import Button from "../../../components/button";
import TickMark from "../../../components/tickmark";
import "./step.css";
//
const StepFour = (props) => {
  const { NextStep, stepFourData, setValues } = props;
  // const { email } = useSelector((state) => state.user.user);
  const [open, setOpen] = useState(false);
  const [verified, setVerified] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [verifiedTitle, setVerifiedTitle] = useState("Verify");
  const [verifiedError, setVerifiedError] = useState(false);
  const [verifiedMessage, setVerifiedMessage] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [resend, setResend] = useState(0);
  const [emailTitle, setEmailTitle] = useState("Request Change Email");
  const [emailMessage, setEmailMessage] = useState("");
  const [emailError, setEmailError] = useState("");

  const [emailDisabled, setEmailDisabled] = useState(true);
  const [changeEmailRequest, setChangeEmailRequest] = useState(true);
  const [time, setTime] = useState(0);
  const [showResend, setshowResend] = useState(false)

  const [showRequiredError, setshowRequiredError] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);
  const [showEmailField, setShowEmailField] = useState(true);
  const [secondsLeft, setSecondsLeft] = useState(30);
  const [timeDisplay, setTimeDisplay] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // useEffect(() => {
  //   setEmailValue(email);
  // }, []);
  const openBox = () => {
    setOpen(!open);
  };
  useEffect(() => {
    const startTimestamp = localStorage.getItem('emailVerificationStart');
    if (startTimestamp) {
      const elapsedTime = Math.floor((Date.now() - parseInt(startTimestamp, 10)) / 1000);
      const newSecondsLeft = Math.max(30 - elapsedTime, 0);
      setSecondsLeft(newSecondsLeft);
      if (newSecondsLeft === 0) {
        setShowEmailField(true);
        setTimeDisplay(false);
      }
    } else {
      localStorage.setItem('emailVerificationStart', Date.now().toString());
    }
  }, []);

  useEffect(() => {
    if (secondsLeft > 0) {
      const timerId = setTimeout(() => {
        setSecondsLeft(secondsLeft - 1);
      }, 1000);

      return () => clearTimeout(timerId);
    } else {
      setShowEmailField(true);
      setTimeDisplay(false);
      setResend(1);
    }
  }, [secondsLeft]);
  const RunFunction = (formData) => {
    dispatch(setLoading(true));
    setValues(formData, 4);
    NextStep();
    const {
      legalAndPrivacy,
      optIn,
      codeOfEthics,
      operatingPrinciples,
    } = formData;
    let item = {
      legal_privacy: legalAndPrivacy,
      opt_in: optIn === "Yes" ? true : false,
      code_of_ethics: codeOfEthics,
      operating_principal: operatingPrinciples,
      medium_status: 1,
    };
    updateUserTechnologiesMeta(item)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          window.openModal();
          openBox();
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };
  const logoutUser = () => {
    let token = localStorage.getItem("access_token");
    logout(token)
      .then((data) => {
        if (data.data.status) moveRoute();
      })
      .catch((err) => console.log(err));
  };
  const moveRoute = () => {
    localStorage.clear();
    navigate("/thanks");
    window.location.reload();
  };
  const customHandleChange = (errors) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const reSendVerificationCodeHandler = () => {
    const formData = {};
    let val = "";
    resendCode(val);
    setResend(2);
    setSecondsLeft(30);
    setShowEmailField(true);
    setTimeDisplay(true);
    localStorage.setItem('emailVerificationStart', Date.now().toString());
    setTimeout(() => {
      setResend(0);
    }, 4000);
  };
  const resendCode = (val) => {
   
    setTime(1);
    let body = {
      verification_code: val,
    };
    sendVerificationCode(body)
      .then((data) => {
        if (
          !data.data.status &&
          data.data.message === "OTP sent Successfully"
        ) {
          let email = data.data.data.email;
          setTimeout(() => {
            // setResend(1);
          }, 30000);
          
          setVerifiedTitle("Verify");
          setVerifiedMessage(`We send your code to ${email}`);
          setVerifiedError(false);
          setChangeEmailRequest(true);
        }
        if (!data.data.status && data.data.message === "Invalid OTP") {
          setVerifiedTitle("Verify");
          setVerifiedMessage("Invalid Verification Code");
          setVerifiedError(true);
          setChangeEmailRequest(false);
        }
        if (data.data.status && data.data.message === "Verified") {
          setSecondsLeft(0);
          localStorage.removeItem('emailVerificationStart');
          setVerifiedTitle("Verified");
          setVerifiedMessage(data.data.message);
          setVerifiedError(false);
          setVerified(true);
          setResend(0);
          setShowEmailField(true);
          setTimeDisplay(true);
          setChangeEmailRequest(false);
        }
      })
      .catch((data) => {
        setVerifiedMessage("Please Try Again");
        setVerifiedError(true);
      });
  };
  const sendVerificationCodeHandler = (formData) => {

    const {
      legalAndPrivacy,
      optIn,
      codeOfEthics,
      operatingPrinciples,
    } = formData;
    // return;
    if (legalAndPrivacy && !optIn === false && codeOfEthics && operatingPrinciples) {
      if (!apiCalled) {
        setshowRequiredError(false);

        let item = {
          legal_privacy: legalAndPrivacy,
          opt_in: optIn === "Yes" ? true : false,
          code_of_ethics: codeOfEthics,
          operating_principal: operatingPrinciples,
          medium_status: 0,
        };
        updateUserTechnologiesMeta(item)
          .then((data) => {

            if (data.data.status) {
              setApiCalled(true);
            }
          })
          .catch((err) => {

            console.log(err);
          });
      }
      setTime(1);
      let body = {
        verification_code: verificationCode,
      };
      sendVerificationCode(body)
        .then((data) => {
          if (
            !data.data.status &&
            data.data.message === "OTP sent Successfully"
          ) {

            let email = data.data.data.email;
            setTimeout(() => {
              // setResend(1);
              
            }, 30000);
            setVerifiedTitle("Verify");
            setVerifiedMessage(`Verification Code sent successfully to ${email}`);
            setVerifiedError(false);
            setChangeEmailRequest(true);
          }
          if (!data.data.status && data.data.message === "Invalid OTP") {
            setVerifiedTitle("Verify");
            setVerifiedMessage("Invalid Verification Code");
            setVerifiedError(true);
            setChangeEmailRequest(false);
          }
          if (data.data.status && data.data.message === "Verified") {
            setVerifiedTitle("Verified");
            localStorage.removeItem('emailVerificationStart');
            setVerifiedTitle("Verified");
            setVerifiedMessage(data.data.message);
            setVerifiedError(false);
            setVerified(true);
            setResend(0);
            setShowEmailField(true);
            setTimeDisplay(true);
            setChangeEmailRequest(false);

          }
        })
        .catch((data) => {
          setVerifiedMessage("Please Try Again");
          setVerifiedError(true);
        });
    }
    else {
      setshowRequiredError(true)
      return;
    }
  };
  // const formik = useFormik({
  //   initialValues: {
  //     stepFourData,
  //   },
  //   onSubmit: (values) => {
  //     console.log('Form submitted with values:', values);
  //     // Add your additional submit logic here
  //   },
  // });
  const handleChangeVerification = (e) => {
    setTime(0);
    const { value } = e.target;
    setVerificationCode(value);
    if(value.length == 6){
      resendCode(value);

    }
  };
  const handleChangeEmail = (e) => {
    setEmailValue(e.target.value);
    if (e.target.value === "") {
      setEmailError("*Required");
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(e.target.value)) {
      setEmailError("*Invalid Email");
    } else {
      setEmailError("");
    }
  };
  const onChangeEmailHandler = (e) => {
    setEmailDisabled(false);
    setEmailTitle("Submit");
    if (emailTitle === "Submit" && emailError === "" && emailValue !== "") {
      let body = {
        email: emailValue,
      };
      updateUserEmail(body)
        .then((data) => {
          if (data.data.status) {
            setshowResend(true);
            setResend(1);
            setEmailMessage("Email successfully Updated");
            setChangeEmailRequest(false);
            setVerifiedTitle("Verify");
            setTimeout(() => {
              setVerifiedMessage("");
              setEmailDisabled(true);
              setEmailTitle("Request Change Email");
              setEmailMessage("");
              reSendVerificationCodeHandler();
            }, 3000);
          }else if(!data.data.status){
            console.log(data.data.error.email[0]);
            if(data.data.error && data.data.error.email[0])
              setEmailError(data.data.error.email[0]);
            }else{
              setEmailError("Please Try Again");
            }
            setTimeout(() => {
              setEmailError("");
            }, 3000);
        })
        .catch(() => { });
    }
    //
  };
  return (
    <Formik
      initialValues={stepFourData}
      validate={(values) => {
        const errors = {};
        if (!values.legalAndPrivacy) errors.legalAndPrivacy = "Required";
        if (!values.optIn) errors.optIn = "Required";
        if (!values.codeOfEthics) errors.codeOfEthics = "Required";
        if (!values.operatingPrinciples)
          errors.operatingPrinciples = "Required";

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        RunFunction(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="from-inner-box">
            <div className="full-fild">
              <label>
                Legal and Privacy <i>*</i>
              </label>
              <span className="error marg-top-55">
                {errors.legalAndPrivacy &&
                  touched.legalAndPrivacy &&
                  errors.legalAndPrivacy}
                {!values.legalAndPrivacy && showRequiredError ? 'Required' : ''}
              </span>
              <div className="prvacy-box">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="I have read and agree to the terms and conditions"
                  id="termsandconditions"
                  type="checkbox"
                  name="legalAndPrivacy"
                  defaultChecked={
                    values.legalAndPrivacy ===
                      "I have read and agree to the terms and conditions"
                      ? true
                      : false
                  }
                />
                <label htmlFor="termsandconditions" className="width-unset">
                  I have read and agree to the terms and conditions.
                </label>
                <div className="tr-pr">
                  <Link to="/terms-conditions" target="_blank">
                    Terms &amp; Conditions
                  </Link>
                  <Link to="/privacy-policy" target="_blank">
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="from-inner-box radeo check-box-input payment-saction">
            <div className="full-fild">
              <label>
                Opt-In? <i>*</i>
              </label>
              <h4>
                I would like to receive notices of offers (for free events,
                opportunities, etc.)
              </h4>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="Yes"
                  id="optin"
                  type="radio"
                  name="optIn"
                  checked={values.optIn === "Yes" ? true : false}
                />
                <label htmlFor="optin">Yes</label>
              </div>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="No"
                  id="optout"
                  type="radio"
                  name="optIn"
                  checked={values.optIn === "No" ? true : false}
                />
                <label htmlFor="optout">No</label>
              </div>
            </div>
            <span className="error marg-top-55">
              {errors.optIn && touched.optIn && errors.optIn}
              {!values.optIn && showRequiredError ? 'Required' : ''}
            </span>
          </div>
          <div className="from-inner-box">
            <div className="full-fild">
              <label>
                Code of Ethics <i>*</i>
              </label>
              <div className="prvacy-box eth-pri">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="have read and agree to abide by the VerySoul"
                  id="ethics"
                  type="checkbox"
                  name="codeOfEthics"
                  defaultChecked={
                    values.legalAndPrivacy ===
                      "have read and agree to abide by the VerySoul"
                      ? true
                      : false
                  }
                />
                <label htmlFor="ethics">
                  I have read and agree to abide by the VerySoul
                  <a
                    href="https://verysoul.com/code-of-ethics/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Code of Ethics
                  </a>
                </label>
                <span className="error">
                  {errors.codeOfEthics &&
                    touched.codeOfEthics &&
                    errors.codeOfEthics}
                  {!values.codeOfEthics && showRequiredError ? 'Required' : ''}
                </span>
              </div>
            </div>
          </div>
          <div className="from-inner-box">
            <div className="full-fild">
              <label>
                Operating Principles <i>*</i>
              </label>
              <div className="prvacy-box eth-pri">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="checkbox"
                  id="principles"
                  value="I have read and agree to abide by the VerySoul"
                  name="operatingPrinciples"
                  defaultChecked={
                    values.operatingPrinciples ===
                      "I have read and agree to abide by the VerySoul"
                      ? true
                      : false
                  }
                />
                <label htmlFor="principles">
                  I have read and agree to abide by the VerySoul{" "}
                  <a
                    href="https://verysoul.com/operating-principle/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Operating Principles
                  </a>
                </label>
                <span className="error">
                  {errors.operatingPrinciples &&
                    touched.operatingPrinciples &&
                    errors.operatingPrinciples}
                  {!values.operatingPrinciples && showRequiredError ? 'Required' : ''}
                </span>
              </div>
            </div>
          </div>

          <div className="from-inner-box">
            <div className="verification-code-section">
              <label>
              Let’s confirm your email.<i></i>
              </label>
              <div className="d-flex">
              {showEmailField ? (
                  <>
                {verifiedTitle !== "Send Verification Code" && (
                  <input
                    onChange={handleChangeVerification}
                    onBlur={handleBlur}
                    className="height-45 margin-right-30"
                    value={verificationCode}
                    type="text"
                    id="codeverification"
                    name="codeverification"
                    placeholder="Enter verification code we send to you here"
                  />
                )}
                
                  {/* <Button
                    status={0}
                    type="button"
                    className={`resend-btn w-unset height-45 min-width-130px ${verifiedTitle === "Verify" ? "bg-green" : ""
                      }`}
                    onClick={time === 0 ? () => sendVerificationCodeHandler(values) : () => { }}
                    title={verifiedTitle}
                  /> */}
                    </>
                ) : (
                  ""
                )}
                  {(verifiedMessage !== "Verified" || showResend) && (
                    <>
                      {resend === 1 && (
                        <Button
                          status={0}
                          type="button"
                          className={`resend-btn w-unset height-45 min-width-130px ml-2`}
                          onClick={reSendVerificationCodeHandler}
                          title={"Resend"}
                        />
                      )}
                      {resend === 2 && <TickMark />}
                    </>
                  )}
                </div>
              
              {verifiedTitle === "Verify" && (
                <>
                  {" "}
                  <br/>
                  <div className="text-left  mt-1">
                    {" "}
                    {timeDisplay ? (
                      <> <i>*</i>Delivery may take up to {secondsLeft} second.
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="text-left mt-1">
                    <i>*</i><div class="hover-container"><span>Can't find it's a wrong email? </span>
                      <span class="hover-target red" tabindex="0">Click here</span>
                      <aside class="hover-popup">
                        <h2></h2>
                        <p>My email is correct, but can’t find code.: <br/><br />1. Check your spam/junk folders <br />
                          2. Search your email for “verysoul” <br />3.Expect significant delays with Yahoo. <br /> 4. Try resending the code by clicking the Resend button <br />5. Request support help here <a class="user-menu-link" href="https://support.verysoul.com/portal/en/newticket" target="_blank">Help &amp; Support</a></p>
                      </aside>
                    </div>
                  </div>
                  {/* <div className="red text-left mt-1">
                    <i>*</i>Please expect significant mail delivery delays if using Yahoo
                    Mail.
                  </div> */}
                </>
              )}
              {verifiedTitle === "Verified" && (
                <div className="text-left font-size-11">
                  {" "}
                  <i>*</i>Email Succesfully Verfied
                </div>
              )}
              <div className={`text-left mt-1 ${verifiedError ? "red" : "green"}`}>
                {verifiedMessage}
                 {changeEmailRequest && (
                  <span
                    onClick={onChangeEmailHandler}
                    className="ml-1 change-email-request pointer"
                  >
                    Change Email
                  </span>
                )} 
              </div>
            </div>

            {/*********starte */}

            {!emailDisabled && (
              <div className="verification-code-section mt-3">
                <div className="d-flex">
                  {emailTitle === "Submit" && (
                    <>
                      {" "}
                      <input
                        onChange={handleChangeEmail}
                        onBlur={handleBlur}
                        className={`height-45 margin-right-30 ${emailDisabled ? "background-beige" : ""
                          }`}
                        value={emailValue}
                        type="text"
                        id="changeemail"
                        name="changeemail"
                        placeholder="Email"
                        disabled={emailDisabled}
                      />
                      <span className="error marg-top-55">{emailError}</span>
                    </>
                  )}

                  <div className="mobile-div">
                    <Button
                      status={0}
                      type="button"
                      className={`resend-btn w-unset height-45 min-width-130px`}
                      onClick={onChangeEmailHandler}
                      title={emailTitle}
                    />
                  </div>
                </div>

                <div className={`text-left green`}>
                  <span>{emailMessage}</span>
                </div>
              </div>
            )}
          </div>
          {/*********end */}
          <div className="thanku-popup">
            <button
              onClick={() => customHandleChange(errors)}
              type="submit"
              className="btn btn-info btn-lg"
              disabled={!verified}
            >
              Save Changes
            </button>
            <div
              className="modal fade"
              id="myModal"
              role="dialog"
              data-backdrop="static"
            >
              <div className="modal-dialog modal-dialog-preview">
                <div className="modal-content">
                  {/* <!--<button type="button" className="close" data-dismiss="modal">&times;</button>--> */}
                  <div className="modal-body">
                    <div className="icon-pop">
                      <img src="images/Union-icon.png" alt="Union-icon" />
                    </div>
                    <p>
                      Thank you for applying to become a medium with VerySoul.
                      All new membership requests are generally reviewed within
                      24 hours and you will be notified by email. If you don’t
                      hear from us after 24 hours, please check your spam and
                      junk folders, search for{" "}
                      <a
                        className="pointer"
                        href="mailto:hello@mail.verysoul.com"
                      >
                        hello@mail.verysoul.com
                      </a>
                      , or just come back to log in at{" "}
                      <a className="pointer" href="app.verysoul.com">
                        app.verysoul.com
                      </a>{" "}
                      .
                    </p>
                    <div className="closebt">
                      <button
                        onClick={logoutUser}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {open && (
              <div className="modal-container ">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <!--<button type="button" className="close" data-dismiss="modal">&times;</button>-->
                    <div className="modal-body">
                      <div className="icon-pop">
                        <img src="images/Union-icon.png" />
                      </div>
                      <p>
                        Thank you for applying to become a medium with VerySoul.
                        We deeply appreciate your interest in joining us on this
                        mission. All new membership requests will be reviewed in
                        the order received but are generally processed within 24
                        to 48 hours. Please watch for an email with further
                        information. Thank you!
                      </p>
                      <div className="closebt">
                        <button
                          onClick={logoutUser}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )} */}
          </div>
        </form>
      )}
    </Formik>
  );
};
export default StepFour;
