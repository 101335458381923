import React from "react";
import Schedules from "../../../admin/calendar/schedules";

export default function MySchedules({
  medium_id,
  openScheduleForm,
  setScheduleId,
}) {
  return (
    <div>
      <Schedules
        type="medium"
        medium_id={medium_id}
        openScheduleForm={openScheduleForm}
        setScheduleId={setScheduleId}
      />
    </div>
  );
}
