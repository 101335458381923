import React, { useCallback, useEffect, useMemo, useState } from "react";
// import BookingCalendar from "./calendar";
import moment from "moment-timezone";
import { getTimezones } from "../../../../services/uidata";
import { getSlotsByDate, getTimeSlotsByDate } from "../../../../services/client";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setLoadingContent, setLoadingText } from "../../../../reducer/auth/auth";
import { toast } from "react-toastify";
import BookingCalendar from "./calendarscreen";
import { getCoachingMediumList, getDateCountMedium, getSlotsByDateMedium } from "../../../../services/medium";
import ProfileCard from "../componetnts/profilecard";
import PayOptionsPopUp from "../componetnts/paymentoptions";


export default function BookingPageOld({
    setMediumVale, bookingDate, serviceValue, sessionLength,
    setSessionLength, sessionLengthList, openForm, setBookingDate, setBookingTime, setTimeZoneInfo, setsession_id,
    setsubsession_id, setTimeZoneName, setPrice,
    setDuration, setPaymentInfo, setCurrency, backHandler, mediumValue, bookingTime, mediumList, setMediumList, setPracticeMediums }) {
    const { timezone } = useSelector(
        (state) => state.user.user
    );
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [pageType, setPageType] = useState('selection');
    const [timezoneData, setTimezoneData] = useState([]);
    const [timezon, setTimezone] = useState("");
    const [timeZoneValue, setTimeZoneValue] = useState("");
    const [appointmentTyepList, setAppointmentTyepList] = useState([]);
    const [dateCount, setDateCount] = useState({});
    const [activeSessionId, setActiveSessionId] = useState("");
    const [activeSubSessionId, setActiveSubSessionId] = useState("");
    const [activeIndex, setActiveIndex] = useState();

    //
    const [slots, setSlots] = useState([]);
    const [slotsupdated, setSlotsUpdated] = useState(false);
    const [active, setActive] = useState(false);
    const [count, setCount] = useState(0);
    const [loadCalcender, setLoadCalendar] = useState('');
    const [mediumLoaded, setMediumLoaded] = useState(false);
    const [value, setVlaue] = useState(true);
    const [resetCalander, setResetCalcendar] = useState(true);
    const [month_empty, setmonth_empty] = useState(false);



    useEffect(() => {
        setmonth_empty(false)
        document.body.classList.remove('modal-open');
        if (serviceValue === 2) {

            let today = moment().format("YYYY-MM-DD")
            // getTimeSlots(today, "mediumlistnoupdate")
        }

        setTimezone(moment.tz.guess());
        // dispatch(setLoading(true))
    }, []);
    useEffect(() => {
        // if (timezone !== "") {
        updateTimezones(timezone);
        // }
    }, []);

    useEffect(() => {
        if (serviceValue === 2 && bookingTime !== "" && (mediumValue === undefined || mediumValue === "")) {
            getTimeSlots(bookingDate, "mediumlist", undefined, "effect")
        }
    }, [bookingTime])



    const getMediumList = () => {
        let body = {
            service: serviceValue,
            session_length: sessionLength,
        }
        getCoachingMediumList(body).then((data) => {
            dispatch(setLoading(false))
            if (data.data.status) {
                setMediumLoaded(true)
                setActiveIndex();
                let newArray = data.data.data.map((info) => {
                    if (info.coaching_pricing === null) {
                        return {
                            ...info,
                            whatTime: 'first'
                        }

                    }

                    else if (Object.values(JSON.parse(JSON.parse(info.coaching_pricing)))[0] === '0') {
                        return {
                            ...info,
                            coaching_pricing: null,
                            whatTime: 'first'
                        }
                    }
                    else {
                        return {
                            ...info,
                            whatTime: 'first'
                        }
                    }


                })
                localStorage.setItem('listmedium', JSON.stringify(newArray))
                setMediumList(newArray);
            }
        }).catch(() => { })
    }
    const onChangeSessionHandler = (e) => {
        setSlotsUpdated(false);
        setActive(false);

        let value = e.target.value;
        console.log(value)
        console.log(sessionLengthList, "appointmentTyepList")
        let item = sessionLengthList.filter(
            (info) => parseInt(info.id) === parseInt(value)
        );
        setSessionLength(value);
        // setPageType('selection');
        // setActiveSubSessionId(item[0].subsession_id);
        // setActiveSessionId(item[0].session_id);

        // setsession_id(item[0].session_id);
        // setPrice(item[0].amount);
        // setDuration(item[0].duration);
        // setCurrency(item[0].currency)
        // setsubsession_id(item[0].subsession_id);

    };
    const changeTimeZoneHandler = (e) => {
        let id = e.target.value;
        let info = timezoneData.filter((time) => {
            return time.id === parseInt(id);
        });
        setTimeZoneName(info[0].front_name);
        setTimeZoneValue(id);
        setTimeZoneInfo(id);
        setSlotsUpdated(false);
        setActive(false);
    };
    const updateTimezones = (timezone) => {
        getTimezones()
            .then((data) => {
                setTimezoneData(data.data.data);

                let info = data.data.data.filter((time) => {
                    return time.front_name === timezone;
                });
                console.log(info);
                if (info.length) {
                    console.log(info[0].id)
                    // setTimeZoneName(info[0].front_name);
                    setTimeZoneValue(info[0].id);
                    // setTimeZoneInfo(info[0].id)
                }
            })
            .catch((err) => console.log(err));
    };


    const getDatesCount = (dates) => {
        dispatch(setLoadingContent(`<div>Searching for availability... we'll have the results shortly! </div>`));
        dispatch(setLoadingText(true));
        // dispatch(setLoading(true))
        if (!mediumLoaded) {

            getMediumList()
        }
        let body = {
            medium_id: mediumValue !== null && mediumValue !== undefined ? mediumValue.id : "",
            startDate: dates.start,
            endDate: dates.end,
            service: serviceValue,
            session_length: sessionLength,
        };
        getDateCountMedium(body)
            .then((data) => {


                if (data.data.status) {
                    setmonth_empty(data.data.externalInfo.month_empty)
                        if (data.data.externalInfo.month_empty) {
                            window.scrollTo({
                                left: 0,
                                top: 700,
                                behavior: "smooth",
                            });
                        }
                    if (JSON.parse(localStorage.getItem('listmedium')) == 0 && serviceValue == 2) {
                        let newObj = Object.keys(data.data.data).reduce((acc, key) => {
                            acc[key] = false;
                            return acc;
                        }, {});
                        
                        setDateCount(newObj)
                        setCount(count + 1)
                    }
                    else {
                        setmonth_empty(false)
                        setDateCount(data.data.data);
                        setCount(count + 1)
                    }



                }
                else {
                    toast.error(data.data.message, {
                        position: "top-center",
                    });
                }
                dispatch(setLoadingContent(""));
                dispatch(setLoadingText(false));
            })
            .catch(() => {
                dispatch(setLoadingContent(""));
                dispatch(setLoadingText(false));
            });
    };

    const getTimeSlots = (date, type, item = "", effect) => {
        if (effect !== "effect") {
            dispatch(setLoadingContent(`<div>Searching for availability... we'll have the results shortly! </div>`));
            dispatch(setLoadingText(true));
            // dispatch(setLoading(true))
            setSlotsUpdated(false);
            setActive(false);
        }
        setBookingDate(date);
        let body = {
            medium_id: type === "mediumlistnoupdate" ? item : mediumValue !== null && mediumValue !== undefined ? mediumValue.id !== undefined ? mediumValue.id : "" : "",
            date: date,
            timezone: timeZoneValue,
            session_id: serviceValue,
            subsession_id: sessionLength,
            is_medium_list: type === "mediumlist" ? 1 : 0,
            time: bookingTime
        }
        getSlotsByDateMedium(body)
            .then((data) => {

                if (effect !== "effect") {


                    if (data.data.status) {
                        if (serviceValue === 1) {
                            setPracticeMediums(data.data.data.mediums)
                        }
                        if (type !== "mediumlist") {

                            setSlotsUpdated(true);
                            setActive(true);
                            window.scrollTo({
                                left: 0,
                                top: document.body.scrollHeight,
                                behavior: "smooth",
                            });

                            setSlots(
                                data.data.data.slots.map((value) => {
                                    return { datetime: value, selectedStatus: false, status: false };
                                })
                            );
                        }
                        else if (type === "mediumlistnoupdate") {

                            setSlotsUpdated(true);
                            setActive(true);
                            window.scrollTo({
                                left: 0,
                                top: document.body.scrollHeight,
                                behavior: "smooth",
                            });

                            setSlots(
                                data.data.data.slots.map((value) => {
                                    return { datetime: value, selectedStatus: false, status: false };
                                })
                            );

                        }
                        else {
                            setActiveIndex();
                            let newArray = data.data.data.mediums.map((info) => {
                                if (info.coaching_pricing === null) {
                                    return info

                                }

                                else if (Object.values(JSON.parse(JSON.parse(info.coaching_pricing)))[0] === '0') {
                                    return {
                                        ...info,
                                        coaching_pricing: null
                                    }
                                }
                                else {
                                    return info
                                }


                            })
                            console.log(newArray, "newArray");
                            setMediumList(newArray);
                            setSlotsUpdated(true);
                            setActive(true);
                            window.scrollTo({
                                left: 0,
                                top: document.body.scrollHeight,
                                behavior: "smooth",
                            });

                            setSlots(
                                data.data.data.slots.map((value) => {
                                    return { datetime: value, selectedStatus: false, status: false };
                                })
                            );
                        }
                        // setTimeout(() => {
                        dispatch(setLoadingContent(""));
                        dispatch(setLoadingText(false));
                        // dispatch(setLoading(false))
                        // }, 6000)
                    }

                    else {
                        toast.error(data.data.message, {
                            position: "top-center",
                        });
                    }
                }
                else {
                    setActiveIndex();
                    let newArray = data.data.data.mediums.map((info) => {
                        if (info.coaching_pricing === null) {
                            return info

                        }

                        else if (Object.values(JSON.parse(JSON.parse(info.coaching_pricing)))[0] === '0') {
                            return {
                                ...info,
                                coaching_pricing: null
                            }
                        }
                        else {
                            return info
                        }

                    })

                    setMediumList(newArray);
                }
            })
            .catch(() => {
                dispatch(setLoadingContent(""));
                dispatch(setLoadingText(false));
            });
    };
    const openCalendar = () => {
        setPageType('calendar');
    }
    const getslotsFromMedium = (item) => {
        setmonth_empty(false)
        setResetCalcendar(false);


        setTimeout(() => {

            setResetCalcendar(true)
        }, 100);
        setMediumVale(item)
        if (item.whatTime === "first") {
            setLoadCalendar(item.id);
        }
        if (serviceValue !== 2) {

            setBookingTime("");
        }
        if (bookingTime === "" && item.whatTime !== "first") {

            getTimeSlots(bookingDate, "mediumlistnoupdate", item.id)
        }
    }

    const checkPyament = () => {


        // return;
        if (serviceValue === 2) {

            if (activeIndex === undefined && mediumList.some((item) => item.coaching_pricing === null)) {
                let newbody = {
                    booking_type: 'free'
                }
                openForm(newbody);

            }
            else if (activeIndex !== undefined && mediumList.some((item) => item.coaching_pricing === null)) {
                let newbody = {
                    booking_type: 'free'
                }
                openForm(newbody);
            }
            else {


                // if (activeIndex === undefined) {
                //     toast.error("Please Select Medium", {
                //         position: "top-center",
                //     });
                //     return;
                // }

                if (bookingTime === "") {
                    toast.error("Please Select Time", {
                        position: "top-center",
                    });
                    return;
                }
                window.loadModal2("#payoptionspopupmediumbooking", "show");
                window.removeBackdrop();
            }
        } else {
            openForm()
        }
    }
    const createBooking = (item) => {

        let i = mediumList.filter((item) => item.coaching_pricing !== null)[0]
        let newbody = {
            amount: activeIndex !== undefined ? Object.values(JSON.parse(JSON.parse(mediumList[activeIndex].coaching_pricing)))[0] : Object.values(JSON.parse(JSON.parse(i.coaching_pricing)))[0],
            appointment_type: 'pay_before',
            booking_type: 'paid',
            method: item.paymenttype,

        }
        console.log(mediumValue, activeIndex);

        if (activeIndex == undefined && mediumValue === '' && mediumList.length > 0) {
            let body = {
                medium_id: mediumList[0].id
            }
            Object.assign(newbody, body);
        }
        openForm(newbody)
    }
    const resetForm = () => {
        dispatch(setLoading(true))
        setMediumList([]);
        setBookingDate('');
        setBookingTime('');
        setMediumVale('');
        setActiveIndex();
        setSlotsUpdated(false);
        setSlots([]);
        getMediumList();
        setResetCalcendar(false);


        setTimeout(() => {

            setResetCalcendar(true)
        }, 100);
    }
    return (
        <>

            <div className="container mt-5">

                {/* <div>
                    <div className="middle-screen-name w-100 font-size-22 mt-2 mb-2  text-center">

                        <select
                            value={timeZoneValue}
                            onChange={changeTimeZoneHandler}
                            className="select-box-timezone pointer width-440px"
                        >
                            <option disabled selected value="">
                                Change Timezone
                            </option>
                            {timezoneData.map((item) => (
                                <option value={item.id}>{item.front_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="middle-screen-name w-100 font-size-22 mt-2 mb-2  text-center">
                        <select
                            onChange={onChangeSessionHandler}
                            className="subsession-box"
              
                        >
                            <option disabled value={""}>
                                Appoinment Type
                            </option>

                            {appointmentTyepList.map((item, index) => (
                                <option data-item={item} value={item.subsession_id}>
                                    {" "}
                                    {item.name}
                                </option>
                            ))}
                        </select>
                        <span className="d-inline-block p-2"> &nbsp;Price :${activeAmount}&nbsp;{activeCurrency}</span>
                    </div>
                </div> */}

            </div>

            <div>


                <div className="container mt-5">
                    <div>

                        <div className="middle-screen-name w-100 font-size-22 mt-2 mb-2  text-center">
                            <select
                                onChange={onChangeSessionHandler}
                                className="subsession-box"
                                value={sessionLength}
                            >
                                <option disabled value={""}>
                                    Change Session Length
                                </option>

                                {sessionLengthList.map((item, index) => (
                                    <option selected={item.id === sessionLength} data-item={item} value={item.id}>
                                        {" "}
                                        {item.sub_session_name}
                                    </option>
                                ))}
                            </select>
                            {/* <span className="d-inline-block p-2"> &nbsp;Price :${activeAmount}&nbsp;{activeCurrency}</span> */}
                        </div>
                    </div>
                    {/* <h4 className='text-center'>Please select service type from dropdown above to get started.</h4> */}
                </div>
                <div className={`${pageType === "selection" ? 'd-none' : 'd-block'}`}>
                    <div className="p-5 m-5">

                    </div>
                    <div className="p-5 m-5">

                    </div>
                    <div className="p-5 m-5">

                    </div>
                </div>
                <div className={`${pageType === "selection" ? 'd-block' : 'd-none'}`}>
                    <div className="schedule-advance-saction d-flex">
                        {serviceValue === 2 &&
                            <div className="calenl-scn">
                                <div className="ml-2 d-flex align-items-center justify-content-between mr-2"
                                ><span
                                    className="text-underline pointer min-width-120 d-inline-block"
                                    href="#"

                                >Coach List</span>
                                    <img
                                        title="Refresh"
                                        onClick={resetForm}
                                        className="googl-link-copy-img copy-image-animate refresh-btn-app mt-4"
                                        alt="refresh"
                                        src="images/refresh.png"
                                    />
                                </div>
                                {mediumList.length > 0 && mediumList.map((item, index) => <ProfileCard onClick={getslotsFromMedium} item={item} index={index} activeIndex={activeIndex} setActiveIndex={setActiveIndex} bookingTime={bookingTime} />)}
                            </div>}
                        <div className="container">
                            <div className="row">
                                {resetCalander && <BookingCalendar

                                    getDatesCount={getDatesCount}
                                    timeZoneValue={timeZoneValue}
                                    dateCount={dateCount}
                                    activeSubSessionId={sessionLength}
                                    getTimeSlots={getTimeSlots}
                                    slots={slots}
                                    setSlots={setSlots}
                                    setBookingDate={setBookingDate}
                                    setBookingTime={setBookingTime}
                                    slotsupdated={slotsupdated}
                                    setSlotsUpdated={setSlotsUpdated}
                                    active={active}
                                    serviceValue={serviceValue}
                                    loadCalcender={loadCalcender}
                                    mediumList={mediumList}
                                    mediumValue={mediumValue}
                                    bookingTime={bookingTime}
                                    bookingDate={bookingDate}
                                    month_empty={month_empty}
                                />}
                            </div>
                        </div>

                    </div>
                    {/**********progress bar */}
                    <div className="progress-saction">
                        <div className="container">
                            <ul>
                                <li
                                    onClick={() => backHandler('SELECTION')}
                                    className="back"
                                >
                                    <a href="#">
                                        <img src="images/black-erow.png" alt="back" />
                                        Back
                                    </a>
                                </li>

                                <li className="continue">
                                    <div className="dont-have-acc">
                                        {/* sessionType==="prof"?  () =>"": */}
                                        <button
                                            onClick={checkPyament}
                                            type="button"
                                            className="btn btn-info btn-lg width-unset-img"
                                        // data-toggle="modal"
                                        // data-target="#myModal"
                                        >
                                            Book Appointment
                                            <img
                                                className="width-unset-img"
                                                src="../images/wight-erow.png"
                                                alt="book appointment"
                                            />
                                        </button>

                                    </div>
                                </li>
                            </ul>
                        </div>
                        <PayOptionsPopUp createBooking={createBooking} paymentInfo={activeIndex !== undefined ? mediumList[activeIndex] : mediumList.filter((item) => item.coaching_pricing !== null)[0]} />

                    </div>
                </div>
            </div>

        </>
    );
}