import React from "react";
import PhoneInput from "react-phone-input-2";

export default function PhoneInputField({
  className,
  country,
  handleBlur,
  value,
  id,
  name,
  setFieldValue,
  disabled = false,
  setValue,
}) {
  const handleChange = (val) => {
    if (setFieldValue !== undefined) {
      setFieldValue(name, val);
    } else {
      setValue(val);
    }
  };

  return (
    <PhoneInput
      className={className}
      country={country}
      onChange={(val) => handleChange(val)}
      onBlur={handleBlur}
      value={value}
      id={id}
      disabled={disabled}
      name={name}
      countryCodeEditable={false}
    />
  );
}
