import React from "react";
import { useNavigate } from "react-router-dom";

export default function ForceBackHandler({ setloaderType }) {
    const navigate = useNavigate();
    // const { id  } = useSelector((state) => state.user.user);



    const navigatePage = () => {
       window.loadModal2("#ForceBackHandler", "hide")
        setTimeout(() => {
            navigate('/appointments')
        }, 100)

    }
    return (
        <div>
            <div
                id="ForceBackHandler"
                class="modal fade bd-example-modal-lg mt-5 preview-modal-availability"
                tabindex="-1"
                role="dialog"
                aria-labelledby="myLargeModalLabel"
                aria-hidden="true"
                data-backdrop="static"
            >
                <div class="modal-dialog modal-lg modal-dialog-preview text-center">
                    <div class="modal-content ">
                        <div className="no-app-fount-box">

                            <p className="font-size-18">Are you sure you want to quit?


                            </p>


                            <br />
                            {/* <p className="font-size-18">Please check back for a future On Demand session, or try booking a Practice session in advance. */}
                            {/* </p> */}
                            <div className="d-flex align-items-center justify-content-around">

                                <div
                                    onClick={() => window.loadModal2("#ForceBackHandler", "hide")}

                                    className="width-215-px white-border pointer indexstyles__StyledButton-sc-83qv1q-0 jdbotF sc-1qeub3n-4  d-flex align-items-center justify-content-center"
                                >
                                  No
                                </div>
                                <div
                                    onClick={navigatePage}
                                    className="width-215-px white-bg-btn pointer indexstyles__StyledButton-sc-83qv1q-0 jdbotF sc-1qeub3n-4  d-flex align-items-center justify-content-center"
                                >
                                     Yes
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
