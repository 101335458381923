import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { setLoading } from "../../../../reducer/auth/auth";
import {
  getZoomAuthUrl,
  deauthorizeCalendly,
} from "../../../../services/uidata";
import { useDispatch, useSelector } from "react-redux";
import { updateUserTechnologies } from "../../../../services/profile";
import { setLinkZoomRequest } from "../../../../reducer/mediumSlice";
import { toast } from "react-toastify";

import Allow from "../../../../assets/allow.png";
import { unLinkUserZoom } from "../../../../reducer/userSlice";
export default function MyTechnology(props) {
  const dispatch = useDispatch();
  const { test_mode } = useSelector(
    (state) => state.user.user
  );
  const { linkZoomRequest } = useSelector((state) => state.medium);
  const { stepThreeData, getTechnologies, stepOneData } = props;
  // const [authorize, setAuthorize] = useState(false);
  const [blink, setBlink] = useState(false);
  

  useEffect(() => {
    if (linkZoomRequest) setBlink(true);
    dispatch(setLinkZoomRequest(false));
  }, [linkZoomRequest]);
  useEffect(() => {
    // setAuthorize(
    //   stepOneData.provider && stepOneData.provider === "Zoom" ? true : false
    // );
  }, [stepOneData]);

  const getAuthUrl = () => {
    dispatch(setLoading(true));
    getZoomAuthUrl("integration")
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          window.open(data.data.data.redirect_url, "_self");
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  const deauthorize = () => {
    dispatch(setLoading(true));
    deauthorizeCalendly()
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          // setAuthorize(false);
          stepOneData.provider = "";
          dispatch(unLinkUserZoom());
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  const RunFunction = (formData) => {
    dispatch(setLoading(true));
    // setValues(formData);
    const {
      electronicCalendars,
      zoomAccountType,
      preferredDevice,
      preferredBrowser,
      zoom_personal_link,
      paypal_me_link
    } = formData;
    let item = {
      calendar_type: electronicCalendars,
      zoom_account_type: zoomAccountType,
      preferred_device: preferredDevice,
      preferred_browser: preferredBrowser,
      zoom_personal_link,
      paypal_me_link: paypal_me_link
    };
    updateUserTechnologies(item)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          // NextStep();
          toast.success("Profile Updated", { position: "top-center" });
          getTechnologies();
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };
  const changeHandler = (event, electronicCalendars, setFieldValue) => {
    const noneIndex = electronicCalendars.indexOf("None");
    console.log(noneIndex);
    let array = electronicCalendars;
    if (event.target.checked && event.target.value === "None") {
      array = ["None"];
      setFieldValue("electronicCalendars", array);
    }
    if (event.target.checked && event.target.value !== "None") {
      if (noneIndex !== -1) {
        array.splice(noneIndex, 1);
      }
      array.push(event.target.value);
      setFieldValue("electronicCalendars", array);
    }
    if (!event.target.checked) {
      // array.splice(noneIndex, 1);
      const index = array.indexOf(event.target.value);
      array.splice(index, 1);
      setFieldValue("electronicCalendars", array);
    }
    console.log(array);
  };
  return (
    <Formik
      initialValues={stepThreeData}
      validate={(values) => {
        const errors = {};
        if (!values.electronicCalendars.length > 0)
          errors.electronicCalendars = "Required";
        if (!values.zoomAccountType) errors.zoomAccountType = "Required";
        if (!values.preferredDevice.length > 0)
          errors.preferredDevice = "Required";
        if (!values.preferredBrowser.length > 0)
          errors.preferredBrowser = "Required";
        // if (!values.lastName) errors.lastName = "Required";
        // if (!values.phone) errors.phone = "Required";
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        RunFunction(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form
          onSubmit={handleSubmit}
          className="tab-pane"
          role="tabpanel"
          id="step3"
        >
          <div className="row-one-step payment-saction">
            <div className="full-fild">
              <label>Which electronic Calendars do you use currently? *</label>
              <div className="check-field">
                <input
                  onChange={(e) =>
                    changeHandler(e, values.electronicCalendars, setFieldValue)
                  }
                  onBlur={handleBlur}
                  id="googlecalendar"
                  type="checkbox"
                  checked={
                    values.electronicCalendars.includes("Google Calendar")
                      ? true
                      : false
                  }
                  value="Google Calendar"
                  name="electronicCalendars"
                />
                <label htmlFor="my-check">Google Calendar</label>
              </div>
              <div className="check-field">
                <input
                  onChange={(e) =>
                    changeHandler(e, values.electronicCalendars, setFieldValue)
                  }
                  onBlur={handleBlur}
                  id="icloundcalendar"
                  type="checkbox"
                  value="Cloud Calendar"
                  checked={
                    values.electronicCalendars.includes("Cloud Calendar")
                      ? true
                      : false
                  }
                  name="electronicCalendars"
                />
                <label htmlFor="my-checktow">iCloud Calendar</label>
              </div>
              <div className="check-field">
                <input
                  onChange={(e) =>
                    changeHandler(e, values.electronicCalendars, setFieldValue)
                  }
                  onBlur={handleBlur}
                  id="Outlook"
                  type="checkbox"
                  checked={
                    values.electronicCalendars.includes("Outlook")
                      ? true
                      : false
                  }
                  value="Outlook"
                  name="electronicCalendars"
                />
                <label htmlFor="my-checktow">Outlook</label>
              </div>
              <div className="check-field">
                <input
                  onChange={(e) =>
                    changeHandler(e, values.electronicCalendars, setFieldValue)
                  }
                  onBlur={handleBlur}
                  id="365calendar"
                  type="checkbox"
                  checked={
                    values.electronicCalendars.includes("Office 365 Calendar")
                      ? true
                      : false
                  }
                  value="Office 365 Calendar"
                  name="electronicCalendars"
                />
                <label htmlFor="my-checktow">Office 365 Calendar</label>
              </div>
              <div className="check-field">
                <input
                  onChange={(e) =>
                    changeHandler(e, values.electronicCalendars, setFieldValue)
                  }
                  onBlur={handleBlur}
                  id="exchangecalendar"
                  type="checkbox"
                  checked={
                    values.electronicCalendars.includes("Exchange Calendar")
                      ? true
                      : false
                  }
                  value="Exchange Calendar"
                  name="electronicCalendars"
                />
                <label htmlFor="my-checktow">Exchange Calendar</label>
              </div>
              <div className="check-field">
                <input
                  onChange={(e) =>
                    changeHandler(e, values.electronicCalendars, setFieldValue)
                  }
                  onBlur={handleBlur}
                  id="othercalendar"
                  type="checkbox"
                  value="Other"
                  checked={
                    values.electronicCalendars.includes("Other") ? true : false
                  }
                  name="electronicCalendars"
                />
                <label htmlFor="my-checktow">Other</label>
              </div>
              <div className="check-field">
                <input
                  onChange={(e) =>
                    changeHandler(e, values.electronicCalendars, setFieldValue)
                  }
                  onBlur={handleBlur}
                  id="nonecalendar"
                  type="checkbox"
                  checked={
                    values.electronicCalendars.includes("None") ? true : false
                  }
                  value="None"
                  name="electronicCalendars"
                />
                <label htmlFor="my-checktow">None</label>
              </div>
              {touched.electronicCalendars && values.electronicCalendars.length > 0 && !values.electronicCalendars.includes("Google Calendar") ?
                <label className="font-size-11">{"Note: You will need a Google Calendar to connect to our system but we will help you connect that with your other personal calendar."}</label> : ""}
              <span className="error margin-top-115">
                {errors.electronicCalendars &&
                  touched.electronicCalendars &&
                  errors.electronicCalendars}
              </span>
            </div>

          </div>
          {test_mode === 1 &&
            <div class="row-one-step payment-saction in-order">
              <div class="full-fild">
                <label>Please enter your personal zoom link.</label>
                <input
                  className={`mb-2 ${blink ? "alerts-border background-unset" : ""} ${errors.zoom_personal_link &&
                      touched.zoom_personal_link &&
                      errors.zoom_personal_link
                      ? "error-boundry"
                      : ""
                    }`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.zoom_personal_link}
                  id="zoom_personal_link"
                  name="zoom_personal_link"
                  type="text"
                  placeholder="Zoom Link"
                />
                <h6>

                  To know your personal zoom link{" "}
                  <a
                    href="https://support.zoom.us/hc/en-us/articles/201362843-Personal-meeting-ID-PMI-and-personal-link"
                    target="_blank"

                    rel="noopener noreferrer"

                  >
                    Click here
                  </a>
                </h6>

              </div>
            </div>
          }
          <div class="row-one-step payment-saction in-order">
            <div class="full-fild">
              <label>
                In order for others to book with you, you will need a personal
                Zoom account. Which type do you have?
              </label>

              <p class="signup">
                {stepOneData.provider && stepOneData.provider === "Zoom" ? (
                  <a href="javascript:void(0)" onClick={deauthorize}>
                    UnLink your Zoom account
                  </a>
                ) : (
                  <>
                    <a
                      href="javascript:void(0)"
                      className="margin-unset"
                      onClick={getAuthUrl}
                    >
                      Link your Zoom account.
                    </a>
                    <p className="mt-2 ">
                      <span className="font-size-11 d-block">
                        {" "}
                        *On the Zoom Authorisation page be sure to select the
                        checkbox before clicking Allow
                      </span>
                      <img className="allow-image" src={Allow} alt="allow" />
                    </p>
                  </>
                )}
              </p>
              <div class="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="I have a paid account"
                  id="zoompaidacc"
                  type="radio"
                  checked={
                    values.zoomAccountType === "I have a paid account"
                      ? true
                      : false
                  }
                  name="zoomAccountType"
                />
                <label htmlFor="my-check">I have a paid account</label>
              </div>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="I have a free account"
                  id="zoomfreeaccount"
                  type="radio"
                  checked={
                    values.zoomAccountType === "I have a free account"
                      ? true
                      : false
                  }
                  name="zoomAccountType"
                />
                <label htmlFor="my-checktow">I have a free account</label>
              </div>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="None"
                  id="nonezoomacc"
                  type="radio"
                  checked={values.zoomAccountType === "None" ? true : false}
                  name="zoomAccountType"
                />
                <label htmlFor="my-checktow">None</label>
              </div>
            </div>
            <span className="error marg-top-55">
              {errors.zoomAccountType &&
                touched.zoomAccountType &&
                errors.zoomAccountType}
            </span>
          </div>
          <div className="row-one-step all-devices">
            <div className="full-fild">
              <label>What is your preferred device when using Zoom?</label>
              <ul className="ul-li-list">
                <li>
                  <div className="check-field">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="windows"
                      type="checkbox"
                      value="windows"
                      checked={
                        values.preferredDevice.includes("windows")
                          ? true
                          : false
                      }
                      name="preferredDevice"
                    />
                    <label htmlFor="windows">
                      Windows - Personal
                      <br />
                      Computer / Laptop
                    </label>
                  </div>
                </li>
                <li>
                  <div className="check-field">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="tablet-ipad"
                      type="checkbox"
                      checked={
                        values.preferredDevice.includes("Mac") ? true : false
                      }
                      value="Mac"
                      name="preferredDevice"
                    />
                    <label htmlFor="tablet-ipad"> Mac</label>
                  </div>
                </li>
                <li>
                  <div className="check-field">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="tablet-android"
                      type="checkbox"
                      checked={
                        values.preferredDevice.includes("Tablet-iPad")
                          ? true
                          : false
                      }
                      value="Tablet-iPad"
                      name="preferredDevice"
                    />
                    <label htmlFor="tablet-android">Tablet-iPad</label>
                  </div>
                </li>
                <li>
                  <div className="check-field">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="iPhone"
                      type="checkbox"
                      value="iPhone"
                      checked={
                        values.preferredDevice.includes("iPhone") ? true : false
                      }
                      name="preferredDevice"
                    />
                    <label htmlFor="iPhone">iPhone</label>
                  </div>
                </li>
                <li>
                  <div className="check-field">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="android"
                      type="checkbox"
                      checked={
                        values.preferredDevice.includes("Android")
                          ? true
                          : false
                      }
                      value="Android"
                      name="preferredDevice"
                    />
                    <label htmlFor="android">Android</label>
                  </div>
                </li>
                <li>
                  <div className="check-field">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="Otherdevice"
                      type="checkbox"
                      checked={
                        values.preferredDevice.includes("Other") ? true : false
                      }
                      value="Other"
                      name="preferredDevice"
                    />
                    <label htmlFor="Otherdevice">Other</label>
                  </div>
                </li>
              </ul>
            </div>
            <span className="error">
              {errors.preferredDevice &&
                touched.preferredDevice &&
                errors.preferredDevice}
            </span>
          </div>
          <div className="row-one-step all-devices lastrow">
            <div className="full-fild">
              <label>What is your preferred browser when using zoom?</label>
              <ul className="ul-li-list browser-saction">
                <li>
                  <div className="check-field">
                    <input
                      className="z-index-1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="chrome"
                      type="checkbox"
                      value="Google Chrome"
                      checked={
                        values.preferredBrowser.includes("Google Chrome")
                          ? true
                          : false
                      }
                      name="preferredBrowser"
                    />
                    <div className="icon-image z-index-0">
                      <img src="images/chrome.png" alt="chrome" />
                    </div>
                    <label htmlFor="chrome">
                      Google <br />
                      Chrome
                    </label>
                  </div>
                </li>
                <li>
                  <div className="check-field">
                    <input
                      className="z-index-1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="firefox"
                      type="checkbox"
                      value="FireFox"
                      checked={
                        values.preferredBrowser.includes("FireFox")
                          ? true
                          : false
                      }
                      name="preferredBrowser"
                    />

                    <div className="icon-image z-index-0">
                      <img src="images/mozila.png" alt="mozila" />
                    </div>
                    <label htmlFor="firefox">FireFox</label>
                  </div>
                </li>
                <li>
                  <div className="check-field">
                    <input
                      className="z-index-1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="edge"
                      type="checkbox"
                      value="Edge"
                      checked={
                        values.preferredBrowser.includes("Edge") ? true : false
                      }
                      name="preferredBrowser"
                    />
                    <div className="icon-image z-index-0">
                      <img src="images/entnet.png" alt="edge" />
                    </div>
                    <label htmlFor="edge">Edge</label>
                  </div>
                </li>
                <li>
                  <div className="check-field">
                    <input
                      className="z-index-1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="safari"
                      type="checkbox"
                      value="Safari"
                      checked={
                        values.preferredBrowser.includes("Safari")
                          ? true
                          : false
                      }
                      name="preferredBrowser"
                    />
                    <div className="icon-image z-index-0">
                      <img src="images/safari.png" alt="safari" />
                    </div>
                    <label htmlFor="safari">Safari</label>
                  </div>
                </li>
                <li>
                  <div className="check-field">
                    <input
                      className="z-index-1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="otherbrowser"
                      type="checkbox"
                      value="other"
                      checked={
                        values.preferredBrowser.includes("other") ? true : false
                      }
                      name="preferredBrowser"
                    />
                    <div className="icon-image z-index-0">
                      <img src="images/word-tow.png" alt="other" />
                    </div>
                    <label htmlFor="otherbrowser">other</label>
                  </div>
                </li>
              </ul>
              <h6 className="note">{values.preferredBrowser.includes("other") ? "Note: For optimum performance: use Chrome, Safari, or Edge browser" : ''}</h6>

              <span className="error">
                {errors.preferredBrowser &&
                  touched.preferredBrowser &&
                  errors.preferredBrowser}
              </span>
            </div>
            <br />
            <div className="left-side">
              <label>
                Paypal.me Link <span className="font-size-12">(only needed once you qualify for Outreach and Paying clients with us)</span>
              </label>
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.paypal_me_link}
                type="text"
                id="paypal_me_link"
                name="paypal_me_link"
                placeholder="paypal link"
              />
            </div>
          </div>
          <div className="update">
            <button type="submit" className="update">
              Update
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
}
