import React, { useEffect, useState } from "react";
import Pusher from "pusher-js";
import { useSelector, useDispatch } from "react-redux";
// import { ClientID, ClientSecret, RedirectURL } from "../utils/utils";
import { useLocation, useNavigate, useParams } from 'react-router-dom';


import { Link } from "react-router-dom";
import Header from "../components/header/header";
import Sidebar from "../components/sidebar/sidebar";
import { acceptSession, getMediumAppointments } from "../services/medium";
import { getZoom } from "../services/client";
import { setLoading } from "../reducer/auth/auth";
import {
  setaAppointmentsList,
  setLinkZoomRequest,
  setMatchingStarted,
} from "../reducer/mediumSlice";
import CountdownTimer from "./countdown";
import OpenUrlPopUp from "./openUrlPopUp";
import ringer from "../assets/ting.mp3";

//functions
import { changeOnlineStatusFunc } from "../commonfunctions";
import { getLiveStatistics } from "../commonfunctions/layout";
import Button from "../components/button";

export default function Layout({ children }) {
  const param = useParams();

  const audio = new Audio(ringer);
  const location = useLocation()
  const { pathArray } = useSelector((state) => state.users);
  const { pathClientArray, windowObject } = useSelector(
    (state) => state.client
  );
  const { status, medium_status, id } = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  // let status = localStorage.getItem("status");
  // let user_status = localStorage.getItem("medium_status");

  let user_status = medium_status;
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [meetingData, setMeetingData] = useState({});

  // const [userDetails, setUserDetails] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [counterStatus, setCounterStatus] = useState(false);
  const [toogle, setToogle] = useState(false);
  const [meetingUrl, setmeetingUrl] = useState("");
  // const [popupOpen, setPopupOpen] = useState(false);
  const [userName, setuserName] = useState("");
  const [joinNow, setJoinNow] = useState(false);
  const [calltime, setCallTime] = useState(0);
  const [initialize, setInitialize] = useState(false);
  useEffect(() => {
    window.loadModal2("#OpenUrlPopUp", "hide");
  }, []);

  useEffect(() => {
    getLiveStatistics(dispatch);
  }, [auth.role === "admin" && status === 1 && user_status === 1]);

  useEffect(() => {
    let item = localStorage.getItem('PATH')
    let patharrayBackup = parseInt(localStorage.getItem('patharrayBackup'))
    console.log(window.location.pathname, "window.location.pathname", `/view/${pathArray}`, patharrayBackup)
    if (
      window.location.pathname === `/paymymedium/${getParam()}` ||
      window.location.pathname === `/thank-you` ||
      window.location.pathname === `/thankyou` ||
      window.location.pathname === `/profile-view/${patharrayBackup}` ||
      window.location.pathname === `/view/${patharrayBackup}` ||
      window.location.pathname === `/public-view/${patharrayBackup}` ||
      window.location.pathname === `/public-view` ||
      window.location.pathname === `/public-view/${item}` ||
      window.location.pathname === `/appointment` && window.location.pathname !== '/appointments' ||
      window.location.pathname === `/intro-video` ||
      window.location.pathname === `/appointment-confirmation/${getParam()}` ||
      window.location.pathname === `/guest-booking/${getParam()}` ||
      window.location.pathname === `/booking` ||
      window.location.pathname === `/verifyemail`
      ||
      window.location.pathname === `/registration-completed`
      ||
      window.location.pathname === `/backfill-client`


    ) {
      setInitialize(true);
    } else {
      setInitialize(false);
    }
  }, [location.pathname]);
  const getParam = () => {
    var str = window.location.pathname;

    var n = str.lastIndexOf('/');
    var result = str.substring(n + 1);
    return result;
  }
  useEffect(() => {
    Pusher.logToConsole = false;
    var pusher = new Pusher("823ab72ed6ecd563b8c8", {
      cluster: "ap2",
    });

    var channel = pusher.subscribe("notify-mediums");
    channel.bind("App\\Events\\NotifyMediums", function (data) {
      // alert(JSON.stringify(data));
      // setUserDetails(data.userName);
      let usersArray;
      if (Array.isArray(data.users)) {
        usersArray = data.users;
      } else {
        usersArray = Object.values(data.users);
      }
      // const userId = JSON.parse(localStorage.getItem("user")).id;
      const userId = id;
      if (usersArray.includes(parseInt(userId))) {
        let item = {
          appointment_id: data.appointment_id,
          medium_id: userId,
        };
        let link = data.link;
        setMeetingData({ item, link });
        setuserName(data.userName);
        changeOnlineStatusFunc(0, dispatch);
        setJoinNow(true);
        window.loadModal1("show");
        window.setPopupOpen = true;
        dispatch(setMatchingStarted(true));
        audio.play();
        // setTimeout(() => {
        //   window.loadModal1("hide");
        // }, 20000);
      }
    });
  }, [auth.role === "medium" && status === 1 && user_status === 1]);
  useEffect(() => {
    const unloadCallback = (event) => {
      if (window.setPopupOpen) {
        event.preventDefault();
        event.returnValue = "";
        return "";
      }
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);
  const declineInvitation = () => {
    window.setPopupOpen = false;
    dispatch(setMatchingStarted(false));
  };
  useEffect(() => {
    if (calltime < 3 && joinNow) {
      hitZoomHandler();
    }
    if (calltime >= 3 && joinNow) {
      setJoinNow(false);
    }
  }, [calltime]);
  const recallApp = () => {
    let time = calltime;
    setCallTime(time + 1);
  };
  const hitZoomHandler = () => {
    getZoom()
      .then((data) => {
        if (data.data === "") {
          recallApp();
        }
        if (data.data.status) {
          setJoinNow(false);
          // dispatch(setLoading(false));
          acceptInvitation(data.data.data.url);
          setmeetingUrl(data.data.data.url);
        }

        if (!data.data.status) {
          window.setPopupOpen = false;
          changeOnlineStatusFunc(0, dispatch);

          setRedirectUrl(data.data.data.redirect_url);
          // window.loadModal("#Authorizeapp", "open"); 
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false));
      });
    setTimeout(() => {
      getAppointments();
    }, 6000);
  };
  const getAppointments = () => {
    getMediumAppointments()
      .then((data) => {
        if (data.data.status) {
          dispatch(setaAppointmentsList(data.data.data));
        }
      })
      .catch(() => { });
  };
  const acceptInvitation = (url) => {
    let body = { meeting_link: url, ...meetingData.item };
    acceptSession(body)
      .then((data) => {
        if (data.data.status) {
          changeOnlineStatusFunc(0, dispatch);
          window.loadModal2("#Countdown", "show");
          setCounterStatus(true);
        }
        if (!data.data.status) {
          window.loadModal2("#seesiontaken", "show");
        }
      })
      .catch(() => { });
  };
  const hideCountdownTimer = () => {
    setCounterStatus(false);
    // window.open(meetingUrl);
    // window.location.href = meetingUrl;
    window.loadModal2("#Countdown", "hide");

    window.loadModal2("#OpenUrlPopUp", "show");
    setTimeout(() => {
      window.setPopupOpen = false;
      window.loadModal2("#OpenUrlPopUp", "hide");
    }, 240000);
    // window.open(meetingUrl, "_blank");
  };
  const startMeetingImmediate = () => {
    setTimeout(() => {
      window.setPopupOpen = false;
      setCounterStatus(false);
      window.loadModal2("#Countdown", "hide");
      window.location.reload();
    }, 3000);
  };
  const authorizeHandler = () => {

    dispatch(setLinkZoomRequest(true));
    navigate("/my-profile");
    // window.location.reload();
    window.loadModal("#Authorizeapp", "close");
    // window.open(redirectUrl, "_self");
  };

  const ToggleSideBar = () => {
    setToogle(!toogle);
  };
  const sessionTakenHandler = () => {
    changeOnlineStatusFunc(1, dispatch);
    dispatch(setMatchingStarted(false));
  };
  const cancelPopupHandler = () => {
    window.loadModal("#Authorizeapp", "close");

  }
  return (
    <>
      {initialize ? (
        <>
          {children}
        </>
      ) : (
        <div id="page-top internal">
          <div id="wrapper">
            {/* <!-- Sidebar --> */}
            <Sidebar toogle={toogle} ToggleSideBar={ToggleSideBar} setToogle={setToogle} />
            {/* <!-- Content Wrapper --> */}
            <div id="content-wrapper" className="d-flex flex-column">
              <div id="content">
                <Header ToggleSideBar={ToggleSideBar} />
                <div
                  className={`${(window.location.pathname === "/my-profile" || window.location.pathname === "/go-on-demand-practice")
                    ? ""
                    : "container-fluid"
                    } `}
                >
                  {children}
                </div>
              </div>



            <div className="mt-5">
              <p className="text-center booking-inner"><b>Note: For optimum performance: use Chrome, Safari, or Edge browser</b></p>
              {(auth.role === "medium" || auth.role === "client") && <p className="text-center booking-inner"><b>Have a question? Send us an email at <a href="mailtosupport@verysoul.com" >support@verysoul.com</a></b></p>}

            </div>
            </div>
          </div>
          <Link className="scroll-to-top rounded" to="/pagetop">
            <i className="fas fa-angle-up"></i>
          </Link>
          {/* <!-- Logout Modal--> */}
          <div
            className="modal fade"
            id="logoutModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-preview" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Ready to Leave?
                  </h5>
                  <button
                    className="close"
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  Select "Logout" below if you are ready to end your current
                  session.
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-secondary"
                    type="button"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <Link className="btn btn-primary" to="/login">
                    Logout
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="join-box-meet display-unset">
            <div
              className="modal fade"
              id="joinnowdialog"
              role="dialog"
              data-backdrop="static"
            >
              <div className="modal-dialog modal-dialog-preview">
                {/* <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button> */}
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="profile-video">
                      <img src="images/img-video.png" alt="img-video.png" />
                    </div>
                    <h3>
                      {/* <strong>{userDetails}</strong> */}
                      An outreach client wants to have a session with you
                    </h3>
                    <div className="bution-close-accept">
                      <a
                        onClick={declineInvitation}
                        href="javascript:void(0)"
                        data-dismiss="modal"
                        className="decline"
                      >
                        <img src="images/cancel.png" alt="cancel.png" />
                        Decline
                      </a>
                      <a
                        onClick={hitZoomHandler}
                        href="javascript:void(0)"
                        className="join-btn"
                        data-dismiss="modal"
                      >
                        <img src="images/video-call.png" alt="video-call.png" />
                        Join Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CountdownTimer
              userName={userName}
              counterStatus={counterStatus}
              hideCountdownTimer={hideCountdownTimer}
              startMeetingImmediate={startMeetingImmediate}
              meetingUrl={meetingUrl}
            />
            <OpenUrlPopUp url={meetingUrl} />
          </div>

          <div className="dont-have-acc display-unset gust-ppop margin-unset">
            <div
              className="modal fade"
              id="Authorizeapp"
              data-backdrop="static"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-preview">
                {/* <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button> */}
                <div className="modal-content">
                  <div className="modal-body mt-3">
                    <div className="guest-popup mt-5">
                      {/* <img src="images/sadu.png" alt="Personal link" /> */}

                      <h3>
                        {/* Please Re-Authorize your Zoom,
                        <br />
                        first */}
                        Please update your Personal Zoom Link

                      </h3>
                      {/* <a
                        className="pointer"
                        // data-dismiss="modal"
                        onClick={authorizeHandler}
                        href={redirectUrl}
                      >
                        Authorize
                      </a> */}
                      <Button
                        className={"resend-btn w-unset height-45 min-width-170px m-3 font-size-18"}
                        status={0}
                        onClick={authorizeHandler}
                        title="Add"
                      />


                      <Button
                        className={"resend-btn w-unset height-45 min-width-170px m-3 font-size-18"}
                        status={0}
                        onClick={() => cancelPopupHandler()}
                        title="Cancel"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal fade" id="seesiontaken" role="dialog">
              <div className="modal-dialog modal-dialog-preview">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="guest-popup">
                      {/* <img src="images/sadu.png" /> */}
                      <h3>
                        We are sorry
                        <br />
                        Session was taken
                      </h3>
                      <a
                        onClick={sessionTakenHandler}
                        data-dismiss="modal"
                        href="javascript:void(0);"
                      >
                        Close
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}{
      }
    </>
  );
}
