import React from "react";
import Vimeo from "@u-wave/react-vimeo";
import "./popover.css";
import { innerWidthHandler } from "../../../utils/helpers";

export default function VideoPopover({ close, src }) {
  return (
    <div className="video-popover-container">
      <div className="video-popover-wrapper">
        <div onClick={close} className="popoverClose-icon">
          x
        </div>
        <Vimeo
          muted={true}
          autoplay={true}
          className="popover-video"
          width={innerWidthHandler()}
          height={430}
          video={src}
        />
        {/* <iframe src={'https://player.vimeo.com/video/115783408?h=def2b8dd8f&speed=0&autoplay=1&quality=undefined&app_id=122963&texttrack=undefined'}  width={'100%'} */}
        {/* height={'100%'}/> */}
        {/* <ReactPlayer
        className="frame"
          referrerpolicy="strict-origin"
          url={'https://player.vimeo.com/video/115783408?h=def2b8dd8f&speed=0&autoplay=1&quality=undefined&app_id=122963&texttrack=undefined'}
          width={'100%'}
          height={'100%'}
          // playing={true}
          controls={true}
          light={true}
        /> */}
        <div className="text-right">
          <button onClick={close} className="popoverClose">
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
