import React, { useEffect, useState } from "react";
import "./giftyourmediums.css";
import Button from "../../../../../components/button";
export default function GiftYourMediums({ activePay, paymentMethodHandler, setPaymentAmount, setPaymentCurrency, paymentstatus }) {
  const [payment, setPayment] = useState([
    { id: 1, value: "20", status: true },
    { id: 2, value: "30", status: false },
    { id: 3, value: "40", status: false },
    { id: 4, value: "50", status: false },
    { id: 5, value: "70", status: false },
    { id: 6, value: "100", status: false },
  ]);
  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    setInputValue("");
  }, [paymentstatus])
  const setPaymentHandler = (item) => {
    let info = payment.map((data) => {
      if (data.id === item.id) {
        return { ...data, ...(data.status = true) };
      } else {
        return { ...data, ...(data.status = false) };
      }
    });
    let input = info.filter((data) => data.status === true);
    setPaymentAmount(input[0].value)
    setInputValue(input[0].value);
    setPayment(info);
  };

  const paymentChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setInputValue(value);
    setPaymentAmount(value)
  };
  return (
    <div
      class="modal fade payment-price-popup gift-your-medium-modal mt-5"
      tabindex="-1"
      role="dialog"
      id="GiftYourMediums"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-preview" >
        <div class="modal-content">
          <div class="modal-body payment-wrapper">
            <h4 className="payment-heading">Gift Your Medium</h4>
            {/* <div className="payment-price-tags pointer">
              {payment.map((item, index) => (
                <>
                  <div
                    onClick={() => setPaymentHandler(item)}
                    className={`tag ${item.status ? "" : "tag-white"}`}
                  >
                    ${item.value}
                  </div>
                </>
              ))}
            </div> */}
            <div className="d-flex mt-3">
              <div className="payment-methods-container">
                <div>
                  <label>Enter Amount</label>
                </div>
              </div>
              <div className="payment-methods-container giftyourmediums-wrap-input d-flex ml-2">
                <div>
                  <input
                    value={inputValue}
                    name="inputvalue"
                    type="number"
                    className="mt-3"
                    onChange={paymentChangeHandler}
                  />
                </div>
                <div className="payment-methods-container ">
                  <div>
                    <select name="currency" id="currency" onChange={(e) => setPaymentCurrency(e.target.value)} class="currency_select giftyourmediums-wrap-select"
                    >
                      <option value="USD">USD</option>
                      <option value="CAD">CAD</option>
                      <option value="AUD">AUD</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {console.log(activePay, "activePay")}
            <div className="payment-save-btn-wrapper pay-if-you-wish">
              {/* {activePay.payment_gateway === "both" && <>
                <Button
                  className={"resend-btn height-45 w-170"}
                  status={0}
                  onClick={() => paymentMethodHandler("paypal")}
                  title="Pay With Paypal"
                />
                <Button
                  className={"resend-btn height-45 w-170"}
                  status={0}
                  onClick={() => paymentMethodHandler("stripe")}
                  title="Pay With Stripe"
                />
              </>
              } */}
              {activePay.is_paypal_integrated &&
              <div className="height-45 w-133">
                <button
                  className={"resend-btn resend-btn-payment height-45 w-133"}
                  status={0}
                  onClick={() => paymentMethodHandler("paypal")}
                  title="Pay With Paypal"
                >
                   <img src={"/integrationimages/paypal.png"} />
                </button>
                <p>(PayPal or credit/debit card)</p>
                </div>
              }
              {activePay.is_stripe_integrated &&
              <div className="height-45 w-133">
                <button
                  className={"resend-btn resend-btn-payment height-45 w-133"}
                  status={0}
                  onClick={() => paymentMethodHandler("stripe")}
                  title="Pay With Stripe"
                >
                  <img
                    className="stripe-img"
                    src={"/integrationimages/stripe.png"}
                  />
                </button>
                <p>(credit/debit card)</p>
                </div>
              }
              {activePay.is_square_integrated &&
              <div className="height-45 w-133">
                <button
                  className={"resend-btn resend-btn-payment height-45 w-133 font-size-22 main-btn"}
                  status={0}
                  onClick={() => paymentMethodHandler("square")}
                  title="Pay With Square"
                >
                  {/* Square */}
                  <img
                                                className="stripe-square"
                                                src={"/integrationimages/square-black.jpg"}
                                            />
                </button>
                <p>(credit/debit card)</p>
                
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
