import React from "react";
import { ClockLoader } from "react-spinners";
export default function LoaderText({ text }) {
  return (
    <>
      <div className="spinner spinner-text">
        <ClockLoader size={60} color={"#d5a67b"} />
        <p className="mt-2 d-block">
          {" "}
          <div dangerouslySetInnerHTML={{ __html: text }} />{" "}
        </p>
      </div>
    </>
  );
}
