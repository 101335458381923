import React from "react";
import BackToSchedules from "../../../admin/calendar/backtoschedules";

export default function MyScheduleForm({
  openSchedules,
  medium_id,
  schedule_id,
  languages,
  countries
}) {
  return (
    <BackToSchedules
    type="medium"
      openSchedules={openSchedules}
      medium_id={medium_id}
      schedule_id={schedule_id}
      languages={languages}
      countries={countries}
    />
  );
}
