import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
//components
import TabItem from "./subcomponent/tabitem";
import Preferences from "./preferences";
import MyTechnology from "./mytechnology";
import Profile from "./profile";
import LegalAndPrivacy from "./legalandprivacy";

import { list } from "./data";
import TabPanel from "./subcomponent/tabpanel";
import {
  stepOneData,
  stepThreeData,
  stepTwoData,
  stepOneDataBackup,
  stepFourData,
} from "../../../profile/data";

//reducer
import {
  setaLanguagesArray,
  setToolsArray,
  setCountryArray,
  setSpecialtyArray,
} from "../../../../reducer/uidataSlice";

//service
import {
  getTools,
  getLanguages,
  getCountry,
  getSpeciality,
  getTimezones,
} from "../../../../services/uidata";
import {
  getProfileBasicInfo,
  getProfileLeagalAndPrivacy,
  getProfilePreferences,
  getProfileTechnology,
} from "../../../../services/admin";
import { setLoading } from "../../../../reducer/auth/auth";
import { toast } from "react-toastify";

export default function MyProfile() {
  const { linkZoomRequest } = useSelector((state) => state.medium);
  const {
    languagesArray,
    toolsArray,
    countryArray,
    specialtyArray,
    timezoneArray,
  } = useSelector((state) => state.uidata);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("view");
  const searchVal = searchParams.get("search");
  const searchName = searchParams.get("searchName");
  const searchOprator = searchParams.get("searchOprator");
  const searchFilterOprater = searchParams.get("searchFilterOprater");
  const page = searchParams.get('page');
  const rowperpage = searchParams.get('rowperpage');


  const navigate = useNavigate();
  const params = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [formLanguages, setFormLanguages] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [timezoneData, setTimezoneData] = useState([]);
  const [specialtyData, setSpecialityData] = useState([]);
  const [tools, setTools] = useState([]);
  const [profileSection, setProfileSection] = useState(null);
  const [teachnologySection, setteachnologySection] = useState(null);
  const [preferncesSection, setpreferncesSection] = useState(null);
  // const [timezoneOnly, settimezoneOnly] = useState(null);
  const [recieved, setRecieved] = useState(false);
  const [receivedInfo, setReceivedInfo] = useState(false);
  const [queryValue, setQueryValue] = useState('');
  const [query1, setQuery1] = useState('');
  const [query2, setQuery2] = useState('');
  const [query3, setQuery3] = useState('');
  const [query4, setQuery4] = useState('');
  const [query5, setQuery5] = useState('');
  const [query6, setQuery6] = useState('');
  const [legalandprivacySection, setLegalandprivacySection] = useState(null);
  // const [coverImage, setCoverImage] = useState("images/profile-cover.png");

  useEffect(() => {
    localStorage.setItem('pagintionTYPE', 'change')
    //getprofiledetails
    if (profileSection === null) getProfile(params.id);
    if (teachnologySection === null) getTechnologies(params.id);
    if (preferncesSection === null) getPreferences(params.id);
    if (legalandprivacySection == null) leagalAndPrivacy(params.id);
    if (!countryArray.length) updateCountries();
    if (!timezoneArray.length) updateTimezones();
    if (!languagesArray.length) updateLanguage();
    if (!specialtyArray.length) updateSpeciality();
    if (!toolsArray.length) updateTools();
    setQueryValue(status)
    setQuery1(searchVal)
    setQuery2(searchName)
    setQuery3(page)
    setQuery4(rowperpage)
    setQuery5(searchOprator)
    setQuery6(searchFilterOprater)
    setTimeout(() => {
      removeQueryParams()
    }, 2000);
  }, []);
  const removeQueryParams = () => {
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.delete("view");
    newUrl.searchParams.delete("search");
    newUrl.searchParams.delete("searchName");
    newUrl.searchParams.delete("searchOprator");
    newUrl.searchParams.delete("searchFilterOprater");
    newUrl.searchParams.delete("page");
    newUrl.searchParams.delete("rowperpage");
    // newUrl.searchParams.delete("id");
    window.history.replaceState({}, document.title, newUrl.href);

    // if (status) {
    //   // 👇️ delete each query param
    //   searchParams.delete('view');

    //   // 👇️ update state after
    //   setSearchParams(searchParams);
    // }
  };
  useEffect(() => {
    if (linkZoomRequest) setActiveTab(2);
  }, [linkZoomRequest]);

  useEffect(() => {
    if (stepTwoData.country_type === "All Countries with Exclusions") {
      stepTwoData.exclusions = getCountryTypes(
        stepTwoData.country_type,
        stepTwoData.countries,
        countryData
      );
    }
    if (stepTwoData.country_type === "Selected Countries only") {
      stepTwoData.inclusions = getCountryTypes(
        stepTwoData.country_type,
        stepTwoData.countries,
        countryData
      );
    }
  }, [recieved, countryData]);
  useEffect(() => {
    setFormLanguages(languagesArray);
    setCountryData(countryArray);
    setSpecialityData(specialtyArray);
    setTools(toolsArray);
  }, [languagesArray, toolsArray, countryArray, specialtyArray]);

  const updateTools = () => {
    getTools()
      .then((data) => {
        dispatch(setToolsArray(data.data.data));
      })
      .catch((err) => console.log(err));
  };

  const updateLanguage = () => {
    getLanguages()
      .then((data) => {
        dispatch(setaLanguagesArray(data.data.data));
      })
      .catch((err) => console.log(err));
  };

  const updateCountries = () => {
    getCountry()
      .then((data) => {
        dispatch(setCountryArray(data.data.data));
      })
      .catch((err) => console.log(err));
  };
  const updateTimezones = () => {
    getTimezones()
      .then((data) => {
        setTimezoneData(data.data.data);
      })
      .catch((err) => console.log(err));
  };
  const reloadTimeZones = (refresh) => {
    getTimezones()
      .then((data) => {
        setTimezoneData(data.data.data);
        if (refresh == "refresh") {
          toast.success("TimeZones Refreshed", { position: "top-center" });
        }
      })
      .catch((err) => console.log(err));
  }
  const updateSpeciality = () => {
    getSpeciality()
      .then((data) => {
        dispatch(setSpecialtyArray(data.data.data));
      })
      .catch((err) => console.log(err));
  };

  const getCountryTypes = (type, countries, items) => {
    if (countries.length) {
      let country;
      if (type === "All Countries with Exclusions") {
        const res = items.filter(
          (entry1) =>
            !countries.some(
              (entry2) => parseInt(entry1.id) === parseInt(entry2.country_id)
            )
        );
        country = res.map((item) => {
          return {
            ...item,
            value: item.id,
            label: item.country_name,
          };
        });
      }
      if (type === "Selected Countries only") {
        const res = items.filter((entry1) =>
          countries.some(
            (entry2) => parseInt(entry1.id) === parseInt(entry2.country_id)
          )
        );

        country = res.map((item) => {
          return {
            ...item,
            value: item.id,
            label: item.country_name,
          };
        });
      }
      // stepTwoData.exclusions =
      //   type == "All Countries with Exclusions" ? country : "";
      // stepTwoData.inclusions = type == "Selected Countries only" ? country : "";
      return country;
    }
  };

  const updateIncomingHandler = (items, type) => {
    let incomingArray;
    if (type === "languages") {
      incomingArray = items.map((item) => {
        return {
          ...item,
          id: item.language_id,
          value: item.language_id,
          label: item.lanugage_name,
        };
      });
    }
    if (type === "specialities") {
      incomingArray = items.map((item) => {
        return {
          ...item,
          id: item.mediumship_specialities_id,
          value: item.mediumship_specialities_id,
          label: item.specialty_name,
        };
      });
    }
    if (type === "tools") {
      incomingArray = items.map((item) => {
        return {
          ...item,
          id: item.mediumship_session_tool_id,
          value: item.mediumship_session_tool_id,
          label: item.tools_name,
        };
      });
    }
    return incomingArray;
  };
  const getProfile = (id) => {
    dispatch(setLoading(true))
    getProfileBasicInfo(id)
      .then((data) => {
        dispatch(setLoading(false))
        if (data.data.status) {
          const {
            country,
            first_name,
            last_name,
            info,
            languages,
            mediumship_level_medium, //which users select
            mediumship_level, //aprroved
            phone,
            specialities,
            website,
            whatsapp,
            timezone,
            when_do_i_pay,
            session_length,
            session_currency,
            profile_image,
            encoded_image,
            zoom,
            google,
            medium_professional_category,
            recommendation,
            encrypted_id,
            personal_booking_link,
            is_square_integrated,
            is_stripe_integrated,
            is_paypal_integrated,
            pay_forward,
            test_mode,
            extra_services,
            extra_services_data,
            extra_services_list,
            coaching_pricing,
            hosting_circle_pricing,
            hosting_qa_pricing,
            comments,
            allow_free_coaching_session,
            payitforward_pricing
          } = data.data.data;

          stepOneData.test_mode = test_mode
          stepOneData.is_square_integrated = is_square_integrated !== undefined && is_square_integrated ? true : false
          stepOneData.is_stripe_integrated = is_stripe_integrated !== undefined && is_stripe_integrated ? true : false
          stepOneData.is_paypal_integrated = is_paypal_integrated !== undefined && is_paypal_integrated ? true : false
          stepOneData.pay_forward = pay_forward !== undefined && pay_forward === 1 ? true : false
          stepOneData.personal_booking_link = personal_booking_link;
          stepOneData.encrypted_id = encrypted_id;
          stepOneData.zoom = zoom !== null ? zoom : "";
          stepOneData.google = google !== null ? google : "";
          stepOneData.profile_image =
            profile_image !== "" ? profile_image : null;
          stepOneData.encoded_image = encoded_image;
          stepOneData.firstName = first_name;
          stepOneData.lastName = last_name;
          stepOneData.website = website;
          stepOneData.country = country;
          stepOneData.biographicalInfo = info;
          stepOneData.phone = phone;
          stepOneData.mediumshipLevel = parseInt(mediumship_level_medium);
          stepOneData.mediumship_level = parseInt(mediumship_level);
          stepOneData.languages = updateIncomingHandler(languages, "languages");
          stepOneData.recommendation =
            recommendation !== null ? recommendation : "";
          stepOneData.mediumshipSpecialities = updateIncomingHandler(
            specialities,
            "specialities"
          );
          stepOneData.medium_professional_category = medium_professional_category;
          stepOneData.whatsapp = whatsapp;
          stepOneData.when_do_i_pay =
            when_do_i_pay !== undefined
              ? when_do_i_pay.map((item) => {
                return String(item);
              })
              : [];
          stepOneData.session_currency =
            session_currency !== null
              ? Object.values(JSON.parse(session_currency))[0] !== "" ? Object.values(JSON.parse(session_currency))[0] : "USD"
              : "USD";
          stepOneData.session_length_30_currency =
            session_currency !== null
              ? Object.values(JSON.parse(session_currency))[0]
              : "";
          stepOneData.session_length_45_currency =
            session_currency !== null
              ? Object.values(JSON.parse(session_currency))[1]
              : "";
          stepOneData.session_length_60_currency =
            session_currency !== null
              ? Object.values(JSON.parse(session_currency))[2]
              : "";
          stepOneData.session_length_45_2_currency =
            session_currency !== null
              ? Object.values(JSON.parse(session_currency))[3]
              : "";
          stepOneData.session_length_60_2_currency =
            session_currency !== null
              ? Object.values(JSON.parse(session_currency))[4]
              : "";

          stepOneData.session_length_30 =
            session_length !== null
              ? Object.values(JSON.parse(session_length))[0]
              : "";

          stepOneData.session_length_45 =
            session_length !== null
              ? Object.values(JSON.parse(session_length))[1]
              : "";
          stepOneData.session_length_60 =
            session_length !== null
              ? Object.values(JSON.parse(session_length))[2]
              : "";
          stepOneData.session_length_45_2 =
            session_length !== null
              ? Object.values(JSON.parse(session_length))[3]
              : "";
          stepOneData.session_length_60_2 =
            session_length !== null
              ? Object.values(JSON.parse(session_length))[4]
              : "";
          stepOneData.extra_services = extra_services;
          stepOneData.extra_services_data = extra_services_data;
          stepOneData.extra_services_list = extra_services_list !== null ? extra_services_list.map((item) => {
            return {
              id: item.id,
              amount: item.amount,
              name: item.sub_session_name,
            };
          }) : "";
          console.log('data', stepOneData.extra_services_list)

          stepOneData.timezone = { ...timezone, value: timezone.id, label: timezone.front_name };
          stepOneData.coaching_pricing_30 = coaching_pricing !== null && coaching_pricing !== undefined ? Object.values(JSON.parse(coaching_pricing))[0] : '';
          stepOneData.hosting_circle_pricing_30 = hosting_circle_pricing !== null && hosting_circle_pricing !== undefined ? Object.values(JSON.parse(hosting_circle_pricing))[0] : '';
          stepOneData.hosting_qa_pricing_30 = hosting_qa_pricing !== null && hosting_qa_pricing !== undefined ? Object.values(JSON.parse(hosting_qa_pricing))[0] : '';
          stepOneData.comments = comments;
          stepOneData.allow_free_coaching_session = allow_free_coaching_session;
          stepOneData.payitforward_pricing_30 = payitforward_pricing !== null && payitforward_pricing !== undefined ? Object.values(JSON.parse(payitforward_pricing))[0]
            : "";
          stepOneData.payitforward_pricing_45 = payitforward_pricing !== null && payitforward_pricing !== undefined ? Object.values(JSON.parse(payitforward_pricing))[1]
            : "";
          stepOneData.payitforward_pricing_60 = payitforward_pricing !== null && payitforward_pricing !== undefined ? Object.values(JSON.parse(payitforward_pricing))[2]
            : "";
            stepOneData.payitforward_pricing_45_2 = payitforward_pricing !== null && payitforward_pricing !== undefined ? Object.values(JSON.parse(payitforward_pricing))[3]
            : "";
            stepOneData.payitforward_pricing_60_2 = payitforward_pricing !== null && payitforward_pricing !== undefined ? Object.values(JSON.parse(payitforward_pricing))[4]
            : "";
          setReceivedInfo(true);
          dispatch(setLoading(false))
          setProfileSection(data.data.data);
        }
      })
      .catch((err) => {
        dispatch(setLoading(false))
        console.log(err)
      });
  };

  const getPreferences = (id) => {
    getProfilePreferences(id)
      .then((data) => {
        setRecieved(true);
        const {
          allow_recordings,
          countries,
          current_roles,
          mediumship_session_tools,
          payment_methods,
          session_message,
          session_preferences,
          client_session_preferences,
          session_type,
          screen_name,
          country_type,
        } = data.data.data;
        stepTwoData.mediumship_session_tools = updateIncomingHandler(
          mediumship_session_tools,
          "tools"
        );

        stepTwoData.allowRecordings = allow_recordings;
        stepTwoData.interestedSessionType1 = session_message;
        stepTwoData.currentRoles = current_roles;
        stepTwoData.PaymentMethods = payment_methods.map((item) => {
          return item.payment_method_id;
        });
        stepTwoData.session_preferences = session_preferences;

        stepTwoData.wantToDoSessions = client_session_preferences;
        stepTwoData.interestedSessionType =
          session_type !== null ? session_type : [];
        stepTwoData.screenName = screen_name;

        stepTwoData.country_type = country_type;
        stepTwoData.wantToDoWork =
          country_type === "" ? "All Countries" : country_type;
        stepTwoData.countries = countries;

        setpreferncesSection(data.data.data);
      })
      .catch((err) => console.log(err));
  };
  const getTechnologies = (id) => {
    getProfileTechnology(id)
      .then((data) => {
        const {
          calendar_type,
          preferred_browser,
          preferred_device,
          zoom_account_type,
          zoom_personal_link,
          paypal_me_link,
          personal_booking_link,
        } = data.data.data;
        stepThreeData.personal_booking_link = personal_booking_link;
        stepThreeData.electronicCalendars =
          calendar_type !== null ? calendar_type : [];
        stepThreeData.zoomAccountType = zoom_account_type;
        stepThreeData.preferredDevice =
          preferred_device !== null ? preferred_device : [];
        stepThreeData.preferredBrowser =
          preferred_browser !== null ? preferred_browser : [];
        stepThreeData.zoom_personal_link = zoom_personal_link;
        stepThreeData.paypal_me_link =
          paypal_me_link !== "" ? paypal_me_link : stepThreeData.paypal_me_link;

        setteachnologySection(data.data.data);
      })
      .catch((err) => console.log(err));
  };
  // const coverImageChangeHandler = (e) => {
  //   const file = e.target.files[0];

  //   const formData = new FormData();
  //   formData.append("file", file);

  //   setCoverImage(e.target.value);
  // };

  const leagalAndPrivacy = (id) => {
    getProfileLeagalAndPrivacy(id).then((data) => {
      if (data.data.status) {
        if (data.data.data.length) {


          let info = data.data.data[0]

          setLegalandprivacySection(info);
          stepFourData.legalAndPrivacy = info.legal_privacy === 1 ? true : false;
          stepFourData.optIn = info.opt_in === 1 ? 'Yes' : info.opt_in === 0 ? 'No' : '';
          stepFourData.codeOfEthics = info.code_of_ethics === 1 ? true : false;
          stepFourData.operatingPrinciples = info.operating_principal === 1 ? true : false;
        }
      }

    })
  }
  return (
    <>
      <div className="back-btn-profile ml-2 container-fluid">
        <span
          className="back-handler pointer"
          onClick={() => status === null ? navigate(-1) : navigate(`/mediums?view=${queryValue}&search=${query1}&searchName=${query2}&page=${query3}&rowperpage=${query4}&searchOprator=${query5}&searchFilterOprater=${query6}&pagintion=${'change'}`)}
        >
          <img src="../images/arrow-back.png" alt="go back" />
          Go Back
        </span>
      </div>
      <div className="middle-screen-name font-size-22">
        {" "}
        <b>{stepTwoData.screenName}&nbsp;Profile</b>
      </div>
      <div className="profile-cover-box mt-5"></div>

      <div className="container-fluid">
        <div className="dashboard">
          <div className="row">
            <div className="col-md-12">
              <div className="profile-tow-box">
                <div className="row">
                  {/* <div className="col-md-3">
                    <div className="profile-tow-box-left">
                      <div className="profile-one-box">
                        <img src="images/profil-big.png" />
                        <div className="pfil-name">
                          {getCountryTypes(
                            stepTwoData.country_type,
                            stepTwoData.countries,
                            countryData
                          )}
                          {stepTwoData.screenName}
                        </div>

                        <div className="star-rating">
                          <ul>
                            <li className="starli">
                              0
                              <img src="../localImages/hollowstar.svg" />
                              <img src="../localImages/hollowstar.svg" />
                              <img src="../localImages/hollowstar.svg" />
                              <img src="../localImages/hollowstar.svg" />
                              <img src="../localImages/hollowstar.svg" />
                            </li>
                            <li className="rating-text">0 Ratings</li>
                          </ul>
                        </div>
                      </div>
                      <div className="row-box-profile">
                        <span>
                          Languages Available for Mediumship Sessions:
                        </span>
                        <ul>
                          {stepOneData.languages.map((item) => (
                            <li>{item.label}</li>
                          ))}
                        </ul>
                      </div>
                      <div className="row-box-profile">
                        <span>Mediumship Specialities:</span>
                        <ul>
                          {stepOneData.mediumshipSpecialities.map((item) => (
                            <li>{item.label}</li>
                          ))}
                        </ul>
                      </div>
                      <div className="row-box-profile">
                        <div className="web-site-link">
                          Country:
                          <p>{stepOneData.country}</p>
                        </div>
                      </div>
                      <div className="row-box-profile">
                        <div className="web-site-link">
                          Website:
                          <p>{stepOneData.website}</p>
                        </div>
                      </div>
                      <div className="bution-profile">
                        <a href="#">
                          <img src="images/view.png" />
                          View Public Profile
                        </a>
                        <div className="copy-link">
                          <div className="left-link">
                            https:verysoul.com/mem...
                            <div className="link-icon">
                              <img src="images/copy-two.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-md-12">
                    <div className="profile-tow-box-right">
                      <section className="signup-step-container">
                        <div className="row d-flex justify-content-center">
                          <div className="wizard">
                            <div className="wizard-inner">
                              <TabItem
                                list={list}
                                setActiveTab={setActiveTab}
                              />
                            </div>
                            <div
                              role="form"
                              action="index.html"
                              className="login-box"
                            >
                              <div className="tab-content" id="main_form">
                                <TabPanel id={0} activeTab={activeTab}>
                                  {receivedInfo && <Profile
                                    tools={tools}
                                    formLanguages={formLanguages}
                                    countryData={countryData}
                                    specialtyData={specialtyData}
                                    stepOneData={stepOneData}
                                    getProfile={getProfile}
                                    stepTwoData={stepTwoData}
                                    timezoneData={timezoneData}
                                    param={params}
                                    preferncesSection={preferncesSection}
                                    receivedInfo={receivedInfo}
                                    reloadTimeZones={reloadTimeZones}
                                  />}
                                </TabPanel>
                                <TabPanel id={1} activeTab={activeTab}>
                                  <Preferences
                                    tools={tools}
                                    formLanguages={formLanguages}
                                    countryData={countryData}
                                    specialtyData={specialtyData}
                                    stepTwoData={stepTwoData}
                                    stepOneData={stepOneData}
                                    getPreferences={getPreferences}
                                    param={params}
                                  />
                                </TabPanel>
                                <TabPanel id={2} activeTab={activeTab}>
                                  <MyTechnology
                                    formLanguages={formLanguages}
                                    countryData={countryData}
                                    specialtyData={specialtyData}
                                    stepThreeData={stepThreeData}
                                    getTechnologies={getTechnologies}
                                    param={params}
                                  />
                                </TabPanel>
                                <TabPanel id={3} activeTab={activeTab}>
                                  <LegalAndPrivacy
                                    stepFourData={stepFourData}
                                  />
                                </TabPanel>
                              </div>
                            </div>
                            {/* <form
                              role="form"
                              action="index.html"
                              className="login-box"
                            > */}
                            {/* <div className="tab-content" id="main_form">
                              
                            </div> */}
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
