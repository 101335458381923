import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../reducer/auth/auth";
import userReducer from "../reducer/userSlice";
import adminReducer from "../reducer/adminSlice";
import clientReducer from "../reducer/clientsSlice";
import mediumReducer from "../reducer/mediumSlice";
import uidataReducer from "../reducer/uidataSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    users: adminReducer,
    client: clientReducer,
    medium: mediumReducer,
    uidata: uidataReducer,
  },
});
