import React, { useState } from "react";
import Tabs from "../../../components/tabs";
import TabPanel from "../../../components/tabs/tabpanel";
// import Testimonial from "./testimonial";
// import Pending from "./pending";

import { list } from "./data";

import "./inspirationstories.css";

export default function InspirationStories() {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <div className="all-medium-page">
      <Tabs list={list} setActiveTab={setActiveTab} />
      <ul className="tabs-content inspiration-stories-page">
        <TabPanel id={0} activeTab={activeTab}>
          {/* <Testimonial /> */}
        </TabPanel>
        <TabPanel id={1} activeTab={activeTab}>
          {/* <Pending /> */}
        </TabPanel>
      </ul>
    </div>
  );
}
