import React, { useState } from "react";
import "./question.css";
export default function Questions({ onCancelQuestions, startLoaderNow }) {
  const [step, setStep] = useState(0);
  const [error, setError] = useState(false);
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [check3, setCheck3] = useState(false);
  const [check4, setCheck4] = useState(false);
  const [check5, setCheck5] = useState(false);
  const [check6, setCheck6] = useState(false);
  const [check7, setCheck7] = useState(false);

  const [check8, setCheck8] = useState(false);
  const [check9, setCheck9] = useState(false);
  const [check10, setCheck10] = useState(false);
  const [check11, setCheck11] = useState(false);
  const [check12, setCheck12] = useState(false);
  const [check13, setCheck13] = useState(false);
  const [check14, setCheck14] = useState(false);

  const [checkYes, setCheckYes] = useState(false);
  // const [checkNo, setCheckNo] = useState(false);

  const changeHandler = (e, setCheck, type) => {
    if (type === "radio") {
      setCheckYes(e.target.value);
    } else {
      setCheck(e.target.checked);
    }
  };
  const onClickHandler = () => {
    if (
      check1 &&
      check2 &&
      check3 &&
      check7 &&
      check8 &&
      check9 &&
      check10 &&
      check11 &&
      check12 &&
      check13 &&
      check14
    ) {
      setError(false);
      startLoaderNow();
    } else {
      setError(true);
    }
  };
  const onClickHandler2 = () => {
    if (
      checkYes === "yes" ||
      (checkYes === "no" && check4 && check5 && check6)
    ) {
      setStep(1);
      setError(false);
      // setStep(1);
    } else {
      setError(true);
    }
  };
  return (
    <div class="container-fluid question-container">
      <div class="row justify-content-center">
        <div class="col-11 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
          <div class="card px-0 pt-4 pb-0 mt-3 mb-3">
            <form id="msform">
              {step === 1 && (
                <fieldset>
                  <div class="form-card">
                    <div class="row wrap">
                      <h2 className="text-center">Please read and confirm each item:</h2>
                      <h2>I am:</h2>
                      <div class="new margin-unset">
                        <div class="form-group">
                          <input
                            onChange={(e) => {
                              changeHandler(e, setCheck1);
                            }}
                            checked={check1}
                            name="check1"
                            type="checkbox"
                            id="check1"
                          />
                          <label htmlFor="check1">
                            Ready for a session{" "}
                            <span className="text-underline">right now</span> if
                            matched with a Medium.
                          </label>
                        </div>
                        <div class="form-group">
                          <input
                            onChange={(e) => {
                              changeHandler(e, setCheck2);
                            }}
                            checked={check2}
                            name="check2"
                            type="checkbox"
                            id="check2"
                          />
                          <label htmlFor="check2">
                            Open to this experience
                          </label>
                        </div>
                        <div class="form-group">
                          <input
                            onChange={(e) => {
                              changeHandler(e, setCheck3);
                            }}
                            checked={check3}
                            name="check3"
                            type="checkbox"
                            id="check3"
                          />
                          <label htmlFor="check3">
                            Ready to respond to potential evidence with “yes”,
                            “no”, “possibly” or “I don’t know”
                          </label>
                        </div>
                        <h2>I will:</h2>
                        <div class="form-group">
                          <input
                            onChange={(e) => {
                              changeHandler(e, setCheck7);
                            }}
                            checked={check7}
                            name="check7"
                            type="checkbox"
                            id="check7"
                          />
                          <label htmlFor="check7">
                            Attend in a quiet space where I am{" "}
                            <span className="text-underline">
                              not distracted.
                            </span>
                          </label>
                        </div>
                        <div class="form-group">
                          <input
                            checked={check8}
                            onChange={(e) => {
                              changeHandler(e, setCheck8);
                            }}
                            name="check8"
                            type="checkbox"
                            id="check8"
                          />
                          <label htmlFor="check8">
                            Attend <span className="text-underline">alone</span>{" "}
                            to enable focus on my loved ones. (unless a 2-person session is selected)
                          </label>
                        </div>

                        <div class="form-group">
                          <input
                            onChange={(e) => {
                              changeHandler(e, setCheck14);
                            }}
                            checked={check14}
                            name="check14"
                            type="checkbox"
                            id="check14"
                          />
                          <label htmlFor="check14">
                            {" "}
                            <span className="text-underline">
                              Not record
                            </span>{" "}
                            without permission from the Medium. (any recordings are for private use only)
                          </label>
                        </div>
                        {/*****In understabd */}
                        <h2>I understand that:</h2>
                        <div class="form-group">
                          <input
                            onChange={(e) => {
                              changeHandler(e, setCheck9);
                            }}
                            checked={check9}
                            name="check9"
                            type="checkbox"
                            id="check9"
                          />
                          <label htmlFor="check9">
                            There are no guarantees of who will come through in
                            my session.
                          </label>
                        </div>
                        <div class="form-group">
                          <input
                            checked={check10}
                            onChange={(e) => {
                              changeHandler(e, setCheck10);
                            }}
                            name="check10"
                            type="checkbox"
                            id="check10"
                          />
                          <label htmlFor="check10">
                            I am the best interpreter of the information
                            conveyed by my loved ones.
                          </label>
                        </div>

                        <div class="form-group">
                          <input
                            onChange={(e) => {
                              changeHandler(e, setCheck11);
                            }}
                            checked={check11}
                            name="check11"
                            type="checkbox"
                            id="check11"
                          />
                          <label htmlFor="check11">
                            {" "}
                            Post-session recognition of evidence is normal and
                            may serve a purpose.
                          </label>
                        </div>
                        {/*****I agree that */}
                        <h2>I agree that:</h2>
                        <div class="form-group">
                          <input
                            onChange={(e) => {
                              changeHandler(e, setCheck12);
                            }}
                            checked={check12}
                            name="check12"
                            type="checkbox"
                            id="check12"
                          />
                          <label htmlFor="check12">
                            After my session I will complete the emailed survey
                            (check spam/junk).
                          </label>
                        </div>
                        <div class="form-group">
                          <input
                            checked={check13}
                            onChange={(e) => {
                              changeHandler(e, setCheck13);
                            }}
                            name="check13"
                            type="checkbox"
                            id="check13"
                          />
                          <label htmlFor="check13">
                            I take sole responsibility for my interpretations,
                            choices, and actions.
                          </label>
                        </div>
                        {error && (
                          <div>
                            <p className="red">
                              *Please Select all check boxes
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <input
                    onClick={() => setStep(0)}
                    type="button"
                    name="previous"
                    class="previous action-button-previous"
                    value="Previous"
                  />
                  <input
                    onClick={onClickHandler}
                    type="button"
                    name="next"
                    class="next action-button"
                    value="Next"
                  />
                </fieldset>
              )}{" "}
              {step === 0 && (
                <fieldset>
                  <div class="form-card">
                    <div class="row wrap">
                      <h2>Zoom Test</h2>
                      <h3>Are you a regular Zoom user ?</h3>
                      <div class="new secend-step">
                        <div class="form-group">
                          <input
                            onChange={(e) => {
                              changeHandler(e, setCheckYes, "radio");
                            }}
                            value={"yes"}
                            checked={checkYes === "yes"}
                            name="yesNo"
                            type="radio"
                            id="yes"
                          />
                          <label htmlFor="yes">Yes</label>
                        </div>
                        <div class="form-group">
                          <input
                            checked={checkYes === "no"}
                            onChange={(e) => {
                              changeHandler(e, setCheckYes, "radio");
                            }}
                            value={"no"}
                            name="no"
                            type="radio"
                            id="no"
                          />
                          <label htmlFor="no">No</label>
                        </div>
                      </div>

                      {checkYes === "no" && (
                        <>
                          <h3>
                            If no, please test your ability to connect with Zoom
                            here
                          </h3>
                          <a href="https://zoom.us/test" target={"_blank"}>
                            https://zoom.us/test
                          </a>
                          <p className="red">  &nbsp;(Only for testing audio, video and sound.)</p>
                          <h3>Please check each box to confirm :</h3>
                          <div class="new secend-step">
                            <div class="form-group">
                              <input
                                onChange={(e) => {
                                  changeHandler(e, setCheck4);
                                }}
                                checked={check4}
                                name="zoom"
                                type="checkbox"
                                id="zoom"
                              />
                              <label for="zoom">Zoom audio worked</label>
                            </div>
                            <div class="form-group">
                              <input
                                onChange={(e) => {
                                  changeHandler(e, setCheck5);
                                }}
                                checked={check5}
                                name="worked"
                                type="checkbox"
                                id="worked"
                              />
                              <label for="worked">Zoom microphone worked</label>
                            </div>
                            <div class="form-group">
                              <input
                                onChange={(e) => {
                                  changeHandler(e, setCheck6);
                                }}
                                checked={check6}
                                name="video"
                                type="checkbox"
                                id="video"
                              />
                              <label for="video">Zoom video worked</label>
                            </div>
                            {error && (
                              <div>
                                <p className="red">
                                  *Please Select all check boxes
                                </p>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <input
                    type="button"
                    name="next"
                    class="next action-button"
                    value="Next"
                    onClick={onClickHandler2}
                  />
                  <input
                    onClick={onCancelQuestions}
                    type="button"
                    name="previous"
                    class="previous action-button-previous"
                    value="Cancel"
                  />
                </fieldset>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
