import API from "../api/api";

const config = (token) => {
  return {
    headers: {
      "Content-type": "application/json",

      'X-Frame-Options': 'DENY',
      'Content-Security-Policy': "style-src 'self'",
      Authorization: `Bearer ${token}`,
    },
  };
};
export const updateInitialProfile = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post("update-initial-profile", body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const updatePricing = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post("update-coaching-session-pricing", body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const referOthers = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post("refer-others", body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const checkreferCode = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post("check-refer-code", body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};


export const updateUserPrefrences = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post("update-preference-data", body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const updateUserTechnologies = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "update-user-technologies",
    body,
    config(token)
  )

    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const updateLegalAndPricayData = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "update-medium-legal-privacy",
    body,
    config(token)
  )

    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const updateUserTechnologiesMeta = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "update-user-technologies-meta",
    body,
    config(token)
  )

    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const updateClientsMeta = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post("update-client-info", body, config(token))

    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const requestScreenNameChange = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post("request-change", body, config(token))

    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};


export const updateNewTimeZone = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post("save-client-timezone", body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};