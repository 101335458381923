import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getProfileBasicInfo } from "../../../../services/medium";
import StarsRating from "stars-rating";
// import BookingPopUp from "./bookingpopup";
import "./profile.css";
import { deleteUserFeedbacks } from "../../../../services/admin";
import { getMediumPublicProfileDetails } from "../../../../services/uidata";
import { getPractitionarInfo } from "../../../../services/client";
import { screenNameHandler } from "../../../../utils/helpers";
export default function MediumProfileView({ onClick ,show }) {
    const navigate = useNavigate();
    const params = useParams()
    // const dispatch = useDispatch();
    //const [profile, setProfile] = useState(null);
    const [searchParams] = useSearchParams();
    const from = searchParams.get("from");
    const serviceValue = searchParams.get("serviceValue");
    const [profileSection, setProfileSection] = useState(null);
    //const [teachnologySection, setteachnologySection] = useState(null);
    //const [preferncesSection, setpreferncesSection] = useState(null);

    //const params = useParams();

    useEffect(() => {
        // dispatch(setPathArray(params.id));
        getProfile();
        // getPreferences();
        // getTechnologies();
        // return () => {
        // dispatch(setPathArray(null));
        // };
    }, []);

    const getProfile = () => {
        getPractitionarInfo(params.id)
            .then((data) => {
                setProfileSection(data.data.data);
            })
            .catch((err) => console.log(err));
    };

    const calculateUnique = (arr) => {
        let value = arr.reduce((acc, val) => {
            acc[val] = acc[val] === undefined ? 1 : (acc[val] += 1);
            return acc;
        }, {});
        return value;
    };
    const calculateRating = (feedbacks) => {
        let total = 0;
        let sum_resp = 0;
        let counts = feedbacks.map((item) => {
            return parseInt(JSON.parse(item.feedback).Star_Rating);
        });

        Object.entries(calculateUnique(counts)).map(([key, value]) => {
            sum_resp += parseInt(value) * parseInt(key);
            total += parseInt(value);
        });
        let score = Math.round((sum_resp / total) * 2) / 2;
        return score;
        // return Math.floor(sum_resp / total);
    };
    // const getPreferences = () => {
    //     getProfilePreferences()
    //         .then((data) => {

    //             setpreferncesSection(data.data.data);
    //         })
    //         .catch((err) => console.log(err));
    // };
    // const getTechnologies = () => {
    //     getProfileTechnology()
    //         .then((data) => {
    //             setteachnologySection(data.data.data);
    //         })
    //         .catch((err) => console.log(err));
    // };
    return (
        <div className="schedule-advance-box-all-container">
            <div class="single-profile-saction single-profile-saction-new">
                {show == undefined && <div onClick={() => navigate(-1)} class="back-btn-profile">
                    {from !== "booking" &&
                        <div className="bac-href pointer">
                            <img className="bac-img" src="/images/arrow-back.png" alt="back" />
                            Go Back
                        </div>
                    }
                </div>}
                <div class="profile-all-box">
                    <div class="banner-img-profile">
                        <img src="/images/profile-cover.png" alt="avatar" />
                    </div>
                    <div class="profile-info app-new-profile">
                        <div class="profile-image">
                            <img
                                className="public-profile-picture"
                                src={`${profileSection !== null && profileSection.general.profile_image !== ""
                                    ? process.env.REACT_APP_IMAGE_URL +
                                    profileSection.general.profile_image
                                    : "/images/user-dummy.png"
                                    }`}
                                alt="avatar"
                            />
                        </div>
                        <div class="profile-name">
                            <div class="nam-prof">
                                {profileSection !== null && screenNameHandler(profileSection.general.first_name, profileSection.general.last_name)}
                            </div>
                            <a href="#">
                                {profileSection !== null && screenNameHandler(profileSection.general.first_name, profileSection.general.last_name)}
                            </a>
                            <div class="star-rating">
                                {profileSection !== null && (
                                    <StarsRating
                                        count={5}
                                        value={calculateRating(profileSection.feedbacks)}
                                        size={20}
                                        color2={"#ffc107"}
                                        edit={false}
                                        color1={"gray"}
                                    />
                                )}
                                {/* {profileSection !== null &&  Array.from(Array(5).keys()).map((item) => (
                  <>
                    {item + 1 <=
                    parseInt(calculateRating(profileSection.feedbacks)) ? (
                      <img src={"localImages/star.png"} alt="star" />
                    ) : (
                      <img src="localImages/hollowstar.svg" alt="white star" />
                    )}
                  </>
                ))} */}
                                {/* <span>0</span>{" "} */}
                                {/* <img src="localImages/hollowstar.svg" alt="white star" />
                <img src="localImages/hollowstar.svg" alt="white star" />
                <img src="localImages/hollowstar.svg" alt="white star" />
                <img src="localImages/hollowstar.svg" alt="white star" />
                <img src="localImages/hollowstar.svg" alt="white star" /> */}
                                {/* <em>0 Ratings</em> */}
                            </div>
                        </div>
                    </div>

                    {from !== "booking" && <div className="profile-book-now">
                        {/* <button type="button" onClick={openPopup}>Book Now</button> */}
                        <a
                            className="book-now-practice"
                            href={
                                profileSection !== null &&
                                    profileSection.personal_booking_link !== ""
                                    ? profileSection.personal_booking_link
                                    : "#"
                            }
                        >
                            Book Here
                        </a>
                    </div>}

                    <div class="profile-details app-new-profile-details">
                        <ul>
                            <li>
                                <div class="icon-detls">
                                    <img src="/images/chate.png" alt="" />
                                </div>
                                <em>Languages Available for Mediumship Sessions:</em>
                                {profileSection !== null &&
                                    profileSection.general.languages.map((data) => (
                                        <span>{data.lanugage_name}</span>
                                    ))}
                            </li>
                            {parseInt(serviceValue) !== 2 && <li>
                                <div class="icon-detls">
                                    <img src="/images/present.png" alt="" />
                                </div>
                                <em>Mediumship Specialities:</em>
                                {profileSection !== null &&
                                    profileSection.general.specialities.map((data) => (
                                        <span>{data.specialty_name}</span>
                                    ))}
                            </li>}
                            <li>
                                <div class="icon-detls">
                                    <img src="/images/word.png" alt="" />
                                </div>
                                <em>Country:</em>
                                <a href="#">
                                    {profileSection !== null && profileSection.general.country}
                                </a>
                            </li>
                            {/* <li>
                <div class="icon-detls">
                  <img src="images/web.png" />
                </div>
                <em>Website:</em>
                <a href="#">{PractitionerInfo.general.website}</a>
              </li> */}
                        </ul>
                    </div>
                    {parseInt(serviceValue) === 2 &&     <>
                    { profileSection !== null && profileSection.general.biographical_info !== "" && <div class="profile-bio">
                        <h2>About Me</h2>
                        {/* {console.log(typeof html)} */}
                        {/* <div dangerouslySetInnerHTML={{ __html: html }} /> */}
                        <p>{(profileSection.general.biographical_info)}</p>
                        {/* <div dangerouslySetInnerHTML={{ __html: JSON.parse(PractitionerInfo.general.biographical_info )}} /> */}
                    </div>}
</>}
                    <div class="profile-bio">
                    {parseInt(serviceValue) === 2 &&   <>
                        <button
                            type="button"
                            class="btn btn-primary bg-theme-d5a67b height-35 d-block mb-5 width-245px"
                            data-toggle="modal"
                            data-target=".bd-example-modal-lg"
                        >
                            About Our Reviews
                        </button>

                        <div
                            class="modal fade bd-example-modal-lg mt-5"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="myLargeModalLabel"
                            aria-hidden="true"
                        >
                            <div class="modal-dialog modal-lg modal-dialog-preview">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">
                                            {" "}
                                            About Our Reviews
                                        </h5>
                                        <button
                                            type="button"
                                            class="close about-us-review-close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body p-5">
                                        <p>
                                            {" "}
                                            In an effort to be as transparent as possible, Mediums who
                                            have completed a sufficient number of sessions can publish
                                            recent reviews from clients (we remove identifying
                                            information and confidential details). We don’t cherry
                                            pick reviews or publish just the positive ones.
                                        </p>
                                        <br />
                                        <p>
                                            We hope you will recognize both the quality of our Mediums
                                            and that mediumship is not an exact science and does not
                                            work perfectly every time, even for the most experienced
                                            Mediums. This balanced view of recent client experiences
                                            should give you a sense of the Medium’s style and results.
                                        </p>
                                        <br />
                                        <p>
                                            We applaud our Mediums for choosing to publish their
                                            reviews, blemishes and all, to help bring more
                                            transparency to the mediumship profession.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        </>}
                        <h2 className="mb-3">
                            Client Reviews{" "}
                            {/* {profileSection !== null && profileSection.recommendation !== ""
                                ? `: ${profileSection.recommendation}% Recommend`
                                : " "} */}
                        </h2>

                        <div className="feedback-container">
                            {profileSection !== null &&
                                profileSection.feedbacks.map((feedback) => (
                                    <div className="feedback-border-feed mt-3 d-flex align-items-center ">
                                        <div className="min-width-200px">
                                            <div className="d-flex align-items-center">
                                                <div className="feedback-image">
                                                    <img
                                                        className="practioner-profile-image"
                                                        src="../images/user-dummy.png"
                                                        alt="avatar"
                                                    />
                                                </div>
                                                <span className="ml-3">
                                                    {feedback.client_name} -{" "}
                                                    {JSON.parse(feedback.feedback).Country}
                                                </span>
                                            </div>
                                            <div className="feedback-star-image">
                                                <StarsRating
                                                    count={5}
                                                    value={parseInt(
                                                        JSON.parse(feedback.feedback).Star_Rating
                                                    )}
                                                    size={20}
                                                    color2={"#ffc107"}
                                                    edit={false}
                                                    color1={"#ffffff"}
                                                />
                                                {/* {Array.from(Array(5).keys()).map((item) => (
                                                <>
                                                    {item + 1 <=
                                                        parseInt(
                                                            JSON.parse(feedback.feedback).Star_Rating
                                                        ) ? (
                                                        <img src={"localImages/star.png"} />
                                                    ) : (
                                                        <img
                                                            src="localImages/hollowstar.svg"
                                                            alt="white star"
                                                        />
                                                    )}
                                                </>
                                            ))} */}
                                            </div>
                                            <p className="feedback-date">{feedback.feedback_date}</p>
                                        </div>
                                        <div>
                                            <span className="margin-unset line-height-unset feedback-heading">
                                                {JSON.parse(feedback.feedback).Title}
                                            </span>
                                            <span className="margin-unset line-height-unset">
                                                {JSON.parse(feedback.feedback).Publishable_Feedback}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
            {/* <BookingPopUp item={PractitionerInfo} /> */}
        </div>
    );
}
