import React from 'react'
// import { CSVLink } from "react-csv";
// export default function CsvDownload({headers,data ,filename}) {
//   return (
//     <CSVLink filename={filename} className='export-btn' headers={headers} data={data}>Export Table</CSVLink>
//   )
// }
// download={usertype.csv}
export default function CsvDownload(props) {
  const {href}=props;
  return (
 
            <a className="export-btn" href={href} >
        Export Table
        </a> 
  )
}




