import React from "react";

export default function Notifiacation() {
  return (
    <div className="step-notifaction">
      <div className="noti-icon">
        <img src="images/info-icon.png" alt="notification" />
      </div>
      <div className="noti-cont">
        <span>
          Your membership account is awaiting approval by the site
          administrator.
        </span>
        <p>
          You will not be able to fully interact with the practitioner portions
          of the platform until you receive an email that you have been <br />
          approved as a VerySoul practitioner. If you've waiting more than 48
          hours, feel free to contact{" "}
          <a href="mailto:support@verysoul.com">support@verysoul.com</a> for
          assistance.
        </p>
      </div>
    </div>
  );
}
