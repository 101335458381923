import React from "react";

export default function Button({ onClick, className, title, type, status ,...prop}) {
  return (
    <button {...prop} onClick={onClick} type={type} className={className}>
      {parseInt(status) === 0
        ? title
        : parseInt(status) === 1
        ? "success"
        : parseInt(status) === 3
        ? "unsuccess"
        : ""}
    </button>
  );
}
