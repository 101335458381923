import React, { useState } from 'react'

export default function ChooseMedium({ rejectPaired, acceptPaired, hangon, seconds, matchName }) {

    return (
        <div class="container-fluid question-container">
            <div class="row justify-content-center">
                <div class="col-11 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
                    <div class="card px-0 pt-4 pb-0 mt-3 mb-3">
                        <form id="msform">
                            <fieldset>
                                <div class="form-card">
                                    <div class="row wrap">

                                        {!hangon ? <h2 className='text-center font-size-30'>
                                            {matchName} is also looking for a match.<br /><br /> Would you like this match ?
                                        </h2>
                                            :
                                            <h2 className='text-center font-size-30 mt-5'>
                                                Checking on your match now….
                                            </h2>
                                        }


                                    </div>
                                    {/* <div className='text-center mt-1 font-size-18'>
                                        {hangon ? <> <i>*</i> Hang on we’re making a connection </> : ""}
                                    </div> */}
                                </div>
                                {<div className='ml-4 mr-4 mt-4 mb-5 d-flex justify-content-center align-items-center choose-medium'>
                                    {!hangon && <button type='button' onClick={rejectPaired} className='previous action-button-previous float-right width-unset pl-4 pr-4'>
                                        Decline
                                    </button>}
                                    <span className='d-block'>

                                        ({seconds}s)
                                    </span>
                                    {!hangon && <button type='button' onClick={acceptPaired} className={`next action-button float-left ${hangon ? 'btn-disabled-practice' : ''}`}>
                                        {'Accept'}
                                    </button>}
                                </div>}



                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
