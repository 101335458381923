import Vimeo from '@u-wave/react-vimeo'
import React, { useEffect, useState } from 'react'
import { FadeLoader } from 'react-spinners'
import Button from '../../../../components/button'
import { Link, useNavigate } from 'react-router-dom'
import TickMark from '../../../../components/tickmark'
import { completeMediumOnBoarding, saveStepsProgress } from '../../../../services/medium'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../../../reducer/auth/auth'
import { changeOnbarding } from '../../../../reducer/userSlice'

export default function StepSix({ setuserFirstTime, NextStep, innerWidthHandler }) {
    const { id } = useSelector(
        (state) => state.user.user
    );
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [showLoader, setShowLoader] = useState(true);
    const [verificationStatus, setVerificationStatus] = useState(false);

    const openAppointMent = () => {
        window.open("/integration", "_blank", "noreferrer")
    }
    useEffect(() => {
        // setTimeout(() => {
        // setVerificationStatus(1);
        // setShowLoader(false);
        // }, 7000)
    }, [])
    const handleStep = (page) => {

        dispatch(setLoading(true))
        let body = {
            medium_id: id,
        }
        completeMediumOnBoarding(body).then((data) => {
            dispatch(setLoading(false))
            if (data.data.status) {
                dispatch(changeOnbarding({
                    "step_1": 1,
                    "step_2": 1,
                    "step_3": 1,
                    "step_4": 1,
                    "step_5": 1,
                    "step_6": 1
                })
                )
                setTimeout(() => {
                    console.log(page, "page")
                    setVerificationStatus(true);
                    setTimeout(() => {
                        console.log(page);
                        navigate(page);
                        window.location.reload();
                    }, 10000)

                }, 300)

            }


        }).catch(() => { dispatch(setLoading(false)) })
    }
    const savestep = (page) => {

        let body = {
            step_number: 6
        }
        saveStepsProgress(body).then((data) => {
            if (data.data.status) {
                localStorage.setItem('step', 6)
                handleStep(page)

            }
        }).catch(() => {

        })
    }
    return (
        <>
            {verificationStatus ?
                <div className='mt-5'>
                    <h2>Thanks for completing Onboarding!</h2>
                </div>
                :
                <div>
                    <div className='text-left  pl-5 pr-5 mt-5 mb-5'>

                        <h3 className='font-size-40'> Book a Practice</h3>
                        <ol className='font-size-18 line-height-24px'>
                            <li>Watch the video below.</li>
                            <li>Book a Practice Session.</li>
                            {/* <li>Go to Appointments to see practice details. (optional)</li> */}
                        </ol>
                        {/* <p className='font-size-18 line-height-24px'>Watch the video below for steps on how to book a practice.</p> */}


                    </div>


                    <div>
                        <Vimeo
                            muted={true}
                            autoplay={false}
                            className="mb-3git "
                            width={innerWidthHandler()}
                            height={330}
                            video={"https://vimeo.com/922176805/a4d6d52f6e"}
                        />
                    </div>


                    <br />
                    <br />
                    <br />
                    <br />

                    <Button
                        className={"action-button margin-unset"}
                        status={0}
                        onClick={() => savestep('/booking')}
                        title="Make Appointment" />
                    {/* <div className='ml-5 d-inline-block'>
                        <Button
                            className={"action-button margin-unset skip-pbtn "}
                            status={0}
                            onClick={() => savestep('/appointments')}
                            title="Skip for now" />
                    </div> */}


                </div>
            }</>
    )
}
