import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik } from "formik";

//services
import { forgotPassword } from "../../services/auth";

//reducer
import { setLoading } from "../../reducer/auth/auth";


//utils

const ForgotPassword = () => {
  //hooks
  const dispatch = useDispatch();

  //states
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    window.loadCarousel();
  }, []);

  const LoginUser = async (formData) => {
    dispatch(setLoading(true));
    let item = {
      email: formData.email,
    };
    forgotPassword(item)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          window.openModal();
        }
        if (
          !data.data.status &&
          data.data.error 
        ) {
          setEmailError(data.data.error);
        }
        if (!data.data.status && data.data.error === "Unauthorized") {
          setEmailError("Invalid Email");
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  return (
    <div>
      <div className="login-register-saction register-medium">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <section id="testim" className="testim">
                <div className="wrap">
                  <span
                    id="right-arrow"
                    className="arrow right fa fa-chevron-right"
                  ></span>
                  <span
                    id="left-arrow"
                    className="arrow left fa fa-chevron-left "
                  ></span>
                  <div id="testim-content" className="cont">
                    <div className="active">
                      <div className="img">
                        <img src="localImages/testi-monal-image.png" alt="testinomial" />
                      </div>
                      <h2>WE ARE SIMPLY THE MESSENGERS</h2>
                      <p>
                        Mediums are communication instruments for those who have
                        passed. We convey their perspectives and sentiments.
                        Given your unique personal relationship, you will always
                        be best positioned to understand their communications.
                      </p>
                    </div>
                    <div>
                      <div className="img">
                        <img src="localImages/testi-monal-image.png" alt="testinomial" />
                      </div>
                      <h2>WE BELIEVE EVIDENCE IS CRUCIAL</h2>
                      <p>
                        Communicators want to provide good evidence – things you
                        would recognize about them – so that you know who they
                        are. We try to convey as much detail as possible, but
                        only you can determine when the evidence is sufficient.
                      </p>
                    </div>
                    <div>
                      <div className="img">
                        <img src="localImages/testi-monal-image.png" alt="testinomial" />
                      </div>
                      <h2>We are as diverse as the world is round</h2>
                      <p>
                        Mediums live in many different places and come in all
                        shapes, sizes, colors, orientations, and ages. In some
                        cultures our abilities are prized and in others, feared.
                        There is no need for either; we are merely here to
                        serve.
                      </p>
                    </div>
                    <div>
                      <div className="img">
                        <img src="localImages/testi-monal-image.png" alt="testinomial" />
                      </div>
                      <h2>We are regular people</h2>
                      <p>
                        Mediums are fully human and come from many backgrounds.
                        We might be your colleague or neighbor, a nurse, lawyer,
                        construction worker, grocery store clerk. We are regular
                        people who experience life’s joys and challenges like
                        you.
                      </p>
                    </div>
                    {/* <div>
                      <div className="img">
                        <img src="localImages/testi-monal-image.png" alt="" />
                      </div>
                      <h2>We discover our abilities in unexpected ways</h2>
                      <p>
                        Some of us discover our abilities as children and others
                        later in life. Many of us become aware following a
                        life-altering event like the passing of a loved one. We
                        often keep our first experiences to ourselves as we work
                        to understand them.
                      </p>
                    </div>
                    <div>
                      <div className="img">
                        <img src="localImages/testi-monal-image.png" alt="" />
                      </div>
                      <h2>We are each unique as instruments</h2>
                      <p>
                        We use our entire being to serve. We welcome
                        communicators into our awareness as we would a friend.
                        We use our senses and life experiences to relay their
                        information. Our lives are their libraries and our
                        bodies their instruments.
                      </p>
                    </div>
                    <div>
                      <div className="img">
                        <img src="localImages/testi-monal-image.png" alt="" />
                      </div>
                      <h2>WE WORK WITH SUBTLE COMMUNICATIONS</h2>
                      <p>
                        For many mediums, communications can seem subtle.&nbsp;
                        Establishing connections is relatively easy but
                        discerning the information without interference from our
                        minds can be more challenging. Your real time feedback
                        helps us stay on track.
                      </p>
                    </div>
                    <div>
                      <div className="img">
                        <img src="localImages/testi-monal-image.png" alt="" />
                      </div>
                      <h2>We work hard to hone our skills</h2>
                      <p>
                        We invest years in study and training, learning to
                        surrender to the experience, strengthen connections with
                        communicators, and convey specific information without
                        embellishment from our minds. We learn to completely
                        trust those communicating.
                      </p>
                    </div>
                    <div>
                      <div className="img">
                        <img src="localImages/testi-monal-image.png" alt="" />
                      </div>
                      <h2>WE ALSO WORK ON OURSELVES</h2>
                      <p>
                        Becoming a good Medium takes a lot of self-work. We
                        cannot convey what we cannot perceive, including what we
                        have blocked from our minds. So we work to remove those
                        blocks to become the clearest possible communication
                        channels.
                      </p>
                    </div>
                    <div>
                      <div className="img">
                        <img src="localImages/testi-monal-image.png" alt="" />
                      </div>
                      <h2>Yet, we are not infallible</h2>
                      <p>
                        We want to serve well, but we don't always get it right.
                        We might misinterpret or connect with someone you don’t
                        recognize. Let us know when you don’t understand so we
                        can recalibrate.
                      </p>
                    </div>
                    <div>
                      <div className="img">
                        <img src="localImages/testi-monal-image.png" alt="" />
                      </div>
                      <h2>We can't read your mind</h2>
                      <p>
                        Sometimes we can’t even read our own minds. But
                        seriously, authentic mediumship abilities seem to
                        require loving, healing intentions. Mind-reading would
                        not serve that purpose.
                      </p>
                    </div>
                    <div>
                      <div className="img">
                        <img src="localImages/testi-monal-image.png" alt="" />
                      </div>
                      <h2>We aren't all-knowing</h2>
                      <p>
                        While many of us do experience guidance and inspiration,
                        it is generally not evidential nor independently
                        verified. So it may be influenced by our minds and
                        beliefs. Ethical Mediums will always encourage you to
                        consider what resonates for you.
                      </p>
                    </div>
                    <div>
                      <div className="img">
                        <img src="localImages/testi-monal-image.png" alt="" />
                      </div>
                      <h2>WE SHARE IN THE AWE OF EVERY SESSION</h2>
                      <p>
                        We often hear about Mediums having a gift. But the
                        love-filled and awe-inspiring communications are the
                        true gift to both you and to us. We also know that
                        everyone can experience these connections directly in
                        their own way.
                      </p>
                    </div> */}
                  </div>
                  <ul id="testim-dots" className="dots">
                    <li className="dot active"></li>
                    <li className="dot"></li>
                    <li className="dot"></li>
                    <li className="dot"></li>
                    {/* <li className="dot"></li>
                    <li className="dot"></li>
                    <li className="dot"></li>
                    <li className="dot"></li>
                    <li className="dot"></li>
                    <li className="dot"></li>
                    <li className="dot"></li>
                    <li className="dot"></li>
                    <li className="dot"></li> */}
                  </ul>
                </div>
              </section>
            </div>

            <div className="col-md-6">
              <div className="login-saction-right">
                <div className="logo">
                  <img src="images/logo-verysoul.png" alt="logo" />
                </div>
                <h2>Forgot Password</h2>
                <span className="error font-size-17">{emailError}</span>
                <div className="form-l---r">
                  <Formik
                    initialValues={{
                      email: "",
                    }}
                    validate={(values) => {
                      const errors = {};
                      if (!values.email) {
                        errors.email = "Required";
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = "Invalid email address";
                      }
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      LoginUser(values);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="from-fild">
                          <div className="icon">
                            <img src="images/mail.png" alt="mail" />
                          </div>
                          <label>
                            Email Address<i>*</i>
                          </label>
                          <input
                            className={`${
                              errors.email && touched.email && errors.email
                                ? "error-boundry"
                                : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            type="email"
                            id="email"
                            name="email"
                            placeholder="myemail@address.com"
                          />
                          <span className="error">
                            {errors.email && touched.email && errors.email}
                          </span>
                        </div>

                        <div className="bution">
                          <button type="submit" className="button-bx">
                            Reset Password
                          </button>
                          <div
                            data-backdrop="static"
                            className="modal fade"
                            id="myModal"
                            role="dialog"
                          >
                            <div className="modal-dialog modal-dialog-preview">
                              <div className="modal-content">
                                <div className="modal-body">
                                  <div className="icon-pop">
                                    <img
                                      src="images/Union-icon.png"
                                      alt="Union-icon"
                                    />
                                  </div>
                                  <h3>
                                    &nbsp;
                                    {/* Begin Registration to <br />
                                    Be a Verysoul Client */}
                                  </h3>
                                  <p className="mt-4">
                                    We have successfully send you reset link
                                    <br />
                                    please check your inbox
                                  </p>
                                  <div
                                    className="register-medium-confirnation"
                                    // onClick={() => redirectRoute("/login")}
                                    data-dismiss="modal"
                                  >
                                    Close
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="dont-have-acc margin-unset text-center">
                          <p className="mt-4">
                            <Link to="/login">Login</Link>
                          </p>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*****modal */}
    </div>
  );
};
export default ForgotPassword;
