import React, { useState } from 'react'
import { starterSignupSurvey } from '../../../services/auth';
import { useSelector } from 'react-redux';

export default function StepZero({ NextStep }) {
    const { id } = useSelector(
        (state) => state.user.user
    );
    const [formValues, setFormValues] = useState({});

    const [about_verysoul, setAbout_verysoul] = useState('');
    const [past_experience, setPast_experience] = useState('');
    const [medium_psychic, setMedium_psychic] = useState('');
    const [help_text, setHelp_text] = useState(null);
    const [otherValue, setOtherValue] = useState('')
    const [userId, setUserId] = useState('');

    const onChangeHandlerMediumOrPsychic = (e, type) => {
        let value = e.target.value;
        if (type === 1) {
            setMedium_psychic(value);
        }
        else {
            setPast_experience(value);

        }

    }
    const submitFunc = () => {
        let body = {
            user_id: id,
            about_verysoul: about_verysoul,
            past_experience: past_experience === "Other" ? otherValue : past_experience,
            medium_psychic: medium_psychic,
            help_text: help_text
        }
        starterSignupSurvey(body).then((data) => {
            if (data.data.status) {
                NextStep();
                // window.loadModal2("#starter-client-question", "hide");
                // LoginUser(formValues)
            }

        }).catch(() => {

        })

        // RegisterUser(formValues, "submit");
    }
    return (
        <div class="modal-content p-5">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    {" "}
                    Special Questions for Starter Client Volunteers

                </h5>

            </div>
            <div class="modal-body p-4 text-left">
                <div>
                    <p className="margin-unset line-height-24px font-size-17">
                        {" "}
                        <b>   How did you hear about VerySoul? <i>*</i></b>
                    </p>
                    <textarea value={about_verysoul} onChange={(e) => setAbout_verysoul(e.target.value)} rows={12} cols={70}>

                    </textarea>
                </div>

                <br />
                <div>
                    <p className="margin-unset line-height-24px font-size-17">
                        <b>     Have you had a fulfilling experience with an Evidential Medium before?<i>*</i></b>
                    </p>
                    <div className='d-flex align-items-center mt-2'>

                        <input onChange={(e) => onChangeHandlerMediumOrPsychic(e, 2)} name='past_experience' value={'Yes with VerySoul'} type='radio' id='yeswithverysoul' className='radio-btn-starter mr-2' />
                        <label htmlFor='yeswithverysoul' className='margin-unset'>Yes with VerySoul</label>
                    </div>
                    <div className='d-flex align-items-center mt-2'>

                        <input onChange={(e) => onChangeHandlerMediumOrPsychic(e, 2)} value={'Yes, Other'} name='past_experience' type='radio' id='yesother' className='radio-btn-starter mr-2' />
                        <label htmlFor='yesother' className='margin-unset'>

                            Yes, Other
                        </label>
                    </div>
                    <div className='d-flex align-items-center mt-2'>

                        <input onChange={(e) => onChangeHandlerMediumOrPsychic(e, 2)} value={'No'} name='past_experience' type='radio' id='no' className='radio-btn-starter mr-2' />
                        <label htmlFor='no' className='margin-unset'> No (please go to specials.verysoul.com and register for a Donated Free session)</label>
                    </div>
                    <div className='d-flex align-items-center mt-2'>

                        <input onChange={(e) => onChangeHandlerMediumOrPsychic(e, 2)} value={'Other'} name='past_experience' type='radio' id='other' className='radio-btn-starter mr-2' />
                        <label htmlFor='other' className='margin-unset'> Other:</label>
                        <input value={otherValue} onChange={(e) => setOtherValue(e.target.value)} type='text' />
                    </div>

                </div>
                <br />
                <div className='mt-4'>
                    <p className="margin-unset line-height-24px font-size-17">
                        <b>     Are you a Medium or psychic? <i>*</i></b>
                    </p>
                    <div className='d-flex align-items-center mt-2'>

                        <input onChange={(e) => onChangeHandlerMediumOrPsychic(e, 1)} value={'Yes, I am a Medium'} type='radio' name='medium_psychic' id='yes-medium_psychic' className='radio-btn-starter mr-2' />
                        <label htmlFor='yes-medium_psychic' className='margin-unset'>Yes, I am a Medium</label>
                    </div>
                    <div className='d-flex align-items-center mt-2'>

                        <input onChange={(e) => onChangeHandlerMediumOrPsychic(e, 1)} type='radio' value={'Yes, I am a Psychic'} id='yes-psychic' name='medium_psychic' className='radio-btn-starter mr-2' />
                        <label htmlFor='yes-psychic' className='margin-unset'>Yes, I am a Psychic</label>

                    </div>
                    <div className='d-flex align-items-center mt-2'>

                        <input onChange={(e) => onChangeHandlerMediumOrPsychic(e, 1)} type='radio' value={'None of the Above'} name='medium_psychic' id='none-medium_psychic' className='radio-btn-starter mr-2' />
                        <label htmlFor='medium_psychic' className='margin-unset'> None of the Above</label>

                    </div>


                </div>
                <div className='mt-4'>
                    <p className="margin-unset line-height-24px font-size-17">
                        <b>      Are you interested in volunteering in other ways for VS? If so, tell us how you think you could help.</b>
                    </p>
                    <textarea name='help_text' value={help_text} onChange={(e) => setHelp_text(e.target.value)} rows={12} cols={70}>

                    </textarea>
                </div>
                <div className='mt-4 text-center'>

                    <button className='next action-button button-starter' onClick={submitFunc} >Submit</button>
                </div>
            </div>
        </div>
    )
}
