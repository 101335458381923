import React, { useEffect, useState } from 'react'
import TransactionTable from '../../../components/transactiontable'
import { getTransactionDetails } from '../../../services/medium';
import { useSelector } from 'react-redux';

const listHeading = [
    'Session #',
    'Name',
    'Date',
    'Session Date Time',
    'Payment Method',
    'Amount',
    'Currency'
]
export default function Transaction() {
    const { id } = useSelector((state) => state.user.user);
    const [transactionsList, setTransactionsList] = useState([]);

    useEffect(() => {
        getTransactions()
    }, [])

    const getTransactions = () => {
        let body = {
            medium_id: id,
        }
        getTransactionDetails(body).then((data) => {
            console.log(data.data.status,"data")
            if(data.data.status){
                console.log(data.data.data)
                setTransactionsList(data.data.data)

            }
         }).catch(() => { })
    }
    return (
        <TransactionTable listHeading={listHeading}  transactionsList={transactionsList}/>
    )
}
