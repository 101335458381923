export const list = [
  { id: 1, name: "Upcoming", count: 0 },
  { id: 2, name: "Completed", count: 0 },
  { id: 3, name: "Cancelled", count: 0 },
  { id: 4, name: "No Shows", count: 0 },
  { id: 5, name: "Restriced", count: 0 },
  { id: 6, name: "Ended Early", count: 0 },
  { id: 7, name: "Ongoing", count: 0 },

];


export const filterList = [
  {
    id: 1,
    name: "Medium Name",
    value: "screen_name",
  },
  {
    id: 2,
    name: "Session Name",
    value: "session_type",
  },
  {
    id: 3,
    name: "Client name",
    value: "name",
  }, {
    id: 4,
    name: "Medium email",
    value: "medium_email",
  }, {
    id: 5,
    name: "Client email",
    value: "client_email",
  }, {
    id: 6,
    name: "Appointment Id",
    value: "meeting_id",
  },
  {
    id: 7,
    name: "Created Date",
    value: "created_at",
  },
];
