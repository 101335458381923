import moment from "moment";
import React, { useEffect, useState } from "react";

export default function EmerGencyPopUp({ closePopup }) {





    return (
        <div>
            <div
                id="EmerGencyPopUp"
                class="modal fade bd-example-modal-lg mt-5 preview-modal-availability"
                tabindex="-1"
                role="dialog"
                aria-labelledby="myLargeModalLabel"
                aria-hidden="true"
                data-backdrop="static"
            >
                <div class="modal-dialog modal-lg modal-dialog-preview text-center">
                    <div class="modal-content p-5">
                        <h3>To ensure seamless operation of the On-Demand sessions, we advise you to clear your browser cache before going Live.
                            <br />  To clear the cache, follow the shortcuts below as based on your device:
                        </h3>
                        <br />
                        <br />
                        <br />


                        <h3 className="font-size-22 text-left text-underline"> If you have Mac operating System the press CMD + R</h3>

                        <br />


                        <h3 className="font-size-22 text-left  text-underline">    If you have Window operating System the press CTRL + ALT + R</h3>


                        <div id="msform">

                            <input
                                type="button"
                                name="Close"
                                class="next action-button m-3"
                                value="Close"
                                onClick={closePopup}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
