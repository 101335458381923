import React, { useEffect, useState } from "react";
import moment from "moment";
import { approveRequest, approveRequestById, getAllRequests, getApprovedFeedback } from "../../../services/admin";

import { setApprovedFeedback, setNameRequests } from "../../../reducer/adminSlice";
import { useDispatch, useSelector } from "react-redux";
import { toTitleCase } from "../../../utils/helpers";
import Button from "../../../components/button";
import { toast } from "react-toastify";
import InfoPopup from "../../../components/infopopup";
import EditFeedback from "../feedback/subcomponent/editfeedback";
import { setLoading } from "../../../reducer/auth/auth";
import EditFeedbackPractice from "../feedback/subcomponent/editfeedbackpractice";

export default function FeedBackRequests() {
  const { approvedFeedback } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const [requests, setRequests] = useState([]);
  const [imageUrl, setImageUrl] = useState('');
  const [active, setActive] = useState({});
  const [editFeedbackDetail, setEditFeedbackDetail] = useState()


  useEffect(() => {
    if (!approvedFeedback.length) getFeedbacks();
  }, []);
  useEffect(() => {
    const response = approvedFeedback.map((resp) => {
      return {
        ...resp,
        status: false,
      };
    });
    setRequests(response);
  }, [approvedFeedback]);
  const getFeedbacks = () => {
    getApprovedFeedback()
      .then((data) => {
        if (data.data.status) {

          dispatch(setApprovedFeedback(data.data.data));
        }
      })
      .catch(() => { });
  };

  const onClickHandler = (e, id) => {
    const response = requests.map((user) => {
      if (user.feedback_id === id) {
        user.status = !user.status;
      } else {
        user.status = false;
      }
      return user;
    });
    setRequests(response);
  };
  const approveUserRequest = (id, status) => {
    dispatch(setLoading(true));
    let body = {
      feedback_id: id,
      status: status,
    };
    approveRequestById(body)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          getFeedbacks()
          //   getRequests();
          if (status === 1) {
            toast.success('Approved Successfully', { position: "top-center" })

          }
          else {
            toast.success('Declined Successfully', { position: "top-center" })
          }
        }

      })
      .catch(() => { dispatch(setLoading(false)); });
  };
  const openPopup = (url) => {
    setImageUrl(url)
  }
  const openMoreInfo = (info) => {
    console.log(info);
    let array = [];
    // Object.values(info).map((item) => {
    array.push(info.feedback);
    // });

    setActive(array);
    window.loadModal2("#infopopup", "show");
  };
  const onEditFeedback = (item) => {
    let info = { 0: item }
    setEditFeedbackDetail(info)
    console.log(info)
    if (item.session_type === "1") {
      window.loadModal2('#editpracticeFeedbacks', 'show');
    }
    else {

      window.loadModal2('#editFeedback', 'show');
    }

  }
  return (
    <>
      <div className="table-box-medium">
        <table className="caseTable">
          <tbody>
            <tr className="dark">
              <th>Session #</th>
              <th>Screen Name</th>
              {/* <th>Request Type</th> */}
              {/* <th>Request value</th> */}
              <th>Date</th>
              <th>Status</th>
              <th>View</th>
              <th className="text-right">Actions</th>
            </tr>
            {requests.length > 0 ? (
              requests.map((request) => (
                <tr className="innr pointer">
                  <td>{request.front_appointment_id}</td>
                  <td className="capitalize">
                    {request.screen_name ? request.screen_name : "N/A"}
                  </td>
                  {/* <td> {request.type ? toTitleCase(request.type) : "N/A"}</td> */}
                  {/* <td className="change">
                    {request.type === 'profile_image' ?
                      <Button
                        data-toggle="modal"
                        data-target="#image-Popup"
                        status={0}
                        onClick={() => openPopup(request.value)}
                        type="button"
                        className="resend-btn"
                        title="View"
                      />
                      :
                      request.value ? request.value : "N/A"

                    }
                  </td> */}
                  <td>
                    {" "}
                    {moment(request.date).format("LL")}
                  </td>
                  <td> <div className="color-d5a67b d-flex align-items-center height-45">{request.feedback_status === 1 ? 'Accepted' : request.feedback_status === 2 ? 'Consider' : request.feedback_status === 0 ? 'Pending' : 'Declined'}</div></td>
                  <td>

                    <Button
                      data-toggle="modal"
                      data-target="#image-Popup"
                      status={0}
                      onClick={() => openMoreInfo(request)}
                      type="button"
                      className="resend-btn"
                      title="View"
                    />
                    {request.master_status === 2 &&
                      <Button
                        data-toggle="modal"
                        data-target="#image-Popup"
                        status={0}
                        onClick={() => onEditFeedback(request)}
                        type="button"
                        className="resend-btn ml-2"
                        title="Edit"
                      />
                    }
                  </td>
                  <td className="dott">
                    <img
                      onClick={(e) => onClickHandler(e, request.feedback_id)}
                      src="/images/Unionblck.png"
                      className="padding-10"
                      alt="dots"
                    />

                    {request.status && (
                      <div className="approve-btn-1 approve-btn z-index-1">
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            approveUserRequest(request.feedback_id, 1);
                          }}
                        >
                          Approve
                        </span>

                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            approveUserRequest(request.feedback_id, 2);
                          }}
                        >
                          Decline
                        </span>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No Request Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <EditFeedback editFeedbackDetail={editFeedbackDetail} callBack={getFeedbacks} />
      <EditFeedbackPractice editFeedbackDetail={editFeedbackDetail} callBack={getFeedbacks} />
      <InfoPopup feedBackinfo={active} />
    </>
  );
}
