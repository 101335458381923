const items = [
  {
    title: "Practice",
    items: [
      {
        session_name: "Practice_Session 01",
        session_time: "15 Minutes",
        session_price: "$0.00",
      },
      {
        session_name: "Practice_Session 02",
        session_time: "30 Minutes",
        session_price: "$0.00",
      },
      {
        session_name: "Practice_Session 03",
        session_time: "45 Minutes",
        session_price: "$0.00",
      },
    ],
  },
  {
    title: "Starter",
    items: [
      {
        session_name: "Practice_Session 01",
        session_time: "15 Minutes",
        session_price: "$0.00",
      },
      {
        session_name: "Practice_Session 02",
        session_time: "30 Minutes",
        session_price: "$0.00",
      },
      {
        session_name: "Practice_Session 03",
        session_time: "45 Minutes",
        session_price: "$0.00",
      },
    ],
  },
  {
    title: "Professional",
    items: [
      {
        session_name: "Practice_Session 01",
        session_time: "15 Minutes",
        session_price: "$0.00",
      },
      {
        session_name: "Practice_Session 02",
        session_time: "30 Minutes",
        session_price: "$0.00",
      },
      {
        session_name: "Practice_Session 03",
        session_time: "45 Minutes",
        session_price: "$0.00",
      },
    ],
  },
  {
    title: "Debrief Coaching",
    items: [
      {
        session_name: "Practice_Session 01",
        session_time: "15 Minutes",
        session_price: "$0.00",
      },
      {
        session_name: "Practice_Session 02",
        session_time: "30 Minutes",
        session_price: "$0.00",
      },
      {
        session_name: "Practice_Session 03",
        session_time: "45 Minutes",
        session_price: "$0.00",
      },
    ],
  },
  {
    title: "Events",
    items: [
      {
        session_name: "Practice_Session 01",
        session_time: "15 Minutes",
        session_price: "$0.00",
      },
      {
        session_name: "Practice_Session 02",
        session_time: "30 Minutes",
        session_price: "$0.00",
      },
      {
        session_name: "Practice_Session 03",
        session_time: "45 Minutes",
        session_price: "$0.00",
      },
    ],
  },

  {
    title: "Support",
    items: [
      {
        session_name: "Practice_Session 01",
        session_time: "15 Minutes",
        session_price: "$0.00",
      },
      {
        session_name: "Practice_Session 02",
        session_time: "30 Minutes",
        session_price: "$0.00",
      },
      {
        session_name: "Practice_Session 03",
        session_time: "45 Minutes",
        session_price: "$0.00",
      },
    ],
  },
];

const initialValues = {
  sub_session_name: "",
  session_type: "",
  max_number_of_participant: "",
  duration: "",
  day: [],
  group_limit: "",
  min_booking_hour: "",
  min_booking_time: "",
  max_booking_hour: "",
  max_booking_time: "",
  questions: [{ question: "", answer: "" }],
  pricing_type: "free",
  fixedprice: "",
  minprice: "",
  maxprice: "",
  session_type_radio: {},
  participant_level: "",
  student_level: "",
};

export const slotsArray = [
  { change: "YES", val: "12am", mtime: "12:00", time: "12:00 AM" },
  { change: "YES", val: "1215-am", mtime: "12:15", time: "12:15 AM" },
  { change: "YES", val: "1230am", mtime: "12:30", time: "12:30 AM" },
  { change: "YES", val: "1245-am", mtime: "12:45", time: "12:45 AM" },

  { change: "YES", val: "1am", mtime: "01:00", time: "01:00 AM" },
  { change: "YES", val: "115-am", mtime: "01:15", time: "01:15 AM" },
  { change: "YES", val: "130am", mtime: "01:30", time: "01:30 AM" },
  { change: "YES", val: "145-am", mtime: "01:45", time: "01:45 AM" },

  { change: "YES", val: "2am", mtime: "02:00", time: "02:00 AM" },
  { change: "YES", val: "215-am", mtime: "02:15", time: "02:15 AM" },
  { change: "YES", val: "230am", mtime: "02:30", time: "02:30 AM" },
  { change: "YES", val: "245-am", mtime: "02:45", time: "02:45 AM" },

  { change: "YES", val: "3am", mtime: "03:00", time: "03:00 AM" },
  { change: "YES", val: "315-am", mtime: "03:15", time: "03:15 AM" },
  { change: "YES", val: "330am", mtime: "03:30", time: "03:30 AM" },
  { change: "YES", val: "345-am", mtime: "03:45", time: "03:45 AM" },

  { change: "YES", val: "4am", mtime: "04:00", time: "04:00 AM" },
  { change: "YES", val: "415-am", mtime: "04:15", time: "04:15 AM" },
  { change: "YES", val: "430am", mtime: "04:30", time: "04:30 AM" },
  { change: "YES", val: "445-am", mtime: "04:45", time: "04:45 AM" },

  { change: "YES", val: "5am", mtime: "05:00", time: "05:00 AM" },
  { change: "YES", val: "515-am", mtime: "05:15", time: "05:15 AM" },
  { change: "YES", val: "530am", mtime: "05:30", time: "05:30 AM" },
  { change: "YES", val: "545-am", mtime: "05:45", time: "05:45 AM" },

  { change: "YES", val: "6am", mtime: "06:00", time: "06:00 AM" },
  { change: "YES", val: "615-am", mtime: "06:15", time: "06:15 AM" },
  { change: "YES", val: "630am", mtime: "06:30", time: "06:30 AM" },
  { change: "YES", val: "645-am", mtime: "06:45", time: "06:45 AM" },
  { change: "YES", val: "7am", mtime: "07:00", time: "07:00 AM" },
  { change: "YES", val: "715-am", mtime: "07:15", time: "07:15 AM" },
  { change: "YES", val: "730am", mtime: "07:30", time: "07:30 AM" },
  { change: "YES", val: "745-am", mtime: "07:45", time: "07:45 AM" },

  { change: "YES", val: "8am", mtime: "08:00", time: "08:00 AM" },
  { change: "YES", val: "815-am", mtime: "08:15", time: "08:15 AM" },
  { change: "YES", val: "830am", mtime: "08:30", time: "08:30 AM" },
  { change: "YES", val: "845-am", mtime: "08:45", time: "08:45 AM" },

  { change: "YES", val: "9am", mtime: "09:00", time: "09:00 AM" },
  { change: "YES", val: "915-am", mtime: "09:15", time: "09:15 AM" },
  { change: "YES", val: "930am", mtime: "09:30", time: "09:30 AM" },
  { change: "YES", val: "945-am", mtime: "09:45", time: "09:45 AM" },

  { change: "YES", val: "10am", mtime: "10:00", time: "10:00 AM" },
  { change: "YES", val: "1015-am", mtime: "10:15", time: "10:15 AM" },
  { change: "YES", val: "1030am", mtime: "10:30", time: "10:30 AM" },
  { change: "YES", val: "1045-am", mtime: "10:45", time: "10:45 AM" },

  { change: "YES", val: "11am", mtime: "11:00", time: "11:00 AM" },
  { change: "YES", val: "1115-am", mtime: "11:15", time: "11:15 AM" },
  { change: "YES", val: "1130am", mtime: "11:30", time: "11:30 AM" },
  { change: "YES", val: "1145-am", mtime: "11:45", time: "11:45 AM" },

  { change: "YES", val: "12pm", mtime: "12:00", time: "12:00 PM" },
  { change: "YES", val: "1215-pm", mtime: "12:15", time: "12:15 PM" },
  { change: "YES", val: "1230pm", mtime: "12:30", time: "12:30 PM" },
  { change: "YES", val: "1245-pm", mtime: "12:45", time: "12:45 PM" },

  { change: "YES", val: "1pm", mtime: "01:00", time: "01:00 PM" },
  { change: "YES", val: "115-pm", mtime: "01:15", time: "01:15 PM" },
  { change: "YES", val: "130pm", mtime: "01:30", time: "01:30 PM" },
  { change: "YES", val: "145-pm", mtime: "01:45", time: "01:45 PM" },

  { change: "YES", val: "2pm", mtime: "02:00", time: "02:00 PM" },
  { change: "YES", val: "215-pm", mtime: "02:15", time: "02:15 PM" },
  { change: "YES", val: "230pm", mtime: "02:30", time: "02:30 PM" },
  { change: "YES", val: "245-pm", mtime: "02:45", time: "02:45 PM" },

  { change: "YES", val: "3pm", mtime: "03:00", time: "03:00 PM" },
  { change: "YES", val: "315-pm", mtime: "03:15", time: "03:15 PM" },
  { change: "YES", val: "330pm", mtime: "03:30", time: "03:30 PM" },
  { change: "YES", val: "345-pm", mtime: "03:45", time: "03:45 PM" },

  { change: "YES", val: "4pm", mtime: "04:00", time: "04:00 PM" },
  { change: "YES", val: "415-pm", mtime: "04:15", time: "04:15 PM" },
  { change: "YES", val: "430pm", mtime: "04:30", time: "04:30 PM" },
  { change: "YES", val: "445-pm", mtime: "04:45", time: "04:45 PM" },

  { change: "YES", val: "5pm", mtime: "05:00", time: "05:00 PM" },

  { change: "YES", val: "515-pm", mtime: "05:15", time: "05:15 PM" },
  { change: "YES", val: "530pm", mtime: "05:30", time: "05:30 PM" },
  { change: "YES", val: "545-pm", mtime: "05:45", time: "05:45 PM" },

  { change: "YES", val: "6pm", mtime: "06:00", time: "06:00 PM" },

  { change: "YES", val: "615-pm", mtime: "06:15", time: "06:15 PM" },
  { change: "YES", val: "630pm", mtime: "06:30", time: "06:30 PM" },
  { change: "YES", val: "645-pm", mtime: "06:45", time: "06:45 PM" },

  { change: "YES", val: "7pm", mtime: "07:00", time: "07:00 PM" },
  { change: "YES", val: "715-pm", mtime: "07:15", time: "07:15 PM" },
  { change: "YES", val: "730pm", mtime: "07:30", time: "07:30 PM" },
  { change: "YES", val: "745-pm", mtime: "07:45", time: "07:45 PM" },

  { change: "YES", val: "8pm", mtime: "08:00", time: "08:00 PM" },
  { change: "YES", val: "815-pm", mtime: "08:15", time: "08:15 PM" },
  { change: "YES", val: "830pm", mtime: "08:30", time: "08:30 PM" },
  { change: "YES", val: "845-pm", mtime: "08:45", time: "08:45 PM" },

  { change: "YES", val: "9pm", mtime: "09:00", time: "09:00 PM" },
  { change: "YES", val: "915-pm", mtime: "09:15", time: "09:15 PM" },
  { change: "YES", val: "930pm", mtime: "09:30", time: "09:30 PM" },
  { change: "YES", val: "945-pm", mtime: "09:45", time: "09:45 PM" },

  { change: "YES", val: "10pm", mtime: "10:00", time: "10:00 PM" },
  { change: "YES", val: "1015-pm", mtime: "10:15", time: "10:15 PM" },
  { change: "YES", val: "1030pm", mtime: "10:30", time: "10:30 PM" },
  { change: "YES", val: "1045-pm", mtime: "10:45", time: "10:45 PM" },

  { change: "YES", val: "11pm", mtime: "11:00", time: "11:00 PM" },
  { change: "YES", val: "1115-pm", mtime: "11:15", time: "11:15 PM" },
  { change: "YES", val: "1130pm", mtime: "11:30", time: "11:30 PM" },
  { change: "YES", val: "1145-pm", mtime: "11:45", time: "11:45 PM" },
  { change: "NO",  val: "12am" ,mtime: "12:00", time: "11:59 PM"},

  // { mtime: "12:00", time: "12:00 AM" },
  // { mtime: "12:15", time: "12:15 AM" },
  // { mtime: "12:30", time: "12:30 AM" },
  // { mtime: "12:45", time: "12:45 AM" },
  // { time: "08:00 PM", status: false },
  // { time: "09:00 PM", status: false },
  // { time: "10:00 PM", status: false },
  // { time: "11:00 PM", status: false },
  // { time: "12:00 AM", status: false },
  // { time: "01:00 AM", status: false },
  // { time: "02:00 AM", status: false },
  // { time: "03:00 AM", status: false },
  // { time: "04:00 AM", status: false },
  // { time: "05:00 AM", status: false },
  // { time: "06:00 AM", status: false },
  // { time: "07:00 AM", status: false },
  // { time: "08:00 AM", status: false },
  // { time: "09:00 AM", status: false },
];
const case10Array = ["10PM","1015PM","1030PM","1045PM","10AM","1015AM","1030AM","1045AM"]
export { items, initialValues ,case10Array };
