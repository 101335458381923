import Vimeo from "@u-wave/react-vimeo";
import React from "react";
import { Link } from "react-router-dom";
import { innerWidthHandler } from "../utils/helpers";

export default function Comingsoon() {
  return (
    <div className="coming-soon">
      <p className="font-size-42">&nbsp;</p>
      <Vimeo
      muted={true}
        autoplay={true}
        className="popover-video"
        width={innerWidthHandler()}
        height={430}
        video={"https://vimeo.com/731198558/e458e0fadc"}
        // video={"https://vimeo.com/713853044/9fdeb9dda5"}
      />
      <div className="text-center">
        <Link className="coming-soon-btn" to={"/login"}>
          {" "}
          Back To Login
        </Link>
      </div>
    </div>
  );
}
