import API from "../api/api";


const config = (token) => {
  return {
    headers: {
      "Content-type": "application/json",

      'X-Frame-Options': 'DENY',
      'Content-Security-Policy': "style-src 'self'",
      Authorization: `Bearer ${token}`,
    },
  };
};

export const starterSignupSurvey = async (body) => {
  const response = await API.post("starter-signup-survey", body)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const login = async (body) => {
  const response = await API.post("login", body)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const fetchUser = async (data) => {
  let token = localStorage.getItem("access_token");
  const response = await API.get("user-profile", config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const logout = async () => {
  let token = localStorage.getItem("access_token");
  const response = await API.post("logout", '', config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const forgotPassword = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post("reset-password-request", body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const updatePassword = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post("change-passwords", body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const sendVerificationCode = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post("send-verification-code", body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

//update email
export const updateUserEmail = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "update-user-email",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getLimitunderMaintenance = async (key) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `get-site-setting?key=${key}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const updateZoomVerificationCod = async (key) => {
  let token = localStorage.getItem("access_token");

  const response = await API.post(
    `update-zoom-verification`,
    '',
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getTimeZoneFromCountry = async (country) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `get-timezones-by-country/${country}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};


