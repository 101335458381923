import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import Select from "react-select";
import {
  updateUserPrefrences,
  requestScreenNameChange,
} from "../../../../services/profile";
import { setLoading } from "../../../../reducer/auth/auth";
import { useDispatch } from "react-redux";
import { removeItemOnce } from "../../../../utils/helpers";
import { toast } from "react-toastify";
import Button from "../../../../components/button";

export default function Preferences(props) {
  const dispatch = useDispatch();
  const {
    stepTwoData,
    countryData,
    tools,
    stepOneData,
    getPreferences,
  } = props;
  const [newToolsData, setNewToolsData] = useState([]);
  const [mediumshipLevel, setMediumshipLevel] = useState(stepOneData);
  const [newCountryData, setNewCountryData] = useState([]);
  const [requestChange, setRequestChange] = useState(false);
  const [newScreenName, setNewScreenName] = useState("");
  const [
    requestChangeSuceessMessage,
    setrequestChangeSuceessMessage,
  ] = useState("");

  useEffect(() => {
    setNewToolsData(
      tools.map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.tools_name,
        };
      })
    );
    setNewCountryData(
      countryData.map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.country_name,
        };
      })
    );
  }, ["", countryData, tools]);
  useEffect(() => {
    setMediumshipLevel(stepOneData);
  }, [stepOneData]);
  const getCountryTypes = (type, exclusions, inclusions) => {
    console.log(type, exclusions, newCountryData);
    let country;
    if (type === "All Countries") {
      country = newCountryData.map((item) => {
        return item.id;
      });
    }
    if (type === "All Countries with Exclusions") {
      let itemArray = [];
      // console.log(exclusions,'exclusion aakash')
      // console.log(newCountryData,'country data aakash')

      newCountryData.map((item, index) => {
        itemArray.push(item.id);
      });
      exclusions.map((item, index) => {
        itemArray = removeItemOnce(itemArray, item.id);
      });
      country = itemArray;
    }
    if (type === "Selected Countries only") {
      country = inclusions.map((item) => {
        return item.id;
      });
    }
    console.log(country, "country");
    return country;
  };
  const RunFunction = (formData) => {
    dispatch(setLoading(true));
    // setValues(formData);
    const {
      mediumship_session_tools,
      currentRoles,
      screenName,
      allowRecordings,
      wantToDoSessions,
      wantToDoWork,
      exclusions,
      inclusions,
      interestedSessionType,
      interestedSessionType1,
      PaymentMethods,
      opt_in,
    } = formData;
    let tools = mediumship_session_tools.map((item) => {
      return item.id;
    });
    let countryType = getCountryTypes(wantToDoWork, exclusions, inclusions);

    let item = {
      user_id: 1,
      mediumship_session_tools: tools,
      current_roles: currentRoles,
      screen_name: screenName,
      allow_recordings: allowRecordings,
      // session_preferences: wantToDoSessions,

      client_session_preferences: wantToDoSessions,
      session_message: interestedSessionType1,
      session_type: interestedSessionType,
      countries: countryType,
      payment_methods: PaymentMethods,
      country_type: wantToDoWork,
      opt_in: opt_in === "Yes" ? 1 : 0,
    };
    updateUserPrefrences(item)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          toast.success("Profile Updated", { position: "top-center" });
          getPreferences();
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };
  const changeHandler = (value, setFieldValue) => {
    let tool = value;
    if (value.length > 1 && value[0].tools_name === "None") {
      tool.shift();
    }
    const checkToolname = (obj) => obj.tools_name === "None";
    if (value.some(checkToolname)) {
      value.map((item) => {
        if (item.tools_name === "None") {
          tool = [item];
        }
      });
    }
    setFieldValue("mediumship_session_tools", tool);
  };

  const requestChangeHandler = () => {
    setRequestChange(true);

    setrequestChangeSuceessMessage("");
    if (requestChange) {
      if (newScreenName !== "") {
        let body = {
          type: "screen_name",
          value: newScreenName,
        };
        requestScreenNameChange(body)
          .then((data) => {
            if (data.data.status) {
              setrequestChangeSuceessMessage(
                "You have successfully submited Request for screen name change"
              );
            }
          })
          .catch(() => {});
      } else {
        setrequestChangeSuceessMessage("Screen name is required");
      }
    }
  };
  const handleChangeScreenNameHandler = (e) => {
    setNewScreenName(e.target.value);
  };
  return (
    <Formik
      initialValues={stepTwoData}
      validate={(values) => {
        const errors = {};
        if (!values.mediumship_session_tools.length)
          errors.mediumship_session_tools = "Required";
        if (!values.currentRoles) errors.currentRoles = "Required";
        if (!values.screenName) errors.screenName = "Required";
        if (!values.allowRecordings) errors.allowRecordings = "Required";
        if (!values.interestedSessionType.length)
          errors.interestedSessionType = "Required";
        if (
          values.wantToDoWork === "All Countries with Exclusions" &&
          !values.exclusions.length
        )
          errors.exclusions = "Required";
        if (
          values.wantToDoWork === "Selected Countries only" &&
          !values.inclusions.length
        )
          errors.inclusions = "Required";
        // if (!values.interestedSessionType1)
        //   errors.interestedSessionType1 = "Required";
        // if (!values.PaymentMethods) errors.PaymentMethods = "Required";
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        RunFunction(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => (
        <form
          onSubmit={handleSubmit}
          className="tab-pane"
          role="tabpanel"
          id="step2"
        >
          <div className="row-one-step">
            <div className="full-side">
              <label>
                Special Tools Used in Your Mediumship Sessions<i>*</i>
              </label>
              <Select
                id="mediumship_session_tools"
                className="selection-box"
                options={newToolsData}
                multi={true}
                isMulti
                onChange={(value) => changeHandler(value, setFieldValue)}
                onBlur={handleBlur}
                placeholder="Select Tools"
                value={values.mediumship_session_tools}
                closeMenuOnSelect={false}
                closeMenuOnScroll={false}
              />
              {/* <select
                data-placeholder="Select Specialities"
                className="chosen-select"
                multiple
                tabindex="6"
              >
                <option>Verbal Prayer</option>
                <option>Tarot Cards</option>
                <option>Crystals</option>
                <option>Japanese</option>
                <option>Australia</option>
                <option>England</option>
                <option>south africa</option>
                <option>America</option>
              </select> */}
              {/* <span
                onClick={() => setOpenOtherLnaguages(!openOtherLnaguages)}
                className="other-lang pointer"
              >
                Other Language
              </span> */}
              {/* {openOtherLnaguages && (
                <TagsInput
                  value={values.otherLanguages}
                  className="form-other-tags-input"
                  name="otherLanguages"
                  onChange={(value) => setFieldValue("otherLanguages", value)}
                  placeHolder="Other Language"
                />
              )} */}
              {/* <span className="error">
                {errors.languages && touched.languages && errors.languages}
              </span> */}
            </div>
          </div>
          <div className="row-one-step">
            <div className="full-side">
              <div className="full-fild">
                <label>
                  Current Roles
                   {/* (medium or coach) */}
                  <i>*</i>
                </label>
                <div className="check-field">
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="medium"
                    type="checkbox"
                    value={1}
                    defaultChecked={values.currentRoles.includes(1)}
                    name="currentRoles"
                  />
                  <label htmlFor="my-check">Medium</label>
                </div>
                <div className="check-field">
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="coachtomedium"
                    value={2}
                    type="checkbox"
                    defaultChecked={values.currentRoles.includes(2)}
                    name="currentRoles"
                  />
                  <label htmlFor="my-checktow">Coach to Mediums</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row-one-step">
            <label>
              Screen Name for Client Bookings<i>*</i>
            </label>
            <input
              className={`${
                errors.screenName && touched.screenName && errors.screenName
                  ? "error-boundry"
                  : ""
              }`}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.screenName}
              disabled
              id="screenName"
              name="screenName"
              type="text"
              placeholder="Screen Name"
            />
            <h6>
            In most cases this should be your real name. If you desire more
              privacy, please consider using your real first name and select something as
              human and authentic as possible for a last name identifier. We do
              not want profiles that sound mystical or magical and this is not a place for credentials or brand names.
            </h6>
            <div className="verification-code-section mt-2">
              <div className="d-flex">
                {requestChange && (
                  <input
                    onChange={handleChangeScreenNameHandler}
                    onBlur={handleBlur}
                    className="height-45 margin-right-30"
                    value={newScreenName}
                    type="text"
                    id="newScreenName"
                    name="newScreenName"
                    placeholder="Screen Name"
                  />
                )}
                <Button
                  status={0}
                  type="button"
                  className={`resend-btn w-unset height-45 min-width-130px`}
                  onClick={requestChangeHandler}
                  title={requestChange ? "Submit" : "Request Change"}
                />
              </div>
              <div
                className={`text-left ${
                  requestChangeSuceessMessage === "Screen name is required"
                    ? "red"
                    : "green"
                }`}
              >
                {requestChangeSuceessMessage}
              </div>
            </div>
          </div>
          <div className="row-one-step">
            <div className="full-fild">
              <label>Allow Recordings(Y/N)</label>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={1}
                  type="radio"
                  defaultChecked={
                    parseInt(values.allowRecordings) === 1 ? true : false
                  }
                  name="allowRecordings"
                  id="allowRecordingsyes"
                />
                <label htmlFor="yes">Yes</label>
              </div>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={0}
                  type="radio"
                  name="allowRecordings"
                  id="allowRecordingsno"
                  defaultChecked={
                    parseInt(values.allowRecordings) === 0 ? true : false
                  }
                />
                <label htmlFor="no">No</label>
              </div>
            </div>
          </div>
          <div className="row-one-step">
            <div className="full-fild">
              <label>
                I Currently Want To Do Sessions With (choose all that apply)
              </label>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="Other Mediums"
                  id="othermediums"
                  defaultChecked={
                    values.wantToDoSessions.includes("Other Mediums")
                      ? true
                      : false
                  }
                  type="checkbox"
                  name="wantToDoSessions"
                />
                <label htmlFor="my-check">Other Mediums</label>
              </div>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="Starter Clients"
                  defaultChecked={
                    values.wantToDoSessions.includes("Starter Clients")
                      ? true
                      : false
                  }
                  id="starterclient"
                  name="wantToDoSessions"
                  type="checkbox"
                />
                <label
                  htmlFor="my-checktow"
                  className="strclint tooltip-1-focus"
                >
                  Starter Clients
                </label>
                <span className="tooltip-1">
                  Starter clients are generally friends and family of other
                  mediums who understand that you are newer to this. They are
                  here to support you in your development
                </span>
              </div>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="Outreach Clients"
                  id="outreachclients"
                  type="checkbox"
                  defaultChecked={
                    values.wantToDoSessions.includes("Outreach Clients")
                      ? true
                      : false
                  }
                  name="wantToDoSessions"
                />
                <label
                  htmlFor="my-checktow"
                  className="strclint tooltip-2-focus"
                >
                  Outreach Clients
                </label>
                <span className="tooltip-2">
                  Outreach clients are people from the general public who cannot
                  afford to purchase a session with a professional level medium.
                </span>
              </div>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="Paying Clients"
                  id="payingclient"
                  type="checkbox"
                  defaultChecked={
                    values.wantToDoSessions.includes("Paying Clients")
                      ? true
                      : false
                  }
                  name="wantToDoSessions"
                />
                <label htmlFor="my-checktow">Paying Clients</label>
              </div>
            </div>
          </div>
          <div className="row-one-step">
            <div className="full-fild">
              <label>
                I Currently Want To Work With Clients In These Locations
              </label>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="All Countries"
                  id="allcountries"
                  defaultChecked={
                    values.wantToDoWork === "All Countries" ? true : false
                  }
                  name="wantToDoWork"
                  type="radio"
                />
                <label htmlFor="my-check">All Countries</label>
              </div>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="All Countries with Exclusions"
                  id="exclusioncountries"
                  name="wantToDoWork"
                  defaultChecked={
                    values.wantToDoWork === "All Countries with Exclusions"
                      ? true
                      : false
                  }
                  type="radio"
                />
                <label htmlFor="my-checktow">
                  All Countries with Exclusions
                </label>
              </div>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="Selected Countries only"
                  defaultChecked={
                    values.wantToDoWork === "Selected Countries only"
                      ? true
                      : false
                  }
                  id="selectedcountries"
                  name="wantToDoWork"
                  type="radio"
                />
                <label htmlFor="my-checktow">Selected Countries only</label>
              </div>
            </div>
          </div>

          {values.wantToDoWork !== "All Countries" &&
            values.wantToDoWork !== "" && (
              <div className="row-one-step">
                <div className="full-fild">
                  <label>
                    {values.wantToDoWork === "All Countries with Exclusions"
                      ? "Select exclusions"
                      : "Select Countries"}
                  </label>
                  <Select
                    id="country"
                    className="selection-box"
                    options={newCountryData}
                    multi={true}
                    isMulti
                    onChange={
                      values.wantToDoWork === "All Countries with Exclusions"
                        ? (value) => setFieldValue("exclusions", value)
                        : (value) => setFieldValue("inclusions", value)
                    }
                    onBlur={handleBlur}
                    placeholder={
                      values.wantToDoWork === "All Countries with Exclusions"
                        ? "Select exclusion"
                        : "Select Country"
                    }
                    value={
                      values.wantToDoWork === "All Countries with Exclusions"
                        ? values.exclusions
                        : values.inclusions
                    }
                    closeMenuOnSelect={false}
                    closeMenuOnScroll={false}
                  />
                  <span className="error">
                    {errors.inclusions &&
                      touched.inclusions &&
                      errors.inclusions}
                    {errors.exclusions &&
                      touched.exclusions &&
                      errors.exclusions}
                  </span>
                </div>
              </div>
            )}
          {/* <div className="row-one-step">
            <div className="full-fild">
              <label>Select exclusions</label>
              <select
                data-placeholder="Select Country"
                className="chosen-select"
                multiple
                tabindex="6"
              >
                <option>United States</option>
                <option>Canada</option>
                <option>Russia</option>
                <option>Japanese</option>
                <option>Australia</option>
                <option>England</option>
                <option>south africa</option>
                <option>America</option>
              </select>
            </div>
          </div> */}
          <div className="row-one-step">
            <div className="full-fild">
              <label>
                As Medium, which types of sessions are you interested in
                performing now? *
              </label>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={1}
                  id="oneononemedium"
                  type="checkbox"
                  defaultChecked={
                    values.interestedSessionType.includes("1") ? true : false
                  }
                  name="interestedSessionType"
                />
                <label htmlFor="my-check">1-on-1 mediumship</label>
              </div>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={2}
                  id="groupmedium"
                  type="checkbox"
                  defaultChecked={
                    values.interestedSessionType.includes("2") ? true : false
                  }
                  name="interestedSessionType"
                />
                <label htmlFor="my-checktow">
                  Group mediumship demonstrations
                </label>
              </div>
            </div>
          </div>
          {parseInt(mediumshipLevel.mediumshipLevel) === 3 && (
            <div className="row-one-step as-medium">
              <div className="full-fild">
                <label>
                  Would you prefer to start out as a public or private persona
                  on the website? <i>*</i>
                </label>
                <div className="full">
                  <div className="check-field">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value="Would want name, photo, and full profile published"
                      id="profilepublised"
                      type="radio"
                      defaultChecked={
                        values.interestedSessionType1 ===
                        "Would want name, photo, and full profile published"
                          ? true
                          : false
                      }
                      name="interestedSessionType1"
                    />
                    <label htmlFor="my-check">
                      Would want name, photo, and full profile published
                    </label>
                  </div>
                </div>
                <div className="full">
                  <div className="check-field">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value="Would want limited information published"
                      id="informationpublised"
                      type="radio"
                      defaultChecked={
                        values.interestedSessionType1 ===
                        "Would want limited information published"
                          ? true
                          : false
                      }
                      name="interestedSessionType1"
                    />
                    <label htmlFor="my-checktow">
                      Would want limited information published
                    </label>
                  </div>
                </div>
                <div className="full">
                  <div className="check-field">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value="Would want to start behind the scenes with nothing published"
                      id="nothingpublised"
                      defaultChecked={
                        values.interestedSessionType1 ===
                        "Would want to start behind the scenes with nothing published"
                          ? true
                          : false
                      }
                      type="radio"
                      name="interestedSessionType1"
                    />
                    <label htmlFor="my-checktow">
                      Would want to start behind the scenes with nothing
                      published
                    </label>
                  </div>
                </div>
                <div className="full">
                  <div className="check-field">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value="Other"
                      id="otherpublished"
                      type="radio"
                      defaultChecked={
                        values.interestedSessionType1 === "Other" ? true : false
                      }
                      name="interestedSessionType1"
                    />
                    <label htmlFor="my-checktow">Other</label>
                  </div>
                </div>
              </div>
            </div>
          )}
          {mediumshipLevel.mediumshipLevel === "3" && (
            <div className="row-one-step payment-saction">
              <div className="full-fild">
                <label>
                  If you plan to take payments on VerySoul bookings, what
                  payment methods do you prefer?
                </label>
                <div className="bookingpay">
                  <div className="check-field">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      defaultChecked={
                        values.PaymentMethods.includes(1) ? true : false
                      }
                      id="nopayment"
                      type="checkbox"
                      value={1}
                      name="PaymentMethods"
                    />
                    <label htmlFor="my-check">
                      I do not intend to take payments at this time
                    </label>
                  </div>
                  <div className="check-field">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      defaultChecked={
                        values.PaymentMethods.includes(2) ? true : false
                      }
                      id="PayPal"
                      type="checkbox"
                      value={2}
                      name="PaymentMethods"
                    />
                    <label htmlFor="my-checktow">PayPal</label>
                  </div>
                  <div className="check-field">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="Stripe"
                      type="checkbox"
                      defaultChecked={
                        values.PaymentMethods.includes(3) ? true : false
                      }
                      value={3}
                      name="PaymentMethods"
                    />
                    <label htmlFor="my-checktow">Stripe</label>
                  </div>
                  <div className="check-field">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="Venmo"
                      type="checkbox"
                      value={4}
                      defaultChecked={
                        values.PaymentMethods.includes(4) ? true : false
                      }
                      name="PaymentMethods"
                    />
                    <label htmlFor="my-checktow">Venmo</label>
                  </div>
                  <div className="check-field">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="Cashapp"
                      type="checkbox"
                      defaultChecked={
                        values.PaymentMethods.includes(5) ? true : false
                      }
                      value={5}
                      name="PaymentMethods"
                    />
                    <label htmlFor="my-checktow">Cashapp</label>
                  </div>
                  <div className="check-field">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="Zelle"
                      type="checkbox"
                      defaultChecked={
                        values.PaymentMethods.includes(6) ? true : false
                      }
                      value={6}
                      name="PaymentMethods"
                    />
                    <label htmlFor="my-checktow">Zelle</label>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row-one-step">
            <div className="full-fild">
              <label>
                Opt-In? <i>*</i>
              </label>
              <h4>
                I would like to receive notices of offers (for free events,
                opportunities, etc.)
              </h4>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="Yes"
                  id="optin"
                  type="radio"
                  name="opt_in"
                  checked={values.opt_in === "Yes" ? true : false}
                />
                <label htmlFor="optin">Yes</label>
              </div>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="No"
                  id="optout"
                  type="radio"
                  name="opt_in"
                  checked={values.opt_in === "No" ? true : false}
                />
                <label htmlFor="optout">No</label>
              </div>
            </div>
            <span className="error marg-top-55">
              {errors.opt_in && touched.opt_in && errors.opt_in}
            </span>
          </div>
          <div className="update mt-20 ">
            <button type="submit" className="update">
              Update
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
}
