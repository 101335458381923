import React from "react";
// import Button from "../components/button";
import CountdownTimerReact from "../components/counter";
export default function CountdownTimer({
  counterStatus,
  hideCountdownTimer,
  startMeetingImmediate,
  meetingUrl,
  userName
}) {
  return (
    <div
      className="modal fade"
      id="Countdown"
      role="dialog"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-preview">
        {/* <button type="button" className="close" data-dismiss="modal">
        &times;
      </button> */}
        <div className="modal-content">
          <div className="modal-body">
            <div className="profile-video">
              <img src="images/img-video.png" alt="img-video.png" />
            </div>
            <h3>
              {/* <strong>{userDetails}</strong> */}
              Your Session will start with {userName} in
              <br />
              {counterStatus && (
                <CountdownTimerReact hideCountdownTimer={hideCountdownTimer} />
              )}
              
              {/* <Countdown date={Date.now() + 200000} /> */}
              <div className="bution-close-accept margin-unset">
                <a
                  onClick={startMeetingImmediate}
                  href={meetingUrl}
                  target="_blank"
                  className="join-btn mb-3"
                  rel="noopener noreferrer"
                >
                  Open Zoom Room
                </a>
              </div>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}
