export const list = [
  {
    name: "Profile",
    imgDark: "/images/step-user-black.png",
    imgLight: "/images/step-user-gold.png",
  },
  {
    name: "Preferences",
    imgDark: "/images/sating.png",
    imgLight: "/images/sating-gold.png",
  },
  {
    name: "My Technology",
    imgDark: "/images/nanotechnology.png",
    imgLight: "/images/nanotechnology-gold.png",
  },
  {
    name: "Legal and Privacy",
    imgDark: "/images/privacy.png",
    imgLight: "/images/privacy-gold.png",
  },
];
