import React, { useEffect, useState } from 'react'
import TransactionTable from '../../../components/transactiontable';
import Pagination from "../../../components/tablePagination";
// import Pagination from '../Subcomponents/Pagination';
import { getTransactionDetails } from '../../../services/medium';
import { useDispatch, useSelector } from 'react-redux';
import { markPaymendAsCompleted,filterAllTransactions } from '../../../services/admin';
import { toast } from 'react-toastify';
// import Pagination from "react-js-pagination";
// import SweetPagination from "sweetpagination";
import Search from "../../../components/search";
import { filterList } from "./data";

import { setLoading } from '../../../reducer/auth/auth';

const listHeading = [
    'Appointment Id',
    'Medium Name',
    'Client Name',
    'Date',
    'Session Date Time',
    'Payment Method',
    'Payment Status',
    'Session Name',
    'Amount',
    'Currency',
    'Action',
]
export default function Transaction() {
    const dispatch = useDispatch()
    // const [currentPage, setCurrentPage] = useState(1);
    // const [postsPerPage] = useState(10);
    const [transactionsList, setTransactionsList] = useState([]);
    const [searchValue, setSearchValues] = useState()
    const [filterValues, setFilterValues] = useState('screen_name')
//       // Get current posts
//   const indexOfLastPost = currentPage * postsPerPage;
//   const indexOfFirstPost = indexOfLastPost - postsPerPage;
//   const currentPosts = transactionsList.slice(indexOfFirstPost, indexOfLastPost);

//   // Change page
//   const paginate = pageNumber => setCurrentPage(pageNumber);
const [totalcount, setTotalCount] = useState(0);
const [transactionPage, setTransactionPage] = useState(1);
const [transactionrowsPerPage, setTransactionrowsPerPage] = useState(10);


// const [currentPage, setCurrentPage] = useState(1);
// const [recordsPerPage] = useState(10);

// const indexOfLastRecord = currentPage * recordsPerPage;
// const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
// const currentRecords = transactionsList.slice(indexOfFirstRecord, indexOfLastRecord);
// const nPages = Math.ceil(transactionsList.length / recordsPerPage)

// const [activePage,setActivePage]=useState(1);
// const [currentPageData, setCurrentPageData] = useState(new Array(2).fill());

    useEffect(() => {

      if (!transactionsList.length)getTransactions()
    }, [])

    const getTransactions = (dat) => {
      console.log('dataaaa')
        dispatch(setLoading(true));
       
        let body ={...dat};
        getTransactionDetails(body).then((data) => {
          console.log('abc')
            dispatch(setLoading(false));
            console.log(data.data.status, "data")
            if (data.data.status) {
              setTransactionsList(data.data.data.data)
                console.log('xz',data.data.data.data)
                setTotalCount(data.data.data.total);
              

            }
        }).catch(() => { })

    }

    // const handlePageChange=(pageNumber)=> {
    //     console.log(`active page is ${pageNumber}`);
    //     setActivePage(pageNumber);
    //   }
     

    const markComplete = (item) => {
        markPaymendAsCompleted(item.id).then((data) => {

            if (data.data.status) {
                getTransactions()
                toast.success('Mark as completed successfully', { position: 'top-center' });


            }

        }).catch(() => { })
    }

    function groupBy(objectArray, property) {
        return objectArray.reduce(function (accumulator, currentObject) {
          let key = currentObject[property];
          if (!accumulator[key]) {
            accumulator[key] = [];
          }
          accumulator[key].push(currentObject);
          return accumulator;
        }, {});
      }

    const filterTransactions = (constants) => {

        const { name = "", session_id = "", screen_name = "",payment_method="",payment_status="",session_name="" } = constants;
        let ids = session_id.slice(2);
        let lastchar=ids.substring(ids.length-1);
        let newId = lastchar==="x"?ids.substring(0,ids.length-1):ids.substring(0,ids.length);
        
        let body = {
          name, newId, screen_name,payment_method,payment_status,session_name
        };
        filterAllTransactions(body)
          .then((data) => {
            if (data.data.status) {
            //   let grouped = groupBy(data.data.data, "appointment");
              // let array = [];
            //   let obj = Object.values(grouped).map((val) => {
            //     return Object.assign({}, val);
            //   });
            setTransactionPage(transactionPage + 1);
            setTransactionrowsPerPage(transactionrowsPerPage);
            setTotalCount(data.data.data.total);
            setTransactionsList(data.data.data.data);
            //   dispatch(setTransactionsList(data.data.data));
              // dispatch(setAllOutreachClientsCount(data.data.data.total));
    
            }
          })
          .catch(() => { });
        // }
      };

      const getPaginationData = (data, activeTab) => {
        let dat = {
          ...data,
          screen_name:
            searchValue !== undefined
              ? searchValue.screen_name !== undefined
                ? searchValue.screen_name
                : ""
              : "",
          session_name:
            searchValue !== undefined
              ? searchValue.session_name !== undefined
                ? searchValue.session_name
                : ""
              : "",
          name:
            searchValue !== undefined
              ? searchValue.name !== undefined
                ? searchValue.name
                : ""
              : "",
          payment_method:
            searchValue !== undefined
              ? searchValue.payment_method !== undefined
                ? searchValue.payment_method
                : ""
              : "",
          payment_status:
            searchValue !== undefined
              ? searchValue.payment_status !== undefined
                ? searchValue.payment_status
                : ""
              : "",
          newId:
            searchValue !== undefined
              ? searchValue.session_id !== undefined
                ? searchValue.session_id
                : ""
              : "",
        };
        console.log('data123',dat);
        getTransactions(dat, activeTab);
      };

    return (
        <>
    <div className="d-flex justify-content-end gap-10 flex-wrap">
        <Search
          callApi={filterTransactions}
          list={filterList}
          setSearchValues={setSearchValues}
          setFilterValues={setFilterValues}
          searchValue={searchValue}
          filterValues={filterValues}
        />
      </div>
        <TransactionTable listHeading={listHeading} transactionsList={transactionsList} type={'admin'} markComplete={markComplete} />
        <Pagination
          transactionPage={transactionPage}
          transactionrowsPerPage={transactionrowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          activeTab={"admintransaction"}
          count={totalcount}
          getPaginationData={getPaginationData}
        />
       
        {/* <Pagination
         currentPage={currentPage}
        postsPerPage={postsPerPage}
        totalPosts={transactionsList.length}
        paginate={paginate}
      /> */}
        {/* {transactionsList.length>0 ?<Pagination
                nPages={nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />:''} */}
             {/* <Pagination
          activePage={activePage}
          itemsCountPerPage={10}
          totalItemsCount={transactionsList.length}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
        /> */}
       {/* { transactionsList.length>0 ? <SweetPagination
        currentPageData={setCurrentPageData}
        dataPerPage={10}
        getData={transactionsList}
        navigation={true}
      />:''} */}
      </>
   
        )
}
