import React from "react";
import "./help.css";
export default function Help() {
  return (
    <div className="help-container">
      <a href="https://support.verysoul.com/" target="_blank" className="span-tag">
        {/* <span className="span-tag "> */}

          <img alt="help" src="/images/hed-2.png" />
          <span> Get Help</span>
        {/* </span> */}
      </a>
    </div>
  );
}
