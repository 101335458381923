import React from 'react'

export default function ImagePopup({ url }) {
    return (
        <div className="popup-accordion">
            <div class="modal fade" id="image-Popup" role="dialog">
                <div class="modal-dialog modal-dialog-preview">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">
                                &times;
                            </button>
                        </div>
                        <div><img className='w-100' src={`${process.env.REACT_APP_IMAGE_URL + url}`} alt="profile" /> </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
