import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik } from "formik";

//services
import { login } from "../../services/auth";
import { fetchUser } from "../../services/auth";

//reducer
import { setUser } from "../../reducer/userSlice";
import { setLoading } from "../../reducer/auth/auth";
import { setUpAuth, setRole } from "../../reducer/auth/auth";

//utils
import { userType } from "../../utils/utils";

import "./login.css";
import Slider from "../../components/slider";
import { getBrowserData } from "../../utils/helpers";
const Login = () => {
  //query params
  const [searchParams] = useSearchParams();
  const verifiedStatus = searchParams.get("message");
  //hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //states
  // Email verification link sent on your email, Please Check
  const [verified, setVerified] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [typePass, SetTypePass] = useState(true);

  useEffect(() => {
    window.loadCarousel();
    if (verifiedStatus) setVerified(verifiedStatus);
  }, []);

  const changePassType = (e) => {
    SetTypePass(!typePass);
  };

  const LoginUser = async (formData) => {
    localStorage.removeItem('guest_id');
    dispatch(setLoading(true));
    let item = {
      email: formData.email,
      password: formData.password,
    };
    let browserinfo = await getBrowserData();
    Object.assign(item, browserinfo);
    login(item)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          localStorage.setItem("access_token", data.data.data.access_token);
          dispatch(setUpAuth());
          callProfile(data.data.data.access_token);
        }
        if (
          !data.data.status &&
          data.data.error &&
          data.data.error !== "Unauthorized"
        ) {
          setEmailError(data.data.error);
        }
        if (!data.data.status && data.data.error === "Unauthorized") {
          setEmailError("Invalid Email or Password");
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  const callProfile = async (token) => {
    dispatch(setLoading(true));
    await fetchUser(token)
      .then((data) => {
        dispatch(setLoading(false));
        dispatch(setUser(data.data.data));
        // localStorage.setItem("user", JSON.stringify(data.data.data));
        // localStorage.setItem("medium_status", data.data.data.medium_status);
        // localStorage.setItem("status", data.data.data.status);
        renderUserType(data.data.data.usertype, data.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  const renderUserType = (type, users) => {

    const user = userType(type);
    // localStorage.setItem("role", user);
    dispatch(setRole(user));
    if (user === "medium") {
      if (users.status === 1 && users.medium_status === 1) {
        navigate("/dashboard");
      } else {
        navigate("/profile");
      }
    }
    if (user === "admin") {
      navigate("/dashboard");
    }
    if (user === "client") {
      if (users.is_first_appointment) {
        navigate("/appointments");
      } else {
        navigate("/dashboard");
      }
    }
  };
  const redirectRoute = (item) => {
    navigate(item);
  };
  return (
    <div className="footer-login-screen-head">
      <div className="login-registerheader">
        <div className="container">
          <div className="logo-top">
            {" "}
            <img src="images/logo-verysoul.png" alt="logo" />
          </div>
          <div className="right-login-register">
            <p className="ml-2">
              Don’t have an account?
              <button
                data-toggle="modal"
                data-target="#myModal"
                className="reg"
              >
                Register
              </button>
            </p>
          </div>
        </div>
      </div>

      <div className="login-register-saction">
        <div className="container black-container">
          <div className="row">
            <div className="col-md-6">
              <Slider />
            </div>

            <div className="col-md-6 align-items-center d-flex flex-column">
              <div className="login-saction-right mt-4">
                <div className="logo">
                  <img src="images/logo-verysoul.png" alt="logo" />
                </div>

                <h2>Login in to your account</h2>

                <span className="error font-size-17 font-600 ">
                  {emailError}
                </span>
                <span className="verified font-size-17 font-600 ">
                  {verified}
                </span>

                <div className="form-l---r">
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                    validate={(values) => {
                      const errors = {};
                      if (!values.email) {
                        errors.email = "Required";
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = "Invalid email address";
                      }
                      if (!values.password) errors.password = "Required";
                      if (values.password && values.password.length < 6)
                        errors.password = "Minimum length 6 character";

                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      LoginUser(values);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="from-fild">
                          <div className="icon">
                            <img src="images/mail.png" alt="mail" />
                          </div>
                          <label>
                            Email Address<i>*</i>
                          </label>
                          <input
                            className={`${errors.email && touched.email && errors.email
                              ? "error-boundry"
                              : ""
                              }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            type="email"
                            id="email"
                            name="email"
                            placeholder="myemail@address.com"
                          />
                          <span className="error">
                            {errors.email && touched.email && errors.email}
                          </span>
                        </div>
                        <div className="from-fild">
                          <div className="icon">
                            <img src="images/pasword.png" alt="password" />
                          </div>
                          <label>
                            Password<i>*</i>
                          </label>
                          <input
                            className={`${errors.password &&
                              touched.password &&
                              errors.password
                              ? "error-boundry"
                              : ""
                              }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            id="password"
                            type={typePass ? "password" : "text"}
                            name="password"
                            placeholder="Enter Password"
                          />
                          <div className="icon-right">
                            <img
                              className={`pointer ${typePass ? "" : "openeye"}`}
                              onClick={changePassType}
                              src={
                                typePass
                                  ? "images/eye.png"
                                  : "localImages/openeye.svg"
                              }
                              alt="eye"
                            />
                          </div>
                          <span className="error">
                            {errors.password &&
                              touched.password &&
                              errors.password}
                          </span>
                        </div>
                        <div className="forget-fild">
                          <p><span> Forgot your password? </span>  <Link className="d-inline" to="/forgot-password"> Change it here</Link></p>
                        </div>
                        <div className="bution">
                          <button type="submit" className="button-bx">
                            Login
                          </button>
                          <br />
                          <div className="mt-3">

                            <p><span> Not yet registered? </span>  <a className="d-inline d5a67b" href="#" onClick={() => window.loadModal2('#myModal', 'show')} >Sign up here</a></p>
                          </div>
                        </div>
                        <div className="forget-fild d-flex justify-content-between text-center mt-4">
                          <Link target="_blank" to={"/privacy-policy"}>
                            Privacy Policy
                          </Link>
                          <Link target="_blank" to={"/terms-conditions"}>
                            Terms & Conditions
                          </Link>
                        </div>
                        <div className="dont-have-acc">
                          <div
                            className="modal fade"
                            id="myModal"
                            role="dialog"
                          >
                            <div className="modal-dialog modal-dialog-preview">
                              <div className="modal-content">
                                <div className="modal-body text-align-unset">
                                  <div className="icon-pop text-align-center">
                                    <img
                                      src="images/Union-icon.png"
                                      alt="union-icon"
                                    />
                                  </div>
                                  <ul>
                                    <li>
                                      <div
                                        onClick={() =>
                                          redirectRoute("/registration")
                                        }
                                        className="height-unset"
                                        data-dismiss="modal"
                                      >
                                        <img
                                          src="images/medum.png"
                                          alt="medium"
                                        />
                                        <span data-dismiss="modal">
                                          Register as <br />
                                          Medium
                                        </span>
                                      </div>
                                    </li>
                                    <li>
                                      <div
                                        className="height-unset"
                                        onClick={() =>
                                          redirectRoute("/register-client")
                                        }
                                        data-dismiss="modal"
                                      >
                                        <img
                                          src="images/customer.png"
                                          alt="register"
                                        />
                                        <span>
                                          Register as <br />
                                          Client
                                        </span>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
              <p className="text- footer-login-screen bookin mt-4"><b>Note: For optimum performance: use Chrome, Safari, or Edge browser</b></p>
            </div>
          </div>
        </div>
      </div>
      {/*****modal */}
      <br />
      <br />
      {/* <p className="text-center"><b>Note: For optimum performance: use Chrome, Safari, or Edge browser</b></p> */}
      {/* <p className="text- footer-login-screen bookin mt-4"><b>Note: For optimum performance: use Chrome, Safari, or Edge browser</b></p> */}
    </div>
  );
};
export default Login;
