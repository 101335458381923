import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { slotsArray } from "./data";

import {
  getMatchedAvailabilites,
  getSlotsByDate,
} from "../../../../../services/client";
//reducer
import {
  setBookingTime,
  setmediumListItems,
  setbookingBtnStatus,
  setBookingDate,
  setBookingIds,
  setMediumsToBeMatch,
} from "../../../../../reducer/clientsSlice";
import { setLoading } from "../../../../../reducer/auth/auth";
import { removFirstDash, removeString } from "../../../../../utils/helpers";
import { getTimeImage } from "../../../../../utils/utils";
import { useParams, useSearchParams } from "react-router-dom";

export default function Slots({
  mediumItems,
  user_type,
  client_id="",
  setSlotsUpdated,
  slotsupdated,
  sessionType,
  activesession_id = "5",
  practitionerInfo,
  medium_id,
  activeSubSessionId = "",
  is_group_session,
  setnoSlotsFound,
  bookingType,
}) {
  //hooks
  const { id, timezone } = useSelector((state) => state.user.user);
  const auth = useSelector((state) => state.auth);

  const [params] = useSearchParams();
  const dispatch = useDispatch();
  const preferences = useSelector((state) => state.client);
  // const { countries, languages, specialities } = preferences.preferences;
  //states
  const [slots, setSlots] = useState([]);
  const [active, setActive] = useState(false);
  useEffect(() => {
    setSlotsUpdated(false);
  }, []);
  //functions

  const changeStatus = (e, slot) => {
    if (user_type === undefined || user_type == "") {
      let { value } = e.target;

      dispatch(setBookingTime(slot.time));
      // dispatch(setBookingDate(slot.date));
      dispatch(setBookingIds(slot.medium_id));
      setSlots(
        slots.map((slot) => {
          if (slot.datetime === value) {
            return {
              ...slot,
              ...(slot.selectedStatus = true),
              ...(slot.status = true),
            };
          } else {
            return {
              ...slot,
              ...(slot.selectedStatus = false),
              ...(slot.status = false),
            };
          }
        })
      );

      setTimeout(() => {
        setSlots(
          slots.map((slot) => {
            return {
              ...slot,
              ...(slot.status = false),
            };
          })
        );
      }, 3000);
    }
  };

  const getAvailabilites = (value) => {
    dispatch(setLoading(true));
    let removeAm = value.replace("AM", "");
    let removePm = removeAm.replace("PM", "");
    // let { countries, languages, specialities } = JSON.parse(
    //   localStorage.getItem("item")
    // );

    // let lang = languages.map((item) => {
    //   return item.id;
    // });

    let body = {
      // languages: lang,
      // specialities: specialities,
      // countries: countries,
      date: preferences.bookingdate,
      time:
        value !== ""
          ? moment(`${preferences.bookingdate} ${value}`, "YYYY-MM-DD h:mm A")
            .utc()
            .format("YYYY-MM-DD hh:mm A")
          : "",
    };

    getMatchedAvailabilites(body)
      .then((data) => {
        // console.log(data, "hefdbhwdbeh");
        dispatch(setLoading(false));
        if (data.data.status) {
          dispatch(setmediumListItems(data.data.data));
          dispatch(setbookingBtnStatus(true));
          //   handleReset();
        }
        if (!data.data.status) {
          dispatch(setmediumListItems(data.data.data));
          //   handleReset();
        }
        if (!data.data.status && data.data.message === "No Time Slots found") {
          dispatch(setmediumListItems(data.data.data));
          window.loadModal("#noslotsfound", "open");
          // handleReset();
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };
  useEffect(() => {
    let data = "";
    let active = "";
    let getmed = "";
    if (auth.role === "medium") {
      getmed = id;
    } else if (auth.role === "admin") {
      getmed = params.get("id");
    }
    let medium_id =
      practitionerInfo !== null && practitionerInfo !== undefined
        ? practitionerInfo.general.medium_id
        : getmed;
    if (practitionerInfo !== null && practitionerInfo !== undefined) {
      if (practitionerInfo.general.payStatus !== undefined) {
        if (practitionerInfo.general.payStatus === "before") {
          data = "8";
        }
        else {
          data = "7";
        }
      }
      else {
        if (
          (practitionerInfo.general.payType.includes(1) &&
            practitionerInfo.general.payType.includes(2)) ||
          (practitionerInfo.general.payType.length === 1 &&
            practitionerInfo.general.payType.includes(1)) ||
          practitionerInfo.general.payType.length === 0
        ) {
          data = "8";
        } else if (
          practitionerInfo.general.payType.length === 1 &&
          practitionerInfo.general.payType.includes(2)
        ) {
          data = "7";
        }
      }
    }
    active =
      user_type !== "medium"
        ? sessionType === "prof"
          ? data == ""
            ? activesession_id
            : data
          : activesession_id
        : activesession_id;

    // getAvailabilites("")
    if (preferences.bookingdate !== "") {

      getSlotsByDate(
        removFirstDash(preferences.bookingdate),
        user_type,
        active,
        medium_id,
        activeSubSessionId === null ? "" : activeSubSessionId,
        is_group_session,
        client_id
      )
        .then((data) => {
          dispatch(setLoading(false));
          setnoSlotsFound(false);
          if (data.data.status) {
            if (user_type === undefined) {
              window.scrollTo({
                left: 0,
                top: document.body.scrollHeight,
                behavior: "smooth",
              });
            } else {
              document
                .getElementById("prvvvvv")
                .scrollTo({
                  left: 0,
                  top: document.body.scrollHeight,
                  behavior: "smooth",
                });
            }
            if (is_group_session == 1) {


              dispatch(setmediumListItems(data.data.data));
              if (data.data.data.length > 0) {
                setActive(true);
                setSlotsUpdated(true);
              }
              else {
                setActive(false);
                setSlotsUpdated(false);
              }

              return;
            }
            if (Object.keys(data.data.data.slots).length > 0) {
              setActive(true);
            }
            setSlotsUpdated(true);
            dispatch(setmediumListItems(data.data.data.slots));
            dispatch(setMediumsToBeMatch(data.data.data.mediums));
            dispatch(setbookingBtnStatus(true));
            //   handleReset();
          }
          if (
            !data.data.status ||
            (data.data.status && !Object.keys(data.data.data.slots).length)
          ) {
            setActive(false);
            setSlots(JSON.parse(JSON.stringify(slotsArray)));
            // if (bookingType === "professional") {
            setnoSlotsFound(true)
            setSlotsUpdated(true);
            // }
            // else {

            //   window.loadModal("#noslotsfound", "open");
            // }
            // dispatch(setmediumListItems(data.data.data));
            //   handleReset();
          }
          if (
            !data.data.status &&
            data.data.message === "No Time Slots found"
          ) {
            // dispatch(setmediumListItems(data.data.data));

            // if (bookingType === "professional") {
            setnoSlotsFound(true)
            setSlotsUpdated(true);
            // }
            // else {

            //   window.loadModal("#noslotsfound", "open");
            // }
            // handleReset();
          }
        })
        .catch((err) => {
          dispatch(setLoading(false));
          console.log(err);
        });
    }
  }, [preferences.bookingdate]);
  const cutString = (value) => {
    return {
      date: value.split("T")[0],
      time: value.split("T")[1],
      datetime: value,
      selectedStatus: false,
      status: false,
    };
  };
  useEffect(() => {
    let infef = slots;

    if (Object.keys(mediumItems).length > 0) {
      // Object.keys(info).length > 0 &&
      // Object.entries(info).map(([key, value]) => (

      infef = Object.entries(mediumItems).map(([key, value]) => {
        return {
          ...cutString(key),
          time: value,
        };
      });
    }
    setSlots(infef);
  }, [mediumItems]);
  useEffect(() => {
    setSlots([]);
    setSlotsUpdated(false);
  }, [timezone]);
  return (
    <>
      {slotsupdated && (
        <>
          {active &&
            React.Children.toArray(
              slots.map((slot) => (
                is_group_session == 1 ?
                  <div className={`check-field-coaching check-field ${slot.selectedStatus ? "dbb38f-background" : ''}`}>
                    {console.log(slot)}
                    <input
                      className={`${slot.selectedStatus ? "dbb38f-border" : ""}`}
                      // onChange={(e) => changeStatus(e, slot)}
                      value={slot.datetime.time_slot_id}
                      // checked={slot.status}
                      type="radio"
                      name="check"
                    />
                    <div className="text-center font-size-17 mt-2">
                      <b> {slot.time.duration}</b>

                    </div>
                    <label
                      className={`height-unset border-unset ${slot.selectedStatus ? "dbb38f-background" : ""
                        }`}
                      htmlFor="my-checktow"
                    >
                      {/* {moment(slot.date).format("LL")}
          <br /> */}
                      {/* <>
                    {slot.datetime.time + " "}


                  </> */}
                      <>
                        {slot.time.from + " "}
                        {getTimeImage(slot.time.from) ? (
                          <img
                            src="/localImages/moon.png"
                            className="star-imh-moon"
                            alt="time-image"
                          />
                        )

                          : (
                            ""
                          )}

                      </>
                      &nbsp;
                      to &nbsp; <>
                        {slot.time.to + " "}
                        {getTimeImage(slot.time.to) ? (
                          <img
                            src="/localImages/moon.png"
                            className="star-imh-moon"
                            alt="time-image"
                          />
                        )

                          : (
                            ""
                          )}

                      </>

                    </label>
                    <br />
                    <div className="text-center p-1">
                      <b> {slot.time.label}</b>

                    </div>
                  </div>
                  :
                  <div className={`check-field`}>
                    <input
                      className={`${slot.selectedStatus ? "dbb38f-border" : ""}`}
                      onChange={(e) => changeStatus(e, slot)}
                      value={slot.datetime}
                      checked={slot.status}
                      type="radio"
                      name="check"
                    />
                    <label
                      className={`${slot.selectedStatus ? "dbb38f-background" : ""
                        }`}
                      htmlFor="my-checktow"
                    >
                      {/* {moment(slot.date).format("LL")}
                <br /> */}
                      {slot.time + " "}
                      {getTimeImage(slot.time) ? (
                        <img
                          src="localImages/moon.png"
                          className="star-imh-moon"
                          alt="time-image"
                        />
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
              ))
            )}
        </>
      )}
    </>
  );
}
