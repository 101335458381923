import React, { useEffect, useRef, useState } from "react";
// import Practitoner from "./practitoner";
// import Footer from "../../footer";
// import Search from "../../../../../components/search";
import "./practitoner/practitoner.css";
import "../practitonerInfo/practitonerinfo.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Prepaypostpaycheck from "./subcomponents/prepaypostpaycheck";
import {
  setPaymentMethodAvailbale,
  setPaymentTypeAvailbale,
} from "../../../../../reducer/clientsSlice";
import { useOutsideAlerter } from "../../../../../utils/helpers";
import { ScrollController } from "@fullcalendar/core/internal";
import { getMediumServiceDetails } from "../../../../../services/client";
export default function Practitioners({
  specialtyData = [],
  languages = [],
  practitioners = [],
  onClick,
  back,
  getPractitionarFiltersList,
  setBookInfo,
  startBookaSessionLaterVideo,
  startBookaSessionLaterQuestions,
  getSubsessions,
  setRenderType
}) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.user);
  const wrapperRef = useRef(null);

  const [languagesList, setlanguagesList] = useState([]);
  const [specialtyList, setspecialtyList] = useState([]);
  const [maxNoOfParticipantList, setMaxNoOfParticipantList] = useState([
    {
      id: 1,
      name: "1",
      status: false,
      label: "1",
      type: "maxNoOfParticipant",
    },
    {
      id: 2,
      name: "2",
      status: false,
      label: "2",
      type: "maxNoOfParticipant",
    },
  ]);
  const [currencyList, setCurrencyList] = useState([
    {
      id: 1,
      name: "AUD",
      status: false,
      label: "AUD",
      type: "currency",
    },
    {
      id: 2,
      name: "CAD",
      status: false,
      label: "CAD",
      type: "currency",
    },
    {
      id: 3,
      name: "USD",
      status: false,
      label: "USD",
      type: "currency",
    },
  ]);
  const [sessionLengthList, setsessionLengthList] = useState([
    {
      id: 30,
      name: "30Minutes",
      status: false,
      label: "30 Minutes",
      type: "sessionlength",
    },
    {
      id: 45,
      name: "45Minutes",
      status: false,
      label: "45 Minutes",
      type: "sessionlength",
    },
    {
      id: 60,
      name: "60Minutes",
      status: false,
      label: "60 Minutes",
      type: "sessionlength",
    },
    {
      id: 452,
      name: "452Minutes",
      status: false,
      label: "45 Minutes for 2 People",
      type: "sessionlength",
    },
    {
      id: 602,
      name: "602Minutes",
      status: false,
      label: "60 Minutes for 2 People",
      type: "sessionlength",
    },
  ]);
  const [experienceList, setExperienceList] = useState([
    {
      id: 1,
      name: "1year",
      status: false,
      label: "1 year",
      type: "experience",
    },
    {
      id: 2,
      name: "2years",
      status: false,
      label: "2-3 years",
      type: "experience",
    },
    {
      id: 3,
      name: "3years",
      status: false,
      label: "3-5 years",
      type: "experience",
    },
    {
      id: 4,
      name: "4years",
      status: false,
      label: "5-10 years",
      type: "experience",
    },
    {
      id: 5,
      name: "5years",
      status: false,
      label: "10-15 years",
      type: "experience",
    },
    {
      id: 6,
      name: "6years",
      status: false,
      label: "15+ years",
      type: "experience",
    },
  ]);
  const [whereDoIPayList, setwhereDoIPayList] = useState([
    {
      id: 1,
      name: "PaytoBook",
      status: false,
      label: "Pay to Book",
      type: "whereDoI",
    },
    {
      id: 2,
      name: "PayAfterSession",
      status: false,
      label: "Pay After Session",
      type: "whereDoI",
    },
  ]);
  const [recording, setRecording] = useState("");
  const [currencyName, setCurrencyName] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [paytypeText, setPaytypeText] = useState("");

  const [filterData, setFilterData] = useState({
    selectedName: "",
    languages: [],
    specialities: [],
    allow_REcording: "",
    whereDoIPay: [],
    sessionLength: [],
    experience: [],
    session_currency: "",
  });
  const [displayData, setDisplayData] = useState({
    selectedName: "",
    languages: [],
    specialities: [],
    allow_REcording: "",
    whereDoIPay: [],
    sessionLength: [],
    experience: [],
    session_currency: "",
  });
  const [offset, setOffset] = useState(0);

  const [professionalInfo, setProfessionalInfo] = useState();
  const [filterTab, setFilterTab] = useState(true);

  const [mediumSessionInfo, setMediumSessionInfo] = useState();
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    let off = localStorage.getItem("offset");
    if (off !== undefined) {
      window.scrollTo({ top: off, left: 0, behavior: "smooth" });
    }

    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  const updateLanguage = (value, checked) => {
    let list = languagesList.map((item) => {
      if (parseInt(item.id) === parseInt(value)) {
        return {
          ...item,
          ...(item.status = checked),
        };
      }
      return {
        ...item,
      };
    });
    setlanguagesList(list);
  };

  const updateSpeciality = (value, checked) => {
    let list = specialtyList.map((item) => {
      if (parseInt(item.id) === parseInt(value)) {
        return {
          ...item,
          ...(item.status = checked),
        };
      }
      return {
        ...item,
      };
    });
    setspecialtyList(list);
  };
  const updateSessionLength = (value, checked) => {
    let list = sessionLengthList.map((item) => {
      if (parseInt(item.id) === parseInt(value)) {
        return {
          ...item,
          ...(item.status = checked),
        };
      }
      return {
        ...item,
      };
    });
    setsessionLengthList(list);
  };
  const updateExperience = (value, checked) => {
    let list = experienceList.map((item) => {
      if (parseInt(item.id) === parseInt(value)) {
        return {
          ...item,
          ...(item.status = checked),
        };
      }
      return {
        ...item,
      };
    });
    setExperienceList(list);
  };
  const updatewhereDoIPay = (value, checked) => {
    let list = whereDoIPayList.map((item) => {
      if (parseInt(item.id) === parseInt(value)) {
        return {
          ...item,
          ...(item.status = checked),
        };
      }
      return {
        ...item,
      };
    });
    setwhereDoIPayList(list);
  };
  const updateCurrency = (value, checked) => {
    let list = currencyList.map((item) => {
      if (parseInt(item.id) === parseInt(value)) {
        return {
          ...item,
          ...(item.status = checked),
        };
      }
      return {
        ...item,
      };
    });
    setCurrencyList(list);
  };
  const updateMaxNoOfParticipant = (value, checked) => {
    let list = maxNoOfParticipantList.map((item) => {
      if (parseInt(item.id) === parseInt(value)) {
        return {
          ...item,
          ...(item.status = checked),
        };
      }
      return {
        ...item,
      };
    });
    setMaxNoOfParticipantList(list);
  };
  const deleteElement = async (item, type) => {
    let info = filterData;
    if (type === "language") {
      removeValue(info.languages, String(item.id));
      updateLanguage(item.id, false);
    }
    if (type === "sessionlength") {
      removeValue(info.sessionLength, item.id);
      updateSessionLength(item.id, false);
    }
    if (type === "experience") {
      removeValue(info.experience, String(item.id));
      updateExperience(item.id, false);
    }
    if (type === "wheredoipay") {
      removeValue(info.whereDoIPay, String(item.id));
      updatewhereDoIPay(item.id, false);
    } else {
      removeValue(info.specialities, String(item.id));
      updateSpeciality(item.id, false);
    }
    if (type === "currency") {
      removeValue(info.sessionLength, item.id);
      updateMaxNoOfParticipant(item.id, false);
    }
    setFilterData(info);
    getPractitionarFiltersList(info);
  };

  const deleteInfo = (type) => {
    let info = filterData;
    if (type === "rec") {
      info.allow_REcording = "";
    } else if (type === "curren") {
      info.session_currency = "";
    } else {
      info.whereDoIPay = "";
    }
    setFilterData(info);
    getPractitionarFiltersList(info);
  };
  const changeHandler = (e, type) => {
    let checked = e.target.checked;
    let value = e.target.value;
    let name = e.target.name;
    clearTimeout(timer);

    let info = filterData;
    let display = displayData;

    if (type === "name") {
      if (value) {
        setSelectedName(value);
        info.selectedName = value;
      } else {
        info.selectedName = "";
        setSelectedName("");
      }
    }
    if (type === "maxNoOfParticipant") {
      updateMaxNoOfParticipant(value, checked);
      sessionLengthList.map((item) => {
        updateSessionLength(item.id, false);
      });
      if (checked) {
        let lens = maxNoOfParticipantList.every((item) => item.status);
        if (maxNoOfParticipantList.every((item) => item.status)) {
          info.sessionLength = [];
          info.sessionLength.push(30, 45, 60, 452, 602);
        } else if (value == 1) {
          info.sessionLength = [];
          info.sessionLength.push(30, 45, 60);
        } else {
          info.sessionLength = [];
          info.sessionLength.push(452, 602);
        }
        display.sessionLength.push(name);
      } else {
        if (value == 1) {
          removeValue(info.sessionLength, 30);
          removeValue(info.sessionLength, 45);
          removeValue(info.sessionLength, 60);
        } else {
          removeValue(info.sessionLength, 452);
          removeValue(info.sessionLength, 602);
        }
        removeValue(info.sessionLength, value);
        // removeValue(display.sessionLength, name);
      }
    }
    if (type === "language") {
      updateLanguage(value, checked);
      if (checked) {
        info.languages.push(value);
        display.languages.push(name);
      } else {
        removeValue(info.languages, value);
        removeValue(display.languages, name);
      }
    }
    if (type === "specialty") {
      updateSpeciality(value, checked);
      if (checked) {
        info.specialities.push(value);
        display.specialities.push(name);
      } else {
        removeValue(info.specialities, value);
        removeValue(display.specialities, name);
      }
    }
    if (type === "Recording") {
      if (checked) {
        setRecording(name);
        info.allow_REcording = value;
      } else {
        info.allow_REcording = "";
        setRecording("");
      }
      // if (checked) {
      //   info.allow_REcording.push(value);
      //   display.allow_REcording.push(name);
      // } else {
      //   removeValue(info.allow_REcording, value);
      //   removeValue(display.allow_REcording, name);
      // }
    }
    if (type === "currency") {
      if (checked) {
        setCurrencyName(name);
        info.session_currency = name;
      } else {
        info.session_currency = "";
        setCurrencyName("");
      }
    }
    // if (type === "wheredoipay") {
    //   if (checked && name === "when_do_i_pay_1") {
    //     info.whereDoIPay = 1;
    //     setPayTime("Pay to Book");
    //   } else if (checked && name === "when_do_i_pay_2") {
    //     setPayTime("Pay After Session");
    //     info.whereDoIPay = 2;
    //   } else {
    //     setPayTime("");
    //     info.whereDoIPay = "";
    //   }
    //   // if (checked) {
    //   //   info.whereDoIPay.push(value);
    //   //   display.whereDoIPay.push(name);
    //   // } else {
    //   //   removeValue(info.whereDoIPay, value);
    //   //   removeValue(display.whereDoIPay, name);
    //   // }
    // }
    if (type === "wheredoipay") {
      updatewhereDoIPay(value, checked);
      if (checked) {
        info.whereDoIPay.push(value);
        display.whereDoIPay.push(name);
      } else {
        removeValue(info.whereDoIPay, value);
        removeValue(display.whereDoIPay, name);
      }
    }

    if (type === "sessionlength") {
      updateSessionLength(value, checked);
      maxNoOfParticipantList.map((item) => {
        updateMaxNoOfParticipant(item.id, false);
      });

      if (checked) {
        info.sessionLength = [];
        let itesm = [];
        sessionLengthList.map((item) => {
          if (item.status) {
            itesm.push(item.id);
          }
        });
        itesm.map((val) => {
          info.sessionLength.push(val);
        });
        // display.sessionLength.push(name);
      } else {
        removeValue(info.sessionLength, parseInt(value));
        removeValue(display.sessionLength, name);
      }
    }
    if (type === "experience") {
      updateExperience(value, checked);
      if (checked) {
        info.experience.push(value);
        display.experience.push(name);
      } else {
        removeValue(info.experience, value);
        removeValue(display.experience, name);
      }
    }
    const newTimer = setTimeout(() => {

      getPractitionarFiltersList(info);
    }, 500);
    setTimer(newTimer);
    setDisplayData(display);
    setFilterData(info);
  };





  useEffect(() => {
    let states = checkWindowSize() > 767 ? true : false;

    setFilterTab(states);
  }, []);
  useEffect(() => {
    // window.loadModal2('#Prepaypostpaycheck', 'show');

    let items = languages.map((item) => {
      return {
        ...item,
        status: false,
        type: "languages",
      };
    });
    setlanguagesList(items);
  }, ["", languages]);
  useEffect(() => {
    let items = specialtyData.map((item) => {
      return {
        ...item,
        status: false,
        type: "specialty",
      };
    });
    setspecialtyList(items);
  }, ["", specialtyData]);
  const removeValue = (array, value) => {
    const index = array.indexOf(value);
    if (index > -1) {
      array.splice(index, 1);
    }
    return array;
  };
  const getBookingLink = (info) => {
    localStorage.setItem("offset", offset);
    setProfessionalInfo(info);
    if (info.show_flexbooker) {
      startSeesion(info);
    } else {
      //live start
      // if (checkPostPreType(info)) {
      //live end
      // startSeesion(info);
      getMediumServiceDetails(info.medium_id)
        .then((data) => {
          if (data.data.status) {
            setMediumSessionInfo(data.data.data[0]);

            window.loadModal2("#Prepaypostpaycheck", "show");
          }
        })
        .catch(() => { });
      // }
    }
  };

  const startSeesion = (info) => {
    let inf = {
      general: {
        personal_booking_link: info.personal_booking_link,
        payType: info.payType,
        pre_pay_session_type: info.pre_pay_session_type,
        post_pay_session_type: info.post_pay_session_type,
        medium_id: info.medium_id,
        ...info,
      },
    };
    setBookInfo(inf);
    {
      setRenderType("newappointment");
      // auth.user.terms_session_status === 1
      //   ? startBookaSessionLaterQuestions()
      //   : startBookaSessionLaterVideo();
    }
  };
  const checkPostPreType = (info) => {
    let status = false;
    const {
      payType,
      post_pay_schedule,
      pre_pay_schedule,
      is_paypal_integrated,
      is_stripe_integrated,
    } = info;

    if (!is_paypal_integrated && !is_stripe_integrated) {
      toast.error("No Availability for this Medium", {
        position: "top-center",
      });
      return;
    } else if (is_paypal_integrated && is_stripe_integrated) {
      dispatch(setPaymentMethodAvailbale("both"));
    } else if (is_paypal_integrated && !is_stripe_integrated) {
      dispatch(setPaymentMethodAvailbale("paypal"));
    } else if (!is_paypal_integrated && is_stripe_integrated) {
      dispatch(setPaymentMethodAvailbale("stripe"));
    }

    if (payType.length === 2) {
      if (!post_pay_schedule && !pre_pay_schedule) {
        // setPaytypeText('both post pay pre pay avialable for this medium')
        toast.error("No Availability for this Medium", {
          position: "top-center",
        });
        return;
      }
      if (post_pay_schedule && pre_pay_schedule) {
        // window.loadModal2("#Prepaypostpaycheck", "show");
        // setPaytypeText('both post pay pre pay avialable for this medium')
        // toast.success('both post pay pre pay avialable for this medium', { position: 'top-center' })
        // status = true
      } else if (post_pay_schedule && !pre_pay_schedule) {
        // window.loadModal2("#Prepaypostpaycheck", "show");
        // setPaytypeText('Only post pay avialable for this medium')
        dispatch(setPaymentTypeAvailbale("Pay After Session"));
        // status = true;
        // toast.error('Only post pay avialable for this medium', { position: 'top-center' })
      } else if (!post_pay_schedule && pre_pay_schedule) {
        // window.loadModal2("#Prepaypostpaycheck", "show");
        dispatch(setPaymentTypeAvailbale("Pay Before Session"));

        // status = true;
        // setPaytypeText('Only pre pay avialable for this medium')
        // toast.error('Only pre pay avialable for this medium', { position: 'top-center' })
      }

      // else if (post_pay_schedule || pre_pay_schedule) {
      //   status = true
      // }
      else {
        // setPaytypeText('No schedule available for this medium')
        toast.error("No schedule available for this medium", {
          position: "top-center",
        });
      }
    } else {
      if (payType[0] === 1 && pre_pay_schedule) {
        dispatch(setPaymentTypeAvailbale("Pay Before Session"));
        // getSubsessions(8);
        // setPaytypeText('only pre pay added and available')
        // toast.success('only pre pay added and available', { position: 'top-center' })
        status = true;
      }
      // else if (payType[0] === 1 && !pre_pay_schedule) {
      //   setPaytypeText('No pre pay schedule available for this medium')
      //   toast.error('No pre pay schedule available for this medium', { position: 'top-center' })
      // }
      else if (payType[0] === 2 && post_pay_schedule) {
        dispatch(setPaymentTypeAvailbale("Pay After Session"));
        // getSubsessions(7);
        // setPaytypeText('only post pay added and available')
        // toast.success('only post pay added and available', { position: 'top-center' })
        status = true;
      }
      // else if (payType[0] === 2 && !post_pay_schedule) {
      //   setPaytypeText('No post pay schedule available for this medium')
      //   toast.error('No post pay schedule available for this medium', { position: 'top-center' })
      // }
      else {
        // setPaytypeText('No schedule available for this medium')
        toast.error("No Availability for this Medium", {
          position: "top-center",
        });
      }
    }
    return status;
  };
  // const filterListMediums = (constants) => {
  //   const { email, screen_name, created_at, orderBy = "asc" } = constants;
  //   let body = {
  //     email,
  //     screen_name,
  //     created_at,
  //     orderBy,
  //   };

  //     filterAllmediums(body)
  //       .then((data) => {
  //         if (data.data.status) {
  //           dispatch(setAllMediums(data.data.data.data));
  //           setbackupMediums(JSON.parse(JSON.stringify(data.data.data.data)));
  //         }
  //       })
  //       .catch(() => {});

  // };

  const checkWindowSize = () => {
    return window.innerWidth;
  };
  const clickViewProfile = (item) => {
    localStorage.setItem("offset", offset);
    onClick(item.id);
  };
  const backbtnHandler = () => {
    localStorage.removeItem("offset");
    back();
  };
  const scrollControllerHandler = () => {
    window.scrollTo({ top: offset + 200, left: 0, behavior: "smooth" });
  };
  const checkSessionExtraPersion = (item) => {
    const obj = JSON.parse(item.session_length);
    
    const sessionKeys = Object.keys(obj);
    const isOffered = sessionKeys.includes("452") || sessionKeys.includes("602");
    if(isOffered) {
      return "";
    }else{
      return <em>Not Offered</em>;
    }
  }
  const checkSessionFoutyFiveExtraPersion = (item) => {
    const session_currency_obj = JSON.parse(item.session_currency);
    const obj = JSON.parse(item.session_length);
    const sessionCurrencyKeys = Object.keys(session_currency_obj);
    const sessionKeys = Object.keys(obj);
    const isOffered = sessionKeys.includes("452");
    const sessionCurrency = sessionCurrencyKeys.includes("452");
    let currency = "USD";
    if(sessionCurrency){
       currency = session_currency_obj[452];
    }
    let diffreance = 0
    if(isOffered) {
      let priceoFoutyFiveForTwo = obj[452];
      let isOfferedForTwo = sessionKeys.includes("45");
      if(isOfferedForTwo){
        let priceoFoutyFive = obj[45];
        diffreance = priceoFoutyFiveForTwo - priceoFoutyFive;
      }else{
        diffreance = 0;
      }
      if (diffreance < 0) {
        return "- $ " + Math.abs(diffreance);
    } else {
        return "+ $ " + diffreance +" "+ currency;
    }
    }else{
      return <em>Not Offered</em>;
    }
  }
  const checkSessionSixtyExtraPersion = (item) => {
    const session_currency_obj = JSON.parse(item.session_currency);
    const obj = JSON.parse(item.session_length);
    const sessionCurrencyKeys = Object.keys(session_currency_obj);
    const sessionKeys = Object.keys(obj);
    const isOffered = sessionKeys.includes("602");
    const sessionCurrency = sessionCurrencyKeys.includes("602");
    let currency = "USD";
    if(sessionCurrency){
       currency = session_currency_obj[602];
    }
    let diffreance = 0
    if(isOffered) {
      let priceoFoutyFiveForTwo = obj[602];
      let isOfferedForTwo = sessionKeys.includes("60");
      if(isOfferedForTwo){
        let priceoFoutyFive = obj[60];
        diffreance = priceoFoutyFiveForTwo - priceoFoutyFive;
      }else{
        diffreance =  0;
      }
      if (diffreance < 0) {
        return "- $ " + Math.abs(diffreance);
    } else {
        return "+ $ " + diffreance +" "+ currency;
    }
    }else{
      return <em>Not Offered</em>;
    }
  }
  
  const checkSessionOffered = (item) => {

    if (item.session_length !== "" && item.length !== undefined) {
      if (
        ((JSON.parse(item.session_length))[4] === undefined ||
          Object.values(JSON.parse(item.session_length))[4] === "") &&
        ((JSON.parse(item.session_length))[3] === "" ||
          Object.values(JSON.parse(item.session_length))[3] === undefined)
      ) {
        return <em>Not Offered</em>;
      }
      // (Object.values(
      //   JSON.parse(item.session_length)
      // )[4] === "" ||
      //   Object.values(
      //     JSON.parse(item.session_length)
      //   )[4] === undefined) &&
      // (Object.values(
      //   JSON.parse(item.session_length)
      // )[3] === "" || Object.values(
      //   JSON.parse(item.session_length)
      // )[3] === undefined) && (
      //   <em>Not Offered</em>
      // )
    } else {
      return <em>Not Offered</em>;
    }
  };
  const checkItemFortyFive = (item) => {
    if (item.session_length !== "" && item.session_length !== undefined) {
      if (
        Object.values(JSON.parse(item.session_length))[3] === "" ||
        Object.values(JSON.parse(item.session_length))[3] === undefined
      ) {
        return true;
      }
    } else {
      return true;
    }
  };
  const isfourtyFive = (item) => {
    if (item.session_length !== "" && item.session_length !== undefined) {
      const obj = JSON.parse(item.session_length);
      const sessionKeys = Object.keys(obj);
      const isOffered = sessionKeys.includes("452");
      if(!isOffered) {
        return true;
      }
    } else {
      return true;
    }
  }
  const isSixtyForTwo = (item) => {
    if (item.session_length !== "" && item.session_length !== undefined) {
      const obj = JSON.parse(item.session_length);
      const sessionKeys = Object.keys(obj);
      const isOffered = sessionKeys.includes("602");
      if(!isOffered) {
        return true;
      }
    } else {
      return true;
    }
  }
  const fourtyFive = (item) => {
    if (item.session_length !== "" && item.session_length !== undefined) {
      if (
        Object.values(JSON.parse(item.session_length))[3] === "" ||
        Object.values(JSON.parse(item.session_length))[3] === undefined
      ) {
        return true;
      }
    } else {
      return true;
    }
  };
  const checkItemSixty = (item) => {
    if (item.session_length !== "" && item.session_length !== undefined) {
      if (
        Object.values(JSON.parse(item.session_length))[4] === "" ||
        Object.values(JSON.parse(item.session_length))[4] === undefined
      ) {
        return true;
      }
    } else {
      return true;
    }
  };
  checkWindowSize() < 767 && useOutsideAlerter(wrapperRef, setFilterTab);

  const add3Dots = (string, limit) => {
    var dots = "...";
    if (string.length > limit) {
      // you can also use substr instead of substring
      string = string.substring(0, limit) + dots;
    }

    return string;
  }
  return (
    <>
      <div
        className={`schedule-advance-box-all  ${checkWindowSize() < 767 ? "" : "schedule-advance-box-all-container"
          }`}
      >
        <div className="appointment-select-tab-btn-wrapper p-2">
          <div class="back-btn-profile mb-2">
            <div onClick={backbtnHandler} className="bac-href pointer">
              <img className="bac-img" src="images/arrow-back.png" alt="back" />
              Go Back
            </div>
          </div>
          <div
            onClick={() => {
              setFilterTab(!filterTab);
            }}
            className="text-center filter-image"
          >
            <img className="cross-icon-image" src="localImages/filter.png" />
            Filter
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-5 filter-box-professional">
            <div
              ref={wrapperRef}
              className={`schedule-advance-box-left filter-box-professional-inner d-block ${filterTab ? "" : "d-none"
                }`}
            >
              {checkWindowSize() < 767 && (
                <div className="cross-icon-image">
                  <img
                    onClick={() => {
                      setFilterTab(false);
                    }}
                    src="localImages/crossicon.png"
                  />
                </div>
              )}
              <h3>Filter</h3>
              <div class="accordion" id="accordionExample">
                <div class="item">
                  <div className="search-box search-container">
                    <input
                      value={selectedName}
                      onChange={(e) => changeHandler(e, "name")}
                      className="search-input"
                      type="text"
                      placeholder="Search By Name"
                    />
                  </div>
                </div>
                <div class="item">
                  <div class="item-header" id="headingOne">
                    <h2 class="mb-0">
                      <button
                        class="btn btn-link"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <img
                          className="langu-img"
                          src="images/Language.png"
                          alt="#"
                        />{" "}
                        Language
                        <img src="images/dropdown.png" alt="caret-down"></img>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseOne"
                    class="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div class="t-p">
                      <div className="slect-language-list">
                        {languagesList.map((item) => (
                          <div class="form-group">
                            <input
                              onChange={(e) => changeHandler(e, "language")}
                              checked={item.status}
                              type="checkbox"
                              value={item.id}
                              name={item.name}
                              id={item.name}
                            />
                            <label htmlFor={item.name}>{item.name}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {/******************************************************************************************* */}

                <div class="item">
                  <div class="item-header" id="headingSeven">
                    <h2 class="mb-0">
                      <button
                        class="btn btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseSeven"
                        aria-expanded="true"
                        aria-controls="collapseSeven"
                      >
                        <img
                          className="langu-img filter-images-n"
                          src="localImages/dollara.png"
                          alt="#"
                        />{" "}
                        Currency
                        <img src="images/dropdown.png" alt="caret-down"></img>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseSeven"
                    class="collapse"
                    aria-labelledby="headingSeven"
                    data-parent="#accordionExample"
                  >
                    <div class="t-p">
                      <div className="slect-language-list">
                        {/* <div class="form-group">
                            <input
                              checked={recording === "Yes" ? true : false}
                              type="checkbox"
                              id={"Yes"}
                              value={1}
                              name={"Yes"}
                              onChange={(e) => changeHandler(e, "Recording")}
                            />
                            <label htmlFor="Yes">Yes</label>
                          </div>
                          <div class="form-group">
                            <input
                              checked={recording === "No" ? true : false}
                              type="checkbox"
                              id="No"
                              name="No"
                              value={0}
                              onChange={(e) => changeHandler(e, "Recording")}
                            />
                            <label htmlFor="No">No</label>
                          </div> */}
                        {currencyList.map((item) => (
                          <div class="form-group">
                            <input
                              onChange={(e) => changeHandler(e, "currency")}
                              checked={item.name === currencyName}
                              type="checkbox"
                              value={item.id}
                              name={item.name}
                              id={item.name}
                            />
                            <label htmlFor={item.name}>{item.name}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {/****************************************************************************************** */}

                <div class="item">
                  <div class="item-header" id="headingFour">
                    <h2 class="mb-0">
                      <button
                        class="btn btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        <img
                          className="langu-img"
                          src="images/fluent_payment-48-filled.png"
                          alt="#"
                        />
                        Schedule of Payment
                        <img src="images/dropdown.png" alt="caret-down"></img>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseFour"
                    class="collapse"
                    aria-labelledby="headingFour"
                    data-parent="#accordionExample"
                  >
                    <div class="t-p">
                      <div className="slect-language-list">
                        {whereDoIPayList.map((item) => (
                          <div class="form-group">
                            <input
                              onChange={(e) => changeHandler(e, "wheredoipay")}
                              checked={item.status}
                              type="checkbox"
                              value={item.id}
                              name={item.name}
                              id={item.name}
                            />
                            <label htmlFor={item.name}>{item.label}</label>
                          </div>
                        ))}
                        {/* <div class="form-group">
                          <input
                            checked={paytime === "Pay to Book" ? true : false}
                            type="checkbox"
                            name="when_do_i_pay_1"
                            id="when_do_i_pay_1"
                            value={1}
                            onChange={(e) => changeHandler(e, "wheredoipay")}
                          />
                          <label htmlFor="when_do_i_pay_1">Pay to Book</label>
                        </div>
                        <div class="form-group">
                          <input
                            type="checkbox"
                            checked={
                              paytime === "Pay After Session" ? true : false
                            }
                            name="when_do_i_pay_2"
                            id="when_do_i_pay_2"
                            value={2}
                            onChange={(e) => changeHandler(e, "wheredoipay")}
                          />
                          <label htmlFor="when_do_i_pay_2">
                            Pay After Session
                          </label>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item">
                  {/* <div class="item-header" id="headingTwo">
                    <h2 class="mb-0">
                      <button
                        class="btn btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Specialties
                        <img src="images/dropdown.png" alt="caret-down"></img>
                      </button>
                    </h2>
                  </div> */}
                  {/* <div
                    id="collapseTwo"
                    class="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div class="t-p">
                      <div className="slect-language-list">
                        {specialtyList.map((item) => <div class="form-group">
                          <input type="checkbox" onChange={(e) => changeHandler(e, 'specialty')} checked={item.status} id={item.specialty_name} value={item.id} name={item.specialty_name} />
                          <label htmlFor={item.specialty_name}>{item.specialty_name}</label>
                        </div>)}

                      </div>
                    </div>
                  </div> */}
                </div>
                <div class="item">
                  <div class="item-header" id="headingFive">
                    <h2 class="mb-0">
                      <button
                        class="btn btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        <img
                          className="langu-img"
                          src="images/clock11.png"
                          alt="#"
                        />{" "}
                        Session Length:
                        <img src="images/dropdown.png" alt="caret-down"></img>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseFive"
                    class="collapse"
                    aria-labelledby="headingFive"
                    data-parent="#accordionExample"
                  >
                    <div class="t-p">
                      <div className="slect-language-list">
                        {sessionLengthList.map((item) => (
                          <div class="form-group">
                            <input
                              onChange={(e) =>
                                changeHandler(e, "sessionlength")
                              }
                              checked={item.status}
                              type="checkbox"
                              value={item.id}
                              name={item.name}
                              id={item.name}
                            />
                            <label htmlFor={item.name}>{item.label}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="item">
                  <div class="item-header" id="headingSix">
                    <h2 class="mb-0">
                      <button
                        class="btn btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        <img
                          className="langu-img filter-images-n"
                          src="localImages/calendara.png"
                          alt="#"
                        />{" "}
                        Years As Professional:
                        <img src="images/dropdown.png" alt="caret-down"></img>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseSix"
                    class="collapse"
                    aria-labelledby="headingSix"
                    data-parent="#accordionExample"
                  >
                    <div class="t-p">
                      <div className="slect-language-list">
                        {experienceList.map((item) => (
                          <div class="form-group">
                            <input
                              onChange={(e) => changeHandler(e, "experience")}
                              checked={item.status}
                              type="checkbox"
                              value={item.id}
                              name={item.name}
                              id={item.name}
                            />
                            <label htmlFor={item.name}>{item.label}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {/*************************************************************************************************** */}
                <div class="item">
                  <div class="item-header" id="headingEight">
                    <h2 class="mb-0">
                      <button
                        class="btn btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                        <img
                          className="langu-img filter-images-n"
                          src="localImages/usera.png"
                          alt="#"
                        />{" "}
                        Max # of Participants
                        <img src="images/dropdown.png" alt="caret-down"></img>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseEight"
                    class="collapse"
                    aria-labelledby="headingEight"
                    data-parent="#accordionExample"
                  >
                    <div class="t-p">
                      <div className="slect-language-list">
                        {maxNoOfParticipantList.map((item) => (
                          <div class="form-group">
                            <input
                              onChange={(e) =>
                                changeHandler(e, "maxNoOfParticipant")
                              }
                              checked={item.status}
                              type="checkbox"
                              value={item.id}
                              name={item.name}
                              id={item.name}
                            />
                            <label htmlFor={item.name}>{item.label}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {/*************************************************************************************************** */}
                <div class="item">
                  <div class="item-header" id="headingThree">
                    <h2 class="mb-0">
                      <button
                        class="btn btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        <img
                          className="langu-img"
                          src="images/ph_video-camera-fill.png"
                          alt="#"
                        />
                        Recording availability
                        <img src="images/dropdown.png" alt="caret-down"></img>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseThree"
                    class="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample"
                  >
                    <div class="t-p">
                      <div className="slect-language-list">
                        <div class="form-group">
                          <input
                            checked={recording === "Yes" ? true : false}
                            type="checkbox"
                            id={"Yes"}
                            value={1}
                            name={"Yes"}
                            onChange={(e) => changeHandler(e, "Recording")}
                          />
                          <label htmlFor="Yes">Yes</label>
                        </div>
                        <div class="form-group">
                          <input
                            checked={recording === "No" ? true : false}
                            type="checkbox"
                            id="No"
                            name="No"
                            value={0}
                            onChange={(e) => changeHandler(e, "Recording")}
                          />
                          <label htmlFor="No">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-tabs-container">
                {/* {specialtyList.map((item) => item.status ? <span className="filter-tabs">{item.specialty_name}<span onClick={() => deleteElementdeleteElement(item, 'specialty')} className="pointer filter-tabs-cross">X</span></span> : '')} */}
                {languagesList.map((item) =>
                  item.status ? (
                    <span className="filter-tabs">
                      {item.name}
                      <span
                        onClick={() => deleteElement(item, "language")}
                        className="pointer filter-tabs-cross"
                      >
                        X
                      </span>
                    </span>
                  ) : (
                    ""
                  )
                )}
                {sessionLengthList.map((item) =>
                  item.status ? (
                    <span className="filter-tabs">
                      {item.label}
                      <span
                        onClick={() => deleteElement(item, "sessionlength")}
                        className="pointer filter-tabs-cross"
                      >
                        X
                      </span>
                    </span>
                  ) : (
                    ""
                  )
                )}
                {maxNoOfParticipantList.map((item) =>
                  item.status ? (
                    <span className="filter-tabs">
                      Max participant {item.label}
                      <span
                        onClick={() => deleteElement(item, "currency")}
                        className="pointer filter-tabs-cross"
                      >
                        X
                      </span>
                    </span>
                  ) : (
                    ""
                  )
                )}
                {experienceList.map((item) =>
                  item.status ? (
                    <span className="filter-tabs">
                      {item.label}
                      <span
                        onClick={() => deleteElement(item, "experience")}
                        className="pointer filter-tabs-cross"
                      >
                        X
                      </span>
                    </span>
                  ) : (
                    ""
                  )
                )}
                {whereDoIPayList.map((item) =>
                  item.status ? (
                    <span className="filter-tabs">
                      {item.label}
                      <span
                        onClick={() => deleteElement(item, "wheredoipay")}
                        className="pointer filter-tabs-cross"
                      >
                        X
                      </span>
                    </span>
                  ) : (
                    ""
                  )
                )}
                {recording !== "" ? (
                  <span className="filter-tabs">
                    {recording}
                    <span
                      onClick={() => {
                        deleteInfo("rec");
                        setRecording("");
                      }}
                      className="pointer filter-tabs-cross"
                    >
                      X
                    </span>
                  </span>
                ) : (
                  ""
                )}
                {currencyName !== "" ? (
                  <span className="filter-tabs">
                    {currencyName}
                    <span
                      onClick={() => {
                        deleteInfo("curren");
                        setCurrencyName("");
                      }}
                      className="pointer filter-tabs-cross"
                    >
                      X
                    </span>
                  </span>
                ) : (
                  ""
                )}
                {/* {paytime !== "" ? (
                  <span className="filter-tabs">
                    {paytime}
                    <span
                      onClick={() => {
                        deleteInfo();
                        setPayTime("");
                      }}
                      className="pointer filter-tabs-cross"
                    >
                      X
                    </span>
                  </span>
                ) : (
                  ""
                )} */}
              </div>
            </div>
            <div></div>
          </div>
          <div className="col-lg-9 col-md-7">
            <div className="schedule-advance-box-right">
              <div className="d-flex-flex-wrap-wrap">
                {practitioners.map((item) => (
                  <div className="col-md-12 mb-3 col-lg-4">
                    <div className="schedule-advance-bx">
                      <div className="profile-box-top">
                        <div className="left-prof-bx">
                          <div className="prof-circal image-advance-client-profile padding-unset">
                            <img
                              className="practioner-profile-image"
                              src={
                                item.profile_image !== ""
                                  ? process.env.REACT_APP_IMAGE_URL +
                                  item.profile_image
                                  : "images/user-dummy.png"
                              }
                              alt="avatar"
                            />
                          </div>
                        </div>
                        <div className="left-name-bx">
                          <span>{item.name}</span>
                        </div>
                        <div className="button-select-profile d-flex align-items-center justify-content-center">
                          {
                            item.show_flexbooker ?
                              <button
                                className="book-nw"
                                onClick={() => window.open(item.personal_booking_link, "_self")}
                              >
                                Book Now
                              </button>
                              :

                              item.session_length !== undefined && item.session_length !== "[]" && <button
                                className="book-nw"
                                onClick={() => getBookingLink(item)}
                              >
                                Book Now
                              </button>}
                          <button
                            className="view-pro"
                            onClick={() => clickViewProfile(item)}
                          >
                            View Profile
                          </button>
                        </div>
                        {!item.show_flexbooker && item.session_length !== undefined && item.session_length === "[]" && <div className="text-center">
                          <span className="red">*Not Available for booking</span>
                        </div>}
                      </div>
                      <div className="language-list-box">
                        <ul>
                          <li className="d-flex">
                            <span className="min-width-70px">
                              <img src="images/Language.png" alt="badge" />
                              Languages:
                            </span>
                            <div>
                              {console.log(item.languages.length)}
                              <em>{add3Dots(item.languages, 100)}</em>
                            </div>
                          </li>
                          <li className="d-flex">
                            <span className="min-width-70px">
                              <img src="images/wordtow.png" alt="badge" />
                              Country:
                            </span>
                            <div>
                              <em>{item.country}</em>
                            </div>
                          </li>
                          <li className="d-flex">
                            <span className="min-width-70px">
                              <img
                                src="images/fluent_payment-48-filled.png"
                                alt="badge"
                              />
                              Payment:
                            </span>
                            <div>
                              {item.payType.includes(1) ? (
                                <em>Pay to Book </em>
                              ) : (
                                ""
                              )}
                              {item.payType.includes(2) ? (
                                <em>Pay After Session</em>
                              ) : (
                                ""
                              )}
                            </div>
                          </li>
                          <li className="d-flex">
                            <span className="min-width-70px">
                              <img
                                src="images/ph_video-camera-fill.png"
                                alt="badge"
                              />
                              Recording:
                            </span>
                            <div>
                              {item.recording === 1 ? (
                                <em>Allowed </em>
                              ) : item.recording === 0 ? (
                                <em>Not Available</em>
                              ) : (
                                ""
                              )}
                            </div>
                          </li>
                          <li className="d-flex margin-unset">
                            <span className="min-width-70px">
                              <img src="images/Badge_1_.png" alt="badge" />
                              Offerings:
                            </span>
                            <div>
                              {item.session_length === "[]" &&
                                <em>Not Offered</em>
                              }
                            </div>
                          </li>
                          {/******************************** */}

                          {item.session_length !== undefined && item.session_length !== "[]" &&
                            Object.entries(JSON.parse(item.session_length)).map(([key, value]) =>

                              <>
                                {/* {console.log()} */}
                                {key != 452 && key != 602 ?
                                <li className="d-flex margin-unset">
                                  <span className="min-width-70px">
                                    {key} Minutes:
                                  </span>
                                  <div>
                                    <em className="d-block">
                                      $ {value} {Object.values(JSON.parse(item.session_currency))[0]}
                                    </em>
                                  </div>
                                </li>
                                : ""}
                              </>
                            )

                          }


                          {/**** test */}
                          {/* {item.session_length !== "[]" &&
                            item.session_currency !== "" && (
                              <>
                                {Object.values(
                                  JSON.parse(item.session_length)
                                )[0] !== "" && (
                                    <li className="d-flex margin-unset">
                                      <span className="min-width-70px">
                                        30 Minutes:
                                      </span>
                                      <div>
                                        {item.session_length !== "" &&
                                          item.session_currency !== "" ? (
                                          <>
                                            <>
                                              {Object.values(
                                                JSON.parse(item.session_length)
                                              )[0] !== "" ? (
                                                <em className="d-block">
                                                  $
                                                  {
                                                    Object.values(
                                                      JSON.parse(
                                                        item.session_length
                                                      )
                                                    )[0]
                                                  }
                                                  { }{" "}
                                                  {
                                                    Object.values(
                                                      JSON.parse(
                                                        item.session_currency
                                                      )
                                                    )[0]
                                                  }
                                                </em>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </li>
                                  )}
                              </>
                            )} */}

                          {/***********45 */}
                          {/* {item.session_length !== "" &&
                            item.session_currency !== "" &&
                            Object.values(
                              JSON.parse(item.session_length)
                            )[1] !== "" && (
                              <li className="d-flex margin-unset">
                                <span className="min-width-70px">
                                  45 Minutes:
                                </span>
                                <div>
                                  {item.session_length !== "" &&
                                    item.session_currency !== "" ? (
                                    <>
                                      <>
                                        {Object.values(
                                          JSON.parse(item.session_length)
                                        )[1] !== "" ? (
                                          <em className="d-block">
                                            $
                                            {
                                              Object.values(
                                                JSON.parse(item.session_length)
                                              )[1]
                                            }
                                            { }{" "}
                                            {
                                              Object.values(
                                                JSON.parse(
                                                  item.session_currency
                                                )
                                              )[1]
                                            }
                                          </em>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </li>
                            )} */}
                          {/*********60 */}
                          {/* {item.session_length !== "" &&
                            item.session_currency !== "" &&
                            Object.values(
                              JSON.parse(item.session_length)
                            )[2] !== "" && (
                              <li className="d-flex margin-unset">
                                <span className="min-width-70px">
                                  60 Minutes:
                                </span>
                                <div>
                                  {item.session_length !== "" &&
                                    item.session_currency !== "" ? (
                                    <>
                                      <>
                                        {Object.values(
                                          JSON.parse(item.session_length)
                                        )[2] !== "" ? (
                                          <em className="d-block">
                                            $
                                            {
                                              Object.values(
                                                JSON.parse(item.session_length)
                                              )[2]
                                            }
                                            { }{" "}
                                            {
                                              Object.values(
                                                JSON.parse(
                                                  item.session_currency
                                                )
                                              )[2]
                                            }
                                          </em>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </li>
                            )} */}

                          {/******************************** */}
                          <span className="prof-price-info app-prof">
                            {/* <>
                              {item.session_length !== "" &&
                                item.session_currency !== "" ? (
                                <>
                                  <>
                                    {Object.values(
                                      JSON.parse(item.session_length)
                                    )[0] !== "" ? (
                                      <em className="d-block">
                                        30 min./$
                                        {
                                          Object.values(
                                            JSON.parse(item.session_length)
                                          )[0]
                                        }
                                        { }{" "}
                                        {
                                          Object.values(
                                            JSON.parse(item.session_currency)
                                          )[0]
                                        }
                                      </em>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                  <>
                                    {Object.values(
                                      JSON.parse(item.session_length)
                                    )[1] !== "" ? (
                                      <em className="d-block">
                                        45 min./$
                                        {
                                          Object.values(
                                            JSON.parse(item.session_length)
                                          )[1]
                                        }
                                        { }{" "}
                                        {
                                          Object.values(
                                            JSON.parse(item.session_currency)
                                          )[1]
                                        }
                                      </em>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                  <>
                                    {Object.values(
                                      JSON.parse(item.session_length)
                                    )[2] !== "" ? (
                                      <em className="d-block">
                                        60 min./$
                                        {
                                          Object.values(
                                            JSON.parse(item.session_length)
                                          )[2]
                                        }
                                        { }{" "}
                                        {
                                          Object.values(
                                            JSON.parse(item.session_currency)
                                          )[2]
                                        }
                                      </em>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                </>
                              ) : (
                                ""
                              )}
                            </> */}

                            {/* {item.recording === 1 ? (
                              <em className="d-block">Recordings Allowed </em>
                            ) : item.recording === 0 ? (
                              <em className="d-block">
                                Recordings Not Allowed
                              </em>
                            ) : (
                              ""
                            )} */}

                            {/* {item.payType.includes(1) ? (
                              <em>Pay to Book </em>
                            ) : (
                              ""
                            )}
                            {item.payType.includes(2) ? (
                              <em>Pay After Session</em>
                            ) : (
                              ""
                            )} */}
                          </span>

                          <li className="d-flex margin-unset">
                            <span className="min-width-70px">
                              {/* <img src="images/Badge_1_.png" alt="badge" /> */}
                              Extra Person:
                            </span>
                            <div>
                              {item.session_length !== "" &&
                                checkSessionExtraPersion(item)}
                            </div>
                          </li>
                        
                       
                              {/* <>
                              {item.session_length !== undefined && item.session_length !== "[]" &&
                                Object.entries(JSON.parse(item.session_length)).map(([key, value]) =>
    
                                  <>
                              
                                   
                                    {key == 452 || key == 602 ?
                                    <li className="d-flex margin-unset">
                                      <span className="min-width-70px">
                                        {key.toString().slice(0, -1)} Minutes for 2:
                                      </span>
                                      <div>
                                        <em className="d-block">
                                          $ {value} {Object.values(JSON.parse(item.session_currency))[0]}
                                        </em>
                                      </div>
                                    </li>
                                    : ""}
                                  </>
                                )
    
                              }
                              </> */}
                           {/*******45 */}
                           <>
                            {item.session_length !== "" &&
                              isfourtyFive(item) ? (
                              " "
                            ) : (
                              <li className="d-flex margin-unset">
                                <span className="min-width-70px">
                                  45 Minutes for 2:
                                </span>
                                <em>
                                {item.session_length !== "" &&
                                checkSessionFoutyFiveExtraPersion(item)}
                                </em>
                                {/* <div>
                                  {item.session_length !== "" &&
                                    Object.values(
                                      JSON.parse(item.session_length)
                                    )[3] !== undefined &&
                                    Object.values(
                                      JSON.parse(item.session_length)
                                    )[3] !== "" ? (
                                    <>
                                      {Object.values(
                                        JSON.parse(item.session_length)
                                      )[3] !== undefined &&
                                        Object.values(
                                          JSON.parse(item.session_length)
                                        )[3] !== "" ? (
                                          <em>
                                            {" "}
                                            {(
                                              parseInt(
                                                Object.values(JSON.parse(item.session_length))[3] !== ""
                                                  ? parseInt(Object.values(JSON.parse(item.session_length))[3])
                                                  : 0
                                              ) -
                                              parseInt(
                                                Object.values(JSON.parse(item.session_length))[1] !== ""
                                                  ? parseInt(Object.values(JSON.parse(item.session_length))[1])
                                                  : 0
                                              )
                                            ) >= 0
                                              ? "+ $" 
                                              : "- $"} 
                                            {Math.abs(
                                              parseInt(
                                                Object.values(JSON.parse(item.session_length))[3] !== ""
                                                  ? parseInt(Object.values(JSON.parse(item.session_length))[3])
                                                  : 0
                                              ) -
                                              parseInt(
                                                Object.values(JSON.parse(item.session_length))[1] !== ""
                                                  ? parseInt(Object.values(JSON.parse(item.session_length))[1])
                                                  : 0
                                              )
                                            )}{" "}
                                            {Object.values(JSON.parse(item.session_length))[3] !== "" &&
                                              Object.values(JSON.parse(item.session_currency))[3] !== undefined &&
                                              Object.values(JSON.parse(item.session_currency))[3]}
                                          </em>
                                        
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div> */}
                              </li>
                            )}
                          </> 
                          {/*******60 */}
                           <>
                            {item.session_length !== "" &&
                              isSixtyForTwo(item) ? (
                              " "
                            ) : (
                              <li className="d-flex margin-unset">
                                <span className="min-width-70px">
                                 
                                  60 Minutes for 2:
                                </span>
                                <em>
                                {item.session_length !== "" &&
                                checkSessionSixtyExtraPersion(item)}
                                </em>
                                {/* <div>
                                  {item.session_length !== "" &&
                                    Object.values(
                                      JSON.parse(item.session_length)
                                    )[4] !== undefined &&
                                    Object.values(
                                      JSON.parse(item.session_length)
                                    )[4] !== "" ? (
                                    <>
                                      {Object.values(
                                        JSON.parse(item.session_length)
                                      )[4] !== undefined &&
                                        Object.values(
                                          JSON.parse(item.session_length)
                                        )[4] !== "" ? (
                                          <em>
                                          {" "}
                                          {(
                                            parseInt(
                                              Object.values(JSON.parse(item.session_length))[4] !== ""
                                                ? parseInt(Object.values(JSON.parse(item.session_length))[4])
                                                : 0
                                            ) -
                                            parseInt(
                                              Object.values(JSON.parse(item.session_length))[2] !== ""
                                                ? parseInt(Object.values(JSON.parse(item.session_length))[2])
                                                : 0
                                            )
                                          ) >= 0
                                            ? "+ $" 
                                            : "- $"} 
                                          {Math.abs(
                                            parseInt(
                                              Object.values(JSON.parse(item.session_length))[4] !== ""
                                                ? parseInt(Object.values(JSON.parse(item.session_length))[4])
                                                : 0
                                            ) -
                                            parseInt(
                                              Object.values(JSON.parse(item.session_length))[2] !== ""
                                                ? parseInt(Object.values(JSON.parse(item.session_length))[2])
                                                : 0
                                            )
                                          )}{" "}
                                          {Object.values(JSON.parse(item.session_length))[4] !== "" &&
                                            Object.values(JSON.parse(item.session_currency))[4] !== undefined &&
                                            Object.values(JSON.parse(item.session_currency))[4]}
                                        </em>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div> */}
                              </li>
                            )}
                          </> 
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div>
                <img
                  src="localImages/arrow-189-xxl.png"
                  onClick={scrollControllerHandler}
                  className="svroll-arrow"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div>
        <h4 className="practioner-heading">Practitioner</h4>
        <div className="starter-practioners">
          <div className="container">
            <div className="row box-practioners-internal">
              {practitioners.length &&

                practitioners.map((item) => (
                  <Practitoner item={item} onClick={onClick} />
                ))
              }

            </div>
          </div>
        </div>
        <div className="text-center">
          <Footer />
        </div>
      </div> */}
      <Prepaypostpaycheck
        mediumSessionInfo={mediumSessionInfo}
        getSubsessions={getSubsessions}
        paytypeText={paytypeText}
        startSeesion={startSeesion}
        professionalInfo={professionalInfo}
      />
    </>
  );
}
