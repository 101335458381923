import Vimeo from '@u-wave/react-vimeo'
import React, { useEffect, useState } from 'react'
import { FadeLoader } from 'react-spinners'
import Button from '../../../../components/button'
import { Link, useNavigate } from 'react-router-dom'
import TickMark from '../../../../components/tickmark'
import { saveStepsProgress } from '../../../../services/medium'

export default function StepFour({ NextStep, innerWidthHandler }) {
    const navigate = useNavigate();

    const [showLoader, setShowLoader] = useState(true);
    const [verificationStatus, setVerificationStatus] = useState(0);

    const openAppointMent = () => {
        window.open("/integration", "_blank", "noreferrer")
    }
    useEffect(() => {
        setTimeout(() => {
            setVerificationStatus(1);
            setShowLoader(false);
        }, 7000)
    })
    const savestep = () => {
        let body = {
            step_number: 4
        }
        saveStepsProgress(body).then((data) => {
            if (data.data.status) {
                localStorage.setItem('step', 4)
                NextStep()
            }
        }).catch(() => {

        })
    }
    return (
        <div>
            <div className='text-left  pl-5 pr-5 mt-5 mb-5'>

                <h3 className='font-size-40 '>Learn About your Appointments Page</h3>
                <ol className='font-size-18 line-height-24px'>
                    <li>Watch the video below.</li>
                    <li>
                    Review &nbsp;

                        <Link className='practice-my-app' to="/appointments?from=onboarding" target='_blank'>
                            Appointments
                        </Link>
                        &nbsp;
                        page
                        <br />
                        &nbsp;(The Appointment screen will open in a new tab, to continue the Onboarding process come back to this tab)

                    </li>
                    <li>Click on Next for Step 5.</li>
                </ol>
                {/* <p className='font-size-18 line-height-24px'>This video will give a general overview for your My Appointments page.</p> */}


            </div>
            <div>
                <Vimeo
                    muted={true}
                    autoplay={false}
                    // className="popover-video"
                    width={innerWidthHandler()}
                    height={330}
                    video={"https://vimeo.com/922676519/c68142040f"}
                />
            </div>

            <div className='mt-5'>

                <Button
                    className={"action-button margin-unset"}
                    status={0}
                    onClick={() => savestep()}
                    title="Next" />

                {/* <Button
                    className={"action-button margin-unset ml-5"}
                    status={0}
                    onClick={openAppointMent}
                    title="Link your Zoom" />
 */}

            </div>

        </div>
    )
}
