import React, { useEffect, useState } from "react";
import Vimeo from "@u-wave/react-vimeo";
import { innerWidthHandler } from "../../../../utils/helpers";
import Button from "../../../../components/button";

export default function Loader({
  loaderType,
  onVideoEnd,
  backHandler,
  text,
  showBtn,
  disabled,
  setRenderType,
  bookingType
}) {
  const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
  };
  const [time, setTime] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setTime(true);
    }, 60000);
  }, [])
  return (
    <div>
      <div className="select-appointment">
        <div onClick={backHandler} className="back-btn-appointments pointer">
          <div className="bck">
            <img src="images/black-erow.png" alt="back" />
            <p className="margin-unset"> Back</p>
          </div>
        </div>
        {/* <div onClick={backHandler} className="back-btn-appointments">
              <a href="#">
                <img src="images/black-erow.png" />
                <p className="margin-unset"> Back</p>
              </a>
            </div> */}
        <div className="container">
          <div className="select-appointment-inner width-unset">
            {/* <div className="edite">
              <a href="#">
                Edit Preferences
                <img src="images/pencil.png" />
              </a>
            </div> */}

            <div className="book-practice">
              {loaderType === "image" ? (
                <>
                  <h2>
                    Based on your preferences, we are <br />
                    finding the best medium for you
                  </h2>
                  <div className="loader-img">
                    <img src="/localImages/search.gif" alt="search" />
                  </div>
                </>
              ) : (
                // <video
                //   autoPlay
                //   onEnded={onVideoEnd}
                //   src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/WeAreGoingOnBullrun.mp4"
                // ></video>
                <>
                  <h2>{text}</h2>
                  <Vimeo
                    muted={isSafari()}
                    onEnd={() => onVideoEnd('prof')}
                    autoplay={true}
                    className="popover-video"
                    width={innerWidthHandler()}
                    height={430}
                    // video={activesession_id === "5" ? 'https://vimeo.com/767367299/9424233cd8' : activesession_id ==="3" ? 'https://vimeo.com/user165935167/subtlebutbeautiful?share=copy' :'https://player.vimeo.com/video/768672555?h=d255e3da2a'}
                    video={bookingType === "outreach"
                      ? 'https://vimeo.com/767367299/9424233cd8'
                      : bookingType === "starter"
                        ? 'https://player.vimeo.com/video/741150506?h=7d1c5c263c&title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479'
                        : 'https://player.vimeo.com/video/768672555?h=d255e3da2a'}
                  // https://vimeo.com/767367299/9424233cd8
                  // https://vimeo.com/user165935167/subtlebutbeautiful?share=copy
                  />
                  {time && (bookingType == "outreach" || bookingType === "starter" || bookingType === "professional") && <Button
                    // onClick={bookStarterSession}
                    onClick={() => onVideoEnd('prof')}
                    className={"practioner-tab-appointment-btn-1"}
                    title={"Continue"}
                    status={0}
                  />}
                </>

              )}
              {/* {showBtn !== undefined && showBtn && (
                <>
                  <p>
                    {" "}
                    Click Here to Book Session after watcing Video
                    <a
                      href={
                        !disabled
                          ? "javascript:void(0);"
                          : "https://appointments.verysoul.com/reserve/donated-free"
                      }
                      className={`action-redirect-btn ${
                        !disabled ? "non-clickable-state" : ""
                      }`}
                    >
                      Book Session
                    </a>
                  </p>
                </>
              )}
              {showBtn !== undefined && !showBtn && (
                <>
                  <p>
                    {" "}
                    Click Here to Book Session after watcing Video
                    <input
                      onClick={
                        !disabled
                          ? () => {}
                          : () => setRenderType("newappointment")
                      }
                      type="button"
                      className={`action-redirect-btn ${
                        !disabled ? "non-clickable-state" : ""
                      }`}
                      value="Book Session"
                    />
                  </p>
                </>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
