import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/button";
import InfoPopup from "../../../components/infopopup";
import { resendSurveyEmail } from "../../../services/admin";
import { toast } from "react-toastify";


const initialValues = {
  languages: [],
  countries: "",
  specialities: "",
};
export default function SurveyTable({surveyLinks}) {
 

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formLanguages, setFormLanguages] = useState([]);
  const [active, setActive] = useState([]);

  const openMoreInfo = (info) => {
    // setActive([])
    // console.log('info...',info)
    let array = [];

    // Object.values(info).map((item) => {
    //   array.push(item.feedback);
    // });

    array[0]=info
    setActive(array);
    // console.log('info12...',active)
    window.loadModal2("#infopopup", "show");
   
  };

 
   const resendSurveyLink=(id)=>{
    let body = {
     survey_id:id
      // survey_link
    };
    resendSurveyEmail(body)
      .then((data) => {
        if (data.data.status) {
          toast.success("Survey generated Succesfully", {
            position: "top-center",
          });
          // getLink();
        }
      })
      .catch(() => {});
   }
  // const openSurveyLink = (item) => {
  //   window.open(item, "_blank");
  // };
  return (
    <div className="dashboard">
      <div className="row">
        <div className="col-md-12">
          <div className="my-ppointments-box appoint-das">
            <div className="appoinments-table">
              <div className="my-app-table">
                <table className="caseTable">
                  <tbody>
                    <tr className="dark">
                    <th>Medium Name</th>
                      <th>
                        {/* <input type="checkbox" id="one" />
                        <label htmlFor="one"></label> */}
                        Medium Email
                      </th>
                      <th>Client Name</th>
                      <th>Client Email</th>
                     
                      <th>Feedback Survey</th>
                      {/* <th>Contact</th>
                      <th>Action</th> */}
                    </tr>
                   
                    {surveyLinks.length > 0 ? (
                      surveyLinks.map((appoinment) => (
                        <tr className="innr">
                          <td className="capitalize">
                            {appoinment.medium_name}
                          </td>
                          <td>
                            {appoinment.medium_email}
                          </td>
                          <td>{appoinment.client_name}
                          </td>
                          <td>{appoinment.client_email}
                          </td>
                          <td>
                            {/* {appoinment.survey_link !== "" && (
                              <Button
                                status={0}
                                onClick={(e) =>
                                  openSurveyLink(appoinment.survey_link)
                                }
                                type="button"
                                className="resend-btn w-130"
                                title="Take Survey"
                              />
                            )} */}
                            {appoinment.survey_status !== " " && appoinment.survey_status =="0" ? (
                              <Button
                                status={0}
                                onClick={(e) =>
                                  resendSurveyLink(appoinment.id)
                                }
                                type="button"
                                className="resend-btn w-130"
                                title="Resend Survey"
                              />
                            ): 
                               (
                              <img
                              title="More"
                              onClick={() => openMoreInfo(appoinment.feedback)}
                              className="pointer"
                              src="/localImages/openeye.svg"
                              alt="eye"
                            />
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No Appointments Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
   <InfoPopup feedBackinfo={active} />
    </div>
  );
}
