import React from "react";

export default function CheckNightAppointment({ bookingtime, deleteUser, cancelRequest, id }) {
    return (
        <div className="confirm-delete-wrapper checkbooktime register-medium">
            <div data-backdrop="static" className="modal fade" id={id} role="dialog">
                <div className="modal-dialog    modal-dialog-preview ">
                    <div className="modal-content">
                        <div className="modal-body">
                            {/* <div className="icon-pop">
                <img src="images/Union-icon.png" alt="Union-icon" />
              </div> */}
                            <p className="red margin-unset font-size-18 line-height-20px pt-5">ALERT: This appointment time of {bookingtime} <img src="localImages/moon.png" class="star-imh-moon" alt="time-image" /> is during typical night time hours in your time zone.</p>
                            <h3>Are you sure you want to book this?</h3>
                            <div className="confirm-btn-box">
                                <div
                                    className="register-medium-confirnation"
                                    onClick={cancelRequest}
                                    data-dismiss="modal"
                                >
                                    Cancel
                                </div>
                                <div
                                    className="register-medium-confirnation"
                                    data-dismiss="modal"
                                    onClick={deleteUser}
                                >
                                    Confirm
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
