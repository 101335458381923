import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//components
import TabItem from "./subcomponent/tabitem";
import Preferences from "./preferences";
import MyTechnology from "./mytechnology";
import Profile from "./profile";
import LegalAndPrivacy from "./legalandprivacy";

import { list } from "./data";
import TabPanel from "./subcomponent/tabpanel";
import {
  stepOneData,
  stepThreeData,
  stepTwoData,
  stepFourData,
} from "../../profile/data";

//reducer
import {
  setaLanguagesArray,
  setToolsArray,
  setCountryArray,
  setSpecialtyArray,
  setTimezoneArray,
} from "../../../reducer/uidataSlice";
//service
import {
  getTools,
  getLanguages,
  getCountry,
  getSpeciality,
  getTimezones,
} from "../../../services/uidata";
import {
  getProfileBasicInfo,
  getProfilePreferences,
  getProfileTechnology,
  getProfileLeagalAndPrivacy
} from "../../../services/medium";
import { toast } from "react-toastify";

export default function MyProfile() {
  const { linkZoomRequest } = useSelector((state) => state.medium);
  const {
    languagesArray,
    toolsArray,
    countryArray,
    specialtyArray,
    timezoneArray,
  } = useSelector((state) => state.uidata);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [formLanguages, setFormLanguages] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [timezoneData, setTimezoneData] = useState([]);
  const [specialtyData, setSpecialityData] = useState([]);
  const [tools, setTools] = useState([]);
  const [profileSection, setProfileSection] = useState(null);
  const [teachnologySection, setteachnologySection] = useState(null);
  const [preferncesSection, setpreferncesSection] = useState(null);
  const [legalandprivacySection, setLegalandprivacySection] = useState(null);
  // const [timezoneOnly, settimezoneOnly] = useState(null);
  const [recieved, setRecieved] = useState(false);
  // const [coverImage, setCoverImage] = useState("images/profile-cover.png");

  useEffect(() => {
    //getprofiledetails
    if (profileSection == null) getProfile();
    if (teachnologySection == null) getTechnologies();
    if (preferncesSection == null) getPreferences();
    if (legalandprivacySection == null) leagalAndPrivacy();
    if (!countryArray.length) updateCountries();
    if (!timezoneArray.length) updateTimezones();
    if (!languagesArray.length) updateLanguage();
    if (!specialtyArray.length) updateSpeciality();
    if (!toolsArray.length) updateTools();
  }, []);

  useEffect(() => {
    if (stepTwoData.country_type === "All Countries with Exclusions") {
      stepTwoData.exclusions = getCountryTypes(
        stepTwoData.country_type,
        stepTwoData.countries,
        countryData
      );
    }
    if (stepTwoData.country_type === "Selected Countries only") {
      stepTwoData.inclusions = getCountryTypes(
        stepTwoData.country_type,
        stepTwoData.countries,
        countryData
      );
    }
  }, [recieved, countryData]);
  useEffect(() => {
    if (linkZoomRequest) setActiveTab(2);
  }, [linkZoomRequest]);
  useEffect(() => {
    setFormLanguages(languagesArray);
    setCountryData(countryArray);
    setSpecialityData(specialtyArray);
    setTools(toolsArray);
  }, [languagesArray, toolsArray, countryArray, specialtyArray]);

  const updateTools = () => {
    getTools()
      .then((data) => {
        dispatch(setToolsArray(data.data.data));
      })
      .catch((err) => console.log(err));
  };

  const updateLanguage = () => {
    getLanguages()
      .then((data) => {
        console.log(data.data.data, "data.data.data")
        dispatch(setaLanguagesArray(data.data.data));
      })
      .catch((err) => console.log(err));
  };

  const updateCountries = () => {
    getCountry()
      .then((data) => {
        dispatch(setCountryArray(data.data.data));
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    setTimezoneData(timezoneArray);
  }, [timezoneArray]);
  const updateTimezones = () => {
    getTimezones()
      .then((data) => {
        dispatch(setTimezoneArray(data.data.data));
      })
      .catch((err) => console.log(err));
  };
  const reloadTimeZones = (refresh) => {
    getTimezones()
      .then((data) => {
        setTimezoneData(data.data.data);
        if (refresh == "refresh") {
          toast.success("TimeZones Refreshed", { position: "top-center" });
        }
      })
      .catch((err) => console.log(err));
  }
  const updateSpeciality = () => {
    getSpeciality()
      .then((data) => {
        dispatch(setSpecialtyArray(data.data.data));
      })
      .catch((err) => console.log(err));
  };

  const getCountryTypes = (type, countries, items) => {
    if (countries.length) {
      let country;
      if (type === "All Countries with Exclusions") {
        const res = items.filter(
          (entry1) =>
            !countries.some(
              (entry2) => parseInt(entry1.id) === parseInt(entry2.country_id)
            )
        );
        country = res.map((item) => {
          return {
            ...item,
            value: item.id,
            label: item.country_name,
          };
        });
      }
      if (type === "Selected Countries only") {
        const res = items.filter((entry1) =>
          countries.some(
            (entry2) => parseInt(entry1.id) === parseInt(entry2.country_id)
          )
        );

        country = res.map((item) => {
          return {
            ...item,
            value: item.id,
            label: item.country_name,
          };
        });
      }
      // stepTwoData.exclusions =
      //   type == "All Countries with Exclusions" ? country : "";
      // stepTwoData.inclusions = type == "Selected Countries only" ? country : "";
      return country;
    }
  };

  const updateIncomingHandler = (items, type) => {
    let incomingArray;
    if (type === "languages") {
      incomingArray = items.map((item) => {
        return {
          ...item,
          id: item.language_id,
          value: item.language_id,
          label: item.lanugage_name,
        };
      });
    }
    if (type === "specialities") {
      incomingArray = items.map((item) => {
        return {
          ...item,
          id: item.mediumship_specialities_id,
          value: item.mediumship_specialities_id,
          label: item.specialty_name,
        };
      });
    }
    if (type === "tools") {
      incomingArray = items.map((item) => {
        return {
          ...item,
          id: item.mediumship_session_tool_id,
          value: item.mediumship_session_tool_id,
          label: item.tools_name,
        };
      });
    }
    return incomingArray;
  };
  const getProfile = () => {
    getProfileBasicInfo()
      .then((data) => {
        console.log(data.data.data);
        const {
          country,
          first_name,
          last_name,
          info,
          languages,
          mediumship_level_medium,
          phone,
          specialities,
          website,
          whatsapp,
          timezone,
          provider,
          mediumship_level,
          when_do_i_pay,
          session_length,
          medium_professional_category,
          session_currency,
          allow_free_coaching_session
        } = data.data.data;
       stepOneData.allow_free_coaching_session = allow_free_coaching_session
        stepOneData.firstName = first_name;
        stepOneData.lastName = last_name;
        stepOneData.website = website;
        stepOneData.country = country;
        stepOneData.biographicalInfo = info;
        stepOneData.phone = phone;
        stepOneData.mediumship_level_admin = parseInt(mediumship_level);
        stepOneData.mediumshipLevel = parseInt(mediumship_level_medium);
        stepOneData.languages = updateIncomingHandler(languages, "languages");
        stepOneData.mediumshipSpecialities = updateIncomingHandler(
          specialities,
          "specialities"
        );
        stepOneData.medium_professional_category = medium_professional_category;
        stepOneData.whatsapp = whatsapp;
        stepOneData.timezone = { ...timezone, value: timezone.id, label: timezone.front_name };
        stepOneData.provider = provider;
        stepOneData.when_do_i_pay =
          when_do_i_pay !== undefined
            ? when_do_i_pay.map((item) => {
              return String(item);
            })
            : [];
        stepOneData.session_length_30_currency =
          session_currency !== null
            ? Object.values(JSON.parse(session_currency))[0]
            : "";
        stepOneData.session_length_30 = session_length !== null && Object.values(
          JSON.parse(session_length)
        )[0];
        stepOneData.session_length_45 = session_length !== null && Object.values(
          JSON.parse(session_length)
        )[1];
        stepOneData.session_length_60 = session_length !== null && Object.values(
          JSON.parse(session_length)
        )[2];
        stepOneData.session_length_45_2 = session_length !== null && Object.values(
          JSON.parse(session_length)
        )[3];
        stepOneData.session_length_60_2 = session_length !== null && Object.values(
          JSON.parse(session_length)
        )[4];
        stepOneData.session_lengths = session_length;
        stepOneData.session_lengths_currency = session_currency
        setProfileSection(data.data.data);
      })
      .catch((err) => console.log(err));
  };
  const getPreferences = () => {
    getProfilePreferences()
      .then((data) => {
        setRecieved(true);
        const {
          allow_recordings,
          countries,
          current_roles,
          mediumship_session_tools,
          payment_methods,
          session_message,
          // session_preferences,
          client_session_preferences,
          session_type,
          screen_name,
          country_type,
          opt_in,
        } = data.data.data;
        stepTwoData.mediumship_session_tools = updateIncomingHandler(
          mediumship_session_tools,
          "tools"
        );

        stepTwoData.allowRecordings = allow_recordings;
        stepTwoData.interestedSessionType1 = session_message;
        stepTwoData.currentRoles = current_roles;
        stepTwoData.PaymentMethods = payment_methods.map((item) => {
          return item.payment_method_id;
        });
        stepTwoData.wantToDoSessions = client_session_preferences;
        stepTwoData.interestedSessionType =
          session_type !== null ? session_type : [];
        stepTwoData.screenName = screen_name;
        stepTwoData.country_type = country_type;
        stepTwoData.wantToDoWork =
          country_type === "" ? "All Countries" : country_type;
        stepTwoData.countries = countries;
        stepTwoData.opt_in = opt_in === 1 ? "Yes" : "No";

        setpreferncesSection(data.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getTechnologies = () => {
    getProfileTechnology()
      .then((data) => {
        const {
          calendar_type,
          preferred_browser,
          preferred_device,
          zoom_account_type,
          zoom_personal_link,
          paypal_me_link,
        } = data.data.data;

        stepThreeData.electronicCalendars =
          calendar_type !== null ? calendar_type : [];
        stepThreeData.zoomAccountType = zoom_account_type;
        stepThreeData.preferredDevice =
          preferred_device !== null ? preferred_device : [];
        stepThreeData.preferredBrowser =
          preferred_browser !== null ? preferred_browser : [];
        stepThreeData.zoom_personal_link = zoom_personal_link;
        stepThreeData.paypal_me_link = paypal_me_link !== "" ? paypal_me_link : stepThreeData.paypal_me_link;

        setteachnologySection(data.data.data);
      })
      .catch((err) => console.log(err));
  };

  const leagalAndPrivacy = () => {
    getProfileLeagalAndPrivacy().then((data) => {
      if (data.data.status) {
        let info = data.data.data[0]

        setLegalandprivacySection(info);
        stepFourData.legalAndPrivacy = info.legal_privacy === 1 ? true : false;
        stepFourData.optIn = info.opt_in === 1 ? 'Yes' : info.opt_in === 0 ? 'No' : '';
        stepFourData.codeOfEthics = info.code_of_ethics === 1 ? true : false;
        stepFourData.operatingPrinciples = info.operating_principal === 1 ? true : false;
      }

    })
  }
  // const coverImageChangeHandler = (e) => {
  //   const file = e.target.files[0];

  //   const formData = new FormData();
  //   formData.append("file", file);

  //   setCoverImage(e.target.value);
  // };
  return (
    <>
      <div className="profile-cover-box">
        <img src={"images/profile-cover.png"} alt="cover" />
        {/* <div className="upload-btn-wrapper">
          <button className="btn">
            <img src={"images/uploadimage.png"} alt="change cover" />
            Change Cover
          </button>
          <input type="file"  name="myfile" />
        </div> */}
      </div>
      <div className="container-fluid">
        <div className="dashboard">
          <div className="row">
            <div className="col-md-12">
              <div className="profile-tow-box">
                <div className="row">
                  <div className="col-md-12">
                    <div className="profile-tow-box-right">
                      <section className="signup-step-container">
                        <div className="row d-flex justify-content-center">
                          <div className="wizard">
                            <div className="wizard-inner">
                              <TabItem
                                list={list}
                                setActiveTab={setActiveTab}
                              />
                            </div>
                            <div
                              role="form"
                              action="index.html"
                              className="login-box"
                            >
                              <div className="tab-content" id="main_form">
                                <TabPanel id={0} activeTab={activeTab}>
                                  <Profile
                                    tools={tools}
                                    formLanguages={formLanguages}
                                    countryData={countryData}
                                    specialtyData={specialtyData}
                                    stepOneData={stepOneData}
                                    getProfile={getProfile}
                                    timezoneData={timezoneData}
                                    reloadTimeZones={reloadTimeZones}
                                  />
                                </TabPanel>
                                <TabPanel id={1} activeTab={activeTab}>
                                  <Preferences
                                    tools={tools}
                                    formLanguages={formLanguages}
                                    countryData={countryData}
                                    specialtyData={specialtyData}
                                    stepTwoData={stepTwoData}
                                    stepOneData={stepOneData}
                                    getPreferences={getPreferences}
                                  />
                                </TabPanel>
                                <TabPanel id={2} activeTab={activeTab}>
                                  <MyTechnology
                                    formLanguages={formLanguages}
                                    countryData={countryData}
                                    specialtyData={specialtyData}
                                    stepOneData={stepOneData}
                                    stepThreeData={stepThreeData}
                                    getTechnologies={getTechnologies}
                                  />
                                </TabPanel>
                                <TabPanel id={3} activeTab={activeTab}>
                                  <LegalAndPrivacy
                                    stepFourData={stepFourData}
                                  />
                                </TabPanel>
                              </div>
                            </div>
                            {/* <form
                              role="form"
                              action="index.html"
                              className="login-box"
                            > */}
                            {/* <div className="tab-content" id="main_form">
                              
                            </div> */}
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
