import React from "react";
import { Formik } from "formik";
import { Link, useParams } from "react-router-dom";
import { updateLegalAndPricayData } from "../../../../../services/profile";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../../reducer/auth/auth";
import { toast } from "react-toastify";
export default function LegalAndPrivacy(props) {
  const { stepFourData } = props;
  const dispatch = useDispatch()
  const params = useParams();


  const RunFunction = (values) => {
    dispatch(setLoading(true));
    const {
      legalAndPrivacy,
      optIn,
      codeOfEthics,
      operatingPrinciples,
    } = values;
    console.log(values, "formData")


    let item = {
      user_id:params.id,
      legal_privacy: legalAndPrivacy ? 1 : 0,
      opt_in: optIn === "Yes" ? 1 : 0,
      code_of_ethics: codeOfEthics ? 1 : 0,
      operating_principal: operatingPrinciples ? 1 : 0,
    };
    updateLegalAndPricayData(item)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          toast.success("Profile Updated", { position: "top-center" });
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });

  }
  return (
    <Formik
      initialValues={stepFourData}
      validate={(values) => {
        const errors = {};
        // if (!values.legalAndPrivacy) errors.legalAndPrivacy = "Required";
        if (!values.optIn) errors.optIn = "Required";
        // if (!values.codeOfEthics) errors.codeOfEthics = "Required";
        // if (!values.operatingPrinciples)
        //   errors.operatingPrinciples = "Required";

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        RunFunction(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form
          onSubmit={handleSubmit}
          className="tab-pane"
          role="tabpanel"
          id="step4"
        >
          <div className="row-one-step">
            <div className="full-fild">
              <label>
                Legal and Privacy <i>*</i>
              </label>
              <div className="prvacy-box">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="I have read and agree to the terms and conditions"
                  id="termsandconditions"
                  type="checkbox"
                  name="legalAndPrivacy"
                  checked={values.legalAndPrivacy}
                  // disabled
                />
                <label htmlFor="termsandconditions" className="width-unset">
                  I have read and agree to the terms and conditions.
                </label>
                <div className="tr-pr">
                  <Link
                    to="/terms-conditions"
                    target="_blank"
                  >
                    Terms &amp; Conditions
                  </Link>
                  <a
                    href="https://verysoul.com/privacy-policy/"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row-one-step">
            <div className="full-fild">
              <label>
                Opt-In? <i>*</i>
              </label>
              <h4>
                I would like to receive notices of offers (for events,
                opportunities, etc.)
              </h4>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="Yes"
                  id="optin"
                  type="radio"
                  name="optIn"
                  defaultChecked={values.optIn === "Yes" ? 'checked' : ''}

                />
                <label htmlFor="my-checktow">Yes</label>
              </div>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="No"
                  id="optout"
                  type="radio"
                  name="optIn"
                  defaultChecked={values.optIn === "No" ? 'checked' : ''}
                />
                <label htmlFor="my-checktow">No</label>
              </div>
            </div>
          </div>
          <div className="row-one-step">
            <div className="full-fild">
              <label>
                Code of Ethics <i>*</i>
              </label>
              <div className="prvacy-box eth-pri">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value=" have read and agree to abide by the VerySoul"
                  id="ethics"
                  type="checkbox"
                  name="codeOfEthics"
                  checked={values.codeOfEthics}
                  // disabled
                />
                <label htmlFor="ethics" className="width-unset">
                  I have read and agree to abide by the VerySoul
                  <a
                    href="https://verysoul.com/code-of-ethics/"
                    target="_blank"
                  >
                    Code of Ethics
                  </a>
                </label>
              </div>
            </div>
          </div>
          <div className="row-one-step lastrow">
            <div className="full-fild">
              <label>
                Operating Principles
                <i>*</i>
              </label>

              <div className="prvacy-box eth-pri">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="checkbox"
                  id="principles"
                  value="I have read and agree to abide by the VerySoul"
                  name="operatingPrinciples"
                  checked={values.operatingPrinciples}
                  // disabled
                />
                <label htmlFor="principles" className="width-unset">
                  I have read and agree to abide by the VerySoul
                  <a 
                  href="https://verysoul.com/operating-principle/"
                  rel="noopener noreferrer"
                  target="_blank"
                  >Operating Principles</a>
                </label>
              </div>
            </div>
          </div>
          <div className="update">
            <button type="submit" className="update">Update</button>
          </div>
        </form>
      )}
    </Formik>
  );
}
