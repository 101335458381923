import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="appointment-footer-client">
      <p>
        <img src="../images/Union-icon.png" alt="footer-img" />
        <p className="demo-tag">For greater perspective on life</p>
        <div>
          <div className="mb-3">
            <Link className="do-a-seesion-link-first" to="/terms-conditions">
              Terms & Conditions
            </Link>
            <span className="do-a-seesion-link-first-line"></span>
            <Link className="do-a-seesion-link" to="/privacy-policy">
              Privacy Policy
            </Link>
          </div>
          <p>© 2022 Very Soul LLC. All rights reserved.</p>
          <p><b>Note: For optimum performance: use Chrome, Safari, or Edge browser</b></p>
          <p ><b>Have a question? Send us an email at <a href="mailtosupport@verysoul.com" >support@verysoul.com</a></b></p>

          
        </div>
      </p>
    </div>
  );
}
