import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../../../components/footer/footer";
import Topbar from "../../../components/topbar/topbar";
import { setPathArray } from "../../../reducer/adminSlice";
import { setLoading } from "../../../reducer/auth/auth";

import {
  getMediumProfileDetails,
  changeMediumshipStatus,
  changeSessionPrefrences,
} from "../../../services/admin";
import StarsRating from "stars-rating";
import "./profile.css";
import { removeQueryParams, setQueryParams } from "../../../utils/helpers";

export default function MediumView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //query params
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("view");
  const searchVal = searchParams.get("search");
  const searchName = searchParams.get("searchName");
  const searchOprator = searchParams.get("searchOprator");
  const searchFilterOprater = searchParams.get("searchFilterOprater");
  const page = searchParams.get('page');
  const rowperpage = searchParams.get('rowperpage');
  
  //query params
  const [profile, setProfile] = useState(null);
  const [selectValue, setSelectValue] = useState("");
  const [approvedPrefrences, setApprovedPrefrences] = useState([
    { id: 1, name: "Other Mediums", status: false },
    { id: 2, name: "Starter Clients", status: false },
    { id: 3, name: "Outreach Clients", status: false },
    { id: 4, name: "Paying Clients", status: false },
  ]);

  //query state
  const [queryValue, setQueryValue] = useState('');
  const [query1, setQuery1] = useState('');
  const [query2, setQuery2] = useState('');
  const [query3, setQuery3] = useState('');
  const [query4, setQuery4] = useState('');
  const [query5, setQuery5] = useState('');
  const [query6, setQuery6] = useState('');
  const params = useParams();

  useEffect(() => {
    localStorage.setItem('pagintionTYPE', 'change')
    dispatch(setPathArray(params.id));
    getProfile(params.id);


    setQueryParams(setQueryValue, setQuery1, setQuery2,setQuery3,setQuery4,setQuery5,setQuery6, status, searchVal, searchName,page ,rowperpage,searchOprator,searchFilterOprater)
    setTimeout(() => {
      removeQueryParams()

    }, 2000);


    return () => {
      dispatch(setPathArray(null));
    };

  }, []);

  const getProfile = (id) => {
    dispatch(setLoading(true));
    getMediumProfileDetails(id)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          const arraydata = data.data.data;
          if (
            Array.isArray(arraydata.preferences) &&
            arraydata.preferences.length === 0
          )
            arraydata.preferences = {};
          if (
            Array.isArray(arraydata.morePreferences) &&
            arraydata.morePreferences.length === 0
          )
            arraydata.morePreferences = {};
          setProfile(arraydata);
          setSelectValue(arraydata.general.current_mediumship_level);
          setApprovedPrefrences(
            arraydata.preferences.session_preferences !== null
              ? mapObject(arraydata.preferences.session_preferences)
              : approvedPrefrences
          );
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
  const mapObject = (items) => {
    let array = approvedPrefrences;
    array.map((item) => {
      if (items.includes(item.name)) {
        item.status = true;
      } else {
        item.status = false;
      }
    });
    return array;
  };
  const changeMediumStatus = (value) => {
    let body = {
      userId: params.id,
      mediumship_level: value,
    };
    changeMediumshipStatus(body)
      .then(() => { })
      .catch(() => { });
  };

  const calculateUnique = (arr) => {
    let value = arr.reduce((acc, val) => {
      acc[val] = acc[val] === undefined ? 1 : (acc[val] += 1);
      return acc;
    }, {});
    return value;
  };
  const calculateRating = (feedbacks) => {
    let total = 0;
    let sum_resp = 0;
    let counts = feedbacks.map((item) => {
      return parseInt(JSON.parse(item.feedback).Star_Rating);
    });

    Object.entries(calculateUnique(counts)).map(([key, value]) => {
      sum_resp += parseInt(value) * parseInt(key);
      total += parseInt(value);
    });

    return Math.floor(sum_resp / total);
  };

  const changeHandler = (e, item) => {
    if (e.target.name === "wantToDoSessions") {
      setApprovedPrefrences(
        approvedPrefrences.map((value) => {
          if (parseInt(value.id) === parseInt(item.id)) {
            return {
              ...value,
              ...(value.status = e.target.checked),
            };
          } else {
            return {
              ...value,
            };
          }
        })
      );
      let bodyData = approvedPrefrences
        .filter((item) => {
          if (item.status) {
            return item.name;
          }
        })
        .map((value) => {
          return value.name;
        });
      let body = {
        session_preferences: bodyData,
        user_id: params.id,
      };
      changeSessionPrefrences(body)
        .then(() => { })
        .catch(() => { });
    }
    if (e.target.name === "sel") {
      const value = e.target.value;
      setSelectValue(value);
      changeMediumStatus(value);
    }
  };
  // const checkDefault = (name) => {
  //   if (approvedPrefrences.includes(name)) return true;
  //   if (!approvedPrefrences.includes(name)) return false;
  // };

  return (
    <div className="sinle-profile">
      <Topbar />

      <div className="single-profile-saction">
        <div className="container">
          <div className="back-btn-profile">
            <span className="back-handler pointer" onClick={() => status === null ? navigate(-1) : navigate(`/mediums?view=${queryValue}&search=${query1}&searchName=${query2}&page=${query3}&rowperpage=${query4}&pagintion=${'change'}&searchOprator=${query5}&searchFilterOprater=${query6}`)} >
              <img src="../images/arrow-back.png" alt="go back" />
              Go Back
            </span>
          </div>
          <div className="profile-all-box">
            <div className="banner-img-profile">
              <img src="../images/profile-cover.png" alt="profile" />
            </div>
            <div className="profile-info">
              <div className="profile-image">
                <img src="../images/user-dummy.png" alt="profile" />
              </div>
              <div className="profile-name">
                <div className="nam-prof">
                  {profile !== null && profile.preferences.screen_name}
                </div>
                <a href={`mailto:${profile !== null && profile.general.email}`}>
                  {profile !== null && profile.general.email}
                </a>
                {/* <div className="star-rating">
                  <span>0</span>
                  <img src="../localImages/hollowstar.svg" alt="white star" />
                  <img src="../localImages/hollowstar.svg" alt="white star" />
                  <img src="../localImages/hollowstar.svg" alt="white star" />
                  <img src="../localImages/hollowstar.svg" alt="white star" />
                  <img src="../localImages/hollowstar.svg" alt="white star" />
                  <em>0 Ratings</em>
                </div> */}
                <div class="star-rating">
                  {profile !== null && Array.from(Array(5).keys()).map((item) => (
                    <>
                      {item + 1 <=
                        parseInt(calculateRating(profile.feedbacks)) ? (
                        <img src={"../localImages/star.png"} alt="star" />
                      ) : (
                        <img src="../localImages/hollowstar.svg" alt="white star" />
                      )}
                    </>
                  ))}
                  {/* <span>0</span>{" "} */}
                  {/* <img src="localImages/hollowstar.svg" alt="white star" />
                <img src="localImages/hollowstar.svg" alt="white star" />
                <img src="localImages/hollowstar.svg" alt="white star" />
                <img src="localImages/hollowstar.svg" alt="white star" />
                <img src="localImages/hollowstar.svg" alt="white star" /> */}
                  {/* <em>0 Ratings</em> */}
                </div>
              </div>
            </div>
            <div className="profile-details">
              <ul>
                <li>
                  <div className="icon-detls">
                    <img src="../images/chate.png" alt="chat.png" />
                  </div>
                  <em>Languages Available for Mediumship Sessions:</em>
                  {profile !== null &&
                    profile.general.languages.map((item) => (
                      <span>{item.lanugage_name}</span>
                    ))}
                </li>
                <li>
                  <div className="icon-detls">
                    <img src="../images/present.png" alt="Mediumship.png" />
                  </div>
                  <em>Mediumship Specialities:</em>
                  {profile !== null &&
                    profile.general.specialities.map((item) => (
                      <span>{item.specialty_name}</span>
                    ))}
                </li>
                <li>
                  <div className="icon-detls">
                    <img src="../images/word.png" alt="web.png" />
                  </div>
                  <em>Country:</em>

                  <p className="label-value">
                    {profile !== null && profile.general.country}
                  </p>
                </li>
                <li>
                  <div className="icon-detls">
                    <img src="../images/word.png" alt="timezone" />
                  </div>
                  <em>Timezone:</em>

                  <p className="label-value">
                    {profile !== null && profile.general.timezone}
                  </p>
                </li>
                <li>
                  <div className="icon-detls">
                    <img src="../images/web.png" alt="website" />
                  </div>
                  <em>Website:</em>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${profile !== null && profile.general.website}`}
                  >
                    {profile !== null && profile.general.website}
                  </a>
                </li>
                <li>
                  <div className="icon-detls">
                    <img src="../images/web.png" alt="special tools" />
                  </div>
                  <em>Special Tools:</em>
                  {profile !== null &&
                    Object.keys(profile.preferences).length > 0 &&
                    profile.preferences.mediumship_session_tools.map((item) => (
                      <span>{item.tools_name}</span>
                    ))}
                </li>
                <li>
                  <div className="icon-detls">
                    <img src="../images/web.png" alt="web.png" />
                  </div>
                  <em>Screen Name :</em>
                  <a href="javascipt:void(0)">
                    {profile !== null && profile.preferences.screen_name}
                  </a>
                </li>
                <li>
                  <div className="icon-detls">
                    <img src="../images/web.png" alt="web.png" />
                  </div>
                  <em>Current Mediumship Level :</em>
                  <a href="javascipt:void(0)">
                    {profile !== null &&
                      (parseInt(
                        profile.general.current_mediumship_level_medium
                      ) === 1
                        ? "Novice"
                        : parseInt(
                          profile.general.current_mediumship_level_medium
                        ) === 2
                          ? "Intermediate"
                          : parseInt(
                            profile.general.current_mediumship_level_medium
                          ) === 3
                            ? "Professional"
                            : "")}
                  </a>
                </li>
                <li>
                  <div className="icon-detls">
                    <img src="../images/web.png" alt="web.png" />
                  </div>
                  <em>Approved Mediumship Level :</em>
                  <select
                    onChange={changeHandler}
                    defaultValue={selectValue}
                    value={selectValue}
                    id="sel"
                    className="ml-1"
                    name="sel"
                  >
                    <option selected={parseInt(selectValue) === 1} value={1}>
                      Novice I
                    </option>
                    <option selected={parseInt(selectValue) === 4} value={4}>
                      Novice II
                    </option>
                    <option selected={parseInt(selectValue) === 2} value={2}>
                      Intermediate I
                    </option>
                    <option selected={parseInt(selectValue) === 5} value={5}>
                      Intermediate II
                    </option>
                    <option selected={parseInt(selectValue) === 3} value={3}>
                      Professional I
                    </option>
                    <option selected={parseInt(selectValue) === 6} value={6}>
                      Professional II
                    </option>
                    <option selected={parseInt(selectValue) === 7} value={7}>
                      Professional III
                    </option>
                  </select>
                </li>
                <li>
                  <div className="icon-detls">
                    <img src="../images/web.png" alt="web.png" />
                  </div>
                  <em>Session Preferences :</em>
                  {profile !== null &&
                    Object.keys(profile.preferences).length > 0 &&
                    profile.preferences.client_session_preferences !== null &&
                    profile.preferences.client_session_preferences.length &&
                    profile.preferences.client_session_preferences.map(
                      (item) => <span>{item}</span>
                    )}
                </li>
                <li>
                  <div className="icon-detls">
                    <img src="../images/web.png" alt="web.png" />
                  </div>
                  <p>
                    <em>Approved Session Preferences :</em>
                  </p>
                  {/* {profile !== null &&
                    Object.keys(profile.preferences).length > 0 &&
                    profile.preferences.session_preferences.map((item) => (
                      <span>{item}</span>
                    ))} */}
                  {approvedPrefrences.map((item) => (
                    <div className="check-field">
                      <input
                        onChange={(e) => changeHandler(e, item)}
                        value={item.name}
                        id={`my-check-${item.id}`}
                        multiple
                        // selected={item.status}
                        checked={item.status === true ? "checked" : ""}
                        type="checkbox"
                        name="wantToDoSessions"
                      />
                      <label htmlFor={`my-check-${item.id}`}>{item.name}</label>
                    </div>
                  ))}
                  {/* <div className="check-field">
                    <input
                      onChange={changeHandler}
                      value="Starter Clients"
                      defaultChecked={checkDefault("Starter Clients")}
                      id="starterclient"
                      name="wantToDoSessions"
                      type="checkbox"
                    />
                    <label htmlFor="my-checktow" className="strclint">
                      Starter Clients
                    </label>
                  </div>
                  <div className="check-field">
                    <input
                      onChange={changeHandler}
                      value="Outreach Clients"
                      id="outreachclients"
                      type="checkbox"
                      defaultChecked={checkDefault("Outreach Clients")}
                      name="wantToDoSessions"
                    />
                    <label htmlFor="my-checktow" className="strclint">
                      Outreach Clients
                    </label>
                  </div>
                  <div className="check-field">
                    <input
                      onChange={changeHandler}
                      value="Paying Clients"
                      id="payingclient"
                      type="checkbox"
                      defaultChecked={checkDefault("Paying Clients")}
                      name="wantToDoSessions"
                    />
                    <label htmlFor="my-checktow">Paying Clients</label>
                  </div> */}
                  {/* </div>
                  </div>  */}
                </li>
                <li>
                  <div className="icon-detls">
                    <img src="../images/web.png" alt="web.png" />
                  </div>
                  <em>Payment methods :</em>

                  {profile !== null &&
                    Object.keys(profile.preferences).length > 0 &&
                    profile.preferences.payment_methods.map((item) => (
                      <span>{item.payment_name} </span>
                    ))}
                </li>
                <li>
                  <div className="icon-detls">
                    <img src="../images/web.png" alt="web.png" />
                  </div>
                  <em>Calendar type :</em>

                  {profile !== null &&
                    Object.keys(profile.morePreferences).length > 0 &&
                    profile.morePreferences.calendar_type !== null &&
                    profile.morePreferences.calendar_type.length &&
                    profile.morePreferences.calendar_type.map((item) => (
                      <span>{item} </span>
                    ))}
                </li>
                <li>
                  <div className="icon-detls">
                    <img src="../images/web.png" alt="web.png" />
                  </div>
                  <em>Preferred browser :</em>

                  {profile !== null &&
                    Object.keys(profile.morePreferences).length > 0 &&
                    profile.morePreferences.preferred_browser !== null &&
                    profile.morePreferences.preferred_browser.length &&
                    profile.morePreferences.preferred_browser.map((item) => (
                      <span>{item} </span>
                    ))}
                </li>
                <li>
                  <div className="icon-detls">
                    <img src="../images/web.png" alt="web.png" />
                  </div>
                  <em>Preferred device :</em>

                  {profile !== null &&
                    Object.keys(profile.morePreferences).length > 0 &&
                    profile.morePreferences.preferred_device !== null &&
                    profile.morePreferences.preferred_device.length &&
                    profile.morePreferences.preferred_device.map((item) => (
                      <span>{item} </span>
                    ))}
                </li>
                <li>
                  <div className="icon-detls">
                    <img src="../images/web.png" alt="web.png" />
                  </div>
                  <em>Zoom account type :</em>

                  {profile !== null &&
                    Object.keys(profile.morePreferences).length > 0 && (
                      <a href="javascipt:void(0)">
                        {profile.morePreferences.zoom_account_type}
                      </a>
                    )}
                </li>
                {/* <li>
                  <div className="icon-detls">
                    <img src="../images/web.png" alt="web.png" />
                  </div>
                  <em>Zoom personal link:</em>

                  {profile !== null &&
                    Object.keys(profile.morePreferences).length > 0 && (
                      <a href="javascipt:void(0)">
                        {profile.morePreferences.zoom_personal_link}
                      </a>
                    )}
                </li> */}
                <li>
                  <div className="icon-detls">
                    <img src="../images/web.png" alt="web.png" />
                  </div>
                  <em>Legal and Privacy :</em>
                  <p className="label-value">
                    {profile !== null &&
                      parseInt(profile.morePreferences.legal_privacy) === 1
                      ? "Checked"
                      : "Not Checked"}
                  </p>
                </li>
                {/* <li>
                  <div className="icon-detls">
                    <img src="../images/web.png" />
                  </div>
                  <em> Starter client guidelines:</em>
                  <a href="#">
                    {profile !== null &&
                      profile.general.agree_to_starter_client_guidelines == 1
                      ? "Checked"
                      : "Not Checked"}
                  </a>
                </li> */}
                <li>
                  <div className="icon-detls">
                    <img src="../images/web.png" alt="web.png" />
                  </div>
                  <em> Opt-In? :</em>
                  <p className="label-value">
                    {profile !== null &&
                      Object.keys(profile.morePreferences).length > 0 &&
                      (parseInt(profile.morePreferences.opt_in) === 0
                        ? "Opt In"
                        : "Opt Out")}
                  </p>
                </li>
                <li>
                  <div className="icon-detls">
                    <img src="../images/web.png" alt="web.png" />
                  </div>
                  <em>Code of ethics:</em>

                  {profile !== null &&
                    Object.keys(profile.morePreferences).length > 0 && (
                      <a href="javascipt:void(0)">
                        {parseInt(profile.morePreferences.code_of_ethics) === 1
                          ? "Checked"
                          : "Not Checked"}
                      </a>
                    )}
                </li>
                <li>
                  <div className="icon-detls">
                    <img src="../images/web.png" alt="web.png" />
                  </div>
                  <em>Operating principal :</em>

                  {profile !== null &&
                    Object.keys(profile.morePreferences).length > 0 && (
                      <a href="javascipt:void(0)">
                        {parseInt(
                          profile.morePreferences.operating_principal
                        ) === 1
                          ? "Checked"
                          : "Not Checked"}
                      </a>
                    )}
                </li>
              </ul>
            </div>
            <div className="profile-bio">
              <h2>About Me</h2>
              <p>{profile !== null && profile.general.biographical_info}</p>
            </div>
            <div class="profile-bio">
              <h2 className="mb-3">
                Client Reviews{" "}
                {profile !== null && profile.general.recommendation !== ""
                  ? `: ${profile.general.recommendation}% Recommend`
                  : " "}
              </h2>

              <div className="feedback-container">
                {profile !== null && profile.feedbacks.map((feedback) => (
                  <div className="feedback-border-feed mt-3 d-flex align-items-center ">
                    <div className="min-width-200px">
                      <div className="d-flex align-items-center">
                        <div className="feedback-image">
                          <img
                            className="practioner-profile-image"
                            src="../images/user-dummy.png"
                            alt="avatar"
                          />
                        </div>
                        <span className="ml-3">
                          {feedback.client_name} -{" "}
                          {JSON.parse(feedback.feedback).Country}
                        </span>
                      </div>
                      <div className="feedback-star-image">
                        <StarsRating
                          count={5}
                          value={parseInt(
                            JSON.parse(feedback.feedback).Star_Rating
                          )}
                          size={20}
                          color2={"#ffc107"}
                          edit={false}
                          color1={"#ffffff"}
                        />
                        {/* {Array.from(Array(5).keys()).map((item) => (
                        <>
                          {item + 1 <=
                          parseInt(
                            JSON.parse(feedback.feedback).Star_Rating
                          ) ? (
                            <img src={"../localImages/star.png"} />
                          ) : (
                            <img
                              src="../localImages/hollowstar.svg"
                              alt="white star"
                            />
                          )}
                        </>
                      ))} */}
                      </div>
                      <p className="feedback-date">{feedback.feedback_date}</p>
                    </div>
                    <div>
                      <span className="margin-unset line-height-unset feedback-heading">
                        {JSON.parse(feedback.feedback).Title}
                      </span>
                      <span className="margin-unset line-height-unset">
                        {JSON.parse(feedback.feedback).Publishable_Feedback}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
