import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import InfoPopup from "./infopopup";
// import BookingPopUp from "./bookingpopup";
import StarsRating from "stars-rating";

// import { stateToHTML } from 'draft-js-export-html';
// import draftToHtml from "draftjs-to-html";
import "./practitonerinfo.css";
import StripeForm from "../../../../../components/stripe";
import Paypal from "../../../../../components/paypal";
import Button from "../../../../../components/button";
import { getMediumServiceDetails, payWithPaypal, payWithSquareUp, payWithStripe } from "../../../../../services/client";
import { bo } from "@fullcalendar/core/internal-common";
import PaymentPrices from "./payment/payment";
import ThankyouPopup from "./payment/thankyou";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setPaymentMethodAvailbale, setPaymentTypeAvailbale } from "../../../../../reducer/clientsSlice";
import Prepaypostpaycheck from "../practitioners/subcomponents/prepaypostpaycheck";
export default function PractitionerInfo({
  PractitionerInfo,
  onClick,
  setRenderType,
  startBookaSessionLaterVideo = { startBookaSessionLaterVideo },
  startBookaSessionLaterQuestions,
  setBookInfo,
  getSubsessions
}) {
  const dispatch = useDispatch()
  // const contentState = PractitionerInfo.general.biographical_info !== "" ? JSON.parse(PractitionerInfo.general.biographical_info) : PractitionerInfo.general.biographical_info;
  //  console.log(contentState)
  // console.log('window.paypal',window.paypal)
  // const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
  const auth = useSelector((state) => state.user);
  // const html = draftToHtml(contentState);
  const [active, setActive] = useState([]);
  const [redirectionUrl, setredirectionUrl] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [paymentAmount, setPaymentAmount] = useState('20');
  const [professionalInfo, setProfessionalInfo] = useState()

  const [mediumSessionInfo, setMediumSessionInfo] = useState();
  const [userCurrency ,setUserCurrency] = useState('USD');
  // const openPopup = () => {
  //   window.loadModal2(`#BookingPopUp`, "show");
  // }

  const openMoreInfo = (info) => {
    console.log("admin info", [info]);
    setActive([info]);
    window.loadModal2("#infopopup", "show");
    return;
  };
  // useEffect(()=>{
  // window.loadModal2('#thankyoupopup','show')
  // },[])
  const calculateUnique = (arr) => {
    let value = arr.reduce((acc, val) => {
      acc[val] = acc[val] === undefined ? 1 : (acc[val] += 1);
      return acc;
    }, {});
    return value;
  };
  const calculateRating = (feedbacks) => {
    let total = 0;
    let sum_resp = 0;
    let counts = feedbacks.map((item) => {
      return parseInt(JSON.parse(item.feedback).Star_Rating);
    });

    Object.entries(calculateUnique(counts)).map(([key, value]) => {
      sum_resp += parseInt(value) * parseInt(key);
      total += parseInt(value);
    });
    let score = Math.round((sum_resp / total) * 2) / 2;
    return score;

    // return Math.floor(sum_resp / total);
  };
  const checkValue = (item) => {
    return [1, 2, 3, 4, 5].includes(item);
  };

  const paymentMethodHandler = () => {
  let curr = PractitionerInfo.general.session_currency !== undefined && PractitionerInfo.general.session_currency !== "" && PractitionerInfo.general.session_currency !== null
      ? Object.values(JSON.parse(PractitionerInfo.general.session_currency))[0]
      : "USD";
    console.log(paymentAmount)
    let body = {
      medium_id: PractitionerInfo.general.medium_id,
      amount: paymentAmount,
      currency:curr
    }
    if (paymentType === "paypal") {
      payWithPaypal(body).then((data) => {
        if (data.data.status) {

          //  window.loadModal2('#payment-price-popup','hide')
          // setredirectionUrl(data.data.data.approvedUrl)
          // setTimeout(() => {
          window.open(data.data.data.approvedUrl, '_self');
          //   window.loadModal2('#payment-redirection-url', 'show')
          // }, 2000)
          // setTimeout(() => {

          //   window.loadModal2('#payment-redirection-url', 'hide')
          // }, 15000)
        }
      }).catch(() => { })
    }
    else if (paymentType == "stripe") {
      payWithStripe(body).then((data) => {
        if (data.data.status) {
          window.open(data.data.data.redirectUrl, '_self');
          // console.log()
          // setredirectionUrl(data.data.data.redirectUrl)
          // setTimeout(() => {

          //   window.loadModal2('#payment-redirection-url', 'show')
          // }, 2000)
          // setTimeout(() => {

          //   window.loadModal2('#payment-redirection-url', 'hide')
          // }, 15000)
        }
      }).catch(() => { })

    }
    else {
      payWithSquareUp(body).then((data) => {
        if (data.data.status) {
          window.open(data.data.data.approvedUrl, '_self');
          // console.log()
          // setredirectionUrl(data.data.data.redirectUrl)
          // setTimeout(() => {

          //   window.loadModal2('#payment-redirection-url', 'show')
          // }, 2000)
          // setTimeout(() => {

          //   window.loadModal2('#payment-redirection-url', 'hide')
          // }, 15000)
        }
      }).catch(() => { })

    }



  }
  const getBookingLink = (info) => {
    localStorage.removeItem('offset');
    setProfessionalInfo(info);

    //live start 
    // if (checkPostPreType(info)) {
    //live end
    getMediumServiceDetails(info.medium_id)
      .then((data) => {
        if (data.data.status) {
          setMediumSessionInfo(data.data.data[0]);

          window.loadModal2("#Prepaypostpaycheck", "show");
        }
      })
      .catch(() => { });

    // startSeesion(info)
    // }
  };

  const startSeesion = (info) => {
    let inf = {
      general: {
        personal_booking_link: info.personal_booking_link,
        payType: info.payType,
        pre_pay_session_type: info.pre_pay_session_type,
        post_pay_session_type: info.post_pay_session_type,
        medium_id: info.medium_id,
        ...info
      },
    };
    setBookInfo(inf);
    setRenderType("newappointment");
    // {
    //   auth.user.terms_session_status === 1
    //     ? startBookaSessionLaterQuestions()
    //     : startBookaSessionLaterVideo();
    // }
  }

  const checkPostPreType = (info) => {

    let status = false;
    const { payType, post_pay_schedule, pre_pay_schedule, is_paypal_integrated, is_stripe_integrated } = info

    if (!is_paypal_integrated && !is_stripe_integrated) {
      toast.error('No Availability for this Medium', { position: 'top-center' })
      return;
    }
    else if (is_paypal_integrated && is_stripe_integrated) {
      dispatch(setPaymentMethodAvailbale('both'));
    }
    else if (is_paypal_integrated && !is_stripe_integrated) {
      dispatch(setPaymentMethodAvailbale('paypal'));
    }
    else if (!is_paypal_integrated && is_stripe_integrated) {
      dispatch(setPaymentMethodAvailbale('stripe'));
    }

    if (payType.length === 2) {
      if (!post_pay_schedule && !pre_pay_schedule) {
        // setPaytypeText('both post pay pre pay avialable for this medium')
        toast.error('No Availability for this Medium', { position: 'top-center' })
        return;
      }
      if (post_pay_schedule && pre_pay_schedule) {
        window.loadModal2('#Prepaypostpaycheck', 'show');
        // setPaytypeText('both post pay pre pay avialable for this medium')
        // toast.success('both post pay pre pay avialable for this medium', { position: 'top-center' })
        // status = true
      }
      else if (post_pay_schedule && !pre_pay_schedule) {
        window.loadModal2("#Prepaypostpaycheck", "show");
        // setPaytypeText('Only post pay avialable for this medium')
        dispatch(setPaymentTypeAvailbale('Pay After Session'))
        // getSubsessions(8)
        // status = true
        // toast.error('Only post pay avialable for this medium', { position: 'top-center' })
      }
      else if (!post_pay_schedule && pre_pay_schedule) {
        window.loadModal2("#Prepaypostpaycheck", "show");
        dispatch(setPaymentTypeAvailbale('Pay Before Session'))
        // status = true
        // setPaytypeText('Only pre pay avialable for this medium')
        // toast.error('Only pre pay avialable for this medium', { position: 'top-center' })
      }

      // else if (post_pay_schedule || pre_pay_schedule) {
      //   status = true
      // }
      else {
        // setPaytypeText('No schedule available for this medium')
        toast.error('No schedule available for this medium', { position: 'top-center' })
      }
    }
    else {
      if (payType[0] === 1 && pre_pay_schedule) {
        dispatch(setPaymentTypeAvailbale('Pay Before Session'))
        // getSubsessions(7)
        // setPaytypeText('only pre pay added and available')
        // toast.success('only pre pay added and available', { position: 'top-center' })
        status = true
      }
      // else if (payType[0] === 1 && !pre_pay_schedule) {
      //   setPaytypeText('No pre pay schedule available for this medium')
      //   toast.error('No pre pay schedule available for this medium', { position: 'top-center' })
      // }
      else if (payType[0] === 2 && post_pay_schedule) {
        dispatch(setPaymentTypeAvailbale('Pay Before Session'))
        // getSubsessions(7)
        // setPaytypeText('only post pay added and available')
        // toast.success('only post pay added and available', { position: 'top-center' })
        status = true
      }
      // else if (payType[0] === 2 && !post_pay_schedule) {
      //   setPaytypeText('No post pay schedule available for this medium')
      //   toast.error('No post pay schedule available for this medium', { position: 'top-center' })
      // }
      else {
        // setPaytypeText('No schedule available for this medium')
        toast.error('No Availability for this Medium', { position: 'top-center' })
      }
    }
    return status;
  }
  const checkWindowSize = () => {
    return window.innerWidth;
  }
  return (
    // <div className="schedule-advance-box-all-container">
    <div className={`schedule-advance-box-all  ${checkWindowSize() < 767 ? '' : 'schedule-advance-box-all-container'}`}>
      {console.log('i am here')}
      {console.log(PractitionerInfo, "PractitionerInfo")}
      <div class="single-profile-saction single-profile-saction-new">
        <div onClick={onClick} class="back-btn-profile">
          <div className="bac-href pointer">
            <img className="bac-img" src="images/arrow-back.png" alt="back" />
            Back to Search
          </div>
        </div>
        <div class="profile-all-box">
          <div class="banner-img-profile">
            <img src="images/profile-cover.png" alt="avatar" />
          </div>
          <div class="profile-info app-new-profile">
            <div class="profile-image">
              <img
                className="public-profile-picture"
                src={`${PractitionerInfo.general.profile_image !== ""
                  ? process.env.REACT_APP_IMAGE_URL +
                  PractitionerInfo.general.profile_image
                  : "/images/user-dummy.png"
                  }`}
                alt="avatar"
              />
            </div>
            <div class="profile-name">
              <div class="nam-prof">
                {PractitionerInfo.preferences.screen_name}
              </div>
              {/* <a href="#">{PractitionerInfo.preferences.screen_name}</a> */}
              <div class="star-rating">
                <StarsRating
                  count={5}
                  value={calculateRating(PractitionerInfo.feedbacks)}
                  size={20}
                  color2={"#ffc107"}
                  edit={false}
                  color1={"#FFFFFF"}
                />

                {/* {Array.from(Array(5).keys()).map((item) => (
                  <>
                    {item + 1 <=
                    parseInt(calculateRating(PractitionerInfo.feedbacks)) ? (
                      <img src={"localImages/star.png"} alt="star" />
                    ) : (
                      <img src="localImages/hollowstar.svg" alt="white star" />
                    )}
                  </>
                ))} */}
                {/* <span>0</span>{" "} */}
                {/* <img src="localImages/hollowstar.svg" alt="white star" />
                <img src="localImages/hollowstar.svg" alt="white star" />
                <img src="localImages/hollowstar.svg" alt="white star" />
                <img src="localImages/hollowstar.svg" alt="white star" />
                <img src="localImages/hollowstar.svg" alt="white star" /> */}
                {/* <em>0 Ratings</em> */}
              </div>
            </div>
          </div>

          {PractitionerInfo.general.show_flexbooker ?
            <div className="profile-book-now">
              <a
                href={PractitionerInfo.general.personal_booking_link !== "" ? PractitionerInfo.general.personal_booking_link : "javascript:void(0);"}
                //staging
                // onClick={() => getBookingLink(PractitionerInfo.general)}

                // live
                // onClick={
                //   PractitionerInfo.general.show_flexbooker ?
                //     PractitionerInfo.general.personal_booking_link !== ""
                //       ? startBookaSessionLaterVideo
                //       : () => { }
                //     : () => getBookingLink(PractitionerInfo.general)
                // }
                className="book-now-practice"
              >
                Book Here
              </a>
            </div>
            :
            PractitionerInfo.general.session_length !== undefined && PractitionerInfo.general.session_length !== "[]" && <div className="profile-book-now">
              {/* <button type="button" onClick={openPopup}>Book Now</button> */}

              <a
                href="javascript:void(0);"
                //staging
                // onClick={() => getBookingLink(PractitionerInfo.general)}

                // live
                onClick={
                  PractitionerInfo.general.show_flexbooker ?
                    PractitionerInfo.general.personal_booking_link !== ""
                      ? ''
                      : () => { }
                    : () => getBookingLink(PractitionerInfo.general)
                }
                className="book-now-practice"
              >
                Book Here
              </a>
              {/* <PayPalButton
              createOrder={(data, actions) => createOrder(data, actions)}
              onApprove={(data, actions) => onApprove(data, actions)}
            /> */}
            </div>}

          <div class="profile-details app-new-profile-details">
            <ul>
              <li>
                <div class="icon-detls">
                  <img src="images/chate.png" />
                </div>
                <div>
                  <em>Languages Available for Mediumship Sessions:</em>
                  <div className="mt-1">
                    {PractitionerInfo.general.languages.map((data) => (
                      <span>{data.lanugage_name}</span>
                    ))}
                  </div>
                </div>
              </li>
              {/* <li>
                <div class="icon-detls">
                  <img src="images/present.png" />
                </div>
                <em>Mediumship Specialities:</em>
                {PractitionerInfo.general.specialities.map((data) => (
                  <span>{data.specialty_name}</span>
                ))}
              </li> */}
              <li className="d-flex align-items-center">
                <div class="icon-detls">
                  <img src="images/word.png" />
                </div>
                <em>Country: </em>
                &nbsp; <a className="mt-1" href="#">{PractitionerInfo.general.country}</a>
              </li>
              {/* <li>
            
                <em>Payment:</em>
                <a href="#">{''}</a>
              </li> */}
              {/* <li>
                <div class="icon-detls">
                  <img src="images/web.png" />
                </div>
                <em>Website:</em>
                <a href="#">{PractitionerInfo.general.website}</a>
              </li> */}
            </ul>
          </div>
          {PractitionerInfo.general.biographical_info !== "" && <div class="profile-bio">
            <h2>About Me</h2>
            {/* {console.log(typeof html)} */}
            {/* <div dangerouslySetInnerHTML={{ __html: html }} /> */}
            <p>{(PractitionerInfo.general.biographical_info)}</p>
            {/* <div dangerouslySetInnerHTML={{ __html: JSON.parse(PractitionerInfo.general.biographical_info )}} /> */}
          </div>}

          <div class="profile-bio">
            <button
              type="button"
              class="btn btn-primary bg-theme-d5a67b height-35 d-block mb-5"
              data-toggle="modal"
              data-target=".bd-example-modal-lg"
            >
              About Our Reviews
            </button>

            <div
              class="modal fade bd-example-modal-lg mt-5"
              tabindex="-1"
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg modal-dialog-preview">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                      {" "}
                      About Our Reviews
                    </h5>
                    <button
                      type="button"
                      class="close about-us-review-close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body p-5">
                    <p>
                      {" "}
                      In an effort to be as transparent as possible, Mediums who
                      have completed a sufficient number of sessions can publish
                      recent reviews from clients (we remove identifying
                      information and confidential details). We don’t cherry
                      pick reviews or publish just the positive ones.
                    </p>
                    <br />
                    <p>
                      We hope you will recognize both the quality of our Mediums
                      and that mediumship is not an exact science and does not
                      work perfectly every time, even for the most experienced
                      Mediums. This balanced view of recent client experiences
                      should give you a sense of the Medium’s style and results.
                    </p>
                    <br />
                    <p>
                      We applaud our Mediums for choosing to publish their
                      reviews, blemishes and all, to help bring more
                      transparency to the mediumship profession.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <h2 className="mb-3">
              Client Reviews{" "}
              {PractitionerInfo.general.recommendation !== ""
                ? `: ${PractitionerInfo.general.recommendation}% Recommend`
                : " "}
            </h2>

            <div className="feedback-container">
              {PractitionerInfo.feedbacks.map((feedback) => (
                <div className="feedback-border-feed mt-3 d-flex align-items-center ">
                  <div className="min-width-200px">
                    <div className="d-flex align-items-center">
                      <div className="feedback-image">
                        <img
                          className="practioner-profile-image"
                          src="images/user-dummy.png"
                          alt="avatar"
                        />
                      </div>
                      <span className="ml-3">
                        {feedback.client_name} -{" "}
                        {JSON.parse(feedback.feedback).Country}
                      </span>
                    </div>
                    <div className="feedback-star-image">
                      <StarsRating
                        count={5}
                        value={parseInt(
                          JSON.parse(feedback.feedback).Star_Rating
                        )}
                        size={20}
                        color2={"#ffc107"}
                        edit={false}
                        color1={"#ffffff"}
                      />
                      {/* {Array.from(Array(5).keys()).map((item) => (
                        <>
                          {console.log(item, "itemitem")}
                          {item + 1 <=
                          parseInt(
                            JSON.parse(feedback.feedback).Star_Rating
                          ) ? (
                            <img src={"localImages/star.png"} />
                          ) : (
                            <img
                              src="localImages/hollowstar.svg"
                              alt="white star"
                            />
                          )}
                        </>
                      ))} */}
                    </div>
                    <p className="feedback-date">{feedback.feedback_date}</p>
                  </div>
                  <div>
                    <span className="margin-unset line-height-unset feedback-heading">
                      {JSON.parse(feedback.feedback).Title}
                    </span>
                    <span className="margin-unset line-height-unset">
                      {JSON.parse(feedback.feedback).Publishable_Feedback}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {!PractitionerInfo.general.is_pay_forward && (
            <div class="profile-bio">
              <h2>Pay With:</h2>
              <div className="payment-new-wrapper">
                <div className="payment-new-container">
                 {PractitionerInfo.general.is_paypal_integrated && <button
                    onClick={() => setPaymentType("paypal")}
                    data-toggle="modal"
                    data-target=".payment-price-popup"
                    className={`main-btn`}
                  >
                    {" "}
                    <img src={"/integrationimages/paypal.png"} />
                  </button>}
                 {PractitionerInfo.general.is_stripe_integrated && <button
                    onClick={() => setPaymentType("stripe")}
                    data-toggle="modal"
                    data-target=".payment-price-popup"
                    className={`main-btn`}
                  >
                    {" "}
                    <img
                      className="stripe-img"
                      src={"/integrationimages/stripe.png"}
                    />
                  </button>}
                 {PractitionerInfo.general.is_square_integrated && <button
                    onClick={() => setPaymentType("squareup")}
                    data-toggle="modal"
                    data-target=".payment-price-popup"
                    className={`main-btn`}


                  >
                    <img
                      className="stripe-square"
                      src={"/integrationimages/square-black.jpg"}
                    />
                  </button>}
                </div>
              </div>

              {/* <div>
              <Button
                className={"resend-btn w-unset height-45 min-width-130px"}
                status={0}
                onClick={() => paymentMethodHandler("stripe")}
                title="Pay With Stripe"
              />
            </div>
            <div className="mt-3">
              <Button
                className={"resend-btn w-unset height-45 min-width-130px"}
                status={0}
                onClick={() => paymentMethodHandler("paypal")}
                title="Pay With Paypal"
              />
            </div> */}
              {/* <div className="paypal-container">

              <Paypal />
            </div>
            <div class="paypal">
              <StripeForm />
            </div> */}

            </div>
          )}
        </div>
      </div>
      <div
        className="modal fade"
        id="payment-redirection-url"
        role="dialog"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-preview">
          {/* <button type="button" className="close" data-dismiss="modal">
        &times;
      </button> */}
          <div className="modal-content">
            <div className="modal-body">
              <div className="profile-video">
                {/* <img src="images/img-video.png" alt="img-video.png" /> */}
              </div>
              <h3>
                {/* <strong>{userDetails}</strong> */}
                Click Here to Pay
              </h3>
              <div className="bution-close-accept">
                <a
                  href={redirectionUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="join-btn pointer"
                >
                  Pay
                </a>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* <BookingPopUp item={PractitionerInfo} /> */}
      {console.log(professionalInfo, "rofessionalInfo !== undefined && professionalInfo.general")}
      <InfoPopup feedBackinfo={active} />
      <PaymentPrices paymentMethodHandler={paymentMethodHandler} setPaymentAmount={setPaymentAmount} />
      <Prepaypostpaycheck mediumSessionInfo={mediumSessionInfo} startSeesion={startSeesion} professionalInfo={professionalInfo} getSubsessions={getSubsessions} />

    </div>
  );
}
