import React, { useEffect, useState } from 'react'
import TimeInput from './times'
import { capatilizeLetter } from '../../../utils/helpers';
import { getTimeImage } from '../../../utils/utils';
import moment from "moment";
import { getAllEventDetails } from '../../../services/admin';
import { deleteSchedule } from './functions';
import { useDispatch } from 'react-redux';

export default function Schedules({ setStep ,openScheduleForm ,setEventId }) {
  const [scheduleList, setScheduleList] = useState([]);
  const dispatch = useDispatch();

 


  function groupBy(objectArray, property) {
    let array = objectArray.map((item) => {
      return { ...item, day: item.day };

      // return { ...item, day: item.day.toTitleCase() };
    });
    return array.reduce(function (accumulator, currentObject) {
      let key = currentObject[property];
      if (!accumulator[key]) {
        accumulator[key] = [];
      }
      accumulator[key].push(currentObject);
      return accumulator;
    }, {});
  }
  const [dateList, setDateList] = useState([]);
  useEffect(() => {
    setEventId("");
    getNewEvents();
  }, []);
  const getNewEvents = () => {
    getAllEventDetails().then((data) => {


      let grouped = data.data.data.map((item) => {
        console.log(groupBy(item.hours, "day"))
        return { ...item, slots: groupBy(item.hours, "day") };
      });

      console.log("grouped", grouped);
      setScheduleList(grouped);
      let dategrouped = data.data.data.map((item) => {
        return {
          ...item,
          slots: groupBy(item.hours, "date"),
        };
      });

      var sortedKeys = dategrouped.map((item) => {
        return Object.keys(item.slots)
          .sort()
          .reduce((objEntries, key) => {
            objEntries[key] = item.slots[key];

            return objEntries;
          }, {});
      });
      let sortedKeyse = data.data.data.map((item) => {
      const groupedByDate = item.specificDateSlots.reduce((acc, curr) => {
        const { date, day, start_time, end_time } = curr;
        
        // If the date doesn't exist in the accumulator, initialize it with an empty array
        if (!acc[date]) {
          acc[date] = [];
        }
        
        // Push the current time slot into the date group
        acc[date].push({ day, start_time, end_time ,date});
      
        return acc;
      }, {});
      console.log(groupedByDate,"groupedByDate")
      return groupedByDate
    })
    console.log(sortedKeyse,"sortedKeys")
      var specificdatesdata =
        Object.keys(sortedKeys).length > 0 &&
        Object.entries(sortedKeys).map(
          ([key, value]) =>
            // console.log('value..',value)
            Object.keys(value).length > 0 &&
            Object.entries(value).map(
              ([key1, value1]) =>
                // console.log('value1',value1)
                value1.length > 0 &&
                value1.filter((data, index) => {
                  return data.status === 0 && data.date !== null;
                })
            )
        );
      setDateList(sortedKeyse);

    })
  }
  const pushValuewithoutdate = (value) => {
    return value;
    console.log(value)
    let values = value.filter((data) => data.date === null);
    console.log(values)
    return values;
  };
  const checkTime = (time) => {
    let newTime = typeof time === 'string' ? time.trim() : time
    if (newTime === "11:59 PM") return "12:00 AM";
    return time;
  };
  const [blockeddateList, setBlockedDateList] = useState([]);
  return (
    <div className="medium-schedules-all white-background">
      <div className="d-flex align-items-center justify-content-between  font-size- mt-2 mb-2 flex-wrap">

        <div className="d-flex align-items-center gap-10">
          <div className="right-last-td">
            <div className="btn-sedule">
              <button
                className="margin-unset"
                type="button"
                onClick={() => setStep(false)}

              >
                <img src="images/web.png"></img>Add Event
              </button>
            </div>
          </div>

        </div>

      </div>

      <div className="table-mideum-table tableclander tableclander-schedules">
        <table>
          <tbody>
            <tr>
              <th className="action-button-schedules-table-th">Actions</th>
              <th>Name</th>
              <th>Hours</th>
              <th>Specific Dates</th>

              {/* <th> Schedule Info</th> */}

              {/* <th>Timezone</th> */}

            </tr>
            {scheduleList.map((item, dataIndex) => (
              <tr>
                <td>

                  <div className="right-last-td action-button-schedules-table">

                    <button
                      className="edit-button-availbility"
                      type="button"
                      onClick={() =>
                      openScheduleForm(item.id)
                      }
                      // onClick={() => setStep(false)}
                    >
                      <img src="images/icon-feather-edit.png"></img>Edit
                    </button>
                    <button
                      className="pointer remove-button-availbility"
                      onClick={() => deleteSchedule(
                        item.id,
                        dispatch,
                        getNewEvents
                      )}
                    >
                      <img src="images/close.svg"></img>Remove
                    </button>


                  </div>
                </td>
                <td>
                  {/* <span className="hours_valid">
                  {item.start_date !== null && item.end_date !== null
                    ? `Valid: ${item.start_date} to ${item.end_date}`
                    : `Valid: ${item.start_date} and on`}
                </span> */}
                  <div className='text-center font-size-22 width-345px pl-2 pr-2'>{item.title}</div>







                </td>
                <td>
                
                  {item.slots !== undefined &&
                    Object.keys(item.slots).length > 0 &&
                    Object.entries(item.slots).map(([key, value]) => (
                      <>
                        {/* <div className="fehfuhrf"> */}{" "}
                        {value.length > 0 &&
                          pushValuewithoutdate(value).map((data, index) => (
                            <>
                              {data.day !== null
                                ? data.start_time.slice(0, 2) !==
                                "00" &
                                data.end_time.slice(0, 2) !==
                                "00" && (
                                  <>
                                    <div
                                      className={`d-flex align-items-center ${pushValuewithoutdate(value)
                                        .length === 1
                                        ? "hr-schedule-dated"
                                        : ""
                                        } 
                                      ${pushValuewithoutdate(value).length -
                                          1 ===
                                          index &&
                                          pushValuewithoutdate(value)
                                            .length !== 1
                                          ? "hr-schedule-dated"
                                          : ""
                                        } 
                                      `}
                                    >
                                      <p className="schedule-day-name">
                                        {" "}
                                        {index === 0 && (
                                          <>
                                            {capatilizeLetter(
                                              key
                                            ).substring(0, 3)}{" "}
                                            &nbsp;
                                          </>
                                        )}
                                      </p>
                                      <span className="schedule-time-from-to">
                                        {checkTime(data.start_time)}{' '}{getTimeImage(
                                          checkTime(
                                            data.start_time
                                          )
                                        ) ? (
                                          <img
                                            src="localImages/moon.png"
                                            className="star-imh-moon"
                                            alt="time-image"
                                          />
                                        ) : (
                                          ""
                                        )}
                                        &nbsp;- &nbsp;
                                        {checkTime(data.end_time)}{' '}{getTimeImage(
                                          checkTime(
                                            data.end_time
                                          )
                                        ) ? (
                                          <img
                                            src="localImages/moon.png"
                                            className="star-imh-moon"
                                            alt="time-image"
                                          />
                                        ) : (
                                          ""
                                        )}
                                        {/* &nbsp;
                                    {data.date !== null && `(${data.date})`} */}
                                      </span>
                                    </div>
                                  </>
                                )
                                : ""}
                            </>
                          ))}
                        {/* </div> */}
                      </>
                    ))}
                </td>
                <td>

                {console.log(dateList[dataIndex], "item.slots")}
                  {dateList[dataIndex] !== undefined &&
                    Object.keys(dateList[dataIndex]).length > 0 &&
                    Object.entries(dateList[dataIndex]).map(
                      ([key, value]) => (
                        <>
                          {" "}
                          {key !== null &&
                            value.length > 0 &&
                            value.map((data, index) => (
                              <>
                                {data.date !== null 
                                  ? data.start_time.slice(0, 2) !==
                                  "00" &&
                                  data.end_time.slice(0, 2) !==
                                  "00" && (
                                    <>
                                      <div className="d-flex align-items-center">
                                        <p className="schedule-date-name">
                                          {" "}
                                          {index === 0
                                            ? `${moment(data.date).format(
                                              "DD MMM YYYY"
                                            )}`
                                            : ""}
                                        </p>
                                        <span className="schedule-time-from-to">
                                          {checkTime(
                                            data.start_time
                                          )}{" "}
                                          {getTimeImage(
                                            checkTime(
                                              data.start_time
                                            )
                                          ) ? (
                                            <img
                                              src="localImages/moon.png"
                                              className="star-imh-moon"
                                              alt="time-image"
                                            />
                                          ) : (
                                            ""
                                          )}
                                          &nbsp;- &nbsp;
                                          {checkTime(data.end_time)}
                                          &nbsp;
                                          {getTimeImage(
                                            checkTime(data.end_time)
                                          ) ? (
                                            <img
                                              src="localImages/moon.png"
                                              className="star-imh-moon"
                                              alt="time-image"
                                            />
                                          ) : (
                                            ""
                                          )}
                                       
                                        </span>
                                      </div>
                                      {index === value.length - 1 && (
                                        <>
                                          <br />
                                        </>
                                      )}
                                    </>
                                  )
                                  : ""}
                              </>
                            ))}
                        </>
                      )
                    )}
                </td>



              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    // <div className='d-flex justify-content-between w-70'>
    //   <div>
    //     <h3>Saturday</h3>
    //     <TimeInput />
    //   </div>
    //   <div>
    //     <h3>Sunday</h3>
    //     <TimeInput />
    //   </div>

    // </div>
  )
}
