import moment from "moment";
import React, { useEffect, useState } from "react";

export default function PartnerEndedPopup({ matchName, addMediumToQueueAgain }) {


    return (
        <>
 
        <div class="container-fluid question-container">
<div class="row justify-content-center">
    <div class="col-11 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
        <div class="card px-0 pt-4 pb-0 mt-3 mb-3">
            <form id="msform">
                <fieldset>
                    <div class="form-card">
                        <div class="row wrap">

                         
                             <h2 className="text-center font-size-30 mt-5">Sorry, {matchName} is no longer available.<br /> Let's find you another match.</h2>


                        </div>
                        {/* <div className='text-center mt-1 font-size-18'>
                            {hangon ? <> <i>*</i> Hang on we’re making a connection </> : ""}
                        </div> */}
                    </div>
                    {<div className='ml-4 mr-4 mt-4 mb-5 d-flex justify-content-center align-items-center choose-medium'>
                    
                            <button class="next action-button m-3"
                            type="button"
                                onClick={addMediumToQueueAgain}
                            >Okay</button>
                          
                    </div>}



                </fieldset>
            </form>
        </div>
    </div>
</div>
</div>
</>
    );
}
