import React from "react";
import "./filterOperator.css";


const FilterOperator = ({ operatorList = [], changeHandlerOprater, filterOprater, name }) => {
  return (
    <div className="filter-container ">
      <select
        value={filterOprater}
        className=""
        onChange={(e) => changeHandlerOprater(e)}
        name="filter"
      >
        <>
          <option value={""} selected disabled>
            {name}
          </option>
          {operatorList.map((item) => (
            <option value={item}>{item}</option>
          ))}
        </>
      </select>
    </div>
  );
};
export default FilterOperator;
