import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import moment from "moment";
import { setLoading, setLoadingContent, setLoadingText } from "../../../reducer/auth/auth";
import {
  UpcomingHeaders,
  list,
  completedHeaders,
  CancelledHeaders,
  NoShowsHeaders,
} from "./data";

import {
  setPreferences,
  setaAppointmentsList,
  setAllAppointments,
  setWindowObject,
  setAllUpcomingCount,
  setAllCompletedCount,
  setAllCancelledCount,
  setAllNoShowCount,
  setBlinkRequest,
  setbookedAppointmentData,
} from "../../../reducer/clientsSlice";

import {
  changeAppointmentStatus,
  getAllAppointments,
  getClientPreferences,
  setClientPreferences,
} from "../../../services/client";
import {
  getLanguages,
  getCountry,
  getSpeciality,
  getTimezones,
} from "../../../services/uidata";

import Select from "react-select";
import Button from "../../../components/button";
import { emptyAllSelectBox, filterVisibleTimeZones, getLink } from "../../../utils/helpers";
import { setTimezoneArray } from "../../../reducer/uidataSlice";
import { setNewTimeZone } from "../../../services/medium";
import { setNewTimeZoneData } from "../../../reducer/userSlice";
import Upcoming from "./subcomponents/upcoming";
import Completed from "./subcomponents/completed";
import Cancelled from "./subcomponents/cancelled";
import NoShows from "./subcomponents/noshows";
import Tabs from "../../../components/tabs";
import TabPanel from "../../../components/tabs/tabpanel";
import { toast } from "react-toastify";
import Pusher from "pusher-js";
import WaitingTimerPopup from "../appointment/subcomponents/waitingTimer";

const initialValues = {
  languages: [],
  countries: "",
  specialities: "",
};
export default function ClientAppointments() {
  const {
    appointmentsList,
    preferences,

    allUpcomingAppointments,
    allCompletedAppointments,
    allCancelledAppointments,
    allNoShowsAppointments,

    allUpcomingCount,
    allCompletedCount,
    allCancelledCount,
    allNoShowCount,
    blinkRequest
  } = useSelector((state) => state.client);
  const { timezone } = useSelector((state) => state.user.user);
  const { timezoneArray } = useSelector((state) => state.uidata);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(0);
  const [upcomingAppointment, setUpcomingAppointments] = useState([]);
  const [completedAppointment, setCompletedAppointments] = useState([]);
  const [canceledAppointment, setCanceledAppointments] = useState([]);
  const [showAppointment, setShowAppointments] = useState([]);
  const [listArray, setListArray] = useState(list);
  const [appoinmentsData, setAppoinmentsData] = useState([]);
  const [timezoneData, setTimezoneData] = useState([]);
  const [backuupcoming, setbackupUpcoming] = useState([]);
  const [backuupcompleted, setbackupCompleted] = useState([]);
  const [backuupcancelled, setbackupCancelled] = useState([]);
  const [backuupnoshow, setbackupUpNoShow] = useState([]);

  const [filterValues, setFilterValues] = useState()
  const [searchValue, setSearchValues] = useState()
  const [counterStatus, setCounterStatus] = useState(false);

  const [blink, setBlink] = useState('');
  const [paymentMessagarType, setPaymentMessagarType] = useState('');

  useEffect(() => {
  localStorage.removeItem('appoinmentId')
    if (searchParams.get("payment_status") !== null) {

      if (searchParams.get("payment_status") === "success") {
        setPaymentMessagarType('success');
      }
      else {
        setPaymentMessagarType('failed');
      }
      setTimeout(() => {
        const newUrl = new URL(window.location.href);
        newUrl.searchParams.delete("payment_status");
        // setQueryValue(newUrl.searchParams.delete("payment_status"))
        window.history.replaceState({}, document.title, newUrl.href);
      }, 2000)
    }
    //


    //



    if (blinkRequest === "givefeedback" || blinkRequest === "paymedium") {
      setActiveTab(1)
    }
    setBlink(blinkRequest)
    dispatch(setBlinkRequest(''))

    // window.removeBackdrop();
    launcApi();

  }, []);

  useEffect(() => {
    if (counterStatus) {
      // window.loadModal2('#WaitingTimerPopup', 'show');
      Pusher.logToConsole = true;

      var pusher = new Pusher("823ab72ed6ecd563b8c8", {
        cluster: "ap2",
      });

      var channel = pusher.subscribe("notify-client-payment");
      channel.bind("App\\Events\\NotifyClientAboutPayment", function (data) {
        console.log(data.appointment, "data.appointment)");
        // dispatch(setbookedAppointmentData(data.appointment));
        // window.loadModal2('#WaitingTimerPopup', 'hide');
        launcApi()
        setTimeout(() => {
          setCounterStatus(false);
        }, 2000);
        // setCounterStatus(false);
        // window.loadModal("#advanceConfirmbooking", "open");
        // appointment
        // : 
        // appointment_id
        // : 
        // 92
        // date
        // : 
        // "2023-06-24"
        // medium_name
        // : 
        // "Sumit C"
        // meeting_link
        // : 
        // "https://us05web.zoom.us/j/81170517832?pwd=VGdIWStZcmpkeGVCOXRUV1VaYjRjQT09"
        // time
        // : 
        // "06:00 AM"
      });
    }
  }, [counterStatus])
  const launcApi = () => {
    const data = {
      page: 1,
      rowsPerPage: 10,
    };
    // if (
    //   !allUpcomingAppointments.length > 0 ||
    //   !allCompletedAppointments.length > 0 ||
    //   !allCancelledAppointments.length > 0 ||
    //   !allNoShowsAppointments.length > 0
    // ) {
    getAllAppointmentsList(data, activeTab);
    // }
  };

  const getPaginationData = (data, activeTab) => {
    getAllAppointmentsList(data, activeTab);
  };
  const setListedArray = (item, set) => {
    const values = item.map((resp) => {
      return {
        ...resp,
        status: false,
      };
    });

    set(values);
  };
  // useEffect(() => {
  //   const data = {
  //     page: 1,
  //     rowsPerPage: 10,
  //   };
  //   console.log("activeTab", activeTab);
  //   getAllAppointmentsList(data, activeTab);
  // }, [activeTab]);
  useEffect(() => {
    setListedArray(allUpcomingAppointments, setUpcomingAppointments);
    setListedArray(allCompletedAppointments, setCompletedAppointments);
    setListedArray(allCancelledAppointments, setCanceledAppointments);
    setListedArray(allNoShowsAppointments, setShowAppointments);
    setListArray(
      list.map((item) => {
        if (item.name === "Upcoming") {
          return {
            ...item,
            ...(item.count = allUpcomingCount),
          };
        }
        if (item.name === "Completed") {
          return {
            ...item,
            ...(item.count = allCompletedCount),
          };
        }

        if (item.name === "Cancelled") {
          return {
            ...item,
            ...(item.count = allCancelledCount),
          };
        }

        if (item.name === "No Shows") {
          return {
            ...item,
            ...(item.count = allNoShowCount),
          };
        }
      })
    );
  }, [
    allCompletedAppointments,
    allUpcomingAppointments,
    allCancelledAppointments,
    allNoShowsAppointments,
  ]);

  const getAllAppointmentsList = (paginationdata, activeTab, info) => {
    if (info === "refresh") {
      // setUpcomingAppointments([]);
      // setCanceledAppointments([]);
      // setCompletedAppointments([]);
      // setShowAppointments([]);
    }
    let appointmentType = "";
    if (activeTab === 0) {
      appointmentType = "upcoming";
    }
    if (activeTab === 1) {
      appointmentType = "completed";
    }
    if (activeTab === 2) {
      appointmentType = "cancelled";
    }
    if (activeTab === 3) {
      appointmentType = "show";
    }
    if (activeTab === 4) {
      appointmentType = "ongoing";
    }
    getAllAppointments(paginationdata, appointmentType)
      .then((data) => {
        if (data.data.status) {
          dispatch(
            setAllUpcomingCount(
              JSON.parse(
                JSON.stringify(data.data.data.upcoming_pagination.total)
              )
            )
          );

          dispatch(
            setAllCompletedCount(
              JSON.parse(
                JSON.stringify(data.data.data.completed_pagination.total)
              )
            )
          );
          console.log("data..", data.data.data.completed_pagination.total);
          dispatch(
            setAllCancelledCount(
              JSON.parse(
                JSON.stringify(data.data.data.cancelled_pagination.total)
              )
            )
          );
          dispatch(
            setAllNoShowCount(
              JSON.parse(JSON.stringify(data.data.data.show_pagination.total))
            )
          );
          dispatch(setAllAppointments(data.data.data));
          setbackupUpcoming(
            JSON.parse(JSON.stringify(data.data.data.upcoming))
          );
          setbackupCompleted(
            JSON.parse(JSON.stringify(data.data.data.completed))
          );
          setbackupCancelled(
            JSON.parse(JSON.stringify(data.data.data.cancelled))
          );
          setbackupUpNoShow(JSON.parse(JSON.stringify(data.data.data.show)));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeStatus = (userId, status, appointmentId, type, ids) => {
    dispatch(setLoadingText(true));
    dispatch(setLoadingContent('<div>Please wait, We are updating your appointment</div>'));
    console.log(type, ids);
    let body;
    // if (type === 'client' && ids === "client_id") {
    //   body = {
    //     client_id: userId,
    //     status: status,
    //     appointmentId: appointmentId,
    //   };
    // } else {
    body = {
      userId: userId,
      status: status,
      appointmentId: appointmentId,
    };

    // console.log(userId, status, appointmentId, type, ids);
    // console.log(upcomingAppointment, "setUpcomingAppointments");
    // let filteredArray = upcomingAppointment.filter(
    //   (item) => item.appointmentId !== appointmentId
    // );
    // setUpcomingAppointments(filteredArray);
    // dispatch(setLoading(false));
    // let filterCounter = listArray.map((item) => {
    //   if (item.name === "Upcoming") {
    //     return {
    //       ...item,
    //       count: item.count - 1,
    //     };
    //   } else {
    //     return {
    //       ...item,
    //     };
    //   }
    // });
    // console.log(userId, status, appointmentId, type, ids);
    // setTimeout(() => {
    //   setListArray(filterCounter);
    // }, 1000);


    changeAppointmentStatus(body)
      .then((data) => {
        emptyAllSelectBox()
        setTimeout(() => {
          dispatch(setLoadingText(false));
          dispatch(setLoadingContent(''));
        }, 1000);
        console.log(data)
        if (data.data.status) {
          setTimeout(() => {
            if (type === "client") {
              toast.success("Appointment status updated succesfully", {
                position: "top-center",
              });
            } else {
              toast.success("Appointment cancelled succesfully", {
                position: "top-center",
              });
            }
          }, 1000);
          const info = {
            page: 1,
            rowsPerPage: 10,
          };
          getAllAppointmentsList(info, activeTab, "refresh");
        }
      })
      .catch(() => {
        dispatch(setLoadingText(false));
        dispatch(setLoadingContent(''));
      });
  };

  const changeHandler = (e, status, appointmentId, id, type, ids) => {
    changeStatus(id, status, appointmentId, type, ids);
  };

  const onClickHandler = (e, id, name) => {
    e.stopPropagation();
    if (name === "upcoming") {
      const response = upcomingAppointment.map((user) => {
        if (user.appointmentId === id) {
          user.status = !user.status;
        } else {
          user.status = false;
        }
        return user;
      });
      setUpcomingAppointments(response);
    }
    if (name === "completed") {
      const response = completedAppointment.map((user) => {
        if (user.appointmentId === id) {
          user.status = !user.status;
        } else {
          user.status = false;
        }
        return user;
      });
      setCompletedAppointments(response);
    }
    // const responsee = upcomingAppointment.map((user) => {
    //   return {
    //     ...user,
    //     status: false,
    //   };
    // });
    // setTimeout(() => {
    //   setUpcomingAppointments(responsee);
    // }, 8000);
  };

  useEffect(() => {
    setAppoinmentsData(appointmentsList);
  }, ["", appointmentsList]);

  const onClickHandlerNavigate = () => {
    localStorage.removeItem('activesession_id')
    localStorage.removeItem('type');
    localStorage.removeItem('offset');
    navigate("/appointment");
  };

  useEffect(() => {
    if (!timezoneArray.length) updateTimezones();
  }, []);
  useEffect(() => {
    setTimezoneData(filterVisibleTimeZones(timezoneArray));
  }, [timezoneArray]);

  const updateTimezones = () => {
    getTimezones()
      .then((data) => {
        dispatch(setTimezoneArray(data.data.data));
      })
      .catch((err) => console.log(err));
  };
  const changeTimeZoneHandler = (e) => {
    dispatch(setLoading(true))
    let id = e.target.value;
    let body = {
      timezone_id: id,
    };
    setNewTimeZone(body)
      .then((data) => {
        dispatch(setLoading(false))
        if (data.data.status) {
          getAppointments();
          dispatch(setNewTimeZoneData(filterTimeZone(id)));
        }
      })
      .catch(() => { dispatch(setLoading(false)) });
  };
  const filterTimeZone = (id) => {
    let zone;
    timezoneData.filter((item) => {
      if (parseInt(id) === parseInt(item.id)) {
        zone = item.front_name;
      }
    });
    console.log(zone);
    return zone;
  };
  const renderRedirect = () => {
    navigate("/appointment");
  };
  const getAppointments = () => {
    const info = {
      page: 1,
      rowsPerPage: 10,
    };
    getAllAppointmentsList(info, activeTab, "refresh");
    // getAllAppointments()
    //   .then((data) => {
    //     dispatch(setLoading(false));
    //     if (data.data.status) {
    //       dispatch(setaAppointmentsList(data.data.data));
    //     }
    //   })
    //   .catch(() => {
    //     dispatch(setLoading(false));
    //   });
  };
  // const copyMeetingLink = (url) => {
  //   navigator.clipboard.writeText(url);
  // };
  const openSurveyLink = (item) => {
    window.open(item, "_blank");
  };

  return (
    <>
      <div className="dashboard">
        {paymentMessagarType === "success" && <div class="alert alert-success" role="alert">
          Your payment is processed successfully.
        </div>}
        {paymentMessagarType === "failed" && <div class="alert alert-danger" role="alert">
          Your payment is not processed, Please try again.
        </div>}
        <div className="row">
          <div className="col-md-12">
            {/* <div className="text-center font-size-17">
              Please check your email for Appointment information until this part
              of our system is ready.
            </div> */}
            <div className="my-ppointments-box appoint-das all-medium-page">
              <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">


                <div className="button-cla unset-float  mb-3 w-unset">
                  <button
                    onClick={onClickHandlerNavigate}
                    type="button"
                    className="btn btn-info btn-lg"
                  >
                    <img src="images/clander.png" alt="clander" />
                    New Appointment
                  </button>
                </div>
                <div className="middle-screen-name time-zone-area font-size-22 w-unset time-zone-area-client text-align-unset">
                  {" "}
                  <select
                    onChange={changeTimeZoneHandler}
                    className="select-box-timezone pointer"
                  >
                    <option disabled selected value="">
                      Change Timezone
                    </option>
                    {timezoneData.map((item) => (
                      <option value={item.id}>
                        {item.front_name}
                      </option>
                    ))}
                  </select>
                  <b>Timezone: {timezone !== undefined && timezone}&nbsp;</b>
                </div>
              </div>
              <div className="appoinments-table">


                <Tabs active={activeTab} list={listArray} setActiveTab={setActiveTab} />
                <ul className="tabs-content">
                  <TabPanel id={0} activeTab={activeTab}>
                    <Upcoming
                      tableData={upcomingAppointment}
                      changeHandler={changeHandler}
                      onClickHandler={onClickHandler}
                      tableHeaders={UpcomingHeaders}
                      openSurveyLink={openSurveyLink}
                      getPaginationData={(data) =>
                        getPaginationData(data, activeTab)
                      }
                      getAllAppointmentsList={getAllAppointmentsList}
                      activeTab={activeTab}
                      blinkRequest={blink}
                      setFilterValues={setFilterValues}
                      setSearchValues={setSearchValues}
                      setCounterStatus={setCounterStatus}
                    />
                    {/* <Upcoming
            upcomingAppointments={upcomingAppointment}
            changeHandler={changeHandler}
            onClickHandler={onClickHandler}
            filterListAppointments={filterListAppointments}
          /> */}
                  </TabPanel>
                  <TabPanel id={1} activeTab={activeTab}>
                    <Completed
                      tableData={completedAppointment}
                      changeHandler={changeHandler}
                      onClickHandler={onClickHandler}
                      tableHeaders={completedHeaders}
                      openSurveyLink={openSurveyLink}
                      getPaginationData={(data) =>
                        getPaginationData(data, activeTab)
                      }
                      blinkRequest={blink}
                      setFilterValues={setFilterValues}
                      setSearchValues={setSearchValues}
                      setCounterStatus={setCounterStatus}
                    />
                    {/* <Completed
            completedAppointments={completedAppointment}
            changeHandler={changeHandler}
            onClickHandler={onClickHandler}
            filterListAppointments={filterListAppointments}
          /> */}
                  </TabPanel>
                  <TabPanel id={2} activeTab={activeTab}>
                    <Cancelled
                      tableData={canceledAppointment}
                      changeHandler={changeHandler}
                      onClickHandler={onClickHandler}
                      tableHeaders={CancelledHeaders}
                      openSurveyLink={openSurveyLink}
                      getPaginationData={(data) =>
                        getPaginationData(data, activeTab)
                      }
                      blinkRequest={blink}
                      setFilterValues={setFilterValues}
                      setSearchValues={setSearchValues}
                    />
                    {/* <Cancelled
            canceledAppointment={canceledAppointment}
            changeHandler={changeHandler}
            filterListAppointments={filterListAppointments}
          /> */}
                  </TabPanel>
                  <TabPanel id={3} activeTab={activeTab}>
                    <NoShows
                      tableData={showAppointment}
                      changeHandler={changeHandler}
                      tableHeaders={NoShowsHeaders}
                      openSurveyLink={openSurveyLink}
                      getPaginationData={(data) =>
                        getPaginationData(data, activeTab)
                      }
                      blinkRequest={blink}
                      setFilterValues={setFilterValues}
                      setSearchValues={setSearchValues}
                    />
                    {/* <Table
                    tableData={appoinmentsData.show
                    }
                    changeHandler={""}
                    onClickHandler={""}
                    tableHeaders={UpcomingHeaders}
                    openSurveyLink={openSurveyLink}
                  /> */}
                    {/* <NoShows
            showAppointment={showAppointment}
            changeHandler={changeHandler}
            filterListAppointments={filterListAppointments}
          /> */}
                  </TabPanel>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {counterStatus && (<WaitingTimerPopup />)} */}
    </>
  );
}
