import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

export default function TabItem({ list, setActiveTab }) {
  const { linkZoomRequest } = useSelector((state) => state.medium);

  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    fillTabs();
  }, []);

  useEffect(() => {

   if(linkZoomRequest) activeTabHandler(2)

  }, [linkZoomRequest]);
  const fillTabs = () => {
    setTabs(
      list.map((item, index) => {
        if (index === 0) {
          return {
            ...item,
            active: true,
          };
        } else {
          return {
            ...item,
            active: false,
          };
        }
      })
    );
  };

  const activeTabHandler = (tabIndex) => {
    setActiveTab(tabIndex);
    setTabs(
      list.map((item, index) => {
        if (parseInt(tabIndex) === parseInt(index)) {
          return {
            ...item,
            active: true,
          };
        } else {
          return {
            ...item,
            active: false,
          };
        }
      })
    );
  };
  return (
    <ul className="nav nav-tabs" role="tablist">
      {React.Children.toArray(
        tabs.map((item, index) => (
          <li
            onClick={() => activeTabHandler(index)}
            role="presentation"
            className={`${item.active ? "active" : "disabled"}`}
          >
            <a
              href="#step1"
              data-toggle="tab"
              aria-controls="step1"
              role="tab"
              aria-expanded="true"
            >
              <div className="icon-step">
                <img src={item.imgDark} className="black" alt="icon" />
                <img src={item.imgLight} className="wight"  alt="icon"/>
              </div>
              <span className="round-tab">{item.name}</span>
            </a>
          </li>
        ))
      )}
    </ul>
  );
}
