import React, { useEffect, useState } from "react";
import { updateFeedbackByAdmin } from "../../../../../services/admin";
import { toast } from "react-toastify";

export default function EditFeedback({ editFeedbackDetail, callBack }) {
    const [additionalFeedback, setAdditionalFeedback] = useState("");
    const [meaningfulExperience, setMeaningfulExperience] = useState("");
    const [overallImpression, setOverallImpression] = useState("");
    const [
        recognizedMyLovedOneFromEvidence,
        setrecognizedMyLovedOneFromEvidence,
    ] = useState("");
    const [recommendTOthers, setRecommendToOthers] = useState("");
    const [feedbackId, setFeedbackId] = useState('')

    //effects
    useEffect(() => {
        if (editFeedbackDetail !== undefined) {
            let feedback;
            let id;
            if ((Object.keys(editFeedbackDetail).length) === 1) {
                feedback = Object.values(editFeedbackDetail)[0].feedback;
                id = Object.values(editFeedbackDetail)[0].feedback_id
            }
            else {
                feedback = Object.values(editFeedbackDetail)[1].feedback;
                id = Object.values(editFeedbackDetail)[1].feedback_id
            }
            console.log(feedback,"feedback")

            setAdditionalFeedback(feedback.additional_feedback);
            setMeaningfulExperience(feedback.meaningful_experience);
            setOverallImpression(feedback.overall_impression);
            setrecognizedMyLovedOneFromEvidence(
                feedback.recognized_my_loved_one_from_evidence
            );
            setRecommendToOthers(feedback.recommend_To_others);
            setFeedbackId(id)
        }
    }, [editFeedbackDetail]);

    const onChangeHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let check = (value > 0 && value < 6 || value === "")
        if (name === "additionalFeedback") {

            setAdditionalFeedback(value);

        }
        if (name === "meaningfulExperience" && check) {
            setMeaningfulExperience(value);
        }
        if (name === "overallImpression") {
            setOverallImpression(value);
        }
        if (name === "recognizedMyLovedOneFromEvidence" && check) {
            setrecognizedMyLovedOneFromEvidence(value);
        }
        if (name === "recommendTOthers" && check) {
            setRecommendToOthers(value);
        }
    };

    const onCancelHandler = () => {
        window.loadModal2("#editFeedback", "hide");
    };

    const updateHandler = () => {
        let body = {
            feedback_id: feedbackId,
            recognized_my_loved_one_from_evidence: recognizedMyLovedOneFromEvidence,
            meaningful_experience: meaningfulExperience,
            recommend_To_others: recommendTOthers,
            overall_impression: overallImpression,
            additional_feedback: additionalFeedback,
        }

        updateFeedbackByAdmin(body)
            .then((data) => {
                if (data.data.status) {
                    toast.success('Feedback updated succesfully', { position: 'top-center' })
                    callBack()
                    window.loadModal2('#editFeedback', 'hide');
                }
            })
            .catch(() => {
                toast.error('Error', { position: 'top-center' })
            });
    };
    return (
        <div
            className="modal fade "
            id="editFeedback"
            // data-backdrop="static"
            role="dialog"
        >
            <div className="modal-dialog  modal-dialog-preview">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="schedule-popup">
                            {/* <img src="../images/new-cll.png" alt="user" /> */}

                            <h3 className="mb-4 text-center ">Feedback</h3>
                            <div>
                                <div className="from-fild mt-2 mb-4">
                                    <label htmlFor="firstName">
                                        Recognized My Loved One From Evidence
                                    </label>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <input
                                            onChange={onChangeHandler}
                                            value={recognizedMyLovedOneFromEvidence}
                                            type="number"
                                            id="recognizedMyLovedOneFromEvidence"
                                            name="recognizedMyLovedOneFromEvidence"
                                            placeholder="Recognized My Loved One From Evidence"
                                            min={1}
                                            max={5}
                                        />
                                    </div>
                                </div>
                                <div className="from-fild mt-2 mb-4">
                                    <label htmlFor="firstName">Meaningful Experience</label>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <input
                                            onChange={onChangeHandler}
                                            value={meaningfulExperience}
                                            min={1}
                                            max={5}
                                            type="number"
                                            id="meaningfulExperience"
                                            name="meaningfulExperience"
                                            placeholder="Meaningful Experience"
                                        />
                                    </div>
                                </div>


                                <div className="from-fild mt-2 mb-4">
                                    <label htmlFor="firstName">Recommend To Others</label>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <input
                                            onChange={onChangeHandler}
                                            value={recommendTOthers}
                                            min={1}
                                            max={5}
                                            type="number"
                                            id="recommendTOthers"
                                            name="recommendTOthers"
                                            placeholder="Recommend To Others"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="from-fild mt-2 mb-4">
                                <label htmlFor="firstName">Overall Impression</label>
                                <div className="d-flex align-items-center justify-content-center">
                                    <input
                                        onChange={onChangeHandler}
                                        value={overallImpression}
                                        type="text"
                                        id="overallImpression"
                                        name="overallImpression"
                                        placeholder="Overall Impression"
                                    />
                                </div>
                            </div>
                            <div className="from-fild mt-2 mb-4">
                                <label htmlFor="firstName">Additional Feedback</label>
                                <div className="d-flex align-items-center justify-content-center">
                                    <input
                                        onChange={onChangeHandler}
                                        value={additionalFeedback}
                                        type="text"
                                        id="additionalFeedback"
                                        name="additionalFeedback"
                                        placeholder="Additional Feedback"
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div
                                    className="advance-tag-new pointer text-center width-150px model-close-btn"
                                    onClick={onCancelHandler}
                                >
                                    Cancel
                                </div>
                                <div
                                    className="advance-tag pointer text-center width-150px"
                                    onClick={updateHandler}
                                >
                                    Update
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
