import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import API from "../../../api/api";
import { setLoading, setRole, setUpAuth } from "../../../reducer/auth/auth";
import { fetchUser, login, starterSignupSurvey } from "../../../services/auth";
import { setUser } from "../../../reducer/userSlice";
import { userType } from "../../../utils/utils";
import Slider from "../../../components/slider";
import PrivacyModal from "./privacymodal";
import { sendVerificationCode } from "../../../services/auth";
import "./client.css";
import { getBrowserData, innerWidthHandler } from "../../../utils/helpers";
import StarterQuestion from "./starterquestions";
import Vimeo from "@u-wave/react-vimeo";

const initialValues = {
  email: "",
  promotion_code: "",
  password: "",
  confirmPassword: "",
  check: false,
  is_client_medium: false,
};
const ClientRegister = () => {
  const dispatch = useDispatch();

  const [emailError, setEmailError] = useState("");
  const [emailSuccess, setEmailSuccess] = useState("");
  const [typePass, SetTypePass] = useState(true);
  const [confirmtypePass, SetTypeconfirmPass] = useState(true);
  const [registrationype, setRegistrationType] = useState("");
  const [formValues, setFormValues] = useState({});

  const [about_verysoul, setAbout_verysoul] = useState("");
  const [past_experience, setPast_experience] = useState("");
  const [medium_psychic, setMedium_psychic] = useState("");
  const [help_text, setHelp_text] = useState(null);
  const [otherValue, setOtherValue] = useState("");
  const [userId, setUserId] = useState("");
  const [invalidCode, setInvalidCode] = useState("");

  const navigate = useNavigate();

  const changePassType = (e) => {
    SetTypePass(!typePass);
  };
  const changePassTypeConfirm = (e) => {
    SetTypeconfirmPass(!confirmtypePass);
  };

  const submitFunc = () => {
    let body = {
      user_id: userId,
      about_verysoul: about_verysoul,
      past_experience:
        past_experience === "Other" ? otherValue : past_experience,
      medium_psychic: medium_psychic,
      help_text: help_text,
    };
    starterSignupSurvey(body)
      .then((data) => {
        if (data.data.status) {
          window.loadModal2("#starter-client-question", "hide");
          LoginUser(formValues);
        }
      })
      .catch(() => { });

    // RegisterUser(formValues, "submit");
  };

  useEffect(() => {
    // window.loadModal2("#starter-client-question", "show");
    const urlString = window.location.href;
    let paramString = urlString.split("?")[1];
    let queryString = new URLSearchParams(paramString);
    console.log(queryString.entries(), "queryString");
    let info = [];
    let key = [];
    for (let pair of queryString.values()) {
      info.push(pair);
    }
    for (let pair of queryString.keys()) {
      key.push(pair);
    }
    // if(info[0] ===)

    if (key[0] == "referral_code") {
      initialValues.promotion_code = info[0];
    }
    if (info[1] !== undefined) {
      setRegistrationType(info[0]);
      localStorage.setItem("guest_id", info[1]);
    }
    window.loadCarousel();
  }, []);

  const RegisterUser = async (formData, type) => {
    setInvalidCode("");
    // window.loadModal2("#starter-client-question", "show");
    // return;
    dispatch(setLoading(true));
    const {
      email,
      check,
      promotion_code,
      is_client_medium,
      password,
      // confirmPassword,
    } = formData;

    let item = {
      promotion_code: promotion_code,
      email: email,
      password: password,
      // password_confirmation: confirmPassword,
      usertype: 3,
      is_adult: check ? 1 : 0,
      is_client_medium: is_client_medium ? 1 : 0,
      type: registrationype === "true" ? "guest-booking" : "",
    };
    let browserinfo = await getBrowserData();
    Object.assign(item, browserinfo);
    await API.post("register", item)
      .then((data) => {
        dispatch(setLoading(false));

        if (data.data.status) {
          // setFormValues(formData);
          // setUserId(data.data.data.id);
          // if (formData.promotion_code !== "" && type !== "submit") {
          //   setEmailError('')
          //   setEmailSuccess('Registered Successfully');
          //   window.loadModal2("#starter-client-question", "show");
          //   return;
          // }
          LoginUser(formData);
          setEmailSuccess("");
        }
        if (!data.data.status && data.data.error.email) {
          dispatch(setLoading(false));
          setEmailSuccess("");
          setEmailError("This email address already exists in our system.");
        }
        if (
          !data.data.status &&
          data.data.error &&
          data.data.error === "Your Account is Restricted/Freezed"
        ) {
          setEmailError(
            "You are not allowed to create new accounts. Please contact support for more help."
          );
          setEmailSuccess("");
        }
        if (
          !data.data.status &&
          data.data.error &&
          data.data.error ===
          "Invalid referral code please remove and continue."
        ) {
          console.log(data, "erroe");
          setInvalidCode(data.data.error);
          setEmailError("");
          setEmailSuccess("");
        }
      })
      .catch((error) => {
        dispatch(setLoading(false));
        console.log(error);
      });
  };
  const redirectRoute = (item) => {
    navigate(item);
  };

  const callProfile = async (token) => {
    dispatch(setLoading(true));
    await fetchUser(token)
      .then((data) => {
        dispatch(setLoading(false));
        dispatch(setUser(data.data.data));
        sendVerificationCodeHandler();
        // localStorage.setItem("user", JSON.stringify(data.data.data));
        // localStorage.setItem("medium_status", data.data.data.medium_status);
        // localStorage.setItem("status", data.data.data.status);
        renderUserType(data.data.data.usertype, data.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };
  const sendVerificationCodeHandler = () => {
    let body = {
      verification_code: "",
    };

    sendVerificationCode(body)
      .then((data) => {
        if (
          !data.data.status &&
          data.data.message === "OTP sent Successfully"
        ) {
          console.log("OTP sent Successfully");
        }
      })
      .catch((data) => {
        console.log(data);
      });
  };
  const renderUserType = (type, users) => {
    const user = userType(type);
    // localStorage.setItem("role", user);
    dispatch(setRole(user));
    if (user === "medium") {
      if (users.status === 1 && users.medium_status === 1) {
        navigate("/dashboard");
      } else {
        navigate("/profile");
      }
    }
    if (user === "admin") {
      navigate("/dashboard");
    }
    if (user === "client") {
      if (users.status === 3 && users.medium_status === 0) {
        navigate("/profile");
      } else {
        navigate("/dashboard");
      }
    }
  };
  const LoginUser = async (formData) => {
    dispatch(setLoading(true));
    let item = {
      email: formData.email,
      password: formData.password,
    };
    let browserinfo = await getBrowserData();
    Object.assign(item, browserinfo);
    login(item)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          localStorage.setItem("access_token", data.data.data.access_token);
          dispatch(setUpAuth());
          callProfile(data.data.data.access_token);
        }
        if (
          !data.data.status &&
          data.data.error &&
          data.data.error !== "Unauthorized"
        ) {
          setEmailError(data.data.error);
          setEmailSuccess("");
        }
        if (!data.data.status && data.data.error === "Unauthorized") {
          setEmailError("Invalid Email or Password");
          setEmailSuccess("");
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };
  return (
    <>
      <div className="login-registerheader">
        <div className="container">
          <div className="logo-top">
            {" "}
            <img src="images/logo-verysoul.png" alt="logo" />
          </div>
          <div className="right-login-register">
            <p>
              Already have an account?
              <Link to={"/login"}>Login</Link>
            </p>
          </div>
        </div>
      </div>

      <div className="login-register-saction background-unset register-medium">
        <div className="container  black-container-bg black-container">
          <div className="row row-client">
            <div className="col-md-6 col-sm-12 col-md-nth-child">
              {/* <Slider /> */}
              <section id="testim" className="min-height-100vh-client mt-5 background-sm">
                <div className="d-flex justify-content-around mt-5 flex-direction-responsive">

                  <div className="text-center mt-5">
                    <h3 className="color-d5a67b register-client-below-text mb-2 text-dflex">Welcome to VerySoul</h3>

                    <Vimeo
                      // muted={true}
                      autoplay={false}
                      width={320}
                      height={600}
                      // className="popover-video"
                      // width={innerWidthHandler()}
                      // height={330}
                      video={"https://vimeo.com/995532900/4616417c09?share=copy"}
                    />
                  </div>
                  <div className="text-center mt-5">
                    <h3 className="color-d5a67b register-client-below-text mb-2 text-dflex">Things to Know</h3>
                    <Vimeo
                      // muted={true}
                      autoplay={false}
                      // width={'200'}
                      width={320}
                      height={600}
                      // className="popover-video"
                      // width={innerWidthHandler()}
                      // height={330}
                      video={"https://vimeo.com/995575351/e9f6ea73c4"}
                    />
                  </div>
                </div>
              </section>
            </div>

            <div className="col-md-6 col-sm-12 align-items-center d-flex">
              <div className="login-saction-right register-right register-clint">
                <div className="logo">
                  <img src="images/logo-verysoul.png" alt="logo" />
                </div>

                <h2>Client Registration</h2>
                <p className="register-client-below-text">
                  Register to claim your free session!
                </p>
                {emailError !== "" && (
                  <div className="dont-have-acc margin-unset">
                    <p>
                      <span className="red font-size-17">{emailError}</span>
                      <br />
                      {emailError ===
                        "This email address already exists in our system." && (
                          <Link to="/login">Click here to Log in.</Link>
                        )}
                    </p>
                  </div>
                )}
                {emailSuccess === "Registered Successfully" && (
                  <div className="dont-have-acc margin-unset">
                    <p>
                      <span className="green font-size-17">{emailSuccess}</span>
                      <br />
                      <Link to="/login">Click here to Log in.</Link>
                    </p>
                  </div>
                )}
                <div className="form-l---r">
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validate={(values) => {
                      const errors = {};

                      if (!values.email) {
                        errors.email = "Required";
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = "Invalid email address";
                      }

                      if (!values.password) errors.password = "Required";
                      if (values.password && values.password.length < 6)
                        errors.password = "Minimum length 6 character";
                      if (
                        values.password &&
                        !/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(
                          values.password
                        )
                      ) {
                        errors.password =
                          "Password must be alphanumeric with one uppercase and one special character";
                      }
                      // if (!values.confirmPassword)
                      //   errors.confirmPassword = "Required";
                      // if (values.password !== values.confirmPassword)
                      //   errors.confirmPassword = "password didn't matched";
                      if (!values.is_client_medium) {
                        errors.is_client_medium = "Required";
                      }
                      if (!values.check) {
                        errors.check = "Required";
                      }
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      RegisterUser(values);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="from-fild">
                          <div className="icon">
                            <img src="images/mail.png" alt="email" />
                          </div>
                          <label>
                            Email Address<i>*</i>
                          </label>
                          <input
                            className={`${errors.email && touched.email && errors.email
                              ? "error-boundry"
                              : ""
                              }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            type="email"
                            id="email"
                            name="email"
                            placeholder="myemail@address.com"
                          />
                          <div className="privacy-btn-wrapper">
                            We care deeply about your privacy.{" "}
                            <button
                              type="button"
                              className="privacy-btn"
                              data-toggle="modal"
                              data-target=".bd-example-modal-lg"
                            >
                              Learn more
                            </button>
                          </div>
                          <span className="error">
                            {errors.email && touched.email && errors.email}
                          </span>
                        </div>

                        {/* /Referral code */}
                        <div className="from-fild">
                          <div className="icon">
                            <img src="images/pasword.png" alt="lock" />
                          </div>
                          <label>
                            Password<i>*</i>
                          </label>
                          <input
                            className={`${errors.password &&
                              touched.password &&
                              errors.password
                              ? "error-boundry"
                              : ""
                              }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            id="password"
                            type={typePass ? "password" : "text"}
                            name="password"
                            placeholder="Password"
                          />
                          <div className="icon-right">
                            <img
                              className={`pointer ${typePass ? "" : "openeye"}`}
                              onClick={changePassType}
                              src={
                                typePass
                                  ? "images/eye.png"
                                  : "localImages/openeye.svg"
                              }
                              alt="eye"
                            />
                          </div>
                          <span className="error">
                            {errors.password &&
                              touched.password &&
                              errors.password}
                          </span>
                        </div>

                        {/* <div className="from-fild">
                          <div className="icon">
                            <img src="images/pasword.png" alt="lock" />
                          </div>
                          <label>
                            Confirm Password<i>*</i>
                          </label>
                          <input
                            className={`${errors.confirmPassword &&
                              touched.confirmPassword &&
                              errors.confirmPassword
                              ? "error-boundry"
                              : ""
                              }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmPassword}
                            id="confirmPassword"
                            type={confirmtypePass ? "password" : "text"}
                            name="confirmPassword"
                            placeholder="Confirm Password"
                          />
                          <div className="icon-right">
                            <img
                              className={`pointer ${confirmtypePass ? "" : "openeye"
                                }`}
                              onClick={changePassTypeConfirm}
                              src={
                                confirmtypePass
                                  ? "images/eye.png"
                                  : "localImages/openeye.svg"
                              }
                              alt="eye"
                            />
                          </div>
                          <span className="error">
                            {errors.confirmPassword &&
                              touched.confirmPassword &&
                              errors.confirmPassword}
                          </span>
                        </div> */}
                        <div className="from-fild">
                          <div className="icon">
                            <img src="images/user.png" alt="user" />
                          </div>
                          <label htmlFor="promotion_code">
                            Optional Referral Code
                          </label>
                          <input
                            className={`${errors.promotion_code &&
                              touched.promotion_code &&
                              errors.promotion_code
                              ? "error-boundry"
                              : ""
                              }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.promotion_code}
                            type="text"
                            id="promotion_code"
                            name="promotion_code"
                            placeholder="Referral code"
                          />
                          <span className="error mt-1">{invalidCode}</span>
                        </div>
                        <div className="check-box">
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            checked={values.is_client_medium}
                            type="checkbox"
                            id="is_client_medium"
                            name="is_client_medium"
                          />{" "}
                          <label
                            className="d-flex align-items-center"
                            htmlFor="is_client_medium"
                          >
                            I am not a medium (Medium register
                            <Link className="margin-unset text-underline" to={"/register-medium"}>&nbsp;here</Link>)
                          </label>
                        </div>
                        <span className="error">
                          {errors.is_client_medium &&
                            touched.is_client_medium &&
                            errors.is_client_medium}
                        </span>
                        <div className="check-box mt-3">
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            checked={values.check}
                            type="checkbox"
                            id="check"
                            name="check"
                          />{" "}
                          <label htmlFor="check">I am over 18</label>
                        </div>
                        <span className="error">
                          {errors.check && touched.check && errors.check}
                        </span>
                        <div className="bution">
                          <button type="submit" className="button-bx">
                            Sign Up
                          </button>
                          <div
                            data-backdrop="static"
                            className="modal fade"
                            id="myModal"
                            role="dialog"
                          >
                            <div className="modal-dialog modal-dialog-preview">
                              <div className="modal-content">
                                <div className="modal-body">
                                  <div className="icon-pop">
                                    <img
                                      src="images/Union-icon.png"
                                      alt="Union-icon"
                                    />
                                  </div>
                                  <h3>
                                    Begin Registration to <br />
                                    Be a Verysoul Client
                                  </h3>
                                  <p>
                                    You have successfully created your account!
                                    To begin using this site you will need to
                                    activate your account via the email we have
                                    just sent to your address.If you do not see
                                    the email in a few minutes, check your “junk
                                    mail” folder or “spam” folder.
                                  </p>
                                  <div
                                    className="register-medium-confirnation"
                                    onClick={() => redirectRoute("/thank-you")}
                                    data-dismiss="modal"
                                  >
                                    Close
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="dont-have-acc">
                          <p>
                            Already have an account?{" "}
                            <Link to="/login">Login</Link>
                          </p>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    

      <p className="text-center mt-4">
        <b>
          Note: For optimum performance: use Chrome, Safari, or Edge browser
        </b>
      </p>
      <StarterQuestion
        submitFunc={submitFunc}
        about_verysoul={about_verysoul}
        past_experience={past_experience}
        medium_psychic={medium_psychic}
        help_text={help_text}
        setAbout_verysoul={setAbout_verysoul}
        setPast_experience={setPast_experience}
        setMedium_psychic={setMedium_psychic}
        setHelp_text={setHelp_text}
        otherValue={otherValue}
        setOtherValue={setOtherValue}
      />
      <PrivacyModal />
    </>
  );
};
export default ClientRegister;
