import React from "react";
import "./card.css";
import { Link } from "react-router-dom";

export default function Card({ type, title, onclick, src, alt, url }) {
  return (
    <>
      {type !== "mail" ? (
        <div
          onClick={onclick}
          className="client-dashboard-card-container pointer"
        >
          <div>
            <img src={src} alt={alt} />
            <p>{title}</p>
          </div>
        </div>
      ) : (
        <a href={url} onClick={()=>{
          localStorage.removeItem('activesession_id')
          localStorage.removeItem('type');
          localStorage.removeItem('offset');
        }} target="_blank" className="client-dashboard-card-container">
          <div>
            <img src={src} alt={alt} />
            <p>{title}</p>
          </div>{" "}
        </a>
      )}
    </>
  );
}
