import React, { useState } from "react";

export default function BackFillConfirmationTime({ goToMediumBackFill, backFillId, cancelRequest, id }) {
    const [value, setValue] = useState('');

    const submitHandler = () => {
        goToMediumBackFill(value, backFillId)
    }
    return (
        <div className="confirm-delete-wrapper register-medium">
            <div data-backdrop="static" className="modal fade" id={id} role="dialog">
                <div className="modal-dialog padding-unset modal-dialog-preview">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="icon-pop mt-3">
                                <img src="images/Union-icon.png" alt="Union-icon" />
                            </div>
                            <h3>How long are you willing to wait for a match?</h3>
                            <div className="mt-2">

                                <div class="select-dropdown">

                                    <select
                                        className="p-3"
                                        // value={values.buffer_time}
                                        onChange={(e) => setValue(e.target.value)}
                                        // onBlur={handleBlur}
                                        name={`buffer_time`}
                                    >
                                        <option disabled selected value="">
                                            Select Time
                                        </option>
                                        <option value={5}>5 Minutes</option>
                                        <option value={10}>10 Minutes</option>
                                        <option value={15}>15 Minutes</option>
                                        <option value={30}>30 Minutes</option>
                                        <option value={45}>45 Minutes</option>
                                        <option value={60}>60 Minutes</option>
                                        {/* <option value={75}>75 Minutes</option>
                                        <option value={90}>90 Minutes</option> */}
                                    </select>
                                </div>
                            </div>
                            <div className="confirm-btn-box">

                                <div
                                    className="register-medium-confirnation"
                                    data-dismiss="modal"
                                    onClick={submitHandler}
                                >
                                    Submit
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
