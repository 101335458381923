import React from 'react'
import Button from '../../../../../components/button'

export default function PracticeCheckPopup({ cancel, continueHandler }) {
    return (
        <div
            className="modal"
            id="PracticeCheckPopup"
            role="dialog"
            data-backdrop="false"
        >
            <div className="modal-dialog  modal-lg modal-dialog-preview w-100 Please-check-your-personal-calendar">
                <div className="modal-content margin-top-300 border-waiting-time">
                    <div className="modal-body modal-body-waiting-time">
                        {/* <button type="button" className="close" data-dismiss="modal">
                            &times;
                        </button> */}

                        <h3 className="text-center mt-5">Please check your personal calendar for your availability before selecting a time</h3>
                        <div>
                            <div className="payment-new-wrapper mt-5">
                                <div className="payment-new-container mt-5">

                                    <div>


                                        <button
                                            className={`resend-btn  min-width-130px`}

                                            onClick={continueHandler}

                                        >
                                            <div> <span> Continue</span> </div></button>
                                    </div>
                                    <button
                                        className={`resend-btn  min-width-130px model-close-btn`}

                                        onClick={cancel}

                                    >  <div>  <span> Cancel</span></div></button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
