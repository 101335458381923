import React, { useState } from "react";
import { Formik, FieldArray } from "formik";
import { useDispatch } from "react-redux";
import moment from "moment";
import { setMediumAvailability } from "../../services/medium";
import { setLoading } from "../../reducer/auth/auth";
import Calendar from "./calender";
import { slotArray, timingArray } from "./data";

const intitialState = {
  date: "",
  time: [{ hour: "", slot: "" }],
};

export default function Availability({ showDetailsHandle }) {

  const dispatch = useDispatch();



  const [slots, setSlots] = useState(intitialState);


  const setAppointments = async (body) => {
    dispatch(setLoading(true));

    await setMediumAvailability(body)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          window.location.reload();
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };
  const submitAvailability = (items) => {
    setSlots(items);
    setAppointments(items);
  };
  return (
    <div className="dashboard">
      <div className="row">
        <div className="col-md-12">
          <div className="clander-saction">
            <div className="headr-box-cl">
              {/* <div className="title-cla">Availability Planner</div> */}
              <div className="button-cla">
                <div className="modal fade" id="myModal" role="dialog">
                  <div className="modal-dialog modal-dialog-preview">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                    <div className="modal-content">
                      <div className="modal-body">
                        <h2>Add Availability</h2>
                        <Formik
                          initialValues={intitialState}
                          validate={(values) => {
                            const errors = {};
                            if (!values.date) errors.date = "Required";
                            return errors;
                          }}
                          onSubmit={(values, { setSubmitting }) => {
                            submitAvailability(values);
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <div className="full-cl-inp">
                                <label>Select Date</label>
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Select your date"
                                  type="date"
                                  name="date"
                                  min={moment().format("YYYY-MM-DD")}
                                  id="datepicker"
                                  value={values.date}
                                  className="calendar checkIn"
                                />
                                <img
                                  src="images/calendar-blck.png"
                                  alt="calendar"
                                />
                              </div>
                              <div className="items">
                                <FieldArray name="time">
                                  {({ insert, remove, push }) => (
                                    <>
                                      {React.Children.toArray(
                                        values.time.length > 0 &&
                                        values.time.map((time, index) => (
                                          <div className="item availbility-item">
                                            <div className="left-clndr">
                                              <select
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                id="state"
                                                className="dropdown"
                                                name={`time.${index}.hour`}
                                              >
                                                <option
                                                  value=""
                                                  disabled="disabled"
                                                  selected="selected"
                                                >
                                                  Choose Time
                                                </option>
                                                <optgroup label="US States">
                                                  {timingArray.map(
                                                    (timing) => (
                                                      <option
                                                        value={timing.time}
                                                      >
                                                        {timing.time}
                                                      </option>
                                                    )
                                                  )}
                                                </optgroup>
                                              </select>
                                              <img
                                                src="images/clock.png"
                                                alt="clock"
                                              />
                                            </div>
                                            <div className="right-clndr">
                                              <select
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                id="state"
                                                className="dropdown"
                                                name={`time.${index}.slot`}
                                              >
                                                <option
                                                  value=""
                                                  disabled="disabled"
                                                  selected="selected"
                                                >
                                                  Choose Slot
                                                </option>
                                                <optgroup label="Slot">
                                                  {slotArray.map((slot) => (
                                                    <option value={slot.slot}>
                                                      {slot.slot} min
                                                    </option>
                                                  ))}
                                                </optgroup>
                                              </select>
                                              <img
                                                src="images/clock.png"
                                                alt="clock"
                                              />
                                            </div>
                                            {values.time.length > 1 && (
                                              <div className="delete-box">
                                                <img
                                                  onClick={() =>
                                                    remove(index)
                                                  }
                                                  className="delete"
                                                  src="images/delete.svg"
                                                  alt="delete.svg"
                                                />
                                              </div>
                                            )}
                                          </div>
                                        ))
                                      )}
                                      <div className="items">
                                        <a
                                          onClick={() =>
                                            push({ hour: "", slot: "" })
                                          }
                                          href="javascript:void(0)"
                                          className="showMore"
                                        ></a>
                                      </div>
                                    </>
                                  )}
                                </FieldArray>
                              </div>
                              <div className="update-ailability">
                                <button
                                  type="submit"
                                  className="update-ailability-button"
                                >
                                  Update Availability
                                </button>
                              </div>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Calendar slots={slots} />
          </div>
        </div>
      </div>
    </div>
  );
}
