import React, { useState } from "react";
import { setReminderOndemand } from "../../../services/medium";
import { toast } from "react-toastify";
import moment from "moment";

export default function ExitQueuePopup({ onYesClick }) {
    const [value, setValue] = useState("");
    const [error, setError] = useState(false);
    // const { id  } = useSelector((state) => state.user.user);

    const setPaymentReminder = () => {
        onYesClick(); // Call the parent function
        handleClose(); 
    };
    const handleClose = () => {
        // Close the modal using jQuery since it's a Bootstrap modal
        window.jQuery('#ExitQueuePopup').modal('hide');
    };


    return (
            <div className="confirm-delete-wrapper register-medium">
            <div id="ExitQueuePopup" class="modal fade"
                tabindex="-1"
                role="dialog"
                aria-labelledby="myLargeModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-preview">
          <div className="modal-content">
            <div className="modal-body">
              <div className="icon-pop">
                <img src="images/Union-icon.png" alt="Union-icon" />
              </div>
              <h3>Please confirm if you want to exit the queue ?</h3>
              <div className="confirm-btn-box">
                <div
                  className="register-medium-confirnation model-close-btn"
                  onClick={handleClose}
                  data-dismiss="modal"
                >
                  No
                </div>
                <div
                  className="register-medium-confirnation"
                  data-dismiss="modal"
                  onClick={setPaymentReminder}
                >
                 Yes
                </div>
              </div>
            </div>
          </div>
        </div>
               
            </div>
        </div>
    );
}
