import React from 'react'
import Button from '../../../../../components/button'

export default function SelectedLimitReached({ showAllAvailability, continueMediumSelection }) {
    return (
        <div
            className="modal"
            id="selectedlimitreached"
            role="dialog"
            data-backdrop="false"
        >
            <div className="modal-dialog  modal-lg modal-dialog-preview w-100">
                <div className="modal-content margin-top-300 border-waiting-time">
                    <div className="modal-body modal-body-waiting-time">
                        {/* <button type="button" className="close" data-dismiss="modal">
                            &times;
                        </button> */}

                        <h3 className="text-center mt-5">Still not finding what you’re looking for?</h3>
                        <div>
                            <div className="payment-new-wrapper mt-5">
                                <div className="payment-new-container mt-5">

                                    <div>


                                        <button
                                            className={`resend-btn limit-reached-popup-buttons min-width-130px`}

                                            onClick={continueMediumSelection}

                                        >
                                            <div><span className='limit-reached-img-btn'>
                                                <img src='/images/pin-image-coach.png' />
                                            </span> <span> Continue with Medium Selection</span> </div></button>
                                    </div>
                                    <button
                                        className={`resend-btn limit-reached-popup-buttons min-width-130px`}

                                        onClick={showAllAvailability}

                                    >  <div><span className='limit-reached-img-btn limit-reached-img-btn-2'>
                                        <img src='/images/calendar-icon-coach.png' /></span>  <span> Show me all availability (Expect Loading Delays)</span></div></button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
