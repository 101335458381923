import React, { useEffect, useState } from "react";
// import "./toogle.css";
// import { changeProfessionalStatus } from "../../services/admin";

export default function ToggleTestMode({ item, setFieldValue ,stepOneData}) {
    // console.log(item, "item")
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setChecked(item.test_mode === 1 ? true : false)
    }, [item])
    useEffect(() => {
        setChecked(stepOneData.test_mode === 1 ? true : false)
    }, [stepOneData])

    const onChange = () => {
        setChecked(!checked)
        setFieldValue('test_mode', item.test_mode === 1 ? 0 : 1);
    }
    return (
        <div class="toggle-button-cover">
            <div class={`button-cover`}>
                <div class="button r" id="button-1" onClick={onChange}>
                    <input
                        id="test_mode"
                        type="checkbox"
                        checked={checked}
                        name="test_mode"
                        class="checkbox"
                    // onChange={onChange}
                    />
                    <div class="knobs"></div>
                    <div class="layer"></div>
                </div>
            </div>
        </div>
    );
}
