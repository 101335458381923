import React from 'react'
import btn from './btn.png';
import './googlebtn.css';

export default function GoogleButton({ onClick ,className }) {
    return (

        <button onClick={onClick} className={`${className} google-btn-image`} type='button'><img src={btn} /></button>

    )
}
