import React from 'react'
import WhatsappImg from '../subcomponetnts/contactImages/whatsapp.png'
import phoneImg from '../subcomponetnts/contactImages/old-typical-phone.png';
import emailImg from '../subcomponetnts/contactImages/email.png'
import CopyComponent from '../../../../components/copyComponent';

export default function Contactpopup({ activeContact }) {

    const { client_email = '', client_phone = "", client_whatsapp = "",client_country="" } = activeContact
    return (
        <div className="confirm-delete-wrapper register-medium">
            <div data-backdrop="static" className="modal fade" id={'Contactpopup'} role="dialog">
                <div className="modal-dialog padding-unset modal-dialog-preview">
                    <div className="modal-content">
                        <div className="cross-icon-popup-1 position-relative">
                            <img src="localImages/crossicon.png" onClick={() => window.loadModal2("#Contactpopup", "hide")} />
                        </div>
                        <div className="modal-body">

                            <h3>Contact Info
                                <br/>
                                (Country - {client_country})
                            </h3>

                            <div className="contactbox-container">

                                {client_phone !== "" && <div className='contactbox-wrapper'>
                                    <img className='w-20-px' src={phoneImg} alt='whatsapp' />
                                    <a className='contactbox-contact' href={`tel:+${client_phone}`}>+{client_phone}</a>
                                    {/* <span>{client_phone}</span> */}
                                    <CopyComponent value={client_phone} />
                                </div>}
                                {client_whatsapp !== "" && <div className='contactbox-wrapper'>
                                    <img className='w-20-px' src={WhatsappImg} alt='whatsapp' />
                                    {/* <span>{client_whatsapp}</span> */}
                                    <a className='contactbox-contact' target='_blank' href={`https://wa.me/${client_whatsapp}`}>+{client_whatsapp}</a>
                                    <CopyComponent value={client_whatsapp} />
                                </div>}
                                {client_email !== "" && <div className='contactbox-wrapper'>
                                    <img className='w-20-px' src={emailImg} alt='whatsapp' />
                                    {/* <span>{client_email}</span> */}
                                    <a
                                    className='contactbox-contact'
                                        href={`mailto:${client_email}`}>
                                        {client_email}
                                    </a>
                                    <CopyComponent value={client_email} />
                                </div>}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
