import React from 'react'

export default function ConfirmIfCalendarSelected({
    confirmCalendarSetup,
    setconfirmCalendarSetup,
    finishPopupHandler

}) {
    return (
        <div className="confirm-delete-wrapper register-medium">
            <div data-backdrop="static" className="modal fade" id="ConfirmIfCalendarSelected" role="dialog">
                <div className="modal-dialog ">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="icon-pop m-3">
                                <img src="images/Union-icon.png" alt="Union-icon" />
                            </div>
                            <h5>{"Please check test appointment created from VerySoul in your google calendar ?"}</h5>
                            <br />
                            <div className="prvacy-box ml-5">
                                <input
                                    onChange={(e) => setconfirmCalendarSetup(e.target.checked)}
                                    // onBlur={handleBlur}
                                    value="I have read and agree to the terms and conditions"
                                    id="termsandconditions"
                                    type="checkbox"
                                    name="agree_to_legal_terms_and_conditionss"
                                    // disabled
                                    checked={confirmCalendarSetup ? 'checked' : ''}
                                />

                                <label htmlFor="termsandconditions" className="width-unset font-size-19">
                                    I confirm appointment is showing in my Google calendar.
                                </label>

                            </div>
                            <span className="error  ml-5">
                             
                                {!confirmCalendarSetup ? 'Required' : ''}
                            </span>
                            <div className="confirm-btn-box">
                                <div
                                    className="register-medium-confirnation"
                                    // data-dismiss="modal"
                                    onClick={finishPopupHandler}
                                >
                                    {'Submit'}
                                </div>
                                {/* <div
                                    className="register-medium-confirnation"
                                    data-dismiss="modal"
                                // onClick={GoToAvailabilityHandler}
                                >
                                    {''}
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
