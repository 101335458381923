import React, { useEffect, useState } from "react";
import moment from "moment";
import Search from "../../../../components/search";
import { useSelector } from "react-redux";
import Pagination from "../../../../components/tablePagination";
import { session_id_beg, session_id_end } from "../../../../utils/helpers";

import { CancelledHeaders, filterList } from "../data";
import Button from "../../../../components/button";
export default function Cancelled(props) {
  const {
    canceledAppointment,
    filterListAppointments,
    getPaginationData,
    filterReset,
    setFilterValues,
    setSearchValues,
    searchValue,
    filterValues,
    blockUser,
    setBlink
  } = props;
  // const copyMeetingLink = (url) => {
  //   navigator.clipboard.writeText(url);
  // };
  const { allCancelledCount } = useSelector((state) => state.medium);
  useEffect(() => {
    setBlink(false)
  }, [])
  const checkAppId = (appoinment) => {
    if (appoinment.is_group_session === 0) {
      return appoinment.id
    }
    else if (appoinment.is_group_session === 1) {
      // if (appoinment.attendees == undefined) {
      if (appoinment.parent_id == null) {
        return appoinment.id
      }
      else {
        return appoinment.parent_id
      }

      // }


    }
    else {
      return appoinment.id
    }

  }
  return (
    <>
      <div className="d-flex justify-content-end gap-10 flex-wrap">
        <Search callApi={filterListAppointments} list={filterList} setSearchValues={setSearchValues} setFilterValues={setFilterValues} searchValue={searchValue}
          filterValues={filterValues} />
        {/* <Button
          status={0} 
           type="button"
           onClick={filterReset}
           className={`resend-btn  width-80px  reset-btn-123`}
           title="Reset"
          /> */}
      </div>
      <div className="table-box-medium admin-appoi">
        <table className="caseTable">
          <tbody>
            <tr className="dark">
              {React.Children.toArray(
                CancelledHeaders.map((item) => <th>{item.title}</th>)
              )}

              {/* <input type="checkbox" id="one" />
                <label htmlFor="one"></label> */}
            </tr>
            {canceledAppointment.length > 0 ? (
              canceledAppointment.map((appoinment) => (
                <tr className="innr">
                  <td>{session_id_beg + checkAppId(appoinment) + session_id_end}{appoinment.is_replaced === 1 ? <img className="ml-1 star-app-hightlight" src="../images/blackstar.jpeg" /> : ''}</td>
                  <td>
                    <span className="capitalize">{appoinment.client_name},</span>
                    <br />
                    <span className="capitalize">{appoinment.medium_name}</span>
                  </td>
                  <td>
                    {appoinment.date.split(" ")[0]}
                    {/* {moment
                      .utc(appoinment.date)
                      .local()
                      .format("LL")} */}
                  </td>
                  <td>
                    {" "}
                    {appoinment.time}
                    {/* {moment
                      .utc(appoinment.time, ["h:mm A"])
                      .local()
                      .format("hh:mm a")} */}
                  </td>
                  <td>{appoinment.session_name}</td>
                  <td>
                    {appoinment.modifiedDate}
                  </td>
                  <td className="position-relative ">
                    {appoinment.session_type === "1" && <> <Button
                      status={0}
                      onClick={(e) => blockUser(appoinment.id, appoinment.medium_id, appoinment.client_id)}
                      type="button"
                      className={`resend-btn w-130 tooltip-2-focus`}
                      title={parseInt(appoinment.is_blocked) === 0 ? "Block" : "Unblock"}
                    />
                      <p className="tooltip-2 block-tooltip-app">{parseInt(appoinment.is_blocked) === 0 ? 'Only click if you do not want to work with this person at all.' : 'Only click if you want to work this person.'}

                      </p>
                    </>
                    }
                  </td>
                  <td>{appoinment.userby}</td>
                  {/* <td>
                    <div>
                      <p className="d-flex">
                        <div> Client:</div>{" "}
                        <div className="d5a67b ml-1">
                          {appoinment.client_email}{" "}
                        </div>
                      </p>
                      <p className="d-flex">
                        {" "}
                        <div>Medium:</div>{" "}
                        <div className="d5a67b ml-1">
                          {appoinment.medium_email}
                        </div>
                      </p>
                    </div>
                  </td> */}
                  {/* <td>Practice - 60 Min (30 Min each)</td> */}
                  {/* <td className="googl-link">
                    <a
                      className="appointment-links"
                      href={appoinment.meeting_link}
                      target="_blank"
                    >
                      {appoinment.meeting_link}
                    </a>
                    <img
                      onClick={() => copyMeetingLink(appoinment.meeting_link)}
                      className="googl-link-copy-img "
                      alt="copy"
                      src="images/copy.png"
                    />
                  </td> */}
                  {/* <td className="change">
                    <a href="#">
                      <img src="../images/email-blu.png" />
                      Email
                    </a>
                  </td> */}
                  {/* <td className="dot-image">
                    <img src="../images/Unionblck.png" />
                  </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No Appointments Found
                </td>
              </tr>
            )}
            {/* <tr className="innr">
              <td>
                <input type="checkbox" id="two" />
                <label htmlFor="two"></label>
                <span>Devid Smith</span>
              </td>
              <td>May 01 2022</td>
              <td>12:30 PM</td>
              <td>Practice - 60 Min (30 Min each)</td>
              <td>
                <a href="#">
                  meet.google.com/ict-bdvp-ydv
                  <img src="../images/copy.png" />
                </a>
              </td>
              <td className="change">
                <a href="#">
                  <img src="../images/email-blu.png" />
                  Email
                </a>
              </td>
              <td className="dot-image">
                <img src="../images/Unionblck.png" />
              </td>
            </tr>
            <tr className="innr">
              <td>
                <input type="checkbox" id="two" />
                <label htmlFor="two"></label>
                <span>Devid Smith</span>
              </td>
              <td>May 01 2022</td>
              <td>12:30 PM</td>
              <td>Practice - 60 Min (30 Min each)</td>
              <td>
                <a href="#">
                  meet.google.com/ict-bdvp-ydv
                  <img src="../images/copy.png" />
                </a>
              </td>
              <td className="change">
                <a href="#">
                  <img src="../images/email-blu.png" />
                  Email
                </a>
              </td>
              <td className="dot-image">
                <img src="../images/Unionblck.png" />
              </td>
            </tr>
            <tr className="innr">
              <td>
                <input type="checkbox" id="two" />
                <label htmlFor="two"></label>
                <span>Devid Smith</span>
              </td>
              <td>May 01 2022</td>
              <td>12:30 PM</td>
              <td>Practice - 60 Min (30 Min each)</td>
              <td>
                <a href="#">
                  meet.google.com/ict-bdvp-ydv
                  <img src="../images/copy.png" />
                </a>
              </td>
              <td className="change">
                <a href="#">
                  <img src="../images/email-blu.png" />
                  Email
                </a>
              </td>
              <td className="dot-image">
                <img src="../images/Unionblck.png" />
              </td>
            </tr>
            <tr className="innr">
              <td>
                <input type="checkbox" id="two" />
                <label htmlFor="two"></label>
                <span>Devid Smith</span>
              </td>
              <td>May 01 2022</td>
              <td>12:30 PM</td>
              <td>Practice - 60 Min (30 Min each)</td>
              <td>
                <a href="#">
                  meet.google.com/ict-bdvp-ydv
                  <img src="../images/copy.png" />
                </a>
              </td>
              <td className="change">
                <a href="#">
                  <img src="../images/email-blu.png" />
                  Email
                </a>
              </td>
              <td className="dot-image">
                <img src="../images/Unionblck.png" />
              </td>
            </tr>
            <tr className="innr">
              <td>
                <input type="checkbox" id="two" />
                <label htmlFor="two"></label>
                <span>Devid Smith</span>
              </td>
              <td>May 01 2022</td>
              <td>12:30 PM</td>
              <td>Practice - 60 Min (30 Min each)</td>
              <td>
                <a href="#">
                  meet.google.com/ict-bdvp-ydv
                  <img src="../images/copy.png" />
                </a>
              </td>
              <td className="change">
                <a href="#">
                  <img src="../images/email-blu.png" />
                  Email
                </a>
              </td>
              <td className="dot-image">
                <img src="../images/Unionblck.png" />
              </td>
            </tr>
            <tr className="innr">
              <td>
                <input type="checkbox" id="two" />
                <label htmlFor="two"></label>
                <span>Devid Smith</span>
              </td>
              <td>May 01 2022</td>
              <td>12:30 PM</td>
              <td>Practice - 60 Min (30 Min each)</td>
              <td>
                <a href="#">
                  meet.google.com/ict-bdvp-ydv
                  <img src="../images/copy.png" />
                </a>
              </td>
              <td className="change">
                <a href="#">
                  <img src="../images/email-blu.png" />
                  Email
                </a>
              </td>
              <td className="dot-image">
                <img src="../images/Unionblck.png" />
              </td>
            </tr>
            <tr className="innr">
              <td>
                <input type="checkbox" id="two" />
                <label htmlFor="two"></label>
                <span>Devid Smith</span>
              </td>
              <td>May 01 2022</td>
              <td>12:30 PM</td>
              <td>Practice - 60 Min (30 Min each)</td>
              <td>
                <a href="#">
                  meet.google.com/ict-bdvp-ydv
                  <img src="../images/copy.png" />
                </a>
              </td>
              <td className="change">
                <a href="#">
                  <img src="../images/email-blu.png" />
                  Email
                </a>
              </td>
              <td className="dot-image">
                <img src="../images/Unionblck.png" />
              </td>
            </tr>
            <tr className="innr">
              <td>
                <input type="checkbox" id="two" />
                <label htmlFor="two"></label>
                <span>Devid Smith</span>
              </td>
              <td>May 01 2022</td>
              <td>12:30 PM</td>
              <td>Practice - 60 Min (30 Min each)</td>
              <td>
                <a href="#">
                  meet.google.com/ict-bdvp-ydv
                  <img src="../images/copy.png" />
                </a>
              </td>
              <td className="change">
                <a href="#">
                  <img src="../images/email-blu.png" />
                  Email
                </a>
              </td>
              <td className="dot-image">
                <img src="../images/Unionblck.png" />
              </td>
            </tr>
            <tr className="innr">
              <td>
                <input type="checkbox" id="two" />
                <label htmlFor="two"></label>
                <span>Devid Smith</span>
              </td>
              <td>May 01 2022</td>
              <td>12:30 PM</td>
              <td>Practice - 60 Min (30 Min each)</td>
              <td>
                <a href="#">
                  meet.google.com/ict-bdvp-ydv
                  <img src="../images/copy.png" />
                </a>
              </td>
              <td className="change">
                <a href="#">
                  <img src="../images/email-blu.png" />
                  Email
                </a>
              </td>
              <td className="dot-image">
                <img src="../images/Unionblck.png" />
              </td>
            </tr>
            <tr className="innr">
              <td>
                <input type="checkbox" id="two" />
                <label htmlFor="two"></label>
                <span>Devid Smith</span>
              </td>
              <td>May 01 2022</td>
              <td>12:30 PM</td>
              <td>Practice - 60 Min (30 Min each)</td>
              <td>
                <a href="#">
                  meet.google.com/ict-bdvp-ydv
                  <img src="../images/copy.png" />
                </a>
              </td>
              <td className="change">
                <a href="#">
                  <img src="../images/email-blu.png" />
                  Email
                </a>
              </td>
              <td className="dot-image">
                <img src="../images/Unionblck.png" />
              </td>
            </tr>
            <tr className="innr">
              <td>
                <input type="checkbox" id="two" />
                <label htmlFor="two"></label>
                <span>Devid Smith</span>
              </td>
              <td>May 01 2022</td>
              <td>12:30 PM</td>
              <td>Practice - 60 Min (30 Min each)</td>
              <td>
                <a href="#">
                  meet.google.com/ict-bdvp-ydv
                  <img src="../images/copy.png" />
                </a>
              </td>
              <td className="change">
                <a href="#">
                  <img src="../images/email-blu.png" />
                  Email
                </a>
              </td>
              <td className="dot-image">
                <img src="../images/Unionblck.png" />
              </td>
            </tr>
            <tr className="innr">
              <td>
                <input type="checkbox" id="two" />
                <label htmlFor="two"></label>
                <span>Devid Smith</span>
              </td>
              <td>May 01 2022</td>
              <td>12:30 PM</td>
              <td>Practice - 60 Min (30 Min each)</td>
              <td>
                <a href="#">
                  meet.google.com/ict-bdvp-ydv
                  <img src="../images/copy.png" />
                </a>
              </td>
              <td className="change">
                <a href="#">
                  <img src="../images/email-blu.png" />
                  Email
                </a>
              </td>
              <td className="dot-image">
                <img src="../images/Unionblck.png" />
              </td>
            </tr> */}
          </tbody>
        </table>
        <Pagination
          rowsPerPageOptions={[5, 10, 25]}
          count={allCancelledCount}
          getPaginationData={getPaginationData}
          activeTab={"mediumcancelled"}
        />
      </div>
    </>
  );
}
