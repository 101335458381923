import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/footer";
import Topbar from "../../components/topbar/topbar";
import Button from "../../components/button";
import { useNavigate, useParams } from "react-router-dom";
import { getPaymentDetails } from "../../services/uidata";
import { payWithPaypalCompleted, payWithSquareCompleted, payWithStripeCompleted, doAPayItForwardPayment, doAPayItForwardPaymentProcess } from "../../services/client";
import Pusher from "pusher-js";
import ThankyouPopup from "../../components/payment/thankyou";
import { useDispatch } from "react-redux";
import { setLoading } from "../../reducer/auth/auth";
import { toast } from "react-toastify";

export default function PayMyMedium() {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [mediumInfo, setMediumInfo] = useState();
  const [mediumstatus, setMediumStatus] = useState(false);
  const [thankPage ,setThanksPage] = useState(false)
  const [is_pay_forward ,setis_pay_forward] = useState(false)
  const [payItforwardValue ,setpayItforwardValue] = useState("")
  const [payItforwardFixPrice ,setpayItforwardFixPrice] = useState("")
  const [inputValue, setInputValue] = useState("");
  

  useEffect(() => {
    let id = param.id;
    // getPaymentDetails(id)
    //   .then((data) => {
    //     console.log(data)
    //   })
    //   .catch(() => { });
    // console.log(param, "param");
  }, []);

  useEffect(() => {
    let id = param.id;
    dispatch(setLoading(true))
    getPaymentDetails(id)
      .then((data) => {
        dispatch(setLoading(false))
        if (data.data.status) {
          setis_pay_forward(data.data.data.is_pay_forward)
          setMediumStatus(true);
          setMediumInfo(data.data.data);
          setThanksPage(data.data.data.appointment_paid === 1? true :false)

          let item = data.data.data;
          
          if (item.payitforward_pricing !== null) {
            let parsedPricing;
            try {
              // Directly parse the JSON string without slicing or replacing
              parsedPricing = JSON.parse(item.payitforward_pricing);
              console.log("Parsed pricing object:", parsedPricing);
            } catch (error) {
              console.error("Error parsing JSON:", error);
              return; // Exit early if there's a parsing error
            }
          
            // Extract the price value based on the duration
            let priceValue = parsedPricing[item.duration];
            console.log("Price value for duration:", priceValue);
          
            // Set state values based on the parsed price
            setpayItforwardValue(priceValue !== undefined ? priceValue : '');
            setpayItforwardFixPrice(priceValue !== undefined ? priceValue : '');
            setInputValue(priceValue !== undefined ? priceValue : '');
          } else {
            setpayItforwardValue('');
          }
        }
        if (!data.data.status) {
          setis_pay_forward(false)
          setMediumStatus(false)
          setThanksPage(false)
        }
      })
      .catch(() => {   dispatch(setLoading(false)) });
  }, []);

  useEffect(() => {
    if (mediumInfo !== undefined) {
      // window.loadModal2('#WaitingTimerPopup', 'show');
      Pusher.logToConsole = true;

      var pusher = new Pusher("823ab72ed6ecd563b8c8", {
        cluster: "ap2",
      });

      var channel = pusher.subscribe("notify-client-payment");
      channel.bind("App\\Events\\NotifyClientAboutPayment", function (data) {
        console.log(data.appointment, "data.appointment)");
        toast.success('Payment Succes', { position: 'top-center' });
        setThanksPage(true);
      });
    }
  }, [mediumInfo])

  const paymentMethodHandler = (type) => {

    let body = {
      id: mediumInfo !== undefined && mediumInfo.appt_id,
      medium_id: mediumInfo !== undefined && mediumInfo.medium_id,
      amount: mediumInfo !== undefined && mediumInfo.amount,
      currency: mediumInfo !== undefined && mediumInfo.currency,
      client_id: mediumInfo !== undefined && mediumInfo.client_id,
      payment_source:'email'

    };
    if (type === "paypal") {
      payWithPaypalCompleted(body)
        .then((data) => {
          if (data.data.status) {
            // setCounterStatus(true)
            // window.loadModal2("#payoptionspopup", "hide");
            // setCounterStatus(true)
            window.open(data.data.data.approvedUrl, "_self");
            // window.loadModal2('#GiftYourMediums', "hide");

          }
          else{
            toast.error(data.data.message, {
              position: "top-center",
            });
          }
        })
        .catch(() => { });
    } else if (type === "stripe") {
      payWithStripeCompleted(body)
        .then((data) => {
          if (data.data.status) {
            // setCounterStatus(true);
            window.open(data.data.data.approvedUrl, "_self");
            // window.loadModal2("#GiftYourMediums", "hide");
          }
          else{
            toast.error(data.data.message, {
              position: "top-center",
            });
          }
        })
        .catch(() => { });
    }
    else {
      payWithSquareCompleted(body)
        .then((data) => {
          if (data.data.status) {
            // setCounterStatus(true);
            window.open(data.data.data.approvedUrl, "_blank");
            // window.loadModal2("#GiftYourMediums", "hide");
          }
          else{
            toast.error(data.data.message, {
              position: "top-center",
            });
          }
        })
        .catch(() => { });
    };
  }
  const paymentChangeHandler = (e) => {
    let value = e.target.value;
    setInputValue(value);
    setpayItforwardValue(value)
};
const payItForwardHandler = () => {
  
  let body = {
    medium_id: mediumInfo !== undefined && mediumInfo.medium_id,
    appointment_id: mediumInfo !== undefined && mediumInfo.appt_id,
    amount: payItforwardValue,
    client_id: mediumInfo !== undefined && mediumInfo.client_id,
    appointment_type: 'payafter'
  }
  doAPayItForwardPaymentProcess(body).then((data) => {
    if (data.data.status) {
     window.open(data.data.data.approvedUrl, "_self");
      // openWaitingContainer();
    }
  }).catch(() => {
  })
}
  return (
    <>
      <Topbar />
      {!thankPage ?
      <div className="paymymedium-wrapper">


      {is_pay_forward ? (
         <div class="paymymedium-container">
         <h5 className="payment-heading font-size-19"><b className="font-size-28"> This Medium is contributing your gift to VerySoul.</b><br />
             <p className="mt-2">Please enter any amount to contribute or continue with the mediums suggested amount.</p></h5>


         <div className="text-center mt-3">
             <label className="font-size-22 font-weight-bold">Suggested Amount : ${payItforwardFixPrice} USD </label>
             <div className="payment-methods-container">
                 <div>
                     <label className="font-size-22">Enter Amount</label>
                 </div>
             </div>
             <br />
             <div className="payment-methods-container giftyourmediums-wrap-input-payit-forward text-center ml-2">
                 <div>
                     <input
                         value={inputValue}
                         name="inputvalue"
                         type="number"
                         className="mt-3"
                         onChange={paymentChangeHandler}
                     />
                 </div>
             </div>
         </div>

         <div className="payment-save-btn-wrapper justify-content-center ">


             <Button
                 className={"resend-btn height-45 w-170"}
                 status={0}
                 onClick={() => payItForwardHandler()}
                 title="Pay Amount"
             />


         </div>
     </div>
      ) : (
        mediumstatus ? (
          <div className="paymymedium-container">
            <p>
              You are about to pay{" "}
              <b className="d5a67b">
                ${mediumInfo !== undefined && mediumInfo.amount}
              </b>{" "}
              for appointment{" "}
              <b className="d5a67b">
                {mediumInfo !== undefined && mediumInfo.appointment_id}
              </b>{" "}
              to{" "}
              <b className="d5a67b">
                {mediumInfo !== undefined && mediumInfo.medium_name}
              </b>{" "}
              Please choose payment method via you want to pay below.
            </p>
            <div className="payment-new-wrapper mt-5">
              <div className="payment-new-container mt-5">
                {mediumInfo !== undefined && mediumInfo.is_paypal_integrated && (
                  <button
                    onClick={() => paymentMethodHandler("paypal")}
                    data-toggle="modal"
                    className={`main-btn`}
                  >
                    <img src={"/integrationimages/paypal.png"} alt="Paypal" />
                  </button>
                )}
                {mediumInfo !== undefined && mediumInfo.is_stripe_integrated && (
                  <button
                    onClick={() => paymentMethodHandler("stripe")}
                    data-toggle="modal"
                    className={`main-btn`}
                  >
                    <img className="stripe-img" src={"/integrationimages/stripe.png"} alt="Stripe" />
                  </button>
                )}
                {mediumInfo !== undefined && mediumInfo.is_squareup_integrated && (
                  <button
                    onClick={() => paymentMethodHandler("squareup")}
                    data-toggle="modal"
                    className={`main-btn font-size-22`}
                  >
                    {/* Square */}
                    <img
                                                className="stripe-square"
                                                src={"/integrationimages/square-black.jpg"}
                                            />
                  </button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="paymymedium-container">
            <p>
              You are not Authenticated to view this page
            </p>
            <div className="payment-new-wrapper mt-5">
              <div className="payment-new-container mt-5">
                <button
                  onClick={() => navigate('/')}
                  className={`main-btn font-size-22`}
                >
                  login
                </button>
              </div>
            </div>
          </div>
        )
      )}
  
      </div>
      :
      <><ThankyouPopup /></>
}
      <Footer />
    </>
  );
}
