import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { updatePricing } from "../../../../services/profile";
import { toast } from "react-toastify";
import { setLoading } from "../../../../reducer/auth/auth";
import Coaching from '../coaching';

export default function PayItForward(props) {

    const dispatch = useDispatch();
    const { stepOneData, id, onProfileUpdate } = props;
    const { payitforward_pricing_30, payitforward_pricing_45, payitforward_pricing_60, payitforward_pricing_45_2, payitforward_pricing_60_2, session_currency, payitforward_coaching_pricing, coaching_info } = stepOneData || {};

    const updatePayItForward = (formData) => {
        dispatch(setLoading(true));
        const {
            payitforward_pricing_30, payitforward_pricing_45, payitforward_pricing_60, payitforward_pricing_45_2, payitforward_pricing_60_2,
            session_currency,
            payitforward_coaching_pricing,
        } = formData;
        const coachingPricing = {};
        coaching_info.forEach(item => {
            coachingPricing[item.id] = formData[item.id];
        });
        let item = {
            userId: id,
            payitforward_pricing: JSON.stringify({
                30: payitforward_pricing_30,
                45: payitforward_pricing_45,
                60: payitforward_pricing_60,
                452: payitforward_pricing_45_2,
                602: payitforward_pricing_60_2,
            }),
            payitforward_coaching_pricing: JSON.stringify(coachingPricing),
            session_currency: JSON.stringify({
                30: session_currency,
                45: session_currency,
                60: session_currency,
                452: session_currency,
                602: session_currency,
            }),

        };
        console.log(payitforward_coaching_pricing,"coaching_Info");
        updatePricing(item)
            .then((data) => {
                dispatch(setLoading(false));
                if (data.data.status) {
                    stepOneData.session_currency = session_currency
                    toast.success("Pay It Forward Prices Updated", { position: "top-center" });
                    if (typeof onProfileUpdate === 'function') {
                        onProfileUpdate(); // Call the parent function
                      } else {
                        console.error('onProfileUpdate is not a function');
                      }
                }
            })
            .catch((err) => {
                dispatch(setLoading(false));
                console.log(err);
            });
    }
    const coachingPricingData = (() => {
        if (!payitforward_coaching_pricing || payitforward_coaching_pricing === "") {
            return {};
        }
        try {
            return typeof payitforward_coaching_pricing === 'string'
                ? JSON.parse(payitforward_coaching_pricing)
                : payitforward_coaching_pricing;
        } catch (error) {
            console.error("Error parsing coaching pricing data:", error);
            return {};
        }
    })();
    
    return (
        <div className="row-one-step min-height-80vh">
            
            <Formik
                initialValues={{
                    payitforward_pricing_30: payitforward_pricing_30 || "",
                    payitforward_pricing_45: payitforward_pricing_45 || "",
                    payitforward_pricing_60: payitforward_pricing_60 || "",
                    payitforward_pricing_45_2: payitforward_pricing_45_2 || '',
                    payitforward_pricing_60_2: payitforward_pricing_60_2 || '',
                    session_currency: session_currency || '',
                    ...Object.fromEntries(coaching_info.map(item => [item.id, coachingPricingData[item.id] || ""])),
                }}
                onSubmit={(values, actions) => {
                    updatePayItForward(values);
                    actions.setSubmitting(false);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting,
                    setFieldTouched,
                }) => (
                    <Form>
                        <div className="full-side">
                            <label>
                                Please enter a suggested price for each of the session lengths below
                            </label>
                            <h5 className='text-underline'>Professional Client Sessions</h5>
                        </div>

                        <div className="mt-2 ml-5">
                            <label>30 Minutes</label>
                            <Field
                                className="w-25 p-2 amount_input"
                                id="payitforward_pricing_30"
                                name="payitforward_pricing_30"
                                type="number"
                                placeholder="Amount"
                            />
                            <ErrorMessage
                                name="payitforward_pricing_30"
                                component="div"
                                className="error"
                            />
                        </div>
                        <div className="mt-2 ml-5">
                            <label>45 Minutes</label>
                            <Field
                                className="w-25 p-2 amount_input"
                                id="payitforward_pricing_45"
                                name="payitforward_pricing_45"
                                type="number"
                                placeholder="Amount"
                            />
                            <ErrorMessage
                                name="payitforward_pricing_45"
                                component="div"
                                className="error"
                            />
                        </div>
                        <div className="mt-2 ml-5">
                            <label>60 Minutes</label>
                            <Field
                                className="w-25 p-2 amount_input"
                                id="payitforward_pricing_60"
                                name="payitforward_pricing_60"
                                type="number"
                                placeholder="Amount"
                            />
                            <ErrorMessage
                                name="payitforward_pricing_60"
                                component="div"
                                className="error"
                            />
                        </div>
                        <div className="mt-2 ml-5">
                            <label>45 Minutes for 2 People</label>
                            <Field
                                className="w-25 p-2 amount_input"
                                id="payitforward_pricing_45_2"
                                name="payitforward_pricing_45_2"
                                type="number"
                                placeholder="Amount"
                            />
                            <ErrorMessage name="payitforward_pricing_45_2" component="div" className="error" />
                        </div>
                        <div className="mt-2 ml-5">
                            <label>60 Minutes for 2 People</label>
                            <Field
                                className="w-25 p-2 amount_input"
                                id="payitforward_pricing_60_2"
                                name="payitforward_pricing_60_2"
                                type="number"
                                placeholder="Amount"
                            />
                            <ErrorMessage name="payitforward_pricing_60_2" component="div" className="error" />
                        </div>

                        <div className="full-side mt-5">

                            <h5 className='text-underline'>Coaching Sessions Princing</h5>
                        </div>
                        <div className="mt-2 ml-1">
                        {coaching_info && coaching_info.map((item) => (
                            <div key={item.id} className="mt-2 ml-5">
                                <label>{item.sub_session_name === "Group Coaching" 
                                    ? "Group Coaching (TBD)" 
                                    : item.sub_session_name}</label>
                                <Field
                                    className="w-25 p-2 amount_input"
                                    id={`${item.id}`}
                                    name={`${item.id}`}
                                    type="number"
                                    placeholder="Amount"
                                />
                                <ErrorMessage
                                    name={`${item.id}`}
                                    component="div"
                                    className="error"
                                />
                            </div>
                        ))}
                        </div>
                        <>
                            <div className="row-one-step p-2">
                                <div className="full-side">
                                    <label>Session Currency</label>

                                    {/* stepOneData.mediumship_level */}
                                    <div className="full-fild ml-5">
                                        <>
                                            <div className="check-field">
                                                <input
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    type="radio"
                                                    name="session_currency"
                                                    id="session_currency_usd"
                                                    value={"USD"}
                                                    checked={
                                                        values.session_currency === "USD"
                                                            ? true
                                                            : false
                                                    }
                                                // checked={
                                                //   parseInt(values.when_do_i_pay) === 1 ? true : false
                                                // }
                                                />
                                                <label
                                                    className=" w-unset"
                                                    htmlFor="when_do_i_pay_1"
                                                >
                                                    USD
                                                </label>
                                            </div>
                                            <div className="check-field">
                                                <input
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    type="radio"
                                                    name="session_currency"
                                                    id="session_currency_cad"
                                                    value={"CAD"}
                                                    checked={
                                                        values.session_currency === "CAD"
                                                            ? true
                                                            : false
                                                    }
                                                // checked={
                                                //   parseInt(values.when_do_i_pay) === 1 ? true : false
                                                // }
                                                />
                                                <label
                                                    className=" w-unset"
                                                    htmlFor="when_do_i_pay_1"
                                                >
                                                    CAD
                                                </label>
                                            </div>
                                            <div className="check-field">
                                                <input
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    type="radio"
                                                    name="session_currency"
                                                    id="session_currency_aud"
                                                    value={"AUD"}
                                                    checked={
                                                        values.session_currency === "AUD"
                                                            ? true
                                                            : false
                                                    }
                                                // checked={
                                                //   parseInt(values.when_do_i_pay) === 1 ? true : false
                                                // }
                                                />
                                                <label
                                                    className=" w-unset"
                                                    htmlFor="when_do_i_pay_1"
                                                >
                                                    AUD
                                                </label>
                                            </div>
                                        </>
                                    </div>
                                </div>
                            </div>
                            <div className="row-one-step ml-5">
                                <div className="full-side">
                                    <span className="error text-left">
                                        {errors.session_currency &&
                                            touched.session_currency &&
                                            errors.session_currency}
                                    </span>
                                </div>
                            </div>
                        </>
                        {/* Add button for form submission */}
                        <div className="mt-2 ml-5">
                            <button type="submit" className="resend-btn">
                                Submit
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}
