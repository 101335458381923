import React, { useEffect, useState } from 'react';
import './prepaypostpaycheck.css';
import Button from '../../../../../../components/button';
import { setPaymentTypeAvailbale } from '../../../../../../reducer/clientsSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

export default function Prepaypostpaycheck({ paytypeText, startSeesion, professionalInfo, getSubsessions, mediumSessionInfo }) {

    const dispatch = useDispatch();


    const [activePaymentTab, setActivePaymentTab] = useState("");
    const [activePaymentText, setActivePaymentText] = useState('');
    const [info, setInfo] = useState([]);
    const [durationValue, setDurationValue] = useState('');
    const [durationVal, setDurationVal] = useState('')
    const [amountVal, setAmountVal] = useState('')
    const [currencyVal, setcurrencyVal] = useState('')

    useEffect(()=>{
        setDurationValue('')
    },[])
    const onClickHandler = () => {
        if (durationValue === "") {
            // window.loadModal2('#Prepaypostpaycheck', 'show');
            toast.error("Check one of the sessions", {
                position: "top-center",
            });
            return;
        }
        let paymen;
        if (activePaymentTab === "before") {
            paymen = {
                ...professionalInfo,
                subsession_id: durationValue,
                payStatus: 'before'

            }
            let dur = info.filter((item) => item.subsession_id == durationValue)
            console.log(dur, 'before')
            getSubsessions(info, durationValue, durationVal ,amountVal,currencyVal)
            window.removeBackdrop()
            // window.loadModal2('#Prepaypostpaycheck', 'hide');

        }
        else {
            paymen = {
                ...professionalInfo, subsession_id: durationValue,
                payStatus: 'after'

            }
            let dur = info.filter((item) => item.subsession_id == durationValue)
          
            getSubsessions(info, durationValue, durationVal,amountVal,currencyVal)
            // window.loadModal2('#Prepaypostpaycheck', 'hide');
        }
        if (activePaymentTab === "") {
            // window.loadModal2('#Prepaypostpaycheck', 'show');
        }
        else {
            startSeesion(paymen)
            // window.loadModal2('#Prepaypostpaycheck', 'hide');
        }
        // window.loadModal2('#Prepaypostpaycheck', 'hide');

    }
    const changeHandler = (text, status) => {
        if (status === "before" && professionalInfo.pre_pay_schedule) {

        }
        else if (status === "before" && !professionalInfo.pre_pay_schedule) {
            toast.error("Only Pay After schedule available for this medium", {
                position: "top-center",
            });
            return;
        }
        if (status === "after" && professionalInfo.post_pay_schedule) {

        }
        else if (status === "after" && !professionalInfo.post_pay_schedule) {
            toast.error("Only Pre pay schedule available for this medium", {
                position: "top-center",
            });
            return;
        }
        setActivePaymentText(text);
        setActivePaymentTab(status);
        dispatch(setPaymentTypeAvailbale(text))
    }
    useEffect(() => {
        console.log(mediumSessionInfo, "mediumSessionInfo")
        setActivePaymentTab(mediumSessionInfo !== undefined ? mediumSessionInfo.type === "Pay_Before" ? 'before' : 'after' : '');
        let inf = mediumSessionInfo !== undefined && mediumSessionInfo.info
        setInfo(inf);
    }, [mediumSessionInfo])
    const onClickHandlerData = (data) => {
    setDurationValue(data.subsession_id);
    if(data.forTwo != 0){
        setDurationVal(data.duration+"2");
    }else{
        setDurationVal(data.duration);
    }
    setAmountVal(data.price);
    setcurrencyVal(data.currency);
    }
    return (
        <div
            className="modal fade mt-5"
            id="Prepaypostpaycheck"
            tabindex="-1"
            role="dialog"
            // aria-labelledby="myLargeModalLabel"
            data-backdrop="static"
            aria-hidden="true"

        >
            <div className="modal-dialog modal-lg modal-dialog-preview">
                <div className="modal-content">
                    {/* <div className="modal-header text-center">
                        <h5 className="modal-title" >
                            {" "}
                            Would to like to pay before session or after the session?
                        </h5>

                    </div> */}
                    {console.log(professionalInfo, "professionalInfo")}

                    <div className="modal-body p-5">
                        <></>
                        <div className="payment-new-wrapper">
                            <p>Medium Name : {professionalInfo !== undefined && professionalInfo.name} </p>
                            <p>Payment : {mediumSessionInfo !== undefined && mediumSessionInfo.type === "Pay_Before" ? 'Required At booking' : 'After Session'} </p>
                            <p>Currency : {info.length && info[0].currency} </p>
                            <div>
                                <div className="">
                                    <div className="full-side">

                                        <div className="full-fild">
                                            {info.length && info.map((item) => <div className="check-field float-unset">
                                                <input
                                                    onChange={(e) => { onClickHandlerData(item) }}
                                                    //   onBlur={handleBlur}setDurationValue(e.target.value)
                                                    type="radio"
                                                    name="when_do_i_pay"
                                                    id="when_do_i_pay_1"
                                                    value={item}
                                                    className='border-unset'
                                                //   checked={
                                                //     values.when_do_i_pay.includes("1") ? true : false
                                                //   }

                                                />
                                                <label className=" w-unset  border-unset" htmlFor="when_do_i_pay_1">
                                                    {item.duration} Minutes for {item.forTwo != 0 ?'2 for':''} ${item.price} 
                                                </label>
                                            </div>)}
                                            {/* <div className="check-field float-unset">
                                                <input
                                                    //   onChange={handleChange}
                                                    //   onBlur={handleBlur}
                                                    type="radio"
                                                    name="when_do_i_pay"
                                                    id="when_do_i_pay_2"
                                                    value={"2"}
                                                    className='border-unset'
                                                //   checked={
                                                //     values.when_do_i_pay.includes("2") ? true : false
                                                //   }
                                                // checked={
                                                //   parseInt(values.when_do_i_pay) === 2 ? true : false
                                                // }
                                                />
                                                <label htmlFor="when_do_i_pay_2" className=" w-unset border-unset">
                                                    45 Minutes $10
                                                </label>
                                            </div>
                                            <div className="check-field float-unset">
                                                <input
                                                    //   onChange={handleChange}
                                                    //   onBlur={handleBlur}
                                                    type="radio"
                                                    name="when_do_i_pay"
                                                    id="when_do_i_pay_2"
                                                    value={"2"}
                                                    className='border-unset'
                                                //   checked={
                                                //     values.when_do_i_pay.includes("2") ? true : false
                                                //   }
                                                // checked={
                                                //   parseInt(values.when_do_i_pay) === 2 ? true : false
                                                // }
                                                />
                                                <label htmlFor="when_do_i_pay_2" className=" w-unset  border-unset">
                                                    60 Minutes $15
                                                </label>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className='font-size-12 d-block'>*All payments made directly to Medium</p>
                            {/* <div className="payment-new-container">
                                <button
                                    onClick={() => changeHandler('Pay Before Session', "before")}
                                    className={`main-btn ${activePaymentTab === "before" ? "payment-active-tab" : ""
                                        }`}
                                >
                                    {" "}
                                    Pay Before Session
                                </button>
                                <button
                                    onClick={() => changeHandler('Pay After Session', "after")}
                                    className={`main-btn ${activePaymentTab === "after" ? "payment-active-tab" : ""
                                        }`}
                                >
                                    {" "}
                                    Pay After Session
                                </button>
                            </div> */}
                        </div>
                        <div className='text-center mt-5'>
                        <Button
                                status={0}
                                data-dismiss={'modal'}
                                type="button"
                                className={`resend-btn payment-continue-btn height-45 m-2  model-close-btn`}
                                // onClick={onClickHandler}
                                // title={requestChangePhoneNo ? "Submit" : "Request Change"}
                                title={"Cancel"}

                            />
                            <Button
                                status={0}
                                data-dismiss={durationValue !== "" ? 'modal' : ''}
                                type="button"
                                className={`resend-btn payment-continue-btn height-45 m-2`}
                                onClick={onClickHandler}
                                // title={requestChangePhoneNo ? "Submit" : "Request Change"}
                                title={"Continue"}

                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
