import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../pages/login/login";
import ClientRegister from "../pages/register/client/client";
import RegisterMedium from "../pages/register/medium/medium";
import ResetPassword from "../pages/resetpassword";
import ForgotPassword from "../pages/forgotpassword";
import Layout from "../layout/layout";
import Comingsoon from "../pages/comingsoon";
import MediumThanks from "../pages/mediumThanks";
import Homepage from "../pages/homepage";
import PractitionerInfo from "../pages/admin/professionalPublicView";
import Embeded from "../components/embeded";
import PayMyMedium from "../pages/paymymedium";
import GuestBooking from "../pages/guestbooking";
import ThankyouPopup from "../pages/client/appointment/premiumsessions/practitonerInfo/payment/thankyou";
import AppointmentConfirm from "../pages/client/appointmentconfirm";
import VerifyEmail from "../pages/admin/calendar/verifyemail";
import RegisterMediumVideo from "../pages/registration-video";

const LoggedOutRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/layout" element={<Layout />} />
      <Route path="/" element={<Homepage />} />
      <Route path="/verifyemail" element={<VerifyEmail />} />
      <Route path="/register-client" element={<ClientRegister />} />
      <Route path="/register-medium" element={<RegisterMedium />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/profile-view/:id" element={<PractitionerInfo view={'public'} />} />
      <Route path="/embeded/:id" element={<Embeded />} />
      <Route path="/thank-you" element={<Comingsoon />} />
      <Route path="/thankyou" element={<ThankyouPopup />} />
      <Route path="/thanks" element={<MediumThanks />} />
      <Route path="/registration" element={<RegisterMediumVideo />} />
      <Route path="/paymymedium/:id" element={<PayMyMedium />} />
      <Route path="/guest-booking/:id" element={<GuestBooking />} />
      <Route path="/appointment-confirmation/:id" element={<AppointmentConfirm />} />
      <Route path="/" element={<Navigate to="/" />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
export default LoggedOutRoutes;
