import React from "react";
// import {
//   getZoomAuthUrl,
// } from "../../../services/uidata";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { updateUserTechnologies } from "../../../services/profile";
import { setLoading } from "../../../reducer/auth/auth";

const StepThree = (props) => {
  const dispatch = useDispatch();
  const { test_mode } = useSelector(
    (state) => state.user.user
  );
  const { NextStep, PreviousStep, stepThreeData, setValues } = props;

  const RunFunction = (formData) => {
    dispatch(setLoading(true));
    setValues(formData, 3);
    const {
      electronicCalendars,
      zoomAccountType,
      preferredDevice,
      preferredBrowser,
      zoom_personal_link,
      paypal_me_link
    } = formData;
    let item = {
      calendar_type: electronicCalendars ,
      zoom_account_type: zoomAccountType,
      preferred_device: preferredDevice,
      preferred_browser: preferredBrowser,
      zoom_personal_link: zoom_personal_link,
      paypal_me_link: paypal_me_link
    };
    updateUserTechnologies(item)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          NextStep();
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };
  // const getAuthUrl = ()=>{
  //   dispatch(setLoading(true));
  //   getZoomAuthUrl()
  //     .then((data) => {
  //       dispatch(setLoading(false));
  //       if (data.data.status) {
  //         window.open(data.data.data.redirect_url, "_blank");
  //       }
  //     })
  //     .catch((err) => {
  //       dispatch(setLoading(false));
  //       console.log(err);
  //     });
  // }
  const customHandleChange = (errors) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const changeHandler = (event, electronicCalendars, setFieldValue) => {
    const noneIndex = electronicCalendars.indexOf("None");
    console.log(noneIndex);
    let array = electronicCalendars;
    if (event.target.checked && event.target.value === "None") {
      array = ["None"];
      setFieldValue("electronicCalendars", array);
    }
    if (event.target.checked && event.target.value !== "None") {
      if (noneIndex !== -1) {
        array.splice(noneIndex, 1);
      }
      array.push(event.target.value);
      setFieldValue("electronicCalendars", array);
    }
    if (!event.target.checked) {
      // array.splice(noneIndex, 1);
      const index = array.indexOf(event.target.value);
      array.splice(index, 1);
      setFieldValue("electronicCalendars", array);
    }
    console.log(array);
  };
  return (
    <Formik
      initialValues={stepThreeData}
      validate={(values) => {
        const errors = {};
        if (!values.electronicCalendars.length)
          errors.electronicCalendars = "Required";
        if (!values.zoomAccountType) errors.zoomAccountType = "Required";
        if (!values.preferredDevice.length) errors.preferredDevice = "Required";
        if (!values.preferredBrowser.length)
          errors.preferredBrowser = "Required";
        // if (!values.lastName) errors.lastName = "Required";
        // if (!values.phone) errors.phone = "Required";
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        RunFunction(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="from-inner-box radeo check-box-input payment-saction">
            <div className="full-fild">
              <label>Which electronic Calendars do you use currently? *</label>
              <div className="check-field">
                <input
                  onChange={(e) =>
                    changeHandler(e, values.electronicCalendars, setFieldValue)
                  }
                  onBlur={handleBlur}
                  id="googlecalendar"
                  type="checkbox"
                  value="Google Calendar"
                  name="electronicCalendars"
                  checked={
                    values.electronicCalendars.includes("Google Calendar")
                      ? true
                      : false
                  }
                />
                <label htmlFor="googlecalendar">Google Calendar</label>
              </div>
              <div className="check-field">
                <input
                  onChange={(e) =>
                    changeHandler(e, values.electronicCalendars, setFieldValue)
                  }
                  onBlur={handleBlur}
                  id="icloundcalendar"
                  type="checkbox"
                  value="Cloud Calendar"
                  name="electronicCalendars"
                  checked={
                    values.electronicCalendars.includes("Cloud Calendar")
                      ? true
                      : false
                  }
                />
                <label htmlFor="icloundcalendar">iCloud Calendar</label>
              </div>
              <div className="check-field">
                <input
                  onChange={(e) =>
                    changeHandler(e, values.electronicCalendars, setFieldValue)
                  }
                  onBlur={handleBlur}
                  id="Outlook"
                  type="checkbox"
                  value="Outlook"
                  name="electronicCalendars"
                  checked={
                    values.electronicCalendars.includes("Outlook")
                      ? true
                      : false
                  }
                />
                <label htmlFor="Outlook">Outlook</label>
              </div>
              <div className="check-field">
                <input
                  onChange={(e) =>
                    changeHandler(e, values.electronicCalendars, setFieldValue)
                  }
                  onBlur={handleBlur}
                  id="365calendar"
                  type="checkbox"
                  value="Office 365 Calendar"
                  name="electronicCalendars"
                  checked={
                    values.electronicCalendars.includes("Office 365 Calendar")
                      ? true
                      : false
                  }
                />
                <label htmlFor="365calendar">Office 365 Calendar</label>
              </div>
              <div className="check-field">
                <input
                  onChange={(e) =>
                    changeHandler(e, values.electronicCalendars, setFieldValue)
                  }
                  onBlur={handleBlur}
                  id="exchangecalendar"
                  type="checkbox"
                  value="Exchange Calendar"
                  name="electronicCalendars"
                  checked={
                    values.electronicCalendars.includes("Exchange Calendar")
                      ? true
                      : false
                  }
                />
                <label htmlFor="exchangecalendar">Exchange Calendar</label>
              </div>
              <div className="check-field">
                <input
                  onChange={(e) =>
                    changeHandler(e, values.electronicCalendars, setFieldValue)
                  }
                  onBlur={handleBlur}
                  id="othercalendar"
                  type="checkbox"
                  value="Other"
                  name="electronicCalendars"
                  checked={
                    values.electronicCalendars.includes("Other") ? true : false
                  }
                />
                <label htmlFor="othercalendar">Other</label>
              </div>
              <div className="check-field">
                <input
                  onChange={(e) =>
                    changeHandler(e, values.electronicCalendars, setFieldValue)
                  }
                  onBlur={handleBlur}
                  id="nonecalendar"
                  type="checkbox"
                  value="None"
                  name="electronicCalendars"
                  checked={
                    values.electronicCalendars.includes("None") ? true : false
                  }
                />
                <label htmlFor="nonecalendar">None</label>
              </div>
            </div>
            {!values.electronicCalendars.includes("Google Calendar") ?
              <label>{"Note: You will need a Google Calendar to connect to our system but we will help you connect that with your other personal calendar."}</label> : ""}
            <span className="error margin-top-115">
              {errors.electronicCalendars &&
                touched.electronicCalendars &&
                errors.electronicCalendars}
            </span>

          </div>

          <div className="from-inner-box radeo check-box-input payment-saction in-order">
            <div className="full-fild">
              <label>
                In order for others to book with you, you will need a personal
                Zoom account. Which type do you have?
              </label>
              {/* <p className="signup">
                <a onClick={getAuthUrl} href="javscript:void(0)">Sign up forCalendly here.</a>(Note: Limitations on free
                accounts)
              </p> */}
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="I have a paid account"
                  id="zoompaidacc"
                  type="radio"
                  name="zoomAccountType"
                  checked={
                    values.zoomAccountType === "I have a paid account"
                      ? true
                      : false
                  }
                />
                <label htmlFor="zoompaidacc">I have a paid account</label>
              </div>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="I have a free account"
                  id="zoomfreeaccount"
                  type="radio"
                  name="zoomAccountType"
                  checked={
                    values.zoomAccountType === "I have a free account"
                      ? true
                      : false
                  }
                />
                <label htmlFor="zoomfreeaccount">I have a free account</label>
              </div>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="None"
                  id="nonezoomacc"
                  type="radio"
                  name="zoomAccountType"
                  checked={values.zoomAccountType === "None" ? true : false}
                />
                <label htmlFor="nonezoomacc">None</label>
              </div>
            </div>
            <span className="error marg-top-55">
              {errors.zoomAccountType &&
                touched.zoomAccountType &&
                errors.zoomAccountType}
            </span>
          </div>
         {test_mode === 1 && <div class="from-inner-box ">
            <div class="full-fild">
              <label>Please enter your personal zoom link.</label>
              <input
                className={`mb-2 ${
                  errors.zoom_personal_link &&
                  touched.zoom_personal_link &&
                  errors.zoom_personal_link
                    ? "error-boundry"
                    : ""
                }`}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.zoom_personal_link}
                id="zoom_personal_link"
                name="zoom_personal_link"
                type="text"
                placeholder="Zoom Link"
              />
              <h6 className="form-h6">
                To know your personal zoom link{" "}
                <a
                  href="https://support.zoom.us/hc/en-us/articles/201362843-Personal-meeting-ID-PMI-and-personal-link"

                  target="_blank"
                  rel="noopener noreferrer"

                >
                  Click here
                </a>
              </h6>
            </div>
          </div>}
          <div class="from-inner-box radeo check-box-input all-devices">
            <div class="full-fild">
              <label>What is your preferred device when using Zoom?</label>
              <ul className="ul-li-list">
                <li>
                  <div className="check-field">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="windows"
                      type="checkbox"
                      value="windows"
                      name="preferredDevice"
                      checked={
                        values.preferredDevice.includes("windows")
                          ? true
                          : false
                      }
                    />
                    <label htmlFor="windows">
                      Windows - Personal
                      <br />
                      Computer / Laptop
                    </label>
                  </div>
                </li>
                <li>
                  <div className="check-field">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="tablet-ipad"
                      type="checkbox"
                      value="Mac"
                      name="preferredDevice"
                      checked={
                        values.preferredDevice.includes("Mac") ? true : false
                      }
                    />
                    <label htmlFor="tablet-ipad"> Mac</label>
                  </div>
                </li>
                <li>
                  <div className="check-field">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="tablet-android"
                      type="checkbox"
                      value="Tablet-iPad"
                      name="preferredDevice"
                      checked={
                        values.preferredDevice.includes("Tablet-iPad")
                          ? true
                          : false
                      }
                    />
                    <label htmlFor="tablet-android">Tablet-iPad</label>
                  </div>
                </li>
                <li>
                  <div className="check-field">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="iPhone"
                      type="checkbox"
                      value="iPhone"
                      name="preferredDevice"
                      checked={
                        values.preferredDevice.includes("iPhone") ? true : false
                      }
                    />
                    <label htmlFor="iPhone">iPhone</label>
                  </div>
                </li>
                <li>
                  <div className="check-field">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="android"
                      type="checkbox"
                      value="Android"
                      name="preferredDevice"
                      checked={
                        values.preferredDevice.includes("Android")
                          ? true
                          : false
                      }
                    />
                    <label htmlFor="android">Android</label>
                  </div>
                </li>
                <li>
                  <div className="check-field">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="Otherdevice"
                      type="checkbox"
                      value="Other"
                      name="preferredDevice"
                      checked={
                        values.preferredDevice.includes("Other") ? true : false
                      }
                    />
                    <label htmlFor="Otherdevice">Other</label>
                  </div>
                </li>
              </ul>
            </div>
            <span className="error">
              {errors.preferredDevice &&
                touched.preferredDevice &&
                errors.preferredDevice}
            </span>
          </div>
          <div className="from-inner-box radeo check-box-input all-devices">
            <div className="full-fild">
              <label>What is your preferred browser when using Zoom?</label>
              <ul className="ul-li-list browser-saction">
                <li>
                  <div className="check-field">
                    <input
                      className="z-index-1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="chrome"
                      type="checkbox"
                      value="Google Chrome"
                      name="preferredBrowser"
                      checked={
                        values.preferredBrowser.includes("Google Chrome")
                          ? true
                          : false
                      }
                    />
                    <div className="icon-image  z-index-0">
                      <img src="images/chrome.png" alt="chromebrowser" />
                    </div>
                    <label htmlFor="chrome">
                      Google <br />
                      Chrome
                    </label>
                  </div>
                </li>
                <li>
                  <div className="check-field">
                    <input
                      className="z-index-1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="firefox"
                      type="checkbox"
                      value="FireFox"
                      name="preferredBrowser"
                      checked={
                        values.preferredBrowser.includes("FireFox")
                          ? true
                          : false
                      }
                    />
                    <div className="icon-image  z-index-0">
                      <img src="images/mozila.png" alt="FireFoxbrowser" />
                    </div>
                    <label htmlFor="firefox">FireFox</label>
                  </div>
                </li>
                <li>
                  <div className="check-field">
                    <input
                      className="z-index-1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="edge"
                      type="checkbox"
                      value="Edge"
                      name="preferredBrowser"
                      checked={
                        values.preferredBrowser.includes("Edge") ? true : false
                      }
                    />
                    <div className="icon-image  z-index-0">
                      <img src="images/entnet.png" alt="Edgebrowser" />
                    </div>
                    <label htmlFor="edge">Edge</label>
                  </div>
                </li>
                <li>
                  <div className="check-field">
                    <input
                      className="z-index-1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="safari"
                      type="checkbox"
                      value="Safari"
                      name="preferredBrowser"
                      checked={
                        values.preferredBrowser.includes("Safari")
                          ? true
                          : false
                      }
                    />
                    <div className="icon-image  z-index-0">
                      <img src="images/safari.png" alt="Safaribrowser" />
                    </div>
                    <label htmlFor="safari">Safari</label>
                  </div>
                </li>
                <li>
                  <div className="check-field">
                    <input
                      className="z-index-1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="otherbrowser"
                      type="checkbox"
                      value="other"
                      name="preferredBrowser"
                      checked={
                        values.preferredBrowser.includes("other") ? true : false
                      }
                    />
                    <div className="icon-image z-index-0">
                      <img src="images/word-tow.png" alt="otherbrowser" />
                    </div>
                    <label htmlFor="otherbrowser">other</label>
                  </div>
                </li>
              
                <h6 className="note">{values.preferredBrowser.includes("other")?"Note: For optimum performance: use Chrome, Safari, or Edge browser":''}</h6>
              </ul>
              <span className="error">
                {errors.preferredBrowser &&
                  touched.preferredBrowser &&
                  errors.preferredBrowser}
              </span>
            </div>
            <br />
            {/* <div className="left-side">
              <label>
                Paypal.me Link <span className="font-size-12">(only needed once you qualify for Outreach and Paying clients with us)</span>
              </label>
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.paypal_me_link}
                type="text"
                id="paypal_me_link"
                name="paypal_me_link"
                placeholder="paypal link"
              />
            </div> */}
          </div>
          <input
            onClick={PreviousStep}
            type="button"
            name="previous"
            className="previous action-button-previous"
            value="Back"
          />
          <input
            type="submit"
            onClick={() => customHandleChange(errors)}
            name="next"
            className="next action-button"
            value="Next"
          />
        </form>
      )}
    </Formik>
  );
};
export default StepThree;
