import React from "react";
import ClientTable from "../table";

export default function StarterClient(props) {
  return (
    <>
      <ClientTable {...props} />
    </>
  );
}
