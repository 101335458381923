import React, { useEffect } from "react";
import { toTitleCase } from "../../../../utils/helpers";
export default function InfoPopupclient({ feedBackinfo }) {
  return (
    <div
      className="modal fade"
      id="InfoPopupclient"
      role="dialog"
      data-backdrop="true"
    >
      <div className="modal-dialog modal-dialog-preview">
        {/* <button type="button" className="close" data-dismiss="modal">
        &times;
      </button> */}
        <div className="modal-content margin-top-300">
          <div className="modal-body">
            <div className="profile-video"></div>
            <div>
              <h3 className="text-center">Client Booking Information</h3>

              {Object.keys(feedBackinfo).length > 0 && (
                <>
                  {Object.entries(feedBackinfo).map(([key, value]) => (
                    <span className="d-block mb-2 mt-2">
                      <br />
                      <h4 className="text-center">{key}</h4>
                      {
                        <>
                          {Object.entries(value).map(([keys, values]) => (
                            <>
                              {" "}
                              <h6 className="text-center">
                                {toTitleCase(keys)} : {values}
                              </h6>
                            </>
                          ))}
                        </>
                      }
                    </span>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
