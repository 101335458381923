import Vimeo from '@u-wave/react-vimeo'
import React, { useEffect, useState } from 'react'
import { FadeLoader } from 'react-spinners'
import Button from '../../../../components/button'
import { Link, useNavigate } from 'react-router-dom'
import TickMark from '../../../../components/tickmark'
import { creatDummyAppointment, saveStepsProgress } from '../../../../services/medium'

export default function StepThree({ NextStep,innerWidthHandler }) {
    const navigate = useNavigate();

    const [showLoader, setShowLoader] = useState(true);
    const [verificationStatus, setVerificationStatus] = useState(0);
    const [check1, setCheck1] = useState(false);
    const [check2, setCheck2] = useState(false);
    const [check3, setCheck3] = useState(false);
    const [check4, setCheck4] = useState(false);
    const [error, setError] = useState(false);


    useEffect(() => {
        let check = localStorage.getItem('no-api-call');
        console.log(check);
        if (check === null) {

            creatDummyAppointmentHandler()
        }
    }, [])
    const changeHandler = (e, setCheck, type) => {

        setCheck(e.target.checked);
    };

    const onClickHandler = () => {
        if (
            check1 &&
            check3 &&
            check4
        ) {
            setError(false);
            setVerificationStatus(1);
            setShowLoader(false);
            savestep()
        } else {
            setError(true);
        }
    };
    const creatDummyAppointmentHandler = () => {
        let body = {
            service: 1,
            session_length: 62
        }
        creatDummyAppointment(body).then((data) => {
            if(data.data.status){

                localStorage.setItem('no-api-call',"no-api-call");
            }
        }).then(() => {

        })
    }
    const savestep = () => {
        let body = {
            step_number: 3
        }
        saveStepsProgress(body).then((data) => {
            if (data.data.status) {
                localStorage.setItem('step',3)
                NextStep()
            }
        }).catch(() => {

        })
    }
    return (
        <div>
            <div className='text-left  pl-5 pr-5'>

                <h3 className='font-size-40'>Test Connections</h3>
                <ol className='font-size-18 line-height-24px'>
                    <li>Now our system will make a test booking with you for 15 minutes from now. This is<p className='text-underline'>not a real appointment and you do NOT need to join the Zoom.</p>  </li>
                    <li>Please check your email, listed time zone, Google Calendar, and Zoom account.</li>
                    <li>Then check the boxes below.</li>
                    <li>If you have any questions or issues please email us at <a href='mailto:support@verysoul.com'>support@verysoul.com</a></li>
                </ol>
                {/* <p className='font-size-18 line-height-24px'>
                    Now our system will make a test appointment booking with you. Please check your email, calendar (if connected), zoom account, and the Appointments page.
                    Then check the boxes below. If you have any questions or issues please email us at <a href='mailto:support@verysoul.com'>support@verysoul.com</a>
                </p> */}
            </div>

            <div class="question-container">
                <div class="text-left  pl-5 pr-5">
                    <div class="">
                        <div class="card px-0 pt-4 pb-0 mt-3">
                            <form id="">
                                <fieldset>
                                    <div class="form-card margin-unset">
                                        <div class="row wrap">
                                            <h2 className="text-left mb-3">
                                                Please read and confirm each item:
                                            </h2>


                                            <div class="new margin-unset">
                                                <div class="form-group">
                                                    <input
                                                        onChange={(e) => {
                                                            changeHandler(e, setCheck1);
                                                        }}
                                                        checked={check1}
                                                        name="check1"
                                                        type="checkbox"
                                                        id="check1"
                                                    />
                                                    <label htmlFor="check1">Appointment to email
                                                    </label>
                                                    <p className='ml-5 mt-2'>(May take up to 10 minutes if you have an outlook or yahoo email address. Please double check your spam or junk folders for our emails)</p>
                                                    {/* <ol className='ml-5 mt-1 line-height-20px' type="i">
                                                        <li>                                         </li>
                                                    </ol> */}

                                                </div>
                                                <div class="form-group">
                                                    <input
                                                        checked={check4}
                                                        onChange={(e) => {
                                                            changeHandler(e, setCheck4);
                                                        }}
                                                        name="check4"
                                                        type="checkbox"
                                                        id="check4"
                                                    />
                                                    <label htmlFor="check4">
                                                    My Time Zone is displayed correctly in email
                                                    </label>

                                                </div>
                                                <div class="form-group">
                                                    <input
                                                        onChange={(e) => {
                                                            changeHandler(e, setCheck2);
                                                        }}
                                                        checked={check2}
                                                        name="check2"
                                                        type="checkbox"
                                                        id="check2"
                                                    />
                                                    <label htmlFor="check2">
                                                        Appointment to calendar (optional)

                                                    </label>
                                                </div>

                                                <div class="form-group">
                                                    <input
                                                        onChange={(e) => {
                                                            changeHandler(e, setCheck3);
                                                        }}
                                                        checked={check3}
                                                        name="check3"
                                                        type="checkbox"
                                                        id="check3"
                                                    />
                                                    <label htmlFor="check3">
                                                        Zoom link created
                                                    </label>
                                                </div>
                                               


                                                {error && (
                                                    <div>
                                                        <p className="red">*Please Select all required check boxes</p>
                                                    </div>
                                                )}
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>



                                    <>

                                    </>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className='text-left pl-5 pr-5'>

                <Button
                    className={"action-button margin-unset"}
                    status={0}

                    onClick={() => onClickHandler()}
                    title="Next" />

                {/* <Button
                    className={"action-button margin-unset ml-5"}
                    status={0}
                    onClick={openAppointMent}
                    title="Link your calendar" /> */}


            </div>

        </div>
    )
}
