import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// import { slotsArray } from "./data";

import {
  getMatchedAvailabilites,
  getSlotsByDate,
} from "../../../../services/client";
//reducer
import {
  // setBookingTime,
  setmediumListItems,
  setbookingBtnStatus,
  setBookingDate,
  setBookingIds,
  setMediumsToBeMatch,
} from "../../../../reducer/clientsSlice";
import { setLoading } from "../../../../reducer/auth/auth";
import { removFirstDash, removeString } from "../../../../utils/helpers";
import { getTimeImage } from "../../../../utils/utils";
import { useParams, useSearchParams } from "react-router-dom";

export default function Slots({
  slots,
  setSlots,
  setBookingTime,
  sloteupdate,
  serviceValue,
  mediumValue,
  bookingTime,
  bookingDate,
  month_empty
}) {
  //hooks
  const { id, timezone } = useSelector((state) => state.user.user);
  const auth = useSelector((state) => state.auth);

  const [params] = useSearchParams();
  const dispatch = useDispatch();

  const preferences = useSelector((state) => state.client);

  const [slotsupdated, setSlotsUpdated] = useState(false);

  const [active, setActive] = useState(false);

  useEffect(() => {
    if (sloteupdate) {

      setSlotsUpdated(true);
      setActive(true);
    }
    else {
      setSlotsUpdated(false);
      setActive(false);
    }
    console.log(slots, "slots")
  }, [sloteupdate]);


  //functions

  const changeStatus = (e, slot) => {
    // if (user_type === undefined) {
    let { value } = e.target;
    setBookingTime(value);

    setSlots(
      slots.map((slot) => {
        if (slot.datetime === value) {
          return {
            ...slot,
            ...(slot.selectedStatus = true),
            ...(slot.status = true),
          };
        } else {
          return {
            ...slot,
            ...(slot.selectedStatus = false),
            ...(slot.status = false),
          };
        }
      })
    );

  };




  useEffect(() => {
    // setSlots([]);
    // setSlotsUpdated(false);
  }, [timezone]);
  return (
    <>
      {slotsupdated && (
        <>
          {active &&
            React.Children.toArray(
              slots.length > 0 ? slots.map((slot) => (
                <div className={`check-field`}>
                  <input
                    className={`${slot.selectedStatus ? "dbb38f-border" : ""}`}
                    onChange={(e) => changeStatus(e, slot)}
                    value={slot.datetime}
                    checked={slot.status}
                    type="radio"
                    name="check"
                  />
                  <label
                    className={`${slot.selectedStatus ? "dbb38f-background" : ""
                      }`}
                    htmlFor="my-checktow"
                  >
                    {/* {moment(slot.date).format("LL")}
                <br /> */}
                    {slot.datetime + " "}
                    {getTimeImage(slot.datetime) ? (
                      <img
                        src="/localImages/moon.png"
                        className="star-imh-moon"
                        alt="time-image"
                      />
                    ) : (
                      ""
                    )}
                  </label>
                </div>
              ))
                :
                <h4 className="mt-5 mb-5"> {'No Availability Found For Selected Date'}</h4>
              // <h4 className="mt-5 mb-5"> {serviceValue=== 2 ? 'Please Select Medium To View Slots': 'No Availability Found For Selected Date'}</h4>
            )}
        </>
      )}
      {month_empty ?
        <h4 className="mt-5 mb-5"> {'Sorry no appointments available for this month. Please check future months.'}</h4>
        :
        <>

          {serviceValue === 2 && bookingDate == "" && <h4 className="mt-5 mb-5"> {'Please select coach or date to start the booking process.'}</h4>}
        </>
      }
    </>
  );
}