import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Formik } from "formik";

import {
  requestScreenNameChange,
  updateInitialProfile,
} from "../../../../services/profile";
import { setLoading } from "../../../../reducer/auth/auth";
import { useDispatch } from "react-redux";
import { TagsInput } from "react-tag-input-component";
import { toast } from "react-toastify";
import PhoneInputField from "../../../../components/Inputfields/phoneinput";
import Button from "../../../../components/button";
import ProfilePicture from "../subcomponent/profilepicture";
import { setNewTimeZoneData } from "../../../../reducer/userSlice";
import {
  filterVisibleTimeZones,
  localization,
  toolbar,
} from "../../../../utils/helpers";

//editors
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Link } from "react-router-dom";
// import {Editors} from "../../../../components/editor";

// import { getCountryCode } from "../../../../utils/helpers";
export default function Profile(props) {
  const dispatch = useDispatch();
  const {
    // stepTwoData,
    formLanguages,
    specialtyData,
    countryData,
    stepOneData,
    getProfile,
    timezoneData,
    reloadTimeZones
    // timezoneOnly,
  } = props;

  // const [formData,setFormData] = useState(stepOneData)
  const [newformData, setNewFormData] = useState([]);
  const [newSpecilityData, setNewSpecilityData] = useState([]);
  const [newCountryData, setNewCountryData] = useState([]);
  const [newTimezoneData, setnewTimezoneData] = useState([]);
  const [openOtherLnaguages, setOpenOtherLnaguages] = useState(false);
  const [openOtherSpecility, setOpenOtherSpecility] = useState(false);
  // const [userCountryCode, setUserCountryCode] = useState("US");
  const [userCountryCodeChange] = useState("US");
  const [requestChangePhoneNo, setRequestChangePhoneNo] = useState(false);
  const [newPhoneNo, setNewPhoneNo] = useState("");
  const [
    requestChangeSuceessMessagePhoneNo,
    setrequestChangeSuceessMessagePhoneNo,
  ] = useState("");

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [contentState, setContentState] = useState(null);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [data, setData] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setEditorLoaded(true);
    }, 4000);
  }, []);
  useEffect(() => {
    setNewFormData(
      formLanguages.map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.name,
        };
      })
    );
    setNewSpecilityData(
      specialtyData.map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.specialty_name,
        };
      })
    );
    setNewCountryData(
      countryData.map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.country_name,
        };
      })
    );
    setnewTimezoneData(filterVisibleTimeZones(timezoneData));
  }, ["", formLanguages, specialtyData, countryData, timezoneData]);
  useEffect(() => {
    setTimeout(() => {
      // console.log(stepOneData.biographicalInfo,"stepOneData.biographicalInfo")
      // const contentState = convertFromRaw(JSON.parse(stepOneData.biographicalInfo));
      //   const editorState = EditorState.createWithContent(contentState);
      //   setEditorState(editorState)
    }, 5000);
    // setEditorState(stepOneData.biographicalInfo)
  }, [stepOneData]);
  const handleContentStateChange = (contentState) => {
    setContentState(draftToHtml(contentState));
  };

  const handleEditorStateChange = (editorState, setFieldValue, name) => {
    console.log(editorState.getCurrentContent());
    setFieldValue(name, JSON.stringify(editorState));
    setEditorState(editorState);
  };

  const onSaveContent = () => {
    const contentState = editorState.getCurrentContent();
    const content = JSON.stringify(convertToRaw(contentState));
    console.log(content);
    return content;
    // Send `content` to your server for storage
    // You can use an API call (e.g., Axios) to send the data to your backend
  };
  const RunFunction = (formData) => {
    console.log(formData, "formData");
    dispatch(setLoading(true));
    // setValues(formData);

    const {
      phone,
      whatsapp,
      languages,
      mediumshipLevel,
      mediumshipSpecialities,
      country,
      website,
      biographicalInfo,
      otherLanguages,
      otherMediumSpecialities,
      timezone,
      medium_professional_category,
      mediumship_level_admin,
      session_lengths,
      session_lengths_currency,
      allow_free_coaching_session,
    } = formData;

    dispatch(setNewTimeZoneData(filterTimeZone(timezone.id)));

    let lang = languages.map((item) => {
      return item.id;
    });
    let speci = mediumshipSpecialities.map((item) => {
      return item.id;
    });
    console.log("bodyData", timezone.id);
    // let count = country.map((item)=> {return item.id})
    // console.log(lang,speci,count)
    let item = {
      // first_name: firstName,
      // last_name: lastName,
      phone: phone,
      whatsapp: whatsapp,
      website: website,
      country: country.label !== undefined ? country.label : country,
      languages: lang,
      mediumship_specialties: speci,
      current_mediumship_level: parseInt(mediumshipLevel),
      // biographical_info:onSaveContent(),
      biographical_info: biographicalInfo,
      other_languages: otherLanguages,
      other_mediumship_specialties: otherMediumSpecialities,
      timezone: timezone.id,
      medium_professional_category: medium_professional_category,
      mediumship_level: mediumship_level_admin,
      // when_do_i_pay: when_do_i_pay,
      session_length: session_lengths,
      session_currency: session_lengths_currency,
      allow_free_coaching_session: allow_free_coaching_session,
      
    };
    updateInitialProfile(item)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          toast.success("Profile Updated", { position: "top-center" });
          getProfile();
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  const filterTimeZone = (id) => {
    let zone;
    timezoneData.filter((item) => {
      if (parseInt(id) === parseInt(item.id)) {
        zone = item.front_name;
      }
    });
    return zone;
  };

  const countryChangeHandler = (value, name, setFieldValue) => {
    // setUserCountryCode(value.country_code);
    setFieldValue(name, value);
  };
  const requestChangeHandler = () => {
    setRequestChangePhoneNo(true);

    setrequestChangeSuceessMessagePhoneNo("");
    if (requestChangePhoneNo) {
      if (newPhoneNo !== "") {
        let body = {
          type: "phone",
          value: newPhoneNo,
        };
        requestScreenNameChange(body)
          .then((data) => {
            if (data.data.status) {
              setrequestChangeSuceessMessagePhoneNo(
                "You have successfully submited Request for Phone No change"
              );
            }
          })
          .catch(() => {});
      } else {
        setrequestChangeSuceessMessagePhoneNo("New Phone No is required");
      }
    }
  };
  // const onEditorStateChange = (e) => {
  //   console.log(e)
  //   setBiostate(e);
  // };
  const onChangeTimeZoneHanlder = () => {
    console.log(newTimezoneData);
    if (newTimezoneData.length > 0) {
      return;
    }
    reloadTimeZones();
  }
  return (
    <div>
      {/* {(parseInt(stepOneData.mediumship_level_admin) === 3 || parseInt(stepOneData.mediumship_level_admin) === 6 || parseInt(stepOneData.mediumship_level_admin) === 7) && <ProfilePicture />} */}
      <ProfilePicture
        mediumship_level_admin={stepOneData.mediumship_level_admin}
      />
      <Formik
        initialValues={stepOneData}
        validate={(values) => {
          const errors = {};
          if (!values.mediumshipSpecialities.length)
            errors.mediumshipSpecialities = "Required";

          // if (!values.phone) errors.phone = "Required";
          // if (!values.whatsapp) errors.whatsapp = "Required";
          if (!values.country) errors.country = "Required";
          // if (!values.website) errors.website = "Required";
          if (!values.mediumshipLevel) errors.mediumshipLevel = "Required";
          // if (!values.biographicalInfo) errors.biographicalInfo = "Required";

          // if (values.phone && !/^[0-9]+$/.test(values.phone)) {
          //   errors.phone = "Invalid Phone";
          // }
          if (values.whatsapp && values.whatsapp.length < 10) {
            errors.phone = "Invalid whatsapp";
          }
          if (values.phone && values.phone.length < 10) {
            errors.phone = "Invalid Phone";
          }
          // if (values.whatsapp && !/^[0-9]+$/.test(values.whatsapp)) {
          //   errors.whatsapp = "Invalid whatsapp";
          // }
          if (!values.timezone) errors.timezone = "Required";
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          RunFunction(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          isSubmitting,
          setFieldTouched,
        }) => (
          <form
            onSubmit={handleSubmit}
            className="tab-pane"
            role="tabpanel"
            id="step1"
          >
            <div className="row-one-step">
              <div className="left-side">
                <label>
                  Phone<i>*</i>
                </label>
                <PhoneInputField
                  className={`number-input ${
                    errors.phone && touched.phone && errors.phone
                      ? "error-boundry"
                      : ""
                  }`}
                  // country={userCountryCode.toLowerCase()}
                  country="us"
                  setFieldValue={setFieldValue}
                  onBlur={handleBlur}
                  value={values.phone}
                  id="phone"
                  name="phone"
                  disabled={true}
                />
                <div className=" mt-2">
                  <div>
                    {requestChangePhoneNo && (
                      <>
                        <label>
                          New Phone<i>*</i>
                        </label>
                        <PhoneInputField
                          country={userCountryCodeChange.toLowerCase()}
                          onBlur={handleBlur}
                          value={newPhoneNo}
                          setValue={setNewPhoneNo}
                          id="updatedphone"
                          name="updatedphone"
                        />
                      </>
                    )}
                    <div className="mt-2">
                      <Button
                        status={0}
                        type="button"
                        className={`resend-btn w-unset height-45 min-width-130px`}
                        onClick={requestChangeHandler}
                        title={
                          requestChangePhoneNo ? "Submit" : "Request Change"
                        }
                      />
                    </div>
                  </div>
                  <div
                    className={`text-left ${
                      requestChangeSuceessMessagePhoneNo ===
                      "New Phone No is required"
                        ? "red"
                        : "green"
                    }`}
                  >
                    {requestChangeSuceessMessagePhoneNo}
                  </div>
                </div>
                {/* <input
                className={`${
                  errors.phone && touched.phone && errors.phone
                    ? "error-boundry"
                    : ""
                }`}
                onChange={(e) => handleChangeHandler(e, "phone", setFieldValue)}
                onBlur={handleBlur}
                value={values.phone}
                id="phone"
                name="phone"
                type="tel"
              /> */}
                <span className="error">
                  {errors.phone && touched.phone && errors.phone}
                </span>
              </div>
              <div className="right-side">
                <label>Whatsapp</label>
                <PhoneInputField
                  className={`${
                    errors.whatsapp && touched.whatsapp && errors.whatsapp
                      ? "error-boundry"
                      : ""
                  }`}
                  // country={userCountryCode.toLowerCase()}
                  country="us"
                  setFieldValue={setFieldValue}
                  onBlur={handleBlur}
                  value={values.whatsapp}
                  id="whatsapp"
                  name="whatsapp"
                />
                {/* <input
                onChange={(e) =>
                  handleChangeHandler(e, "whatsapp", setFieldValue)
                }
                onBlur={handleBlur}
                value={values.whatsapp}
                id="whatsapp"
                name="whatsapp"
                type="tel"
              /> */}
                <span className="error">
                  {errors.whatsapp && touched.whatsapp && errors.whatsapp}
                </span>
              </div>
            </div>
            <div className="row-one-step">
              <div className="full-side">
                <label>
                  Languages Available for Mediumship Sessions<i>*</i>
                </label>
                <Select
                  id="languages"
                  className="selection-box"
                  options={newformData}
                  multi={true}
                  isMulti
                  onChange={(value) => setFieldValue("languages", value)}
                  onBlur={handleBlur}
                  placeholder="Select languages"
                  value={values.languages}
                  closeMenuOnSelect={false}
                  closeMenuOnScroll={false}
                />
                <span
                  onClick={() => setOpenOtherLnaguages(!openOtherLnaguages)}
                  className="other-lang pointer"
                >
                  Other Language
                </span>
                {openOtherLnaguages && (
                  <TagsInput
                    value={values.otherLanguages}
                    className="form-other-tags-input"
                    name="otherLanguages"
                    onChange={(value) => setFieldValue("otherLanguages", value)}
                    placeHolder="Other Language"
                  />
                )}
                <span className="error">
                  {errors.languages && touched.languages && errors.languages}
                </span>
              </div>
            </div>
            <div className="row-one-step">
              <div className="full-side">
                <label>
                  Current Mediumship Level
                  <i>*</i>
                </label>
                <div className="InputGroup">
                  <div className="radio-box">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="radio"
                      name="mediumshipLevel"
                      id="size_1"
                      value={1}
                      checked={
                        parseInt(values.mediumshipLevel) === 1 ? true : false
                      }
                    />
                    <label htmlFor="size_1">Novice</label>
                  </div>
                  <div className="radio-box">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="radio"
                      name="mediumshipLevel"
                      id="size_2"
                      value={2}
                      checked={
                        parseInt(values.mediumshipLevel) === 2 ? true : false
                      }
                    />
                    <label htmlFor="size_2">Intermediate</label>
                  </div>
                  <div className="radio-box">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="radio"
                      name="mediumshipLevel"
                      id="size_3"
                      value={3}
                      checked={
                        parseInt(values.mediumshipLevel) === 3 ? true : false
                      }
                    />
                    <label htmlFor="size_3">Professional</label>
                  </div>
                </div>
              </div>
            </div>
            {/***if professinal */}
            {parseInt(values.mediumshipLevel) === 3 && (
              <div className="from-inner-box radeo">
                <div className="full-fild">
                  <label>
                    Years as professional <i>*</i>
                  </label>
                  <div className="InputGroup">
                    <div className="radio-box">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="radio"
                        name="medium_professional_category"
                        id="medium_professional_category_1"
                        value={1}
                        checked={
                          parseInt(values.medium_professional_category) === 1
                            ? true
                            : false
                        }
                      />
                      <label htmlFor="medium_professional_category_1">
                        1 year
                      </label>
                    </div>
                    <div className="radio-box">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="radio"
                        name="medium_professional_category"
                        id="medium_professional_category_2"
                        value={2}
                        checked={
                          parseInt(values.medium_professional_category) === 2
                            ? true
                            : false
                        }
                      />
                      <label htmlFor="medium_professional_category_2">
                        2-3 years
                      </label>
                    </div>
                    <div className="radio-box">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="radio"
                        name="medium_professional_category"
                        id="medium_professional_category_3"
                        value={3}
                        checked={
                          parseInt(values.medium_professional_category) === 3
                            ? true
                            : false
                        }
                      />
                      <label htmlFor="medium_professional_category_3">
                        3-5 years
                      </label>
                    </div>
                    <div className="radio-box">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="radio"
                        name="medium_professional_category"
                        id="medium_professional_category_4"
                        value={4}
                        checked={
                          parseInt(values.medium_professional_category) === 4
                            ? true
                            : false
                        }
                      />
                      <label htmlFor="medium_professional_category_4">
                        5-10 years
                      </label>
                    </div>
                    <div className="radio-box">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="radio"
                        name="medium_professional_category"
                        id="medium_professional_category_5"
                        value={5}
                        checked={
                          parseInt(values.medium_professional_category) === 5
                            ? true
                            : false
                        }
                      />
                      <label htmlFor="medium_professional_category_5">
                        10-15 years
                      </label>
                    </div>
                    <div className="radio-box">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="radio"
                        name="medium_professional_category"
                        id="medium_professional_category_6"
                        value={6}
                        checked={
                          parseInt(values.medium_professional_category) === 6
                            ? true
                            : false
                        }
                      />
                      <label htmlFor="medium_professional_category_6">
                        15+ years
                      </label>
                    </div>
                  </div>
                  <span className="error text-left">
                    {errors.mediumshipLevel &&
                      touched.mediumshipLevel &&
                      errors.mediumshipLevel}
                  </span>
                </div>
              </div>
            )}
            {/*****New section***** */}
            {console.log(
              values.mediumship_level_admin,
              "mediumship_level_admin"
            )}
            {(parseInt(values.mediumship_level_admin) === 3 ||
              parseInt(values.mediumship_level_admin) === 6 ||
              parseInt(values.mediumship_level_admin) === 7) && (
              <>
                <div className="row-one-step">
                  <div className="full-side">
                    <label>When do I pay?</label>
                    <div className="full-fild">
                      <div className="check-field">
                        <input
                          disabled
                          // onChange={handleChange}
                          // onBlur={handleBlur}
                          type="checkbox"
                          name="when_do_i_pay"
                          id="when_do_i_pay_1"
                          value={"1"}
                          checked={
                            values.when_do_i_pay.includes("1") ? true : false
                          }
                          // checked={
                          //   parseInt(values.when_do_i_pay) === 1 ? true : false
                          // }
                        />
                        <label className=" w-unset" htmlFor="when_do_i_pay_1">
                          Pay to Book
                        </label>
                      </div>
                      <div className="check-field">
                        <input
                          // onChange={handleChange}
                          // onBlur={handleBlur}
                          disabled
                          type="checkbox"
                          name="when_do_i_pay"
                          id="when_do_i_pay_2"
                          value={"2"}
                          checked={
                            values.when_do_i_pay.includes("2") ? true : false
                          }
                          // checked={
                          //   parseInt(values.when_do_i_pay) === 2 ? true : false
                          // }
                        />
                        <label htmlFor="when_do_i_pay_2" className=" w-unset">
                          Pay After Session
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {/******************** */}
                {(parseInt(values.mediumship_level_admin) === 3 ||
                  parseInt(values.mediumship_level_admin) === 6 ||
                  parseInt(values.mediumship_level_admin) === 7) && (
                  <>
                    <div className="row-one-step p-2">
                      <div className="full-side">
                        <label>
                          Session Currency :{" "}
                          <span className="color-d5a67b font-size-18">
                            {values.session_length_30_currency}
                          </span>{" "}
                        </label>
                      </div>
                    </div>
                  </>
                )}
                <div className="row-one-step new-step-row">
                  <div className="full-side">
                    <label>
                      Session Length &nbsp; &nbsp; &nbsp;{" "}
                      <Link className="text-underline" to={"/princing"}>
                        Click here to adjust session prices.
                      </Link>
                    </label>
                    {/* <div className="InputGroup">
                    <div className="radio-box">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="radio"
                        name="session_length"
                        id="size_1_session_length"
                        value={30}
                        checked={
                          parseInt(values.session_length) === 30 ? true : false
                        }
                      />
                      <label
                        className=" w-unset"
                        htmlFor="size_1_session_length"
                      >
                        30 Minutes
                      </label>
                    </div>
                    <div className="radio-box">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="radio"
                        name="session_length"
                        id="size_2_session_length"
                        value={45}
                        checked={
                          parseInt(values.session_length) === 45 ? true : false
                        }
                      />
                      <label
                        htmlFor="size_2_session_length"
                        className=" w-unset"
                      >
                        45 Minutes
                      </label>
                    </div>
                    <div className="radio-box">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="radio"
                        name="session_length"
                        id="size_3_session_length"
                        value={60}
                        checked={
                          parseInt(values.session_length) === 60 ? true : false
                        }
                      />
                      <label
                        htmlFor="size_3_session_length"
                        className=" w-unset"
                      >
                        60 Minutes
                      </label>
                    </div>
                  </div> */}
                  </div>
                  {/* stepOneData.mediumship_level */}

                  {(parseInt(values.mediumship_level_admin) === 3 ||
                    parseInt(values.mediumship_level_admin) === 6 ||
                    parseInt(values.mediumship_level_admin) === 7) && (
                    <>
                      <div className="w-25 mt-2 ml-5">
                        <label>30 Minutes</label>
                        <input
                          className="p-2 amount_input"
                          // onChange={handleChange}
                          // onBlur={handleBlur}
                          disabled
                          value={values.session_length_30}
                          id={"session_length_30"}
                          name={"session_length_30"}
                          type="text"
                          placeholder="Amount"
                        />
                      </div>
                      <div className="w-25 mt-2 ml-5">
                        <label>45 Minutes</label>

                        <input
                          className="p-2 amount_input"
                          // onChange={handleChange}
                          // onBlur={handleBlur}
                          disabled
                          value={values.session_length_45}
                          id={"session_length_45"}
                          name={"session_length_45"}
                          type="text"
                          placeholder="Amount"
                        />
                      </div>
                      <div className="w-25 mt-2 ml-5">
                        <label>60 Minutes</label>

                        <input
                          className="p-2 amount_input"
                          // onChange={handleChange}
                          // onBlur={handleBlur}
                          disabled
                          value={values.session_length_60}
                          id={"session_length_60"}
                          name={"session_length_60"}
                          type="text"
                          placeholder="Amount"
                        />
                      </div>
                      <div className="w-25 mt-2 ml-5">
                        <label>45 Minutes for 2 People</label>

                        <input
                          className="p-2 amount_input"
                          // onChange={handleChange}
                          // onBlur={handleBlur}
                          disabled
                          value={values.session_length_45_2}
                          id={"session_length_45_2"}
                          name={"session_length_45_2"}
                          type="text"
                          placeholder="Amount"
                        />
                      </div>
                      <div className="w-25 mt-2 ml-5">
                        <label>60 Minutes for 2 People</label>

                        <input
                          className="p-2 amount_input"
                          // onChange={handleChange}
                          // onBlur={handleBlur}
                          disabled
                          value={values.session_length_60_2}
                          id={"session_length_60_2"}
                          name={"session_length_60_2"}
                          type="text"
                          placeholder="Amount"
                        />
                      </div>
                    </>
                  )}
                </div>
                {/******New section**** */}
              </>
            )}

            <div className="row-one-step">
              <div className="full-side">
                <label>
                  Mediumship Specialities<i>*</i>
                </label>
                <Select
                  id="mediumshipSpecialities"
                  className="selection-box"
                  options={newSpecilityData}
                  multi={true}
                  isMulti
                  onChange={(value) =>
                    setFieldValue("mediumshipSpecialities", value)
                  }
                  onBlur={handleBlur}
                  placeholder="Select Specialities"
                  value={values.mediumshipSpecialities}
                  closeMenuOnSelect={false}
                  closeMenuOnScroll={false}
                />

                <span
                  onClick={() => setOpenOtherSpecility(!openOtherSpecility)}
                  className="other-lang pointer"
                >
                  Other specialities
                </span>
                {openOtherSpecility && (
                  <TagsInput
                    value={values.otherMediumSpecialities}
                    className="form-other-tags-input"
                    onChange={(value) =>
                      setFieldValue("otherMediumSpecialities", value)
                    }
                    name="otherSpecialities"
                    placeHolder="Other specialities"
                  />
                )}
                <span className="error">
                  {errors.mediumshipSpecialities &&
                    touched.mediumshipSpecialities &&
                    errors.mediumshipSpecialities}
                </span>
              </div>
            </div>
            <div className="row-one-step">
              <div className="left-side">
                <label>
                  Country <i>*</i>
                </label>
                {/* <input type="text" placeholder="United States" /> */}
                {values.country && (
                  <Select
                    id="country"
                    className="selection-box"
                    options={newCountryData}
                    // multi={true}
                    // isMulti
                    onChange={(value) =>
                      countryChangeHandler(value, "country", setFieldValue)
                    }
                    onBlur={handleBlur}
                    placeholder="United States "
                    defaultInputValue={values.country}
                    value={values.country}
                    closeMenuOnSelect={true}
                    closeMenuOnScroll={true}
                  />
                )}
                {!values.country && (
                  <Select
                    id="country"
                    className="selection-box"
                    options={newCountryData}
                    // multi={true}
                    // isMulti
                    onChange={(value) =>
                      countryChangeHandler(value, "country", setFieldValue)
                    }
                    onBlur={handleBlur}
                    placeholder="United States "
                    defaultInputValue={values.country}
                    value={values.country}
                    closeMenuOnSelect={true}
                    closeMenuOnScroll={true}
                  />
                )}
                <span className="error">
                  {errors.country && touched.country && errors.country}
                </span>
              </div>
              {parseInt(values.mediumshipLevel) === 3 && (
                <div className="right-side cuntrey-website">
                  <label>Website</label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.website}
                    id="website"
                    name="website"
                    type="text"
                    placeholder="yourwebsite.com"
                  />
                </div>
              )}
            </div>
            <div className="row-one-step">
              <label>
                Timezone<i>*</i>
                {/* <img
                  title="Reload TimeZones"
                  onClick={(e) => reloadTimeZones("refresh")}
                  className="googl-link-copy-img copy-image-animate refresh-btn-app mt-4"
                  alt="Reload TimeZones"
                  src="/images/refresh.png"
                /> */}
              </label>
              {values.timezone && (
                <Select
                  id="timezone"
                  className="selection-box"
                  options={newTimezoneData}
                  onChange={(value) => setFieldValue("timezone", value)}
                  onBlur={handleBlur}
                  placeholder="Select Your Timezone"
                  defaultValue={values.timezone}
                  // defaultInputValue={values.timezone.front_name}
                  value={values.timezone}
                  closeMenuOnSelect={true}
                  closeMenuOnScroll={true}
                  onMenuOpen={onChangeTimeZoneHanlder}
                />
              )}
              {!values.timezone && (
                <Select
                  id="timezone"
                  className="selection-box"
                  options={newTimezoneData}
                  onChange={(value) => setFieldValue("timezone", value)}
                  onBlur={handleBlur}
                  // defaultInputValue={values.timezone.front_name}
                  defaultValue={values.timezone}
                  placeholder="Select Your Timezone"
                  value={values.timezone}
                  closeMenuOnSelect={true}
                  closeMenuOnScroll={true}
                  onMenuOpen={onChangeTimeZoneHanlder}
                />
              )}
              <span className="error">
                {errors.timezone && touched.timezone && errors.timezone}
              </span>
            </div>
            {parseInt(values.mediumshipLevel) === 3 && (
              <div className="row-one-step textarea">
                <label>Biographical Info</label>
                <textarea
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.biographicalInfo}
                  //   style="text"
                  id="biographicalInfo"
                  name="biographicalInfo"
                  placeholder="Write Here..."
                  rows="4"
                  cols="50"
                ></textarea>
                {/* <Editors
                 name="description"
                 onChange={(data) => {
                   setData(data);
                 }}
                 editorLoaded={editorLoaded}/> */}
                {/* <br /> */}
                {/* <Editor
                // initialContentState={EditorState.createWithContent(JSON.parse(values.biographicalInfo))}
                placeholder="Write Here..."
                  editorState={editorState}
                  toolbarClassName="editor-toolbar"
                  wrapperClassName="editor-wrapper"
                  editorClassName="editor"
                  onEditorStateChange={(editorState)=>handleEditorStateChange(editorState,setFieldValue ,'biographicalInfo')}
                  onContentStateChange={handleContentStateChange}
                  // toolbar={toolbar}
                  // localization={localization}
                  spellCheck
                  // stripPastedStyles
                  // toolbarCustomButtons={[<CustomComponent />]}
                /> */}
                {/* <div dangerouslySetInnerHTML={{ __html: contentState }} />
                {console.log(contentState,"contentState")} */}
              </div>
            )}
          
            <div className="update">
              <button type="submit" className="update">
                Update
              </button>
            </div>
            {/* <div className="edite-bution">
            <a href="#">
              <img src="images/edite.png" />
            </a>
          </div> */}
          </form>
        )}
      </Formik>
    </div>
  );
}
