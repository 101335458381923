import React, { useEffect, useState } from "react";
import "../giftyourmediums/giftyourmediums.css";
import Button from "../../../../../components/button";
export default function PayItForwardPopUp({ activePay, paymentMethodHandler, setpayItforwardValue, setPaymentCurrency, paymentstatus, payItforwardValue,payItforwardFixPrice }) {
    const [payment, setPayment] = useState([
        { id: 1, value: "20", status: true },
        { id: 2, value: "30", status: false },
        { id: 3, value: "40", status: false },
        { id: 4, value: "50", status: false },
        { id: 5, value: "70", status: false },
        { id: 6, value: "100", status: false },
    ]);
    const [inputValue, setInputValue] = useState("");
    useEffect(() => {
        setInputValue("");
    }, [paymentstatus])

    useEffect(() => {
        setInputValue(payItforwardValue);
    }, [payItforwardValue])


    const paymentChangeHandler = (e) => {
        let value = e.target.value;
        setInputValue(value);
        setpayItforwardValue(value)
    };
    return (
        <div
            class="modal fade payment-price-popup gift-your-medium-modal mt-5"
            tabindex="-1"
            role="dialog"
            id="PayItForwardPopUp"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog " >
                <div class="modal-content">
                    <div class="modal-body payment-wrapper">
                        <h5 className="payment-heading font-size-19"><b className="font-size-28"> This Medium is contributing your gift to VerySoul.</b><br />
                            <p className="mt-2">Please enter any amount to contribute or continue with the mediums suggested amount.</p></h5>


                        <div className="text-center mt-3">
                            <label className="font-size-22 font-weight-bold">Suggested Amount : ${payItforwardFixPrice} USD </label>
                            <div className="payment-methods-container">
                                <div>
                                    <label className="font-size-22">Enter Amount</label>
                                </div>
                            </div>
                            <br />
                            <div className="payment-methods-container giftyourmediums-wrap-input-payit-forward text-center ml-2">
                                <div>
                                    <input
                                        value={inputValue}
                                        name="inputvalue"
                                        type="number"
                                        className="mt-3"
                                        onChange={paymentChangeHandler}
                                    />
                                </div>
                                {/* <div className="payment-methods-container ">
                  <div>
                    <select name="currency" id="currency" onChange={(e) => setPaymentCurrency(e.target.value)} class="currency_select giftyourmediums-wrap-select"
                    >
                      <option value="USD">USD</option>
                      <option value="CAD">CAD</option>
                      <option value="AUD">AUD</option>
                    </select>
                  </div>
                </div> */}
                            </div>
                        </div>

                        <div className="payment-save-btn-wrapper justify-content-center payment-new-wrapper">


                            <button
                                className={"main-btn"}
                                status={0}
                                onClick={() => paymentMethodHandler()}
                                title="Pay Amount"
                            >
                             {" "}
                                            <img
                                                className="paypal-img"
                                                src={"/integrationimages/paypal.png"}
                                            />
                                            </button>
            
                        </div>
                        <p className="mt-3">(PayPal or credit/debit card)</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
