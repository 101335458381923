import React from "react";
import { useNavigate } from "react-router-dom";

export default function TimeEndedPopup({ setloaderType,addMediumQueue,resetTimer ,setHowManyTimes}) {
    const navigate = useNavigate();
    // const { id  } = useSelector((state) => state.user.user);



    const navigatePage = () => {
        window.loadModal2("#TimeEndedPopup", "hide")
        setTimeout(() => {
            navigate('/appointment')
        }, 100)

    }
    const resetTimerQueue = ()=> {
        window.loadModal2("#TimeEndedPopup", "hide")
        setHowManyTimes(0)
        setTimeout(() => {
            // addMediumQueue()
            // resetTimer()
        }, 100)
    }
    return (
        <div>
            <div
                id="TimeEndedPopup"
                class="modal fade bd-example-modal-lg mt-5 preview-modal-availability"
                tabindex="-1"
                role="dialog"
                aria-labelledby="myLargeModalLabel"
                aria-hidden="true"
                data-backdrop="static"
            >
                <div class="modal-dialog modal-lg modal-dialog-preview text-center">
                    <div class="modal-content ">
                        <div className="no-app-fount-box">

                            <p className="font-size-18">We are sorry, we could not match you with a Medium at this time.



                            </p>


                            <br />
                            <p className="font-size-18">Would you like to continue waiting, or select a future date and time instead?
                            </p>
                            <div className="d-flex align-items-center justify-content-around">

                                <div
                                    onClick={resetTimerQueue}

                                    className="width-260-px white-border pointer indexstyles__StyledButton-sc-83qv1q-0 jdbotF sc-1qeub3n-4  d-flex align-items-center justify-content-center"
                                >
                                  Continue Waiting
                                </div>
                                <div
                                    onClick={navigatePage}
                                    className="width-260-px white-bg-btn pointer indexstyles__StyledButton-sc-83qv1q-0 jdbotF sc-1qeub3n-4  d-flex align-items-center justify-content-center"
                                >
                                  Select Future Date and Time
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
