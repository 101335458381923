import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment-timezone";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { getTimezones } from "../../services/uidata";
import { filterVisibleTimeZones } from "../../utils/helpers";

//css
import "./checktimezone.css";
import { updateNewTimeZone } from "../../services/profile";
import { setUser } from "../../reducer/userSlice";
import { fetchUser } from "../../services/auth";
import { ar } from "date-fns/locale";

export default function UpdateTimeZone({ newTimezoneData }) {
    //states
    // const navigate = useNavigate();
    const dispatch = useDispatch()

    const [value, setValue] = useState("");
    const [error, setError] = useState(false);

    //effects



    const getNextMatchingTimezone = (timezoneData, newtime) => {
        const array = timezoneData;
        const currentValue = newtime;
        console.log(array, currentValue)
        const currentIndex = array.findIndex(obj => obj.name === currentValue);
        const nextMatchingIndex = array.findIndex((obj, index) => index > currentIndex && obj.status === 1);
        const nextMatchingObject = nextMatchingIndex !== -1 ? array[nextMatchingIndex] : null;

        return nextMatchingObject;

    }
    useEffect(() => {
        if (newTimezoneData.length > 0) {


            let zone = moment.tz.guess();
            let item = newTimezoneData.filter((item) => {
                if (item.name == zone) {
                    return item.id
                }
            })
            console.log(item, "item");
            if (item.length && item[0].status === 1) {
                setValue(item[0].id)
                // stepOneData.timezone = { ...item[0], value: item[0].id, label: item[0].front_name };
            }
            else {
                let info = getNextMatchingTimezone(newTimezoneData, zone)
                console.log(info, "info")
                setValue(info.id)
                // stepOneData.timezone = { ...info, value: info.id, label: info.front_name };
            }
        }
    }, [newTimezoneData])

    //handlers

    const clickHander = () => {
        if (value === "") {
            setError(true)
            return;
        }
        let body = {
            timezone_id: value,
        };
        updateNewTimeZone(body)
            .then((data) => {
                if (data.data.status) {
                    toast.success('Timezone updated successfully', { position: 'top-center' });
                    callProfile()
                    window.loadModal2("#updateTimeZoneId", "hide");
                }
            })
            .catch(() => { });

    }
    const changeHandler = (e) => {
        console.log(e);
        setValue(e.target.value);
    };
    const callProfile = async () => {
        await fetchUser()
            .then((data) => {
                dispatch(setUser(data.data.data));
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <div
            className="modal fade "
            id="updateTimeZoneId"
            data-backdrop="static"
            role="dialog"
        >
            <div className="modal-dialog  modal-dialog-preview">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="schedule-popup">
                            {/* <img src="../images/new-cll.png" alt="user" /> */}

                            <h3 className="">
                                Please confirm your timezone
                                <br /> 
                            </h3>
                            <div>
                                <div className="cuntrey-website p-5">
                                    <div className="cuntrey-website">
                                        {/* {timezoneOnly && ( */}
                                        <select
                                            className="select-check"
                                            value={value}
                                            onChange={changeHandler}
                                            id="timezone"
                                            name="timezone"
                                            placeholder="Select Your Timezone"
                                        >
                                            <option disabled="" selected="" value="">
                                                Timezone<i>*</i>
                                            </option>
                                            {filterVisibleTimeZones(newTimezoneData).map((timezone) => (
                                                <option value={timezone.id}>
                                                    {timezone.front_name}
                                                </option>
                                            ))}
                                        </select>
                                        {error && <div className="error-select"> <span className="error">Required</span></div>}
                                        {/* )} */}

                                        {/* <span className="error">
                {timeZoneError && 'Required'}
              </span> */}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="advance-tag margin-unset pointer"
                                onClick={clickHander}
                            >
                                update
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
