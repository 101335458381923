import Vimeo from "@u-wave/react-vimeo";
import React from "react";
import { Link } from "react-router-dom";
import { innerWidthHandler } from "../utils/helpers";

import Topbar from "../components/topbar/topbar";
import Footer from "../pages/client/appointment/footer";

export default function RegisterMediumVideo() {
  return (
    <>
      <Topbar />

      <div className="coming-soon height-unset mb-5 ">
        <Vimeo
          muted={true}
          autoplay={true}
          className="popover-video"
          width={innerWidthHandler()}
          height={430}
          video={"https://vimeo.com/678206499/ba93cdbfe1"}

        />
        <div className="mt-5">
          <ol className="font-size-18 line-height-24px">
            <li>Watch Video. </li>
            <li>Review our Code of Ethics <a target="_blank" href="https://verysoul.com/code-of-ethics/">Click Here.</a>
            </li>
            <li>Review our Operating Principles <a target="_blank" href="https://verysoul.com/operating-principle/">Click Here.</a>

            </li>
            <li>For more details, review our Medium FAQs <a target="_blank" href="https://verysoul.com/medium-faqs">Click Here.</a>

            </li>
          </ol>
          <p className="font-size-18 line-height-24px">
            If you still have questions, feel free to send them to  <a href="mailto:support@verysoul.com">support@verysoul.com.</a>

          </p>
          <p className="font-size-18 line-height-24px">Once you complete the registration, wait for further instructions by email.  Review of registration is typically completed within 24-48 hours.</p>
        </div>
        <div className="text-center">
          <Link className="coming-soon-btn  skip-pbtn m-3" to={"/login"}>
            {" "}
            Cancel
          </Link>

          <Link className="coming-soon-btn m-3" to={"/register-medium"}>
            {" "}
            Continue to Register
          </Link>
        </div>
      </div >
      <div className="text-center">
      <Footer />
      </div>
    </>
  );
}
