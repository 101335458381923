import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import StarsRating from "stars-rating";

import { setLoading } from "../../../reducer/auth/auth";
import {
  deleteUserFeedbacks,
  getMediumProfileDetails,
} from "../../../services/admin";
import { setPathArray } from "../../../reducer/adminSlice";
import { toast } from "react-toastify";
// import BookingPopUp from "./bookingpopup";
import "./view.css";
import { getMediumPublicProfileDetails } from "../../../services/uidata";
export default function PractitionerInfo({ PractitionerInfo, onClick, view, show }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("view");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [profile, setProfile] = useState(null);
  const [queryValue, setQueryValue] = useState('');
  const params = useParams();

  useEffect(() => {


    dispatch(setPathArray(params.id));
    getProfile(params.id);
    setQueryValue(status)
    setTimeout(() => {
      removeQueryParams()
    }, 2000);
    return () => {
      dispatch(setPathArray(null));
    };
  }, []);
  const removeQueryParams = () => {
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.delete("view");
    // newUrl.searchParams.delete("id");
    window.history.replaceState({}, document.title, newUrl.href);

    // if (status) {
    //   // 👇️ delete each query param
    //   searchParams.delete('view');

    //   // 👇️ update state after
    //   setSearchParams(searchParams);
    // }
  };
  const getProfile = (id) => {
    dispatch(setLoading(true));
    let set = view === 'public' ? getMediumPublicProfileDetails(id) : getMediumProfileDetails(id)

    set.then((data) => {
      dispatch(setLoading(false));
      if (data.data.status) {
        const arraydata = data.data.data;
        if (
          Array.isArray(arraydata.preferences) &&
          arraydata.preferences.length === 0
        )
          arraydata.preferences = {};
        if (
          Array.isArray(arraydata.morePreferences) &&
          arraydata.morePreferences.length === 0
        )
          arraydata.morePreferences = {};
        setProfile(arraydata);
      }
    })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
  const calculateUnique = (arr) => {
    let value = arr.reduce((acc, val) => {
      acc[val] = acc[val] === undefined ? 1 : (acc[val] += 1);
      return acc;
    }, {});
    return value;
  };
  const calculateRating = (feedbacks) => {
    let total = 0;
    let sum_resp = 0;
    let counts = feedbacks.map((item) => {
      return parseInt(JSON.parse(item.feedback).Star_Rating);
    });

    Object.entries(calculateUnique(counts)).map(([key, value]) => {
      sum_resp += parseInt(value) * parseInt(key);
      total += parseInt(value);
    });
    let score = Math.round((sum_resp / total) * 2) / 2;
    return score;

    // return Math.floor(sum_resp / total);
  };
  const deleteFeedBacks = (feedback_id) => {
    let body = {
      feedback_id,
    };
    deleteUserFeedbacks(body)
      .then((data) => {
        if (data.data.status) {
          toast.success("Feedback Deleted Succesfully", {
            position: "top-center",
          });
          getProfile(params.id);
        }
      })
      .catch(() => { });
  };
  return (
    <div className="schedule-advance-box-all-container">
      <div class="single-profile-saction single-profile-saction-new">
        {show == undefined &&
          <>
            {view !== "public" && <div onClick={onClick} class="back-btn-profile">
              <div className="bac-href pointer">
                <img
                  className="bac-img"
                  src="/images/arrow-back.png"
                  alt="back"
                  onClick={() => status === null ? navigate(-1) : navigate(`/mediums?view=${queryValue}`)}
                />
                Go Back
              </div>
            </div>}
          </>
        }
        <div class="profile-all-box">
          <div class="banner-img-profile">
            <img src="/images/profile-cover.png" alt="avatar" />
          </div>
          <div class="profile-info app-new-profile">
            <div class="profile-image">
              <img
                className="public-profile-picture"
                src={`${profile !== null && profile.general.profile_image !== ""
                  ? process.env.REACT_APP_IMAGE_URL +
                  profile.general.profile_image
                  : "/images/user-dummy.png"
                  }`}
                alt="avatar"
              />
            </div>
            <div class="profile-name">
              <div class="nam-prof">
                {profile !== null && profile.preferences.screen_name}
              </div>
              <a href="#">
                {profile !== null && profile.preferences.screen_name}
              </a>
              <div class="star-rating">
                {profile !== null && (
                  <StarsRating
                    count={5}
                    value={calculateRating(profile.feedbacks)}
                    size={20}
                    color2={"#ffc107"}
                    edit={false}
                    color1={"#ffffff"}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="profile-book-now">
            {/* <button type="button" onClick={openPopup}>Book Now</button> */}
            {view !== "public" ? <a
              className="book-now-practice"
              href={
                profile !== null && profile.general.personal_booking_link !== ""
                  ? profile.general.personal_booking_link
                  : "#"
              }
            >
              Book Here
            </a> : <a target="_blank" className="book-now-practice"
              href={`${process.env.REACT_APP_BASE_URL}guest-booking/${profile !== null && profile.general.medium_id}`}
            //  href={`/register-client?guest=true&id=${profile !== null && profile.general.medium_id}`}
            >Book Here</a>}
          </div>

          <div class="profile-details app-new-profile-details">
            <ul>
              <li>
                <div class="icon-detls">
                  <img src="/images/chate.png" alt="" />
                </div>
                <em>Languages Available for Mediumship Sessions:</em>
                {profile !== null &&
                  profile.general.languages.map((data) => (
                    <span>{data.lanugage_name}</span>
                  ))}
              </li>
              <li>
                <div class="icon-detls">
                  <img src="/images/present.png" alt="" />
                </div>
                <em>Mediumship Specialities:</em>
                {profile !== null &&
                  profile.general.specialities.map((data) => (
                    <span>{data.specialty_name}</span>
                  ))}
              </li>
              <li>
                <div class="icon-detls">
                  <img src="/images/word.png" alt="" />
                </div>
                <em>Country:</em>
                <a href="#">{profile !== null && profile.general.country}</a>
              </li>
              {/* <li>
                  <div class="icon-detls">
                    <img src="images/web.png" />
                  </div>
                  <em>Website:</em>
                  <a href="#">{PractitionerInfo.general.website}</a>
                </li> */}
            </ul>
          </div>
          <div class="profile-bio">
            <h2>About Me</h2>
            <p>{profile !== null && profile.general.biographical_info}</p>
          </div>
          <div class="profile-bio">
            <h2 className="mb-3">
              Client Reviews{" "}
              {profile !== null && profile.general.recommendation !== ""
                ? `: ${profile.general.recommendation}% Recommend`
                : " "}
            </h2>

            <div className="feedback-container">
              {profile !== null &&
                profile.feedbacks.map((feedback) => (
                  <div className="feedback-border-feed mt-3 d-flex align-items-center ">
                    <div className="min-width-200px">
                      <div className="d-flex align-items-center">
                        <div className="feedback-image">
                          <img
                            className="practioner-profile-image"
                            src="../images/user-dummy.png"
                            alt="avatar"
                          />
                        </div>
                        <span className="ml-3">
                          {feedback.client_name} -{" "}
                          {JSON.parse(feedback.feedback).Country}
                        </span>
                      </div>
                      <div className="feedback-star-image">
                        <StarsRating
                          count={5}
                          value={parseInt(
                            JSON.parse(feedback.feedback).Star_Rating
                          )}
                          size={20}
                          color2={"#ffc107"}
                          edit={false}
                          color1={"#FFFFFF"}
                        />
                      </div>
                      <p className="feedback-date">{feedback.feedback_date}</p>
                    </div>
                    <div>
                      <span className="margin-unset line-height-unset feedback-heading">
                        {JSON.parse(feedback.feedback).Title}
                      </span>
                      <span className="margin-unset line-height-unset">
                        {JSON.parse(feedback.feedback).Publishable_Feedback}
                      </span>
                    </div>
                    <div className="feedback-delete-section">
                      <img
                        onClick={() => deleteFeedBacks(feedback.feedback_id)}
                        className="pointer"
                        src="../images/icon-b2.png"
                        alt="delete"
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      {/* <BookingPopUp item={PractitionerInfo} /> */}
    </div>
  );
}
