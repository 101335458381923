import React from "react";
import "./filterOptions.css";

const FilterOptions = ({ optionsList = [], changeHandlerOptions, filterValueOptions, name }) => {
  return (
    <div className="filter-container ">
      <select
        value={filterValueOptions}
        className="pointer"
        onChange={(e) => changeHandlerOptions(e)}
        name="filter"
      >
        <>
          <option value={""} selected>
            {name}
          </option>
          {optionsList.map((item) => (
            <option value={item.value}>{item.name}</option>
          ))}
        </>
      </select>
    </div>
  );
};
export default FilterOptions;
