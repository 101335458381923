import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTimezoneArray } from "../../../../reducer/uidataSlice";
import { setNewTimeZoneData } from "../../../../reducer/userSlice";
import { setNewTimeZone } from "../../../../services/medium";
import { getTimezones } from "../../../../services/uidata";
//components
// import MediumList from "./mediumlist";
import AdvanceCalendar from "./calendar";
//css
import "./mediumlist/mediumlist.css";
import { filterVisibleTimeZones } from "../../../../utils/helpers";

const AdvanceAppointment = ({
  client_id="",
  user_type,
  practitionerInfo,
  activesession_id,
  sessionType,
  type,
  preferncesSection,
  appointmentTyepList = [],
  getSubsessions,
  activeSubSessionId,
  activeDurationId,
  setActiveSubSessionId,
  setActiveDurationId,
  setActiveAmount,
  setActiveCurrency,
  activeAmount,
  activeCurrency,
  bookingType,
  is_group_session
}) => {
  const dispatch = useDispatch();
  const { mediumListItems } = useSelector((state) => state.client);
  const { timezone } = useSelector((state) => state.user.user);
  const { timezoneArray } = useSelector((state) => state.uidata);
  //states
  const [mediumItems, setMediumItems] = useState({});
  const [timezoneData, setTimezoneData] = useState([]);
  const [y, setY] = useState(window.scrollY);
  const [scroll, setScroll] = useState(false);
  const [noSlotsFound ,setnoSlotsFound] = useState(false);




  useEffect(() => {
    if (!timezoneArray.length) updateTimezones();
    if (user_type === undefined) {

      // !appointmentTyepList.length > 0 && getSubsessions(5)
    }
  }, []);
  useEffect(() => {
    setTimezoneData(filterVisibleTimeZones(timezoneArray));
  }, [timezoneArray]);

  const updateTimezones = () => {
    getTimezones()
      .then((data) => {
        dispatch(setTimezoneArray(data.data.data));
      })
      .catch((err) => console.log(err));
  };
  const changeTimeZoneHandler = (e) => {
    let id = e.target.value;
    let body = {
      timezone_id: id,
    };
    setNewTimeZone(body)
      .then((data) => {
        if (data.data.status) {
          // getAllAppointmentsList();
          dispatch(setNewTimeZoneData(filterTimeZone(id)));
        }
      })
      .catch(() => { });
  };
  const filterTimeZone = (id) => {
    let zone;
    timezoneData.filter((item) => {
      if (parseInt(id) === parseInt(item.id)) {
        zone = item.front_name;

      }
    });

    return zone;
  };

  useEffect(() => {
    setMediumItems(mediumListItems);
  }, [mediumListItems]);

  const onChangeSessionHandler = (e) => {
    let value = e.target.value
    let item = appointmentTyepList.filter((info) => parseInt(info.id) === parseInt(value))

    setActiveSubSessionId(item[0].id);
    setActiveDurationId(item[0].duration);
    setActiveAmount(item[0].price);
    setActiveCurrency(item[0].currency)
    localStorage.setItem('subsession_id', item[0].id);
    localStorage.setItem('duration', item[0].duration);
    localStorage.setItem('amount', item[0].price);
    localStorage.setItem('activeCurrency', item[0].currency);
  }

  const checTypeOfBooking = () => {
    let info = localStorage.getItem('type');
    return info ? true : false
  }
  const handleNavigation = useCallback(
    e => {
      const window = e.currentTarget;
      if (y > 200) {
        setScroll(true)
      } else if (y < 200) {
        setScroll(false)
      }
      setY(window.scrollY);
    }, [y]
  );
  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);
  return (
    <>
      <div className="container">
        <div>
          <div className={scroll ? 'scrollable' : ''}>

            <div className="middle-screen-name w-100 font-size-22 mt-5 mb-2  text-center">
              {" "}
              {/* <select
          onChange={changeTimeZoneHandler}
          className="select-box-timezone pointer"
        >
          <option disabled selected value="">
            Change Timezone
          </option>
          {timezoneData.map((item) => (
            <option value={item.id}>{item.front_name}</option>
          ))}
        </select> */}
              {type !== "admin" && (
                <b className="mr-5">
                  Timezone: {timezone !== undefined && timezone}&nbsp;
                </b>
              )}
              {type === "admin" && user_type !=="" && (
                <b className="mr-5">
                  Medium's Timezone:{" "}
                  {preferncesSection !== null && preferncesSection.time_zone}&nbsp;
                </b>
              )}

            </div>
            {!checTypeOfBooking() && user_type === undefined &&  <> <div className="middle-screen-name w-100 font-size-22 mt-2 mb-2  text-center">
              <select onChange={onChangeSessionHandler} className="subsession-box">

                <option selected disabled value={""}>Appointment Type</option>
                {appointmentTyepList.map((item, index) => <option selected={parseInt(item.id) === parseInt(activeSubSessionId)} data-item={item} value={item.id}> {item.sub_session_name}</option>)}
              </select>
              <span className="d-inline-block p-2"> &nbsp;Price :${activeAmount}&nbsp;{activeCurrency}</span>
            </div>

            </>
            }
          </div>
        </div>
      </div>
      <div className="schedule-advance-saction">
        <div className="container">
       {(bookingType !=="professional" && user_type !== "medium") &&  <h6>Note: You can view 15 days at a time, click on dates into the future for more dates and times.</h6>}
       {(bookingType =="professional" || user_type == "medium") &&  <h6>Note: You can view up to 30 days at a time. Click on dates into the future for more dates and times.</h6>}
          {type !== "admin" && checTypeOfBooking() && bookingType === "outreach" && (
            <h6 className="text-decoration-underline">Make your 1 free booking here by selecting a date and time you're sure you can make. If you cancel, fail to confirm, or don't show up within 5 minutes of your scheduled start time, your free session will be considered used.</h6>
          )}
          <div className="row">
            <AdvanceCalendar
            is_group_session={is_group_session}
              mediumItems={mediumItems}
              practitionerInfo={practitionerInfo}
              sessionType={sessionType}
              user_type={user_type}
              client_id={client_id}
              activesession_id={activesession_id}
              type={type}
              activeSubSessionId={activeSubSessionId}
              activeDurationId={activeDurationId}
              bookingType={bookingType}
              noSlotsFound={noSlotsFound}
              setnoSlotsFound={setnoSlotsFound}
              // month_empty={month_empty}
            />
            <div className="col-md-4">
              {/* <h3>Medium List</h3>
            {mediumItems.length ? (
              <MediumList mediumItems={mediumItems} />
            ) : (
              <div className="list-medium-box-right dummy-box-list">
                <img src="/images/user-dummy.png" alt="user-dummy" />
                <span className="dummy-text">No Medium Yet</span>
              </div>
            )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdvanceAppointment;
