import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { getTimezones } from "../../services/uidata";
import { filterVisibleTimeZones } from "../../utils/helpers";

//css
import "./checktimezone.css";
import { updateNewTimeZone } from "../../services/profile";
import { setUser } from "../../reducer/userSlice";
import { fetchUser } from "../../services/auth";

export default function CheckTimeZone() {
  //states
  // const navigate = useNavigate();
  const dispatch = useDispatch()

  const [newTimezoneData, setnewTimezoneData] = useState([]);
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);

  //effects
  useEffect(() => {
    updateTimezones();
  }, []);

  //handlers
  const updateTimezones = () => {
    getTimezones()
      .then((data) => {
        if (data.data.status) {
          let items = filterVisibleTimeZones(data.data.data);
          setnewTimezoneData(items);
        }
      })
      .catch((err) => console.log(err));
  };
  const clickHander = () => {
    if (value === "") {
      setError(true)
      return;
    }
    let body = {
      timezone_id: value,
    };
    updateNewTimeZone(body)
      .then((data) => {
        if (data.data.status) {
          toast.success('Timezone updated successfully', { position: 'top-center' });
          callProfile()
          window.loadModal2("#checkTimeZone", "hide");
        }
      })
      .catch(() => { });

  };
  const changeHandler = (e) => {
    console.log(e);
    setValue(e.target.value);
  };
  const callProfile = async () => {
    await fetchUser()
      .then((data) => {
        dispatch(setUser(data.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div
      className="modal fade "
      id="checkTimeZone"
      data-backdrop="static"
      role="dialog"
    >
      <div className="modal-dialog  modal-dialog-preview">
        <div className="modal-content">
          <div className="modal-body">
            <div className="schedule-popup">
              {/* <img src="../images/new-cll.png" alt="user" /> */}

              <h3 className="">
                Please select and update your
                <br /> timezone
              </h3>
              <div>
                <div className="cuntrey-website p-5">
                  <div className="cuntrey-website">
                    {/* {timezoneOnly && ( */}
                    <select
                      className="select-check"
                      value={value}
                      onChange={changeHandler}
                      id="timezone"
                      name="timezone"
                      placeholder="Select Your Timezone"
                    >
                      <option disabled="" selected="" value="">
                        Timezone<i>*</i>
                      </option>
                      {newTimezoneData.map((timezone) => (
                        <option value={timezone.id}>
                        {timezone.front_name}
                        </option>
                      ))}
                    </select>
                    {error && <div className="error-select"> <span className="error">Required</span></div>}
                    {/* )} */}

                    {/* <span className="error">
                {timeZoneError && 'Required'}
              </span> */}
                  </div>
                </div>
              </div>
              <div
                className="advance-tag margin-unset pointer"
                onClick={clickHander}
              >
                update
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
