import Pusher from "pusher-js";
import { setLiveCount, setLiveCountOnDemand } from "../../reducer/adminSlice";

const getLiveStatistics = (dispatch) => {
  Pusher.logToConsole = false;

  var pusher = new Pusher("823ab72ed6ecd563b8c8", {
    cluster: "ap2",
  });

  var channel = pusher.subscribe("logged-in-users");
  channel.bind("App\\Events\\LiveUserEvent", function(data) {
    dispatch(setLiveCount(data));
  });

  var channel1 = pusher.subscribe("users-on-demand");
  channel1.bind("App\\Events\\OnDemandUsers", function(data) {
    dispatch(setLiveCountOnDemand(data));
  });
};

export { getLiveStatistics };
