import React, { useEffect } from "react";
import { toTitleCase } from "../../../../../utils/helpers";
export default function InfoPopup({ feedBackinfo }) {
  return (
    <div
      className="modal fade"
      id="infopopup"
      role="dialog"
      data-backdrop="true"
    >
      <div className="modal-dialog modal-dialog-preview">
        {/* <button type="button" className="close" data-dismiss="modal">
        &times;
      </button> */}
        <div className="modal-content margin-top-300">
          <div className="modal-body">
            <div className="profile-video"></div>
            <h3 className="text-center">Feedback</h3>
            <div>
              {feedBackinfo !== null &&
                feedBackinfo.length > 0 &&
                feedBackinfo.map((info, index) => (
                  <>
                    {feedBackinfo.length > 1 && (
                      <h6 className="text-center">Feedback {index + 1}</h6>
                    )}
                    {Object.keys(info).length > 0 &&
                      Object.entries(info).map(([key, value]) => (
                        <span className="d-block mb-2 mt-2 border-feed">
                          {key !== "appointment" && (
                            <>
                              {" "}
                              <h6 className="inline-block">
                                {" "}
                               Question : <b className="d5a67b">{toTitleCase(key)}</b>
                              </h6>{" "}
                              <br />Answer : {value}
                            </>
                          )}
                        </span>
                      ))}
                  </>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}