import React, { useState } from "react";

export default function Questions({
    onCancelQuestions,
    startLoaderNow,
    setCheckYesMain,
}) {
    const [error, setError] = useState(false);
    const [error1, setError1] = useState(false);
    const [check4, setCheck4] = useState("");
    const [check5, setCheck5] = useState(false);
    const [check6, setCheck6] = useState(false);
    const [checkYes, setCheckYes] = useState(false);
    // const [checkNo, setCheckNo] = useState(false);

    const changeHandler = (e, setCheck, type) => {
        if (type === "radio") {
            setCheckYes(e.target.value);
            setCheckYesMain(e.target.value);
        } else {
            setCheck(e.target.checked);
            setCheckYesMain(e.target.checked);
        }
    };

    const onClickHandler2 = () => {
        if (checkYes === "yes" || checkYes === "no") {
            setError(false);
        }
        else {
            setError(true);
            // return
        }

        if (check4 != "") {
            setError1(false);

        }
        else {
            setError1(true);
            // return
        }
        if ((checkYes === "yes" || checkYes === "no") && check4 != "") {

            startLoaderNow(checkYes);
        }



    };
    const changeHandler1 = (e, setCheck, type) => {
        // setCheck(e.target.checked)
        if (e.target.checked) {
            setCheck4(e.target.value);
            // setCheckYesMain(e.target.value);
        } else {
            setCheck4("");
            // setCheckYesMain(e.target.checked)
        }
    };
    return (
        <div class="container-fluid question-container">
            <div class="row justify-content-center">
                <div class="col-11 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
                    <div class="card px-0 pt-4 pb-0 mt-3 mb-3">
                        <form id="msform">
                            <fieldset className="padding-unset pad-bottom-30">
                                <div className="padd-heading text-left">
                                    <h3 className="tex-left font-size-19 ">
                                        On Demand Settings
                                    </h3>{" "}
                                </div>
                                <div className="pad-30">
                                    <div class="form-card">
                                        <div class="row wrap">
                                            <div class="new secend-step">
                                                <div class="form-group margin-left-unset">
                                                    <input
                                                        checked={check4 === "no1" ? "checked" : ""}
                                                        onChange={(e) => {
                                                            changeHandler1(e, setCheckYes, "radio");
                                                        }}
                                                        value={"no1"}
                                                        name="no1"
                                                        type="checkbox"
                                                        id="no1"
                                                    />
                                                    <label htmlFor="no1">
                                                        I am signed into my zoom account on this device
                                                        <br /> (because I may be the host)
                                                        {/* {error1 && ( */}
                                                        <span className="red ml-2 font-size-14">
                                                            *required
                                                        </span>
                                                        {/* )} */}
                                                    </label>
                                                    {error1 && (
                                                        <span className="d-block mt-2 red ml-2 font-size-14">
                                                            Please confirm above!
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            {/* <h2>Zoom Test</h2> */}
                                            <div className="d-flex align-items-center">
                                                <h3 className="w-unset text-underline font-size-19">
                                                    Potential partners
                                                </h3>{" "}
                                                {/* {error && ( */}
                                                <p className="red margin-12px ml-3">
                                                    (Please choose one below to continue)
                                                </p>
                                                {/* )} */}
                                            </div>
                                            <div class="new secend-step">
                                                <div class="form-group margin-left-unset">
                                                    <input
                                                        onChange={(e) => {
                                                            changeHandler(e, setCheckYes, "radio");
                                                        }}
                                                        value={"yes"}
                                                        checked={checkYes === "yes"}
                                                        name="yesNo"
                                                        type="radio"
                                                        id="yes"
                                                    />
                                                    <label htmlFor="yes">
                                                        Anyone (except someone I’ve blocked)
                                                    </label>
                                                </div>
                                                <div class="form-group margin-left-unset">
                                                    <input
                                                        checked={checkYes === "no"}
                                                        onChange={(e) => {
                                                            changeHandler(e, setCheckYes, "radio");
                                                        }}
                                                        value={"no"}
                                                        name="no"
                                                        type="radio"
                                                        id="no"
                                                    />
                                                    <label htmlFor="no">
                                                        Only someone new (haven’t worked with before)
                                                    </label>
                                                </div>
                                                {error && (
                                                    <p className="red margin-12px ml-3">
                                                        Please select any one partner preference !
                                                    </p>
                                                )}
                                                <p className=" d-flex align-items-center font-style-italic">
                                                    <img
                                                        className="w-20-px mr-2"
                                                        src={"/ondemand-icons/i-icon.png"}
                                                        alt="globe"
                                                    />
                                                    {" "}
                                                    Instructions: Please stay near device for next 10
                                                    minutes
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                    <input
                                        onClick={onCancelQuestions}
                                        type="button"
                                        name="previous"
                                        class="previous action-button-previous border-radius-40"
                                        value="Cancel"
                                    />
                                    <input
                                        type="button"
                                        name="next"
                                        class="next action-button border-radius-40"
                                        value="Next"
                                        onClick={onClickHandler2}
                                    />
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
