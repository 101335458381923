import React from "react";

export default function CountStatusComponent({ onClick, src, count, title }) {
  return (
    <div onClick={onClick} className="upcoming-comlilated pointer">
      <div className="content-left">
        <div className="icon-left">
          <img src={src} alt="count" />
        </div>
        <span>
          {count}
          {/* {Object.keys(statuscounts).length > 0 &&
            statuscounts.pendingApprovals} */}
        </span>
        {title}
      </div>
    </div>
  );
}
