import React, { useEffect, useState } from "react";
import { items, slotsArray } from "../../calendar/data";
import Select from "react-select";
import ReactSelect from "react-select";
import {
    changeStatusAppointmentFromAdmin,
    createAppointmentAdmin,
    getClientMediumTimeZone,
} from "../../../../services/admin";
import { da } from "date-fns/locale";
import { toast } from "react-toastify";
import { getTimeImage } from "../../../../utils/utils";
import { setLoading } from "../../../../reducer/auth/auth";
import { useDispatch } from "react-redux";

export default function UpdateAppointments({
    updateItem,
    appType = [],
    from,
    getAppointmentsNewList,
    acc_Type,

}) {
    const dispatch = useDispatch();
    const [appointmentType, setAppointmentType] = useState("");
    const [is_survey, setis_survey] = useState();
    const [userResponsible, setUserResponsible] = useState('');

    const checkAppType = () => {
        let status = true;
        if (appointmentType == "") {
            status = false
        }
        else if (appointmentType === "Complete") {
            status = false;
        }
        
        else if (appointmentType !== "Restrict") {
            status = true
        }
        else if (appointmentType == "Restrict") {
            status = false
        }

        else {
            status = true
        }




        return status;
    }
    const is_surveyHandler = (e) => {
        let checked = e.target.checked;
        setis_survey(checked);
    }
    const createAppointment = () => {
        if (appointmentType == "") {
            toast.error('Please Select Status', {
                position: "top-center",
            });
            return;
        }
        if ((appointmentType == "Cancel" || appointmentType == "No Show") && userResponsible == "") {
            toast.error(`Please Select ${appointmentType} By`, {
                position: "top-center",
            });
            return;
        }
        dispatch(setLoading(true))
        let type = ''
        if (appointmentType === "Complete") {
            type = 1
        }
        else if (appointmentType === "Cancel") {
            type = 3
        }
        else if (appointmentType === "No Show") {
            type = 2
        } else if (appointmentType === "Restrict") {
            type = 5
        }
        else if (appointmentType === "Ended Early") {
            type = 4
        }
        let body = {
            appointment_id: updateItem.id,
            status: parseInt(type),
            user_responsible: parseInt(userResponsible),
            is_survey: is_survey ? 1 : 0
        }

        changeStatusAppointmentFromAdmin(body, acc_Type).then((data) => {


            dispatch(setLoading(false))
            if (data.data.status) {

                toast.success("Appointment Updated Successfully!!", {
                    position: "top-center",
                });

                setAppointmentType("");
                setis_survey()
                setUserResponsible('')

                getAppointmentsNewList();
            }
            else {
                toast.error(data.data.message, {
                    position: "top-center",
                });
            }
        })
            .catch(() => { })

    }
    const onInputChange = (e) => {
        let value = e.target.value;
        setUserResponsible(value);
    }
    const cancelRequest = () => {
        setAppointmentType("");
        setis_survey();
        setUserResponsible("");
        window.loadModal2('#UpdateAppointments', 'hide')
    }
    return (
        <div className="register-medium">
            {console.log(updateItem)}
            <div
                className="modal fade "
                id="UpdateAppointments"
                data-backdrop="static"
                role="dialog"
            >
                <div className="width-unset  modal-dialog advanceConfirmbooking modal-lg  modal-dialog-preview height-unset">
                    <div className="modal-content p-5">
                        <div className="modal-body">
                            <div className="">
                                <div className="schedule-popup">
                                    <h3 className="mb-4 mt-2 text-center mt-2">
                                        Update Appointment
                                    </h3>
                                    {/* {warningMessage !== "" && (
                                        <div className={`eroorbox warning-color`}>
                                            {warningMessage}
                                        </div>
                                    )}
                                    {errorType.map((item) => (
                                        <div className={`eroorbox ${colorType}`}>{item}</div>
                                    ))} */}


                                    <div>
                                        <div className="d-flex align-items-center justify-content-between flex-column">
                                            <div className="from-fild mt-2 mb-4 text-left ">
                                                <label className="font-size-17" htmlFor="firstName">
                                                    Select Status
                                                </label>
                                                <div className="d-flex align-items-center justify-content-center  width-335px">
                                                    <select
                                                        onChange={(e) => {
                                                            setAppointmentType(e.target.value);
                                                             setis_survey(false);
                                                            setUserResponsible("");
                                                            // getSubsessions(e.target.value);
                                                        }}
                                                        className="p-2 w-100"
                                                    value={appointmentType}
                                                    >
                                                        <option value={""} selected disabled>
                                                            Type
                                                        </option>
                                                        {appType.map((item) => <option value={item}>{item}</option>)}


                                                    </select>
                                                </div>
                                            </div>
                                            {console.log(from, appointmentType)}
                                            {( appointmentType === "Complete") &&
                                                <div className="from-fild  text-left padding-unset ">

                                                    <div className="padding-unset ">
                                                        <div className="full-fild mt-2">
                                                            {/* <label>Allow Free Coaching Session</label> */}
                                                            <div className="prvacy-box eth-pri">
                                                                <input
                                                                    onChange={is_surveyHandler}
                                                                    value="is_survey"
                                                                    id="is_survey"
                                                                    type="checkbox"
                                                                    name="is_survey"
                                                                    checked={is_survey}
                                                                />
                                                                <label htmlFor="is_survey">Send Survey Mail</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            }
                                            {checkAppType() && <div className="from-fild mt-2 mb-4 text-left ">
                                                <label className="font-size-17" htmlFor="firstName">
                                                    {appointmentType} By
                                                </label>
                                                <div className="d-flex align-items-center justify-content-center width-335px">
                                                    <select
                                                        onChange={onInputChange}
                                                        name="duration"
                                                        // value={duration}
                                                        className="p-2 w-100"
                                                    >
                                                        <option value={""} selected disabled>
                                                            By
                                                        </option>
                                                        { }
                                                        {updateItem.client_name !== undefined && <>
                                                            <option value={updateItem.client_id}>
                                                                {updateItem.client_name}
                                                            </option>
                                                            <option value={updateItem.medium_id}>
                                                                {updateItem.medium_name}
                                                            </option>
                                                        </>}
                                                    </select>
                                                </div>
                                            </div>}


                                        </div>
                                    </div>




                                </div>
                                <div className="confirm-delete-wrapper ">
                                    <div className="confirm-btn-box">
                                        <div
                                            className="register-medium-confirnation model-close-btn"
                                            onClick={cancelRequest}
                                            data-dismiss="modal"
                                        >
                                            Cancel
                                        </div>
                                        <div
                                            className="register-medium-confirnation"
                                            // data-dismiss="modal"
                                            onClick={

                                                () => createAppointment()

                                            }
                                        >
                                            Confirm
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
