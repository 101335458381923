export const list = [
  { id: 1, name: "Client Feedback to Intermediate", count: 0 },
  { id: 2, name: "Partner Feedback to Practicing Medium", count: 0 },
  { id: 2, name: "Client Feedback to Professional", count: 0 },
];


export const filterList = [

  {
    id: 1,
    name: "Medium name",
    value: "screen_name",
  },
  {
    id: 2,
    name: "Client name",
    value: "name",
  },
  {
    id: 3,
    name: "Session Name",
    value: "session_name",
  },
  {
    id: 4,
    name: "Medium Email",
    value: "medium_email",
  },
  {
    id: 5,
    name: "Client Email",
    value: "client_email",
  },
  {
    id: 6,
    name: "Appt. ID",
    value: "session_id",
  },

];
