import { createSlice } from "@reduxjs/toolkit";
import { act } from "@testing-library/react";

const initialState = {
  users: [],
  allunverifiedEmails:[],
  counts: "",

  allMediums: [],
  allMediumCount: 0,
  allProfessionalsCount: 0,
  allNoviceCount: 0,
  allIntermediateCount: 0,
  allDeclinedCount: 0,
  allPendingApprovalsCount: 0,
  allUnverifiedEmailsCount:0,
  allOutreachClientsCount: 0,
  allStarterClientsCount: 0,
  allRestrictedClientsCount: 0,
  allVolunteerClientsCount:0,
  allUpcomingCount: 0,
  allCompletedCount: 0,
  allCancelledCount: 0,
  allNoShowCount: 0,
  allRestricted: 0,
  allEndedEarly: 0,
  allOngoingCount: 0,
  allProfessionals: [],
  allNovice: [],
  allIntermediate: [],
  allDeclined: [],
  allUpcomingAppointments: [],
  allCompletedAppointments: [],
  allCancelledAppointments: [],
  allNoShowsAppointments: [],
  allOngoingAppointments: [],
  allClients: [],
  allStarterClients: [],
  allRestrictedClients: [],
  allVolunteerClients:[],
  mediumProfileDetails: null,
  pathArray: null,
  filteredData: [],
  feedBacks: [],
  practicefeedBacks:[],
  nameRequests: [],
  calendarSessions: [],
  sessionTypes: [],
  sessionFormInfo: {},
  scheduleArray: [],
  sessionMediumShipRecords: [],
  assignSessionArray: [],
  screenNameDetails: null,
  admsurveyList: [],
  approvedFeedback: [],
  mediumpage: 1,
  mediumrowsPerPage: 10,
  novicepage: 1,
  novicerowsPerPage: 10,
  intermediatePage: 1,
  intermediaterowsPerPage: 10,
  professionalPage: 1,
  professionalrowsPerPage: 10,
  pendingapprovalsPage: 1,
  pendingapprovalsrowsPerPage: 10,
  unverifiedEmailsPage:1,
  unverifiedEmailsrowsPerPage:10,
  declinedPage: 1,
  declinedrowsPerPage: 10,
  //
  // allU
  // appointments
  upcomingAppointmentsPage: 1,
  upcomingAppointmentsrowsPerPage: 10,
  completedAppointmentsPage: 1,
  completedAppointmentsrowsPerPage: 10,
  cancelledAppointmentsPage: 1,
  cancelledAppointmentsrowsPerPage: 10,
  noshowAppointmentsPage: 1,
  noshowAppointmentsrowsPerPage: 10,
  restrictedAppointmentsPage: 1,
  restrictedAppointmentsrowsPerPage: 10,
  volunteerAppointmentsPage:1,
  volunteerAppointmentsrowsPerPage:10,
  endedEarlyAppointmentsPage: 1,
  endedEarlyAppointmentsrowsPerPage: 10,
  ongoingAppointmentsPage: 1,
  ongoingAppointmentsrowsPerPage: 10,
  globalRowsPerPage: 10,

  //


  ////
  allPendingApprovalsPage:1,
  allPendingApprovalsrowsPerPage:10,
   allUnverifiedEmailsPage:1,
   allUnverifiedEmailsrowsPerPage:10,
   allOutreachClientsPage:1,
   allOutreachClientsrowsPerPage:10,
 
   allStarterClientsPage:1,
   allStarterClientsrowsPerPage:10,
   allRestrictedClientsPage:1,
   allRestrictedClientsrowsPerPage:10,
 
   allVolunteerClientsPage:1,
   allVolunteerClientsrowsPerPage:10,
 

  /////
  feedbackPage:1,
  feedbackrowsPerPage:10,
  practicefeedbackPage:1,
  practicefeedbackrowsPerPage:10
  //
};
export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setAllUnverifiedEmails: (state, action) => {
      state.allunverifiedEmails = action.payload;
    },
    setDashboardCount: (state, action) => {
      state.counts = action.payload;
    },
    setAllMediumsCount: (state, action) => {
      state.allMediumCount = action.payload;
    },
    setAllProfessionalsCount: (state, action) => {
      state.allProfessionalsCount = action.payload;
    },
    setAllNoviceCount: (state, action) => {
      state.allNoviceCount = action.payload;
    },
    setAllIntermediateCount: (state, action) => {
      state.allIntermediateCount = action.payload;
    },
    setAllPendingApprovalsCount: (state, action) => {
      state.allPendingApprovalsCount = action.payload;
    },
    setAllUnverifiedEmailsCount: (state, action) => {
      state.allUnverifiedEmailsCount = action.payload;
    },
    setAllDeclinedCount: (state, action) => {
      state.allDeclinedCount = action.payload;
    },
    setAllOutreachClientsCount: (state, action) => {
      state.allOutreachClientsCount = action.payload;
    },
    setAllStarterClientsCount: (state, action) => {
      state.allStarterClientsCount = action.payload;
    },

    setAllUpcomingCount: (state, action) => {
      state.allUpcomingCount = action.payload;
    },
    setAllCompletedCount: (state, action) => {
      state.allCompletedCount = action.payload;
    },
    setAllCancelledCount: (state, action) => {
      state.allCancelledCount = action.payload;
    },
    setAllNoShowCount: (state, action) => {
      state.allNoShowCount = action.payload;
    },
    setAllRestrictedCount: (state, action) => {
      state.allRestricted = action.payload
    },
    setEndedEarlyCount: (state, action) => {
      state.allEndedEarly = action.payload
    },
    setAllOngoingCount: (state, action) => {
      state.allOngoingCount = action.payload;
    },
    setLiveCount: (state, action) => {
      if (typeof state.counts !== "string") {
        state.counts = {
          ...state.counts,
          ...(state.counts.LoggedInClients = action.payload.LoggedInClients),
          ...(state.counts.loggedInMediums = action.payload.loggedInMediums),
        };
      }
    },
    setLiveCountOnDemand: (state, action) => {
      if (typeof state.counts !== "string") {
        state.counts = {
          ...state.counts,
          ...(state.counts.onDemandMediums = action.payload.onDemandMediums),
          ...(state.counts.onDemandClients = action.payload.onDemandClients),
        };
      }
    },
    setAllMediums: (state, action) => {
      // console.log(action.payload, "22");
      state.allMediums = action.payload;
    },
    setAllProfessionals: (state, action) => {
      // console.log(action.payload, "26");
      state.allProfessionals = action.payload;
    },
    setFilteredData: (state, action) => {
      state.filteredData = action.payload;
    },
    setAllIntermediate: (state, action) => {
      state.allIntermediate = action.payload;
    },
    setAllDeclined: (state, action) => {
      state.allDeclined = action.payload;
    },
    setAllNovice: (state, action) => {
      // console.log(action.payload, "34");
      state.allNovice = action.payload;
    },
    setAllAppointments: (state, action) => {
      state.allUpcomingAppointments = action.payload.upcoming;
      state.allCompletedAppointments = action.payload.completed;
      state.allCancelledAppointments = action.payload.cancelled;
      state.allNoShowsAppointments = action.payload.show;
      state.allOngoingAppointments = action.payload.ongoing;
    },
    //
    // setAllUpcomingAppointments:(state,action) =>{
    //   state.allUpcomingAppointments = action.payload.upcoming;
    // }
    // setAllUpcomingAppointments:(state,action) =>{
    //   state.allUpcomingAppointments = action.payload.upcoming;
    // }
    //
    setAllClients: (state, action) => {
      state.allClients = action.payload;
    },
    setAllStarterClients: (state, action) => {
      state.allStarterClients = action.payload;
    },
    setMediumProfileDetails: (state, action) => {
      state.mediumProfileDetails = action.payload;
    },
    setFeedBacks: (state, action) => {
      state.feedBacks = action.payload;
    },
    setPracticeFeedBacks: (state, action) => {
      state.practicefeedBacks = action.payload;
    },
    setPathArray: (state, action) => {
      localStorage.setItem('patharrayBackup',action.payload)
      state.pathArray = action.payload;
    },
    setNameRequests: (state, action) => {
      state.nameRequests = action.payload;
    },
    setCalendarSession: (state, action) => {
      state.calendarSessions = action.payload;
    },
    setSessionTypes: (state, action) => {
      console.log(action.payload);
      state.sessionTypes = action.payload;
    },
    setSessionFormInfo: (state, action) => {
      state.sessionFormInfo = action.payload;
    },
    setScheduleArray: (state, action) => {
      state.scheduleArray = action.payload;
    },
    setSessionMediumShipRecords: (state, action) => {
      state.sessionMediumShipRecords = action.payload;
    },
    setassignSessionArray: (state, action) => {
      state.assignSessionArray = action.payload;
    },
    setScreenNameDetails: (state, action) => {
      state.screenNameDetails = action.payload;
    },
    setAdmsurveyList: (state, action) => {
      state.admsurveyList = action.payload;
    },
    setApprovedFeedback: (state, action) => {
      state.approvedFeedback = action.payload;
    },
    setAllRestrictedClients: (state, action) => {
      state.allRestrictedClients = action.payload;
    },
    setAllRestrictedClientsCount: (state, action) => {
      state.allRestrictedClientsCount = action.payload;
    },
    setAllVolunteerClients: (state, action) => {
      state.allVolunteerClients = action.payload;
    },
    setAllVolunteerClientsCount: (state, action) => {
      state.allVolunteerClientsCount = action.payload;
    },
    setMediumPage: (state, action) => {
      console.log(state,action,"mediumpagegit")
      state.mediumpage = action.payload;
    },
    setMediumrowsPerPage: (state, action) => {
      state.mediumrowsPerPage = action.payload;
    },
    setNovicePage: (state, action) => {
      state.novicepage = action.payload;
    },
    setNovicerowsPerPage: (state, action) => {
      state.novicerowsPerPage = action.payload;
    },
    setIntermediatePage: (state, action) => {
      state.intermediatepage = action.payload;
    },
    setIntermediaterowsPerPage: (state, action) => {
      state.intermediaterowsPerPage = action.payload;
    },
    setProfessionalPage: (state, action) => {
      state.professionalPage = action.payload;
    },
    setProfessionalrowsPerPage: (state, action) => {
      state.professionalrowsPerPage = action.payload;
    },
    setPendingApprovalsPage: (state, action) => {
      state.pendingapprovalsPage = action.payload;
    },
    setPendingApprovalsrowsPerPage: (state, action) => {
      state.pendingapprovalsrowsPerPage = action.payload;
    },
    setUnverifiedEmailsPage: (state, action) => {
      state.unverifiedEmailsPage = action.payload;
    },
    setUnverifiedEmailsrowsPerPage: (state, action) => {
      state.unverifiedEmailsrowsPerPage = action.payload;
    },
    setDeclinedPage: (state, action) => {
      state.declinedpage = action.payload;
    },
    setDeclinedrowsPerPage: (state, action) => {
      state.declinedrowsPerPage = action.payload;
    },

    //appointments

    setUpcomingAppointmentsPage: (state, action) => {
      state.upcomingAppointmentsPage = action.payload;
    },
    setUpcomingAppointmentsrowsPerPage: (state, action) => {
      state.upcomingAppointmentsrowsPerPage = action.payload;
    },
    //
    setCompletedAppointmentsPage: (state, action) => {
      state.completedAppointmentsPage = action.payload;
    },
    setCompletedAppointmentsrowsPerPage: (state, action) => {
      state.completedAppointmentsrowsPerPage = action.payload;
    },

    //
    setCancelledAppointmentsPage: (state, action) => {
      state.cancelledAppointmentsPage = action.payload;
    },
    setCancelledAppointmentsrowsPerPage: (state, action) => {
      state.cancelledAppointmentsrowsPerPage = action.payload;
    },
    //
    setNoShowAppointmentsPage: (state, action) => {
      state.noshowAppointmentsPage = action.payload;
    },
    setNoShowAppointmentsrowsPerPage: (state, action) => {
      state.noshowAppointmentsrowsPerPage = action.payload;
    },
    //
    //
    setrestrictedAppointmentsrowsPerPage: (state, action) => {
      state.restrictedAppointmentsrowsPerPage = action.payload
    },
    setvolunteerAppointmentsrowsPerPage: (state, action) => {
      state.volunteerAppointmentsrowsPerPage = action.payload
    },
    setendedEarlyAppointmentsrowsPerPage: (state, action) => {
      state.endedEarlyAppointmentsrowsPerPage = action.payload
    },
    setrestrictedAppointmentsPage: (state, action) => {
      state.restrictedAppointmentsPage = action.payload;
    },
    setvolunteerAppointmentsPage: (state, action) => {
      state.volunteerAppointmentsPage = action.payload;
    },
    setendedEarlyAppointmentsPage: (state, action) => {
      state.endedEarlyAppointmentsPage = action.payload;
    },




    //

    setOngoingAppointmentsPage: (state, action) => {
      state.ongoingAppointmentsPage = action.payload;
    },
    setOngoingAppointmentsrowsPerPage: (state, action) => {
      state.ongoingAppointmentsrowsPerPage = action.payload;
    },
    setGlobalRowsPerPage: (state, action) => {
      state.globalRowsPerPage = action.payload;
    },

    //
    setFeedbackPage: (state, action) => {
      state.feedbackPage = action.payload;
    },

    setFeedbackrowsPerPage: (state, action) => {
      state.feedbackrowsPerPage = action.payload;
    },
    setPracticeFeedbackPage: (state, action) => {
      state.practicefeedbackPage = action.payload;
    },
    setPracticeFeedbackrowsPerPage: (state, action) => {
      state.practicefeedbackPage = action.payload;
    },


    ////

    setAllPendingApprovalsPage: (state, action) => {
    state.allPendingApprovalsPage  = action.payload;
    },
    setAllPendingApprovalsrowsPerPage: (state, action) => {
    state.allPendingApprovalsrowsPerPage  = action.payload;
    },
     setAllUnverifiedEmailsPage: (state, action) => {
    state.allUnverifiedEmailsPage  = action.payload; 
    },
     setAllUnverifiedEmailsrowsPerPage: (state, action) => {
    state.allUnverifiedEmailsrowsPerPage  = action.payload; 
    },
     setAllOutreachClientsPage: (state, action) => {
    state.allOutreachClientsPage  = action.payload; 
    },
     setAllOutreachClientsrowsPerPage: (state, action) => {
    state.allOutreachClientsrowsPerPage  = action.payload; 
    },
   
     setAllStarterClientsPage: (state, action) => {
    state.allStarterClientsPage  = action.payload; 
    },
     setAllStarterClientsrowsPerPage: (state, action) => {
    state.allStarterClientsrowsPerPage  = action.payload; 
    },
     setAllRestrictedClientsPage: (state, action) => {
    state.allRestrictedClientsPage  = action.payload; 
    },
     setAllRestrictedClientsrowsPerPage: (state, action) => {
    state.allRestrictedClientsrowsPerPage  = action.payload; 
    },
   
     setAllVolunteerClientsPage: (state, action) => {
    state.allVolunteerClientsPage  = action.payload; 
    },
     setAllVolunteerClientsrowsPerPage: (state, action) => {
    state.allVolunteerClientsrowsPerPage  = action.payload; 
    },


    ////
  
    

  },
});

export const {
  setPracticeFeedbackPage,
  setPracticeFeedbackrowsPerPage,
  setUsers,
  setAllUnverifiedEmails,
  setDashboardCount,
  setAllMediumsCount,
  setAllDeclinedCount,
  setAllNoviceCount,
  setAllProfessionalsCount,
  setAllIntermediateCount,

// // // //
 setAllPendingApprovalsPage,
 setAllPendingApprovalsrowsPerPage,
  setAllUnverifiedEmailsPage,
  setAllUnverifiedEmailsrowsPerPage,
  setAllOutreachClientsPage,
  setAllOutreachClientsrowsPerPage,

  setAllStarterClientsPage,
  setAllStarterClientsrowsPerPage,
  setAllRestrictedClientsPage,
  setAllRestrictedClientsrowsPerPage,

  setAllVolunteerClientsPage,
  setAllVolunteerClientsrowsPerPage,


//// // //


  setAllPendingApprovalsCount,
  setAllUnverifiedEmailsCount,
  setAllOutreachClientsCount,
  setAllStarterClientsCount,
  setAllRestrictedClients,
  setAllVolunteerClients,


  
  setAllMediums,
  setAllProfessionals,
  setAllIntermediate,
  setAllNovice,
  setAllDeclined,
  setAllAppointments,
  setAllUpcomingCount,
  setAllCompletedCount,
  setAllCancelledCount,
  setAllNoShowCount,
  setAllRestrictedCount,
  setEndedEarlyCount,
  setAllOngoingCount,
  setMediumPage,
  setMediumrowsPerPage,
  setNovicePage,
  setNovicerowsPerPage,
  setIntermediatePage,
  setIntermediaterowsPerPage,
  setProfessionalPage,
  setProfessionalrowsPerPage,
  setPendingApprovalsPage,
  setPendingApprovalsrowsPerPage,
  setUnverifiedEmailsPage,
  setUnverifiedEmailsrowsPerPage,
  setDeclinedPage,
  setDeclinedrowsPerPage,
  //
  setUpcomingAppointmentsPage,
  setUpcomingAppointmentsrowsPerPage,
  setCompletedAppointmentsPage,
  setCompletedAppointmentsrowsPerPage,
  setCancelledAppointmentsPage,
  setCancelledAppointmentsrowsPerPage,
  setNoShowAppointmentsPage,
  setNoShowAppointmentsrowsPerPage,
  setrestrictedAppointmentsrowsPerPage,
  setendedEarlyAppointmentsrowsPerPage,
  setrestrictedAppointmentsPage,
  setvolunteerAppointmentsrowsPerPage,
  setvolunteerAppointmentsPage,
  setendedEarlyAppointmentsPage,
  setOngoingAppointmentsPage,
  setOngoingAppointmentsrowsPerPage,
  setGlobalRowsPerPage,

  //


  //


  setFeedbackPage,
  setFeedbackrowsPerPage,
  //
  setAllClients,
  setAllStarterClients,
  setMediumProfileDetails,
  setPathArray,
  setFilteredData,
  setFeedBacks,
  setPracticeFeedBacks,
  setNameRequests,
  setLiveCount,
  setLiveCountOnDemand,
  setCalendarSession,
  setSessionTypes,
  setSessionFormInfo,
  setScheduleArray,
  setSessionMediumShipRecords,
  setassignSessionArray,
  setScreenNameDetails,
  setAdmsurveyList,
  setApprovedFeedback,
  setAllRestrictedClientsCount,
  setAllVolunteerClientsCount
} = adminSlice.actions;

export default adminSlice.reducer;

export const CurrentUser = (state) => state.users;
