import React from "react";

export default function TimeZoneCofirmPopup({ cancelRequest, confirmRequest, id }) {
    return (
        <div className="confirm-delete-wrapper register-medium mt-5">
            <div data-backdrop="static" className="modal fade" id={id} role="dialog">
                <div className="modal-dialog padding-unset modal-dialog-preview">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="icon-pop mt-4">
                                <img src="/images/Union-icon.png" alt="Union-icon" />
                            </div>
                            <h3 className="p-1">Are you in Arizona, USA or Yukon, CAN?</h3>
                            <div className="confirm-btn-box">
                                <div
                                    className="register-medium-confirnation"
                                    onClick={()=>confirmRequest('')}
                                    data-dismiss="modal"
                                >
                                    Yes
                                </div>
                                <div
                                    className="register-medium-confirnation"
                                    data-dismiss="modal"
                                    onClick={cancelRequest}
                                >
                                    No
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
