import React, { useState } from 'react'

export default function StepOne({ onCancelQuestions ,startLoaderNow }) {
    const [error, setError] = useState(false);

    const [check4, setCheck4] = useState(false);
    const [check5, setCheck5] = useState(false);
    const [check6, setCheck6] = useState(false);
    const [checkYes, setCheckYes] = useState(false);
    // const [checkNo, setCheckNo] = useState(false);

    const changeHandler = (e, setCheck, type) => {
        if (type === "radio") {
            setCheckYes(e.target.value);
        } else {
            setCheck(e.target.checked);
        }
    };

    const onClickHandler2 = () => {
        if (
            checkYes === "yes" ||
            (checkYes === "no" && check4 && check5 && check6)
        ) {
            // setStep(1);
            setError(false);
            startLoaderNow();
        } else {
            setError(true);
        }

    };
    return (
        <div class="container-fluid question-container">
            <div class="row justify-content-center">
                <div class="col-11 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
                    <div class="card px-0 pt-4 pb-0 mt-3 mb-3">
                        <form id="msform">
                            <fieldset>
                                <div class="form-card">
                                    <div class="row wrap">
                                        <h2>Zoom Test</h2>
                                        <h3>Are you a regular Zoom user ?</h3>
                                        <div class="new secend-step">
                                            <div class="form-group">
                                                <input
                                                    onChange={(e) => {
                                                        changeHandler(e, setCheckYes, "radio");
                                                    }}
                                                    value={"yes"}
                                                    checked={checkYes === "yes"}
                                                    name="yesNo"
                                                    type="radio"
                                                    id="yes"
                                                />
                                                <label htmlFor="yes">Yes</label>
                                            </div>
                                            <div class="form-group">
                                                <input
                                                    checked={checkYes === "no"}
                                                    onChange={(e) => {
                                                        changeHandler(e, setCheckYes, "radio");
                                                    }}
                                                    value={"no"}
                                                    name="no"
                                                    type="radio"
                                                    id="no"
                                                />
                                                <label htmlFor="no">No</label>
                                            </div>
                                        </div>

                                        {checkYes === "no" && (
                                            <>
                                                <h3>
                                                    If no, please test your ability to connect with Zoom
                                                    here
                                                </h3>
                                                <a href="https://zoom.us/test" target={"_blank"}>
                                                    https://zoom.us/test
                                                </a>
                                                <p className="red">  &nbsp;(Only for testing audio, video and sound.)</p>
                                                <h3>Please check each box to confirm :</h3>
                                                <div class="new secend-step">
                                                    <div class="form-group">
                                                        <input
                                                            onChange={(e) => {
                                                                changeHandler(e, setCheck4);
                                                            }}
                                                            checked={check4}
                                                            name="zoom"
                                                            type="checkbox"
                                                            id="zoom"
                                                        />
                                                        <label for="zoom">Zoom audio worked</label>
                                                    </div>
                                                    <div class="form-group">
                                                        <input
                                                            onChange={(e) => {
                                                                changeHandler(e, setCheck5);
                                                            }}
                                                            checked={check5}
                                                            name="worked"
                                                            type="checkbox"
                                                            id="worked"
                                                        />
                                                        <label for="worked">Zoom microphone worked</label>
                                                    </div>
                                                    <div class="form-group">
                                                        <input
                                                            onChange={(e) => {
                                                                changeHandler(e, setCheck6);
                                                            }}
                                                            checked={check6}
                                                            name="video"
                                                            type="checkbox"
                                                            id="video"
                                                        />
                                                        <label for="video">Zoom video worked</label>
                                                    </div>
                                                    {error && (
                                                        <div>
                                                            <p className="red">
                                                                *Please Select all check boxes
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <input
                                    onClick={onCancelQuestions}
                                    type="button"
                                    name="previous"
                                    class="previous action-button-previous"
                                    value="Cancel"
                                />
                                <input
                                    type="button"
                                    name="next"
                                    class="next action-button"
                                    value="Next"
                                    onClick={onClickHandler2}
                                />

                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
