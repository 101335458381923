import Vimeo from "@u-wave/react-vimeo";
import React from "react";
import { Link } from "react-router-dom";
import { innerWidthHandler } from "../utils/helpers";

export default function MediumThanks() {
  return (
    <div className="coming-soon">
      <p className="font-size-28 text-center max-width-900">
        While you’re waiting, feel free to turn up your volume and preview  this video that Outreach
        Clients will Watch before their mediumship session.
      </p>
      <Vimeo
        muted={true}
        autoplay={true}
        className="popover-video"
        width={innerWidthHandler()}
        height={430}
        video={"https://vimeo.com/767367299/9424233cd8"}
      />
      <div className="text-center">
        <Link className="coming-soon-btn" to={"/login"}>
          {" "}
          Back To Login
        </Link>
      </div>
    </div>
  );
}
