import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  appointmentsList: [],
  topAppointment: [],
  counts: "",
  allUpcomingCount: 0,
  allCompletedCount: 0,
  allCancelledCount: 0,
  allNoShowCount: 0,
  goOndemandStatus: null,
  linkZoomRequest: false,
  allUpcomingAppointments: [],
  allCompletedAppointments: [],
  allCancelledAppointments: [],
  allNoShowsAppointments: [],
  feedBacks: [],
  practicefeedBacks: [],
  coachingfeedBacks: [],
  matchingStarted: false,

  mediumupcomingAppointmentsPage: 1,
  mediumupcomingAppointmentsrowsPerPage: 10,

  mediumcompletedAppointmentsPage: 1,
  mediumcompletedAppointmentsrowsPerPage: 10,

  mediumcancelledAppointmentsPage: 1,
  mediumcancelledAppointmentsrowsPerPage: 10,

  mediumnoshowAppointmentsPage: 1,
  mediumnoshowAppointmentsrowsPerPage: 10,
  globalRowsPerPage: 10,
};
export const mediumSlice = createSlice({
  name: "medium",
  initialState,
  reducers: {
    setMediumUpcomingAppointmentsPage: (state, action) => {
      state.mediumupcomingAppointmentsPage = action.payload;
    },
    setMediumUpcomingAppointmentsrowsPerPage: (state, action) => {
      state.mediumupcomingAppointmentsrowsPerPage = action.payload;
    },
    //
    setMediumCompletedAppointmentsPage: (state, action) => {
      state.mediumcompletedAppointmentsPage = action.payload;
    },
    setMediumCompletedAppointmentsrowsPerPage: (state, action) => {
      state.mediumcompletedAppointmentsrowsPerPage = action.payload;
    },

    //
    setMediumCancelledAppointmentsPage: (state, action) => {
      state.mediumcancelledAppointmentsPage = action.payload;
    },
    setMediumCancelledAppointmentsrowsPerPage: (state, action) => {
      state.mediumcancelledAppointmentsrowsPerPage = action.payload;
    },
    //
    setMediumNoShowAppointmentsPage: (state, action) => {
      state.mediumnoshowAppointmentsPage = action.payload;
    },
    setMediumNoShowAppointmentsrowsPerPage: (state, action) => {
      state.mediumnoshowAppointmentsrowsPerPage = action.payload;
    },
    setGlobalRowsPerPage: (state, action) => {
      state.globalRowsPerPage = action.payload;
    },
    setaAppointmentsList: (state, action) => {
      state.appointmentsList = action.payload;
    },
    setTopAppointmentList: (state, action) => {
      state.topAppointment = action.payload;
    },
    setDashboardCount: (state, action) => {
      state.counts = action.payload;
    },
    setGoOndemandStatus: (state, action) => {
      state.goOndemandStatus = action.payload;
    },
    setLinkZoomRequest: (state, action) => {
      state.linkZoomRequest = action.payload;
    },
    setAllAppointments: (state, action) => {
      state.allUpcomingAppointments = action.payload.upcoming;
      state.allCompletedAppointments = action.payload.completed;
      state.allCancelledAppointments = action.payload.cancelled;
      state.allNoShowsAppointments = action.payload.show;
    },
    setselectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    setFeedBacks: (state, action) => {
      state.feedBacks = action.payload;
    },
    setPracticefeedBacks: (state, action) => {
      state.practicefeedBacks = action.payload;
    },
    setCoachingfeedBacks: (state, action) => {
      state.coachingfeedBacks = action.payload;
    },
    
    setAllUpcomingCount: (state, action) => {
      state.allUpcomingCount = action.payload;
    },
    setAllCompletedCount: (state, action) => {
      state.allCompletedCount = action.payload;
    },
    setAllCancelledCount: (state, action) => {
      state.allCancelledCount = action.payload;
    },
    setAllNoShowCount: (state, action) => {
      state.allNoShowCount = action.payload;
    },
    setMatchingStarted: (state, action) => {
      state.matchingStarted = action.payload;
    },
  },
});

export const {
  setaAppointmentsList,
  setTopAppointmentList,
  setDashboardCount,
  setGoOndemandStatus,
  setLinkZoomRequest,
  setAllAppointments,
  setAllUpcomingCount,
  setAllCompletedCount,
  setAllCancelledCount,
  setAllNoShowCount,
  setFeedBacks,
  setPracticefeedBacks,
  setCoachingfeedBacks,
  setMatchingStarted,
  //
  setMediumUpcomingAppointmentsPage,
  setMediumUpcomingAppointmentsrowsPerPage,
  //
  setMediumCompletedAppointmentsPage,
  setMediumCompletedAppointmentsrowsPerPage,


  //
  setMediumCancelledAppointmentsPage,
  setMediumCancelledAppointmentsrowsPerPage,

  //
  setMediumNoShowAppointmentsPage,
  setMediumNoShowAppointmentsrowsPerPage,
  setGlobalRowsPerPage
} = mediumSlice.actions;

export default mediumSlice.reducer;
