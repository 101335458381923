import moment from "moment";
import React, { useEffect, useState } from "react";

export default function TimerForEvent({ activeEvent }) {

    const [open, close] = useState(false);
    let eventtime = moment(
        `${activeEvent.backupdate} ${activeEvent.time}`,
        "YYYY-MM-DD hh:mm A"
    ).format("YYYY-MM-DD hh:mm A");
    // const { id  } = useSelector((state) => state.user.user);
    const calculateTimeLeft = () => {
        close(true)
        const now = moment();
        const target = moment(eventtime.replace(/-/g, '/'));
        const duration = moment.duration(target.diff(now));

        return {
            days: Math.floor(duration.asDays()),
            hours: duration.hours(),
            minutes: duration.minutes(),
            seconds: duration.seconds(),
        };
    };

    const [timeLeft, setTimeLeft] = useState();


    useEffect(() => {
        setTimeLeft(calculateTimeLeft());
        close(false)
    }, [activeEvent])
    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        // Cleanup interval on component unmount
        return () => clearInterval(timer);
    }, [eventtime])
    return (
        <div>
            <div
                id="TimerForEvent"
                class="modal fade bd-example-modal-lg mt-5 preview-modal-availability"
                tabindex="-1"
                role="dialog"
                aria-labelledby="myLargeModalLabel"
                aria-hidden="true"
                data-backdrop="static"
            >
                <div class="modal-dialog modal-lg modal-dialog-preview text-center">
                    <div class="modal-content p-5">
                        <h3>Event Will Start In


                        </h3>
                        <br />
                        {open && <h2>{timeLeft.days}d : {timeLeft.hours}h : {timeLeft.minutes}m : {timeLeft.seconds}s</h2>}
                        <div id="msform">

                            <input
                                type="button"
                                name="Close"
                                class="next action-button m-3"
                                value="Close"
                                onClick={() => window.loadModal2('#TimerForEvent', "hide")}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
