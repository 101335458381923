import React from 'react'
import { Link } from 'react-router-dom'

export default function ThankyouPopup() {
    return (
        // <div
        //     class="modal fade mt-5"
        //     id="thankyoupopup"
        //     tabindex="-1"
        //     role="dialog"
        //     aria-labelledby="myLargeModalLabel"
        //     aria-hidden="true"
        // >
            // <div class="modal-dialog modal-lg">
                <div class="thankyou-page-box">

                    <div class="thankyou-page-wrapper">
                        <div className='thankyou-page-container'>
                            <img src={'/integrationimages/thankyou.png'} />
                            {/* <p>You are now a member of our list of awesome peoples</p> */}
                            <Link className='mt-2' to={'/'}>Back to Home</Link>
                        </div>
                    </div>
                </div>
            // </div>
        // </div>
    )
}
