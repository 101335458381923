import { Formik } from "formik";
//import { values } from "pusher-js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getMediumRole, mediumRoles } from "../../../../utils/utils";
import { assignSessions } from "../functions";
import "./assignsessionsbylevel.css";
const initialValues = { session_type: [], mediumship_level: "" };
export default function AssignSessionsByLevel({
  openSessionList,
  assignSessionList,
}) {
  const {calendarSessions, sessionTypes  ,assignSessionArray} = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const [sessions, setSessions] = useState([]);
  const [roles, setroles] = useState([
    { key: "Novice I", value: "" },
    { key: "Novice II", value: "" },
    { key: "Intermediate I", value: "" },
    { key: "Intermediate II", value: "" },
    { key: "Professional I", value: "" },
    { key: "Professional II", value: "" },
    { key: "Professional III", value: "" },
    { key: "Support", value: "" },
  ]);

  useEffect(() => {
    setSessions(
      calendarSessions.map((item) => {
        return {
          value: item.session_id,
          label: item.session_name,
        };
      })
    );
  }, []);
  useEffect(() => {
    console.log(assignSessionArray,"assignSessionList")
    let role = roles;
    Object.keys(assignSessionArray).length > 0 &&
      Object.entries(assignSessionArray).map(([key, value]) => {
        role.map((level) => {
          if (level.key === key) {
            level.value = value
          }
        });
      });
      setroles(role)
      console.log(role,"role")
  }, [assignSessionArray]);
  const submitForm = (values) => {
    let body = {
      ...values,
      session_type: values.session_type.map.length && values.session_type.map((item) => {
        return item.value;
      }),
    };
    assignSessions(body, dispatch);
  };
  const onChangeHandler = (e, setFieldValue) => {
    initialValues.session_type = [];
    let found;
    let val = e.target.value;
    setFieldValue("mediumship_level", val);
    Object.entries(assignSessionList).map(([key, value]) => {
      if (key === getMediumRole(parseInt(val))) {
        found = sessions.filter((item, index) => {
          return value.includes(item.label);
        });
      }
    });
    setFieldValue("session_type", found);
  };

  return (
    <>
      <div className="assignsessionsbylevel-all-box">
        <Formik
          initialValues={initialValues}
          validate={(values) => {
            const errors = {};

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            submitForm(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="top-box-saction">
                <div className="left-top-box">
                  <span>Assign Sessions By Level</span>
                </div>
                <div className="right-top-box">
                  <button
                    type="button"
                    onClick={openSessionList}
                    className="btn-back"
                  >
                    Back
                  </button>{" "}
                  <button type="submit" className="sumbit-btn">
                    Submit
                  </button>
                </div>
              </div>

              <div className="assignsessionsbylevel-box-select">
                <div>
                  <div className="assignsessionsbylevel-filde">
                    <label>Approved Level</label>
                    <select
                      onChange={(e) => onChangeHandler(e, setFieldValue)}
                      value={values.mediumship_level}
                      name="mediumship_level"
                      id="mediumship_level"
                      className="approved-single-select"
                    >
                      <option selected disabled value="">
                        Approved Level
                      </option>
                      {mediumRoles.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="assignsessionsbylevel-filde">
                    <label>Choose Session Name </label>
                    <Select
                      id="session_type"
                      className="assign-level-select"
                      options={sessions}
                      isMulti
                      onChange={(value) => setFieldValue("session_type", value)}
                      // onBlur={handleBlur}
                      placeholder=""
                      value={values.session_type}
                      closeMenuOnSelect={false}
                      closeMenuOnScroll={false}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
        <div className="assignsessionsbylevel-box-select">
          <div className="assignlevel-table">
            <table>
              <tbody>
                <tr>
                  <th className="text-align-center-unset">Roles / Levels</th>
                  <th className="text-align-center-unset">
                    Approved Session Types
                  </th>
                </tr>

                {/* {Object.keys(assignSessionList).length > 0 &&
                  Object.entries(assignSessionList).map(([key, value]) => ( */}
                  {roles.length > 0 && roles.map(({key,value})=>(
                    <tr>
                      <>
                        <td className="text-align-center-unset">{key}</td>
                        <td className="text-align-center-unset">
                          {value.length > 0 &&  value.map((val) => (
                            <>{val},</>
                          ))}
                        </td>
                      </>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
