import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSessionTypes,
  updateLanguage,
  updateCountries,
} from "../../admin/calendar/functions";
import MyScheduleForm from "./scheduleform";
import MySchedules from "./schedules";
import { useSearchParams } from "react-router-dom";
export default function MyAvailability() {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.user.user);
  const { sessionTypes } = useSelector((state) => state.users);

  const { languagesArray, countryArray } = useSelector((state) => state.uidata);
  const [searchParams] = useSearchParams();

  const [redirectType, setRedirectType] = useState("schedules");
  const [languages, setLanguages] = useState([]);
  const [scheduleId, setScheduleId] = useState("");
  const [sessionTypesList, setSessionTypesList] = useState([]);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    console.log('working ,cekn')
    let from = searchParams.get("from");
    let time = searchParams.get("time");
    if (from === "onboarding" && time === "1") {
      openScheduleForm();
      const newUrl = new URL(window.location.href);

      newUrl.searchParams.delete("time");
      window.history.replaceState({}, document.title, newUrl.href);
    }
    if (!sessionTypes.length) getSessionTypes(dispatch);
    if (!languagesArray.length) updateLanguage(dispatch);
    if (!countryArray.length) updateCountries(dispatch);
  }, []);

  useEffect(() => {
    setLanguages(
      languagesArray.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      })
    );
  }, [languagesArray]);

  useEffect(() => {
    setCountries(
      countryArray.map((item) => {
        return {
          value: item.id,
          label: item.country_name,
        };
      })
    );
  }, [countryArray]);

  useEffect(() => {
    setSessionTypesList(sessionTypes);
  }, [sessionTypes]);

  const openSchedules = () => {
    setRedirectType("schedules");
  };
  const openScheduleForm = (id) => {
    if (id === "") {
      setScheduleId("");
    } else {
      setScheduleId(id);
    }
    setRedirectType("add-edit-schedule");
  };
  return (
    <>
      {redirectType === "schedules" && (
        <MySchedules
          openScheduleForm={openScheduleForm}
          medium_id={id}
          setScheduleId={setScheduleId}
        />
      )}
      {redirectType === "add-edit-schedule" && (
        <MyScheduleForm
          openSchedules={openSchedules}
          medium_id={id}
          schedule_id={scheduleId}
          languages={languages}
          countries={countries}
        />
      )}
    </>
  );
}
