import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Tabs from "../../../components/tabs";
import TabPanel from "../../../components/tabs/tabpanel";
import Upcoming from "./upcoming";
import Cancelled from "./cancelled";
import Completed from "./completed";

import { list } from "./data";

//
import { setAllAppointments, setAllUpcomingCount, setAllCompletedCount, setAllCancelledCount, setAllNoShowCount, setMediumUpcomingAppointmentsPage, setMediumUpcomingAppointmentsrowsPerPage, setMediumCompletedAppointmentsPage, setMediumCompletedAppointmentsrowsPerPage, setMediumCancelledAppointmentsPage, setMediumCancelledAppointmentsrowsPerPage, setMediumNoShowAppointmentsPage, setMediumNoShowAppointmentsrowsPerPage } from "../../../reducer/mediumSlice";

//service
import {
  getAllAppointments,
  changeAppointmentStatus,
  filterAllAppointments,
  setNewTimeZone,
  changeAppointmentStatusPractice,
  blockUserfromMedium,
} from "../../../services/medium";

import "./appointments.css";
import NoShows from "./noshows";
import { getTimezones } from "../../../services/uidata";
import { setTimezoneArray } from "../../../reducer/uidataSlice";
import { setNewTimeZoneData } from "../../../reducer/userSlice";
import { emptyAllSelectBox, filterVisibleTimeZones } from "../../../utils/helpers";
import { setLoading, setLoadingContent, setLoadingText } from "../../../reducer/auth/auth";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";

export default function Appointments() {
  const { timezoneArray } = useSelector((state) => state.uidata);
  const { timezone, id } = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const {
    allUpcomingAppointments,
    allCompletedAppointments,
    allCancelledAppointments,
    allNoShowsAppointments,
    allUpcomingCount,
    allCompletedCount,
    allCancelledCount,
    allNoShowCount,
    mediumupcomingAppointmentsPage,
    mediumupcomingAppointmentsrowsPerPage,

    mediumcompletedAppointmentsPage,
    mediumcompletedAppointmentsrowsPerPage,

    mediumcancelledAppointmentsPage,
    mediumcancelledAppointmentsrowsPerPage,

    mediumnoshowAppointmentsPage,
    mediumnoshowAppointmentsrowsPerPage,
    globalRowsPerPage,
  } = useSelector((state) => state.medium);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams()
  const [activeTab, setActiveTab] = useState(0);
  const [upcomingAppointment, setUpcomingAppointments] = useState([]);
  const [completedAppointment, setCompletedAppointments] = useState([]);
  const [canceledAppointment, setCanceledAppointments] = useState([]);
  const [showAppointment, setShowAppointments] = useState([]);
  const [listArray, setListArray] = useState(list);
  const [backuupcoming, setbackupUpcoming] = useState([]);
  const [backuupcompleted, setbackupCompleted] = useState([]);
  const [backuupcancelled, setbackupCancelled] = useState([]);
  const [backuupnoshow, setbackupUpNoShow] = useState([]);
  const [timezoneData, setTimezoneData] = useState([]);

  const [filterValues, setFilterValues] = useState('screen_name');
  const [searchValue, setSearchValues] = useState()
  const [noShowValue, setNowShowValues] = useState(0);

  const [appointment_id, setappointment_id] = useState('');

  const [blink, setBlink] = useState(false);
  const [message, setMessage] = useState('')



  useEffect(() => {
    const from = searchParams.get('from');
    const message1 = searchParams.get('message');
    const appointment_i = searchParams.get('appointment_id');
    console.log(from, "Client Confirm");
    // appointments?from=goondemand&appointment_id${data.appointment.id}&message=${data.message}`)
    if (from === "goondemand") {
      setappointment_id(appointment_i);
      setMessage(message1)
      // toast.success(message, { position: "top-center" })
      setBlink(true)
    }
    else {
      setBlink(false)
    }
    setTimeout(() => {
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.delete("from");
      newUrl.searchParams.delete("message");
      newUrl.searchParams.delete("appointment_id");
      window.history.replaceState({}, document.title, newUrl.href);

    }, 3000)
  }, [])
  useEffect(() => {
    localStorage.removeItem('appoinmentId')


    // let from = searchParams.get('from')
    // console.log(from, "from")
    // if (from === "goondemand") {

    const data = {
      page: 1,
      rowsPerPage: 10
    }

    getAllAppointmentsList(data, activeTab);


    // }
    // if (from === "onboarding") {
    // }

    if (!timezoneArray.length) updateTimezones();
    launcApi();
  }, []);
  useEffect(() => {

    setTimezoneData(filterVisibleTimeZones(timezoneArray));
  }, [timezoneArray]);

  const changeTimeZoneHandler = (e) => {
    dispatch(setLoading(true))
    let id = e.target.value;
    let body = {
      timezone_id: id,
    };
    setNewTimeZone(body)
      .then((data) => {
        dispatch(setLoading(false))
        if (data.data.status) {
          const data = {
            page: 1,
            rowsPerPage: 10
          }
          getAllAppointmentsList(data, activeTab);
          dispatch(setNewTimeZoneData(filterTimeZone(id)));
        }
      })
      .catch(() => { dispatch(setLoading(false)) });
  };
  const filterTimeZone = (id) => {
    let zone;
    timezoneData.filter((item) => {
      if (parseInt(id) === parseInt(item.id)) {
        zone = item.front_name;
      }
    });
    console.log(zone);
    return zone;
  };
  // useEffect(() => {
  //   const data={
  //     page:1,
  //     rowsPerPage:10
  //   }
  //   console.log('activeTab',activeTab);
  //   getAllAppointmentsList(data,activeTab);

  // }, [activeTab]);
  useEffect(() => {
    console.log(

      allCompletedAppointments,
      allUpcomingAppointments,
      allCancelledAppointments,
      allNoShowsAppointments,
    )
    setListedArray(allUpcomingAppointments, setUpcomingAppointments);
    setListedArray(allCompletedAppointments, setCompletedAppointments);
    setListedArray(allCancelledAppointments, setCanceledAppointments);
    setListedArray(allNoShowsAppointments, setShowAppointments);
    setListArray(
      list.map((item) => {
        if (item.name === "Upcoming") {
          return {
            ...item,
            ...(item.count = allUpcomingCount),
          };
        }
        if (item.name === "Completed") {
          return {
            ...item,
            ...(item.count = allCompletedCount),
          };
        }

        if (item.name === "Cancelled") {
          return {
            ...item,
            ...(item.count = allCancelledCount),
          };
        }

        if (item.name === "No Shows") {
          return {
            ...item,
            ...(item.count = allNoShowCount),
          };
        }
      })
    );
  }, [
    allCompletedAppointments,
    allUpcomingAppointments,
    allCancelledAppointments,
    allNoShowsAppointments,
  ]);
  const getPaginationData = (data, activeTab) => {
    getAllAppointmentsList(data, activeTab)


  }


  const updateTimezones = () => {
    getTimezones()
      .then((data) => {
        dispatch(setTimezoneArray(data.data.data));
      })
      .catch((err) => console.log(err));
  };
  const checkStatus0Values = (data) => {
    if (data.attendees !== undefined) {
      let array = data.attendees.filter((info) => info.status == 0)
      return array
    }
    else {
      return []
    }
    // return data.attendees.length;
  }
  const setListedArray = (item, set) => {
    const values = item.map((resp) => {
      return {
        ...resp,
        status: false,
        statusValue: '',
        nostatusValue: '',
        noOfAttendees: checkStatus0Values(resp),
        noShowTimes: getTimeDiff(resp),

      };
    });

    set(values);
  };
  const onClickHandler = (e, id, name) => {
    e.stopPropagation();
    if (name === "upcoming") {
      const response = upcomingAppointment.map((user) => {
        if (user.appointmentId === id) {
          user.status = !user.status;
        } else {
          user.status = false;
        }
        return user;
      });
      setUpcomingAppointments(response);
    }
    if (name === "completed") {
      const response = completedAppointment.map((user) => {
        if (user.appointmentId === id) {
          user.status = !user.status;
        } else {
          user.status = false;
        }
        return user;
      });
      setCompletedAppointments(response);

    }
    // const responsee = upcomingAppointment.map((user) => {
    //   return {
    //     ...user,
    //     status: false,
    //   };
    // });
    // setTimeout(() => {
    //   setUpcomingAppointments(responsee);
    // }, 8000);
    // if (name == "novice") {
    //   const response = novice.map((user) => {
    //     if (user.id === id) {
    //       user.status = !user.status;
    //     } else {
    //       user.status = false;
    //     }
    //     return user;
    //   });
    //   setNovice(response);
    // }
    // if (name == "professional") {
    //   const response = professionals.map((user) => {
    //     if (user.id === id) {
    //       user.status = !user.status;
    //     } else {
    //       user.status = false;
    //     }
    //     return user;
    //   });
    //   setProfessionals(response);
    // }
    // if (name == "pendingAprrovals") {
    //   const response = pendingApprovals.map((user) => {
    //     if (user.id === id) {
    //       user.status = !user.status;
    //     } else {
    //       user.status = false;
    //     }
    //     return user;
    //   });
    //   setPendingApprovals(response);
    // }
  };
  const getAllAppointmentsList = (paginationdata, activeTab, info) => {

    if (info === "refresh") {
      // setUpcomingAppointments([])
      // setCanceledAppointments([])
      // setCompletedAppointments([])
      // setShowAppointments([])
    }
    let appointmentType = "";
    if (activeTab === 0) {
      appointmentType = 'upcoming'
    }
    if (activeTab === 1) {
      appointmentType = 'completed'
    }
    if (activeTab === 2) {
      appointmentType = 'cancelled'
    }
    if (activeTab === 3) {
      appointmentType = 'show'
    }
    if (activeTab === 4) {
      appointmentType = 'ongoing'
    }
    console.log(searchValue, filterValues, "session_type=${session_type}&name=${screen_name}&meeting_id=${meeting_id}`")

    let newbody = {
      session_type: searchValue !== undefined && searchValue.session_type !== undefined ? searchValue.session_type : '',
      screen_name: searchValue !== undefined && searchValue.screen_name !== undefined ? searchValue.screen_name : '',
      meeting_id: searchValue !== undefined && searchValue.meeting_id !== undefined ? searchValue.meeting_id : '',

    }
    Object.assign(paginationdata, newbody);
    console.log(paginationdata, paginationdata, "paginationdata");
    getAllAppointments(paginationdata, appointmentType)
      .then((data) => {
        if (data.data.status) {
          dispatch(setAllUpcomingCount(JSON.parse(JSON.stringify(data.data.data.upcoming_pagination.total))))

          dispatch(setAllCompletedCount(JSON.parse(JSON.stringify(data.data.data.completed_pagination.total))))
          dispatch(setAllCancelledCount(JSON.parse(JSON.stringify(data.data.data.cancelled_pagination.total))))
          dispatch(setAllNoShowCount(JSON.parse(JSON.stringify(data.data.data.show_pagination.total))))
          dispatch(setAllAppointments(data.data.data));
          setbackupUpcoming(
            JSON.parse(JSON.stringify(data.data.data.upcoming))
          );
          setbackupCompleted(
            JSON.parse(JSON.stringify(data.data.data.completed))
          );
          setbackupCancelled(
            JSON.parse(JSON.stringify(data.data.data.cancelled))
          );
          setbackupUpNoShow(JSON.parse(JSON.stringify(data.data.data.show)));



        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const launcApi = () => {
    const data = {
      page: 1,
      rowsPerPage: 10
    }
    if (
      !allUpcomingAppointments.length > 0 ||
      !allCompletedAppointments.length > 0 ||
      !allCancelledAppointments.length > 0 ||
      !allNoShowsAppointments.length > 0
    ) {
      getAllAppointmentsList(data, activeTab);
    }
  };
  const changeStatusPractice = (userId, status, appointmentId, type, ids,) => {
    dispatch(setLoadingText(true));
    dispatch(setLoadingContent('<div>Please wait, We are updating your appointment</div>'));
    let body;

    body = {
      appointment_id: appointmentId,
      user_id: userId
    };

    changeAppointmentStatusPractice(body)
      .then((data) => {
        emptyAllSelectBox()
        setTimeout(() => {
          dispatch(setLoadingText(false));
          dispatch(setLoadingContent(''));
        }, 1000);
        if (data.data.status) {
          // dispatch(setLoading(false))

          setTimeout(() => {
            if (type === "medium") {
              //no show
              toast.success("Appointment status updated succesfully", {
                position: "top-center",
              });
            } else {
              toast.success("Appointment cancelled succesfully", {
                position: "top-center",
              });
            }
          }, 1000);

          const info = {
            page: 1,
            rowsPerPage: 10
          }
          getAllAppointmentsList(info, activeTab, 'refresh');
        }
      })
      .catch(() => {
        dispatch(setLoadingText(false));
        dispatch(setLoadingContent(''));
      });
  };

  const blockUser = (appointment_id, user_id, client_id) => {
    let check = parseInt(id) === parseInt(user_id) ? client_id : user_id;
    let body = {
      appointment_id: appointment_id,
      blocked_by: id,
      blocked_user: check,
    }
    blockUserfromMedium(body)
      .then((data) => {
        if (data.data.status) {
          toast.success(data.data.message, {
            position: "top-center",
          });
          let dat = {
            page: 1,
            rowsPerPage: 10
          }
          getAllAppointmentsList(dat, activeTab);
        }
        else {
          toast.error(data.data.message, {
            position: "top-center",
          });
        }
      }).catch(() => {

      })

  }
  const changeStatus = (userId, status, appointmentId, type, ids, sessionType = 0, noShowBy = 0) => {
    console.log(noShowBy, " noShowBy")
    dispatch(setLoadingText(true));
    dispatch(setLoadingContent('<div>Please wait, We are updating your appointment</div>'));
    let body;

    body = {
      userId: userId,
      status: status,
      appointmentId: appointmentId,
      restrictByMedium: true,
    };

    changeAppointmentStatus(body)
      .then((data) => {
        emptyAllSelectBox()
        setTimeout(() => {
          dispatch(setLoadingText(false));
          dispatch(setLoadingContent(''));
        }, 1000);
        if (data.data.status) {
          // dispatch(setLoading(false))


          setTimeout(() => {
            if (type === "medium") {
              //no show
              toast.success("Appointment status updated succesfully", {
                position: "top-center",
              });
            } else {
              toast.success("Appointment cancelled succesfully", {
                position: "top-center",
              });
            }
            if (status == 2 && data.data.data.backfill_status && data.data.data.backfill_status == 1 && noShowBy == "other") {
              if (sessionType == 5 || sessionType == 7 || sessionType == 8)
                window.loadModal2("#BackFillConfirmation", "show");
            }
          }, 1000);

          const info = {
            page: 1,
            rowsPerPage: 10
          }
          getAllAppointmentsList(info, activeTab, 'refresh');
        }
      })
      .catch(() => {
        dispatch(setLoadingText(false));
        dispatch(setLoadingContent(''));
      });
  };
  const changeHandler = (e, status, appointmentId, id, type, ids, sessionType, noShowBy,) => {
    changeStatus(id, status, appointmentId, type, ids, sessionType, noShowBy);
  };
  const changeHandle1 = (e, status, appointmentId, id, type, ids, sessionType, noShowBy,) => {
    changeStatusPractice(id, status, appointmentId, type, ids, sessionType, noShowBy);
  };
  const filterListAppointments = (constants) => {
    console.log(activeTab);
    const { session_type, screen_name, meeting_id } = constants;
    let body = {
      session_type,
      screen_name,
      meeting_id
    };
    // if (activeTab == 0) {
    filterAllAppointments(body)
      .then((data) => {
        if (data.data.status) {

          if (parseInt(activeTab) === 0) {
            dispatch(setMediumUpcomingAppointmentsPage(mediumupcomingAppointmentsPage + 1));
            dispatch(setMediumUpcomingAppointmentsrowsPerPage(globalRowsPerPage));
          }
          else if (parseInt(activeTab) === 1) {
            dispatch(setMediumCompletedAppointmentsPage(mediumcompletedAppointmentsPage + 1));
            dispatch(setMediumCompletedAppointmentsrowsPerPage(globalRowsPerPage));
          }
          else if (parseInt(activeTab) === 2) {
            dispatch(setMediumCancelledAppointmentsPage(mediumcancelledAppointmentsPage + 1));
            dispatch(setMediumCancelledAppointmentsrowsPerPage(globalRowsPerPage));
          }

          else if (parseInt(activeTab) === 3) {
            dispatch(setMediumNoShowAppointmentsPage(mediumnoshowAppointmentsPage + 1));
            dispatch(setMediumNoShowAppointmentsrowsPerPage(globalRowsPerPage));
          }


          console.log(data.data.data.upcoming_pagination.total, "data.data.data")
          dispatch(setAllUpcomingCount(JSON.parse(JSON.stringify(data.data.data.upcoming_pagination.total))))

          dispatch(setAllCompletedCount(JSON.parse(JSON.stringify(data.data.data.completed_pagination.total))))
          dispatch(setAllCancelledCount(JSON.parse(JSON.stringify(data.data.data.cancelled_pagination.total))))
          dispatch(setAllNoShowCount(JSON.parse(JSON.stringify(data.data.data.show_pagination.total))))
          dispatch(setAllAppointments(data.data.data));
        }
      })
      .catch(() => { });
    // }

    // if (activeTab == 1) {
    //   filterAllAppointments(body)
    //     .then((data) => {
    //       if (data.data.status) {
    //         dispatch(setAllAppointments(data.data.data));
    //       }
    //     })
    //     .catch(() => {});
    // }
    // if (activeTab == 2) {
    //   filterAllAppointments(body)
    //     .then((data) => {
    //       if (data.data.status) {
    //         dispatch(setAllAppointments(data.data.data));
    //       }
    //     })
    //     .catch(() => {});
    // }
    // if (activeTab == 3) {
    //   filterAllAppointments(body)
    //     .then((data) => {
    //       if (data.data.status) {
    //         dispatch(setAllAppointments(data.data.data));
    //       }
    //     })
    //     .catch(() => {});
    // }
  };
  const filterReset = () => {
    setFilterValues('screen_name');
    setSearchValues("");
    let dataVal = {
      "email": "",
      "client_name": "",
      "created_at": "",
      "orderBy": "asc",
      "referal_code": "",
      "created_at_order_by": ""
    }
    filterListAppointments(dataVal);
  }
  const goToMediumBackFill = (time, id) => {
    navigate(`/backfill-medium?appId=${id}&time=${time}`)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setNowShowValues((prevValue) => prevValue + 1)
    }, 60000); // 60000 ms = 1 minute

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);
  useEffect(() => {
    let appoinment = upcomingAppointment.map((info) => {
      return {
        ...info,
        noShowTimes: getTimeDiff(info),
      }
    })
    setUpcomingAppointments(appoinment)
  }, [noShowValue])
  const getTimeDiff = (appoinment) => {
    let appdate = moment(appoinment.date).format('YYYY/MM/DD');
    let now = moment().format();
    let datetimeB = moment(appdate + " " + appoinment.time);


    let final = datetimeB.diff(now, 'minutes');
    // console.log(final)
    let status;
    final > -5 ? status = true : status = false
    return status
  }
  return (
    <div className="all-medium-page">
      {blink && <div class="alert alert-success d-flex align-items-center justify-content-center font-size-20 height-56"  role="alert">
       <b> {message}</b>
      </div>}
      <div className="middle-screen-name time-zone-area font-size-22 mt-2 mb-2">
        {" "}
        <a target="_blank" className="font-size-14 text-underline mr-3 pointer learn-more-btn" href="https://vimeo.com/922676519/c68142040f?share=copy">Learn more</a>
        <select
          onChange={changeTimeZoneHandler}
          className="select-box-timezone pointer"
        >
          <option disabled selected value="">
            Change Timezone
          </option>
          {timezoneData.map((item) => (
            <option value={item.id}>{item.front_name}</option>
          ))}
        </select>
        <b>Timezone: {timezone !== undefined && timezone}&nbsp;</b>
      </div>
      <Tabs active={activeTab} list={listArray} setActiveTab={setActiveTab} />
      <ul className="tabs-content">
        <TabPanel id={0} activeTab={activeTab}>
          <Upcoming
            upcomingAppointments={upcomingAppointment}
            changeHandler={changeHandler}
            onClickHandler={onClickHandler}
            filterListAppointments={filterListAppointments}
            filterReset={filterReset}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            setFilterValues={setFilterValues}
            changeHandle1={changeHandle1}
            setSearchValues={setSearchValues}
            getAllAppointmentsList={getAllAppointmentsList}
            activeTab={activeTab}
            searchValue={searchValue}
            filterValues={filterValues}
            setAppointments={setUpcomingAppointments}
            goToMediumBackFill={goToMediumBackFill}
            blink={blink}
            appointment_id={appointment_id}
          />
        </TabPanel>
        <TabPanel id={1} activeTab={activeTab}>
          <Completed
            completedAppointments={completedAppointment}
            changeHandler={changeHandler}
            onClickHandler={onClickHandler}
            filterListAppointments={filterListAppointments}
            filterReset={filterReset}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            changeHandle1={changeHandle1}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            getAllAppointmentsList={getAllAppointmentsList}
            activeTab={activeTab}
            searchValue={searchValue}
            filterValues={filterValues}
            blockUser={blockUser}
            goToMediumBackFill={goToMediumBackFill}
            setBlink={setBlink}
          />
        </TabPanel>
        <TabPanel id={2} activeTab={activeTab}>
          <Cancelled
            canceledAppointment={canceledAppointment}
            changeHandler={changeHandler}
            filterListAppointments={filterListAppointments}
            filterReset={filterReset}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            searchValue={searchValue}
            filterValues={filterValues}
            activeTab={activeTab}
            blockUser={blockUser}
            getAllAppointmentsList={getAllAppointmentsList}
            setBlink={setBlink}
          />
        </TabPanel>
        <TabPanel id={3} activeTab={activeTab}>
          <NoShows
            showAppointment={showAppointment}
            activeTab={activeTab}
            changeHandler={changeHandler}
            filterListAppointments={filterListAppointments}
            filterReset={filterReset}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            searchValue={searchValue}
            filterValues={filterValues}
            blockUser={blockUser}
            getAllAppointmentsList={getAllAppointmentsList}
            setBlink={setBlink}
          />
        </TabPanel>
      </ul>
    </div>
  );
}
