import API from "../api/api";

const config = (token) => {
  return {
    headers: {
      "Content-type": "application/json",

      "X-Frame-Options": "DENY",
      "Content-Security-Policy": "style-src 'self'",
      Authorization: `Bearer ${token}`,
    },
  };
};

export const createSession = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(`get-mediums`, body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getZoom = async (medium) => {
  let token = localStorage.getItem("access_token");
  const currentTime = getCurrentTime();

  const configHeaders = (token, params) => {
    return {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: params,
    };
  };
  let body = {
    start_time: currentTime,
    duration: 30,
    host_video: "",
    participant_video: "",
    agenda: "",
    topic: "Medium Session",
  };
  if (medium) {
    body.medium = medium;
  }
  const response = await API.get(`get-zoom-link`, configHeaders(token, body))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

const getCurrentTime = () => {
  const time = new Date();
  console.log(time, "timetimetimetime");
  const currentTime =
    time.getFullYear() +
    "-" +
    ("00" + (time.getMonth() + 1)).slice(-2) +
    "-" +
    ("00" + time.getDate()).slice(-2) +
    "T" +
    ("00" + time.getHours()).slice(-2) +
    ":" +
    ("00" + time.getMinutes()).slice(-2) +
    ":" +
    ("00" + time.getSeconds()).slice(-2);
  console.log(currentTime);
  return currentTime;
};

export const getMatchedAvailabilites = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `get-matched-availabilites`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const saveSessionTerms = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(`save-session-terms`, body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const saveAdvanceBooking = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(`save-advance-booking`, body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};


export const saveDirectAdvanceBooking = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `save-direct-advance-appointment`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getAllAppointments = async (data, appointmentType) => {
  console.log("paginatedData", data);
  console.log("appointmentType", appointmentType);
  let token = localStorage.getItem("access_token");
  let page = data !== undefined ? data.page : 1;
  let rowsPerPage = data !== undefined ? data.rowsPerPage : 10;
  const response = await API.get(
    `appointment-show?page=${page}&appoitment_type=${appointmentType}&rowsPerPage=${rowsPerPage}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const getClientCount = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`get-client-appointment-status`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getClientPreferences = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`get-client-preferences`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const setClientPreferences = async (body) => {
  let token = localStorage.getItem("access_token");

  const response = await API.post(`set-client-preferences`, body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const updateLegalprivacy = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post("update-client-legal-privacy", body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const getPractitionars = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `get-prodessional-mediums?ondemand=yes`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const getPractitionarsFilter = async (body) => {
  console.log(body);
  const {
    allow_REcording,
    languages,
    specialities,
    whereDoIPay,
    sessionLength,
    experience,
    selectedName,
    session_currency,
  } = body;
  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `get-prodessional-mediums?ondemand=yes&allow_recordings=${allow_REcording}&languages[]=${languages}&specialities[]=${specialities}&pay_type[]=${whereDoIPay}&session_length[]=${sessionLength}&medium_professional_category[]=${experience}&screen_name=${selectedName}&session_currency=${session_currency}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getPractitionarInfo = async (id) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `user/get-medium-details?medium=${id}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getProfileBasicInfo = async (id) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`profile-info`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getLegalAndServicesForClient = async (id) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`get-client-legal-privacy`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const getSlotsByDate = async (
  date,
  user_type,
  session_id,
  medium_id,
  subsession_id = "",
  is_group_session,
  client_id
) => {
  console.log(session_id);
  let url1 = `group/coaching/get-medium-slots-by-date?date=${date}
  &session_id=${session_id}&medium_id=${medium_id}&subsession_id=${subsession_id}
  &is_medium_list=true`
  let url = client_id == "" ? `get-slots-by-date?date=${date}&session_id=${session_id !== "" && session_id !== undefined ? session_id : "5"
    }&medium_id=${medium_id}&subsession_id=${subsession_id}`:
    `get-slots-by-date?date=${date}&session_id=${session_id !== "" && session_id !== undefined ? session_id : "5"
    }&medium_id=${medium_id}&subsession_id=${subsession_id}&client_id=${client_id}`
    ;
  let token = localStorage.getItem("access_token");
  const response = await API.get(is_group_session == 1 ? url1 : url, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const changeAppointmentStatus = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "client-change-appointment-status",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getBannerPage = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`get-banner-page`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getSlotsCountByDate = async (body, is_group_session) => {
  console.log(body, "datada");
  let token = localStorage.getItem("access_token");
  let url1 = `group/coaching/get-medium-calendar?from=${body.startDate}
  &to=${body.endDate}
  &medium_id=${body.medium_id == false ? "" : body.medium_id}
  &service=${body.session_id}
  &session_length=${body.subsession_id}`;
  let url =
    body.user_type !== undefined
      ? `get-slots-count-by-from-to-date?from=${body.startDate}&to=${body.endDate
      }&session_id=${body.session_id == "" ? "5" : body.session_id
      }&medium_id=${body.medium_id == false ? "" : body.medium_id
      }&subsession_id=${body.subsession_id}&client_id=${body.client_id}`
      : `get-slots-count-by-from-to-date?from=${body.startDate}&to=${body.endDate
      }&session_id=${body.session_id == "" ? "5" : body.session_id
      }&medium_id=${body.medium_id == false ? "" : body.medium_id
      }&subsession_id=${body.subsession_id}`;
  const response = await API.get(is_group_session == 1 ? url1 : url, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const payWithPaypal = async (body) => {
  let url = `paypal-access?medium_id=${body.medium_id}&amount=${body.amount}&currency=${body.currency}`;
  let token = localStorage.getItem("access_token");
  const response = await API.get(url, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const payWithStripe = async (body) => {
  let url = `stripe-access?medium_id=${body.medium_id}&amount=${body.amount}&currency=${body.currency}`;
  let token = localStorage.getItem("access_token");
  const response = await API.get(url, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const payWithSquareUp = async (body) => {
  let url = `square-up-access?medium_id=${body.medium_id}&amount=${body.amount}&currency=${body.currency}`;
  let token = localStorage.getItem("access_token");
  const response = await API.get(url, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const payWithPaypalCompleted = async (body) => {
  let url =
    body.payment_source !== undefined
      ? `paypal-access/${body.id}?medium_id=${body.medium_id}&amount=${body.amount}&currency=${body.currency}&client_id=${body.client_id}&payment_source=${body.payment_source}`
      : `paypal-access/${body.id}?medium_id=${body.medium_id}&amount=${body.amount}&currency=${body.currency}&appointment_type=${body.appointment_type}&client_id=${body.client_id}`;
  let token = localStorage.getItem("access_token");
  const response = await API.get(url, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const payWithStripeCompleted = async (body) => {
  let url =
    body.payment_source !== undefined
      ? `stripe-access/${body.id}?medium_id=${body.medium_id}&amount=${body.amount}&currency=${body.currency}&client_id=${body.client_id}&payment_source=${body.payment_source}`
      : `stripe-access/${body.id}?medium_id=${body.medium_id}&amount=${body.amount}&currency=${body.currency}&appointment_type=${body.appointment_type}&client_id=${body.client_id}`;
  let token = localStorage.getItem("access_token");
  const response = await API.get(url, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const payWithSquareCompleted = async (body) => {
  let url =
    body.payment_source !== undefined
      ? `square-up-access/${body.id}?medium_id=${body.medium_id}&amount=${body.amount}&currency=${body.currency}&client_id=${body.client_id}&payment_source=${body.payment_source}`
      : `square-up-access/${body.id}?medium_id=${body.medium_id}&amount=${body.amount}&currency=${body.currency}&appointment_type=${body.appointment_type}&client_id=${body.client_id}`;
  let token = localStorage.getItem("access_token");
  const response = await API.get(url, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const doAProfessionalPayment = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "save-direct-advance-appointment-return-payment-url",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const doAPayItForwardPayment = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "pay-it-forward",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const doAPayItForwardPaymentProcess = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "pay-it-forward-process",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const getCreateAppointmentSubseesions = async (id) => {
  let token = localStorage.getItem("access_token");
  const response = await API.get(`get-subsessions/${id}`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getMediumServiceDetails = async (id) => {
  let token = localStorage.getItem("access_token");
  const response = await API.get(
    `get-medium-services?medium_id=${id}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getDateCount = async (body) => {
  console.log(body, "datada");
  let token = localStorage.getItem("access_token");
  let url = `get-session-data/${body.medium_id}?from=${body.startDate}&to=${body.endDate}&timezone=${body.timezone}&session_id=${body.session_id}&subsession_id=${body.subsession_id}`;

  const response = await API.get(url, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getTimeSlotsByDate = async (body) => {
  let token = localStorage.getItem("access_token");
  let url = `get-sesssion-data-by-date/${body.medium_id}?date=${body.date}&timezone=${body.timezone}&session_id=${body.session_id}&subsession_id=${body.subsession_id}`;

  const response = await API.get(url, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const createGuestBooking = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "create-guest-booking",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
}
export const getAppointmentInfo = async (id) => {
  let token = localStorage.getItem("access_token");
  const response = await API.get(`appointment/${id}`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getSessionLimit = async () => {
  let token = localStorage.getItem("access_token");
  const response = await API.get(`get-session-limit`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
}

export const addClientToBackFillQueue = async (event_id,date ,end_time,user_pereference) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`create-backfill-booking`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};