import React from "react";

export default function BackFillConfirmation({ deleteUser, cancelRequest, id }) {
    return (
        <div className="confirm-delete-wrapper register-medium">
            <div data-backdrop="static" className="modal fade" id={id} role="dialog">
                <div className="modal-dialog padding-unset modal-dialog-preview">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="icon-pop mt-3">
                                <img src="images/Union-icon.png" alt="Union-icon" />
                            </div>
                            <h3>Do you want a backfill client if available soon?</h3>
                            <div className="confirm-btn-box">
                                <div
                                    className="register-medium-confirnation model-close-btn"
                                    onClick={cancelRequest}
                                    // data-dismiss="modal"
                                >
                                    No
                                </div>
                                <div
                                    className="register-medium-confirnation"
                                    // data-dismiss="modal"
                                    onClick={deleteUser}
                                >
                                    Yes
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
