import React, { useState } from "react";
import { getBrowserData } from "../../../utils/helpers";
import { useEffect } from "react";
import Button from "../../../components/button";

export default function CacheStep({ startLoaderOndemand ,onCancelQuestions }) {
    const [error, setError] = useState(false);

    const [check4, setCheck4] = useState(false);
    const [check5, setCheck5] = useState(false);
    const [check6, setCheck6] = useState(false);
    const [checkYes, setCheckYes] = useState(false);
    // const [checkNo, setCheckNo] = useState(false);

    const changeHandler = (e, setCheck, type) => {
        setCheck(e.target.checked)
        if (e.target.checked) {
            setCheckYes(e.target.value);
            // setCheckYesMain(e.target.value);
        } else {
            setCheckYes('');
            ;
            // setCheckYesMain(e.target.checked)
        }
    };

    const onClickHandler2 = () => {
        if (
            checkYes === "yes"
        ) {
            // setStep(1);
            setError(false);

            startLoaderOndemand('step2');
        } else {
            setError(true);
        }

    };
    return (
        <div class="container-fluid question-container">
            {/* {console.log(ua,"ua")} */}
            <div class="row justify-content-center">
                <div class="col-11 col-sm-10 col-md-10 col-lg-8 col-xl-8 text-center p-0 mt-3 mb-2">
                    <div class="card px-0 pt-4 pb-0 mt-3 mb-3">
                        <form id="msform">
                            <fieldset>
                                <div class="form-card">
                                    <h3 className="d-block text-center font-size-28">  Clear your device cache</h3>
                                    <h3 className="d-block font-size-20">
                                        To ensure seamless operation of the On-Demand sessions, we
                                        advise you to clear your browser cache before going Live.
                                        <br />
                                    </h3>
                                    <h3 className="d-block font-size-20">  It’s generally okay to clear your cache occasionally:</h3>
                                    <ul>
                                        <li>
                                            <h3 className="d-block font-size-20">
                                                Improves performance: Clearing your cache can help your
                                                computer or device run faster and load web pages from
                                                quickly.
                                            </h3>
                                        </li>
                                        <li>

                                            <h3 className="d-block font-size-20">
                                                Fixes Issues: Clearing your cache can help you fix
                                                problems with loading or formatting websites, or with
                                                viewing new versions of previously visited pages
                                            </h3>
                                        </li>
                                        <li>
                                            <h3 className="d-block font-size-20">
                                                Keeps things clean: Clearing your cache can help keep your
                                                device clean by removing temporary files that can pile up.
                                            </h3>
                                        </li>
                                        <li>
                                            <h3 className="d-block font-size-20">
                                                Good tech hygiene: Clearing your cache is considered good
                                                “tech hygiene”.
                                            </h3>
                                        </li>
                                    </ul>
                                    <h3 className="d-block font-size-20">
                                        The Cache is a temporary storage area on your device that
                                        stores information from websites you have recently visited.
                                        Clearing your cache only removes cached images and files, and
                                        does not affect your passwords.<br /><br /> You can clear your cache in
                                        different browsers and devices by doing the following:
                                    </h3>
                                    <br />
                                    <br />
                                    <ul>
                                        <li>
                                            <h3 className="font-size-20 text-left d-block ">
                                                {" "}
                                                If you have Mac operating System the press CMD + R
                                            </h3>
                                        </li>
                                        <li>
                                            <h3 className="font-size-20 text-left d-block">
                                                {" "}
                                                If you have Window operating System
                                            </h3>
                                            <ol type="a" className="font-size-20 line-height-30px">
                                                <li>
                                                    Chrome:
                                                    <ol type="i">
                                                        <li>Go to 3 dots in upper right corner
                                                        </li>
                                                        <li>Scroll down, find and click “History”</li>
                                                        <li>Look left, find and click “History”</li>
                                                        <li>In upper left corner find and select “Delete Browsing Data”</li>
                                                        <li> Change Time range to “All time”</li>
                                                        <li>Make sure “Cache” selected</li>
                                                        <li>Select “Delete Data”</li>

                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <h3 className="font-size-20 text-left d-block">
                                                {" "}
                                                Safari/chrome, IPhone / Safari:<br /> Settings > Apps > Safari >
                                                Clear History and Website Data <br /><br />Also clear cache on any
                                                other browsers or devices that you’ve used with on demand in
                                                the past.
                                            </h3>
                                        </li>
                                    </ul>

                                    <br />


                                </div>
                                <div class="form-card">
                                    <div class="row wrap">

                                        <div class="new secend-step">
                                            <div class="form-group">
                                                <input
                                                    onChange={(e) => {
                                                        changeHandler(e, setCheckYes, "radio");
                                                    }}
                                                    value={"yes"}
                                                    checked={checkYes === "yes" ? "checked" : ''}
                                                    name="yesNo"
                                                    type="checkbox"
                                                    id="yes"
                                                />
                                                <label htmlFor="yes">Yes, I have cleared my cache on this browser and device</label>
                                                {error && (
                                                    <div className="mt-2">
                                                        <p className="red ml-5">
                                                            *Please Select Checkbox
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">


                                    <Button
                                        status={0}
                                        onClick={onCancelQuestions}
                                        type="button"
                                        className={ `height-56 resend-btn width-175-px previous action-button-previous`}
                                        title="Cancel"
                                    />
                                    <Button
                                        status={0}
                                        onClick={onClickHandler2}
                                        type="button"
                                        className={` height-56 resend-btn width-175-px next action-button"`}
                                        title="Next"
                                    />
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
