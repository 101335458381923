import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import moment, { duration } from "moment";
import BookingPage from "./calendar";
import Topbar from "../../../components/topbar/topbar";
import Footer from "../../client/appointment/footer";
import "./booking.css";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/button";
import { checkIfFreeSessions, checkPracticeAvailability, createMediumBooking, getMatchingMediums, getMediumBookingDetails, getServicesLength, getServicesList, getServicesType } from "../../../services/medium";
import { useNavigate } from "react-router-dom";
import { getTimeImage } from "../../../utils/utils";
import { items, slotsArray } from "../../admin/calendar/data";
import { setLoading } from "../../../reducer/auth/auth";
import FullCalendar from "@fullcalendar/react";
import { saveDirectAdvanceBooking } from "../../../services/client";
import { toast } from "react-toastify";
import BookingConfirm from "./confirm";
import { el, es } from "date-fns/locale";
import { StyledEngineProvider } from "@mui/material";
import VerificationSteps from "./verificationsteps";
import ProfileCard from "./componetnts/profilecard";
import ConfirmDeleteModal from "./componetnts/confirmmodal";
import BookingPageOld from "./oldcomponents/calendar";
import PracticeCheckPopup from "./componetnts/practicecheck";

export default function MediumBooking() {
    const allItems = [
        { name: "Novice", checked: true, value: 1 },
        { name: "Intermediate", checked: true, value: 2 },
        { name: "Professional", checked: true, value: 3 },

    ];

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { timezone, onboarding_completed } = useSelector((state) => state.user.user);
    // const [pageType, setPageType] = useState(onboarding_completed === 1 ? "SELECTION" : "form");
    const [pageType, setPageType] = useState("SELECTION");

    const [clientValue, setClientVale] = useState("");


    const [serviceList, setServiceList] = useState([]);
    const [serviceTypeList, setServiceTypeList] = useState([]);
    const [sessionLengthList, setSessionLengthList] = useState([]);
    const [clientList, setClientList] = useState([]);

    const [service, setService] = useState('');
    const [serviceError, setServiceError] = useState(false);
    const [serviceType, setServiceType] = useState('');
    const [serviceTypeError, setServiceTypeError] = useState(false);
    const [sessionLength, setSessionLength] = useState('');
    const [sessionLengthError, setSessionLengthError] = useState(false);
    const [partnerLevel, setPartnerLevel] = useState([]);

    const [focusState, setFocusState] = useState(false);
    const [timeslots, setTimeslots] = useState([]);
    const [mediumValue, setMediumVale] = useState("");

    const [appDate, setAppDate] = useState('')
    const [appTime, setAppTime] = useState('')
    const [confirm, setConfirm] = useState('');
    const [buttonType, setButtonType] = useState('');
    const [bookingDate, setBookingDate] = useState("");
    const [bookingTime, setBookingTime] = useState("");
    const [slotDetails, setslotDetails] = useState({})
    const [userDetails, setuserDetails] = useState({})


    const [serviceValue, setServiceValue] = useState('');

    const [timeSlotsArray, setTimeSlotsArray] = useState([])
    const [IsNovice, setIsNovice] = useState(false);
    const [IsIntermediate, setIsIntermediate] = useState(false);
    const [IsProfessional, setIsProfessional] = useState(false);
    const [items, setItems] = useState([...allItems.map((x, id) => ({ id, ...x }))]);
    const [userFirstTime, setuserFirstTime] = useState(true);
    const [btnDisabled, setBtnDisabled] = useState(true)
    const [confirmText, setConfirmText] = useState('');
    const [bodyType, setBodyType] = useState();
    const [mediumList, setMediumList] = useState([]);
    const [practiceMediums, setPracticeMediums] = useState([]);
    const [supportMediums, setSupportMediums] = useState([]);
    const [coachingType, setCoachingType] = useState('');
    const [coachingTypeBackup, setcoachingTypeBackup] = useState('');
    const [activeIndex, setActiveIndex] = useState();
    const [oldFlow, setoldFlow] = useState(false);

    const [coachType, setCoachType] = useState('')



    const checkStatus = () => {
        if (service === "") {
            setServiceError(true);
        }
        else {
            setServiceError(false);
        }

        if (serviceType === "") {
            setServiceTypeError(true)
        }
        else {
            setServiceTypeError(false)
        }
        if (sessionLength === "") {
            setSessionLengthError(true)
        }
        else {
            setSessionLengthError(false)
        }
        if (service !== "" && serviceType !== "" && sessionLength !== "") {
            return true
        }
        else {
            return false
        }
    }
    useEffect(() => {
        setoldFlow(false);
        setTimeSlotsArray(slotsArray.slice(0, -2));

        getServicesList()
            .then((data) => {
                if (data.data.status) {
                    let array = []
                    Object.entries(data.data.data).map(([key, value]) => (
                        array.push({ id: parseInt(key), name: value })
                    ))
                    setServiceList(array);
                    // setService(array[0].name);
                    // setServiceValue(array[0].id);

                    // let value = array[0].name.toLowerCase()
                    // let newvalue = value.replace(' ', '')

                    // getServicesType(newvalue).then((data) => {
                    //     if (data.data.status) {
                    //         let array1 = []
                    //         Object.entries(data.data.data).map(([key, value]) => (
                    //             array1.push({ id: parseInt(key), name: value })
                    //         ))
                    //         setServiceTypeList(array1);
                    //         setServiceType(array1[0].id)


                    //         let info = array[0].id
                    //         getServicesLength(info).then((data) => {
                    //             if (data.data.status) {


                    //                 setSessionLengthList(data.data.data);
                    //             }
                    //         }).catch(() => { })
                    //     }
                    // }).catch(() => { })
                }
            })
            .catch(() => { });
    }, []);
    const changeHandler = (item) => {
        setClientVale(item);
    };
    const changeHandlerItem = () => {
        if (checkStatus()) {

            setPageType("CALENDAR");
        }
    };
    const fullCalendarViewHandler = () => {
        // setMediumVale('');
        setPageType("CALENDAR");
    }
    const changeService = (e) => {
        setConfirm('')
        setAppDate('')
        setAppTime('')
        setButtonType('')
        setMediumVale('')
        setClientList([])
        setServiceType('')
        setServiceTypeList([]);
        setSessionLengthList([]);
        setSessionLength('');
        // setItems(items.map((item) => { return { ...item.checked = false, ...item } }))
        let value = e.target.value.toLowerCase()
        let newvalue = value.replace(' ', '')
        let info = serviceList.filter((item) => { return item.name === e.target.value });
        setServiceValue(info[0].id);

        setService(e.target.value)

        setServiceError(false);
        getServicesType(newvalue).then((data) => {
            if (data.data.status) {
                let array = []
                Object.entries(data.data.data).map(([key, value]) => (
                    array.push({ id: parseInt(key), name: value })
                ))
                setServiceTypeList(array);
            }
        }).catch(() => { })

    };
    const changeServiceType = (e) => {
        setConfirm('')
        setAppDate('')
        setButtonType('')
        setAppTime('')
        setMediumVale('')
        setClientList([])
        setSessionLength('');
        setSessionLengthList([]);
        // setItems(items.map((item) => { return { ...item.checked = false, ...item } }))
        let value = e.target.value
        setServiceType(value)
        setServiceTypeError(false)
        let info = serviceValue
        let is_group_session = serviceTypeList.filter((item) => item.id == value)[0].name.includes('Group');

        getServicesLength(info, is_group_session).then((data) => {
            if (data.data.status) {
                // let array = []
                // Object.entries(data.data.data).map(([key, value]) => (
                //     array.push({ id: parseInt(key), name: value })
                // ))

                setSessionLengthList(data.data.data);
            }
        }).catch(() => { })
    }
    const changeSessionLength = (e) => {
        setConfirm('')
        setAppDate('')
        setButtonType('')
        setAppTime('')
        setMediumVale('')
        setClientList([])
        setPartnerLevel([])
        // setItems(items.map((item) => { return { ...item.checked = false, ...item } }))
        let value = e.target.value

        setSessionLength(value);
        setSessionLengthError(false);
    }
    const onInputFocus = () => {
        setFocusState(true)
    }

    const getMatchedMedium = (e) => {
        let newItem = items.filter((item) => { if (item.checked === true) return { ...item.value } })
        let newArray = newItem.map((item) => item.value)

        let partner = newArray;
        if (partner.includes(1)) {
            if (!partner.includes(4)) {
                partner.push(4)
            }
        }
        if (partner.includes(2)) {
            if (!partner.includes(5)) {
                partner.push(5)

            }
        }
        if (partner.includes(3)) {
            if (!partner.includes(6)) {
                partner.push(6)
            }
            if (!partner.includes(7)) {
                partner.push(7)
            }

        }
        let partner_level = partner;
        let value = e;
        if (value.length > 3) {


            getMatchingMediums(value, partner_level).then((data) => {
                let info = data.data.data.map((item) => {
                    return {
                        ...item,
                        value: item.id,
                        label:
                            item.first_name + " " + item.last_name + " " + `(${item.email})`,
                    };
                })

                setClientList(info)
            }).catch(() => {

            })
            // console.log(selectRef)
            // setTimeout(() => {
            //     selectRef.current.focus();

            // }, 300)
            // console.log( selectRef.current, "getMatchedMedium");
        }


    }
    const changeHandlerItem1 = (item) => {
        checkStatus();
        setMediumVale(item);
        if ((item !== "" || item !== null) && appDate === "" && appTime === "") {
            setButtonType('showcalendar');
        }
        else {
            setButtonType('search');
        }
    }
    const onInputChangeHandler = (e) => {

        let name = e.target.name;
        let value = e.target.value;
        let today = moment(value).isSame(moment(), 'day')
        if (today) {
            let today = moment().add(10, 'minutes');
            let items = timeSlotsArray.filter((item) => {
                // console.log(item.time, moment().add(15, 'minutes').format("HH:mm A"))
                if (today.isBefore(moment(item.time, 'HH:mm A'))
                ) {
                    return item;
                }
            })
            setTimeSlotsArray(items)
        }
        else {
            setTimeSlotsArray(slotsArray.slice(0, -2))
        }
        if (name === "appDate") {
            setAppDate(value)
        }
        else {
            setAppTime(value);
        }


    }
    useEffect(() => {
        if ((appDate !== "" && appTime !== "") || (appDate !== "" && appTime !== "" && mediumValue !== "")) {
            setButtonType('search');
        }
    }, [appDate, appTime])
    const checkAvailAbility = () => {
        if (checkStatus()) {
            dispatch(setLoading(true));
            let body;
            if (mediumValue === "" || mediumValue === null) {
                let newItem = items.filter((item) => { if (item.checked === true) return { ...item.value } })
                let newArray = newItem.map((item) => item.value)

                let partner = newArray;
                if (partner.includes(1)) {
                    if (!partner.includes(4)) {
                        partner.push(4)
                    }
                }
                if (partner.includes(2)) {
                    if (!partner.includes(5)) {
                        partner.push(5)

                    }
                }
                if (partner.includes(3)) {
                    if (!partner.includes(6)) {
                        partner.push(6)
                    }
                    if (!partner.includes(7)) {
                        partner.push(7)
                    }

                }
                body = {
                    date: appDate,
                    time: appTime,
                    service: serviceValue,
                    session_length: sessionLength,
                    partner_level: partner
                }

            }
            else {

                body = {
                    date: appDate,
                    time: appTime,
                    service: serviceValue,
                    session_length: sessionLength,
                    medium_id: mediumValue !== null && mediumValue !== undefined ? mediumValue.id : "",

                }
            }
            checkPracticeAvailability(body).then((data) => {
                if (data.data.status) {
                    setConfirm(data.data.data);
                    if (!data.data.data) {
                        setButtonType('fullcalendar');
                    }
                    else {
                        setButtonType('bookappointment');
                    }
                }
                dispatch(setLoading(false));
            }).catch(() => { dispatch(setLoading(false)); })
        }
    }

    const checkCoachingBookingType = () => {
        let body = {
            date: bookingDate,
            time: bookingTime,
            service: serviceValue,
            session_length: sessionLength,

        }

        getMediumBookingDetails(body).then((data) => {
            console.log(data, "dataaaa");
            if (data.data.status);
            console.log(data.data.data, "data.data.data");
            setMediumVale(data.data.data);
            // setActiveIndex(0);
            window.loadModal2("#payoptionspopupmediumbooking", "show");
            window.removeBackdrop();
        })
    }
    const doABooking = (newbody, is_confirmed) => {
        console.log(JSON.parse(localStorage.getItem('grouptimeslot')));
        setBodyType(newbody)

        if (bookingDate === "" || bookingTime === "") {
            toast.error("Please select both date and time slot", {
                position: "top-center",
            });
            return;

        }

        if (serviceValue === 2 && coachingTypeBackup === 'paid' && coachingType === "free" && newbody.booking_type === "free") {
            // setCoachingType('paid');
            checkCoachingBookingType();
            return;

        }
        dispatch(setLoading(true))
        // setTimeout(() => {
        //     dispatch(setLoading(false))
        //     setStateType("form");
        // }, 1000)
        let newItem = items.filter((item) => { if (item.checked === true) return { ...item.value } })
        let newArray = newItem.map((item) => item.value)

        let partner = newArray;
        if (partner.includes(1)) {
            if (!partner.includes(4)) {
                partner.push(4)
            }
        }
        if (partner.includes(2)) {
            if (!partner.includes(5)) {
                partner.push(5)

            }
        }
        if (partner.includes(3)) {
            if (!partner.includes(6)) {
                partner.push(6)
            }
            if (!partner.includes(7)) {
                partner.push(7)
            }

        }
        let body = {
            date: bookingDate,
            time: bookingTime,
            service: serviceValue,
            session_length: sessionLength,
            medium_id: mediumValue !== null && mediumValue !== undefined ? mediumValue.id : "",
            partner_level: partner,
            is_confirmed: is_confirmed ? is_confirmed : false,

        }
        if (serviceValue === 2) {
            Object.assign(body, newbody);
        }
        // if (serviceValue === 2 && mediumValue === "" && bookingTime !== "") {

        //     if (mediumList.some((e) => e.coaching_pricing !== null)) {
        //         let list = mediumList.filter((item) => item.coaching_pricing !== null)
        //         body.medium_id = list[0].id
        //         body.booking_type = 'paid'
        //     }
        //     else {
        //         body.medium_id = mediumList.length && mediumList[0].id
        //         body.booking_type = 'free'

        //     }

        //     // if (mediumValue === "") {
        //     //     toast.error("Please select medium", {
        //     //         position: "top-center",
        //     //     });
        //     //     return;
        //     // }
        // }
        if (serviceValue === 1) {
            body.mediums = practiceMediums
        }
        if (serviceValue === 11) {
            body.mediums = supportMediums
        }
        let type = parseInt(serviceType) == 1 && serviceValue === 2;
        if (type) {
            let grouptimeslot = JSON.parse(localStorage.getItem('grouptimeslot'));
            Object.assign(body, { duration: grouptimeslot[0].datetime.duration.replace(' Minutes', '') })
            Object.assign(body, { time_slot_id: grouptimeslot[0].datetime.time_slot_id })
            body.time = grouptimeslot[0].datetime.from


        }

        createMediumBooking(body, type).then((data) => {
            dispatch(setLoading(false))
            window.loadModal2("#Confirmblockslot", "hide");
            console.log(data.data.status, "data.data", newbody)
            if (data.data.status) {
                if (serviceValue === 2 && (newbody !== undefined && newbody.booking_type === 'paid')) {
                    window.open(data.data.data.approvedUrl, "_self");
                    localStorage.setItem('bookingType', 'coaching');

                }
                else {

                    console.log(data.data)


                    toast.success(data.data.message, {
                        position: "top-center",
                    });
                    setslotDetails(data.data.data)
                    setuserDetails(body)
                    setPageType('CONFIRM');
                }
            }
            else if (!data.data.status && data.data.data.block) {
                setConfirmText(data.data.message);
                window.loadModal2("#Confirmblockslot", "show");
            }
            else {
                toast.error(data.data.message, {
                    position: "top-center",
                });
            }
        }).catch(() => { dispatch(setLoading(false)) })
    }

    const directBookingHandler = () => {
        let newItem = items.filter((item) => { if (item.checked === true) return { ...item.value } })
        let newArray = newItem.map((item) => item.value)

        let partner = newArray;
        if (partner.includes(1)) {
            if (!partner.includes(4)) {
                partner.push(4)
            }
        }
        if (partner.includes(2)) {
            if (!partner.includes(5)) {
                partner.push(5)

            }
        }
        if (partner.includes(3)) {
            if (!partner.includes(6)) {
                partner.push(6)
            }
            if (!partner.includes(7)) {
                partner.push(7)
            }

        }
        let body = {
            date: appDate,
            time: appTime,
            service: serviceValue,
            session_length: sessionLength,
            medium_id: mediumValue !== null && mediumValue !== undefined ? mediumValue.id : "",
            partner_level: partner,
            is_confirmed: false,
        }
        createMediumBooking(body).then((data) => {
            dispatch(setLoading(false))
            if (data.data.status) {
                toast.success(data.data.message, {
                    position: "top-center",
                });

                setslotDetails(data.data.data)
                setuserDetails(body)
                setPageType('CONFIRM');
            }
            else {
                toast.error(data.data.message, {
                    position: "top-center",
                });
            }
        }).catch(() => { dispatch(setLoading(false)) })
    }

    const handlePartnerLevel = (e) => {
        let checked = e.target.checked
        let value = e.target.value
        console.log(items, "items")
        let newItems = items.map((item) => {
            if (item.name === e.target.id) {
                return {
                    ...item.checked = checked,
                    ...item
                }
            }
            else {
                return {
                    ...item
                }
            }
        })
        console.log(newItems, "newItems")
        setItems(newItems)

        // console.log(checked, value, "value", "checked");
        // let partner = partnerLevel;
        // if (checked) {
        //     partner.push(parseInt(value))
        // }
        // else {
        //     if (parseInt(value) === 1) {
        //         const index = partner.indexOf(parseInt(4));
        //         partner.splice(index, 1);
        //     }
        //     if (parseInt(value) === 2) {
        //         const index = partner.indexOf(parseInt(5));
        //         partner.splice(index, 1);
        //     }
        //     if (parseInt(value) === 3) {
        //         const index = partner.indexOf(parseInt(6));
        //         partner.splice(index, 1);
        //         const index1 = partner.indexOf(parseInt(7));
        //         partner.splice(index1, 1);

        //     }

        //     const index = partner.indexOf(parseInt(value));
        //     if (index > -1) {
        //         partner.splice(index, 1);
        //     }

        // }
        // setPartnerLevel(partner);


    }
    useEffect(() => {
        if (service !== "" && serviceType !== "" && sessionLength !== ""
            // && appDate !== "" && appTime !== ""
        ) {
            setBtnDisabled(false);

        }
        else {
            setBtnDisabled(true);
        }
    }, [service, serviceType, sessionLength, appDate, appTime])

    useEffect(() => {
        checkCoachingSessionType();
    }, [])
    const checkCoachingSessionType = () => {

        checkIfFreeSessions().then((data) => {
            if (data.data.status) {

                setcoachingTypeBackup(data.data.data.is_free ? 'free' : 'paid')
            }
        }).catch(() => {

        })
    }
    const coachingHandler = (type) => {
        setCoachingType(type);


        if (type === "free" && coachingTypeBackup === "free") {
            fullCalendarViewHandler()
        }
        else if (type === "free" && coachingTypeBackup === "paid") {
            toast.error("You are not eligible for free booking please go to for paid booking!", {
                position: "top-center",
            });
        }
        else {
            fullCalendarViewHandler()
        }
    }
    const checkPracticeAvail = () => {
        window.loadModal2("#PracticeCheckPopup", "show");
        window.removeBackdrop();
    }
    const checkSupportAvail = () => {
        fullCalendarViewHandler()
    }

    const cancelPopup = () => {
        window.loadModal2("#PracticeCheckPopup", "hide");
        window.removeBackdrop();
    }
    const continueHandler = () => {
        if (serviceValue === 2 && parseInt(serviceType) === 0) {
            cancelPopup()
            coachingHandler(coachType)
            return;
        }
        if (serviceValue === 2 && parseInt(serviceType) === 1) {
            cancelPopup()
            fullCalendarViewHandler()
            return;
        }
        if (serviceValue === 1) {
            cancelPopup();
            fullCalendarViewHandler()
            return;
        }
        console.log(serviceValue, serviceType)
        return;

    }



    const checkCoachingAvail = (type) => {
        setCoachType(type)
        window.loadModal2("#PracticeCheckPopup", "show");
        window.removeBackdrop();
    }



    return (
        <>
            <Topbar />
            {pageType === "form" &&

                <div className="practice-booking-step-container">

                    <VerificationSteps setuserFirstTime={setPageType} /></div>}
            {pageType !== "form" && <div className="medium-booking-container">
                <h4 className="mt-5 text-center">
                    Timezone: {timezone !== undefined && timezone}&nbsp;
                </h4>

                {pageType === "SELECTION" && (
                    <>
                        <span
                            className="back-handler pointer medium-booking-container-back-btn"
                            onClick={() => {
                                navigate('/appointments');
                            }}
                        >
                            <img src="../images/arrow-back.png" alt="go back" />&nbsp;
                            Go Back
                        </span>
                        <div className="from-fild mt-5 mb-4 text-center ">
                            <h4>Please choose service for booking </h4>
                            <a target="_blank" className="font-size-14 ml-3 mr-3 pointer learn-more-btn" href="https://vimeo.com/922176805/a4d6d52f6e">Learn more</a>
                            <div className="d-flex align-items-center justify-content-center mt-5">
                                <div class="item">
                                    <div className="search-box search-booking-page search-container">
                                        <div className="select-box-booking ">

                                            <div className="d-flex align-items-center w-100">
                                                <span className="step-no-booking">1.</span>
                                                <select
                                                    value={service}
                                                    onChange={(e) => changeService(e)}
                                                    name="selectservice"
                                                    placeholder="Select Service"
                                                    className={`select-box-1-practive-book ${serviceError ? 'red error-boundry' : ''}`}
                                                >
                                                    <option disabled selected value={""}>
                                                        Select Service
                                                    </option>
                                                    {serviceList.map((item) => <option disabled={item.name.includes('(TBD)')} value={item.name}>{item.name == "Support" ? "Support Team" : item.name}</option>)}
                                                </select>
                                            </div>
                                            <div className="d-flex align-items-center w-100">

                                                <span className="step-no-booking">2.</span>
                                                <select
                                                    value={serviceType}
                                                    onChange={(e) => changeServiceType(e)}
                                                    className={serviceTypeError ? 'red error-boundry' : ''}

                                                    name="serviceType" placeholder="Select Service Type">
                                                    <option disabled selected value={""}>
                                                        Select Service Type
                                                    </option>
                                                    {serviceTypeList.map((item) => <option disabled={item.name.includes('(TBD)')} value={item.id}>{item.name}</option>)}
                                                </select>
                                            </div>
                                            <div className="d-flex align-items-center w-100">
                                                <span className="step-no-booking">3.</span>
                                                <select value={sessionLength} className={sessionLengthError ? 'red error-boundry' : ''} onChange={(e) => changeSessionLength(e)} name="sessionLength" placeholder="Select Session Length/Type">
                                                    <option disabled selected value={""}>
                                                        Select Session Length/Type
                                                    </option>
                                                    {sessionLengthList.map((item) => <option disabled={item.sub_session_name.includes('(TBD)')} value={item.id}>{item.sub_session_name}</option>)}
                                                </select>
                                            </div>
                                        </div>



                                    </div>
                                    <div className="search-booking-page">

                                        {service === "Practices" && <div className="">
                                            <div className="full-side text-left clear-both">
                                                <label className="mt-2 font-size-17 ml-5">
                                                    Select Partner Level:  &nbsp; <span className="font-size-11">*feature coming soon </span>
                                                </label>
                                                <div className="check-field">
                                                    {/* <div className="check-field mt-3">
                                                        <input
                                                            
                                                            id="starter"
                                                            multiple
                                                          
                                                            type="checkbox"
                                                            name="approvedSessions"
                                                        />
                                              
                                                        <label htmlFor="">Beginner</label>
                                                    </div> */}
                                                    {items.map((item) =>
                                                        <div className="check-field mt-3 ml-5 select_partner_level ">
                                                            <input
                                                                onChange={handlePartnerLevel}
                                                                value={item.value}
                                                                id={item.name}
                                                                disabled={true}
                                                                // multiple
                                                                // checked={partnerLevel.includes(item.value) ? true : false}
                                                                checked={true}
                                                                // selected={item.status}
                                                                // checked={item.checked}
                                                                // checked={IsNovice}
                                                                type="checkbox"
                                                                name="partner_level"
                                                            />
                                                            {/* {console.log(item.status,"approvedPrefrences")} */}
                                                            <label htmlFor="novice">{item.name}</label>
                                                        </div>)}
                                                    {/* <div className="check-field mt-3">
                                                        <input
                                                            onChange={handlePartnerLevel}
                                                            value={2}
                                                            id="Intermediate"
                                                          
                                                            type="checkbox"
                                                            name="partner_level"
                                                        />
                                                      
                                                        <label htmlFor="Intermediate">Intermediate</label>
                                                    </div>
                                                    <div className="check-field mt-3">
                                                        <input
                                                            onChange={handlePartnerLevel}
                                                            value={3}
                                                            id="Professional"
                                                           
                                                       
                                                            type="checkbox"
                                                            name="partner_level"
                                                        />
                                                      
                                                        <label htmlFor="Professional">Professional</label>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>}
                                        {/* <div className=" mt-2">
                                            <div className="d-flex align-items-center w-100">
                                                <span className="step-no-booking">4.</span>
                                                <div className="full-side text-left">
                                                    <label className="mt-4 mb-4 font-size-17 ml-4">
                                                      
                                                        Choose a date and time for the system to create a session for you:
                                                    </label>
                                                   
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>



                                    {/* <div>


                                        <div className="search-box search-booking-page  input-booking-page ">
                                            <div className="d-flex align-items-center justify-content-center ml-4">
                                                <input min={moment().format('YYYY-MM-DD')} onChange={onInputChangeHandler} onFocus={() => onInputFocus()} type={focusState ? "date" : ""} placeholder="Select date" className="search-input p-2" value={appDate} name="appDate" />



                                                <select
                                                   
                                                    onChange={onInputChangeHandler}

                                                    name="appTime"
                                                    className="p-2 search-input"
                                                    value={appTime}

                                                >
                                                    {<option value={""} selected>
                                                        {'Time'}

                                                    </option>}
                                                    {timeSlotsArray.map((time) => (
                                                        <option
                                                            className="moon-create-app star-imh-moon"
                                                            value={time.time}
                                                        >
                                                            {time.change == "NO" ? "12:00 AM" : time.time}{" "}
                                                            {getTimeImage(time.time) ? (
                                                                <span>🌙</span>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-center ">
                                <div className="search-booking-page">
                                    {serviceValue === 2 && <p className="d-flex">
                                        <span className="text-underline d-block w-100px">Note</span> :
                                        <span className="d-block text-left ml-1"> Free 1-on-1 coaching sessions are limited to 1 every 6 months. If you wish to work with the same coach again, you will pay them directly using the button on the right.
                                        <br />
                                        <br />
                                        All group coaching sessions are currently free and unlimited.
                                        </span>

                                    </p>
                                    }
                                </div>
                            </div>

                            {/* <div className="d-flex align-items-center justify-content-center mt-4 text-section-medium-booking">
                                {confirm === true && <p className="guest-booking-error-border">Yes,the selected date and time is available.<br />Click "Book Now" to create booking</p>}
                                {confirm === false && <p className="guest-booking-error-border">Sorry,the date and time selected is not available.<br />Please check our Full Calendar to see dates and<br />times that are available.</p>}
                            </div> */}
                            {/* <div>
                                Note: Partners will be chosen by the system.
                            </div> */}
                            <div className="d-flex align-items-center justify-content-center mt-5">

                                {/* {buttonType === "" && <Button

                                    className={`resend-btn w-unset height-45 min-width-130px 1 ${btnDisabled ? 'btn-disabled-practice' : ''}`}
                                    status={0}
                                    onClick={btnDisabled ? () => { } : () => checkStatus()}
                                    title="Search"
                                />}
                                {buttonType === "search" && <Button
                                    className={`resend-btn w-unset height-45 min-width-130px 2 ${btnDisabled ? 'btn-disabled-practice' : ''}`}
                                    status={0}
                                    onClick={btnDisabled ? () => { } : checkAvailAbility}
                                    title="Search"
                                />}
                                {buttonType === "showcalendar" && <Button
                                    className={`resend-btn w-unset height-45 min-width-130px 3 ${btnDisabled ? 'btn-disabled-practice' : ''}`}
                                    status={0}
                                    onClick={btnDisabled ? () => { } : changeHandlerItem}
                                    title="Next"
                                />}
                                {buttonType === "fullcalendar" && <Button
                                    className={`resend-btn w-unset height-45 min-width-130px 4 ${btnDisabled ? 'btn-disabled-practice' : ''}`}
                                    status={0}
                                    onClick={btnDisabled ? () => { } : fullCalendarViewHandler}
                                    title="Go To Full Calendar View"
                                />}
                                {buttonType === "bookappointment" && <Button
                                    className={`resend-btn w-unset height-45 min-width-130px 5 ${btnDisabled ? 'btn-disabled-practice' : ''}`}
                                    status={0}
                                    onClick={btnDisabled ? () => { } : directBookingHandler}
                                    title="Book Now"
                                />} */}
                                <div className="medium-coaching-btns">
                                    {serviceValue === 2 && parseInt(serviceType) == 0 && <Button
                                        className={`resend-btn w-unset height-45 min-width-130px ${btnDisabled ? 'btn-disabled-practice' : ''}`}
                                        status={0}
                                        onClick={btnDisabled ? () => { } : () => checkCoachingAvail('free')}
                                        title="Pick a Coach for me (Free Sessions Only)"
                                    />}
                                    <br />
                                    {serviceValue === 2 && parseInt(serviceType) == 0 && <Button
                                        className={`resend-btn w-unset height-45 min-width-130px ${btnDisabled ? 'btn-disabled-practice' : ''}`}
                                        status={0}
                                        onClick={btnDisabled ? () => { } : () => checkCoachingAvail('paid')}
                                        title="I will Choose my  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp;    Coach &nbsp; &nbsp;  &nbsp; &nbsp;   &nbsp; &nbsp; 
                                        &nbsp; &nbsp; 
                                        &nbsp; &nbsp; (First Session Free)"
                                    />}
                                </div>

                                {serviceValue === 1 && <Button
                                    className={`resend-btn w-unset height-45 min-width-130px 4 ${btnDisabled ? 'btn-disabled-practice' : ''}`}
                                    status={0}
                                    onClick={btnDisabled ? () => { } : checkPracticeAvail}
                                    title="Go To Full Calendar View"
                                />}
                                {serviceValue === 2 && parseInt(serviceType) == 1 && <Button
                                    className={`resend-btn w-unset height-45 min-width-130px 4 ${btnDisabled ? 'btn-disabled-practice' : ''}`}
                                    status={0}
                                    onClick={btnDisabled ? () => { } : checkCoachingAvail}
                                    title="Go To Full Calendar View"
                                />
                                }

                                {serviceValue === 11 && <Button
                                    className={`resend-btn w-unset height-45 min-width-130px 4 ${btnDisabled ? 'btn-disabled-practice' : ''}`}
                                    status={0}
                                    onClick={btnDisabled ? () => { } : checkSupportAvail}
                                    title="Go To Full Calendar View"
                                />}
                            </div>

                        </div>
                    </>
                )}

                {pageType === "CALENDAR" && oldFlow &&

                    <>
                        <BookingPageOld
                            mediumList={mediumList}
                            setMediumList={setMediumList}
                            setMediumVale={setMediumVale}
                            serviceValue={serviceValue}
                            sessionLength={sessionLength}
                            setSessionLength={setSessionLength}
                            sessionLengthList={sessionLengthList}
                            openForm={doABooking}
                            backHandler={setPageType}
                            mediumValue={mediumValue}
                            bookingDate={bookingDate}
                            setBookingDate={setBookingDate}
                            setBookingTime={setBookingTime}
                            bookingTime={bookingTime}
                            setPracticeMediums={setPracticeMediums}
                            serviceType={serviceType}
                            setSupportMediums={setSupportMediums}
                        // coachingType={coachingType}
                        // setCoachingType={setCoachingType}
                        // activeIndex={activeIndex}
                        // setActiveIndex={setActiveIndex}
                        // coachingTypeBackup={coachingTypeBackup}
                        // setoldFlow={setoldFlow}
                        />

                    </>}
                {pageType === "CALENDAR" && !oldFlow && <BookingPage
                    mediumList={mediumList}
                    setMediumList={setMediumList}
                    setMediumVale={setMediumVale}
                    serviceValue={serviceValue}
                    sessionLength={sessionLength}
                    setSessionLength={setSessionLength}
                    sessionLengthList={sessionLengthList}
                    openForm={doABooking}
                    backHandler={setPageType}
                    mediumValue={mediumValue}
                    bookingDate={bookingDate}
                    setBookingDate={setBookingDate}
                    setBookingTime={setBookingTime}
                    bookingTime={bookingTime}
                    setPracticeMediums={setPracticeMediums}
                    setSupportMediums={setSupportMediums}
                    coachingType={coachingType}
                    setCoachingType={setCoachingType}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    coachingTypeBackup={coachingTypeBackup}
                    setoldFlow={setoldFlow}
                    serviceType={serviceType}


                />}


                {pageType === "CONFIRM" && <BookingConfirm slotDetails={slotDetails} userDetails={userDetails} />}
            </div>}
            <div className="text-center pb-5 mt-5 pt-5 padding-botton-120px">
                <Footer />
            </div>
            <ConfirmDeleteModal
                deleteUser={() => doABooking(bodyType, true)}

                id={"Confirmblockslot"}
                label={confirmText}
                btnLabel={"Continue"} />
            <PracticeCheckPopup
                continueHandler={continueHandler}
                cancel={cancelPopup} />
        </>
    );
}