export const list = [
  { id: 1, name: "Upcoming", count: 0 },
  { id: 2, name: "Completed", count: 0 },
  { id: 3, name: "Cancelled", count: 0 },
  { id: 3, name: "No Shows", count: 0 },
];

const UpcomingHeaders = [
  { id: 9, title: "Session #" },
  { id: 1, title: "Meeting Link" },
  { id: 8, title: "Client Confirm"},
  { id: 2, title: "Attendee's Name" },
  { id: 3, title: "Date" },
  { id: 4, title: "Time" },
  { id: 5, title: "Session Type" },
  { id: 6, title: "Session Issue" },
  { id: 7, title: "Actions" },
];

const CompletedHeaders = [
  { id: 8, title: "Session #" },
  { id: 1, title: "Attendee's Name" },
  { id: 2, title: "Date" },
  { id: 5, title: "Time" },
  { id: 3, title: "Session Type" },
  { id: 4, title: "Feedback" },
  { id: 8, title: "Payment Status" },
  { id: 9, title: "Payment Request"},
  { id: 6, title: "Session Issue" },
  { id: 10,title: "No Repeat"},
  { id: 7, title: "Actions" },
];

const CancelledHeaders = [
  { id: 7, title: "Session #" },
  { id: 1, title: "Attendee's Name" },
  { id: 2, title: "Session Date" },
  { id: 3, title: "Time" },
  { id: 4, title: "Session Type" },
  { id: 5, title: "Date and Time Cancelled" },
  { id: 8,title: "No Repeat"},
  { id: 6, title: "Cancelled By" },
];

const NoShowsHeaders = [
  { id: 7, title: "Session #" },
  { id: 1, title: "Attendee's Name" },
  { id: 2, title: "Session Date" },
  { id: 3, title: "Time" },
  { id: 4, title: "Session Type" },
  { id: 5, title: "Date and Time Cancelled" },
  { id: 8,title: "No Repeat"},
  // { id: 9,title: "Status"},
  { id: 6, title: "No Show By" },
];

const filterList = [
  {
    id: 1,
    name: "Attendee Name",
    value: "screen_name",
  },
  {
    id: 2,
    name: "Session Type",
    value: "session_type",
  },
  {
    id: 3,
    name: "Appointment Id",
    value: "meeting_id",
  },
];
export {
  UpcomingHeaders,
  CompletedHeaders,
  CancelledHeaders,
  NoShowsHeaders,
  filterList,
};
