import React from "react";
import './transactiontable.css';
import Button from "../button";
export default function TransactionTable({ listHeading, transactionsList, type, markComplete }) {
  return (
    <div className="table-mideum-table tableclander">
      <table>
        <tbody>
          <tr>
            {listHeading.map((item) => (
              <th>{item}</th>
            ))}
          </tr>


          {transactionsList.length > 0 && transactionsList.map((item) => <tr>
            {type === "admin" ? <> <td className="padd-15">{item.appointment_id}</td>
              <td className="padd-15">{item.medium_name}</td>
              <td className="padd-15">{item.client_name} {item.client_comment === true && (<b  style={{
                              fontSize: '22px',
                            }}>*</b>) }</td>
              <td className="padd-15">{item.date}</td>
              <td className="padd-15">{item.session_date_time}</td>
              <td className="padd-15">{item.method}</td>
              <td className="padd-15 color-d5a67b ">{item.payment_status === 1 ? 'Completed' : 'Pending'}</td>
              <td className="padd-15">{item.session_name}</td>
              <td className="padd-15">{item.amount}</td>
              <td className="padd-15 uppercase">{item.currency}</td>
              <td className="padd-15">
                {item.payment_status === 1 ? <span className="color-d5a67b ">Completed</span> : <Button
                  // status={client.resent_status}
                  status={0}
                  onClick={(e) => markComplete(item)}
                  type="button"
                  className="resend-btn min-width-210"
                  title="Mark Completed"
                />}
              </td>

            </> :
              <>
                <td className="padd-15">{item.appointment_id}</td>
                <td className="padd-15">{item.client_name}</td>
                <td className="padd-15">{item.date}</td>
                <td className="padd-15">{item.session_date_time}</td>
                <td className="padd-15">{item.method}</td>
                <td className="padd-15">{item.amount}</td>
                <td className="padd-15 uppercase">{item.currency}</td>
              </>
            }
          </tr>)}


          {/* ))} */}
        </tbody>
      </table>
    </div>
  );
}
