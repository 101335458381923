import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function OpenUrlPopUp({ url }) {
  const auth = useSelector((state) => state.auth);
  return (
    <div
      className="modal fade"
      id="OpenUrlPopUp"
      role="dialog"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-preview">
        {/* <button type="button" className="close" data-dismiss="modal">
        &times;
      </button> */}
        <div className="modal-content">
          <div className="modal-body">
            <div className="profile-video">
              {/* <img src="images/img-video.png" alt="img-video.png" /> */}
            </div>
            <h3>
              {/* <strong>{userDetails}</strong> */}
              Click Here to Join Meeting
            </h3>
            <div className="bution-close-accept">
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="join-btn"
              >
                <img src="images/video-call.png" alt="video-call.png" />
                Join
              </a>
            </div>
            {auth.role === "client" && (
              <p className="mt-2">
                Having Trouble <Link to="/appointments">Click Here</Link>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
