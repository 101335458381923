import React, { useState } from 'react'
import './index.css';
import Button from '../../../../../components/button';
import { useDispatch } from 'react-redux';
import { setPathArray } from '../../../../../reducer/adminSlice';

export default function ProfileCard({ onClick, item, activeIndex, index, setActiveIndex, serviceValue }) {
    const dispatch = useDispatch();
    let pricing = item.coaching_pricing !== null && item.coaching_pricing !== "null" ? Object.values(JSON.parse(JSON.parse(item.coaching_pricing)))[0] : 'Free'

    const onClickHandler = (index, item) => {
        setActiveIndex(parseInt(index))
        onClick(item)

    }
    const viewProfileHandler = (e, item) => {
        e.stopPropagation();
        localStorage.setItem('PATH', item.id)

    }
    return (
        <>
            <div onClick={() => onClickHandler(index, item)} className={`profilecard-wrapper pointer ${activeIndex === parseInt(index) ? 'profilecard-selected' : ''}`} >

                <div className='profile-photo'>
                    <div >
                        <img width="100%" height="100%" src={item.profile_image !== null
                            ? `${process.env.REACT_APP_IMAGE_URL}${item.profile_image}`
                            : "/images/user-dummy.png"}
                        />
                    </div>
                </div>
                <div>


                    <div className='font-size-15 '>
                        {item.screen_name.length > 24 ? item.screen_name.substring(0, 24).concat("...") : item.screen_name}
                        <br />

                    </div>
                    <div className='mt-1'>
                        <span className='d-inline-block font-size-15 profile-details-medium'>{pricing === "Free" ? pricing : `Price : $ ${pricing}`}</span>
                        <a
                            title={'View Profile'}

                            className={'resend-btn view-profile-btn d-inline-flex align-items-center justify-content-center'}
                            target='_blank'
                            onClick={(e) => viewProfileHandler(e, item)}
                            href={`/public-view/${item.id}?from=booking&serviceValue=${serviceValue}`}

                        >View Profile</a>
                    </div>
                </div>


            </div>
        </>
    )
}
