import React, { useEffect, useState } from "react";
import { Formik, FieldArray } from "formik";
import Select from "react-select";
import "./addnewsessions.css";
import { initialValues } from "../data";
import {
  addMainSessions,
  getSubsessionInfo,
  makeHours,
  setFormValues,
} from "../functions";
import { useDispatch, useSelector } from "react-redux";

export default function AddNewSessions({
  openSessionList,
  session_id = "",
  formType,
  sessionTypesList,
  setRedirectType,
}) {
  const { sessionFormInfo } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialValues);
  const [isValueLoded, setisValueLoded] = useState(false);

  useEffect(() => {
    if (formType === "Edit" && session_id !== "") {
      getSubsessionInfo(session_id, dispatch);
    } else {
      setisValueLoded(true);
    }
  }, []);
  useEffect(() => {
    if (Object.keys(sessionFormInfo).length > 0) {
      setFormValues(sessionFormInfo, initialValues, setValues);
      setisValueLoded(true);
      // setValues(initialValues)
    }
  }, [sessionFormInfo]);
  return !isValueLoded ? (
    <div className="d-flex align-items-center justify-content-center h-100">
      Loading...
    </div>
  ) : (
    <>
      <div className="add-new-sessions-groups">
        <div className="hading-sessions">
          <span>{formType} Sessions / Groups</span>
        </div>
        <div className="add-new-sessions-from">
          <Formik
            enableReinitialize={true}
            initialValues={values}
            validate={(values) => {
              const errors = {};

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              // addSeesion(values);
              let body = {
                ...values,
                session_id: session_id,
                day: values.day.map((val) => {
                  return val.value;
                }),
                participant_level: values.participant_level.length && values.participant_level.includes('1') ? 1 :"",
                student_level: values.student_level.length && values.student_level.includes('1') ? 1 :"",

                // min_booking_time: `${values.min_booking_hour} : ${values.min_booking_time}`,
                // max_booking_time: `${values.max_booking_hour} : ${values.max_booking_time}`,
                // pricing: values.pricing,

                // values.pricing === "free"
                //   ? 0
                //   : values.pricing === "pricevariable"
                //   ? values.maxprice
                //   : values.fixedprice,
              };
              addMainSessions(body, formType, dispatch,setRedirectType);
              // setOpen(true);
              // setTimeout(() => {
              //   alert(JSON.stringify(values, null, 2));
              //   setSubmitting(false);
              // }, 400);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="sessions-from-left">
                  <label>Session Name</label>
                  <input
                    onChange={handleChange}
                    // defaultValue={selectValue}
                    value={values.sub_session_name}
                    id="sub_session_name"
                    name="sub_session_name"
                    type="text"
                    placeholder=""
                  />
                </div>
                <div className="sessions-from-right">
                  <label>Session Type</label>
                  <div class="select-dropdown">
                    <select
                      onChange={handleChange}
                      value={values.session_type}
                      id="session_type"
                      name="session_type"
                    >
                      <option selected disabled value="">
                        Session Type
                      </option>
                  
                      {sessionTypesList.length &&
                        sessionTypesList.map((item, index) => (
                          <option value={item.session_id}>{item.session_name}</option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="sessions-from-left">
                  <label>Duration</label>
                  <div className="left-select-b">
                    <div class="select-dropdown">
                      <select
                        onChange={handleChange}
                        // defaultValue={selectValue}
                        value={values.duration}
                        id="duration"
                        name="duration"
                      >
                        <option selected disabled value="">
                          Duration
                        </option>
                        <option value={15}>15</option>
                        <option value={30}>30</option>
                        <option value={45}>45</option>
                        <option value={60}>60</option>
                      </select>
                    </div>
                  </div>
                  <div className="right-select-b">
                    <div class="select-dropdown">
                      <select>
                        <option disabled selected value="">
                          Minute
                        </option>
                        {/* <option value="Option 2">10 Minute</option>
                        <option value="Option 2">20 Minute</option>
                        <option value="Option 2">30 Minute</option> */}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="sessions-from-right">
                  <label className="mb-2">Day</label>
                  <Select
                    id="day"
                    className="assign-level-select"
                    options={[
                      {
                        value: "monday",
                        label: "Monday",
                      },
                      {
                        value: "tuesday",
                        label: "Tuesday",
                      },
                      {
                        value: "wednesday",
                        label: "Wednesday",
                      },
                      {
                        value: "thursday",
                        label: "Thursday",
                      },
                      {
                        value: "friday",
                        label: "Friday",
                      },
                      {
                        value: "saturday",
                        label: "Saturday",
                      },
                      {
                        value: "sunday",
                        label: "Sunday",
                      },
                    ]}
                    isMulti
                    onChange={(value) => setFieldValue("day", value)}
                    // onBlur={handleBlur}
                    placeholder=""
                    value={values.day}
                    closeMenuOnSelect={false}
                    closeMenuOnScroll={false}
                  />
                  {/* <div class="select-dropdown">
                    <select
                      onChange={handleChange}
                      // defaultValue={selectValue}
                      value={values.day}
                      id="day"
                      name="day"
                    >
                      <option selected disabled value="">
                        Day
                      </option>

                      <option value={1}>Monday</option>
                      <option value={2}>Tuesday</option>
                      <option value={3}>Wednesday</option>
                      <option value={4}>Thursday</option>
                      <option value={5}>Friday</option>
                      <option value={6}>Saturday</option>
                      <option value={7}>Sunday</option>
                    </select>
                  </div> */}
                </div>
                {parseInt(values.session_type_radio.session_type) === 2 && (
                  <div className="group-limit-box--row">
                    {" "}
                    <label>Group Limit</label>
                    <div class="select-dropdown">
                      <select
                        name="group_limit"
                        value={values.group_limit}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option disabled selected value="">
                          Group Limit
                        </option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={4}>3</option>
                        <option value={5}>5</option>

                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>

                        <option value={10}>10</option>
                        <option value={11}>11</option>
                        <option value={12}>12</option>
                        <option value={13}>13</option>
                        <option value={14}>14</option>
                        <option value={15}>15</option>

                        <option value={16}>16</option>
                        <option value={17}>17</option>
                        <option value={18}>18</option>
                        <option value={19}>19</option>

                        <option value={20}>20</option>
                      </select>
                    </div>
                  </div>
                )}
                <div className="session-bookinf-all">
                  <div className="sessions-from-left booking-timebx">
                    <label>Minimum Booking Time</label>
                    <div className="left-select-b">
                      <div className="time-booking-b">Hours</div>
                      <div class="select-dropdown">
                        <select
                          onChange={handleChange}
                          // defaultValue={selectValue}
                          value={values.min_booking_hour}
                          id="min_booking_hour"
                          name="min_booking_hour"
                        >
                          <option disabled selected value="">
                            Hours
                          </option>
                          {makeHours(23).map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="right-select-b">
                      <div className="time-booking-b">Min</div>
                      <div class="select-dropdown">
                        <select
                          onChange={handleChange}
                          // defaultValue={selectValue}
                          value={values.min_booking_time}
                          id="min_booking_time"
                          name="min_booking_time"
                        >
                          <option disabled selected value="">
                            Min
                          </option>
                          {makeHours(59).map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* <div className="sessions-from-right booking-timebx">
                    <label>Maximum Booking Time</label>
                    <div className="left-select-b">
                      <div className="time-booking-b">Hours</div>
                      <div class="select-dropdown">
                        <select
                          onChange={handleChange}
                          // defaultValue={selectValue}
                          value={values.max_booking_hour}
                          id="max_booking_hour"
                          name="max_booking_hour"
                        >
                          <option disabled selected value="">
                            Hours
                          </option>
                          {makeHours(23).map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="right-select-b">
                      <div className="time-booking-b">Min</div>
                      <div class="select-dropdown">
                        <select
                          onChange={handleChange}
                          // defaultValue={selectValue}
                          value={values.max_booking_time}
                          id="max_booking_time"
                          name="max_booking_time"
                        >
                          <option disabled selected value="">
                            Min
                          </option>
                          {makeHours(59).map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div> */}
                </div>

                <div className="add-booking-questions-box">
                  <FieldArray name="questions">
                    {({ insert, remove, push }) => (
                      <div>
                        {values.questions.length > 0 &&
                          values.questions.map((question, index) => (
                            <div key={index}>
                              <div className="ful-filed-question">
                                <label htmlFor={`questions.${index}.question`}>
                                  Add Booking Questions
                                </label>
                                <input
                                  onChange={handleChange}
                                  name={`questions.${index}.question`}
                                  value={question.question}
                                  type="text"
                                  placeholder=""
                                ></input>
                              </div>
                              <div className="ful-filed-question">
                                <label htmlFor={`questions.${index}.answer`}>
                                  Answer
                                </label>
                                <input
                                  onChange={handleChange}
                                  name={`questions.${index}.answer`}
                                  value={question.answer}
                                  type="text"
                                  placeholder=""
                                ></input>
                              </div>
                              <div className="buttion-add-box onclick-button ">
                                <img
                                  onClick={() => remove(index)}
                                  className="pointer"
                                  src="../images/icon-b2.png"
                                  alt="delete"
                                />
                              </div>
                            </div>
                          ))}
                        <div className="buttion-add-box onclick-button clear-both">
                          <button
                            type="button"
                            className="box-valid-from-three-btn"
                            onClick={() => push({ question: "", answer: "" })}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    )}
                  </FieldArray>
                  {/****
                   *
                   *
                   *
                   */}

                  <div>
                    <div className="full-side">
                      <label >Level Type</label>
                      <div className="check-field mt-3">
                        <div className="check-field">
                          <input
                            onChange={handleChange}
                            value={1}
                            id={`my-check-participant-level`}
                            defaultChecked={values.participant_level === 1 ? "checked" : ""}
                            type="checkbox"
                            name="participant_level"
                          />
                          <label htmlFor={`my-check-participant-level`}>
                            Paticipant Level
                          </label>
                        </div>
                      </div>
                      <div className="check-field mt-3">
                        <div className="check-field">
                          <input
                            onChange={handleChange}
                            value={1}
                            id={`my-check-Student-level`}
                            defaultChecked={values.student_level === 1 ? "checked" : ""}
                            type="checkbox"
                            name="student_level"
                          />
                          <label htmlFor={`my-check-Student-level`}>
                            Student Level
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*******
                   *
                   *
                   *
                   *
                   */}
                  <div className="price-box-all">
                    <div className="filde-box-price">
                      <label className="mb-2 mt-4">Pricing</label>
                      {/* <form action="#"> */}
                      <p>
                        <input
                          onChange={handleChange}
                          value="free"
                          type="radio"
                          id="free"
                          name="pricing_type"
                          checked={
                            values.pricing_type === "free" ? true : false
                          }
                        ></input>
                        <label for="free">Free</label>
                      </p>
                      <p>
                        <input
                          onChange={handleChange}
                          value="fixed"
                          type="radio"
                          id="fixed"
                          name="pricing_type"
                          checked={
                            values.pricing_type === "fixed" ? true : false
                          }
                        ></input>
                        <label for="fixed">Fixed Price</label>
                      </p>
                      <p>
                        <input
                          onChange={handleChange}
                          value="pricevariable"
                          type="radio"
                          id="pricevariable"
                          name="pricing_type"
                          checked={
                            values.pricing_type === "pricevariable"
                              ? true
                              : false
                          }
                        ></input>
                        <label htmlFor="pricevariable">Price Variables</label>
                      </p>
                      {/* </form> */}
                      {values.pricing_type === "fixed" && (
                        <div className="price-fild-input d-flex">
                          <div className="box-doler">$</div>
                          <input
                            onChange={handleChange}
                            type="text"
                            name="minprice"
                            id="minprice"
                            value={values.minprice}
                            placeholder=""
                          ></input>
                        </div>
                      )}
                      {values.pricing_type === "pricevariable" && (
                        <div className="d-flex align-items-center">
                          <div className="price-fild-input d-flex">
                            <div className="box-doler">$</div>
                            <input
                              onChange={handleChange}
                              value={values.minprice}
                              name="minprice"
                              id="minprice"
                              type="text"
                              placeholder=""
                            ></input>
                          </div>
                          <span className="price-variable-dash">-</span>
                          <div className="price-fild-input d-flex">
                            <div className="box-doler">$</div>
                            <input
                              onChange={handleChange}
                              type="text"
                              name="maxprice"
                              id="maxprice"
                              value={values.maxprice}
                              placeholder=""
                            ></input>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="cancel-sumbit-button-box">
                  <button
                    type="button"
                    onClick={openSessionList}
                    className="cancel-btn"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="submit-btn">
                    Submit
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
