import React from "react";

export default function ConfirmTime({ deleteUser, cancelRequest, id }) {
    return (
        <div className="confirm-delete-wrapper register-medium">
            <div data-backdrop="static" className="modal fade" id={id} role="dialog">
                <div className="modal-dialog padding-unset modal-dialog-preview">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="icon-pop">
                                {/* <img src="images/Union-icon.png" alt="Union-icon" /> */}
                                <br />
                            </div>
                            <h3>You have added session of more than 120 minutes.</h3>
                            <div className="confirm-btn-box">

                                <div
                                    className="register-medium-confirnation"
                                    data-dismiss="modal"
                                    onClick={deleteUser}
                                >
                                    close
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
