import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Topbar from "../components/topbar/topbar";
import Footer from "../components/footer/footer";
import Profile from "../pages/clientprofile";
import VerifyEmail from "../pages/admin/calendar/verifyemail";

const ClientRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/profile" />} />
      <Route path="/verifyemail" element={<VerifyEmail />} />
      <Route
        path="/profile"
        element={
          <>
            <Topbar />
            <Profile />
            <Footer />
          </>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
export default ClientRoute;
