import React, { useEffect, useState } from "react";
import moment from "moment";
import { approveRequest, getAllRequests } from "../../../services/admin";

import { setNameRequests } from "../../../reducer/adminSlice";
import { useDispatch, useSelector } from "react-redux";
import { toTitleCase } from "../../../utils/helpers";
import ImagePopup from "./imagepopup";
import Button from "../../../components/button";

export default function Requests() {
  const { nameRequests } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const [requests, setRequests] = useState([]);
  const [imageUrl, setImageUrl] = useState('')
  useEffect(() => {
    if (!nameRequests.length) getRequests();
  }, []);
  useEffect(() => {
    const response = nameRequests.map((resp) => {
      return {
        ...resp,
        status: false,
      };
    });
    setRequests(response);
  }, [nameRequests]);
  const getRequests = () => {
    getAllRequests()
      .then((data) => {
        if (data.data.status) {
          dispatch(setNameRequests(data.data.data));
        }
      })
      .catch(() => { });
  };

  const onClickHandler = (e, id) => {
    const response = requests.map((user) => {
      console.log(typeof user.id, typeof id);
      if (user.id === id) {
        user.status = !user.status;
      } else {
        user.status = false;
      }
      return user;
    });
    setRequests(response);
  };
  const approveUserRequest = (id, status) => {
    let body = {
      id: id,
      status: status,
    };
    approveRequest(body)
      .then((data) => {
        if (data.data.status) {
          getRequests();
        }
      })
      .catch(() => { });
  };
  const openPopup = (url) => {
    setImageUrl(url)
  }
  return (
    <>
      <div className="table-box-medium">
        <table className="caseTable">
          <tbody>
            <tr className="dark">
              <th>Screen Name</th>
              <th>Request Type</th>
              <th>Request value</th>
              <th>Date</th>
              <th className="text-right">Actions</th>
            </tr>
            {requests.length > 0 ? (
              requests.map((request) => (
                <tr className="innr pointer">
                  <td className="capitalize">
                    {request.screen_name ? request.screen_name : "N/A"}
                  </td>
                  <td> {request.type ? toTitleCase(request.type) : "N/A"}</td>
                  <td className="change">
                    {request.type === 'profile_image' ?
                      <Button
                        data-toggle="modal"
                        data-target="#image-Popup"
                        status={0}
                        onClick={() => openPopup(request.value)}
                        type="button"
                        className="resend-btn"
                        title="View"
                      />
                      :
                      request.value ? request.value : "N/A"

                    }
                  </td>
                  <td>
                    {" "}
                    {moment
                      .utc(request.date)
                      .local()
                      .format("LL")}
                  </td>
                  <td className="dott">
                    <img
                      onClick={(e) => onClickHandler(e, request.id)}
                      src="/images/Unionblck.png"
                      className="padding-10"
                      alt="dots"
                    />

                    {request.status && (
                      <div className="approve-btn-1 approve-btn z-index-1">
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            approveUserRequest(request.id, 1);
                          }}
                        >
                          Approve
                        </span>

                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            approveUserRequest(request.id, 2);
                          }}
                        >
                          Decline
                        </span>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No Request Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
       <ImagePopup url={imageUrl} />
      </div>
    </>
  );
}
