export const list = [
  { id: 1, name: "Client Feedback to Intermediate", count: 0 },
  { id: 2, name: "Partner Feedback to Practicing Medium", count: 0 },
  { id: 2, name: "Client Feedback to Professional", count: 0 },
];


export const filterList = [
  {
    id: 1,
    name: "Medium name",
    value: "screen_name",
  },
  {
    id: 2,
    name: "Client name",
    value: "name",
  },
  {
    id: 3,
    name: "Payment method",
    value: "payment_method",
  },
 {
    id: 4,
    name: "Payment status",
    value: "payment_status",
  },
  {
    id: 5,
    name: "Session name",
    value: "session_name",
  },
  {
    id: 3,
    name: "Appt. ID",
    value: "session_id",
  },
];
