import React from "react";
//import moment from "moment";
// import Search from "../../../../components/search";
import { useSelector } from "react-redux";
import { session_id_beg,session_id_end } from "../../../../../utils/helpers";
import Pagination from "../../../../../components/tablePagination";
export default function NoShows(props) {
  const {
    tableData=[],
    changeHandler,
    onClickHandler,
    tableHeaders=[],
    // filterListAppointments,
    openSurveyLink,
    getPaginationData,
    setFilterValues,
    setSearchValues
  } = props;
  const {
    allNoShowCount
  } =  useSelector((state) => state.client);
  return (
    <>
      <div className="d-flex justify-content-end gap-10 flex-wrap">
        {/* <Search callApi={filterListAppointments} list={filterList} /> */}
      </div>
      <div className="table-box-medium admin-appoi">
        <table className="caseTable">
          <tbody>
            <tr className="dark">
              {React.Children.toArray(
                tableHeaders.map((item) => <th>{item.title}</th>)
              )}
            </tr>
            {tableData.length > 0 ? (
              tableData.map((appoinment) => (
                <tr className="innr">
                   <td>{session_id_beg+appoinment.id+session_id_end}</td>
                  <td>
                    <span className="capitalize">
                      {" "}
                      {appoinment.medium_name}
                    </span>
                  </td>
                  <td>{appoinment.session_name}</td>
                  <td>{appoinment.date}</td>
                  <td> {appoinment.time}</td>
  

                  <td>
                  {appoinment.userby}
           
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No Appointments Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination  rowsPerPageOptions={[5, 10, 25]}  count={allNoShowCount} activeTab={"noshows"} getPaginationData={getPaginationData} />
      </div>
    </>
  );
}
