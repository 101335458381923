import React, { useEffect } from "react";
import { toTitleCase } from "../../utils/helpers";
import './index.css'
export default function InfoPopup({ feedBackinfo, sessionType }) {
  useEffect(() => {
    console.log(sessionType,"sessionType")
  }, [feedBackinfo]);
  return (
    <div
      className="modal fade"
      id="infopopup"
      role="dialog"
      data-backdrop="true"
    >
      <div className="modal-dialog modal-dialog-preview">
        {/* <button type="button" className="close" data-dismiss="modal">
        &times;
      </button> */}
        <div className={`modal-content margin-top-300 ${sessionType !== undefined && sessionType.length && sessionType[0].session_type === "Coaching" ? 'custom-modal-content' : ''}`}>
          <div className="modal-body">
            <div className="profile-video"></div>
            <h3 className="text-center">Feedback</h3>
            <div>

              {feedBackinfo !== null && feedBackinfo.length > 0 &&
                feedBackinfo.map((info, index) => (
                  <>
                    {sessionType !== undefined && sessionType.length && (sessionType[0].session_type === "Practice" || sessionType[0].session_type === "OnDemand Sessions") ?
                      <h6 className="text-center font-weight-bolder font-size-17 mt-2 mb-2">Feedback Given By :<span className="d5a67b "> {sessionType[index].client_name}</span> </h6>
                      :
                      feedBackinfo.length > 1 && (
                        <h6 className="text-center font-weight-bolder font-size-17">Feedback {index + 1}&nbsp;{index === 0 && '(Earliest)'} </h6>
                      )
                    }
                    {Object.keys(info).length > 0 &&
                      Object.entries(info).map(([key, value]) => {
                        // Skip this iteration if the value is an empty string and the key matches the conditions
                        

                        return (
                          <span className={`d-block mb-2 mt-2 font-size-17 ${sessionType !== undefined && sessionType.length && sessionType[0].session_type === "Coaching" ? 'feedback-item' : ''}`} key={key}>
                            {key !== "appointment" && (
                              <>
                                {" "}
                                <h6 className={`inline-block font-weight-bolder align-data font-size-17 ${sessionType !== undefined && sessionType.length && sessionType[0].session_type === "Coaching" ? 'question-text' : ''}`}>
                                  {" "}
                                    {toTitleCase(key) === "Overall Impression" 
                                    ? "VerySoul Public View"
                                    : toTitleCase(key) === "Additional Feedback"
                                    ? "Confidential Note to You"
                                    : toTitleCase(key)}
                                </h6>{" "}
                                : {value}
                              </>
                            )}
                          </span>
                        );
                      })
                    }
                  </>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
