import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import {
  format,
  startOfWeek,
  addDays,
  // isSameDay,
  lastDayOfWeek,
  // getWeek,
  addWeeks,
  subWeeks,
} from "date-fns";
import { useDispatch, useSelector } from "react-redux";

import { setLoading } from "../../reducer/auth/auth";
import { getMediumAvailability, setNewTimeZone } from "../../services/medium";
import { calendarArray } from "./data";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
//components
// import TimeBox from "../../components/timebox";
import { setNewTimeZoneData } from "../../reducer/userSlice";
import { setTimezoneArray } from "../../reducer/uidataSlice";
import { getTimezones } from "../../services/uidata";
import { filterVisibleTimeZones, removeQueryParams, setQueryParams } from "../../utils/helpers";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";


export default function Calendar({ slots }) {
  const { timezone } = useSelector((state) => state.user.user);
  const { timezoneArray } = useSelector((state) => state.uidata);

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const calendarRef = useRef();


  //query params
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("view");
  const searchVal = searchParams.get("search");
  const searchName = searchParams.get("searchName");
  const page = searchParams.get('page');
  const rowperpage = searchParams.get('rowperpage');
  //query params


  //query state
  const [queryValue, setQueryValue] = useState('');
  const [query1, setQuery1] = useState('');
  const [query2, setQuery2] = useState('');
  const [query3, setQuery3] = useState('');
  const [query4, setQuery4] = useState('');
  const [query5, setQuery5] = useState('');
  const [query6, setQuery6] = useState('');
  //query state

  const [calendarData, setCalendarData] = useState(
    JSON.parse(JSON.stringify(calendarArray))
  );
  const [weekType, setWeekType] = useState(0);
  const [dataList, setDataList] = useState([]);
  const [timezoneData, setTimezoneData] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  
  // const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  // const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    localStorage.setItem('pagintionTYPE', 'change')
    if (params.id !== undefined) {
      setQueryParams(setQueryValue, setQuery1, setQuery2,setQuery3,setQuery4,setQuery5,setQuery6, status, searchVal, searchName,page ,rowperpage,"","")
      setTimeout(() => {
        removeQueryParams()

      }, 2000);
    }

  }, []);
  const startNextWeek = (type) => {
    return moment()
      .add(type, "weeks")
      .startOf("week")
      .format("YYYY-MM-DD");
  };
  const endNextWeek = (type) => {
    return moment()
      .add(type, "weeks")
      .endOf("week")
      .format("YYYY-MM-DD");
  };
  const getDate = (date) => {
    return moment
      .utc(`${date}`, "YYYY-MM-DD HH:mm:ss")
      .local()
      .format("YYYY-MM-DD");
  };
  const getDay = (date, time) => {
    return moment
      .utc(`${getDate(date)} ${time}`, "YYYY-MM-DD HH:mm:ss")
      .local()
      .format("ddd");
  };
  const getGoogleDay = (time) => {
    return moment(time, "YYYY-MM-DD HH:mm:ss")
      .local()
      .format("ddd");
  };
  function arrayMatch(arr1, arr2) {
    for (var i = 0; i < arr1.length; ++i) {
      for (var j = 0; j < arr2.length; ++j) {
        let item =
          arr2[j].appointment_type === "Google Event"
            ? moment
              .utc(arr2[j].start_date_time, "YYYY-MM-DD HH:mm:ss")
              .local()
              .format("hh:mm A")
            : moment
              .utc(
                `${getDate(arr2[j].date)} ${arr2[j].time}`,
                "YYYY-MM-DD HH:mm:ss"
              )
              .local()
              .format("hh:mm A");
        if (arr1[i].time === item) {
          Object.keys(arr1[i]).forEach((key) => {
            if (key !== "time") {
              let day =
                arr2[j].appointment_type === "Google Event"
                  ? getGoogleDay(arr2[j].start_date_time)
                  : getDay(getDate(arr2[j].date), arr2[j].time);
              // console.log(item , moment().day(getDay(getDate(arr2[j].date),arr2[j].time)).toLowerCase());
              if (key === day.toLowerCase()) {
                arr1[i][day.toLowerCase()].push({
                  ...arr2[j],
                  item,
                  // type: "verysoul",
                });
              } else {
                arr1[i][key] = arr1[i][key];
              }
            }
          });
        }
      }
    }
    return arr1;
  }

  // const changeMonthHandle = (btnType) => {
  //   if (btnType === "prev") {
  //     setCurrentMonth(subMonths(currentMonth, 1));
  //   }
  //   if (btnType === "next") {
  //     setCurrentMonth(addMonths(currentMonth, 1));
  //   }
  // };

  const changeWeekHandle = (btnType) => {
    setCalendarData(JSON.parse(JSON.stringify(calendarArray)));
    if (btnType === "prev") {
      let newweek = weekType - 1;
      setWeekType(newweek);
      getAppointments(newweek);
      setCurrentMonth(subWeeks(currentMonth, 1));
      // setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
    }
    if (btnType === "next") {
      let newweek = weekType + 1;
      setWeekType(newweek);
      getAppointments(newweek);
      setCurrentMonth(addWeeks(currentMonth, 1));
      // setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
    }
  };

  // const onDateClickHandle = (day, dayStr) => {
  //   setSelectedDate(day);
  //   // showDetailsHandle(dayStr);
  // };

  const renderHeader = () => {
    const dateFormat = "MMM yyyy";
    return <div className="year-box">{format(currentMonth, dateFormat)}</div>;
  };
  // const renderDays = () => {
  //   const dateFormat = "EEE";
  //   const days = [];
  //   let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
  //   for (let i = 0; i < 7; i++) {
  //     days.push(
  //       <div className="col col-center" key={i}>
  //         {format(addDays(startDate, i), dateFormat)}
  //       </div>
  //     );
  //   }
  //   return <div className="days row">{days}</div>;
  // };
  const renderCells = () => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 0 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 0 });
    const ndateFormat = "EEE";
    // const ndays = [];
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        // const cloneDay = day;
        days.push(
          <li>
            {format(addDays(startDate, i), ndateFormat)} {formattedDate}
          </li>
        );
        day = addDays(day, 1);
      }

      rows.push(<>{days}</>);
      days = [];
    }
    return <>{rows}</>;
  };

  const getAppointments = async (type, calendar, date) => {
    const START_DATE = document
      .querySelectorAll('[role="columnheader"]')[0]
      .getAttribute("data-date");
    const END_DATE = document
      .querySelectorAll('[role="columnheader"]')[6]
      .getAttribute("data-date");
    dispatch(setLoading(true));
    let body;
    // if (calendar === "yes") {
    let medium_id = params.id;
    if (medium_id !== undefined) {
      body = {
        startDate: START_DATE,
        endDate: moment(END_DATE)
          .add(2, "d")
          .format("YYYY-MM-DD"),
        medium_id: medium_id,

        // };
        // } else 2
        //   body = {
        //     startDate: startNextWeek(type),
        //     endDate: endNextWeek(type),
        //   };
      };
    } else {
      body = {
        startDate: START_DATE,
        endDate: moment(END_DATE)
          .add(2, "d")
          .format("YYYY-MM-DD"),

        // };
        // } else 2
        //   body = {
        //     startDate: startNextWeek(type),
        //     endDate: endNextWeek(type),
        //   };
      };
    }
    await getMediumAvailability(body)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          if (data.data.data.length > 0) {
            let info = data.data.data.map((item) => {
              if (item.appointment_type === "Google Event") {
                return {
                  title: "Blocked",
                  start: item.start_date_time,
                  end: item.end_date_time,
                  color: item.colour_code,
                };
              } else if (item.appointment_type === "Availability") {
                return {
                  title: "Availability",
                  start: item.start_date_time,
                  end: item.end_date_time,
                  color: item.colour_code,
                };
              } else if (item.appointment_type === "Advance") {
                return {
                  title: item.appointment_name,
                  start: item.start_date_time,
                  end: item.end_date_time,
                  color: item.colour_code,
                };
              } else {
                return {
                  title: item.appointment_name,
                  start: item.start_date_time,
                  end: item.end_date_time,
                  display: "none",
                  // color: "green",
                };
              }
            });
            setDataList(info);
            calendarSetUp(data.data.data);
          } else {
            setCalendarData(JSON.parse(JSON.stringify(calendarArray)));
          }
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };
  const calendarSetUp = (data) => {
    setCalendarData(
      arrayMatch(JSON.parse(JSON.stringify(calendarArray)), data)
    );
  };
  useEffect(() => {
    let calendarApi = calendarRef.current.getApi();
    console.log(calendarApi.currentData.dateProfile.currentRange);
    getAppointments(
      "",
      "yes",
      calendarApi.currentData.dateProfile.currentRange
    );
  }, []);
  // useEffect(()=>{

  // },[dataList])
  const prevHandler = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.incrementDate({ days: 7 });
    getAppointments(
      "",
      "yes",
      calendarApi.currentData.dateProfile.currentRange
    );
    console.log(calendarApi.currentData.dateProfile.currentRange);
  };
  const nextHandler = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.incrementDate({ days: -7 });
    getAppointments(
      "",
      "yes",
      calendarApi.currentData.dateProfile.currentRange
    );
    console.log(calendarApi.currentData.dateProfile.currentRange);
  };

  useEffect(() => {
    if (!timezoneArray.length) updateTimezones();
  }, []);
  useEffect(() => {
    setTimezoneData(filterVisibleTimeZones(timezoneArray));
  }, [timezoneArray]);

  const updateTimezones = () => {
    getTimezones()
      .then((data) => {
        if (data.data.status) {
          dispatch(setTimezoneArray(data.data.data));
        }
      })
      .catch((err) => console.log(err));
  };
  const changeTimeZoneHandler = (e) => {
    let id = e.target.value;
    let body = {
      timezone_id: id,
    };
    setNewTimeZone(body)
      .then((data) => {
        if (data.data.status) {
          // getAllAppointmentsList();
          dispatch(setNewTimeZoneData(filterTimeZone(id)));
          let calendarApi = calendarRef.current.getApi();
          getAppointments(
            "",
            "yes",
            calendarApi.currentData.dateProfile.currentRange
          );
        }
      })
      .catch(() => { });
  };
  const filterTimeZone = (id) => {
    let zone;
    timezoneData.filter((item) => {
      if (parseInt(id) === parseInt(item.id)) {
        zone = item.front_name;
      }
    });
    return zone;
  };
  return (
    <>
      {/* <div className="bar-wrapper-note-calendar ">
        <p className="red margin-unset font-size-15">
          Note: Min/Max Booking Times will be reflected in availability
          precisely (minute) in the booking views, but only approximately (day)
          in My Sessions and your Google Calendar.
        </p>
      </div> */}
      <div>
        {params.id !== undefined && (
          <div className="right-last-td d-flex justify-content-between align-items-center tr-padding">
            <span
              onClick={() => navigate(`/mediums?view=${queryValue}&search=${query1}&searchName=${query2}&page=${query3}&rowperpage=${query4}&pagintion=${'change'}`)}
              className="pointer"
            >
              <span>
                <>
                  <img
                    src="/images/arrow-back.png"
                    alt="arrow"
                    className="mt-3"
                  />
                  &nbsp; Back to list
                </>
              </span>
            </span>
          </div>
        )}
        {params.id === undefined && (
          <div className="middle-screen-name time-zone-area font-size-22 mt-2 mb-2">
            {" "}
            <select
              onChange={changeTimeZoneHandler}
              className="select-box-timezone pointer"
            >
              <option disabled selected value="">
                Change Timezone
              </option>
              {timezoneData.map((item) => (
                <option value={item.id}>{item.front_name}</option>
              ))}
            </select>
            <b>Timezone: {timezone !== undefined && timezone}&nbsp;</b>
          </div>
        )}
        <br />
        <br />

        <div className="ml-4 d-flex">
          <div className="legend-calendar FF9500-Starter">Starter Availability</div>
          <div className="legend-calendar CD964-Donated ">Donated Availability</div>
          <div className="legend-calendar AC8FA-PostPay">PostPay Availability</div>
          <div className="legend-calendar FECB2E-PrePay">PrePay Availability</div>
          <div className="legend-calendar FECB2E-Blocked">Blocked Hours</div>
          <div className="legend-calendar C39BD3-extra">Extra Services</div>
          <div className="legend-calendar FF0D2-practice">Practice Availability</div>
        </div>
        <FullCalendar
          timeZone="US/Central"
          ref={calendarRef}
          customButtons={{
            prev: {
              text: "Prev",
              click: nextHandler,
            },
            next: {
              text: "next",
              click: prevHandler,
            },
          }}
          dayHeaderFormat={{
            weekday: "long",
            month: "short",
            day: "numeric",
            omitCommas: false,
          }}
          titleFormat={{ year: "numeric", month: "short", day: "numeric" }}
          headerToolbar={{
            left: "prev",
            right: "next",
            center: "title",
          }}
          slotEventOverlap={false}
          events={dataList}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          height={"90vh"}
        />
      </div>
      <br />
      <br />
      {/* <div className="table-calndar">
      {renderHeader()}
      <div className="butn-next-prievw">
        <span
          onClick={() => {
            changeWeekHandle("prev");
          }}
          className="controlers"
        >
          <img src="images/left-erow.png" alt="Previous" />
          Previous
        </span>
        <span
          onClick={() => {
            changeWeekHandle("next");
          }}
          className="controlers"
        >
          Next
          <img src="images/right-ereow.png" alt="Next" />
        </span>
      </div>
      <div className="tbll-row">
        <div className="ul">
          <ul className="hading-row-tbl">
            <li className="time">
              <img src="images/time.png" alt="time" />
              Time
            </li>
            {renderCells()}
          </ul>

          {calendarData.length &&
            calendarData.map((time) => (
              <ul className="content-row-tbl">
                <li className="time-cont">{time.time}</li>
                <li>
                  {time.sun.map((slot) => (
                    <TimeBox time={slot} />
                  ))}
                </li>
                <li>
                  {time.mon.map((slot) => (
                    <TimeBox time={slot} />
                  ))}
                </li>
                <li>
                  {time.tue.map((slot) => (
                    <TimeBox time={slot} />
                  ))}
                </li>
                <li>
                  {time.wed.map((slot) => (
                    <TimeBox time={slot} />
                  ))}
                </li>
                <li>
                  {time.thu.map((slot) => (
                    <TimeBox time={slot} />
                  ))}
                </li>
                <li>
                  {time.fri.map((slot) => (
                    <TimeBox time={slot} />
                  ))}
                </li>
                <li>
                  {time.sat.map((slot) => (
                    <TimeBox time={slot} />
                  ))}
                </li>
              </ul>
            ))}
        </div>
      </div>
    </div> */}
    </>
  );
}
