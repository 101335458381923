import React, { useEffect, useState } from 'react'
import StepOne from './stepone';
import StepTwo from './steptwo';
import StepThree from './stepthree';
import StepFour from './stepfour';
import StepSix from './stepsix';
import StepFive from './stepfive';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function VerificationSteps({ setuserFirstTime }) {
    const { step_progress } = useSelector(
        (state) => state.user.user
    );
    const [step, setStep] = useState(0);
    const activestep = localStorage.getItem('step')
    const navigate = useNavigate()

    const NextStep = () => step !== 5 && setStep(step + 1);
    const PreviousStep = () => step !== 0 && setStep(step - 1);

    useEffect(() => {

        setStep(Object.values(step_progress).lastIndexOf(1) + 1);
    }, [])
    const innerWidthHandler = () => {
        let innerWidth = window.innerWidth;
        return innerWidth < 768 ? 350 : 860;
      };
    const RenderedComponent = () => {
        switch (step) {
            case 0:
                return (
                    <StepOne
                        NextStep={NextStep}
                        innerWidthHandler={innerWidthHandler}
                    />
                );
            case 1:
                return (
                    <StepTwo
                        NextStep={NextStep}
                        innerWidthHandler={innerWidthHandler}
                    />
                );
            case 2:
                return (
                    <StepThree
                        NextStep={NextStep}
                        innerWidthHandler={innerWidthHandler}
                    />
                )
            case 3:
                return (
                    <StepFour
                        NextStep={NextStep}
                        innerWidthHandler={innerWidthHandler}
                    />
                )
            case 4:
                return (
                    <StepFive
                        NextStep={NextStep}
                        innerWidthHandler={innerWidthHandler}
                    />
                )
            case 5:
                return (
                    <StepSix
                    setuserFirstTime={setuserFirstTime}
                        NextStep={NextStep}
                        innerWidthHandler={innerWidthHandler}
                    />
                )
            default:
                return (
                    <></>
                )
        }
    }
    const stepHandler = (stepNum) => {
        setStep(stepNum)
    }
    const checkStep = (move) => {
        if (move > activestep) {
            return;
        }
        else {
            stepHandler(move);
        }
    }
    return (
        <div className="medium-booking-container mt-2">
            {console.log(activestep, "activestep", step)}
            {/* <span
                className="back-handler pointer medium-booking-container-back-btn"
                onClick={() => {
                    navigate('/appointments');
                }}
            >
                <img src="../images/arrow-back.png" alt="go back" />&nbsp;
                Go Back
            </span> */}
            <div className=" background-unset">
                <div className="container">
                    <div className="row justify-content-center">
                        <div id="msform">
                            <div className="header-from background-unset">
                                <ul id="progressbar">
                                    <li className={`${"active"} ${step === 0 ? 'current-active' : ''}`} id="account">
                                        <span
                                            onClick={() => { checkStep(0) }}
                                        >
                                            <img
                                                src="images/privacy.png"
                                                className="black"
                                                alt="dark"
                                            />
                                            <img
                                                src="images/privacy-wight.png"
                                                className="wight"
                                                alt="light"
                                            />
                                        </span>
                                        {step > 0 ?
                                            <div className='step-image-box'>
                                                <img className='w-20-px mr-2' src="./images/tick-marks.png" />
                                                <strong>Step 1</strong>
                                            </div> : <strong className='w-100'>Step 1</strong>}
                                    </li>

                                    <li className={`${step > 0 ? "active" : ""} ${step === 1 ? 'current-active' : ''}`} id="confirm">
                                        <span
                                            onClick={() => { checkStep(1) }}
                                        >
                                            <img
                                                src="images/privacy.png"
                                                className="black"
                                                alt="dark"
                                            />
                                            <img
                                                src="images/privacy-wight.png"
                                                className="wight"
                                                alt="light"
                                            />
                                        </span>
                                        {step > 1 ?
                                            <div className='step-image-box'>
                                                <img className='w-20-px mr-2' src="./images/tick-marks.png" />
                                                <strong>Step 2</strong>
                                            </div> : <strong className='w-100'>Step 2</strong>}
                                    </li>
                                    <li className={`${step > 1 ? "active" : ""} ${step === 2 ? 'current-active' : ''}`} id="confirm">
                                        <span
                                            onClick={() => { checkStep(2) }}
                                        >
                                            <img
                                                src="images/privacy.png"
                                                className="black"
                                                alt="dark"
                                            />
                                            <img
                                                src="images/privacy-wight.png"
                                                className="wight"
                                                alt="light"
                                            />
                                        </span>
                                        {step > 2 ?
                                            <div className='step-image-box'>
                                                <img className='w-20-px mr-2' src="./images/tick-marks.png" />
                                                <strong>Step 3</strong>
                                            </div> : <strong className='w-100'>Step 3</strong>}
                                    </li>
                                    <li className={`${step > 2 ? "active" : ""} ${step === 3 ? 'current-active' : ''}`} id="confirm">
                                        <span
                                            onClick={() => { checkStep(3) }}
                                        >
                                            <img
                                                src="images/privacy.png"
                                                className="black"
                                                alt="dark"
                                            />
                                            <img
                                                src="images/privacy-wight.png"
                                                className="wight"
                                                alt="light"
                                            />
                                        </span>
                                        {step > 3 ?
                                            <div className='step-image-box'>
                                                <img className='w-20-px mr-2' src="./images/tick-marks.png" />
                                                <strong>Step 4</strong>
                                            </div> : <strong className='w-100'>Step 4</strong>}
                                    </li>
                                    <li className={`${step > 3 ? "active" : ""} ${step === 4 ? 'current-active' : ''}`} id="confirm">
                                        <span
                                            onClick={() => { checkStep(4) }}
                                        >
                                            <img
                                                src="images/privacy.png"
                                                className="black"
                                                alt="dark"
                                            />
                                            <img
                                                src="images/privacy-wight.png"
                                                className="wight"
                                                alt="light"
                                            />
                                        </span>
                                        {step > 4 ?
                                            <div className='step-image-box'>
                                                <img className='w-20-px mr-2' src="./images/tick-marks.png" />
                                                <strong>Step 5</strong>
                                            </div> : <strong className='w-100'>Step 5</strong>}
                                    </li>
                                    <li className={`${step > 4 ? "active" : ""} ${step === 5 ? 'current-active' : ''}`} id="confirm">
                                        <span
                                            onClick={() => { checkStep(5) }}
                                        >
                                            <img
                                                src="images/privacy.png"
                                                className="black"
                                                alt="dark"
                                            />
                                            <img
                                                src="images/privacy-wight.png"
                                                className="wight"
                                                alt="light"
                                            />
                                        </span>
                                        {step > 5 ?
                                            <div className='step-image-box'>
                                                <img className='w-20-px mr-2' src="./images/tick-marks.png" />
                                                <strong>Step 6</strong>
                                            </div> : <strong className='w-100'>Step 6</strong>}
                                    </li>
                                </ul>
                            </div>
                            <div className="body-from from-step-new">{RenderedComponent()}</div>
                        </div>
                    </div>
                </div>
                {/* <div className="loader-overlay">
      <GridLoader />
    </div> */}
            </div>
        </div>
    )
}
