import React, { useEffect, useState } from "react";
// import "./toogle.css"; // Make sure your styles are correct
// import { changeProfessionalStatus } from "../../services/admin"; // Remove if not used
import { testUser } from '../../../../services/admin';

const ToggleTestUser = ({ clientId, initialStatus, onStatusChange }) => {
    const [toggleState, setToggleState] = useState(initialStatus);

    useEffect(() => {
        // Set the toggle state based on the initial status when the component mounts
        setToggleState(initialStatus);
    }, [initialStatus]);

    const handleChange = async () => {
        const newStatus = !toggleState;

        // Update the local state immediately for a responsive UI
        setToggleState(newStatus);
        
        const body = {
            user_id: clientId,
            test_user_status: newStatus ? 1 : 0,
        };

        try {
            // Make the API call to update the user's status
            const data = await testUser(body);
            if (data.data.status) {
                console.log("Status changed successfully");
                // Call the onStatusChange callback to inform the parent component
                onStatusChange(clientId, newStatus);
            }
        } catch (error) {
            console.error('Error updating user status:', error);
            // Revert the state if the API call fails
            setToggleState(!newStatus);
        }
    };

    return (
        <div className="toggle-button-cover">
            <div className="button-cover">
                <div className="button r" id="button-1" onClick={handleChange}>
                    <input
                        id={`test_user_${clientId}`}
                        type="checkbox"
                        checked={toggleState}
                        name="test_user"
                        className="checkbox"
                        readOnly // Prevent manual changes to the checkbox
                    />
                    <div className="knobs"></div>
                    <div className="layer"></div>
                </div>
            </div>
        </div>
    );
};

export default ToggleTestUser;