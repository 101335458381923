import React, { useEffect, useState } from "react";
import { session_id_beg, session_id_end } from "../../../../../utils/helpers";



export default function NoshowBox({
    noShowActive,
    list = [],
    noShowRequest,
    noshowRemoveGroupAppointment,
    noshowFrom
}) {

    return (
        <div className="register-medium">
            <div
                className="modal fade "
                id="NoshowBox"
                // data-backdrop="static"
                role="dialog"
            >
                <div className="width-unset  modal-dialog advanceConfirmbooking modal-lg  modal-dialog-preview height-unset">
                    <div className="modal-content p-5">
                        <div className="modal-body">
                            <div className="">
                                <div className="schedule-popup">
                                    <h3 className="mb-4 mt-2 text-center mt-2">
                                        Attendees For {noShowActive !== undefined && `${session_id_beg}${noShowActive.id}${session_id_end}`}
                                    </h3>
                                    <div className="now-show-box-wrapper">
                                        {list.length > 0 && list.map((item) =>
                                            <>
                                                <div className={`profilecard-wrapper justify-content-between pointer w-90`} >
                                                    <div className="d-flex align-items-center">
                                                        <div className='profile-photo'>
                                                            <div >
                                                                <img width="100%" height="100%" src={item.client.profile_image !== null
                                                                    ? `${process.env.REACT_APP_IMAGE_URL}${item.client.profile_image}`
                                                                    : "/images/user-dummy.png"}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='font-size-15 text-left ml-2'>
                                                            {item.client.screen_name}
                                                            <br />
                                                            {item.client.email}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {item.status == 0 || item.status == 1?
                                                            <>
                                                                {noshowFrom === "remove" && <div
                                                                    className="margin-unset register-medium-confirnation  appointment-no-show-card"
                                                                    onClick={() => noshowRemoveGroupAppointment(item)}
                                                                    data-dismiss="modal"
                                                                >
                                                                    Remove
                                                                </div>}

                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                <div
                                                                    className="margin-unset register-medium-confirnation appointment-no-show-card"
                                                                    onClick={() => noShowRequest(item)}
                                                                    data-dismiss="modal"
                                                                >
                                                                    No Show
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                              <span> <b> {item.status == 2 ? 'Marked as No Show' : item.status === 3 ? 'Removed' : ''}</b></span>


                                                            </>
                                                        }
                                                    </div>


                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
