import React, { useState } from "react";
import { setReminderOndemand } from "../../../services/medium";
import { toast } from "react-toastify";
import moment from "moment";
import { useSelector } from "react-redux";

export default function BookAppointmentPopUp({
  directAppMediumInfo,
  booKGroupEvent,
}) {
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const { timezone_name } = useSelector((state) => state.user.user);

  const currentUserDate = (day)=> moment(day,'YYYY-MM-DD').format("dddd MMMM DD, YYYY");
  const { medium_name="" ,fullday="", backupdate=''} = directAppMediumInfo

  return (
    <div>
      <div
        id="BookAppointmentPopUp"
        class="modal fade bd-example-modal-lg mt-5 preview-modal-availability"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        // data-backdrop="static"
      >
        <div class="modal-dialog modal-lg modal-dialog-preview text-center">
          <div class="modal-content p-5 ">
            <div className="cross-icon-popup">
              <img
                onClick={() =>
                  window.loadModal2("#BookAppointmentPopUp", "hide")
                }
                src="localImages/crossicon.png"
              />
            </div>
            <p className="font-size-24 line-height-36px">
              Please confirm if you <br />
              want to book group session with
              <br />
              <b className="text-underline"> {medium_name}&nbsp; </b>
              on
              <b className="text-underline"> {currentUserDate(backupdate)} </b>?
            </p>
            <form className="mt-5" id="msform">
              <div className="mt-2 ml-4 mr-4 d-flex justify-content-center align-items-center choose-medium">
                <button
                  type="button"
                  onClick={() =>
                    window.loadModal2("#BookAppointmentPopUp", "hide")
                  }
                  className="previous action-button-previous float-right width-unset pl-4 pr-4 mr-5 color-d5a67b color-d5a67b-qw"
                >
                  No
                </button>

                <button
                  type="button"
                  onClick={booKGroupEvent}
                  className={`next action-button float-left margin-unset`}
                >
                  {"Yes"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
