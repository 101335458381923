import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirstLetter } from "../../utils/utils";
import { logoutUser } from "../../commonfunctions";
import { Link, useNavigate, useParams } from "react-router-dom";

const Topbar = () => {
  const auth = useSelector((state) => state.user);
  const {
    renderTypeInfo,
    bookingTypeInfo,
  } = useSelector((state) => state.client);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const logout = () => {
    logoutUser(dispatch, navigate);

    // localStorage.clear()
    // window.location.reload();
  };
  const [open, setOpen] = useState(false);
  return (
    <div className="headar-saction">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="logo-saction">
              <a href="javascript:void(0)">
                <img src="../images/logo-verysoul.png" alt="LOGO" />
              </a>
            </div>
          </div>
          <div className="col-md-6">

            {window.location.pathname !== "/terms-conditions" &&
              window.location.pathname !== "/privacy-policy" &&
              window.location.pathname !== `/paymymedium/${params.id}` && window.location.pathname !== `/guest-booking/${params.id}` &&
              window.location.pathname !== '/registration' &&
              (
                <div className="user-saction">
                  {window.location.pathname === `/appointment` && <>
                    {renderTypeInfo === "startBookaSessionLaterQuestions" && bookingTypeInfo === "outreach" && <Link to='/intro-video' >Introductory video </Link>}
                    {renderTypeInfo === "book-specific-professinal" && bookingTypeInfo === "professional" && <Link to='/intro-video'  >Introductory video </Link>}
                  </>}
                  <div className="user-menu-wrap">
                    <div

                      className="mini-photo-wrapper "
                    >
                      <img
                        onClick={() => setOpen(!open)}
                        className="mini-photo pointer"
                        src="../images/profile.png"
                        width="36"
                        height="36"
                        alt="profile.png"
                      />
                      <span className="pointer" onClick={() => setOpen(!open)}>{auth.user.screen_name
                        ? capitalizeFirstLetter(auth.user.screen_name)
                        : capitalizeFirstLetter(auth.user.first_name)}
                      </span>
                      <img
                        onClick={() => setOpen(!open)}
                        src="../images/dropdown.png"
                        className="dropdowan pointer"
                        alt="dropdown.png"
                      />
                    </div>
                    {open && (
                      <div className="menu-container">
                        <ul className="user-menu">
                          {/* <li className="user-menu__item">
                        <a className="user-menu-link" href="#">
                          Achievements
                        </a>
                      </li>
                      <li className="user-menu__item">
                        <a className="user-menu-link" href="#">
                          Team
                        </a>
                      </li>
                      <li className="user-menu__item">
                        <a className="user-menu-link" href="#">
                          Log History
                        </a>
                      </li> */}
                       <li className="user-menu__item">
                          <a
                            className="user-menu-link"
                            href="https://support.verysoul.com/portal/en/newticket"
                            target="_blank"
                          // data-target="#logoutModal"
                          // data-toggle="modal"

                          >
                            Help & Support
                          </a>
                          </li>
                          <li onClick={logout} className="user-menu__item">
                            <a
                              className="user-menu-link"
                              href="javascript:void(0);"
                              style={{ color: "#F44336" }}
                            >
                              Logout
                            </a>
                          </li>
                          {/* <li className="user-menu__item">
                        <a className="user-menu-link" href="#">
                          Settings
                        </a>
                      </li> */}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Topbar;
