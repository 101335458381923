import React from "react";
import Topbar from "../../components/topbar/topbar";
import Footer from "../../components/footer/footer";
import { Link } from "react-router-dom";
import "./terms.css";
export default function TermsAndConditions() {
  return (
    <>
      <Topbar />
      <div>
        <h1 className="single-title page-title entry-title">
          Terms &amp; Conditions
        </h1>
        <div className="terms-container">
          <div className="terms-wrapper">
            <p className="terms-date">Effective February 1, 2022</p>

            <div>
              <h2 className="main-title">Acceptance of the Terms of Use</h2>
              <p className="title-desc mt-30">
                The following terms and conditions (the “
                <strong>Terms of Use</strong>”) are entered into by and between
                you and Very Soul LLC, a Delaware public benefit corporation
                doing business as VerySoul (“<strong>VerySoul</strong>,” “
                <strong>Company</strong>”, “<strong>we</strong>”, or “
                <strong>us</strong>”). These Terms of Use govern your access to
                and use of the VerySoul website, including any content,
                functionality, and services or programs offered on, through, or
                coordinated with VerySoul (collectively, the “
                <strong>Website</strong>”).
              </p>

              <p className="title-desc mt-40">
                Please read the Terms of Use carefully before you start to use
                the Website or participate in any VerySoul programs. By clicking
                to accept or agree to these Terms of Use, you accept and agree
                to be bound and abide by these Terms of Use and our Privacy
                Policy, found at{" "}
                <Link to="/privacy-policy">Verysoul.com/privacy-policy</Link>,
                incorporated herein by reference. Please be aware that these
                Terms of Use contain a binding arbitration clause and className
                action waiver that impact your rights around how disputes
                related to the Website are resolved. If you do not want to agree
                to these Terms of Use or the Privacy Policy, you must not access
                or use the Website.
              </p>
              <p className="title-desc">
                This Website is offered and available to users who are eighteen
                (18) years of age or older. By using this Website, you represent
                and warrant that you are of legal age to form a binding contract
                with VerySoul and meet all of the foregoing eligibility
                requirements. If you do not meet all of these requirements, you
                must not access or use the Website.
              </p>
              <p className="title-desc">
                Subject to your strict compliance with these Terms of Use, we
                hereby grant you a non-exclusive, non-transferable,
                non-sublicensable, limited license to use the Website solely in
                accordance with the Terms of Use. You have no other rights in
                the Website and shall not use the Website for any purposes
                beyond the scope of the access granted in the Terms of Use.
              </p>
            </div>
            <div>
              <h2 className="main-title">Changes to the Terms of Use</h2>
              <p>
                We may revise and update these Terms of Use from time to time in
                our sole discretion. All changes are effective immediately when
                we post them, and apply to all access to and use of the Website
                thereafter. However, any changes to the dispute resolution
                provisions set forth in Governing Law and Jurisdiction will not
                apply to any disputes for which the parties have actual notice
                on or prior to the date the change is posted on the Website.
              </p>
              <p>
                You agree to periodically check this page for any changes to
                these Terms of Use. Continued use of the Website following the
                posting of revised Terms of Use means that you accept and agree
                to the changes and constitutes agreement with the revised Terms
                of Use.
              </p>
            </div>

            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Accessing the Website and Account Security
              </h2>
              <p>
                We reserve the right to withdraw or amend this Website, and any
                service or material we provide on the Website, in our sole
                discretion without notice. We will not be liable if for any
                reason all or any part of the Website is unavailable at any time
                or for any period. From time to time, we may restrict access to
                some parts of the Website, or the entire Website, to users,
                including registered users.
              </p>
              <div className="elementor-widget-container">
                <p>You are responsible for:</p>
                <ul>
                  <li>
                    Making all arrangements necessary for you to have access to
                    the Website.
                  </li>
                  <li>
                    Ensuring that all persons who access the Website through
                    your internet connection are aware of these Terms of Use and
                    comply with them.
                  </li>
                </ul>
              </div>

              <div className="elementor-widget-container">
                <p>
                  To access the Website or some of the resources it offers, you
                  may be asked to provide certain registration details or other
                  information. It is a condition of your use of the Website that
                  all the information you provide on the Website is correct,
                  current, and complete. You agree that all information you
                  provide to register with this Website or otherwise, including
                  but not limited to through the use of any interactive features
                  on the Website, is governed by our Privacy Policy
                  <Link to="/privacy-policy">VerySoul.com/privacy-policy</Link>,
                  and you consent to all actions we take with respect to your
                  information consistent with our Privacy Policy.
                </p>
              </div>
              <div className="elementor-widget-container">
                <p>
                  If you choose, or are provided with, a user name, password, or
                  any other piece of information as part of our security
                  procedures, you must treat such information as confidential,
                  and you must not disclose it to any other person or entity.
                  You also acknowledge that your account is personal to you and
                  agree not to provide any other person with access to this
                  Website or portions of it using your user name, password, or
                  other security information. You agree to notify us immediately
                  of any unauthorized access to or use of your user name or
                  password or any other breach of security. You also agree to
                  ensure that you exit from your account at the end of each
                  session. You should use particular caution when accessing your
                  account from a public or shared computer so that others are
                  not able to view or record your password or other personal
                  information.
                </p>
                <p>
                  We have the right to disable any user name, password, or other
                  identifier, whether chosen by you or provided by us, at any
                  time in our sole discretion for any or no reason, including
                  if, in our opinion, you have violated any provision of these
                  Terms of Use.
                  <span style={{ fontWeight: "400" }}>
                    You may request further information about such actions at
                    <a href="mailto:concerns@verysoul.com">
                      concerns@verysoul.com
                    </a>
                    .
                  </span>
                </p>
              </div>

              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default">
                  Intellectual Property Rights
                </h2>
                <p>
                  The Website and its entire contents, features and
                  functionality (including but not limited to all information,
                  software, text, displays, images, video and audio, and the
                  design, selection and arrangement thereof), are owned by the
                  Company, its licensors, or other providers of such material
                  and are protected by United States and international
                  copyright, trademark, patent, trade secret, and other
                  intellectual property or proprietary rights laws.
                </p>
                <p>
                  You acknowledge and agree that the Website is provided under
                  license, and not sold, to you. You do not acquire any
                  ownership interest in the Website under these Terms of Use, or
                  any other rights thereto, other than to use the same in
                  accordance with the license granted and subject to all terms,
                  conditions, and restrictions under the Terms of Use. We, and
                  our licensors and service providers, reserve and shall retain
                  the entire right, title, and interest in and to the Website
                  and all intellectual property rights arising out of or
                  relating to the Website, except as expressly granted to you in
                  this Agreement.
                </p>
                <p>
                  You must not reproduce, distribute, modify, create derivative
                  works of, publicly display, publicly perform, republish,
                  download, store or transmit any of the material on our
                  Website, except as follows:
                </p>
              </div>
              <div className="elementor-widget-container">
                <ul>
                  <li>
                    Your computer may temporarily store copies of such materials
                    in RAM incidental to your accessing and viewing those
                    materials.
                  </li>

                  <li>
                    You may store files that are automatically cached by your
                    web browser for display enhancement purposes.
                  </li>

                  <li>
                    You may print or download one copy of a reasonable number of
                    pages of the Website for your own personal, non-commercial
                    use and not for further reproduction, publication, or
                    distribution.
                  </li>

                  <li>
                    If we provide desktop, mobile, or other applications for
                    download, you may download a single copy to your computer or
                    mobile device, provided you agree to be bound by our end
                    user license agreement for such applications.
                  </li>

                  <li>
                    If we provide social media features with certain content,
                    you may take such actions as are enabled by such features.
                  </li>
                </ul>
              </div>
              <div className="elementor-widget-container">
                You must not:
                <br />
                <br />
                <ul>
                  <li>
                    Copy the Website or any materials on the Website, except as
                    expressly permitted by the Terms of Use.
                  </li>
                  <li>
                    Modify, translate, adapt, or otherwise create derivative
                    works or improvements, of the Website or any materials on
                    the Website.
                  </li>
                  <li>
                    Remove, delete, obscure, or alter any copyright, trademark,
                    or other proprietary rights notices from copies of materials
                    from this site.
                  </li>
                  <li>
                    Reverse engineer, disassemble, decompile, decode, or
                    otherwise attempt to derive or gain access to the source
                    code of the Website or any part thereof.
                  </li>
                  <li>
                    Rent, lease, lend, sell, sublicense, assign, distribute,
                    publish, transfer, or otherwise make available the Website,
                    or any features or functionality of the Website, to any
                    third party for any reason, including by making the Website
                    available on a network where it is capable of being accessed
                    by more than one device at any time.
                  </li>
                  <li>
                    Remove, disable, circumvent, or otherwise create or
                    implement any workaround to any copy protection, rights
                    management, or security features in or protecting the
                    Website.
                  </li>
                  <li>
                    Use the Website in, or in association with, any hazardous
                    environments or systems.
                  </li>
                </ul>
              </div>
              <p>
                If you print, copy, modify, download, or otherwise use or
                provide any other person with access to any part of the Website
                in breach of the Terms of Use, your right to use the Website
                will cease immediately and you must, at our option, return or
                destroy any copies of the materials you have made. No right,
                title, or interest in or to the Website or any content on the
                Website is transferred to you, and all rights not expressly
                granted are reserved by the Company. Any use of the Website not
                expressly permitted by these Terms of Use is a breach of these
                Terms of Use and may violate copyright, trademark, and other
                laws.
              </p>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-5d88941a elementor-widget elementor-widget-heading"
            data-id="5d88941a"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Trademarks
              </h2>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-fbe6df0 elementor-widget elementor-widget-text-editor"
            data-id="fbe6df0"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                The Company name and all related names, logos, product, and
                service names, designs, and slogans are trademarks of the
                Company or its affiliates or licensors. You must not use such
                marks without the prior written permission of the Company. All
                other names, logos, product and service names, designs, and
                slogans on this Website are the trademarks of their respective
                owners.
              </p>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-6e27131 elementor-widget elementor-widget-heading"
            data-id="6e27131"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Prohibited Uses
              </h2>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-45afc13f elementor-widget elementor-widget-text-editor"
            data-id="45afc13f"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                You may use the Website only for lawful purposes and in
                accordance with these Terms of Use. You agree not to use the
                Website:
              </p>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-1b5443e8 elementor-widget elementor-widget-text-editor"
            data-id="1b5443e8"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <ul>
                <li>
                  In any way that violates any applicable federal, state, local
                  or international law or regulation (including, without
                  limitation, any laws regarding the export of data or software
                  to and from the US or other countries).
                </li>

                <li>
                  For the purpose of exploiting, harming or attempting to
                  exploit or harm minors in any way by exposing them to
                  inappropriate content, asking for personally identifiable
                  information or otherwise.
                </li>

                <li>
                  To send, knowingly receive, upload, download, use or re-use
                  any material which does not comply with the Content Standards
                  set out in these Terms of Use.
                </li>

                <li>
                  To transmit, or procure the sending of, any advertising or
                  promotional material without prior written consent, including
                  any “junk mail,” “chain letter,” or “spam,” or any other
                  similar solicitation.
                </li>

                <li>
                  To impersonate or attempt to impersonate the Company, a
                  Company employee, another user or any other person or entity
                  (including, without limitation, by using e-mail addresses,
                  user names/screen names, or other identifiers associated with
                  users of the Website).
                </li>

                <li>
                  To engage in any other conduct that restricts or inhibits
                  anyone’s use or enjoyment of the Website, or which, as
                  determined by us, may harm the Company or users of the Website
                  or expose them to liability.
                </li>
              </ul>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-48d239eb elementor-widget elementor-widget-text-editor"
            data-id="48d239eb"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>Additionally, you agree not to:</p>
              <br />
              <br />
              <ul>
                <li>
                  Use the Website in any manner that could disable, overburden,
                  damage, or impair the site or interfere with any other party’s
                  use of the Website, including their ability to engage in real
                  time activities through the Website.
                </li>

                <li>
                  Use any robot, spider, or other automatic device, process, or
                  means to access the Website for any purpose, including
                  monitoring or copying any of the material on the Website.
                </li>

                <li>
                  Use any manual process to monitor or copy any of the material
                  on the Website or for any other unauthorized purpose without
                  our prior written consent.
                </li>

                <li>
                  Use any device, software, or routine that interferes with the
                  proper working of the Website.
                </li>

                <li>
                  Introduce any viruses, trojan horses, worms, logic bombs, or
                  other material which is malicious or technologically harmful.
                </li>

                <li>
                  Attempt to gain unauthorized access to, interfere with,
                  damage, or disrupt any parts of the Website, the server on
                  which the Website is stored, or any server, computer, or
                  database connected to the Website.
                </li>

                <li>
                  Attack the Website via a denial-of-service attack or a
                  distributed denial-of-service attack.
                </li>

                <li>
                  Otherwise attempt to interfere with the proper working of the
                  Website.
                </li>
              </ul>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-841a76c elementor-widget elementor-widget-heading"
            data-id="841a76c"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                User Contributions
              </h2>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-c33393 elementor-widget elementor-widget-text-editor"
            data-id="c33393"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                The Website may contain message boards, chat rooms, personal web
                pages or profiles, forums, bulletin boards, and other
                interactive features (collectively, “
                <strong>Interactive Services</strong>”) that allow users to
                post, submit, publish, display or transmit to other users or
                other persons (hereinafter, “<strong>post</strong>”) content or
                materials (collectively, “<strong>User Contributions</strong>”)
                on or through the Website.
              </p>
              <p>
                <span>
                  All User Contributions must comply with the Content Standards
                  set out in these Terms of Use.
                </span>
              </p>
              <p>
                Any User Contribution you post to the site will be considered
                non-confidential and non-proprietary. By providing any User
                Contribution on the Website, you grant us and our affiliates and
                service providers, and each of their and our respective
                licensees, successors and assigns the right to use, reproduce,
                modify, perform, display, distribute, and otherwise disclose to
                third parties any such material according to your account
                settings.
              </p>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-2665fa9 elementor-widget elementor-widget-text-editor"
            data-id="2665fa9"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>You represent and warrant that:</p>
              <ul>
                <li>
                  You own or control all rights in and to the User Contributions
                  and have the right to grant the license granted above to us
                  and our affiliates and service providers, and each of their
                  and our respective licensees, successors and assigns.
                </li>
                <li>
                  All of your User Contributions do and will comply with these
                  Terms of Use.
                </li>
              </ul>
              <p>
                You understand and acknowledge that you are responsible for any
                User Contributions you submit or contribute, and you, not the
                Company, have fully responsibility for such content, including
                its legality, reliability, accuracy and appropriateness.
                <br />
                <br />
              </p>
              <p>
                We are not responsible, or liable to any third party, for the
                content or accuracy of any User Contributions posted by you or
                any other user of the Website.
              </p>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-50dfc654 elementor-widget elementor-widget-heading"
            data-id="50dfc654"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Monitoring and Enforcement; Termination
              </h2>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-2504924b elementor-widget elementor-widget-text-editor"
            data-id="2504924b"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>We have the right to:</p>
              <ul>
                <li>
                  Remove or refuse to post any User Contributions in our sole
                  discretion.
                </li>
                <li>
                  Take any action with respect to any User Contribution that we
                  deem necessary or appropriate in our sole discretion,
                  including if we believe that such User Contribution violates
                  the Terms of Use, including the Content Standards, infringes
                  any intellectual property right or other right of any person
                  or entity, threatens the personal safety of users of the
                  Website or the public, or could create liability for the
                  Company.
                </li>
                <li>
                  Disclose your identity or other information about you to any
                  third party who claims that material posted by you violates
                  their rights, including their intellectual property rights or
                  their right to privacy.
                </li>
                <li>
                  Take appropriate legal action, including without limitation,
                  referral to law enforcement, for any illegal or unauthorized
                  use of the Website.
                </li>
                <li>
                  Terminate or suspend your access to all or part of the Website
                  for any or no reason, including without limitation, any
                  violation of these Terms of Use.
                </li>
              </ul>
              <div>
                <span>
                  <p>
                    You may request further information about such actions at
                    <a href="mailto:concerns@verysoul.com">
                      concerns@verysoul.com
                    </a>
                  </p>
                  .
                </span>
              </div>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-438c9a88 elementor-widget elementor-widget-text-editor"
            data-id="438c9a88"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                Without limiting the foregoing, we have the right to fully
                cooperate with any law enforcement authorities or court order
                requesting or directing us to disclose the identity or other
                information of anyone posting any materials on or through the
                Website. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS
                AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS
                RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES
                DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS
                TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER SUCH PARTIES
                OR LAW ENFORCEMENT AUTHORITIES.
              </p>
              <p>
                However, we do not undertake to review all material before it is
                posted on the Website, and cannot ensure prompt removal of
                objectionable material after it has been posted. Accordingly, we
                assume no liability for any action or inaction regarding
                transmissions, communications, or content provided by any user
                or third party. We have no liability or responsibility to anyone
                for performance or nonperformance of the activities described in
                this section.
              </p>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-76097434 elementor-widget elementor-widget-heading"
            data-id="76097434"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Term and Termination.
              </h2>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-264e7e26 elementor-widget elementor-widget-text-editor"
            data-id="264e7e26"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                <span>
                  &nbsp; &nbsp; &nbsp; a.&nbsp; The term of these Terms of Use
                  commences when you access the Website and will continue in
                  effect until terminated by you or Company as set forth in this
                  Section.
                </span>
              </p>
              <p>
                <span>
                  &nbsp; &nbsp; &nbsp; b.&nbsp; You may terminate the Term of
                  Use by ceasing to use and destroying all copies of the
                  Website.
                </span>
              </p>
              <p>
                <span>
                  &nbsp; &nbsp; &nbsp; c.&nbsp; Company may terminate the Terms
                  of Use at any time without notice. In addition, the Terms of
                  Use will terminate immediately and automatically without any
                  notice if you violate any of the terms and conditions of these
                  Terms of Use.
                </span>
              </p>
              <p>
                <span>&nbsp; &nbsp; &nbsp; d.&nbsp; Upon termination:</span>
              </p>
              <p>
                <span>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; i.&nbsp; &nbsp;all
                  rights granted to you under the Terms of Use will also
                  terminate; and
                </span>
              </p>
              <p>
                <span>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ii.&nbsp; you must
                  cease all use of the Website and delete all copies of the
                  Website.
                </span>
              </p>
              <p>
                <span>
                  &nbsp; &nbsp; &nbsp; e.&nbsp; Termination will not limit any
                  of Company’s rights or remedies at law or in equity.
                </span>
              </p>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-62b3fbc2 elementor-widget elementor-widget-heading"
            data-id="62b3fbc2"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Content Standards
              </h2>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-48f2eb8f elementor-widget elementor-widget-text-editor"
            data-id="48f2eb8f"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                These content standards apply to any and all User Contributions
                and use of Interactive Services. User Contributions must in
                their entirety comply with all applicable federal, state, local,
                and international laws and regulations. Without limiting the
                foregoing, User Contributions must not:
              </p>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-5375458 elementor-widget elementor-widget-text-editor"
            data-id="5375458"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <ul>
                <li>
                  Contain any material which is defamatory, obscene, indecent,
                  abusive, offensive, harassing, violent, hateful, inflammatory,
                  or otherwise objectionable.
                </li>
                <li>
                  Promote sexually explicit or pornographic material, violence,
                  or discrimination based on race, sex, religion, nationality,
                  disability, sexual orientation, or age.
                </li>
                <li>
                  Infringe any patent, trademark, trade secret, copyright, or
                  other intellectual property or other rights of any other
                  person.
                </li>
                <li>
                  Violate the legal rights (including the rights of publicity
                  and privacy) of others or contain any material that could give
                  rise to any civil or criminal liability under applicable laws
                  or regulations or that otherwise may be in conflict with these
                  Terms of Use and our Privacy Policy
                  <Link to="/privacy-policy">VerySoul.com/privacy-policy</Link>.
                </li>
                <li>Be likely to deceive any person.</li>
                <li>
                  Promote any illegal activity, or advocate, promote, or assist
                  any unlawful act.
                </li>
                <li>
                  Cause annoyance, inconvenience, or needless anxiety or be
                  likely to upset, embarrass, alarm, or annoy any other person.
                </li>
                <li>
                  Impersonate any person or misrepresent your identity or
                  affiliation with any person or organization.
                </li>
                <li>
                  Give the impression that they emanate from or are endorsed by
                  us or any other person or entity, if this is not the case.
                </li>
              </ul>
              <p>
                Any violation of these content standards will also be deemed a
                violation of these Terms of Service.
              </p>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-79d2394c elementor-widget elementor-widget-heading"
            data-id="79d2394c"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Copyright Infringement
              </h2>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-531410c8 elementor-widget elementor-widget-text-editor"
            data-id="531410c8"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                If you believe that any User Contributions violate your
                copyright, please reach out to
                <span style={{ textDecoration: "underline" }}>
                  <a href="mailto:concerns@verysoul.com">
                    concerns@verysoul.com
                  </a>
                </span>
                sending us a notice of copyright infringement. It is the policy
                of the Company to terminate the user accounts of repeat
                infringers.
              </p>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-19701cc2 elementor-widget elementor-widget-heading"
            data-id="19701cc2"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Reliance on Information Posted
              </h2>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-17059fe0 elementor-widget elementor-widget-text-editor"
            data-id="17059fe0"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                <span>
                  The information presented on or through the Website is made
                  available solely for general information purposes. We do not
                  warrant the accuracy, completeness or usefulness of this
                  information. Any reliance you place on such information is
                  strictly at your own risk. We disclaim all liability and
                  responsibility arising from any reliance placed on such
                  materials by you or any other visitor to the Website, or by
                  anyone who may be informed of any of its contents.
                </span>
              </p>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-61bc050b elementor-widget elementor-widget-text-editor"
            data-id="61bc050b"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                This Website includes content provided by third parties,
                including materials provided by other users, bloggers, and
                third-party licensors, syndicators, aggregators, and/or
                reporting services. All statements and/or opinions expressed in
                these materials, and all articles and responses to questions and
                other content, other than the content provided by the Company,
                are solely the opinions and the responsibility of the person or
                entity providing those materials. These materials do not
                necessarily reflect the opinion of the Company.
              </p>
              <p>
                You acknowledge and agree that Company is not responsible for
                Third-Party Materials, including their accuracy, completeness,
                timeliness, validity, copyright compliance, legality, decency,
                quality, or any other aspect thereof. Company does not assume
                and will not have any liability or responsibility to you or any
                other person or entity for any content provided by third
                parties. Content provided by third parties and links to that
                content are provided solely as a convenience to you, and you
                access and use them entirely at your own risk and subject to
                such third parties’ terms and conditions.
              </p>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-17993502 elementor-widget elementor-widget-heading"
            data-id="17993502"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Changes to the Website
              </h2>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-39767f62 elementor-widget elementor-widget-text-editor"
            data-id="39767f62"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                We may update the content on this Website from time to time, but
                its content is not necessarily complete or up-to-date. Any of
                the material on the Website may be out of date at any given
                time, and we are under no obligation to update such material.
              </p>
              <p>
                Company may from time to time in its sole discretion develop and
                provide Website updates, which may include upgrades, bug fixes,
                patches, other error corrections, and/or new features
                (collectively, including related documentation, “
                <strong>Updates</strong>”). Updates may also modify or delete in
                their entirety certain features and functionality. You agree
                that Company has no obligation to provide any Updates or to
                continue to provide or enable any particular features or
                functionality.
              </p>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-21262ef0 elementor-widget elementor-widget-heading"
            data-id="21262ef0"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Information About You and Your Visits to the Website
              </h2>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-45de267d elementor-widget elementor-widget-text-editor"
            data-id="45de267d"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                All information we collect on this Website is subject to our
                Privacy Policy
                <Link to="/privacy-policy">
                  <span>VerySoul.com/privacy-policy</span>
                </Link>
                . By using the Website, you consent to all actions taken by us
                with respect to your information in compliance with the Privacy
                Policy.
              </p>
              <p>
                You acknowledge that when you access or use the Website, Company
                may use automatic means (including, for example, cookies and web
                beacons) to collect information about the device(s) you use to
                access the Website and about your use of the Website. You also
                may be required to provide certain information about yourself as
                a condition to using the Website or certain of its features or
                functionality, and the Website may provide you with
                opportunities to share information about yourself with others.
                All information we collect through or in connection with this
                Website is subject to our Privacy Policy
                <Link to="/privacy-policy">
                  <span>VerySoul.com/privacy-policy</span>
                </Link>
                . By using and providing information to or through this Website,
                you consent to all actions taken by us with respect to your
                information in compliance with the Privacy Policy.
              </p>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-3479ff58 elementor-widget elementor-widget-heading"
            data-id="3479ff58"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Linking to the Website and Social Media Features
              </h2>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-4f9b541d elementor-widget elementor-widget-text-editor"
            data-id="4f9b541d"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                You may link to our homepage, provided you do so in a way that
                is fair and legal and does not damage our reputation or take
                advantage of it, but you must not establish a link in such a way
                as to suggest any form of association, approval, or endorsement
                on our part without our express written consent.
                <br />
                This Website may provide certain social media features that
                enable you to:
                <br />
                <br />
              </p>
              <ul>
                <li>
                  Link from your own or certain third-party websites to certain
                  content on this Website.
                </li>

                <li>
                  Send e-mails or other communications with certain content, or
                  links to certain content, on this Website.
                </li>

                <li>
                  Cause limited portions of content on this Website to be
                  displayed or appear to be displayed on your own or certain
                  third-party websites.
                </li>
              </ul>
              <p>
              You may use these features solely as they are provided by us, and
              solely with respect to the content they are displayed with and
              otherwise in accordance with any additional terms and conditions
              we provide with respect to such features. Subject to the
              foregoing, you must not:
              </p>
              <br />
              <br />
              <ul>
                <li>
                  Establish a link from any website that is not owned by you.
                </li>

                <li>
                  Cause the Website or portions of it to be displayed, or appear
                  to be displayed by, for example, framing, deep linking or
                  in-line linking, on any other site.
                </li>

                <li>
                  Link to any part of the Website other than the homepage.
                </li>

                <li>
                  Otherwise take any action with respect to the materials on
                  this Website that is inconsistent with any other provision of
                  these Terms of Use.
                </li>
              </ul>
              <p>
                The website from which you are linking, or on which you make
                certain content accessible, must comply in all respects with the
                Content Standards set out in these Terms of Use.
                <br />
                <br />
                You agree to cooperate with us in causing any unauthorized
                framing or linking immediately to cease. We reserve the right to
                withdraw linking permission without notice.
                <br />
                <br />
                We may disable all or any social media features and any links at
                any time without notice in our discretion.
              </p>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-fcf744f elementor-widget elementor-widget-heading"
            data-id="fcf744f"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Links from the Website
              </h2>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-6d93a061 elementor-widget elementor-widget-text-editor"
            data-id="6d93a061"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                If the Website contains links to other sites and resources
                provided by third parties, these links are provided for your
                convenience only. This includes links contained in
                advertisements, including banner advertisements and sponsored
                links. We have no control over the contents of those sites or
                resources, and accept no responsibility for them or for any loss
                or damage that may arise from your use of them. If you decide to
                access any of the third-party websites linked to this Website,
                you do so entirely at your own risk and subject to the terms and
                conditions of use for such websites, which we encourage you to
                review.
              </p>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-594af1e1 elementor-widget elementor-widget-heading"
            data-id="594af1e1"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Geographic Restrictions
              </h2>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-21166082 elementor-widget elementor-widget-text-editor"
            data-id="21166082"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                The owner of the Website is based in the state of North Carolina
                in the United States. We make no claims that the Website or any
                of its content is accessible or appropriate outside of the
                United States. Access to the Website may not be legal by certain
                persons or in certain countries. If you access the Website from
                outside the United States, you do so on your own initiative and
                are responsible for compliance with local laws.
              </p>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-28f4eea0 elementor-widget elementor-widget-heading"
            data-id="28f4eea0"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Disclaimer of Warranties
              </h2>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-7c0c30f4 elementor-widget elementor-widget-text-editor"
            data-id="7c0c30f4"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                You understand that we cannot and do not guarantee or warrant
                that files available for downloading from the internet or the
                Website will be free of viruses or other destructive code. You
                are responsible for implementing sufficient procedures and
                checkpoints to satisfy your particular requirements for
                anti-virus protection and accuracy of data input and output, and
                for maintaining a means external to our site for any
                reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY
                LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK,
                VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY
                INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR
                OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY
                SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR
                DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE
                LINKED TO IT.
              </p>
              <p>
                YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS
                OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE,
                ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE
                WEBSITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS,
                WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
                NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY
                MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
                COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
                AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING,
                NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY
                REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT, OR ANY
                SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE,
                RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE
                CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE
                ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE
                WEBSITE, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE
                WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
              </p>
              <p>
                THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
                EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT
                LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT
                AND FITNESS FOR PARTICULAR PURPOSE.
              </p>
              <p>
                THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
                EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
              </p>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-61d214d0 elementor-widget elementor-widget-heading"
            data-id="61d214d0"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Limitation on Liability
              </h2>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-795c0c66 elementor-widget elementor-widget-text-editor"
            data-id="795c0c66"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, OR THEIR
                LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR
                DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL
                THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR
                INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY
                CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR
                ITEMS OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES,
                INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
                CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED
                TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS,
                LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR
                ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF
                DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH
                OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.
              </p>
              <p>
                THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE
                EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
              </p>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-2b4b7ba7 elementor-widget elementor-widget-heading"
            data-id="2b4b7ba7"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Indemnification
              </h2>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-3bbecb39 elementor-widget elementor-widget-text-editor"
            data-id="3bbecb39"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                You agree to defend, indemnify, and hold harmless the Company,
                its affiliates, licensors and service providers, and its and
                their respective officers, directors, employees, contractors,
                agents, licensors, suppliers, successors and assigns from and
                against any claims, liabilities, damages, judgments, awards,
                losses, costs, expenses or fees (including reasonable attorneys’
                fees) arising out of or relating to your violation of these
                Terms of Use or your use of the Website, including, but not
                limited to, your User Contributions, any use of the Website’s
                content, services and products other than as expressly
                authorized in these Terms of Use or your use of any information
                obtained from the Website.
              </p>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-222e3cce elementor-widget elementor-widget-heading"
            data-id="222e3cce"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Governing Law and Jurisdiction
              </h2>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-2b70cba2 elementor-widget elementor-widget-text-editor"
            data-id="2b70cba2"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                All matters relating to the Website and these Terms of Use and
                any dispute or claim arising therefrom or related thereto (in
                each case, including non-contractual disputes or claims), shall
                be governed by and construed in accordance with the internal
                laws of the State of <mark>North Carolina</mark> without giving
                effect to any choice or conflict of law provision or rule
                (whether of the State of <mark>North Carolina</mark> or any
                other jurisdiction). Any legal suit, action or proceeding
                arising out of, or related to, these Terms of Use or the Website
                shall be instituted exclusively in the federal courts of the
                United States or the courts of the State of{" "}
                <mark>North Carolina</mark> in each case located in the City of{" "}
                <mark>Charlotte</mark> and County of
                <mark>Mecklenburg</mark> although we retain the right to bring
                any suit, action or proceeding against you for breach of these
                Terms of Use in your country of residence or any other relevant
                country. You waive any and all objections to the exercise of
                jurisdiction over you by such courts and to venue in such
                courts.
              </p>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-7096c2da elementor-widget elementor-widget-heading"
            data-id="7096c2da"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Dispute Resolution, Arbitration, and Class Action Waiver
              </h2>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-798370b elementor-widget elementor-widget-text-editor"
            data-id="798370b"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                At Company’s sole discretion, it may require You to submit any
                disputes arising from the use of these Terms of Use or the
                Website, including disputes arising from or concerning their
                interpretation, violation, invalidity, non-performance, or
                termination, to final and binding arbitration under the Rules of
                Arbitration of the American Arbitration Association applying
                North Carolina law.
              </p>
              <p>
                Arbitration will be conducted on an individual basis only. The
                parties expressly waive any right to bring any proceeding
                (whether an action, lawsuit, a proceeding as a className or
                collective action, a private attorney general action, or any
                other proceeding), and agree that the arbitrator will not
                consolidate or preside over any proceeding or claim in which any
                party acts or proposes to act in a representative capacity.
              </p>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-598be347 elementor-widget elementor-widget-heading"
            data-id="598be347"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Limitation on Time to File Claims
              </h2>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-75d48bd2 elementor-widget elementor-widget-text-editor"
            data-id="75d48bd2"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR
                RELATING TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED
                WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES,
                OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
              </p>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-6838a663 elementor-widget elementor-widget-heading"
            data-id="6838a663"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Export Regulation
              </h2>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-4cbc1105 elementor-widget elementor-widget-text-editor"
            data-id="4cbc1105"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                The Website may be subject to United States export control laws,
                including the Export Control Reform Act and its associated
                regulations. You shall not, directly or indirectly, export,
                re-export, or release the Website to, or make the Website
                accessible from, any jurisdiction or country to which export,
                re-export, or release is prohibited by law, rule, or regulation.
                You shall comply with all applicable federal laws, regulations,
                and rules, and complete all required undertakings (including
                obtaining any necessary export&nbsp;license&nbsp;or other
                governmental approval), prior to exporting, re-exporting,
                releasing, or otherwise making the Website available outside the
                US.
              </p>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-3f506353 elementor-widget elementor-widget-heading"
            data-id="3f506353"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Waiver and Severability
              </h2>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-75e1d79c elementor-widget elementor-widget-text-editor"
            data-id="75e1d79c"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                No waiver by the Company of any term or condition set forth in
                these Terms of Use shall be deemed a further or continuing
                waiver of such term or condition or a waiver of any other term
                or condition, and any failure of the Company to assert a right
                or provision under these Terms of Use shall not constitute a
                waiver of such right or provision.
              </p>
              <p>
                If any provision of these Terms of Use is held by a court or
                other tribunal of competent jurisdiction to be invalid, illegal
                or unenforceable for any reason, such provision shall be
                eliminated or limited to the minimum extent such that the
                remaining provisions of the Terms of Use will continue in full
                force and effect.
              </p>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-8a36e3f elementor-widget elementor-widget-heading"
            data-id="8a36e3f"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Entire Agreement
              </h2>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-4a455f34 elementor-widget elementor-widget-text-editor"
            data-id="4a455f34"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                The Terms of Use and our Privacy Policy constitute the sole and
                entire agreement between you and Company with respect to the
                Website and supersede all prior and contemporaneous
                understandings, agreements, representations and warranties, both
                written and oral, with respect to the Website.
              </p>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-663259ba elementor-widget elementor-widget-heading"
            data-id="663259ba"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Your Comments and Concerns
              </h2>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-3f47dda4 elementor-widget elementor-widget-text-editor"
            data-id="3f47dda4"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                This website is operated by <mark>Very Soul LLC</mark>, located
                in Charlotte, North Carolina and having a mailing address of
                1213 W Morehead St., STE 500 #163, Charlotte, NC&nbsp;28208.
              </p>
              <p>
                All notices of copyright infringement claims should be sent to
                <span style={{ textDecoration: "underline" }}>
                  <strong>
                    <a href="mailto:concerns@verysoul.com">
                      concerns@verysoul.com
                    </a>
                  </strong>
                </span>
                , as indicated above in the Terms of Use.
              </p>
              <p>
                All other feedback, comments, requests for technical support and
                other communications relating to the Website should be directed
                to:
                <strong>
                  <span style={{ textDecoration: "underline" }}>
                    <a href="mailto:support@verysoul.com">
                      support@verysoul.com.
                    </a>
                  </span>
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
