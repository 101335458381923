import React, { useState } from "react";
import "./bookingperiodbar.css";

export default function BookingPeriodBar({ values }) {
    const {
        min_booking_days,
        min_booking_hour,
        max_booking_days,
        min_booking_time,
    } = values;

    //states
    const [open, setOpen] = useState(false);

    const calculateMin = (
        min_booking_days,
        min_booking_hour,
        min_booking_time
    ) => {
        // console.log(min_booking_days, min_booking_hour, min_booking_time,"min_booking_days, min_booking_hour, min_booking_time")
        let day = min_booking_days !== "" ? min_booking_days : 0;
        let hour = min_booking_hour !== "" ? min_booking_hour : 0;
        let min = min_booking_time !== "" ? min_booking_time : 0;

        let time = parseInt(day) + parseInt(hour) / 24 + parseInt(min) / 1440;
        return time !== NaN ? time : "";
    };
    const calculateMax = (
        max_booking_days,
        min_booking_days,
        min_booking_hour
    ) => {
        let days = max_booking_days !== "" ? max_booking_days : 0;
        let final_time = parseInt(days) - parseFloat(minTime);
        return final_time != NaN || final_time !== "" ? final_time : "";
    };
    function roundToTwo(num) {
        return +(Math.round(num + "e+2") + "e-2");
    }

    const minTime = calculateMin(
        min_booking_days,
        min_booking_hour,
        min_booking_time
    );
    const maxTime = calculateMax(
        max_booking_days,
        min_booking_days,
        min_booking_hour
    );
    return (
        <>
            {/* <div className="bar-wrapper">
                <button
                    className="bookingperiodbar-btn"
                    onClick={() => setOpen(!open)}
                    type="button"
                >
                    {" "}
                    See results of min/max choices
                </button>
                <p className="red margin-unset font-size-15">
                Note: Min/Max Booking Times will be reflected in availability precisely (minute) in the booking views, but only approximately (day) in My Sessions and your Google Calendar.
                </p>
            </div>
            {open && ( */}
                <div className="bookingperiodbar-wrapper">
                    {/* {console.log(values, "values")} */}
                    <div className="bookingperiodbar-container">
                        <div className="div-minimum-time">
                            <div className="div-minimum-time-container">
                                {minTime !== "" ? `${roundToTwo(minTime)} Days` : ""}
                            </div>
                        </div>
                        <div className="div-maximum-time">
                            <div className="div-maximum-time-container">
                                {maxTime !== "" ? `${roundToTwo(maxTime)} Days` : ""}
                            </div>
                        </div>
                    </div>
                    <div className="mt-2">*Before other date restrictions applied</div>
                </div>
            {/* )} */}
        </>
    );
}
