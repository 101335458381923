import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: null,
  isLoading: false,
  isLoadingText: false,
  auth: !localStorage.getItem("access_token"),
  role: "",
  appointment: "",
  activeRouteName: "Dashboard",
  loadingContent:"",
};
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.auth = !localStorage.getItem("access_token");
    },
    logOut: (state, action) => {
      state.auth = !localStorage.getItem("access_token");
      state.token = null;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setLoadingText: (state, action) => {
      state.isLoadingText = action.payload;
    },
    setLoadingContent: (state, action) => {
      state.loadingContent = action.payload;
    },
    setUpAuth: (state) => {
      state.auth = false;
    },
    setUpAuthTrue: (state) => {
      state.auth = true;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setAppointment: (state, action) => {
      state.appointment = action.payload;
    },
    setActiveRouteName: (state, action) => {
      state.activeRouteName = action.payload;
    },
  },
});

export const {
  setCredentials,
  logOut,
  setUpAuth,
  setRole,
  setLoading,
  setAppointment,
  setActiveRouteName,
  setUpAuthTrue,
  setLoadingText,
  setLoadingContent
} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth;
