import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  mediumdata: ""
};
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    changeOnlineStatus: (state, action) => {
      state.user = {
        ...state.user,
        ...(state.user.is_available = action.payload),
      };
    },
    changeOnbarding: (state, action) => {
    
      state.user = {
        ...state.user,
        ...(state.user.step_progress = action.payload),
      };
    },
    setMediumData: (state, action) => {
      state.mediumdata = action.payload;
    },
    setDemandstatus: (state, action) => {
      state.user.is_available = action.payload;
    },
    setProfileImage: (state, action) => {
      state.user.profile_image = action.payload;
    },
    updateUser: (state, action) => {
      state.user = {
        ...state.user,
        ...(state.user.medium_status = 1),
      };
    },
    unLinkUserZoom: (state, action) => {
      state.user = {
        ...state.user,
        ...(state.user.provider = ""),
      };
    },
    unLinkUserCalendar: (state, action) => {
      state.user = {
        ...state.user,
        ...(state.user.google_calendar = ""),
      };
    },
    setNewTimeZoneData: (state, action) => {
      state.user.timezone = action.payload
    },
    setNewScreenName: (state, action) => {
      state.user.screen_name = action.payload
    },
    updateGoogleSync: (state, action) => {
      state.user = {
        ...state.user,
        ...(state.user.google_availability_sync = action.payload),
      };
    },
    setSaveTermsHanlder: (state, action) => {
      state.user.terms_session_status = 1
    },
    setSaveTermsHanlderStarter: (state, action) => {
      state.user.terms_session_starter_status = 1
    },
    setIsOnDemandBox: (state, action) => {
      state.user.is_on_demand_box = 1
    },
  },
});

export const {
  setUser,
  setMediumData,
  setDemandstatus,
  changeOnlineStatus,
  updateUser,
  unLinkUserZoom,
  unLinkUserCalendar,
  setProfileImage,
  setNewTimeZoneData,
  updateGoogleSync,
  setNewScreenName,
  setSaveTermsHanlder,
  setSaveTermsHanlderStarter,
  changeOnbarding,
  setIsOnDemandBox
} = userSlice.actions;

export default userSlice.reducer;

export const CurrentUser = (state) => state.user;
