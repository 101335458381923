import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

export default function ConfirAdvanceModal() {
  const { bookedAppointmentData } = useSelector((state) => state.client);
  const { user } = useSelector((state) => state.user);
  const [userDetails, setUserDetails] = useState({ name: "" });
  const [slotDetails, setSlotDetails] = useState({});
  useEffect(() => {
    localStorage.removeItem("offset");
    // let user = JSON.parse(localStorage.getItem("user"));
    setUserDetails(user);
  }, [user]);

  useEffect(() => {
    setSlotDetails(bookedAppointmentData);
  }, [bookedAppointmentData]);
  //hooks
  const checTypeOfBooking = () => {
    let profType = localStorage.getItem('profType')
    let info = localStorage.getItem('bookingType');
    return info == "outreach" || profType == "after" ? true : false
  }
  return (
    <div
      className="modal fade "
      id="advanceConfirmbooking"
      data-backdrop="static"
      role="dialog"
    >
      <div className="modal-dialog advanceConfirmbooking  modal-dialog-preview height-unset">
        <div className="modal-content">
          {/* <button
                type="button"
                class="close about-us-review-close  calendar-view-creoss-icin"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="cross-preview-box" aria-hidden="true">&times;</span>
              </button> */}
          <div className="modal-body">
            <div className="schedule-popup">
              <img src="../images/new-cll.png" alt="user" />
              {checTypeOfBooking() ?
                <>
                  <h5>
                    Appointment Reserved
                    {/* {userDetails && userDetails.first_name}, your appointment is
                    confirmed */}
                  </h5>
                  {/* <div className="font-size-17">Check your email now for meeting link, may be in spam/junk.</div> */}
                </>
                :
                <h5>
                  {userDetails && userDetails.first_name !== undefined ? userDetails.first_name : slotDetails && slotDetails.client_name}, your appointment is
                  confirmed
                </h5>
              }
              <span>

                {slotDetails &&
                  moment(
                    `${slotDetails.date} ${slotDetails.time}`,
                    "YYYY-MM-DD hh:mm A"
                  ).format("hh:mm A")}{" "}
                with {slotDetails && slotDetails.medium_name}
              </span>
              <p className="font-weight-bolder font-size-22">
                {slotDetails && slotDetails.day_of_week},&nbsp;{slotDetails &&
                  moment(`${slotDetails.date}`, "YYYY-MM-DD hh:mm A").format(
                    "LL"
                  )}{" "}
              </p>
              {/* <div>

                <a className="advance-tag-my-calendar"
                  target="_blank"
                  href={slotDetails && slotDetails.calendarLink}
                >
                  Add to my calendar
                </a>
              </div> */}
              {checTypeOfBooking() ?
                <div className="advance-notice">
                  <h5>Important:</h5>
                  <p className="line-height-24px d-flex ">
                    <span className="mr-1">1)</span> <p>Check your email now for meeting link, It may be in spam/junk.
                    </p>
                  </p>
                  <p className="line-height-24px d-flex">
                    <span className="mr-1">2)</span> <p> Add this appointment to your personal calendar.
                    </p>
                  </p>
                  <p className="line-height-24px d-flex">
                    <span className="mr-1">3)</span> <p> Ensure your device has the Zoom app downloaded.
                    </p>
                  </p>
                  <p className="line-height-24px d-flex">
                    <span className="mr-1">4)</span> <p>You will receive an email reminder 24 hours before your
                      session requiring confirmation.
                    </p>
                  </p>
                  {/* <br />    <br /> */}
                  <p className="line-height-24px d-flex pl-3 pr-3">
                    {/* <span className="mr-1">5)</span> */}
                     <p>If you have not{" "}
                      <span className="text-underline d-inline">
                        confirmed at least 2 hours prior to your session
                      </span>
                      , your appointment will be cancelled.
                    </p>
                  </p>
                  {/* <br />    <br /> */}
                  <p className="line-height-24px d-flex pl-3 pr-3" >
                    {/* <span className="mr-1">4)
                      </span> */}
                       <p> This confirmation helps us offer free sessions while
                      ensuring good use of time donated by our Mediums.
                    </p>
                  </p>
                </div>
                : ''
              }
              {
                localStorage.getItem('bookingType') === undefined || localStorage.getItem('bookingType') === null ?
                  <a
                    className=" advance-tag-margin advance-tag-my-calendar"

                    href={'/login'}
                  >
                    Continue To Login
                  </a>
                  :
                  <a
                    className=" advance-tag-margin advance-tag-my-calendar"
                    onClick={() => {
                      localStorage.removeItem("offset");
                      localStorage.removeItem('bookingType');
                      localStorage.removeItem('profType');
                      localStorage.removeItem('type');
                    }}
                    href={checTypeOfBooking() ? "/shortcuts" : "/appointments"}
                  >
                    {checTypeOfBooking() ? 'I Understand' : 'My Appointments'}
                  </a>
              }
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}
