import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "../../assets/css/sb-admin-2.min.css";
import { logoutUser } from "../../commonfunctions";

export default function Header({ ToggleSideBar }) {
  const auth = useSelector((state) => state.user);
  const { activeRouteName } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const clicKHandler = () => {
    // setOpen(!open);
  };

  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
      <button
        onClick={ToggleSideBar}
        id="sidebarToggleTop"
        className="btn btn-link d-md-none rounded-circle mr-3"
      >
        <img src="images/menu.png" alt="menu" />
      </button>
      <div className="title-head">
        <h2>{activeRouteName}</h2>
      </div>
      <ul className="navbar-nav ml-auto top-nav">
        <li className="nav-item dropdown no-arrow d-sm-none">
          <Link
            className="nav-link dropdown-toggle"
            to="/"
            id="searchDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="fas fa-search fa-fw"></i>
          </Link>
          <div
            className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
            aria-labelledby="searchDropdown"
          >
            <form className="form-inline mr-auto w-100 navbar-search">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control bg-light border-0 small"
                  placeholder="Search for..."
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                />
                <div className="input-group-append">
                  <button className="btn btn-primary" type="button">
                    <i className="fas fa-search fa-sm"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </li>
        <li className="how">
          {/* <Link to="/">
            <img src="images/q-tow.png" alt="" />
            How it works?
          </Link> */}
        </li>
        <li className="bell-opction">
          {/* <Link to="/">
            <img src="images/bell-ring.png" alt="" />
          </Link> */}
        </li>
        <li onClick={clicKHandler} className="nav-item dropdown no-arrow">
          <Link
            className="nav-link dropdown-toggle"
            to="#"
            id="userDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            
          >
            <img
              className="img-profile rounded-circle"
              src="/images/undraw_profile.svg"
              alt="undraw_profile.svg"
            />
            <span className="mr-2  d-lg-inline text-gray-600 small">
              <span>
                {auth.user.screen_name
                  ? auth.user.screen_name
                  : `${auth.user && auth.user.first_name} ${auth.user &&
                  auth.user.last_name}`}
              </span>
              &nbsp;{" "}
            </span>
            <img
              className="img-erow rounded-circle"
              src="/images/dropdown.png"
              alt="dropdown.png"
            />
          </Link>
          {/* {open && ( */}
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              {/* <Link className="dropdown-item" to="/">
                Profile
              </Link>
              <Link className="dropdown-item" to="/">
                Settings
              </Link>
              <Link className="dropdown-item" to="/">
                Activity Log{" "}
              </Link> */}
              <a
                className="dropdown-item"
                href="https://support.verysoul.com/portal/en/newticket"
                target="_blank"
                // data-target="#logoutModal"
                // data-toggle="modal"
               
              >
              Help & Support
              </a>
              <Link
                className="dropdown-item"
                to="#"
                data-toggle="modal"
                // data-target="#logoutModal"
                onClick={() => logoutUser(dispatch, navigate)}
              >
                Logout
              </Link>
            </div>
          {/* )} */}
        </li>
      </ul>
    </nav>
  );
}
