import React, { useEffect, useState } from "react";
import TabItem from "./tabItem";
export default function Tabs({ active, list, setActiveTab }) {
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    fillTabs();
  }, []);
  useEffect(() => {
    fillTabs();
  }, [list]);

  const fillTabs = () => {
    setTabs(
      list.map((item, index) => {
        if (active ? parseInt(index) === parseInt(active) : parseInt(index) === 0) {
          return {
            ...item,
            active: true,
          };
        } else {
          return {
            ...item,
            active: false,
          };
        }
      })
    );
  };

  const activeTabHandler = (tabIndex) => {
    setActiveTab(tabIndex);
    setTabs(
      list.map((item, index) => {
        if (parseInt(tabIndex) === parseInt(index)) {
          return {
            ...item,
            active: true,
          };
        } else {
          return {
            ...item,
            active: false,
          };
        }
      })
    );
  };
  return (
    <>
      <ul className="tabs">
        {React.Children.toArray(
          tabs.map((tab, index) => (
            <TabItem
              tab={tab}
              index={index}
              activeTabHandler={activeTabHandler}
            />
          ))
        )}
      </ul>
    </>
  );
}
