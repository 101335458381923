import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { getTimezones } from "../../services/uidata";
import { filterVisibleTimeZones } from "../../utils/helpers";

//css
import "../checkTimeZone/checktimezone.css";
import { updateBannerStaus } from "../../services/admin";


export default function AlertPopUp() {
    //states
    // const navigate = useNavigate();
    const dispatch = useDispatch()
    const { banner_text, id } = useSelector(
        (state) => state.user.user
    );

    const [newTimezoneData, setnewTimezoneData] = useState([]);
    const [value, setValue] = useState("");
    const [error, setError] = useState(false);


    const closePopup = () => {
        let body = {
            user_id: id
        }
        updateBannerStaus(body).then(() => {
            window.loadModal2("#alertPopup", "hide");
        }).catch(() => {

        })
    }
    return (
        <div
            className="modal fade mt-5 "
            id="alertPopup"
            data-backdrop="static"
            role="dialog"
        >
            <div className="modal-dialog modal-dialog-alert-box">
                <div className="modal-content">
                    <div className="modal-body mb-4">
                        <div className="schedule-popup">
                            <div className="d-flex align-items-center justify-content-between">

                                <span className="d-flex align-items-center margin-unset">
                                    <img className='w-50-px' src="./images/error-mark.png" />
                                    <span className="font-size-28 ml-1 ">Info</span>

                                </span>
                                <span className="pointer align-self-baseline" onClick={closePopup}>

                                    X
                                </span>
                            </div>


                            {/* <h3 className="text-center ">
                                <img className='w-70-px' src="./images/error-mark.png" />
                            </h3> */}
                            <div className="mb-2 mt-2">
                                <div className="cuntrey-website p-2 line-height-30px font-size-22 ">
                                    {banner_text}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
