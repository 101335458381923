import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import Select from "react-select";
import {
  setMediumOnlineStatus,
  getMediumAppointments,
  checkZoomConnectionStatus,
} from "../../../services/medium";
import {
  setGoOndemandStatus,
  setaAppointmentsList,
} from "../../../reducer/mediumSlice";
import { setDemandstatus } from "../../../reducer/userSlice";
import { setLoading } from "../../../reducer/auth/auth";
import Button from "../../../components/button";
import moment from "moment";
// import { getMettingLink } from "../../../services/uidata";
import { getLink } from "../../../utils/helpers";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// //components
// import Spinner from "../../../components/spinner";

export default function GoOnDemand() {
  const navigate = useNavigate();
  const { appointmentsList } = useSelector((state) => state.medium);
  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();
  const { goOndemandStatus, matchingStarted } = useSelector(
    (state) => state.medium
  );
  const [onlineStatus, setOnlineStatus] = useState(null);
  const [defaultVal, setDefaultVal] = useState();
  const [appoinmentsData, setAppoinmentsData] = useState([]);
  const [checkingConnection, setCheckingConnection] = useState(false);

  useEffect(() => {
    setAppoinmentsData(appointmentsList);
  }, [appointmentsList]);

  useEffect(() => {
    if (appointmentsList.length === 0) getAppointments();
    let session = JSON.parse(localStorage.getItem("desired_session"));
    if (session !== null && session.length > 0) setDefaultVal(session);
    setDefaultVal(desiredOptions[0]);
  }, []);

  useEffect(() => {
    dispatch(
      setGoOndemandStatus(parseInt(user.is_available) === 1 ? true : false)
    );
  }, [user]);

  useEffect(() => {
    setOnlineStatus(goOndemandStatus);
  }, [goOndemandStatus]);
  const getAppointments = () => {
    dispatch(setLoading(true));
    getMediumAppointments()
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          dispatch(setaAppointmentsList(data.data.data));
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
  // const statusChangeHandler = (e) => {
  //   console.log(e.target.checked);
  //   setOnlineStatus(e.target.checked);
  //   changeStatus(e.target.checked);
  // };
  const changeStatus = (status) => {
    let body = {
      status: !onlineStatus ? 1 : 0,
    };

    setMediumOnlineStatus(body)
      .then((data) => {
        if (data.data.status) {
          setOnlineStatus(status);
          dispatch(setDemandstatus(status ? 1 : 0));
          dispatch(setGoOndemandStatus(status));
        }
      })
      .catch(() => {});
  };
  const desiredOptions = [
    { id: 1, label: "30 min", value: "30 min" },
    { id: 2, label: "45 min", value: "45 min" },
  ];
  const handleChange = (e) => {
    setDefaultVal(e);
    let data = JSON.stringify(e);

    localStorage.setItem("desired_session", data);
  };
  // const copyMeetingLink = (url) => {
  //   navigator.clipboard.writeText(url);
  // };
  const handleChangeActiveStatus = (e) => {
    let status = parseInt(e.target.value) === 1 ? true : false;
    if (status) {
      setCheckingConnection(true);
      checKConnection(status);
    } else {
      changeStatus(status);
    }
  };

  const checKConnection = (status) => {
    const test = false;
    checkZoomConnectionStatus()
      .then((data) => {
        if (data.data.status) {
          changeStatus(status);
          setCheckingConnection(false);
          toast.success("Connection Established Successfully", {
            position: "top-center",
          });
        } else if (!data.data.status) {
          toast.error("Error Connecting ,Please Re - link Your Zoom", {
            position: "top-center",
          });
          setTimeout(()=>{
            // navigate('/integration')
            window.loadModal("#Authorizeapp", "open"); 
          },2000)
        }
      })
      .catch(() => {});
  };

  return (
    <div className="container-fluid">
      <div className="dashboard">
        <div className="row">
          <div className="col-md-12">
            <div className="go-on-demand-saction">
              <div className="go-on-demand-one">
                <form>
                  <div className="left-side w-100">
                    {/* <label>Desired Session Type</label>
                    <Select
                      id="desired-session-type"
                      className="selection-box"
                      options={desiredOptions}
                      multi={true}
                      isMulti
                      // defaultInputValue={desiredOptions[0].value}
                      // onChange={(value) =>
                      //   setFieldValue("mediumship_session_tools", value)
                      // }
                      value={defaultVal}
                      onChange={handleChange}
                      placeholder="Select Session Type"
                      // value={values.mediumship_session_tools}
                      closeMenuOnSelect={false}
                      closeMenuOnScroll={false}
                    /> */}
                    {/* <select id="estados">
                      <option value="">30 min (15 each)</option>
                      <option value="">60 min (15 each)</option>
                    </select> */}
                  </div>
                  {/* {!matchingStarted ? (
                    <div className="radio-butin-effect">
                      <label className="switch">
                        <input
                          onChange={statusChangeHandler}
                          checked={onlineStatus}
                          type="checkbox"
                          id="change-bg"
                        />
                        <div className="slider round"></div>
                      </label>
                    </div>
                  ) : (
                    <div className="d-inline-flex align-items-center">
                      <Spinner />
                      <span className="font-size-22">Matching In Progress</span>
                    </div>
                  )} */}
                  {/******START */}

                  <div className="from-inner-box radeo check-box-input">
                    <div class="statusBar">
                      <div
                        className={`_item ${
                          !onlineStatus && !matchingStarted
                            ? "_item1"
                            : "_item0"
                        } remove-styles mr-2 width-170px`}
                      >
                        <input
                          onChange={handleChangeActiveStatus}
                          value={0}
                          defaultChecked={!onlineStatus ? true : false}
                          checked={!onlineStatus ? true : false}
                          type="radio"
                          name="sessionstatus"
                          id="sessionstatus2"
                        />
                        <label htmlFor="sessionstatus2">
                          {" "}
                          <span className="mr-1">
                            <img src="/images/power.png" alt="power" />
                          </span>
                          Offline
                        </label>
                      </div>
                      <div
                        className={`_item ${
                          onlineStatus ? "_item2" : "_item0"
                        } remove-styles mr-2 width-200px`}
                      >
                        {checkingConnection ? (
                          <>
                            {" "}
                            <input
                              // onChange={handleChangeActiveStatus}
                              // value={1}
                              // checked={onlineStatus ? true : false}
                              // defaultChecked={onlineStatus ? true : false}
                              type="radio"
                              name="sessionstatus"
                              id="sessionstatus1"
                            />
                            <label htmlFor="sessionstatus1">
                              {" "}
                              <span className="mr-1">
                                <img src="/images/progres.png" className="rotate-image" alt="power" />
                              </span>
                              <span className="red">Checking Connection</span>
                            </label>
                          </>
                        ) : (
                          <>
                            {" "}
                            <input
                              onChange={handleChangeActiveStatus}
                              value={1}
                              checked={onlineStatus ? true : false}
                              defaultChecked={onlineStatus ? true : false}
                              type="radio"
                              name="sessionstatus"
                              id="sessionstatus1"
                            />
                            <label htmlFor="sessionstatus1">
                              {" "}
                              <span className="mr-1">
                                <img src="/images/gren-chck.png" alt="power" />
                              </span>
                              Go On Demand
                            </label>
                          </>
                        )}
                      </div>

                      <div
                        className={`_item ${
                          matchingStarted ? "_item3" : "_item0"
                        }  remove-styles mr-2 width-250px`}
                      >
                        <input
                          value={2}
                          checked={matchingStarted ? true : false}
                          type="radio"
                          name="sessionstatus"
                          id="sessionstatus3"
                        />
                        <label htmlFor="sessionstatus3">
                          <span className="mr-1">
                            <img src="/images/progres.png" alt="power" />
                          </span>
                          Matched For Session
                        </label>
                      </div>
                    </div>

                    {/* <div className="full-fild">
                      <div className="check-field">
                        <input
                          onChange={handleChangeActiveStatus}
                          value={1}
                          checked={onlineStatus ? true : false}
                          defaultChecked={onlineStatus ? true : false}
                          type="radio"
                          name="sessionstatus"
                          id="sessionstatus1"
                        />
                        <label htmlFor="sessionstatus1">Online</label>
                      </div>
                      <div className="check-field">
                        <input
                          onChange={handleChangeActiveStatus}
                          value={0}
                          defaultChecked={!onlineStatus ? true : false}
                          checked={!onlineStatus ? true : false}
                          type="radio"
                          name="sessionstatus"
                          id="sessionstatus2"
                        />
                        <label htmlFor="sessionstatus2">Offline</label>
                      </div>
                      <div className="check-field">
                     
                          value={2}
                          checked={matchingStarted ? true : false}
                          type="radio"
                          name="sessionstatus"
                          id="sessionstatus3"
                        />
                        <label htmlFor="sessionstatus3">
                          Matching In Progress
                        </label>
                      </div>
                      <span className="error marg-top-55">
                      
                      </span>
                    </div> */}
                    <p className="mt-3 font-size-11">
                      *Switching On-Demand status can sometimes take between 3
                      to 5 seconds, do not click multiple times.
                    </p>
                  </div>
                  {/******END */}
                </form>
              </div>
              <div className="full-row">
                <div className="go-on-join-meting">
                  <h2>My Appointments</h2>
                  <div className="go-on-join-inner">
                    <div className="go-on-join-table">
                      <table>
                        <tbody>
                          <tr>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Meeting Link</th>
                            {/* <th>Duration</th>
                            <th>Attendee</th> */}
                          </tr>
                          {appoinmentsData.length > 0 &&
                            appoinmentsData.map((appoinment) => (
                              <>
                                {appoinment.appointment_type === "On Demand" ? (
                                  <tr>
                                    <td>
                                      {moment(appoinment.date).format("LL")}
                                    </td>
                                    <td>
                                      {appoinment.time}
                                    </td>
                                    <td className="googl-link">
                                      <Button
                                        className={`resend-btn w-unset height-35 min-width-130px`}
                                        title="Click Here"
                                        status={0}
                                        onClick={() =>
                                          getLink(appoinment.appointmentId)
                                        }
                                      />
                                    </td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                              </>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
