import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";

export default function BookingConfirm({ slotDetails, userDetails }) {
  const { first_name } = useSelector(
    (state) => state.user.user
  );
  return (
    <div className="paymymedium-wrapper">
      <div className="paymymedium-container">
        <div className="">
          <img src="../images/new-cll.png" alt="user" />
          <>
            <h3 className="mt-3">
              Appointment Confirmed
              {/* {userDetails && userDetails.first_name}, your appointment is
          confirmed */}
            </h3>
            <div className="font-size-17 advance-guest-book-p">
              Check your email now for meeting link, may be in spam/junk.
            </div>
          </>
          <h5 className="mt-2">
          {userDetails && userDetails.first_name}, your appointment is
            confirmed
          </h5>
          {console.log(slotDetails,":slotDetails")}
          <span className="advance-guest-book-span">
            {slotDetails &&
              moment(
                `${slotDetails.date} ${slotDetails.time}`,
                "YYYY-MM-DD hh:mm A"
              ).format("hh:mm A")}{" "}
            with {slotDetails && slotDetails.medium_name}
          </span>
          <br />
          <p className="advance-guest-book-p">
            {slotDetails &&
              moment(`${slotDetails.date}`, "YYYY-MM-DD hh:mm A").format(
                "LL"
              )}{" "}
          </p>
          <br />
          {/* <span className="advance-guest-book-span">
            {" "}
            An account has been created on VerySoul.com.
            <br /> Please check your email for a temporary password to Login.
          </span> */}
          {/* <div>

      <a className="advance-tag-my-calendar"
        target="_blank"
        href={slotDetails && slotDetails.calendarLink}
      >
        Add to my calendar
      </a>
    </div> */}
          <div className="confirm-btn-host-booking">
            <a
              className=" advance-tag-margin advance-tag-my-calendar d-block"
              href={"/"}
            >
              Continue
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
