import React, { useEffect, useState } from 'react'
import TimeInput from './times'
import { capatilizeLetter } from '../../../utils/helpers';
import { getTimeImage } from '../../../utils/utils';
import moment from "moment";
import Schedules from './schedules';
import AddEditSchedules from './addeditschedules';
import { useDispatch, useSelector } from 'react-redux';
import { updateCountries, updateLanguage } from './functions';
import { getAllEventDetails } from '../../../services/admin';

export default function OnDemandUpdates() {

  const { languagesArray, countryArray } = useSelector((state) => state.uidata);
  const dispatch = useDispatch();
  const [languages, setLanguages] = useState([]);
  const [countries, setCountries] = useState([]);
  const [step, setStep] = useState(true);
  const [eventId, setEventId] = useState("");

  useEffect(() => {
    setEventId("");
    if (!languagesArray.length) updateLanguage(dispatch);
    if (!countryArray.length) updateCountries(dispatch);
  }, [])

  useEffect(() => {
    setLanguages(
      languagesArray.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      })
    );
  }, [languagesArray]);
  useEffect(() => {
    setCountries(
      countryArray.map((item) => {
        return {
          value: item.id,
          label: item.country_name,
        };
      })
    );
  }, [countryArray]);

  const openScheduleForm = (id) => {
    setEventId(id)
    setStep(false);
  }

  return (
    <>
      {step ? <Schedules
       setStep={setStep}
        openScheduleForm={openScheduleForm}
        setEventId={setEventId}
        />
        :
        <AddEditSchedules setStep={setStep}
          openSchedules={setStep}
          languages={languages}
          countries={countries}
          schedule_id={eventId}

        />}
    </>
  )
}
