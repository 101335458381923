import API from "../api/api";

const config = (token) => {
  return {
    headers: {
      "Content-type": "application/json",

      "X-Frame-Options": "DENY",
      "Content-Security-Policy": "style-src 'self'",
      Authorization: `Bearer ${token}`,
    },
  };
};

export const getLanguages = async () => {
  let token = localStorage.getItem("access_token");
  const response = await API.get("language-data", config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getSpeciality = async () => {
  let token = localStorage.getItem("access_token");
  const response = await API.get("specialty-data", config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getCountry = async () => {
  let token = localStorage.getItem("access_token");
  const response = await API.get("select-country", config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getTimezones = async () => {
  const response = await API.get("show-timezones")
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getPaymentName = async () => {
  let token = localStorage.getItem("access_token");
  const response = await API.get("select-payment", config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getTools = async () => {
  let token = localStorage.getItem("access_token");
  const response = await API.get("select-tool", config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getZoomAuthUrl = async (page) => {
  let token = localStorage.getItem("access_token");
  const response = await API.get(`zoom/get-redirection-url/${page}`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const deauthorizeCalendly = async () => {
  let token = localStorage.getItem("access_token");
  const response = await API.get("zoom/deauthorize", config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getMettingLink = async (id) => {
  let token = localStorage.getItem("access_token");
  const response = await API.get(`/get-meeting-link/${id}`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const LinkPersonalCalendar = async (id,page) => {
  let token = localStorage.getItem("access_token");
  const response = await API.get(`google/calendar?state=${id}&page=${page}`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const deauthorizePersonalCalendar = async () => {
  let token = localStorage.getItem("access_token");
  const response = await API.get("google/calendar/unlink", config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getMediumPublicProfileDetails = async (id) => {
  // let token = localStorage.getItem("access_token");

  const response = await API.get(
    `get-medium-details?medium=${id}`
    // config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getPaymentDetails = async (id) => {
  // let token = localStorage.getItem("access_token");

  const response = await API.get(
    `medium/payment-details/${id}`
    // config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
