import React from "react";
import Vimeo from "@u-wave/react-vimeo";
import { innerWidthHandler } from "../../../utils/helpers";
import Footer from "../appointment/footer";

import { useNavigate } from "react-router-dom";
import { setLoading } from "../../../reducer/auth/auth";
import { saveSessionTerms } from "../../../services/client";
import { setSaveTermsHanlder } from "../../../reducer/userSlice";
import { useDispatch, useSelector } from "react-redux";

export default function ClientVideo({

}) {
    const {
        renderTypeInfo,
        bookingTypeInfo,
    } = useSelector((state) => state.client);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isSafari = () => {
        const ua = navigator.userAgent.toLowerCase();
        return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
    };

    const onVideoEnd = () => {
        // const getSessionTerms = (data) => {
        dispatch(setLoading(true));
        let body = {
            terms_and_condition_json: JSON.stringify(1),
        };
        saveSessionTerms(body)
            .then((data) => {
                dispatch(setLoading(false));
                if (data.data.status) {
                    console.log("success");
                    dispatch(setSaveTermsHanlder());
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
        //   };
    }
    return (
        <div>
            <div className="select-appointment">
                <div onClick={() => navigate('/appointment')} className="back-btn-appointments">
                    <div className="bck">
                        <img src="images/black-erow.png" alt="back" />
                        <p className="margin-unset"> Back</p>
                    </div>
                </div>
                {/* <div onClick={backHandler} className="back-btn-appointments">
              <a href="#">
                <img src="images/black-erow.png" />
                <p className="margin-unset"> Back</p>
              </a>
            </div> */}
                <div className="container">
                    <div className="select-appointment-inner width-unset">
                        {/* <div className="edite">
              <a href="#">
                Edit Preferences
                <img src="images/pencil.png" />
              </a>
            </div> */}

                        <div className="book-practice">


                            <>
                                <h2>{"Please turn up your volume to watch this 3-minute introductory video"}</h2>
                                <Vimeo
                                    muted={isSafari()}
                                    onEnd={onVideoEnd}
                                    autoplay={true}
                                    className="popover-video"
                                    width={innerWidthHandler()}
                                    height={430}
                                    // video={"https://vimeo.com/767367299/9424233cd8"}
                                    video={bookingTypeInfo === "outreach" ? 'https://vimeo.com/767367299/9424233cd8' : 'https://player.vimeo.com/video/768672555?h=d255e3da2a'}
                                // https://vimeo.com/767367299/9424233cd8
                                />
                            </>


                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <p className="mt-3 nav-link font-size-22">
                        For Support &nbsp;
                        <a href="mailto:Support@verysoul.com" target="_blank">
                            Click Here
                        </a>
                    </p>

                    <Footer />
                </div>
            </div>
        </div>
    );
}
