import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment-timezone";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { getTimezones } from "../../services/uidata";
import { filterVisibleTimeZones } from "../../utils/helpers";

//css
import "./checktimezone.css";
import { updateNewTimeZone } from "../../services/profile";
import { setUser } from "../../reducer/userSlice";
import { fetchUser } from "../../services/auth";
import { ar } from "date-fns/locale";

export default function TimeZonePopup({ newTimezoneData = [], confirmNewTimeZone, disableTime, CurrentDate }) {
    //states
    // const navigate = useNavigate();
    const dispatch = useDispatch()

    const [value, setValue] = useState("");
    const [error, setError] = useState(false);



    const changeHandler = (e) => {
        setError(false)
        setValue(e.target.value);
        setshowRequiredError(false)
        changeTimeZone(e.target.value)
    }

    const submitHandler = () => {
        setshowRequiredError(true)
        if (value !== "" && timeconfirmcheck) {
            confirmNewTimeZone(value)
        }
        else {
            if (value == "") setError(true)


        }
    }
    useEffect(() => {
        setCurrentDates(CurrentDate);
    }, [CurrentDate])
    const [CurrentDates, setCurrentDates] = useState(CurrentDate)
    const [timeconfirmcheck, settimeconfirmcheck] = useState(false)
    const [showRequiredError, setshowRequiredError] = useState(false);
    const changeTimeZone = (value) => {
        // setFieldValue("timezone", value)
        let filter = filterVisibleTimeZones(newTimezoneData).filter((item) => item.id == value);
        setCurrentDates(moment().tz(filter[0].name).format('hh:mm A'))
    }
    return (
        <div
            className="modal fade mt-5"
            id="TimeZonePopup"
            data-backdrop="static"
            role="dialog"
        >
            {console.log(CurrentDate, "CurrentDate")}
            <div className="modal-dialog  modal-dialog-preview-new">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="schedule-popup">
                            {/* <img src="../images/new-cll.png" alt="user" /> */}

                            <h3 className="">
                                What timezone do you think you are in?
                                <br />
                            </h3>
                            <div>
                                <div className="cuntrey-website pl-5 pr-5 pt-5 pb-1">
                                    <div className="cuntrey-website">
                                        {/* {timezoneOnly && ( */}
                                        <select
                                            className="select-check"
                                            value={value}
                                            onChange={changeHandler}
                                            id="timezone"
                                            name="timezone"
                                            placeholder="Select Your Timezone"
                                        >
                                            <option disabled="" selected="" value="">
                                                Timezone<i>*</i>
                                            </option>
                                            {filterVisibleTimeZones(newTimezoneData).map((timezone) => (
                                                <option disabled={disableTime == timezone.name} value={timezone.id}>
                                                    {timezone.front_name}
                                                </option>
                                            ))}
                                        </select>

                                        {error && <div className="error-select mb-1"> <span className="error">Required</span></div>}
                                  

                                    </div>
                                    {CurrentDate !== undefined && <div>
                                        <h6 className="mt-4 text-left">   Current time as per selected timezone: <b className="red ">{CurrentDates}</b></h6>
                                        <div className="full-fild">

                                            <div className="prvacy-box eth-pri">
                                                <input
                                                    onChange={(e) => settimeconfirmcheck(e.target.checked)}
                                                  
                                                    value="The time above is my correct time"
                                                    id="timeconfirmcheck1"
                                                    type="checkbox"
                                                    name="timeconfirmcheck1"
                                                
                                                    className="pointer"
                                                />
                                                <label htmlFor="timeconfirmcheck1">
                                                    The time above is my correct time.
                                                </label>
                                                <span className="error error-select">
                                                  
                                                    {!timeconfirmcheck && showRequiredError && <div className="error-select mb-1"> <span className="error">Required</span></div>}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                            <div
                                className="advance-tag margin-unset pointer"
                                onClick={submitHandler}
                            >
                                Submit
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
