import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  languagesArray: [],
  toolsArray: [],
  countryArray: [],
  specialtyArray: [],
  timezoneArray:[],
};
export const uidataSlice = createSlice({
  name: "uidata",
  initialState,
  reducers: {
    setaLanguagesArray: (state, action) => {
      state.languagesArray = action.payload;
    },
    setToolsArray: (state, action) => {
      state.toolsArray = action.payload;
    },
    setCountryArray: (state, action) => {
      state.countryArray = action.payload;
    },
    setSpecialtyArray: (state, action) => {
      state.specialtyArray = action.payload;
    },
    setTimezoneArray: (state, action) => {
      state.timezoneArray = action.payload;
    },
  },
});

export const {
  setaLanguagesArray,
  setToolsArray,
  setCountryArray,
  setSpecialtyArray,
  setTimezoneArray
  
} = uidataSlice.actions;

export default uidataSlice.reducer;
