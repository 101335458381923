import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-saction">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="footer-box footer-one">
              <p>
                <img src="../images/Union-icon.png" alt="footer-img" />
                For greater perspective on life
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="footer-box-centar">
              <ul>
                <li>
                  <Link to="/terms-conditions">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to={"/privacy-policy"}>Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-12 col-lg-5">
            <div className="footer-box-right">
             
              <p>© 2022 Very Soul LLC. All rights reserved.</p>
               <p><b>Note: For optimum performance: use Chrome, Safari, or Edge browser</b></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
