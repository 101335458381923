import React from "react";
import IntegrateModule from "./integrateModule";
import './integration.css';
export default function Integration() {
  return (
    <div>
      <IntegrateModule />
    </div>
  );
}
