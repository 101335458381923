import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CopyComponent from "../../../../components/copyComponent";
import CsvDownload from "../../../../components/csvdownload";
import Filter from "../../../../components/filter";
import Search from "../../../../components/search";
import Sorting from "../../../../components/sorting";
import UserStatus from "../../../../components/userstatus";
import Pagination from "../../../../components/tablePagination";
import { getMediumRole } from "../../../../utils/utils";
import Checkbox from "../../Subcomponents/checkbox";
import { filterList, bulkList } from "../data";
import Button from "../../../../components/button";
import ToggleTestUser from "../../Subcomponents/toogletestuser";


import { fireFilter } from "../functions";
import { setIntermediatePage, setIntermediaterowsPerPage, setPathArray } from "../../../../reducer/adminSlice";

const headers = [
  { label: "Screen Name", key: "screen_name" },
  { label: "Email", key: "email" },
  { label: "Approved Role", key: "approved_role" },
  // { label: "Status", key: "status_name" },
  { label: "Online status", key: "is_available" }

];
export default function Intermediate(props) {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [selected, setSelected] = useState([]);

  const {
    intermediates,
    updateUser,
    onClickHandler,
    filterListMediums,
    bulkActions,
    getPaginationData,
    intermediatePage,
    filterReset,
    intermediaterowsPerPage,
    setFilterValues,
    setSearchValues,
    searchValue,
    filterValues,
    setOptionsList,
    optionsList,
    operatorList,
    setOperatorList,
    showOprater,
    setShowOprater,
    setFilterOprater,
    filterOprater,
    setFilterValueOptions,
    filterValueOptions,
  } = props;

  const dispatch = useDispatch()
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const rowperpage = searchParams.get('rowperpage');

  useEffect(() => {
    if (page !== null) {

      dispatch(setIntermediatePage(page));
    }
    if (rowperpage !== null) {
      dispatch(setIntermediaterowsPerPage(rowperpage));
    }
  }, [])
  let info = JSON.parse(localStorage.getItem('pagination'))
  const {
    allIntermediateCount
  } = useSelector((state) => state.users);

  const navigateUser = (id) => {
    dispatch(setPathArray(id.id));
    window.renderFirst = false
    navigate(`/profile-view/${id.id}?view=2&search=${filterValues}&searchName=${Object.values(searchValue)}&page=${info.page}&rowperpage=${info.rowsPerPage}&searchOprator=${showOprater}&searchFilterOprater=${filterOprater}`);
  };
  const navigateEdit = (e, id) => {
    window.renderFirst = false
    if (e.target.className == "capitalize") {
      navigate(`/profile-edit/${id.id}?view=2&search=${filterValues}&searchName=${Object.values(searchValue)}&page=${info.page}&rowperpage=${info.rowsPerPage}&searchOprator=${showOprater}&searchFilterOprater=${filterOprater}`);
    } else {
    }
  };
  const checkBoxChangeHandler = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      let result = intermediates.map((a) => a.id);
      setSelected(result);
    } else {
      setSelected([]);
    }
  };

  // const getPaginationData = (item) => {
  //   console.log('paginationdata',item.from);
  //   getFromToData(item)
  //  };


  const changeHandler = (e) => {
    if (e.target.value === "Decline") {
      bulkActions(2, selected);
    }
    if (e.target.value === "Delete") {
      bulkActions(3, selected);
    }
  };
  return (
    <>
      <div className="d-flex justify-content-end gap-10 flex-wrap bottomClass">
        <Search callApi={filterListMediums} list={filterList} searchValue={searchValue} setSearchValues={setSearchValues} setFilterValues={setFilterValues}
          filterValues={filterValues} isDatepickerShow={1} setOptionsList={setOptionsList}
          optionsList={optionsList}
          operatorList={operatorList}
          setOperatorList={setOperatorList}
          showOprater={showOprater}
          setShowOprater={setShowOprater}
          setFilterOprater={setFilterOprater}
          filterOprater={filterOprater} setFilterValueOptions={setFilterValueOptions} filterValueOptions={filterValueOptions} isOperator={1}/>
        <Filter
          name={"Bulk Actions"}
          list={bulkList}
          changeHandler={changeHandler}
        />
        {/* <Button
          status={0}
          type="button"
          onClick={filterReset}
          className={`resend-btn  width-80px  reset-btn-123`}
          title="Reset"
        /> */}
      </div>
      <CsvDownload
        href={`${process.env.REACT_APP_API_URL_EXPORT}` + 'export-medium-list?user_type=intermediate'}
      // usertype={"intermediate"}
      // data={fireFilter(intermediates)} headers={headers} filename={'intermediates'}
      />
      <div className="table-box-medium">
        <table className="caseTable">
          <tbody>
            <tr className="dark">
              <th>
                <input
                  onChange={(e) => checkBoxChangeHandler(e)}
                  type="checkbox"
                  id="one"
                />
                <label htmlFor="one"></label>
                &nbsp;
              </th>
              <th > <div className="d-flex align-items-center"> <span>Screen Name</span> <Sorting callApi={filterListMediums} /></div></th>


              <th>Email</th>
              <th>Test User</th>
              <th>Starter Referral Code</th>
              <th>Total Starter Referrals</th>
              <th>Approved Role</th>
              <th>Online status</th>
              {/* <th>status</th> */}
              {/* <th>Additional Data</th> */}
              <th className="text-right">Actions</th>
            </tr>
            {intermediates.length > 0 ? (
              intermediates.map((intermediate) => (
                <tr
                  onClick={(e) => navigateEdit(e, intermediate)}
                  className="innr pointer"
                >
                  <td className="">
                    <div className="d-flex-medium-tables">
                      <Checkbox
                        medium={intermediate}
                        status={checked}
                        selected={selected}
                        setSelected={setSelected}
                      />
                      <img src="/images/undraw_profile.svg" className="profile-image-avatar" alt="avatar" />
                    </div>
                  </td>
                  <td className="capitalize">
                    {/* {intermediate.first_name} &nbsp; {intermediate.last_name} */}
                    {intermediate.screen_name
                      ? intermediate.screen_name
                      : "N/A"}{(intermediate.comments != null && intermediate.comments !== "") && (<b  style={{
                        fontSize: '22px',
                      }}>*</b>)}
                  </td>
                  <td>{intermediate.email}
                    <CopyComponent value={intermediate.email} />
                  </td>
                  <td><ToggleTestUser
                    clientId={intermediate.id}
                    initialStatus={intermediate.test_user === 1} // Set initial toggle state
                    onStatusChange={(id, newStatus) => {
                      console.log(`Client ${id} status changed to ${newStatus ? 'on' : 'off'}`);
                      // Additional handling if necessary
                    }}/></td>
                  <td>{intermediate.refer_code ? intermediate.refer_code : '-'}</td>
                  <td>{intermediate.number_of_uses}</td>
                  <td className="change">
                    <em>{getMediumRole(intermediate.current_mediumship_level)}</em>
                    {/* <a href="#">Change</a> */}
                  </td>
                  <td>
                    <UserStatus status={intermediate.is_available} />
                  </td>
                  {/* <td>{intermediate.status}</td> */}
                  {/* <td>
                    <Link
                      className="view-profile-navigate"
                      to={`/profile-view/${medium.id}`}
                    >
                      <img src="../images/blue-e.png" />
                      View Profile
                    </Link>
                  </td> */}
                  <td className="dott">

                    <img
                      onClick={(e) =>
                        onClickHandler(e, intermediate.id, "intermediate")
                      }
                      className="padding-10"
                      src="images/Unionblck.png"
                      alt=""
                    />
                    {intermediate.status && (
                      <div className="approve-btn-1 approve-btn z-index-1">
                        <span
                          onClick={(e) => {
                            window.renderFirst = false
                            e.stopPropagation();
                            navigate(
                              `/services?schedule=schedule&id=${intermediate.id}&view=2&level=${intermediate.current_mediumship_level}&search=${filterValues}&searchName=${Object.values(searchValue)}&page=${info.page}&rowperpage=${info.rowsPerPage}`
                            );
                          }}
                        >
                          Schedule
                        </span>
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            window.renderFirst = false;
                            navigate(
                              `/availability/${intermediate.id}?&view=2&search=${filterValues}&searchName=${Object.values(searchValue)}&page=${info.page}&rowperpage=${info.rowsPerPage}`
                            );
                          }}
                        >
                          Calendar
                        </span>
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            navigateUser(intermediate);
                          }}
                        >
                          View
                        </span>
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            updateUser(
                              intermediate,
                              parseInt(intermediate.status_name) === 4 ? 1 : 4,
                              "intermediate",
                              ""
                            );
                          }}
                        >
                          {parseInt(intermediate.status_name) === 4
                            ? "UnRestrict"
                            : "Restrict"}
                        </span>

                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            updateUser(intermediate, 3, "delete", "");
                          }}
                        >
                          Delete
                        </span>
                      </div>
                    )}
                  </td>
                  {/* <td onClick={() => onClickHandler(user.id)} className="dott">
                    <a href="javascript:void(0);">
                      <img src="images/Unionblck.png" alt="" />
                    </a>
                    {user.status && (
                      <div className="approve-btn  mt-20">
                        <span onClick={() => updateUser(user, 2)}>Decline</span>

                        <span onClick={() => updateUser(user, 3)}>Delete</span>
                      </div>
                    )}
                  </td> */}
                  {/* <td>Medium</td> */}
                  {/* <td className="dot-image">
                  <img src="../images/Unionblck.png" />
                </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No Mediums Found
                </td>
              </tr>
            )}
            {/* <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr> */}
          </tbody>
        </table>
        {/* <div className="pagination-box">
        <div className="left-pagi">
          <span>Showing 1 to 10 of 18 entries</span>
        </div>
        <div className="right-pagi">
          <ul>
            <li>Previous</li>
            <li className="active">
              <a href="#">1</a>
            </li>
            <li>
              <a href="#">2</a>
            </li>
            <li>
              <a href="#">3</a>
            </li>
            <li>Next</li>
          </ul>
        </div>
      </div> */}
        <Pagination rowsPerPageOptions={[5, 10, 25]} count={allIntermediateCount} activeTab={"intermediate"} intermediatePage={intermediatePage}
          intermediaterowsPerPage={intermediaterowsPerPage} getPaginationData={getPaginationData} />
      </div>
    </>
  );
}
