import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { items, slotsArray } from "./data";
import moment from "moment";
import Select from "react-select";
import ReactSelect from "react-select";
import {
  reschedueAppointment,
  getAppointmentsDetails,
} from "../../../services/admin";
import { da } from "date-fns/locale";
import { toast } from "react-toastify";
import { getTimeImage } from "../../../utils/utils";
export default function UpdatedReScheduleAppt({
  // usersListMedium,
  // usersListClient,
  reschedeId,
  // reschedeData,
  getAllAppointmentsList,
  activeTab,
  setReschedeId,
  // setReschedeData,
  selectedIndex,
  setSelectedIndex,
}) {
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [clienttime, setClientTime] = useState("");
  const [clienttimeZone, setClientTimeZone] = useState("");
  const [clientdate, setClientDate] = useState("");
  // const [timeslots,setTimeslots]=useState([]);
  // const [allowButton, setAllowButton] = useState(false);
  const { timezone } = useSelector((state) => state.user.user);
  const [errorType, setErrorType] = useState([]);
  const [colorType, setColorType] = useState("red");

  const [warningMessage, setWarningMessage] = useState("");

  useEffect(() => {
    // console.log(reschedeData, "reschedeData")
    if (Object.values(reschedeId).length > 0) {
      console.log(reschedeId, "reschedeId");

      setTime("");
      setDate(reschedeId.date);
      setClientDate("");
      setClientTime("");
      setClientTimeZone("");
      // setTimeslots(reschedeData.slots);
    }
    // if(!reschedeData){
    //     setColorType("black");
    //     setErrorType(["No Slots Found"]);
    // }
  }, [reschedeId]);


  const onInputChange = (e) => {
    setErrorType([]);
    setWarningMessage("");
    let name = e.target.name;
    let value = e.target.value;
    if (name === "date") {
      setDate(value);
      setTime("");
      setClientDate("");
      setClientTime("");
      // let body = {
      //     appointment_id:reschedeId.id,
      //     date: value
      //   }
      //   getAppointmentsDetails(body).then((data) => {
      //     // settimeslots(data.data.data);
      //     console.log('abc',data);
      //     let info = [];
      //     if(data.data.status){

      //     if(data.data.data){

      //     setTimeslots(data.data.data.slots);
      //     }}
      //     else{
      //         console.log("mesg",data.data.message);
      //         info.push(data.data.message);
      //         setTime("");
      //         setTimeslots([]);
      //         setColorType("black");
      //        setErrorType(info);
      // }
      // setReschedeId(item)
      // setSelectedIndex(index)
      // window.loadModal2("#ReScheduleAppt", "show");
      //   }).catch(() => { })
    }
    if (name === "time") {
      console.log('time', value)
      setTime(value);
      if (value !== "") {
        let body = {
          appointment_id: reschedeId.id,
          date: date,
          time: value
        }
        getAppointmentsDetails(body).then((data) => {
          // settimeslots(data.data.data);
          console.log('abc', data);
          let info = [];
          if (data.data.status) {

            if (data.data.data.slots) {
              setClientDate(data.data.data.timezone.client_timezone_date)
              setClientTime(data.data.data.timezone.client_timezone_time)
              setClientTimeZone(data.data.data.timezone.client_timezone)
              // setMediumDate(data.data.data.date);
            }
          }
          // else{
          //     console.log("mesg",data.data.message);
          //     info.push(data.data.message);
          //     setTime("");
          //     setTimeslots("");
          //     setColorType("black");
          //    setErrorType(info);
          // }
          // setReschedeId(item)
          // setSelectedIndex(index)
          // window.loadModal2("#ReScheduleAppt", "show");
        }).catch(() => { })
      }
      // reSchedule(value, false);
    }

    // if (name === "time") {
    //   console.log("time", value);
    //   setTime(value);

    // }
  };

  const reSchedule = (value, check) => {
    let body = {
      date: date,
      time: time,
      appointment_id: reschedeId.id,
    };

    reschedueAppointment(body)
      .then((data) => {
        console.log(data.data);
        if (data.data.status) {
          let info = [];
          if (data.data.message === "Slot Booked Successfully") {
            setTime("");
            setDate("");
            setClientDate("");
            setClientTime("");
            setClientTimeZone("");
            // setColorType("red");
            setErrorType([]);
            // setAllowButton(false);
            setWarningMessage("");
          }

          info.push(data.data.message);
          toast.success(data.data.message, { position: "top-center" });
          const dat = {
            page: 1,
            rowsPerPage: 10,
          };
          getAllAppointmentsList(dat, activeTab);
          window.loadModal2("#ReScheduleAppt", "hide");
          // setErrorType(info)
        } else {
          let info = [];
          if (data.data.error === "warning") {
            setWarningMessage(data.data.message);
          }
          //     if (data.data.error === "Slot is Available") {
          //         setAllowButton(true);
          //         info.push("Slot is Available");
          //         setColorType("bg-green");
          //         setWarningMessage('')
          //         console.log(data.data.data, ":cedl,")
          //         setMediumDate(data.data.data.medium.date)
          //         setClientTimer(data.data.data.medium.time)
          //         // setMediumTimeZone(data.data.data.medium_time);
          //         // setClientTimeZone(data.data.data.client_time)
          //     } else {
          //         typeof data.data.error !== "string"
          //             ? Object.entries(data.data.error).map(([key, value]) =>
          //                 info.push(value[0])
          //             )
          //             : info.push(data.data.error);
          //         setColorType("");
          //         setWarningMessage('')

          //     }
          //     setErrorType(info);
        }
      })
      .catch(() => { });
  };

  const updaterequest = () => {
    setErrorType([]);
    setWarningMessage("Please select another date/time");
  };
  const cancelRequest = () => {
    setTime("");
    setDate("");
    setClientDate("");
    setClientTime("");
    // setTimeslots([]);
    // setAppointmentType("");

    setColorType("");
    setErrorType([]);
    // setAllowButton(false);

    setWarningMessage("");

    // setClientTimer('')
    setReschedeId("");

    window.loadModal2("#ReScheduleAppt", "hide");
  };

  // const checkClientMediumTime = (name, mediumID, clientId) => {
  //     let body;
  //     if (name === "client") {
  //         body = {
  //             client_id: clientId,
  //             medium_id: mediumID
  //         }
  //     }
  //     else {
  //         body = {
  //             client_id: clientId,
  //             medium_id: mediumID
  //         }
  //     }
  //     console.log(body, "body")
  //     getClientMediumTimeZone(body).then((data) => {
  //         // if(data.data.status){
  //         setMediumTimeZone(data.data.data.medium_timezone);
  //         setClientTimeZone(data.data.data.client_timezone)
  //         // }
  //     }).catch(() => {

  //     })
  // }
  return (
    <div className="register-medium">
      <div
        className="modal fade "
        id="ReScheduleAppt"
        data-backdrop="static"
        role="dialog"
      >
        <div className="width-unset  modal-dialog advanceConfirmbooking modal-lg  modal-dialog-preview height-unset">
          <div className="modal-content p-5">
            <div className="modal-body">
              <div className="">
                <div className="schedule-popup">
                  <h3 className="mb-4 mt-2 text-center mt-2">
                    Re-Schedule Appointment ({"2A" + reschedeId.id + "X"})
                  </h3>
                  {warningMessage !== "" && (
                    <div className={`eroorbox warning-color`}>
                      {warningMessage}
                    </div>
                  )}
                  {errorType.map((item) => (
                    <div className={`eroorbox ${colorType}`}>{item}</div>
                  ))}
                </div>
                <div>
                  <div className="d-flex align-items-center ">
                    <div className="from-fild mt-2 mb-4 text-left ">
                
                      <span className="d5a67b p-1 width-140px d-inline-block"> {reschedeId.session_type === "1" ? 'My Time Zone' : 'Medium'}</span>
                    </div>
                    <div className="from-fild mt-2 mb-4 text-left width-150px max-width-150px  mr-3">
                      <label className="font-size-17" htmlFor="firstName">
                        Date
                      </label>
                      <div className="d-flex align-items-center justify-content-center">
                        <input
                          // disabled={checkDisbaled()}
                          onChange={onInputChange}
                          name="date"
                          type="date"
                          className="d-block p-2"
                          value={date}
                          min={moment().format("YYYY-MM-DD")}
                        />
                      </div>
                    </div>
                    <div className="from-fild mt-2 mb-4 text-left width-150px max-width-150px">
                      <label className="font-size-17" htmlFor="firstName">
                        Time
                      </label>
                      <div className="d-flex align-items-center justify-content-center">
                        <select
                          // disabled={checkDisbaled()}
                          onChange={onInputChange}
                          name="time"
                          className="p-2"
                          value={time}
                        >
                          {
                            <option value={""} selected disabled>
                              {"Time"}
                            </option>
                          }
                          {slotsArray.map((time) => (
                            <option
                              className="moon-create-app star-imh-moon"
                              value={time.time}
                            >
                              {time.change == "NO" ? "12:00 AM" : time.time}{" "}
                              {getTimeImage(time.time) ? (
                                <span>🌙</span>
                              ) : (
                                <></>
                              )}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="from-fild ml-2 text-left">
                      <span className="font-size-14">{timezone}</span>
                      {/* {clientTimeZone !== "" && <div className="d-flex align-items-center justify-content-center">
                                        <div className="from-fild mt-2 mb-4 text-left">
                                            <span className="text-center d-block mb-1">
                                                {" "}
                                                Client Timezone:
                                            </span>{" "}
                                            {clientTimeZone}
                                        </div>

                                    </div>
                                    } */}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="d-flex align-items-center ">
                    <div className="from-fild mt-2 mb-4 text-left ">
                      <span className="d5a67b p-1 width-140px d-inline-block"> {reschedeId.session_type === "1" ? 'Partner Time Zone' : 'Client'}</span>
                    </div>
                    <div className="from-fild mt-2 mb-4 text-left width-150px max-width-150px mr-3">
                      <div className="d-flex align-items-center justify-content-center">
                        <input
                          disabled={true}
                          // onChange={onInputChange}
                          // name="date"
                          type="date"
                          className="d-block p-2"
                          value={clientdate}
                        />
                      </div>
                    </div>
                    <div className="from-fild mt-2 mb-4 text-left width-150px max-width-150px">
                      {/* <label className="font-size-17" htmlFor="firstName">
                                                    Time
                                                </label> */}
                      <div className="d-flex align-items-center justify-content-center">
                        <select
                          disabled={true}
                          // onChange={onInputChange}
                          name="time"
                          className="p-2"
                          value={clienttime}
                        >
                          <option value={""} selected disabled>
                            {" "}
                            Time
                          </option>
                          {slotsArray.map((time) => (
                            <option
                              className="moon-create-app star-imh-moon"
                              value={time.time}
                            >
                              {time.change == "NO" ? "12:00 AM" : time.time}{" "}
                              {getTimeImage(time.time) ? (
                                <span>🌙</span>
                              ) : (
                                <></>
                              )}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="from-fild ml-2 text-left">
                      <span className="font-size-14">{clienttimeZone}</span>
                    </div>
                  </div>
                </div>
                {/* <div>
                                    <div className="d-flex align-items-center justify-content-center">
                                       
                                        <div className="from-fild mt-2 mb-4 text-left width-150px max-width-150px mr-3">
                                            <label className="font-size-17" htmlFor="firstName">
                                                Select Date
                                            </label>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <input
                                                    // disabled={true}
                                                    onChange={onInputChange}
                                                    name="date"
                                                    type="date"
                                                    className="d-block p-2"
                                                    value={date}
                                                    min={moment().format('YYYY-MM-DD')}
                                                />
                                            </div>
                                        </div>
                                        <div className="from-fild mt-2 mb-4 text-left width-150px max-width-150px">
                                            <label className="font-size-17" htmlFor="firstName">
                                                Select Time
                                            </label>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <select
                                                    // disabled={true}
                                                    onChange={onInputChange}
                                                    name="time"
                                                    className="p-2"
                                                    value={time}

                                                >
                                                    { <option value={""} selected disabled>
                                                        {'Time'}
                                                        
                                                    </option> }
                                                    {slotsArray.map((time) => (
                                                        <option className="moon-create-app star-imh-moon" value={time.time}>

                                                            {time.change == "NO" ? "12:00 AM" : time.time} {getTimeImage(time.time

                                                            ) ? <span>🌙</span> : <></>}


                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>
                                 */}

                <div className="confirm-delete-wrapper ">
                  <div className="confirm-btn-box">
                    <div
                      className="register-medium-confirnation"
                      onClick={cancelRequest}
                    // data-dismiss="modal"
                    >
                      Cancel
                    </div>
                    <div
                      className="register-medium-confirnation"
                      // data-dismiss="modal"
                      onClick={() =>
                        date !== "" && time !== ""
                          ? reSchedule(time, true)
                          : updaterequest()
                      }
                    >
                      Update
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
