import React, { useEffect, useState } from "react";
import Pusher from "pusher-js";
import moment from "moment-timezone";
//components
import Captcha from "./captcha";
import BookingPage from "./bookingpage";
import Topbar from "../../components/topbar/topbar";
import Footer from "../client/appointment/footer";
import BookingConfirm from './bookingconfirm';

//css
import "./booking.css";
import ClientForm from "./clientform";
import { toast } from "react-toastify";
import { createGuestBooking } from "../../services/client";
import { useParams } from "react-router-dom";
import { setLoading } from "../../reducer/auth/auth";
import { useDispatch, useSelector } from "react-redux";
import Questions from "./questions";
import PayOptionsPopUp from "./payment";
import { getCountry, getLanguages } from "../../services/uidata";
import TimeZoneCofirmPopup from "../profile/timeZoneConfirmPopup";
import TimeZonePopup from "../profile/timeZonePopup";
import { getTimeZoneFromCountry } from "../../services/auth";
import CountryTimeZoneSelection from "../profile/countryTimeZoneSelection";

export default function GuestBooking() {
    const { email } = useSelector(
        (state) => state.user.user
    );
    const params = useParams();
    const dispatch = useDispatch();
    const [stateType, setStateType] = useState("calendar");
    const [bookingDate, setBookingDate] = useState("");
    const [bookingTime, setBookingTime] = useState("");
    const [timezoneInfo, setTimeZoneInfo] = useState("");
    const [session_id, setsession_id] = useState("");
    const [subsession_id, setsubsession_id] = useState("");
    const [slotDetails, setslotDetails] = useState({})
    const [userDetails, setuserDetails] = useState({})
    const [timezoneData, setTimezoneData] = useState([]);
    const [price, setPrice] = useState('');
    const [duration, setDuration] = useState('');
    const [currency, setCurrency] = useState('');

    const [timeZoneName, setTimeZoneName] = useState('');

    const [timeZoneLocalName, setTimeZoneLocalName] = useState('');
    //
    const [paymentInfo, setPaymentInfo] = useState();
    const [counterStatus, setCounterStatus] = useState(false);
    const [formLanguages, setFormLanguages] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const [temporaryStorage, setTemporaryStorage] = useState({});
    const [disableTime, setDisableTime] = useState('');
    const [countryName, setcountryName] = useState('');
    const [countrySpecifiedTimeZone, setCountrySpecifiedTimeZone] = useState([

    ]);
    const [CurrentDate, setCurrentDate] = useState(moment().format('hh:mm A'))
    const usTimeZones =
        ["(GMT-5:00) Eastern Standard Time - New York",
            "(GMT-5:00) Eastern Standard Time - East-Indiana",
            "(GMT-6:00) Central Standard Time - Chicago",
            "(GMT-9:00) Alaska Standard Time - Alaska",
            "(GMT-10:00) Hawaii Standard Time - Honolulu",
            "(GMT-11:00) Samoa Standard Time - Midway"]
    const canadaTimeZones = ["(GMT-3:30) Newfoundland Standard Time - Newfoundland",
        "(GMT-4:00) Atlantic Standard Time - Atlantic",
        "(GMT-5:00) Eastern Standard Time - New York",
        "(GMT-6:00) Central Standard Time - Saskatchewan",
        "(GMT-6:00) Central Standard Time - Chicago"
    ]
    const updateLanguage = () => {
        getLanguages()
            .then((data) => {
                setFormLanguages(data.data.data);
            })
            .catch((err) => console.log(err));
    };

    const updateCountries = () => {
        getCountry()
            .then((data) => {
                setCountryData(data.data.data);
            })
            .catch((err) => console.log(err));
    };

    const onChange = (value) => {
        setTimeout(() => {
            setStateType("questions");
        }, 2000);
    };
    const openForm = () => {

        if (bookingDate === "" || bookingTime === "") {
            toast.error("Please select both date and time slot", {
                position: "top-center",
            });
            return;
        }
        // if (parseInt(session_id) !== 7) {
        //     window.loadModal2("#payoptionspopupguestBooking", "show");
        // }
        // else {
        //     let item = {

        //         paymenttype: '',
        //     };
        //     createBooking(item);
        // }
        dispatch(setLoading(true))
        setTimeout(() => {
            dispatch(setLoading(false))
            setStateType("form");
        }, 1000)
    };

    const createBooking = (info) => {
        // if (parseInt(session_id) !== 7) {
        //     dispatch(setLoading(false));
        //     window.loadModal2("#payoptionspopupguestBooking", "show");
        //     console.log(session_id, "seeiomnid")
        //     return;
        // }

        let body = {
            first_name: info.first_name,
            last_name: info.last_name,
            email: info.email,
            phone: info.phone,
            whatsapp: info.whatsapp,
            timezone: timezoneInfo,
            date: bookingDate,
            time: bookingTime,
            session_id: session_id,
            subsession_id: subsession_id,
            medium_id: params.id,
            agree_to_legal_terms_and_condition: info.agree_to_legal_terms_and_condition,
            opt_in: info.opt_in,
            amount: price,
            duration: duration,
            method: info.paymenttype,
            currency: currency,
            appointment_type: 'paybefore',
            country: info.country,
            languages: info.languages,
        }
        console.log(timeZoneLocalName)

        //new
        console.log(info.country, "info.country", "timezoneInfo", timezoneInfo)

        let countrycheck = info.country
        let countryLabel = countryData.filter(
            (item) => item.country_name == countrycheck
        );
        console.log(countryLabel, "countryLabel", countryData, countrycheck);
        setcountryName(countrycheck)
        let timeZoneFilter = timezoneData.filter((info) => info.id == timezoneInfo);
        console.log(timeZoneFilter);
        let newTimeZone = timeZoneFilter[0].name;
        let front_name = timeZoneFilter[0].front_name
        let phone = info.phone

        if (newTimeZone == "US/Arizona") {
            setDisableTime(newTimeZone)
            window.loadModal2('#TimeZoneCofirmPopup', 'show');
            setTemporaryStorage(body);
            return;
        }
        if (newTimeZone === "US/Mountain" || newTimeZone === "America/Los_Angeles") {
            setTemporaryStorage(body);
            const check = phone.slice(0, 4);
            console.log(check, "check")
            let array = [1623, 1480, 1520, 1867]
            if (array.includes(parseInt(check))) {
                setDisableTime('US/Arizona')
                window.loadModal2('#TimeZoneCofirmPopup', 'show');
                return;
            } else {

                window.loadModal2('#TimeZonePopup', 'show');
                return;
            }

        }

        if (countrycheck == "United States" && !usTimeZones.includes(front_name)) {
            window.loadModal2("#CountryTimeZoneSelection", "show");
            setTemporaryStorage(body);
            getTimeZoneFromCountry(countryLabel[0].country_code)
                .then((data) => {
                    if (data.data.status) {
                        setCountrySpecifiedTimeZone(data.data.data);
                    }
                })
                .catch(() => { });
            return;
        }
        if (countrycheck == "Canada" && !canadaTimeZones.includes(front_name)) {
            window.loadModal2("#CountryTimeZoneSelection", "show");
            setTemporaryStorage(body);
            getTimeZoneFromCountry(countryLabel[0].country_code)
                .then((data) => {
                    if (data.data.status) {
                        setCountrySpecifiedTimeZone(data.data.data);
                    }
                })
                .catch(() => { });
            return;
        }
        dispatch(setLoading(true));
        setDisableTime('')
        createGuestBooking(body)
            .then((data) => {
                console.log(data, body);
                setTimeout(() => {

                    if (data.data.status && parseInt(session_id) === 7) {
                        dispatch(setLoading(false));
                        setslotDetails(data.data.data)
                        setuserDetails(body)
                        setStateType("confirm");
                    }
                    else if (data.data.status && parseInt(session_id) !== 7) {
                        setCounterStatus(true)
                        dispatch(setLoading(false));
                        window.open(data.data.data.approvedUrl, "_self");
                        // setslotDetails(data.data.data.data)
                        setuserDetails(body)
                        // setStateType("confirm");

                        // window.loadModal2('#WaitingTimerPopup', 'show');
                    }
                    else {
                        dispatch(setLoading(false));
                    }
                }, 1000)
            })
            .catch(() => { dispatch(setLoading(false)); });
    };

    //
    const confirmRequest = (values) => {
        let value = values == "" ? temporaryStorage : values;
        window.loadModal2('#TimeZonePopup', 'hide');
        console.log(value);
        dispatch(setLoading(true));
        createGuestBooking(value)
            .then((data) => {
                console.log(data, value);
                setTimeout(() => {

                    if (data.data.status && parseInt(session_id) === 7) {
                        dispatch(setLoading(false));
                        setslotDetails(data.data.data)
                        setuserDetails(value)
                        setStateType("confirm");
                    }
                    else if (data.data.status && parseInt(session_id) !== 7) {
                        setCounterStatus(true)
                        dispatch(setLoading(false));
                        window.open(data.data.data.approvedUrl, "_self");
                        // setslotDetails(data.data.data.data)
                        setuserDetails(value)
                        // setStateType("confirm");

                        // window.loadModal2('#WaitingTimerPopup', 'show');
                    }
                    else {
                        dispatch(setLoading(false));
                    }
                }, 1000)
            })
            .catch(() => { dispatch(setLoading(false)); });
    }
    const cancelRequest = () => {
        window.loadModal2('#TimeZoneCofirmPopup', 'hide');
        window.loadModal2('#TimeZonePopup', 'show');
    }

    const confirmNewTimeZone = (value) => {
        console.log(temporaryStorage, "temporaryStorage", value);

        let newValue = {
            ...temporaryStorage,
            timezone: parseInt(value),
        }
        console.log(newValue, newValue)
        confirmRequest(newValue)
    }
    //
    useEffect(() => {
        localStorage.removeItem('guest_id');
        updateCountries();
        updateLanguage();

    }, [])

    return (
        <>
            <div className="new-appointment new-appointment-listt pb-5">
                <Topbar />
                {/* {stateType === "captcha" && <Captcha onChange={onChange} />}
            {stateType === "questions" && <Questions setStateType={setStateType} />} */}
                {stateType === "calendar" && (
                    <BookingPage
                        openForm={openForm}
                        setBookingDate={setBookingDate}
                        setBookingTime={setBookingTime}
                        setTimeZoneInfo={setTimeZoneInfo}
                        setsession_id={setsession_id}
                        setsubsession_id={setsubsession_id}
                        setTimeZoneName={setTimeZoneName}
                        setTimeZoneLocalName={setTimeZoneLocalName}
                        price={price}
                        currency={currency}
                        setPrice={setPrice}
                        setDuration={setDuration}
                        setPaymentInfo={setPaymentInfo}
                        setCurrency={setCurrency}
                        timezoneData={timezoneData}
                        setTimezoneData={setTimezoneData}
                    />
                )}
                {stateType === "form" && <ClientForm createBooking={createBooking}
                    bookingTime={bookingTime}
                    bookingDate={bookingDate}
                    timeZoneName={timeZoneName}
                    paymentInfo={paymentInfo}
                    session_id={session_id}
                    subsession_id={subsession_id}
                    medium_id={params.id}
                    price={price}
                    duration={duration}
                    setCounterStatus={setCounterStatus}
                    counterStatus={counterStatus}
                    formLanguages={formLanguages}
                    countryData={countryData}

                />}
                {stateType === "confirm" && <BookingConfirm slotDetails={slotDetails} userDetails={userDetails} />}
                <PayOptionsPopUp paymentInfo={paymentInfo} createBooking={createBooking} />
                <div className="text-center pb-5 mt-5 pt-5 padding-botton-120px">
                    <Footer />
                </div>

            </div>


            <TimeZoneCofirmPopup confirmRequest={confirmRequest} cancelRequest={cancelRequest} id={"TimeZoneCofirmPopup"} />
            <div className="text-center">
                <TimeZonePopup newTimezoneData={timezoneData} confirmNewTimeZone={confirmNewTimeZone} disableTime={disableTime} CurrentDate={CurrentDate} />
            </div>
            <CountryTimeZoneSelection
                confirmNewTimeZone={confirmNewTimeZone}
                newTimezoneData={countrySpecifiedTimeZone}
                CurrentDate={CurrentDate}
                countryName={countryName}
            />
        </>
    );
}


