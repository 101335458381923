import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { updateUserPrefrences } from "../../../services/profile";
import { setLoading } from "../../../reducer/auth/auth";
import { removeItemOnce } from "../../../utils/helpers";

const StepTwo = (props) => {
  const dispatch = useDispatch();
  const {
    NextStep,
    PreviousStep,
    stepTwoData,
    stepOneData,
    setValues,
    countryData,
    tools,
  } = props;
  const [newToolsData, setNewToolsData] = useState([]);
  const [newCountryData, setNewCountryData] = useState([]);
  useEffect(() => {
    setNewToolsData(
      tools.map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.tools_name,
        };
      })
    );
    setNewCountryData(
      countryData.map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.country_name,
        };
      })
    );
  }, ["", tools, countryData]);

  const getCountryTypes = (type, exclusions, inclusions) => {
    let country;
    if (type === "All Countries") {
      country = newCountryData.map((item) => {
        return item.id;
      });
    }
    if (type === "All Countries with Exclusions") {
      let itemArray = [];
      itemArray = newCountryData.map((item) => item.id);
      itemArray = exclusions.map((item) => removeItemOnce(itemArray, item.id));
      country = itemArray[0];
    }
    if (type === "Selected Countries only") {
      country = inclusions.map((item) => {
        return item.id;
      });
    }
    return country;
  };

  const RunFunction = (formData) => {
    dispatch(setLoading(true));
    setValues(formData, 2);
    const {
      mediumship_session_tools,
      currentRoles,
      screenName,
      allowRecordings,
      wantToDoSessions,
      wantToDoWork,
      exclusions,
      inclusions,
      interestedSessionType,
      interestedSessionType1,
      PaymentMethods,
    } = formData;
    let tools = mediumship_session_tools.map((item) => {
      return item.id;
    });
    let countryType = getCountryTypes(wantToDoWork, exclusions, inclusions);

    let item = {
      user_id: 1,
      mediumship_session_tools: tools,
      current_roles: currentRoles,
      screen_name: screenName,
      allow_recordings: allowRecordings,
      session_preferences: wantToDoSessions,

      session_message: interestedSessionType1,
      session_type: interestedSessionType,
      countries: countryType,
      country_type: wantToDoWork === "" ? "All Countries" : wantToDoWork,
      payment_methods: PaymentMethods,
    };
    updateUserPrefrences(item)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          NextStep();
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };
  const customHandleChange = (errors) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const changeHandler = (value, setFieldValue) => {
    let tool = value;
    if (value.length > 1 && value[0].tools_name === "None") {
      tool.shift();
    }
    const checkToolname = (obj) => obj.tools_name === "None";
    if (value.some(checkToolname)) {
      tool = value.filter((item) => item.tools_name === "None");
    }
    setFieldValue("mediumship_session_tools", tool);
  };

  const addDefault = () => {
    if (
      !stepTwoData.mediumship_session_tools.length > 0 &&
      newToolsData.length
    ) {
      stepTwoData.mediumship_session_tools.push(newToolsData[3]);
    }
  };
  return (
    <Formik
      initialValues={stepTwoData}
      validate={(values) => {
        const errors = {};
        if (!values.mediumship_session_tools.length)
          errors.mediumship_session_tools = "Required";
        if (!values.currentRoles.length) errors.currentRoles = "Required";
        if (!values.screenName) errors.screenName = "Required";
        if (!values.allowRecordings || parseInt(values.allowRecordings) === 3)
          errors.allowRecordings = "Required";
        if (!values.interestedSessionType.length)
          errors.interestedSessionType = "Required";
        if (
          values.wantToDoWork === "All Countries with Exclusions" &&
          !values.exclusions.length
        )
          errors.exclusions = "Required";
        if (
          values.wantToDoWork === "Selected Countries only" &&
          !values.inclusions.length
        )
          errors.inclusions = "Required";
        // if(values.wantToDoWork =="")
        // if (!values.interestedSessionType1)
        //   errors.interestedSessionType1 = "Required";
        // if (!values.PaymentMethods) errors.PaymentMethods = "Required";
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        RunFunction(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          {addDefault()}
          <div className="from-inner-box">
            <div className="full-fild">
              <label>
                Special Tools Used in Your Mediumship Sessions<i>*</i>
              </label>
              <Select
                id="mediumship_session_tools"
                className="selection-box"
                options={newToolsData}
                multi={true}
                isMulti
                onChange={(value) => changeHandler(value, setFieldValue)}
                onBlur={handleBlur}
                placeholder="Select Tools"
                value={values.mediumship_session_tools}
                closeMenuOnSelect={false}
                closeMenuOnScroll={false}
              />
              <div className="article">
                <p className="moretext">
                  <input type="text" placeholder="Other Language" />
                </p>
              </div>
              {/* <a className="moreless-button" href="#">
                Other (mediumship only)
              </a> */}
              <span className="error left-align">
                {errors.mediumship_session_tools &&
                  touched.mediumship_session_tools &&
                  errors.mediumship_session_tools}
              </span>
            </div>
          </div>
          <div className="from-inner-box radeo check-box-input">
            <div className="full-fild">
              <label>
                Current Roles 
                <i>*</i>
              </label>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="medium"
                  type="checkbox"
                  value={1}
                  defaultChecked={values.currentRoles.includes(1)}
                  // value="Medium"
                  name="currentRoles"
                />
                <label htmlFor="medium">Medium</label>
              </div>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="coachtomedium"
                  value={2}
                  defaultChecked={values.currentRoles.includes(2)}
                  // value="Coach to Mediums"
                  type="checkbox"
                  name="currentRoles"
                />
                <label htmlFor="coachtomedium">Coach to Mediums</label>
              </div>
            </div>
            <span className="error marg-top-55">
              {errors.currentRoles &&
                touched.currentRoles &&
                errors.currentRoles}
            </span>
          </div>
          <div className="from-inner-box radeo check-box-input">
            <div className="full-fild">
              <label>
                As Medium, which types of sessions are you interested in
                performing now? *
              </label>

              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={1}
                  defaultChecked={
                    values.interestedSessionType.includes("1") ? true : false
                  }
                  id="oneononemedium"
                  type="checkbox"
                  name="interestedSessionType"
                />
                <label htmlFor="oneononemedium">1-on-1 mediumship</label>
              </div>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={2}
                  id="groupmedium"
                  type="checkbox"
                  name="interestedSessionType"
                  defaultChecked={
                    values.interestedSessionType.includes("2") ? true : false
                  }
                />
                <label htmlFor="groupmedium">
                  Group mediumship demonstrations
                </label>
              </div>
              <span className="error marg-top-55">
                {errors.interestedSessionType &&
                  touched.interestedSessionType &&
                  errors.interestedSessionType}
              </span>
            </div>
          </div>
          <div className="from-inner-box search-name">
            <label>
              Screen Name for Client Bookings<i>*</i>
            </label>
            <input
              className={`${
                errors.screenName && touched.screenName && errors.screenName
                  ? "error-boundry"
                  : ""
              }`}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.screenName}
              id="screenName"
              name="screenName"
              type="text"
              placeholder="Screen Name"
            />
            <h6>
              In most cases this should be your real name. If you desire more
              privacy, please consider using your real first name and select something as
              human and authentic as possible for a last name identifier. We do
              not want profiles that sound mystical or magical and this is not a place for credentials or brand names.
            </h6>
          </div>
          <div className="from-inner-box radeo check-box-input">
            <div className="full-fild">
              <label>
                Allow Recordings(Y/N) <i>*</i>
              </label>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={1}
                  defaultChecked={
                    parseInt(values.allowRecordings) === 1 ? true : false
                  }
                  type="radio"
                  name="allowRecordings"
                  id="allowRecordingsyes"
                />
                <label htmlFor="allowRecordingsyes">Yes</label>
              </div>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={0}
                  defaultChecked={
                    parseInt(values.allowRecordings) === 0 ? true : false
                  }
                  type="radio"
                  name="allowRecordings"
                  id="allowRecordingsno"
                />
                <label htmlFor="allowRecordingsno">No</label>
              </div>
              <span className="error marg-top-55">
                {errors.allowRecordings &&
                  touched.allowRecordings &&
                  errors.allowRecordings}
              </span>
            </div>
          </div>
          <div className="from-inner-box radeo check-box-input">
            <div className="full-fild">
              <label>
                I Currently Want To Do Sessions With (choose all that apply)
              </label>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="Other Mediums"
                  id="othermediums"
                  type="checkbox"
                  name="wantToDoSessions"
                  defaultChecked={
                    values.wantToDoSessions.includes("Other Mediums")
                      ? true
                      : false
                  }
                />
                <label htmlFor="my-check">Other Mediums</label>
              </div>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="Starter Clients"
                  id="starterclient"
                  name="wantToDoSessions"
                  type="checkbox"
                  defaultChecked={
                    values.wantToDoSessions.includes("Starter Clients")
                      ? true
                      : false
                  }
                />
                {/* {console.log(values.wantToDoSessions,"wantToDoSessions")} */}
                <label
                  htmlFor="my-checktow"
                  className="strclint tooltip-1-focus"
                >
                  Starter Clients
                </label>
                <span className="tooltip-1">
                  Starter clients are generally friends and family of other
                  mediums who understand that you are newer to this. They are
                  here to support you in your development
                </span>
              </div>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="Outreach Clients"
                  id="outreachclients"
                  type="checkbox"
                  name="wantToDoSessions"
                  defaultChecked={
                    values.wantToDoSessions.includes("Outreach Clients")
                      ? true
                      : false
                  }
                />
                <label
                  htmlFor="my-checktow"
                  className="strclint tooltip-2-focus"
                >
                  Outreach Clients
                </label>
                <span className="tooltip-2">
                  Outreach clients are people from the general public who cannot
                  afford to purchase a session with a professional level medium.
                </span>
              </div>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="Paying Clients"
                  id="payingclient"
                  type="checkbox"
                  name="wantToDoSessions"
                  defaultChecked={
                    values.wantToDoSessions.includes("Paying Clients")
                      ? true
                      : false
                  }
                />
                <label htmlFor="payingclient">Paying Clients</label>
              </div>
            </div>
          </div>

          <div className="from-inner-box radeo check-box-input">
            <div className="full-fild">
              <label>
                I Currently Want To Work With Clients In These Locations
              </label>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="All Countries"
                  id="allcountries"
                  name="wantToDoWork"
                  type="radio"
                  defaultChecked={
                    values.wantToDoWork === "All Countries" ? true : false
                  }
                  checked={
                    values.wantToDoWork === "All Countries" ? true : false
                  }
                />
                <label htmlFor="allcountries">All Countries</label>
              </div>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="All Countries with Exclusions"
                  id="exclusioncountries"
                  name="wantToDoWork"
                  type="radio"
                  defaultChecked={
                    values.wantToDoWork === "All Countries with Exclusions"
                      ? true
                      : false
                  }
                />
                <label htmlFor="exclusioncountries">
                  All Countries with Exclusions
                </label>
              </div>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="Selected Countries only"
                  id="selectedcountries"
                  name="wantToDoWork"
                  type="radio"
                  defaultChecked={
                    values.wantToDoWork === "Selected Countries only"
                      ? true
                      : false
                  }
                />
                <label htmlFor="selectedcountries">
                  Selected Countries only
                </label>
              </div>
            </div>
          </div>
          {values.wantToDoWork !== "All Countries" &&
            values.wantToDoWork !== "" && (
              <div className="from-inner-box">
                <div className="full-fild">
                  <label>
                    {values.wantToDoWork === "All Countries with Exclusions"
                      ? "Select exclusions"
                      : "Select Countries"}
                  </label>
                  <Select
                    id="country"
                    className="selection-box"
                    options={newCountryData}
                    multi={true}
                    isMulti
                    onChange={
                      values.wantToDoWork === "All Countries with Exclusions"
                        ? (value) => setFieldValue("exclusions", value)
                        : (value) => setFieldValue("inclusions", value)
                    }
                    onBlur={handleBlur}
                    placeholder={
                      values.wantToDoWork === "All Countries with Exclusions"
                        ? "Select exclusion"
                        : "Select Country"
                    }
                    value={
                      values.wantToDoWork === "All Countries with Exclusions"
                        ? values.exclusions
                        : values.inclusions
                    }
                    closeMenuOnSelect={false}
                    closeMenuOnScroll={false}
                  />
                  <span className="error">
                    {errors.inclusions &&
                      touched.inclusions &&
                      errors.inclusions}
                    {errors.exclusions &&
                      touched.exclusions &&
                      errors.exclusions}
                  </span>
                </div>
              </div>
            )}

          {parseInt(stepOneData.mediumshipLevel) === 3 && (
            <div className="from-inner-box radeo check-box-input as-medium">
              <div className="full-fild">
                <label>
                  Would you prefer to start out as a public or private persona
                  on the website? <i>*</i>
                </label>
                <div className="full">
                  <div className="check-field">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value="Would want name, photo, and full profile published"
                      id="profilepublised"
                      type="radio"
                      name="interestedSessionType1"
                      defaultChecked={
                        values.interestedSessionType1 ===
                        "Would want name, photo, and full profile published"
                          ? true
                          : false
                      }
                    />
                    <label htmlFor="profilepublised">
                      Would want name, photo, and full profile published
                    </label>
                  </div>
                </div>
                <div className="full">
                  <div className="check-field">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value="Would want limited information published"
                      id="informationpublised"
                      type="radio"
                      name="interestedSessionType1"
                      defaultChecked={
                        values.interestedSessionType1 ===
                        "Would want limited information published"
                          ? true
                          : false
                      }
                    />
                    <label htmlFor="informationpublised">
                      Would want limited information published
                    </label>
                  </div>
                </div>
                <div className="full">
                  <div className="check-field">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value="Would want to start behind the scenes with nothing published"
                      id="nothingpublised"
                      type="radio"
                      name="interestedSessionType1"
                      defaultChecked={
                        values.interestedSessionType1 ===
                        "Would want to start behind the scenes with nothing published"
                          ? true
                          : false
                      }
                    />
                    <label htmlFor="nothingpublised">
                      Would want to start behind the scenes with nothing
                      published
                    </label>
                  </div>
                </div>
                <div className="full">
                  <div className="check-field">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value="Other"
                      id="otherpublished"
                      type="radio"
                      name="interestedSessionType1"
                      defaultChecked={
                        values.interestedSessionType1 === "Other" ? true : false
                      }
                    />
                    <label htmlFor="otherpublished">Other</label>
                  </div>
                  <span className="error marg-top-55">
                    {errors.interestedSessionType1 &&
                      touched.interestedSessionType1 &&
                      errors.interestedSessionType1}
                  </span>
                </div>
              </div>
            </div>
          )}
          {parseInt(stepOneData.mediumshipLevel) === 3 && (
            <div className="from-inner-box radeo check-box-input payment-saction">
              <div className="full-fild">
                <label>
                  If you plan to take payments on VerySoul bookings, what
                  payment methods do you prefer?
                </label>
                <div className="check-field">
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    defaultChecked={
                      values.PaymentMethods.includes(1) ? true : false
                    }
                    id="nopayment"
                    type="checkbox"
                    value={1}
                    name="PaymentMethods"
                  />
                  <label htmlFor="nopayment">
                    I do not intend to take payments at this time
                  </label>
                </div>
                <div className="check-field">
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="PayPal"
                    type="checkbox"
                    value={2}
                    name="PaymentMethods"
                    defaultChecked={
                      values.PaymentMethods.includes(2) ? true : false
                    }
                  />
                  <label htmlFor="PayPal">PayPal</label>
                </div>
                <div className="check-field">
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="Stripe"
                    type="checkbox"
                    value={3}
                    name="PaymentMethods"
                    defaultChecked={
                      values.PaymentMethods.includes(3) ? true : false
                    }
                  />
                  <label htmlFor="Stripe">Stripe</label>
                </div>
                <div className="check-field">
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="Venmo"
                    type="checkbox"
                    value={4}
                    name="PaymentMethods"
                    defaultChecked={
                      values.PaymentMethods.includes(4) ? true : false
                    }
                  />
                  <label htmlFor="Venmo">Venmo</label>
                </div>
                <div className="check-field">
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="Cashapp"
                    type="checkbox"
                    value={5}
                    name="PaymentMethods"
                    defaultChecked={
                      values.PaymentMethods.includes(5) ? true : false
                    }
                  />
                  <label htmlFor="Cashapp">Cashapp</label>
                </div>
                <div className="check-field">
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="Zelle"
                    type="checkbox"
                    value={6}
                    name="PaymentMethods"
                    defaultChecked={
                      values.PaymentMethods.includes(6) ? true : false
                    }
                  />
                  <label htmlFor="Zelle">Zelle</label>
                </div>
              </div>
            </div>
          )}
          <input
            onClick={PreviousStep}
            type="button"
            name="previous"
            className="previous action-button-previous"
            value="Back"
          />
          <input
            type="submit"
            onClick={() => customHandleChange(errors)}
            name="next"
            className="next action-button"
            value="Next"
          />
        </form>
      )}
    </Formik>
  );
};
export default StepTwo;
