import React, { useEffect, useState } from "react";
import "./toogle.css";
import { changeProfessionalStatus } from "../../services/admin";

export default function Toggle({item}) {
  const [checked, setChecked] = useState(false);
  useEffect(()=>{
    setChecked(item.is_enabled ===1?true:false)
  },["",item])
  const onChange = (e) => {
    e.stopPropagation();
    console.log(checked);
    let body = {
      medium_id:item.id,
      medium_status: checked ? 0 : 1
    }
    changeProfessionalStatus(body).then((data) => {
      if (data.data.status) {

        setChecked(!checked);
      }
    }).catch(() => {

    })
  };
  return (
    <div class="toggle-button-cover">
      <div class="button-cover">
        <div class="button r" id="button-1" onClick={onChange}>
          <input
            type="checkbox"
            checked={checked}
            name="toggle"
            class="checkbox"
          // onChange={onChange}
          />
          <div class="knobs"></div>
          <div class="layer"></div>
        </div>
      </div>
    </div>
  );
}
