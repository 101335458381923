import axios from "axios";
import { toast } from "react-toastify";
let status = false;
const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const refreshToken = async () => {
  const header = (token) => {
    return {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
  };
  const token = localStorage.getItem("access_token");
  const response = await API.post("refresh", "", header(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
API.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // console.log(config);
    return config;
  },
  function (error) {
    return error;
    // Do something with request error
    // console.log(error);
  }
);
API.interceptors.response.use(
  function (response) {
    if (
      response.data.status === false &&
      response.data.message === "Token Expired"
    ) {
      // refreshToken()
      //   .then((data) => {
      //     if (data.data.status) {
      toast.error("Please Login again to continue", { position: "top-center" })
      localStorage.clear();
      // localStorage.setItem('token','expired');
      // localStorage.setItem("access_token", data.data.data.access_token);
      window.location.reload();
      //   }
      // })
      // .catch(
      //   (err) => {
      //     console.log(err, "ssdfefrfs");
      //     return err;
      //   }
      //   // console.log(err)
      // );
    }
    if (
      response.data.status === false &&
      response.data.message === "The token has been blacklisted"
    ) {
      localStorage.clear();
      window.location.reload();
    }
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log(response);
    return response;
  },
  function (error) {
    console.log(error);
    if (status === false) {
      status = true;
      if( localStorage.getItem('ONDEMAND') =="ONDEMAND"){

      }
      else{
        
        toast.error("Something went wrong please try again", { position: "top-center" })
      }
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (error.response.status === 401) {
      refreshToken()
        .then((data) => {
          if (data.data.status) {
            localStorage.setItem("access_token", data.data.data.access_token);
            window.location.reload();
          }
        })
        .catch(
          (err) => {
            return err;
          }
          // console.log(err)
        );
    }
    return error;
  }
);
export default API;
